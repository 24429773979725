import useStyles from './styles'
import {connect} from 'react-redux'
import {useConfirmationModalContext} from "../../../../../../components/ConfirmationModalContextProvider";
import {RiskScoreConfirmationModalContext} from "../../../../../RiskProfiling/components/StepContent/components/step/ProductsSelectionSteps/components/InvestmentRecommendationStep/InvestmentRecommendationStep";
import React from "react";
import {
  extendAssetsWithDataAttribute,
  getRiskScoreConfirmation, PaymentPlanEditInstrumentsHandler, PayoutPlanAddInstrumentsHandler,
  SavingPlanAddInstrumentsHandler, SwitchInPlanInstrumentsHandler
} from "../../../../../RiskProfiling/utils";
import _ from "lodash";
import {
  calculateSwitchTransactionSaldo,
  checkSavingsPlanChanged,
  getModelPortfolioNumberFromPortfolio,
  getTransactionItemSRRI, instrCustodianData, mergeSwitchItems, percentageToQuantity, setEarliestPriceDates,
  setInstrumentBankData, transactionValueToEuro, transactionValueToPercentage, transactionValueToQuantity
} from '../../../../utils';
import {nextAvailableDate, SERVICE_CONCEPTS, TRANSACTION_INSTR, TRANSACTION_TYPE_VALUES} from "../../../../constants";
import RiskScoreConfirmationModal
  from "../../../../../RiskProfiling/components/StepContent/components/step/ProductsSelectionSteps/components/InvestmentRecommendationStep/components/RiskScoreConfirmationModal/RiskScoreConfimrationModal";
import moment from "moment/moment";
import {QTY_DECIMALS} from "../../../../../../utils/utils";
import {
  TRADING_ACTION_PAYOUT_PLAN, TRADING_ACTION_SAVINGS_PLAN,
  TRADING_ACTION_SWITCH_PLAN
} from "../../../../../../components/Charts/InstrumentsAllocationTable/constants";
import {
  DECIMAL_SCALE
} from "../../../../../RiskProfiling/components/StepContent/components/formElement/InputFormElement/InputFormElement";
import {
  getAssetInternalId, getKeyFieldValue,
  hasUnusedPaymentPlans,
  paymentPlanHasPosition,
  portfolioIsPaymentPlansOnly
} from "../../../../../CustomerDashboard/utils";
import {PAYMENT_PLANS_PORTFOLIO_ONLY_DELETE_PAYMENT_PLAN_MSG} from "../../../../../RiskProfiling/constants";
import {getPortfolioInstrumentsData} from "../../CombinedTradeStep";
import {fetchAssetPrice} from "../PortfolioTrade/PortfolioTrade";

function PaymentPlanEditTable(props) {

  const {
    portfolio,
    allInstruments,
    portfolioComponents,
    onInstrumentsChange,
    customer_id,
    action,
    groupInstruments,
    defaults,
    updateDefaults,
    dataService,
    addPaymentPlanOption,
    removePortfolioPaymentPlanOption,
    removePaymentPlanOption,
    transactionType
  } = props;

  const classes = useStyles()
  const modalContext = useConfirmationModalContext();

  const tradingType = {
    [TRANSACTION_TYPE_VALUES.SWITCH_PLAN]: TRADING_ACTION_SWITCH_PLAN,
    [TRANSACTION_TYPE_VALUES.PAYOUT_PLAN]: TRADING_ACTION_PAYOUT_PLAN,
    [TRANSACTION_TYPE_VALUES.SAVINGS_PLAN]: TRADING_ACTION_SAVINGS_PLAN
  }[transactionType]

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [confirmRiskModalState, setConfirmRiskModalState] = React.useState({
    confirmRiskModalOpen: false,
    confirmRiskModalActiveAsset: undefined
  })
  const [indexedGroupInstruments, setIndexGroupedInstrument] = React.useState([]);
  const [instrumentsHandlers, setInstrumentsHandlers] = React.useState({})

  const getInstrumentsHandlers = () => {

    if(!props.isVirtual) {
      let disableFunds = undefined;
      if (portfolio.depotType) {
        disableFunds = portfolio.depotType.configuration.trading_funds_disabled
      }

      if (transactionType == TRANSACTION_TYPE_VALUES.SWITCH_PLAN) {

        return {
          create: new SwitchInPlanInstrumentsHandler(
            portfolio.companyId, undefined,
            dataService.targetMarket, undefined, disableFunds),
          edit: new PaymentPlanEditInstrumentsHandler(portfolio.companyId, disableFunds)
        }
      } else if (transactionType == TRANSACTION_TYPE_VALUES.PAYOUT_PLAN) {

        return {
          create: new PayoutPlanAddInstrumentsHandler(
            portfolio.companyId, undefined,
            undefined, undefined, disableFunds),
        }
      } else if (transactionType == TRANSACTION_TYPE_VALUES.SAVINGS_PLAN) {

        return {
          create: new SavingPlanAddInstrumentsHandler(portfolio.companyId, undefined, dataService.targetMarket, undefined, disableFunds),
          edit: new PaymentPlanEditInstrumentsHandler(portfolio.companyId, disableFunds)
        }
      }
    }

    return {}

  }

  React.useEffect(() => {

    const handlers = getInstrumentsHandlers();
    if (!portfolio.isModelportfolio) {
      (async () => {
        if (!_.isEmpty(groupInstruments)) {
          const action = _.get(groupInstruments, '0.action')
          const handler = handlers[action]
          if (handler) {
            const instruments = getPortfolioInstrumentsData(groupInstruments, true)
            await handler.getCustodianAndFilterInstruments(instruments, true);
          }
        }
      })()
    }
    setInstrumentsHandlers(handlers)

  }, [portfolio.depotType])

  React.useEffect(() => {
    let actionInstrumentsCopy = [...groupInstruments]
    actionInstrumentsCopy.forEach((i, index) => {
      i._idx = index
      initExecutionDate(i);
      // trigger date change to load price if missing
      if(props.isVirtual && !i.loading && i.action === 'edit' && i.from_date && !_.has(i, 'data.price_eur')){
        handleDateChange(i, moment(i.from_date), 'from_date');
      }
      if (!_.isEmpty(i.buy)) {
        i.buy.forEach((b, index) => {
          b._idx = index
        })
      }
    })
    setIndexGroupedInstrument(actionInstrumentsCopy)
  }, [allInstruments])

  const initExecutionDate = (item) => {
    if (!props.isVirtual && ['edit', 'delete'].includes(item.action)) {
      let from_date = item.from_date && moment(item.from_date)
      let nextDate = nextAvailableDate(from_date, portfolio, transactionType)

      if (nextDate && (!from_date || nextDate.format() != from_date.format())) {
        handleDateChange(item, nextDate, 'from_date')
      }
    }
  }

  const keyField = ['edit', 'delete'].includes(action) ? 'payment_id' : getAssetInternalId;

  const findAssetIndex = (assets, asset) => _.findIndex(assets, a => getKeyFieldValue(a.data, keyField) == getKeyFieldValue(asset, keyField));

  const handleAddItems = async (action, selectedAssets, switchOutItem) => {
    const instrumentsCopy = [...allInstruments];

    const isSwitch = !!switchOutItem;

    if (isSwitch) {
      _.unset(switchOutItem, 'errors.buy');
    }

    // Confirm risk only for Savings Plans or Switch In Plans
    const transactionTypeValid = (transactionType === TRANSACTION_TYPE_VALUES.SWITCH_PLAN && isSwitch)
      || transactionType === TRANSACTION_TYPE_VALUES.SAVINGS_PLAN

    if (action === 'delete') {

      const serviceConcept = dataService.serviceConcept || dataService._service_concept;

      // In one order flow fields were renamed.
      // This solution prepared to handle one order flow and old processes
      const portfolioComponents = portfolio.data.hasOwnProperty('base_components')
        ? portfolio.data.base_components
        : portfolio.data.components
      const portfolioSavingsPlans = portfolio.data.hasOwnProperty('savings_plan')
        ? portfolio.data.savings_plan
        : portfolio.data.components

      const deleteConfirmationRequired = transactionType === TRANSACTION_TYPE_VALUES.SAVINGS_PLAN
        && serviceConcept == SERVICE_CONCEPTS.Anlageberatung
        && portfolioIsPaymentPlansOnly(portfolioComponents, portfolioSavingsPlans)
        && _.some(selectedAssets.map((asset) => paymentPlanHasPosition(asset, portfolioComponents)))
        && _.some(selectedAssets.map((asset) => !hasUnusedPaymentPlans(asset.isin, asset.payment_id,
          portfolioSavingsPlans, portfolio.transactions.savings_plan || [])))

      if (deleteConfirmationRequired && !await modalContext.confirm(PAYMENT_PLANS_PORTFOLIO_ONLY_DELETE_PAYMENT_PLAN_MSG, 'Bitte bestätigen Sie hier')) {
        return false
      }

    } else if (action === 'create') {
      const riskScoreConfirmation = transactionTypeValid && getRiskScoreConfirmation(
        dataService, extendAssetsWithDataAttribute(selectedAssets), false, getTransactionItemSRRI)

      if (riskScoreConfirmation && !await modalContext.confirm(riskScoreConfirmation, 'Bitte bestätigen Sie hier')) {
        return false
      }
    }

    selectedAssets.map(item => {
      // check only switch in asset as we might have multiple plans for same asset
      if(!isSwitch || findAssetIndex([switchOutItem, ...switchOutItem.buy], item) === -1){
        initExecutionDate(item)
        const instrument = {
          data: item, action,
          transaction_type: TRANSACTION_INSTR.amount,
          ...(action == 'create' && defaults || {})}; // set default transaction type
        setInstrumentBankData(instrument, instrCustodianData(item, portfolio.companyId), true);

        if(props.isVirtual && action === 'edit'){
          // for edit existing SP we can't get latest price but shoul be price on from_date
          _.unset(instrument, 'data.price_eur');
        }

        if (!isSwitch) {
          if (transactionType === TRANSACTION_TYPE_VALUES.SWITCH_PLAN) {
            instrument.tradingType = TRADING_ACTION_SWITCH_PLAN
          }
          instrumentsCopy.push(instrument);

          // TODO: use redux for VP as well
          if(props.isVirtual){
            instrument.tradingType = tradingType;
          } else {
            props.dispatch(addPaymentPlanOption({ customer_id: customer_id }, portfolio.data, _.cloneDeep(instrument.data), action, tradingType))
          }
        } else {
          switchOutItem.buy.push(instrument)
        }
      }
    });

    if (!!instrumentsCopy.length && props.isVirtual){
      setLoading(true);
      await setEarliestPriceDates(instrumentsCopy, props.dispatch)
      setLoading(false);
    }

    onInstrumentsChange(instrumentsCopy);
    setOpen(false);
  };

  const handleChange = (item, data) => {
    Object.keys(data).forEach(key => item[key] = data[key]);
    _.unset(item, 'errors.no_changes'); // clean no_changes error
    checkSavingsPlanChanged(item);

    const preventDateUpdate = props.isVirtual && item.action === 'edit';
    // if changed but it's not from_date change
    if(!preventDateUpdate && item.is_changed && !item.changes.from_date){
      const nextDate = nextAvailableDate(item.from_date, portfolio, transactionType);
      if(nextDate){
        handleDateChange(item, nextDate, 'from_date');
      }
    }
    onInstrumentsChange([...allInstruments]);
  };

  const handleAmountChange = (item, value) => {
    handleChange(item, {transaction_value: value, errors: {...item.errors, transaction_value: null}});
  };

  const handleDiscountChange = (item, value) => {
    handleChange(item, {discount: value, errors: {...item.errors, discount: null}});
    setDefaultsForNew(item.action, 'discount', value);
  };

  const handleRotationChange = (item, value) => {
    handleChange(item, {rotation: value, errors: {...item.errors, rotation: null}});
    setDefaultsForNew(item.action, 'rotation', value);
  };

  const handleDateChange = (item, value, dateFieldName) => {
    let loading = false;
    // for virtual portfolio when date is changed fetch price of asset at prev date
    if(props.isVirtual && dateFieldName !== 'till_date' && value instanceof moment && value.isValid()){
      loading = true;
      fetchAssetPrice(customer_id, item, moment(value).subtract(1, 'days'), props.dispatch, handleChange)
    }

    handleChange(item, {[dateFieldName]: value, errors: {...item.errors, [dateFieldName]: null}, loading: loading});

    if(!props.isVirtual){
      // don't set default from_date for now -> as we should get it's price
      setDefaultsForNew(item.action, dateFieldName, value);
    }
  };

  const setDefaultsForNew = (action, field, value) => {
    if (action === 'create'){
      updateDefaults({...defaults, [field]: value})
    }
  };

  const handleSwitchPercentageChanged = (item, value, switchOutItem=undefined) => {

    const transactionValuePercentage = _.round(value, 2)
    let getSwitchOutQuantityCallback = () => 0
    if (switchOutItem) {
      let switchOutQuantity = transactionValueToQuantity(
        switchOutItem.transaction_value, switchOutItem, false)
      getSwitchOutQuantityCallback = () => switchOutQuantity
    }
    let transactionValue = percentageToQuantity(
      value, (switchOutItem && mergeSwitchItems(item, switchOutItem, getSwitchOutQuantityCallback)) || item, false)
    let transactionValueEuro = transactionValueToEuro(
      transactionValue, (switchOutItem && mergeSwitchItems(item, switchOutItem)) || item, false)
    transactionValueEuro = transactionValueEuro && _.round(transactionValueEuro, DECIMAL_SCALE)

    handleChange(item, {
      transaction_value: transactionValueEuro,
      calculated: {
        ..._.get(item, 'calculated', {}),
        transaction_value_percentage: transactionValuePercentage,
        transaction_value_euro: transactionValueEuro
      },
      errors: {...item.errors, transaction_value: null, transaction_value_euro: null}
    });

  }

  const handleSwitchAmountEuroChanged = (item, value, switchOutItem=undefined) => {

    const transactionValueEuro = _.round(value, DECIMAL_SCALE)
    let transactionValue = transactionValueToQuantity(
      transactionValueEuro, (switchOutItem && mergeSwitchItems(item, switchOutItem)) || item, false)

    let getSwitchOutQuantityCallback = () => 0
    if (switchOutItem) {
      let switchOutQuantity = transactionValueToQuantity(
        switchOutItem.transaction_value, switchOutItem, false)
      getSwitchOutQuantityCallback = () => switchOutQuantity
    }

    const transactionValuePercentage = transactionValueToPercentage(
      transactionValue, (switchOutItem && mergeSwitchItems(item, switchOutItem, getSwitchOutQuantityCallback)) || item, false)

    handleChange(item, {
      transaction_value: transactionValueEuro,
      calculated: {
        ..._.get(item, 'calculated', {}),
        transaction_value_euro: transactionValueEuro,
        transaction_value_percentage: transactionValuePercentage
      },
      errors: {...item.errors, transaction_value: null, transaction_value_euro: null}
    });
  }

  /**
   * In case saldo value != 0 - distribute saldo quantity between Switch In transactions.
   *
   * @return {(function(): void)|*}
   */
  const handleDistributeQuantitiesClick = (switchOutItem) => () => {

    const precision = portfolio.maxQtyDecimals || QTY_DECIMALS

    const saldo = _.round(calculateSwitchTransactionSaldo(switchOutItem, portfolio.maxQtyDecimals), precision)

    if (saldo == 0 || _.isEmpty(switchOutItem.buy || [])) {
      return
    }


    switchOutItem.buy.reduce((currentSaldoValue, transaction) => {

      // Continue in case all quantities were distributed
      if (currentSaldoValue == 0) {
        return currentSaldoValue
      }

      let transactionValue = _.round(
        (transaction.transaction_value || 0) + currentSaldoValue, precision)

      if (transactionValue < 0) {
        // If Transaction value is negative after distribution
        // (saldo is big negative number) recalculate saldo value
        // and continue distributing for next transaction
        transactionValue = 0
        currentSaldoValue = _.round( currentSaldoValue + transaction.transaction_value || 0, precision)
      } else {
        currentSaldoValue = 0
      }

      handleSwitchAmountEuroChanged(transaction, transactionValue, switchOutItem)

      return currentSaldoValue

    }, saldo)

  }

  const _removeItem = (instrumentsCopy, item) => {
    const idx = findAssetIndex(instrumentsCopy, item.data);

    if(idx !== -1) {
      instrumentsCopy.splice(idx, 1);
      if (portfolio.isModelportfolio) {
        props.dispatch(removePortfolioPaymentPlanOption(customer_id, portfolio.portfolioId));
      } else {
        props.dispatch(removePaymentPlanOption(customer_id, portfolio.portfolioId, getAssetInternalId(item.data)));
      }

      return true;
    }

    return false;
  };

  const _removeFromSwitchOut = (item, switchOut) => {

    switchOut.buy = switchOut.buy.filter(i => i !== item)
    onInstrumentsChange([...allInstruments])

  }

  const _removeAllFromSwitchOut = (switchOut) => {
    switchOut.buy.length = 0
    onInstrumentsChange([...allInstruments])
  }

  const onRemoveClick = (item, switchOutItem=undefined) => {

    if (switchOutItem) {
      _removeFromSwitchOut(item, switchOutItem)
      return
    }

    let instruemntToRemove = allInstruments
      .filter(i => i.action === item.action)
      .filter(i => i._idx === item._idx)

    let instruemntToUse = allInstruments.filter(i => i !== item)

    if(_removeItem(instruemntToRemove, item)){
      onInstrumentsChange([...instruemntToUse]);
    }
  };

  const onRemoveAllClick = (switchOutItem=undefined) => {
    if (switchOutItem) {
      _removeAllFromSwitchOut(switchOutItem);
      return;
    }
    const instruemntToRemove = allInstruments.filter(i => i.action === action)
    const instruemntToUse = allInstruments.filter(i => i.action != action)

    instruemntToRemove.map(i => _removeItem(instruemntToRemove, i))

    onInstrumentsChange(instruemntToUse);
  };


  const handleAddSavingPlanButtonClick = async (action) => {

    if (!portfolio.isModelportfolio || ['delete', 'edit'].includes(action)) {
      setOpen(true)
      return
    }

    const portfolioPrepared = _.cloneDeep(portfolio)
    portfolioPrepared.srri = getTransactionItemSRRI(portfolio)
    const riskScoreConfirmation = transactionType != TRANSACTION_TYPE_VALUES.PAYOUT_PLAN && getRiskScoreConfirmation(dataService, extendAssetsWithDataAttribute([portfolioPrepared]), false, getTransactionItemSRRI)

    if (riskScoreConfirmation && !await modalContext.confirm(riskScoreConfirmation, 'Bitte bestätigen Sie hier')) {
      return false
    }

    const instrumentsCopy = [...allInstruments];

    const instrument = {
      data: {
        ...portfolio.data,
        mp_number: getModelPortfolioNumberFromPortfolio(portfolio),
        factsheet_link: _.get(portfolio, 'data.investment_strategy_data.factsheet_link'),
        components: portfolio.base_components || [],
        isModelportfolio: portfolio.data.is_model_portfolio,
      },
      action,
      transaction_type: TRANSACTION_INSTR.amount,
      broker_fee: portfolio.broker_fee,
      ...defaults
    };

    if (action == 'create') {
      instrument.isin = + new Date() // For now portfolio id is used, as we assume, that only one sparplan could be added at the same time
    }

    instrumentsCopy.push(instrument)

    props.dispatch(addPaymentPlanOption({customer_id: customer_id}, portfolio.data, instrument.data, action, tradingType))
    onInstrumentsChange(instrumentsCopy);

  }

  const handleConfirmRiskModalSave = (item, value) => {
    handleChange(item, {
      risk_score_explanation: value,
      errors: {...item.errors, risk_score_explanation: null}});
    setConfirmRiskModalState({
      confirmRiskModalOpen: false,
      confirmRiskModalActiveAsset: undefined
    })
  }

  const handleSelectAsset = (asset) => {
    setConfirmRiskModalState({
      confirmRiskModalOpen: true,
      confirmRiskModalActiveAsset: asset
    })
  }

  const isEnableAdding = () => {
    let isCreate = _.some(allInstruments, {'action': 'create'})
    let isEdit = _.some(allInstruments, {'action': 'edit'})
    let isDelete = _.some(allInstruments, {'action': 'delete'})

    switch (action) {
      case 'create':
        return portfolio.isModelportfolio ? !isEdit && !isDelete : true
      case 'edit':
        return portfolio.isModelportfolio ? !isCreate && !isDelete : true
      case 'delete':
        return portfolio.isModelportfolio ? !isCreate && !isEdit : true
    }
  }

  const isAnlageberatung = dataService.serviceConcept == SERVICE_CONCEPTS.Anlageberatung

  return (
    <RiskScoreConfirmationModalContext.Provider
      value={{
        open: confirmRiskModalState.confirmRiskModalOpen,
        activeAsset: confirmRiskModalState.confirmRiskModalActiveAsset,
        onSave: handleConfirmRiskModalSave,
        onSelectAsset: handleSelectAsset,
        singleInvestmentEnabled: _.isEmpty(portfolio.transactions.savings_plan)
      }}
    >
      {props.children({
        isAnlageberatung,
        isEnableAdding,
        handleAddSavingPlanButtonClick,
        onRemoveClick,
        onRemoveAllClick,
        handleAmountChange,
        handleDiscountChange,
        handleRotationChange,
        handleDateChange,
        classes,
        setOpen,
        open,
        handleAddItems,
        indexedGroupInstruments,
        handleSwitchAmountEuroChanged,
        handleSwitchPercentageChanged,
        handleDistributeQuantitiesClick,
        instrumentsHandlers,
        loading,
      })}
      <RiskScoreConfirmationModal />
    </RiskScoreConfirmationModalContext.Provider>
  )
}

export default connect()(PaymentPlanEditTable)