import React from 'react';

/* Material-UI Components */
import Grid from '@material-ui/core/Grid';

/* BCA modules*/
import useStyles from './styles';
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import ScrollMenu from "react-horizontal-scrolling-menu";
import _ from 'lodash';
import WarningIcon from "../../../../components/Icons/WarningIcon";


export default (props) => {
  const {
    title,
    menuItems,
    currMenu,
    onDeleteProcessClick,
    isFinished,
    answersData,
    navigateToPrevMenu,
  } = props;

  const classes = useStyles();

  const MenuItem = ({item, idx}) => {
    const isActive = currMenu === idx;
    const isDone = item.isDone; // function so better call it once
    const hasMissingInfo = _.has(answersData, item.uid) && item.getHasMissingAnswers(_.keys(answersData[item.uid]));
    const goToMenu = !!navigateToPrevMenu && currMenu > idx ? () => navigateToPrevMenu(item.uid) : undefined;

    return (
      <div className={clsx(classes.menuItem, isActive && 'active', isDone && 'done', !!goToMenu && 'clickable')} onClick={goToMenu}>
        {hasMissingInfo
          ? <WarningIcon className={classes.warningIcon} />
          : isDone && (<i className={'fa fa-check-circle'} />)
        }
        {item.name}
        {isActive && <i className={clsx('fa fa-lg fa-angle-up', hasMissingInfo && 'has-missing-info')} />}
      </div>
    )
  };

  return (
    <React.Fragment>
      <Grid container wrap={"nowrap"} className={classes.topContainer} alignItems={'center'}>
        <Grid item className={classes.header}>
          {title}
          {_.isFunction(onDeleteProcessClick) && !isFinished &&
            <IconButton className={classes.deleteBtn} color={"primary"} aria-label="close" onClick={onDeleteProcessClick}>
              <i className={"far fa-trash-alt"} />
            </IconButton>
          }
        </Grid>
        <Grid item className={classes.menuContainer}>
          <ScrollMenu
            alignCenter={false}
            wheel={false}
            scrollToSelected={true}
            hideSingleArrow={true}
            arrowLeft={<div>{"<"}</div>}
            arrowRight={<div>{">"}</div>}
            dragging={false}
            selected={menuItems && menuItems[currMenu] && menuItems[currMenu].uid}
            data={menuItems && menuItems.map((item, idx) => <MenuItem key={item.uid} item={item} idx={idx} />)}
          />
        </Grid>
        {/*{menuItems && (currMenu !== menuItems.length - 1) && (*/}
        {/*  <Grid item className={classes.buttonContainer}>*/}
        {/*    <IconButton aria-label="close"  onClick={() => {}}>*/}
        {/*        <CloseIcon className={classes.buttonCircle}/>*/}
        {/*    </IconButton>*/}
        {/*    Schließen und später weitermachen*/}
        {/*  </Grid>*/}
        {/*)}*/}
      </Grid>
    </React.Fragment>
  )
}