import {Service} from "../../RiskProfiling/service";
import {TRADING_TYPE} from "../../../components/TradingStore/constants";


export class TradeMenuItem extends Service {
  constructor(uid, name, customer_id, questionnaire, getPrevStepAnswer) {
    super(uid, name, '', '', getPrevStepAnswer);
    this._questionnaire = this.__deepCopy(questionnaire);
    this._customer_id = customer_id;
    this._trading_type = TRADING_TYPE.NORMAL
  }

  get isDone(){
    return this._is_finished;
  }

  get tradingType() {
    return this._trading_type || TRADING_TYPE.NORMAL
  }

  set tradingType(type) {
    if ([TRADING_TYPE.NORMAL, TRADING_TYPE.NEW_BUY].includes(type)) {
      this._trading_type = type
    }
  }

  async __setQuestionnaire(defaultAnswers){
    await super.__setQuestionnaire(defaultAnswers);
    this._setDefaultAnswers(defaultAnswers);
  }

  async __sendStepData() {
    await this._validateStepFunc()
    await super.__sendStepData() // store answers in our db
  }

  setQuestionData(step_uid, question_uid, data) {
    if(this._questionnaire && this._questionnaire.steps){
      const step = this._questionnaire.steps.find(s => s.uid === step_uid);
      if(step){
        const question = step.question.find(q => q.uid === question_uid);
        if(question) {
          Object.keys(data).forEach(key => {
            question[key] = data[key];
          })
        }
      }
    }
  }
}