import _ from "lodash";
import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";

import styles from "../../styles";


export default withStyles(styles)(({name, classes}) => {
  const withAsterisk = _.endsWith(name, '*');

  return withAsterisk ? <>{name.substring(0, name.length - 1)}<span className={classes.asterisk}>*</span></> : name;
})