import React from "react";
import _ from "lodash";
import {parseResponse} from "../../../utils/api";
import {generateQueryParamsHash, getPtfTrackingStartDate} from "../utils";
import {useTabsSharedDataContext} from "../components_v2/TabsSharedData/TabsSharedData";

const SHARED_DATA_KEY ='correlation-matrix-data'

/**
 * Fetch portfolio risk return and correlation matrix data.
 * @param customerId Customer identifier
 * @param portfolio Portfolio data
 * @param dataProvider Data provider
 * Set to true in case hook wrapped in context provider that already fetch data and it could be reused.
 * @param startDate
 * @param endDate
 */
export function useCorrelationMatrixData(customerId, portfolio, dataProvider, startDate, endDate) {

  const [data, setData] = React.useState({
    correlationData: undefined,
    correlationLoading: true,
    correlationError: null,
    updatedAt: +new Date(),
  });

  const paramsForHash = [startDate, endDate];
  const sharedData = useTabsSharedDataContext();
  const dataKey = `${SHARED_DATA_KEY}-${portfolio.depotNumber}`;

  const fetch = () => {

    const hashKey = generateQueryParamsHash(customerId, [portfolio], ...paramsForHash);

    const _setData = (state) => {
      // It is not possible to sync data with shared data in
      // hook because hook is not executed if component destroyed
      setData(state);
      sharedData.setDataValue(dataKey, hashKey, state);
    };

    _setData({
      correlationData: undefined,
      correlationLoading: true,
      correlationError: null,
      updatedAt: +new Date(),
    });
    dataProvider.getCorrelation(customerId, portfolio.depotNumberForAssets || portfolio.depotNumber, startDate || getPtfTrackingStartDate(portfolio), endDate)
      .then((response) => {
        parseResponse(response, 'correlation_matrix',
          (data) => {
            _setData({
              correlationError: undefined,
              correlationData: data,
              correlationLoading: false,
              updatedAt: +new Date()
            })
          },
          (error) => {
            _setData({
              correlationData: undefined,
              correlationLoading: false,
              correlationError: error,
              updatedAt: +new Date()
            })
          })
      })
      .catch((error) => {
        _setData({
          correlationData: undefined,
          correlationLoading: false,
          correlationError: error.toString(),
          updatedAt: +new Date()
        })
      })
  };

  React.useEffect(() => {
    if (!!customerId && !!portfolio) {
      const sharedRiskReturnData = !!sharedData
        ? sharedData.getDataValue(dataKey, generateQueryParamsHash(customerId, [portfolio], ...paramsForHash))
        : undefined;

      if (!!sharedRiskReturnData && !sharedRiskReturnData.errors) {
        // sync internal hook data with shared data.
        setData(sharedRiskReturnData);
        return;
      }

      // fetch data if there is no shared data or shared data was loaded with error
      // check if data fetched for the first time (updatedAt is undefined) to prevent infinite data reloading
      // in case of data loading error, so data will be requested again only if tab changed
      if (!data.updatedAt || !sharedRiskReturnData) {
        fetch();
      }
    }
  }, [customerId, _.get(portfolio, 'depotNumberForAssets'), _.get(portfolio, 'depotNumber'), startDate, endDate]);

  return data

}