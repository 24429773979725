import React from 'react'
import _, {get} from "lodash";
import moment from "moment";
import {
  ORDERING_AVAILABLE_OPTIONS_FIELD_NAMES,
  TRANSACTION_INSTR,
  TRANSACTION_INSTR_ORDERING_FIELD_NAMES,
  TRANSACTION_START_DATE,
  TRANSACTION_TYPE_VALUES,
  DEPOT_TYPE_USAGE_AREA,
  ORDERING_MP_AVAILABLE_OPTIONS_FIELD_NAMES,
  MODEL_PORTFOLIO_TRANSACTION_TYPE
} from "./constants";
import {
  TRADING_ACTION_DEFAULT,
  TRADING_ACTION_SWITCH,
  TRADING_ACTION_SWITCH_PLAN,
  TRADING_ACTIONS,
} from "../../components/Charts/InstrumentsAllocationTable/constants";
import {BankSettingsHandlerResource, parseResponse, VirtualPortfolioHandlerResource} from "../../utils/api";
import {checkCriterias, InstrumentsHandler, getInstrName, getInstrumentId} from "../Modelportfolios/utils";
import {
  calculateInstrumentsAverageSRRI,
  getBankData,
  getDiscountsOptions,
  getListOptionsFromBankData,
  throwErrorMsg
} from "../RiskProfiling/utils";
import {
  getClearingAccountNumber,
  getIdentifierByTypeId,
  getIdentifierCodeByTypeId
} from "../../components/CustomersDataProvider/utils";
import {PORTFOLIO_DEPOT_TYPE_IDENTIFIER} from "../../components/CustomersDataProvider/constants";
import {getErrorMessage, QTY_DECIMALS} from "../../utils/utils";
import {getFromStorage, SHARED_SETTINGS_KEY} from "../../utils/storage";
import {displayWarningSnackBar} from "../../components/SnackbarProvider/actions";
import { isPaymentPlanType } from '../../components/TradingStore/utils';
import { getAssetInternalIdForNewPaymentPlan } from '../CustomerDashboard/utils';

export const getAmount = (item, transaction_value, switchOutItem=null) => {
  let amount = transaction_value || item.transaction_value || 0;
  if(item.transaction_type === TRANSACTION_INSTR.qty){
    // In case we have Switch Out item, we should take price of it
    amount = amount * ((switchOutItem ? switchOutItem.data.price_eur : item.data.price_eur) || 0);
  }

  return amount;
};

export const totalTransactionAmount = (items) => {
 return (items || []).reduce((accumulator, item) => {
    return item.action && item.action == 'delete' ? accumulator : getAmount(item) + accumulator;
  }, 0);
};

export const portfolioTransactionAmount = (portfolio, transaction) => {

  if (transaction === TRANSACTION_TYPE_VALUES.SWITCH_PLAN) return 0;

  let instruments = portfolio.transactions[transaction];

  let amount = totalTransactionAmount(instruments);
  if([TRANSACTION_TYPE_VALUES.SELL, TRANSACTION_TYPE_VALUES.PAYOUT_PLAN].includes(transaction)) amount = -amount;

  return amount;
};

export const getTransactionValueByType = (item, transactionType) => {
  const transactionValue = item.transaction_value;
  return transactionType === TRANSACTION_INSTR.qty ? transactionValueToQuantity(transactionValue, item) : transactionValueToEuro(transactionValue, item);;
};

export const getTradingOption = (value) => {
  return TRADING_ACTIONS.find(o => o.value === value);
};

export const setInstrumentBankData = (instr, bankInfo, isInstrumentInfo=false) => {
  const info = isInstrumentInfo ? bankInfo : _.get(bankInfo, instr.data && instr.data.isin || instr.isin);
  if(info){
    instr.max_discount = info.c_front_end_load_max_discount;
    instr.broker_fee = info.c_front_end_load_percent;
    instr.rebate_percent = info.c_rebate_percent;
    instr.min_order_amount = info.c_min_amount_single_payment;
    instr.min_amount_savings_plan = info.c_min_amount_savings_plan;
    instr.min_amount_payout_plan = info.c_min_amount_payout_plan;
    if (instr.data && _.isEmpty(instr.data.custodian_data)) {
      instr.data.custodian_data = {[info.c_company_id]: info};
    }
  }
  if(_.has(instr, 'data.calculated.last_price_value' && _.has(instr, 'data.calculated.last_price_date'))){
    _.set(instr, 'data.price_eur', instr.data.calculated.last_price_value);
    _.set(instr, 'data.price_date', instr.data.calculated.last_price_date);
  }
};

export const getBanksCompanyIdToCodeMapping = async () => {
  const banksData = await BankSettingsHandlerResource.getBanksData();
  let banksMapping = {};
  banksData.forEach(bank => {
    if(bank.custodian_id && bank.code){
      banksMapping[bank.custodian_id] = bank;
    }
  });
  return banksMapping;
};

/**
 *  Generate object of portfolios ex ante costs from list of ex ante.
 *
 *  @param {Object[]} portfolios List of portfolios
 *
 *  @param isOnboarding
 *  @return {Object} Aggregated object of portfolios data
 * */
export const aggregatePortfoliosExAnte = (portfolios, isOnboarding=false) => {

  let result = {}
  let errors = {};

  portfolios.forEach(portfolio => {

    if (!portfolio) {
      return
    }

    if(isOnboarding) {
      errors = portfolio.response.errors;
    } else {
      try {
        throwErrorMsg(portfolio.response.errors);
      } catch (e) {
        errors[portfolio.portfolioName || portfolio.bankCode] = ["\n" + e.message];
        return;
      }
    }

    if (!result.hasOwnProperty(portfolio.portfolioId)) {
      result[portfolio.portfolioId] = portfolio.hasOwnProperty('objectType') ?
        {[portfolio.objectType]: {buy: {}, sell: {}}} : {buy: {}, sell: {}}
    } else {
      if (!result[portfolio.portfolioId].hasOwnProperty(portfolio.objectType)) {
        result[portfolio.portfolioId][portfolio.objectType] = {buy: {}, sell: {}}
      }
    }

    if(isOnboarding) {
      if (!result[portfolio.portfolioId].buy.hasOwnProperty(portfolio.simulationYears)) {
        result[portfolio.portfolioId].buy[portfolio.simulationYears] = portfolio.response[Object.keys(portfolio.response)[0]]
      }
    } else {
      Object.keys(result[portfolio.portfolioId][portfolio.objectType]).map(field => {
        if (!result[portfolio.portfolioId][portfolio.objectType][field].hasOwnProperty(portfolio.simulationYears)) {
          let exAnte = portfolio.response[Object.keys(portfolio.response)[0]]

          if (exAnte && exAnte.hasOwnProperty(field)){
            result[portfolio.portfolioId][portfolio.objectType][field][portfolio.simulationYears] = exAnte[field];
          }
        }
      })
    }
  })

  throwErrorMsg(errors, isOnboarding); // do not capTitle for Tradings

  return result
}


export const getExAnteSimulationYearData = (exAnte, simulationYears) => {
  if (!exAnte.hasOwnProperty(simulationYears)) {
    return {}
  }

  return exAnte[simulationYears]
}


export const getExAnteSpecificYearData = (exAnte, simulationYears, year) => {
  let yearData = getExAnteSimulationYearData(exAnte, simulationYears)

  if (_.isEmpty(yearData)) {
    return {}
  }

  let { yearly } = yearData

  if (!yearly) {
    return {}
  }

  for (let index = 0; index < yearly.length; index++) {
    if (yearly[index].year === year) {
      return yearly[index]
    }
  }

  return {}
}

export const getBreakDown = (exAnte, breakDownName) => {
  return (exAnte && exAnte.breakdown || []).find(b => b.name === breakDownName);
};

export const aggregateExAnteYearlyCostValue = (exAnte, simulationYears) => {
  let yearData = getExAnteSimulationYearData(exAnte, simulationYears)

  if (_.isEmpty(yearData)) {
    return 0
  }

  let { yearly } = yearData
  if (!yearly) {
    return 0
  }

  return yearly.reduce((value, year) =>  {
    value += parseFloat(get(year, 'cost.cost_value', 0))
    return value
  }, 0)

}

export class FundsInstrumentsHandler extends InstrumentsHandler {
  filterInstruments(instruments) {
    let instrumentsToAccept = [], instrumentsToExclude = [];

    (instruments || []).forEach(instrument => {

      let acceptanceCriterias = {
        "general": {
          "only_funds": {
            "title": "Nur Fonds",
            "value": instrument.is_fund
          }
        }
      }

      checkCriterias(instrument, acceptanceCriterias, instrumentsToAccept, instrumentsToExclude)
    });
    return [instrumentsToAccept, instrumentsToExclude]
  }

  // TODO: refactor it -> custodian data now is part of asset info so filterInstruments
  getCustodianAndFilterInstruments(instruments) {
    return this.filterInstruments(instruments);
  }
}

export const getNumberValue = (value, decimals=2) => {
  value = parseFloat(value);
  return _.isNaN(value) ? '' : decimals ? +value.toFixed(decimals) : value;
};

export const getBookingDate = (item) => {
  let bookingDate;

  if(item.deposit_from_type === TRANSACTION_START_DATE.date) {
    bookingDate = item.deposit_from_date && moment(item.deposit_from_date).format('YYYY-MM-DD')
  }

  return bookingDate;
};

export const getPortfolioExAnteCost = (portfolioExAnte) => {
  if(!portfolioExAnte) return 0;

  return ['buy', 'sell'].reduce((result, field) => {
    const transactionExAnte = portfolioExAnte[field];
    if(transactionExAnte){
      result += aggregateExAnteYearlyCostValue(transactionExAnte, 5)
      return result;
    }
  }, 0);
}

const findInstr = (list, instr) => {
  return (list || []).find(i => i.data.isin == instr.data.isin);
}

export const buildPortfolioTradeTransactions = (transactionsQuestion, availableTransactionTypes=[]) => {
  const portfoliosFromAnswer = transactionsQuestion.answer || [];

  transactionsQuestion.isTransactionsBuilt = true;
  return transactionsQuestion.portfolios.map(portfolio => {
    // get portfolio from existing answer or from storage
    let portfolioData = portfoliosFromAnswer.find(p => p.portfolioId === portfolio.portfolioId);
    const prevTransactionsFromDB = portfolioData && portfolioData.transactions || {};
    const prevTransactions = portfolio.transactions || {};

    let transactions = {};
    availableTransactionTypes.map(t => transactions[t] = []);
    const bankInstrumentsData = transactionsQuestion.bankInstrumentsData && transactionsQuestion.bankInstrumentsData[portfolio.companyId];

    // We could have only one transaction for portfolio for buy, sell and switch
    // As it is possible to have many savings plans entries  for model portfolio,
    // we handle them similar to individual instruments
    if (portfolio.isModelportfolio) {
      delete transactions.rebalancing
    }

    // in case we trade MP itself it should has tradingType
    if(portfolio.isModelportfolio && !!portfolio.tradingType){
      const transactionType = portfolio.tradingType;
      if (transactions.hasOwnProperty(transactionType)){
        // we have 1 transaction for modelportfolio
        const prevData = (prevTransactions[transactionType] || [])[0] || (prevTransactionsFromDB[transactionType] || [])[0];
        transactions[transactionType].push({...prevData, data: {...portfolio.data}});
      }
    }

    // MP payment plans are instruments as well
    (portfolio.instruments || []).map(instr => {
      const transactionType = _.get(getTradingOption(instr.tradingType), 'code');
      if (transactions.hasOwnProperty(transactionType)){
        const prevData = findInstr(prevTransactions[transactionType], instr) || findInstr(prevTransactionsFromDB[transactionType], instr);
        setInstrumentBankData(instr, bankInstrumentsData);
        if (transactionType === TRANSACTION_TYPE_VALUES.SWITCH_PLAN) {
          (instr.data.components || []).forEach((switchInPlan) => {
            setInstrumentBankData(switchInPlan, bankInstrumentsData);
          })
        }
        const data = {...prevData, ...instr};
        if(transactionType === 'switch'){
          // Quantity is only one enabled option for switch
          data.transaction_type = TRANSACTION_INSTR.qty
          if(!data.hasOwnProperty('buy'))
            data.buy = []; // instruments to switchIn (buy)
        }

        transactions[transactionType].push(data);
      }
    })

    if (portfolio.isModelportfolio) {
      // Update instruments of model portfolio with custodian data
      // to make it possible to use this data in ex ante
      ['components',  'base_components'].forEach((key) => {

        if(_.get(portfolio, `data.${key}`)) {

          portfolio.data[key] = portfolio.data[key]
            .map((instrument) => {
              // Duplicate instrument info to 'data' to use setInstrumentBankData
              const instrumentCopy = {...instrument}
              let extendedWithData = false
              if (!instrumentCopy.hasOwnProperty('data')) {
                instrumentCopy['data'] = {...instrumentCopy}
                extendedWithData = true
              }

              setInstrumentBankData(instrumentCopy, bankInstrumentsData)

              if (extendedWithData) {
                delete instrumentCopy.data
              }

              return instrumentCopy
            })
        }

      })
    }

    portfolioData = portfolioData || portfolio;
    portfolio.transactions = transactions;
    portfolio.depot_fee = portfolioData.depot_fee;
    portfolio.discount = portfolioData.discount;

    return portfolio;
  });
};

/**
 * Recalculate transaction value for transaction according to the transaction settings.
 *
 * @param {Object} transaction Transaction data
 * @param {Object|undefined} targetTransaction Transaction, that should be used for settings usage.
 *                                             Required in case Switch In item should be recalculated.
 *                                             In this case Switch Out item should be used as a targetTransaction
 * @private
 */
const validateTransaction = (transaction, targetTransaction=undefined, decimals=QTY_DECIMALS) => {

  const sourceTransaction = (!targetTransaction && transaction) || mergeSwitchItems(transaction, targetTransaction)

  if (targetTransaction && targetTransaction.sell_all && transaction.transaction_type != TRANSACTION_INSTR.qty) {
    transaction.transaction_value = percentageToQuantity(transaction.transaction_value, sourceTransaction)
  } else if (transaction.transaction_type == TRANSACTION_INSTR.amount) {
    transaction.transaction_value = transactionValueToQuantity(transaction.transaction_value, sourceTransaction)
  }

  transaction.transaction_value = _.floor(transaction.transaction_value, decimals)

  if (!transaction.calculated) {

    const transactionValueEuro = transactionValueToEuro(
      transaction.transaction_value, (targetTransaction && mergeSwitchItems(transaction, targetTransaction)) || transaction)
    const transactionValuePercentage = transactionValueToPercentage(
      transaction.transaction_value, (targetTransaction && mergeSwitchItems(transaction, targetTransaction)) || transaction)

    transaction.calculated = {
      "transaction_value_euro": transactionValueEuro,
      "transaction_value_percentage": transactionValuePercentage
    }

  }

  // making "quantity" as a default transaction type for switch
  transaction.transaction_type = TRANSACTION_INSTR.qty
}


const validateSwitchPlanTransaction = (transaction, targetTransaction=undefined, decimals=QTY_DECIMALS) => {

  let sourceTransaction = transaction;
  if (targetTransaction) {
    let switchOutQuantity = transactionValueToQuantity(
      targetTransaction.transaction_value, targetTransaction, false)
    switchOutQuantity = switchOutQuantity && _.floor(switchOutQuantity, decimals)
    const getSwitchOutQuantityCallback = (switchOut) => switchOutQuantity
    sourceTransaction = mergeSwitchItems(transaction, targetTransaction, getSwitchOutQuantityCallback)
  }

  if (!transaction.calculated) {

    let transactionValueQuantity = transactionValueToQuantity(
      transaction.transaction_value, sourceTransaction, false)
    transactionValueQuantity = transactionValueQuantity && _.floor(transactionValueQuantity, decimals)
    const transactionValuePercentage = transactionValueToPercentage(
      transactionValueQuantity, sourceTransaction, false)

    transaction.calculated = {
      "transaction_value_euro": transaction.transaction_value,
      "transaction_value_percentage": transactionValuePercentage
    }
  }

}


/**
 * Function to validate portfolio, that we get from db.
 * Should use to handle old portfolios answers in case new changes was applied to the process.
 *
 * @param {Object} portfolio Portfolio data
 * @param {Array<'switch'|'switch_plan'>} transactions
 */
export const validatePortfolio = (portfolio, transactions) => {

  const validators = {
    "switch": validateTransaction,
    "switch_plan": validateSwitchPlanTransaction
  }

  for (let transactionType of transactions) {
    if (portfolio.transactions.hasOwnProperty(transactionType) && !_.isEmpty(portfolio.transactions[transactionType])) {
      portfolio.transactions[transactionType].forEach((transaction) => {
        validators[transactionType](transaction, undefined, portfolio.maxQtyDecimals)
        if (transaction.hasOwnProperty('buy') && !_.isEmpty(transaction.buy)) {
          transaction.buy.forEach((switchIn) => {
            validators[transactionType](switchIn, transaction, portfolio.maxQtyDecimals)
          })
        }
      })
    }
  }

}

export const setMPInstrumentBankData = (instr, portfolio) => {
  instr.broker_fee = portfolio.broker_fee;
  instr.max_discount = portfolio.max_discount;
}

export const setPortfoliosConfig = (portfolios, banksData, banksDataDepotTypes=undefined) => {
  portfolios.map(portfolio => {

    portfolio.availableDiscounts = getDiscountsOptions(
        banksData, portfolio.companyId);
    portfolio.availableSavingsPlanPeriodicPlans = getListOptionsFromBankData(
        banksData, portfolio.companyId);
    portfolio.availableSavingsPlanExecutionDates = getListOptionsFromBankData(
        banksData, portfolio.companyId, 'savings_plan_execution_dates');
    portfolio.availableMPSavingsPlanPeriodicPlans = getListOptionsFromBankData(
      banksData, portfolio.companyId, 'mp_savings_plan_periodic_plans');
    portfolio.availableMPSavingsPlanExecutionDates = getListOptionsFromBankData(
      banksData, portfolio.companyId, 'mp_savings_plan_execution_dates');

    portfolio.availablePayoutPlanPeriodicPlans = getListOptionsFromBankData(
      banksData, portfolio.companyId, 'payout_plan_periodic_plans');
    portfolio.availablePayoutPlanExecutionDates = getListOptionsFromBankData(
      banksData, portfolio.companyId, 'payout_plan_execution_dates');
    portfolio.availableMPPayoutPlanPeriodicPlans = getListOptionsFromBankData(
      banksData, portfolio.companyId, 'mp_payout_plan_periodic_plans');
    portfolio.availableMPPayoutPlanExecutionDates = getListOptionsFromBankData(
      banksData, portfolio.companyId, 'mp_payout_plan_execution_dates');

    portfolio.availableSwitchPlanPeriodicPlans = getListOptionsFromBankData(
      banksData, portfolio.companyId, 'switch_plan_periodic_plans');
    portfolio.availableSwitchPlanExecutionDates = getListOptionsFromBankData(
      banksData, portfolio.companyId, 'switch_plan_execution_dates');
    portfolio.availableMPSwitchPlanPeriodicPlans = getListOptionsFromBankData(
      banksData, portfolio.companyId, 'mp_switch_plan_periodic_plans');
    portfolio.availableMPSwitchPlanExecutionDates = getListOptionsFromBankData(
      banksData, portfolio.companyId, 'mp_switch_plan_execution_dates');
    portfolio.ordering_switch_plan_min_amount_payment = getBankData(
      banksData, portfolio.companyId, 'ordering_switch_plan_min_amount_payment');

    portfolio[ORDERING_AVAILABLE_OPTIONS_FIELD_NAMES[TRANSACTION_TYPE_VALUES.BUY]] = getBankData(
      banksData, portfolio.companyId, 'ordering_buy_quotation_fields') || [];
    portfolio[ORDERING_AVAILABLE_OPTIONS_FIELD_NAMES[TRANSACTION_TYPE_VALUES.SELL]] = getBankData(
      banksData, portfolio.companyId, 'ordering_sell_quotation_fields') || [];
    portfolio[ORDERING_AVAILABLE_OPTIONS_FIELD_NAMES[TRANSACTION_TYPE_VALUES.SWITCH]] = getBankData(
      banksData, portfolio.companyId, 'ordering_switch_quotation_fields') || [];
    portfolio.custodianName = getBankData(
        banksData, portfolio.companyId, 'custodian_name');

    // Configuration for Model portfolio ordering
    portfolio[ORDERING_MP_AVAILABLE_OPTIONS_FIELD_NAMES[TRANSACTION_TYPE_VALUES.BUY]] = getBankData(
      banksData, portfolio.companyId, 'ordering_mp_buy_quotation_fields') || [];
    portfolio[ORDERING_MP_AVAILABLE_OPTIONS_FIELD_NAMES[TRANSACTION_TYPE_VALUES.SELL]] = getBankData(
      banksData, portfolio.companyId, 'ordering_mp_sell_quotation_fields') || [];
    portfolio[ORDERING_MP_AVAILABLE_OPTIONS_FIELD_NAMES[TRANSACTION_TYPE_VALUES.SWITCH]] = getBankData(
      banksData, portfolio.companyId, 'ordering_mp_switch_quotation_fields') || [];

    portfolio.ordering_savings_plan_min_amount_payment = getBankData(
        banksData, portfolio.companyId, 'ordering_savings_plan_min_amount_payment');
    portfolio.ordering_mp_savings_plan_min_amount_payment = getBankData(
      banksData, portfolio.companyId, 'ordering_mp_savings_plan_min_amount_payment');
    portfolio.ordering_payout_plan_min_amount_payment = getBankData(
      banksData, portfolio.companyId, 'ordering_payout_plan_min_amount_payment');
    portfolio.ordering_mp_payout_plan_min_amount_payment = getBankData(
      banksData, portfolio.companyId, 'ordering_mp_payout_plan_min_amount_payment');
    portfolio.onboarding_savings_plan_min_amount_payment = getBankData(
      banksData, portfolio.companyId, 'onboarding_savings_plan_min_amount_payment');
    portfolio.ordering_min_amount_payment = getBankData(
        banksData, portfolio.companyId, 'ordering_min_amount_payment');
    portfolio.ordering_switch_plan_out_min_amount_payment = getBankData(
      banksData, portfolio.companyId, 'ordering_switch_plan_out_min_amount_payment');
    portfolio.ordering_switch_plan_in_skip_min_amount_payment_validation = getBankData(
      banksData, portfolio.companyId, 'ordering_switch_plan_in_skip_min_amount_payment_validation');
    portfolio.onboarding_min_amount_payment = getBankData(
      banksData, portfolio.companyId, 'onboarding_min_amount_payment');
    portfolio.maxQtyDecimals = getBankData(
        banksData, portfolio.companyId, 'max_qty_decimals');
    portfolio.ordering_savings_plan_till_date_enabled = getBankData(
      banksData, portfolio.companyId, 'ordering_savings_plan_till_date_enabled');
    portfolio.ordering_payout_plan_till_date_enabled = getBankData(
      banksData, portfolio.companyId, 'ordering_payout_plan_till_date_enabled');
    portfolio.ordering_switch_plan_till_date_enabled = getBankData(
      banksData, portfolio.companyId, 'ordering_switch_plan_till_date_enabled');
    portfolio.onboarding_savings_plan_till_date_enabled = getBankData(
      banksData, portfolio.companyId, 'onboarding_savings_plan_till_date_enabled');
    portfolio.is_use_product_id = getBankData(
      banksData, portfolio.companyId, 'is_use_product_id');
    portfolio.stock_exchange_options = getBankData(
        banksData, portfolio.companyId, 'stock_exchange_options');
    portfolio.available_asset_classes = getBankData(
        banksData, portfolio.companyId, 'available_asset_classes');
    portfolio.depot_available_asset_classes = portfolio.available_asset_classes;
    portfolio.onboarding_discounts_allowed = getBankData(
      banksData, portfolio.companyId, 'onboarding_discounts_allowed') || [];
    portfolio.ordering_discounts_allowed = getBankData(
      banksData, portfolio.companyId, 'ordering_discounts_allowed') || [];
    portfolio.payment_plan_keep_day = getBankData(
      banksData, portfolio.companyId, 'payment_plan_keep_day');
    portfolio.payment_plan_till_month = getBankData(
      banksData, portfolio.companyId, 'payment_plan_till_month');
    portfolio.einzeltitel_order_type_buy = getBankData(
      banksData, portfolio.companyId, 'einzeltitel_order_type_buy');
    portfolio.einzeltitel_order_type_sell = getBankData(
      banksData, portfolio.companyId, 'einzeltitel_order_type_sell');
    portfolio.convert_sub_depot_nr_to_int = getBankData(
      banksData, portfolio.companyId, 'convert_sub_depot_nr_to_int');
    portfolio.usePercentageForSwitchAll = getBankData(
      banksData, portfolio.companyId, 'use_percentage_for_switch_all');
    portfolio.portfolioBuilderAvailable = getBankData(
      banksData, portfolio.companyId, 'ordering_portfolio_builder_allowed');

    // Refactor this, as in case it is investment strategy it also is model portfolio
    if (_.get(portfolio, 'data.is_model_portfolio')) {

      portfolio.broker_fee = _.get(portfolio, 'data.is_private_investment')
        ? parseFloat(_.get(portfolio, 'data.investment_strategy_data.cost_brutto') || 0)
        : parseFloat(_.get(portfolio, 'data.model_portfolio_data.fee_brutto') || 0);
      portfolio.max_discount = getBankData(
        banksData, portfolio.companyId, 'ordering_mp_max_discount') || 0;

      for (let transactionType in portfolio.transactions) {
        if (portfolio.transactions[transactionType] && portfolio.transactions[transactionType].length) {
          portfolio.transactions[transactionType].forEach((transaction) => {
            setMPInstrumentBankData(transaction, portfolio);
          })

        }
      }
    }

    if (banksDataDepotTypes) {

      const portfolioDepotTypeIdentifier = getIdentifierByTypeId(_.get(portfolio, 'data.identifiers', []), PORTFOLIO_DEPOT_TYPE_IDENTIFIER)

      portfolio.availableDepotTypes = _getPortfolioAvailableContracts(
        banksDataDepotTypes, portfolio.companyId, DEPOT_TYPE_USAGE_AREA.TRADING)

      if (!portfolio.depotType) {
        portfolio.depotType = _getPortfolioDefaultContract(
          banksDataDepotTypes, DEPOT_TYPE_USAGE_AREA.TRADING, portfolio.companyId,
          portfolioDepotTypeIdentifier && portfolioDepotTypeIdentifier.code)
      }
    }

  });
};

/**
 * Get list of contracts for specific bank using company id.
 *
 * @param {Array<Object>} contracts List of banks with contracts configurations.
 * @param {String|Number} companyId Company id
 * @private
 */
const _getContractsByCompanyId = (contracts, companyId) => {

  const bankData = _.find(contracts, (bank) => bank.bankCompanyId == companyId)

  return _.get(bankData, 'contract_types')

}

/**
 * Filter contracts by usage area.
 *
 * @param {Array<Object>} contracts List of contracts (depot types)
 * @param {DEPOT_TYPE_USAGE_AREA.TRADING|DEPOT_TYPE_USAGE_AREA.ONBOARDING} usageArea Area of contract usage
 * @return {Array<Object>}
 * @private
 */
const _filterContractsByUsageArea = (contracts, usageArea) => {

  return _.filter(contracts, (contract) => contract.usage_areas.includes(usageArea))

}

/**
 * Filter contracts by usage area.
 *
 * @param {Array<Object>} contracts List of contracts (depot types)
 * @param {String} code Depot Type code
 * @private
 */
const _filterContractsByDepotTypeCode = (contracts, code) => {

  return _.filter(contracts, (contract) => {
    return !!_.find(contract.depot_type_codes, (dCode) => dCode.toLowerCase() === code.toLowerCase())
  })

}

/**
 * Get list of available contracts for portfolio.
 *
 * @param {Array<Object>} contracts List of banks with contracts configurations.
 * @param {String|Number} companyId Company id
 * @param {DEPOT_TYPE_USAGE_AREA.TRADING|DEPOT_TYPE_USAGE_AREA.ONBOARDING|undefined} usageArea Area of contract usage
 * @private
 */
const _getPortfolioAvailableContracts = (contracts, companyId, usageArea=undefined) => {

  let portfolioContracts = _getContractsByCompanyId(contracts, companyId)
  if (!portfolioContracts) {
    return undefined
  }

  if (!_.isNil(usageArea)) {
    portfolioContracts = _filterContractsByUsageArea(portfolioContracts, usageArea)
  }

  return portfolioContracts

}

/**
 * Find depot type according to the provided company id and depot type code.
 *
 * @param {Array<*>} depotTypes List of banks with depot types configuration
 * @param {String|Number} companyId Company identifier (custodian id)
 * @param {DEPOT_TYPE_USAGE_AREA.TRADING|DEPOT_TYPE_USAGE_AREA.ONBOARDING} usageArea Area of depot type usage
 * @param {String|undefined} depotTypeCode Code of the depot type
 * @private
 */
const _getPortfolioDefaultContract = (depotTypes, usageArea,  companyId, depotTypeCode) => {

  // No need to search for depot type, if code was not provided
  if (!depotTypeCode) {
    return undefined
  }

  let contracts = _getPortfolioAvailableContracts(depotTypes, companyId, usageArea)
  if (!contracts) {
    return undefined
  }

  contracts = _filterContractsByDepotTypeCode(contracts, depotTypeCode)

  return contracts.length && contracts[0]

}

export const isClearingAccountValid = (account) => {
  return account.currency === 'EUR';
};

const CLEARING_ACCOUNT_IBAN_TYPE_ID = 4;
const CLEARING_ACCOUNT_BIC_TYPE_ID = 1;
const CLEARING_ACCOUNT_HOLDER_TYPE_ID = 35;

export const setPortfolioClearingAccount = (portfolio, clearingAccountGetter) => {

  const clearingAccounts = _.cloneDeep(clearingAccountGetter(portfolio) || []);

  portfolio.clearing_account = _.filter(clearingAccounts, isClearingAccountValid);
  if (!_.isEmpty(portfolio.clearing_account)) {
    portfolio.clearing_account = portfolio.clearing_account.reduce((result, cAccount) => {
      result[cAccount.id] = cAccount;
      cAccount.iban = getIdentifierCodeByTypeId(
        cAccount.identifiers, CLEARING_ACCOUNT_IBAN_TYPE_ID);
      cAccount.bic = getIdentifierCodeByTypeId(
        cAccount.identifiers, CLEARING_ACCOUNT_BIC_TYPE_ID);
      cAccount.account_number = getClearingAccountNumber(
        cAccount.identifiers);
      cAccount.account_holder = getIdentifierCodeByTypeId(
        cAccount.identifiers, CLEARING_ACCOUNT_HOLDER_TYPE_ID);
      return result;
    }, {});
  }
};

export const checkSavingsPlanChanged = (savingsPlan) => {
  const savingsPlanData = _.get(savingsPlan, 'data', {});

  const amountChanged = savingsPlan.transaction_value != Math.abs(parseFloat(savingsPlanData.rate));
  const rotationChanged = savingsPlan.rotation != savingsPlanData.shifts_value;
  const fromDateChanged = moment(savingsPlan.from_date).format('YYYY-MM-DD') != savingsPlanData.start_date;
  const tillDateChanged = (savingsPlan.till_date || savingsPlanData.end_date) && moment(savingsPlan.till_date).format('YYYY-MM-DD') != savingsPlanData.end_date;
  const discountChanged = !!savingsPlan.discount;

  savingsPlan.is_changed = (amountChanged || rotationChanged || fromDateChanged || discountChanged || tillDateChanged
    || savingsPlan.action == 'delete');

  if(!savingsPlan.is_changed){
    savingsPlan.errors = {}; // clean errors
  }

  savingsPlan.changes = {
    transaction_value: amountChanged,
    rotation: rotationChanged,
    from_date: fromDateChanged,
    discount: discountChanged,
    till_date: tillDateChanged
  }
};

/**
 * Check if tradings are savings plans.
 *
 * @param {Array<*>} tradings - List of portfolios tradings
 * */
export const paymentPlansTransactions = (tradings) => {
  if (!Array.isArray(tradings) || !tradings.length) {
    return false
  }

  // In current flow it is not possible to combine savings plans with tradings.
  // So we can check transactions for one portfolio only.
  return _.some([TRANSACTION_TYPE_VALUES.SAVINGS_PLAN, TRANSACTION_TYPE_VALUES.PAYOUT_PLAN, TRANSACTION_TYPE_VALUES.SWITCH_PLAN]
    .map((transactionType) => tradings[0].transactions.hasOwnProperty(transactionType)))
}


const isDeleteOnlyPaymentPlans = (paymentPlans) => {
  if (!paymentPlans) return false;

  for (let paymentPlan of paymentPlans) {
    if (paymentPlan.action != 'delete') {
      return false
    }
  }

  return true
}


export const savingsPlansDeleteOnly = (tradings) => {

  let portfolios = tradings
  if (!portfolios) return false

  for(let portfolio of portfolios) {
    let savingsPlans = portfolio.transactions.savings_plan
    let payoutPlans = portfolio.transactions.payout_plan
    let switchPlans = portfolio.transactions.switch_plan

    if (!isDeleteOnlyPaymentPlans(savingsPlans)
      || !isDeleteOnlyPaymentPlans(payoutPlans)
      || !isDeleteOnlyPaymentPlans(switchPlans)) {
      return false
    }
  }

  return true
}

export const getTransactionsTotalAmount = (transactions) => {

  const availableTradingTypes = [
    TRANSACTION_TYPE_VALUES.BUY,
    TRANSACTION_TYPE_VALUES.SELL,
    TRANSACTION_TYPE_VALUES.SAVINGS_PLAN,
    TRANSACTION_TYPE_VALUES.PAYOUT_PLAN
  ];

  return availableTradingTypes.reduce((total, key) => {
    let instruments = transactions[key];
    if ([TRANSACTION_TYPE_VALUES.PAYOUT_PLAN, TRANSACTION_TYPE_VALUES.SAVINGS_PLAN].includes(key) && _.isArray(instruments)) {
      instruments = instruments.filter(i => i.is_changed);
    }
    let amount = totalTransactionAmount(instruments);
    if([TRANSACTION_TYPE_VALUES.SELL, TRANSACTION_TYPE_VALUES.PAYOUT_PLAN].includes(key)) amount = -amount;
    return total + amount;
  }, 0);
}

/**
 * Get transaction type according to the provided ordering field name.
 *
 * @param {String} orderingFieldName Name of the field according to the custodian configuration (quantity / amount_eur)
 *
 * @return {String|undefined} Transaction type
 */
export const getTransactionTypeByOrderingFieldName = (orderingFieldName) => {

  return Object.keys(TRANSACTION_INSTR_ORDERING_FIELD_NAMES)
    .find((transactionType) => TRANSACTION_INSTR_ORDERING_FIELD_NAMES[transactionType] == orderingFieldName)

}

/**
 * Get transaction type according to the custodian configuration.
 * amount_eur will be used in case it is allowed by custodian.
 * Otherwise, first available will be used.
 *
 * @param {Object} portfolio portfolio data
 * @param {String} transaction type: buy / sell / switch
 *
 * @return {String|undefined} Transaction type
 */
export const getTransactionType = (portfolio, transaction) => {
  const availableTransactionTypes = getAvailableOrderingFields(portfolio, transaction);

  if (!availableTransactionTypes.length) {
    return undefined
  }

  if (availableTransactionTypes.includes(TRANSACTION_INSTR_ORDERING_FIELD_NAMES[TRANSACTION_INSTR.amount])) {
    return TRANSACTION_INSTR.amount
  } else if (availableTransactionTypes.includes(TRANSACTION_INSTR_ORDERING_FIELD_NAMES[TRANSACTION_INSTR.qty])) {

    // It should work in a way, that in case amount_eur is not available,
    // first available option should be used. List of available fields from CIOS contains all fields,
    // that could be used for specific trading type (buy/sell/switch), so this list contains not only transaction types.
    // As we have only 2 possible choices for transaction type ('amount_eur' and 'quantity')
    // we should return 'quantity' in case it is available for custodian and 'amount_eur' is not.

    return TRANSACTION_INSTR.qty
  }

  return undefined
}

/**
 * Get list of available fields for transaction
 *
 *
 * @param {Object} portfolio portfolio data
 * @param {String} transaction type: buy / sell / switch
 *
 * @return {String|undefined} Transaction type
 */
export const getAvailableOrderingFields = (portfolio, transaction) => {
  const quotationFieldsSource = _.get(portfolio, 'isModelportfolio')
    ? ORDERING_MP_AVAILABLE_OPTIONS_FIELD_NAMES : ORDERING_AVAILABLE_OPTIONS_FIELD_NAMES;
  return _.get(portfolio, quotationFieldsSource[transaction], []);
}

export const percentageToQuantity = (percentage, item) => {

  const totalQuantity = _.get(item, 'data.quantity') || 0

  return totalQuantity / 100 * percentage
}


export const transactionValueToQuantity = (transactionValue, item, validate=true) => {

  if (validate && (item.transaction_type == TRANSACTION_INSTR.qty || !transactionValue)) {
    return transactionValue
  }

  const itemPriceEur = _.get(item, 'data.price_eur') || 1

  try {
    return transactionValue / itemPriceEur
  } catch (e) {
    return transactionValue
  }
}

/**
 * Convert transaction value to euro in case transaction type is quantity.
 *
 * @param {Number} transactionValue - Transaction value in quantity. In case transaction
 *                                    value is amount in euro - Transaction value will be returned.
 * @param {Object} item - Transaction item data
 * @param {Boolean} validate - Flag that indicate, if transaction type of item should be handled.
 *                                            So in case flag is activated, any additional converting is not required
 *                                            in case item transaction type is TRANSACTION_INSTR.amount
 * @return {Number}
 */
export const transactionValueToEuro = (transactionValue, item, validate=true) => {

  if (validate && item.transaction_type == TRANSACTION_INSTR.amount || !transactionValue) {
    return transactionValue
  }

  const itemPriceEur = _.get(item, 'data.price_eur') || 1
  return itemPriceEur * transactionValue
}

/**
 * Convert transaction value to percentage.
 *
 * @param {Number} transactionValue - Transaction value in quantity or euro.
 * @param {Object} item - Transaction item data
 * @return {Number}
 */
export const transactionValueToPercentage = (transactionValue, item, validate=true) => {

  let quantity = validate ? transactionValueToQuantity(transactionValue, item) : transactionValue

  const totalQuantity = _.get(item, 'data.quantity') || 0

  return (quantity / totalQuantity) * 100

}


/**
 * Calculate difference between Switch Out transaction value and
 * Aggregated transaction value of all Switch In transactions.
 *
 * @param {Object} transaction Switch transaction
 * @return {number} Saldo value
 */
export const calculateSwitchTransactionSaldo = (transaction, fraction=QTY_DECIMALS) => {

  if (![TRADING_ACTION_SWITCH, TRADING_ACTION_SWITCH_PLAN].includes(transaction.tradingType)) {
    return 0
  }

  const switchOutAmount = transaction.transaction_value
  const switchInAmount = _.round((_.get(transaction, 'buy') || []).reduce((total, transaction) => {
    return total + (transaction.transaction_value || 0)
  }, 0), fraction)

  return switchOutAmount - switchInAmount

}


/**
 * Extend switchIn transaction with data from switchOut transaction.
 * Function should be used for Switch process, where values of switch in transaction
 * should be calculated according to the switch out transaction configuration.
 *
 * @param {Object} switchIn Switch In transaction
 * @param {Object} switchOut Switch Out transaction
 * @return {*&{sell_all: (boolean|string|null|*), data: (*&{quantity, price_eur})}}
 */
export const mergeSwitchItems = (switchIn, switchOut, getQuantityCallback=(switchOut) => switchOut.transaction_value || 0) => {
  return {
    ...switchIn,
    sell_all: switchOut.sell_all,
    data: {
      ...switchIn.data,
      // Using transaction_value of Switch Out transaction as a total quantity
      // to calculate values for Switch Out transactions
      quantity: getQuantityCallback(switchOut),
      price_eur: switchOut.data.price_eur
    }
  }
}


/**
 * Retrieve MP Number from portfolio data
 * @param portfolio
 */
export const getModelPortfolioNumberFromPortfolio = (portfolio) => {
  return _.get(portfolio, 'data.is_private_investment')
    ? _.get(portfolio, 'data.investment_strategy_data.portfolio_id')
    : _.get(portfolio, 'data.model_portfolio_data.agency_portfolio_id')
}


/**
 * Get order instrument type for model portfolio.
 * @param portfolio
 */
export const getModelPortfolioInstrumentType = (portfolio) => {

  let instrumentType = _.get(portfolio, 'data.is_private_investment')
    ? 'model portfolio managed' : 'model portfolio'

  if (portfolio.hasOwnProperty('modelPortfolioTransactionType')) {
    instrumentType = portfolio.modelPortfolioTransactionType == MODEL_PORTFOLIO_TRANSACTION_TYPE.BASE_FUND
      ? `${instrumentType} base` : instrumentType
  }

  return instrumentType
}


/**
 * Update existing instruments market values according to buy / sell instruments
 * @param instruments Existing instruments list
 * @param instrumentsToBuy New instruments to buy (+= transaction value)
 * @param instrumentsToSell Instruments to sell (-= transaction value)
 */
export const mergeInstrumentsWithTransactions = (instruments, instrumentsToBuy=undefined, instrumentsToSell=undefined, useCalculated=true) => {

  const getTransactionValue = (transaction) => (useCalculated && transaction.hasOwnProperty('calculated') ? _.get(transaction, 'calculated.transaction_value_euro') : transaction.transaction_value) || 0

  let instrumentsCopy = _.cloneDeep(instruments)
  // We assume that existing instruments always contains list of uniq instruments
  let instrumentsIdentifiers = instrumentsCopy.map((instrument) => instrument.isin)

  if (!_.isEmpty(instrumentsToBuy)) {
    instrumentsToBuy.forEach((instrumentB) => {

      if (!instrumentsIdentifiers.includes(instrumentB.data.isin)) {
        instrumentsCopy.push({
          ...instrumentB.data,
          market_value: getTransactionValue(instrumentB)
        })
        instrumentsIdentifiers.push(instrumentB.data.isin)
      } else {
        instrumentsCopy.forEach((instrument) => {

          if (instrumentB.data.isin != instrument.isin) return;

          instrument.market_value = (instrument.market_value || 0) + getTransactionValue(instrumentB)
        })
      }
    })
  }

  if (!_.isEmpty(instrumentsToSell)) {
    instrumentsToSell.forEach((instrumentS) => {
      instrumentsCopy.forEach((instrument) => {

        if (instrumentS.data.isin != instrument.isin) return;

        instrument.market_value = (instrument.market_value || 0) - getTransactionValue(instrumentS)

      })
    })
  }

  return instrumentsCopy

}

/**
 * Return SRRI value for transaction.
 * @param item
 * @param withMax flag, that indicate, if max srri from list of instruments should be returned.
 * Note: Works only in case item is model portfolio
 */
export const getTransactionItemSRRI = (item, withMax=false, useInternalSRI=false) => {

  const isPrivateInvestment = _.get(item, 'data.is_private_investment')
  const isModelPortfolio = _.get(item, 'data.is_model_portfolio')

  if (withMax && isModelPortfolio && !isPrivateInvestment) {

    const components = _.get(item, 'data.components')
    // If there is no components - continue with usual flow and return configured SRRI value for MP
    if (Array.isArray(components) && components.length) {
      return _.max(components.map((component) => component.sri || 0))
    }

  }

  let itemSRRI = _.get(item, 'data.sri')
  if (_.isNil(itemSRRI) && useInternalSRI) {
    itemSRRI = _.get(item, 'data.internal_sri')
  }
  if (isPrivateInvestment) {
    itemSRRI = +(_.get(item, 'data.investment_strategy_data.srri') || 0)
  } else if (isModelPortfolio) {
    itemSRRI = +(_.get(item, 'data.model_portfolio_data.srri') || 0)
  }

  return itemSRRI
}

export const calculatePortfolioInstrumentsAverageSrri = (portfolio, componentsFieldName='components', useCalculated=true) => {

  if (!portfolio) {
    return 0
  }

  if (_.get(portfolio, 'data.is_private_investment')) {
    return +(_.get(portfolio, 'data.investment_strategy_data.srri') || 0)
  } else if (_.get(portfolio, 'data.is_model_portfolio')) {
    return +(_.get(portfolio, 'data.model_portfolio_data.srri') || 0)
  }

  let buyTransactions = [...(portfolio.transactions.buy || [])]
  let sellTransactions = [...(portfolio.transactions.sell || [])]
  let switchTransactions = [...(portfolio.transactions.switch || [])]
  switchTransactions.forEach((switchTransaction) => {
    sellTransactions.push(switchTransaction)
    buyTransactions = [...buyTransactions, ...(switchTransaction.buy || [])]
  })

  const srriInstrumentsWithTransactions = mergeInstrumentsWithTransactions(
    portfolio.data[componentsFieldName], buyTransactions, sellTransactions, useCalculated)

  return calculateInstrumentsAverageSRRI(srriInstrumentsWithTransactions,
    (instrument) => instrument.market_value || instrument.newMarketValue || 0)
}

export const getExAnteExplanations = () => {
  let hostRelatedSettings;
  if (getFromStorage(SHARED_SETTINGS_KEY)) {
      hostRelatedSettings = getFromStorage(SHARED_SETTINGS_KEY).host_related_settings || {}
  }

  const exAnteExplanations = [
        'ERWERBSKOSTEN',
        'ERWERBSKOSTEN_PRODUKTKOSTEN',
        'ERWERBSKOSTEN_DIENSTLEISTUNGSKOSTEN',
        'LAUFENDE_KOSTEN',
        'LAUFENDE_KOSTEN_PRODUKTKOSTEN',
        'LAUFENDE_KOSTEN_PRODUKTKOSTEN_DAVON_LAUFENDE_PROVISION',
        'LAUFENDE_KOSTEN_DIENSTLEISTUNGSKOSTEN',
        'VERAUSSERUNGSKOSTEN',
        'VERAUSSERUNGSKOSTEN_PRODUKTKOSTEN',
        'GESAMTKOSTEN_PROGNOSE',
        'DIE_KOSTEN_REDUZIEREN_DIE_RENDITE_DES_INVESTMENTS_WIE_FOLGT',
    ]

  return Object.assign({}, ...exAnteExplanations.map((item) => {
    if(hostRelatedSettings[item] && hostRelatedSettings[item].length) {
      return {
        [item]: <div dangerouslySetInnerHTML={{__html: hostRelatedSettings[item][0].value}}/>
      }
    }
    return {
      [item]: ''
    }
  }))
}

export const isRebalancingEnabled = () => {
  let sharedStorage = getFromStorage(SHARED_SETTINGS_KEY) || {};
  return !!(_.get(sharedStorage, 'sub_systems.trading.config.rebalancing_enabled'));
};

export const getInstrumentName = (instrument) => {
  return (getInstrName(instrument) || instrument.isin).slice(0, 120);
}

const buildInstrumentId = (internalID, depotNumber, subDepotNumber) => {
  subDepotNumber = subDepotNumber.split('-');
  return `${internalID}${depotNumber}${subDepotNumber[0]}`
}

export const updateInstrumentIdInstrumentType = (item, order, key, depotNumber='', companyId, isUseProductId=false, instrumentType='instrument_type') => {
  const subDepotNumber = _.get(item, 'data.depot_sub_number') || '';
  let custodianData = instrCustodianData(item, companyId);
  if (_.isEmpty(custodianData)){
    custodianData = instrCustodianData(item.data, companyId)
  }
  if (isUseProductId && depotNumber && !!subDepotNumber && !!custodianData.c_internal_id) {
    order[key] = buildInstrumentId(custodianData.c_internal_id, depotNumber, subDepotNumber);
    order[instrumentType] = 'instrument id';
  }
}

export const getInstrumentSri = (instrument) => (instrument.isInvestmentStrategy
  ? instrument.srri
  : instrument.sri)
  || instrument.internal_sri

export const DUMMY_ACCOUNT_HOLDER = {
  first_name: "Dummy",
  last_name: "Dummy",
  registration_address: {
    street: "Dummy 1A",
    zip_code: "11111",
    city: "Dummy",
    country: "DE"
  },
  birth_date: "2000-01-01",
  mobile_number: "+49 30 259668605",
  email: "dummy@dummy.de",
  bank_account: {
    iban: "DE89370400440532013000",
    bic: "COBADEFFXXX",
    account_holder: "Dummy",
    account_holder_full_name: "Dummy",
    credit_institute_name: "Commerzbank"
  }
};

export const DUMMY_ORDER = {
  portfolioId: 'DUMMY',
  order_details: []
};

export const setEarliestPriceDates = async (instruments, dispatch, pattern = 'data.isin') => {
  const handleError = (e) => {
    dispatch(displayWarningSnackBar(`Fehler beim Abrufen vorheriger Preisdaten: ${getErrorMessage(e)}`));
  };

  try {
    const res = await VirtualPortfolioHandlerResource.getInstrumentsEarliestPriceDate(_.uniq(instruments.map(i => _.get(i, pattern))));

    parseResponse(res, 'earliest', (earliestPrices) => {
      instruments.map(i => i.earliestPrice = earliestPrices[_.get(i, pattern)]);
    }, handleError)
  } catch (e) {
    handleError(e)
  }
};

export const buildTransactionsWithAddTradingOption = (
  portfoliosTransactions,
  tradings,
  portfolio,
  instrument,
  tradingType,
  action=undefined,
  paymentPlanTradingType,
) => {
  // function to update trading for virtual portfolios when it is selected via select on instrument level

  // In BCA-9202 possibility to create new payment plans from dashboard was added.
  const isPaymentPlan = isPaymentPlanType(paymentPlanTradingType);
  if (_.isUndefined(action) && isPaymentPlan){
    action = 'create';
  }

  const getIdFunc = isPaymentPlan ? getAssetInternalIdForNewPaymentPlan : getInstrumentId;

  let updatedPortfoliosTransactions = [...portfoliosTransactions];
  // get transactions of proper portfolio
  let ptfTransactionToUpd = _.find(updatedPortfoliosTransactions, portfolioTransaction => portfolioTransaction.data.id === portfolio.id);

  const prevInstruments = _.get(_.find(tradings, t => t.portfolioId === portfolio.id), 'instruments');
  const instrumentId = getIdFunc(instrument, paymentPlanTradingType);
  const prevTransaction = _.find(prevInstruments, i => i.instrumentId === instrumentId);

  if (prevTransaction){
    // if transaction for asset exist - get code
    const prevTransactionType = _.find(TRADING_ACTIONS, action => action.value === prevTransaction.tradingType).code;

    // delete old transaction because transaction type could change
    const prevTransactionIdx = _.findIndex(ptfTransactionToUpd.transactions[prevTransactionType], t => getIdFunc(t.data, paymentPlanTradingType) === instrumentId);
    prevTransactionIdx >= 0 && ptfTransactionToUpd.transactions[prevTransactionType].splice(prevTransactionIdx, 1)
  }

  // if default option was not selected
  if (tradingType !== TRADING_ACTION_DEFAULT.value && action !== TRADING_ACTION_DEFAULT.value) {
    let transactionFields = {
      data: instrument,
      tradingType,
      action,
      instrumentId,
    };

    // get code ('buy', 'sell') depending on tradingType
    const tradingActionCode = _.find(TRADING_ACTIONS, action => action.value === tradingType).code;
    // get reference for list of transactions using tradingActionCode
    let transactionTypeList = ptfTransactionToUpd.transactions[tradingActionCode];

    if (tradingActionCode === TRANSACTION_TYPE_VALUES.SWITCH){
      transactionFields.buy = []
    }

    // add new transaction
    transactionTypeList.push(transactionFields)
  }

  return updatedPortfoliosTransactions;
};

export const clearingAccountToBankAccount = (clearingAccount, portfolio) => ({
  id: clearingAccount.iban,
  value: clearingAccount.iban,
  iban: clearingAccount.iban,
  bic: clearingAccount.bic,
  account_holder: clearingAccount.account_holder,
  account_holder_full_name: clearingAccount.account_holder,
  credit_institute_name: portfolio.bank
});


export const filterAvailableDiscounts = (availableDiscounts, maxDiscount) => {
  // get options < max discount for this instrument or Empty
  return availableDiscounts.filter((o) => parseInt(o.value || '0') <= maxDiscount);
};

/*
We use custodian_data as copy of c_company field and keep it synced with latest data
*/
export const instrCustodianData = (item, bankCompanyID) => {
  return item.custodian_data && item.custodian_data[bankCompanyID] || _.get(item, `c_company_${bankCompanyID}`) || {};
};

// Hide sell and switch portfolio trading sections
// edit and delete trading sections for the payment plans (saving/payout/switch)
// if customer has no components and transactions for them
export const isHideTradingSection = (key, components, transactions) =>
  ["delete", "edit", "switch", "sell"].includes(key) &&
  !(components || []).filter(i => i.isModelportfolio || !!i.isin).length &&
  !transactions.length;

export const getAmountFraction = (value) => {
  value = `${value}`.split('.');

  return value.length > 1 ? value[1].length : 0;
};