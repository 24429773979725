import {parseResponse, PortfolioHandlerResource} from "../../../utils/api";
import React, {useState} from "react";
import _ from "lodash";
import {generateQueryParamsHash} from "../utils";
import {useTabsSharedDataContext} from "../components_v2/TabsSharedData/TabsSharedData";

const defaultData = {
  mdd: undefined,
  mdd_benchmark: undefined,
  value_at_risk: undefined,
  value_at_risk_benchmark : undefined,
  volatility: undefined,
  volatility_benchmark: undefined,
  mddStart: undefined,
  mddEnd: undefined,
  mddPickDate: undefined,
  recoveryDays: undefined,
};

const SHARED_DATA_KEY = 'risk-analysis-data';

export function useRiskAnalyseData(customerId, portfolios, benchmarks, withHistoriaclPortfolios, startDate, endDate, dataProvider=PortfolioHandlerResource, assets, investmentStrategyId) {
  const [data, setData] = useState({
    data: defaultData,
    errors: null,
    loading: false,
    updatedAt: +new Date()
  });

  const paramsForHash = [startDate, endDate, withHistoriaclPortfolios, benchmarks, investmentStrategyId];
  const sharedData = useTabsSharedDataContext();

  React.useEffect(() => {
    if (!!customerId && !_.isEmpty(portfolios)) {
      const sharedPortfolioData = !!sharedData
        ? sharedData.getDataValue(SHARED_DATA_KEY, generateQueryParamsHash(customerId, portfolios, ...paramsForHash))
        : undefined;

      if (!!sharedPortfolioData && !sharedPortfolioData.errors) {
        // sync internal hook data with shared data.
        setData(sharedPortfolioData);
        return;
      }

      // fetch data if there is no shared data or shared data was loaded with error
      // check if data fetched for the first time (updatedAt is undefined) to prevent infinite data reloading
      // in case of data loading error, so data will be requested again only if tab changed
      if (!data.updatedAt || !sharedPortfolioData) {
        fetch()
      }
    }
  }, [customerId, JSON.stringify(portfolios), JSON.stringify(benchmarks), withHistoriaclPortfolios, startDate, endDate])

  async function fetch() {

    const _setState = (state) => {
      // It is not possible to sync data with shared data in
      // hook because hook is not executed if component destroyed
      setData(state);
      sharedData.setDataValue(SHARED_DATA_KEY, hashKey, state);
    };

    const hashKey = generateQueryParamsHash(customerId, portfolios, ...paramsForHash);

    _setState({
      data: defaultData,
      errors: null,
      loading: true,
      updatedAt: +new Date()
    });

    try {

      const riskResponse = await dataProvider.getRiskData(
        customerId, portfolios.map(p => p.depotNumber), startDate, endDate,
        withHistoriaclPortfolios, benchmarks, assets, investmentStrategyId);


      let mdd = null,
        mdd_benchmark = null,
        value_at_risk = null,
        value_at_risk_benchmark = null,
        volatility = null,
        volatility_benchmark = null,
        mddStart = null,
        mddEnd = null,
        mddPickDate = null,
        recoveryDays = null,
        error = null;

      parseResponse(riskResponse, 'risk',
        data => {
          if (_.has(data, 'volatility.min_date_message')) {
            error = data['volatility']['min_date_message'];
          } else {
            const _volatility = data['volatility']['risk_metrics'];
            const _benchmarkVolatility = data['benchmark_volatility']['risk_metrics'];
            mdd = _volatility.maximum_drawdown * 100;
            value_at_risk = Math.abs(_volatility.var_hist_hp) * 100;
            volatility = _volatility.volatility_annual * 100;
            mddStart = _volatility.mdd_date;
            mddEnd = _volatility.recovery_date;
            mddPickDate = _volatility.mdd_peak_date;
            recoveryDays = _volatility.recovery_days;
            if (!!benchmarks && !!_benchmarkVolatility) {
              value_at_risk_benchmark = Math.abs(_benchmarkVolatility.var_hist_hp) * 100;
              volatility_benchmark = _benchmarkVolatility.volatility_annual * 100;
              mdd_benchmark = _benchmarkVolatility.maximum_drawdown * 100;
            }
          }
        },
        error => {
          error = error;
        }
      );

      _setState({
        data: {
          mdd,
          mdd_benchmark,
          value_at_risk,
          value_at_risk_benchmark,
          volatility,
          volatility_benchmark,
          mddStart,
          mddEnd,
          mddPickDate,
          recoveryDays,
        },
        errors: error,
        loading: false,
        updatedAt: +new Date()
      });

    } catch (errors) {
      _setState({
        data: defaultData,
        errors: errors,
        loading: false,
        updatedAt: +new Date()
      });
    }
  }

  return data;
}