import React from 'react';

/* Material-UI Components */
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

/* BCA modules*/
import useStyles from './styles';


export default (props) => {
  const classes = useStyles();
  const {
    isLoading,
    isSettingsSuccessUpload,
    handleSaveClicked,
    isVisible
  } = props;

  return (
    isVisible ? (
      <div className={classes.footerContainer}>
        <Container className={classes.footerContentContainer}>
          <Grid container alignItems={"center"} justify={"flex-end"}>
            <Grid item>
              {isSettingsSuccessUpload
                ? <div className={classes.successMessage}>Ihre Einstellungen wurden erfolgreich gespeichert</div>
                : <Button variant="contained"
                        className={classes.footerButton}
                        disabled={isLoading}
                        onClick={handleSaveClicked}>
                    {isLoading
                      ? <CircularProgress size={24} className={classes.buttonProgress}/>
                      : 'Alle Änderungen speichern'
                    }
                  </Button>
              }
            </Grid>
          </Grid>
        </Container>
      </div>
    ) : null
  )
}