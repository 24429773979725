import React from "react";

import Container from "@material-ui/core/Container/Container";
import withStyles from "@material-ui/core/styles/withStyles";

import {withRiskProfilingData} from "./components/RiskProfilingDataProvider";
import ProgressBar from "./components/ProgressBar";
import StepContentV2 from "./components/StepContent/StepContentV2";
import styles from "./styles";
import {getStepCategory} from "./utils";
import withCustomersSelectorNew from "../../components/CustomersSelectorProviderNew";
import {CircularProgress} from "@material-ui/core";
import { onboardingOnCustomersChange, RenderStepLoadingMessage, toLocalDateTime } from '../../utils/utils';
import {QuestionnairesHandlerResource} from "../../utils/api";
import EinzeltitelProcessConfirmationModalContextProvider
  from "./components/EinzeltitelsProcessConfirmationProvider/EinzeltitelsProcessConfirmationProvider";

let onboardings = {};

const onCustomersChange = async (customers) => {
  onboardings = await onboardingOnCustomersChange(customers)
};

const getButtonAttributes = (customer, useNewDesign) => {
  const lastOnboarding = onboardings[customer.id];
  let lastOnboardingStatus = lastOnboarding && !lastOnboarding.finished && lastOnboarding.has_answers;

  let buttonAttrs = {
    title: lastOnboardingStatus ? "Fortsetzen" : "Start Investmentberatung",
    disabled: !!customer.bca_is_company
  };

  if(lastOnboardingStatus){
    buttonAttrs.customOnDeleteClickHandler  = async (props) => {
      await QuestionnairesHandlerResource.deleteQuestionnaire(lastOnboarding.session_id);  // session_id is the same as onboarding_uid
      delete onboardings[customer.id]
    }
  } else if(useNewDesign){ // start new session in new design
    buttonAttrs.componentIdentifier = 'RISK_PROFILING_V2'
  }

  return buttonAttrs
};

const extraColumns = [{
  content: [{
    body: {
      content: (customer) => {
        const data = onboardings[customer.id];
        if(data){
          const updatedAt = toLocalDateTime(data.updated_at);
          return (
            <>
              <span>{updatedAt.format('DD.MM.YYYY')}</span><br/>
              <span>{updatedAt.format('HH:mm')}</span>
            </>
          )
        } else {
         return ' - ';
        }
      }
    },
    header: {
      content: () => (
        <>
          <span>Zuletzt</span><br/>
          <span>bearbeitet</span>
        </>
      )
    }
  }],
  isHiddenOnSmallScreens: true
}];

const RiskProfiling = props => {
  const {
    classes,
    onNextClick,
    onPrevClick,
    onGoToStepClick,
    onResendResultClick,
    onConfirmResultClick,
    step,
    loading,
    progress,
    isFirst,
    isLast,
    required,
    valid,
    onAnswerChange,
    successBody,
    nextStepBtnText,
    dataService,
    extraRightButtons,
    isNewDesign,
    answersData,
  } = props;

  return (
    <EinzeltitelProcessConfirmationModalContextProvider>
    <Container className={classes.container}>
      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.loadingIndicator}/>
          {RenderStepLoadingMessage(step)}
        </div>
      ) : (
        <>
          <StepContentV2
            classes={classes}
            step={step}
            loading={loading}
            isFirst={isFirst}
            isLast={isLast}
            onNextClick={onNextClick}
            onPrevClick={onPrevClick}
            onGoToStepClick={onGoToStepClick}
            onResendResultClick={onResendResultClick}
            onConfirmResultClick={onConfirmResultClick}
            required={required}
            valid={valid}
            onAnswerChange={onAnswerChange}
            successBody={successBody}
            nextStepBtnText={nextStepBtnText}
            dataService={dataService}
            extraRightButtons={extraRightButtons}
            isNewDesign={isNewDesign}
            answersData={answersData}
          />
          <ProgressBar value={progress} dataService={dataService} category={getStepCategory(step)} isNewDesign={isNewDesign} />
        </>
      )}
    </Container>
    </EinzeltitelProcessConfirmationModalContextProvider>
  )
};

export default withCustomersSelectorNew('RISK_PROFILING', getButtonAttributes, onCustomersChange, extraColumns)(
  withRiskProfilingData(withStyles(styles)(RiskProfiling)));