import React from "react";
import {withStyles} from "@material-ui/core";
import clsx from "clsx";

import CommonAnalysisCard from "../../../VirtualPortfolioPage/components/AnalysisCard";
import styles from "./styles";
import WarningTooltip from "../../../../components/WarningTooltip";
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../../../utils/constants";

class TableWidget extends React.Component {

    componentDidUpdate(prevProps) {
        if (this.props.data && this.props.data !== prevProps.data) {
            this.renderData()
        }
    }

    renderValue({ value, transform, defaultIfNone}) {
        if (value === undefined || value === null) {
            return defaultIfNone || 'k.A.';
        } else if (value && transform) {
            return transform(value);
        }

        return value;
    }

    renderDescriptions() {
        let lines = [];
        this.props.data.filter(i => i.sup).forEach((item, index) => {
            lines.push(
                <p key={index}>
                    <sup>{item.sup.index}</sup> {item.sup.text}
                </p>
            )
        })
        return lines;
    }

    renderLines() {
        const { classes, noLines, defaultIfNone} = this.props;
        let lines = [];
        this.props.data.forEach((item, index) => (
            lines.push(
                <li className={clsx([classes.item, noLines && classes.noLines])} key={index}>
                    <span className={classes.title}>{item.title} {item.tooltip && (
                      <WarningTooltip
                        placement={'bottom'}
                        title={item.tooltip.text}
                        iconClassName={classes.helpIcon}
                      />
                    )}</span>
                    <span className={classes.values} dangerouslySetInnerHTML={{__html: this.renderValue(item)}}></span>
                </li>
            )
        ))
        return lines;
    }

    renderData() {
        const { classes, data } = this.props;

        return (
            <>
                <ul className={classes.list}>
                    {this.renderLines()}
                </ul>
                <div className={classes.subline}>
                  <p className={classes.description}>
                    {this.renderDescriptions()}
                  </p>
                </div>
            </>
        )
    }

    renderError() {
        return <div>{'Fehler. Die ' + this.props.title + ' Tabelle konnte nicht geladen werden.'}</div>
    }

    render() {
        const { classes } = this.props;

        return <CommonAnalysisCard
            classes={{
              card: classes.root
            }}
            title={this.props.title}
            subtitle={this.props.subtitle}
            loading={this.props.loading}
            content={
              <div className={classes.container}>
                {this.props.error ? this.renderError() : this.props.data ? this.renderData() : DEFAULT_EMPTY_SECTION_MESSAGE}
              </div>
          }
        />
      }
}

export default withStyles(styles)(TableWidget)