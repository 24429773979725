import {CustomerMenuItem} from "../CustomerMenuItem";
import {buildDataSteps} from "../../../RiskProfiling/components/servicesV2/mock_customer_data";

export class CustomerMenuItemV2 extends CustomerMenuItem {
  async _getBankDetails() {
    // do nothing
  }

  setClearingAccountQuestionOptions() {
    // do nothing
  }

  validateBankAccountRequirements() {
    // do nothing
  }

  _buildSteps(customer){
    return buildDataSteps(customer);
  }
}
