import {parseResponse, PortfolioHandlerResource} from "../../../utils/api";
import React, {useState} from "react";
import _ from "lodash";
import {useTabsSharedDataContext} from "../components_v2/TabsSharedData/TabsSharedData";
import {generateQueryParamsHash} from "../utils";

const SHARED_DATA_KEY = 'performance-forecast-data';

export function usePerformanceForecastData(customerId, portfolios, dataProvider=PortfolioHandlerResource, assets, investmentStrategyId) {
  const [data, setData] = useState({
    data: null,
    errors: null,
    loading: false,
    updatedAt: +new Date()
  });

  const sharedData = useTabsSharedDataContext();

  React.useEffect(() => {
    if (!!customerId && !_.isEmpty(portfolios)) {
      const sharedPortfolioData = !!sharedData
        ? sharedData.getDataValue(SHARED_DATA_KEY, generateQueryParamsHash(customerId, portfolios))
        : undefined;

      if (!!sharedPortfolioData && !sharedPortfolioData.errors) {
        // sync internal hook data with shared data.
        setData(sharedPortfolioData);
        return;
      }

      // fetch data if there is no shared data or shared data was loaded with error
      // check if data fetched for the first time (updatedAt is undefined) to prevent infinite data reloading
      // in case of data loading error, so data will be requested again only if tab changed
      if (!data.updatedAt || !sharedPortfolioData) {
        fetch()
      }
    }
  }, [customerId, JSON.stringify(portfolios)])

  async function fetch() {
    const _setState = (state) => {
      // It is not possible to sync data with shared data in
      // hook because hook is not executed if component destroyed
      setData(state);
      sharedData.setDataValue(SHARED_DATA_KEY, hashKey, state);
    };

    const hashKey = generateQueryParamsHash(customerId, portfolios);

    _setState({
      data: null,
      errors: null,
      loading: true,
      updatedAt: +new Date()
    });

    try {

      const portfoliosIds = portfolios.filter(portfolio => !!portfolio.depotNumber).map(portfolio => portfolio.depotNumber);

      const response = await dataProvider.getPortfolioForecast(customerId, portfoliosIds, assets, investmentStrategyId);
      /** Parse extended_asset_data data */
      parseResponse(response, 'performance_forecast', (data) => {
        _setState({
          data: data,
          errors: null,
          loading: false,
          updatedAt: +new Date()
        });
      }, (errors) => {
        _setState({
          data: null,
          errors: errors,
          loading: false,
          updatedAt: +new Date()
        });
      });

    } catch (errors){
      _setState({
        data: null,
        errors: errors,
        loading: false,
        updatedAt: +new Date()
      });
    }
  }

  return data;
}