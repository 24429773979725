import {footerHeight} from "../StepFooter/styles";
import images from "../../../../../../images";

const styles = theme => ({
  loadingIndicator: {
    color: theme.palette.primary.main
  },
  loadingContainer: {
    width: '100%',
    height: 'calc(100vh - 145px)', // 145px for header
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  stepContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    marginTop: 50, // margin for progress bar
  },
  stepScrollContainer: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  stepContent: {
    padding: '32px 8px',
    width: '100%',
    margin: '0 auto',
  },
  appPageContainer: {
    maxWidth: '1368px!important',
    paddingLeft: 0,
    paddingRight: 0,
  },
  scrollFix: {
    height: footerHeight,
  },
  stepName: {
    fontSize: 24,
    fontFamily: 'Roboto-Bold',
    lineHeight: '32px',
    textAlign: 'center',
    marginBottom: 16,
    '&.text-left': {
      textAlign: 'left',
    }
  },
  stepRequired: {
    marginTop: -22,
    color: '#0092E5',
    fontSize: 16,
    fontStyle: 'italic',
    textAlign: 'right'
  },
  stepInfoText: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
    marginBottom: 16,
  },
  stepHelpText: {
    fontSize: 16,
    marginBottom: 16,
    whiteSpace: 'pre-wrap',
  },
  questionsContent: {
    paddingTop: 24,
  },
  finalContent: {
    marginTop: 64,
    display: 'flex',
    justifyContent: 'space-between',

    '& > div': {
      width: '45%'
    },
    '& h3': {
      fontSize: 20,
      fontFamily: 'Roboto-Regular',
      marginTop: 0,
      marginBottom: 16
    }
  },
  finalSliderLabels: {
    display: 'flex',
    justifyContent: 'space-between',

    '& > div': {
      textAlign: 'center',
      fontSize: 10
    },
  },
  finalPdfButtonWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 32
  },
  finalListItem: {
    position: 'relative',
    paddingLeft: 32,

    '&::before': {
      content: "''",
      position: 'absolute',
      top: 5,
      left: 8,
      width: 14,
      height: 13,
      backgroundImage: `url(${images.risk_profiling.megaphone})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    }
  },
  finalActiveLabel: {
    color: theme.palette.primary.main
  },
  congratsContent: {
    maxWidth: '752px!important',
    paddingTop: '5em',
    textAlign: 'center',
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '10em'
    }
  },
  businessCasesContainer: {
    marginTop: '35px!important'
  },
  congratsCheck: {
    fontSize: 36,
    color: '#3fd374',
    verticalAlign: 'bottom',
    marginRight: '0.3em'
  },
  congratsHeader: {
    fontSize: 24,
    fontFamily: 'Roboto-Bold',
  },
  congratsBody: {
    fontSize: 20,
    marginBottom: '3em',
    '& a': {
      color: theme.palette.primary.main,
      fontFamily: 'Roboto-Bold',
      textDecoration: 'none',
      '&.disabled': {
        cursor: 'inherit',
        opacity: 0.5
      }
    }
  },
  checkboxRegularContainer: {
    '& .MuiFormControlLabel-label': {
      fontFamily: 'Roboto-Regular !important'
    },
    '& .MuiFormControlLabel-root': {
      marginBottom: 0
    }
  },
  errorGrid: {
    marginBottom: 25,
    '& .label': {
      color: theme.palette.error.main,
    }
  },
  inlineRadio: {
    '& .MuiFormControl-root .MuiFormLabel-root': {
      display: 'none',
    },
    '& .MuiFormControl-root': {
      width: 'auto',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      marginLeft: 21,
      [theme.breakpoints.down('md')]: {
        margin: 0
      }
    },
    '& .MuiFormGroup-root': {
      margin: '-14px 0 0 20px',
      [theme.breakpoints.down('md')]: {
        margin: 0
      }
    }
  },
  inlineGroup: {
    '& .MuiFormControl-root .MuiFormLabel-root': {
      display: 'none',
    },
    '& .MuiTextField-root': {
      width: '50%'
    },
    '& .MuiFormGroup-root': {
      height: 'auto'
    }
  },
  clearingAccountLine: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    '& > div:last-child': {
      width: 'auto !important'
    }
  },
  clearingAccountWarning: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 32,
    }
  },
  asterisk: {
    color: theme.palette.primary.main
  }
});

export default styles;