import React from 'react';
import _ from 'lodash'

import useStyles from './styles';
import Tooltip from "../../Tooltip";
import clsx from "clsx";


const ChartLegendItem = (props) => {
  const {
    legendItem
  } = props;
  const classes = useStyles();

  const _name = legendItem.name;

  const otherControls = legendItem.otherControls;
  const symbol = {
    circle:  <span style={{fontSize: 12, verticalAlign: 'middle'}}>&#11044;</span>,
    triangle: <span style={{fontSize: 18, verticalAlign: 'middle'}}>&#9650;</span>,
  }[legendItem.symbol] || <>&#x25A0;</>; // square by default


  if (!_.isUndefined(_name)) {
    return (
        <span className={classes.chartLegendItem}>
          <Tooltip title={legendItem.tooltip} placement={"top"}>
            <span>
              <span style={{color: legendItem.color}} className={classes.chartLegendItemSymbol}>{symbol}</span>
              <span className={classes.chartLegendItemText}>{_name}</span>
            </span>
          </Tooltip>
          {otherControls && (
            <>
              {otherControls}
            </>
          )}
        </span>
    )
  }
  return null;
};


const ChartLegend = (props) => {
  const {
    legendItems,
    styles
  } = props;
  const classes = useStyles();

  if (!_.isUndefined(legendItems) && _.isArray(legendItems)) {
    return (
      <div className={clsx(classes.container, props.classes && props.classes.container)} style={styles ? styles : {}}>
        {
          legendItems.map((legendItem, index) => <ChartLegendItem key={index} legendItem={legendItem} />)
        }
      </div>
    )
  }
  return null;
};

export default ChartLegend;