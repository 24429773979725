import React from "react";
import _ from "lodash";

import {Service} from "./service";
import {getDanteCustomerData, getStepAnswer, setQuestionAnswerFromConfig} from "./utils";
import {QuestionnairesHandlerResource} from "../../utils/api";
import {getErrorMessage} from "../../utils/utils";
import {SERVER_ERROR} from "../../utils/constants";
import {buildDataSteps, PROOF_ID} from "./mock_custody_certificate";


export class CustodyCertificateService extends Service  {
  async __setQuestionnaire(onboardingAnswers) {
    await super.__setQuestionnaire(onboardingAnswers);
    let customer = await getDanteCustomerData(this._customer_id);
    const preparedQuestionnaire = buildDataSteps();

    this._questionnaire = this.__deepCopy(preparedQuestionnaire);

    this._questionnaire.steps.map((step) => {
      (step.question || []).map((q) => {
        setQuestionAnswerFromConfig(q, customer);
      })
    });

    this._setDefaultAnswers(onboardingAnswers);
  }

  async __sendStepData() {
    if (this._step.uid === PROOF_ID) {
      const [isValid, errorMessage] = await this._sendDocument(this._step.question.find(q => q.uid === PROOF_ID));
      if(!isValid){
        throw errorMessage || 'Dante: Beim Hochladen des Sorgerechtsbescheinigung ist ein Fehler aufgetreten. Bitte versuchen Sie das Dokument erneut hochzuladen.';
      }

      await this._validateAccount();
    }

    return await super.__sendStepData(); // store answers in our db
  }

  getDataForAccount(validate) {
    let answers = {};
    this._questionnaire && this._questionnaire.steps.map(step => {
      if(step.question){
        (getStepAnswer(step).answers || []).map(answ => _.set(answers, answ.question_uid, answ.answer[0]))
      }
    });

    return answers;
  }

  async _sendDocument(question){
    let isValid = true, errorMessage;

    // if Document has formData - send it
    if (question && question.answer && question.answer.formData){
      const formData = question.answer.formData;

      try {
        const res = await QuestionnairesHandlerResource.at(
          `upload-customer-document/${this._customer_id}/`).post(formData);
        // if file was sent update GUID and remove formData to prevent send it again
        question.answer = {guid: res.id};
      } catch (e) {
        const ERROR_MESSAGES_TRANSLATIONS = {
          413: "Die hochgeladene Sorgerechtsbescheinigung ist zu groß",
          502: SERVER_ERROR
        };

        const msg = _.get(ERROR_MESSAGES_TRANSLATIONS, e.status) || getErrorMessage(e.data || e);

        question.error = _.get(e, 'data.errors.file.0', msg);
        errorMessage = _.get(e, 'data.message');
        isValid = false;
      }
    }

    return [isValid, errorMessage];
  }
}
