import React from "react";
import clsx from "clsx";

import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import {ArrowBack} from "@material-ui/icons";

import styles from "../styles";
import {getQuestionComponent, getQuestionSize, getQuestionType} from "../../../../../utils";
import PrimaryButton from "../../../../../../../components/Buttons/PrimaryButton";
import StepFooter from "../../StepFooter";
import StepName from "./components/StepName";
import {SAVE_AND_CONTINUE_BTN_LABEL} from "../../../../../constants";

const DynamicStep = props => {
  const {
    classes,
    uid,
    name,
    questions,
    info_text,
    help_text,
    required,
    isFirst,
    onNextClick,
    onPrevClick,
    onGoToStepClick,
    onAnswerChange,
    custom_classes,
    dataService,
    StepContentComponent,
    extraRightButtons,
  } = props;

  const handleAnswerChange = (question, answer) => {
    onAnswerChange(question, answer);
  };

  const renderQuestion = (question) => {
    const type = getQuestionType(question);
    const QuestionComponent = getQuestionComponent(type);

    return (
      <Grid item key={question.uid} data-id={question.uid} sm={getQuestionSize(question)}>
        {QuestionComponent && (
          <QuestionComponent
            question={question}
            onAnswerChange={handleAnswerChange}
            dataService={dataService}
          />
        )}
      </Grid>
    )
  };

  const BackButton = (
    <PrimaryButton
      text={"Zurück"}
      icon={<ArrowBack color={'primary'}/>}
      variant={"outlined"}
      onButtonClick={onPrevClick}
    />
  );

  const NextButton = (
    <PrimaryButton
      text={SAVE_AND_CONTINUE_BTN_LABEL}
      onButtonClick={onNextClick}
      disabled={false}
    />
  );

  const isNewDesign = dataService && dataService.isNewDesign;
  const leftButtons = isNewDesign ? [<><span style={{color: '#0092E5'}}>*</span> Pflichtangaben</>] : [BackButton];

  return (
    <div className={classes.stepContainer}>
      <div className={classes.stepScrollContainer}>
        <div className={clsx(classes.stepContent, 'app-page-container')}>
          {name && (
            <div className={clsx(classes.stepName, custom_classes && custom_classes.stepName)} data-id={`${uid}-name`}>
              <StepName name={name} />
            </div>
          )}
          {required && !isNewDesign && (
            <div className={classes.stepRequired}>
              *Pflichtfeld
            </div>
          )}
          {info_text && (
            <div className={classes.stepInfoText} data-id={`${uid}-info`}>
              <span className='far fa-info-circle' />
              &nbsp;
              {info_text}
            </div>
          )}
          {help_text && (
            <div className={classes.stepHelpText} data-id={`${uid}-help`}>
              {help_text}
            </div>
          )}
          {StepContentComponent && (
            <StepContentComponent
              questions={questions}
              onAnswerChange={handleAnswerChange}
              dataService={dataService}
              onGoToStepClick={onGoToStepClick}
            />
          )}
          {!StepContentComponent && questions && (
            <Grid container className={classes.questionsContent} spacing={2}>
              {questions.map(renderQuestion)}
            </Grid>
          )}
        </div>
        <div className={classes.scrollFix} />
      </div>

      <StepFooter
        leftItems={!isFirst && leftButtons}
        rightItems={[...extraRightButtons, NextButton]}
      />
    </div>
  );
};

DynamicStep.defaultProps = {
  extraRightButtons: []
};

export default withStyles(styles)(DynamicStep);
