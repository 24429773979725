import React from "react";
import _ from 'lodash';
import moment from "moment";

import Grid from "@material-ui/core/Grid";
import {
  getMainLegitimationData,
  isLegitimationQuestionsEmpty,
  renderQuestion
} from "../../../../../utils";
import {
  DefaultCountry, LEGITIMATION_MAPPING, LEGITIMATION_MINOR_MAPPING,
  NOT_COMPLETE_IDENTIFICATION_WARNING_MSG,
  OUTDATED_IDENTIFICATION_WARNING_MSG
} from "../../../../../constants";
import FormLabelElement from "../../formElement/LabelFormElement/LabelFormElement";
import useStyles from './styles'
import WarningIcon from "../../../../../../../components/Icons/WarningIcon";

const LegitimationStep = props => {
  const {
    onAnswerChange,
    questions: [
      type,
      number,
      issue_date,
      expiry_date,
      country,
      authority,
      original,
      front_side,
      rear_side,
      guid,
      legitimation_is_valid
    ],
    dataService,
  } = props;

  const classes = useStyles();
  const legitimationIsValidUID = legitimation_is_valid && legitimation_is_valid.uid;

  const _getAvailableFields = (stepsToSkip) => {

    let steps = [guid.uid, legitimationIsValidUID];
    if (stepsToSkip) {
      steps = steps.concat(stepsToSkip)
    }

    return props.questions
      .filter((question) => !steps.includes(question.uid))

  }

  const legitimationCompleted = !isLegitimationQuestionsEmpty(dataService.customer_type, dataService.step, ["legitimation['rear_side']"], false)
  const _prefillLegitimationWithOutdated = () => {

    let identificationStep = dataService.step
    if (!identificationStep || !identificationStep.hasOwnProperty('all_legitimations') || !identificationStep.all_legitimations.length) {
      return
    }

    const activeLegitimation = _.find(identificationStep.all_legitimations, (legitimation) => legitimation.main)
    if (!activeLegitimation) {
      return
    }

    if (!moment(activeLegitimation.expirationDate, 'DD.MM.YYYY').isBefore(moment(), 'day')) {
      return
    }

    expiry_date.disable_past = false
    onAnswerChange(expiry_date.uid, moment(activeLegitimation.expirationDate, 'DD.MM.YYYY'))

  }


  /**
   * Prefill inputs with active legitimation data from customer.
   * @private
   */
  const _prefillFieldsWithLegitimationData = () => {

    let identificationStep = dataService.step;
    if (!identificationStep || !identificationStep.hasOwnProperty('all_legitimations') || !identificationStep.all_legitimations.length) {
      return
    }

    const existingData = getMainLegitimationData(identificationStep.all_legitimations);
    if (!existingData) {
      return
    }

    onAnswerChange(type.uid, _.get(type, `config.mapping.config.${existingData.type}`));
    onAnswerChange(number.uid, existingData.identificationNumber)
    onAnswerChange(authority.uid, DefaultCountry) // Pre-fill with default country, as legitimation comes without country defined
    onAnswerChange(issue_date.uid, moment(existingData.dateOfIssue, 'DD.MM.YYYY'))
    onAnswerChange(expiry_date.uid, moment(existingData.expirationDate, 'DD.MM.YYYY'))
    onAnswerChange(country.uid, existingData.issuingAuthority)
    onAnswerChange(original.uid, existingData.shownOriginal)
    onAnswerChange(front_side.uid, {guid: _.get(existingData, 'identificationDocument.Legitimation'), formData: null})
    onAnswerChange(rear_side.uid, {guid: _.get(existingData, 'identificationDocument.LegitimationBack'), formData: null})
    onAnswerChange(guid.uid, existingData.GUID)
  }

  const handleFieldsOptionalProperty = (optional) => {

    const isFieldOptional = optional || !!(expiry_date.answer && expiry_date.disabled)

    _getAvailableFields([rear_side.uid])
      .forEach((question) =>  question.optional = isFieldOptional)
    _getAvailableFields()
      .forEach((question) =>  {
        if (question.optional) {

          if (!(question.uid === expiry_date.uid && question.disabled)) {
            question.answer = null
            if (question.hasOwnProperty('fileFormData')) {
              delete question.fileFormData
            }
          }

        }
        question.error = null
      })
    _getAvailableFields().forEach((question) => {

      // disabled expiry_date if it's optional or has answer
      question.disabled = optional || (question.uid == expiry_date.uid && !!question.answer)

    })

  }

  const afterLegitimationTypeChanged = (legitimationType) => {
    rear_side.error = null;
    if (legitimationType === LEGITIMATION_MAPPING['Reisepass']) {
      rear_side.question_text = 'Meldebescheinigung';
      rear_side.question_info = 'Wenn Sie Reisepass als Legitimationsart wählen, wird zukünftig von Seiten der Depotbank eine Meldebescheinigung benötigt.'
    } else {
      rear_side.question_text = 'Weitere Datei (z.B. Rückseite)';
      rear_side.question_info = '';
    };

    number.question_text = {
      [LEGITIMATION_MAPPING['Reisepass']]: 'Pass-Nr.',
      [LEGITIMATION_MAPPING.Personalausweis]: 'Ausweis-Nr.',
      [LEGITIMATION_MINOR_MAPPING.Geburtsurkunde]: 'Register-Nr.'
    }[legitimationType] || 'Ausweisnummer';
  };

  const handleChange = (question, value) => {

    if (question === type.uid) afterLegitimationTypeChanged(value)

    onAnswerChange(question, value);

    if (legitimationIsValidUID == question) {
      if (value) {
        // In case expiry date is disabled - outdated legitimation should be used.
        // Otherwise, user start editing - clean all inputs to continue with not completed identification
        if (expiry_date.disabled) {
          _prefillLegitimationWithOutdated()
        }
      } else if (!expiry_date.answer) {
        // In case expiry date does not have answer - not completed flow were used.
        // Prefill inputs with active legitimation data.

        _prefillFieldsWithLegitimationData()
      }
      handleFieldsOptionalProperty(value)
    } else {
      if (dataService.step.isIdentificationOutdated) {
        _getAvailableFields([rear_side.uid])
          .forEach((question) => {
            // For outdated identification documents expiry date could be in the past.
            // In case value was entered we need to put back correct validation for expiry date.
            question.optional = legitimation_is_valid && legitimation_is_valid.answer || false
            question.disabled = false
            if (question.uid == expiry_date.uid) {
              question.disable_past = true
            }
          })
      }
    }

  }

  /**
   * Workaround function, to insert empty space instead of removed 'place_of_issue' field
   *
   * @param question Question object
   */
  const renderLegitimationQuestion = (question) => {
    if (question.uid === type.uid) afterLegitimationTypeChanged(type.answer)

    return (
      <>
        {renderQuestion(question, handleChange, dataService)}
      </>
    )
  };

  return (
    <Grid container spacing={2}>

      {(dataService.step.isIdentificationOutdated && (expiry_date.disabled && expiry_date.answer)) ? (
        <Grid item xs={12}>
          <FormLabelElement
            label={OUTDATED_IDENTIFICATION_WARNING_MSG}
            customClasses={{
              labelRoot: classes.primaryColorText
            }}
          />
        </Grid>
      ) : (
        <>
          {
            dataService.is_trading && !legitimationCompleted && (
              <Grid item xs={12}>
                <FormLabelElement
                  label={NOT_COMPLETE_IDENTIFICATION_WARNING_MSG}
                  customClasses={{
                    labelRoot: classes.primaryColorText
                  }}
                />
              </Grid>
            )
          }
        </>
      )}

      {(dataService.is_trading && (dataService.step.isIdentificationOutdated || !legitimationCompleted)) && (
        <>
          <Grid item xs={12}>
            <FormLabelElement
              label={`Wir weisen darauf hin, dass Sie gemäß § 10 Abs. 1 Nr. 5 GwG allgemeine Sorgfaltspflichten, 
                      wie die kontinuierliche Überwachung der Geschäftsbeziehung, zu erfüllen haben.`}
              customClasses={{
                labelRoot: classes.legitimationHelpText
              }}
            />
            <FormLabelElement
              label={`Hierzu gehört auch, die Ausweisdokumente des Kunden unter Berücksichtigung des jeweiligen 
                      Risikos im angemessenen zeitlichen Abstand zu aktualisieren. Sie können die Angaben nun aktualisieren. 
                      Wenn Sie die aktuellen Daten an anderer Stelle gespeichert haben, bestätigen Sie dies bitte hier.`}
              customClasses={{
                labelRoot: classes.legitimationHelpText
              }}
            />
          </Grid>
          {legitimation_is_valid && (
            <>
              {renderQuestion(legitimation_is_valid, handleChange, dataService)}
            </>
          )}
        </>
      )}
      {(!dataService.is_trading && dataService.isNewDesign && legitimation_is_valid) && (
        <Grid item xs={12}>
          <div className={classes.warningCard}>
            <Grid container style={{marginLeft: 24}}>
              <Grid item xs={12} className={classes.legitimationHelpText} style={{position: 'relative'}}>
                <WarningIcon className={classes.warningIcon} /> <b>Warnung: Fehlende Legitimation</b>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabelElement
                      label={`Bitte beachten Sie, dass die Legitimation verpflichtend ist und vor Abschluss der Beratung 
                              vollständig vorliegen muss, insbesondere bei einer Depoteröffnung.`}
                      customClasses={{
                        labelRoot: classes.legitimationHelpText
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabelElement
                      label={`Sie können die Legitimation überspringen und später bereitstellen, wenn Sie die Checkbox wählen. 
                              Andernfalls müssen alle Pflichtangaben und das Legitimationsdokument jetzt bereitgestellt werden.`}
                      customClasses={{
                        labelRoot: classes.legitimationHelpText
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {renderQuestion(legitimation_is_valid, handleChange, dataService)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      )}
      {props.questions.filter(question => question.uid != legitimationIsValidUID).map(renderLegitimationQuestion)}
    </Grid>
  );
};

export default LegitimationStep;
