import React from "react";

import InputFormElement from "../../formElement/InputFormElement";
import {
  getQuestionType,
  isRequiredQuestion,
  isValidValue,
  isMiltilineQuestion,
  requiredCheck
} from "../../../../../utils";
import {FIELD_REQUIRED_MSG} from "../../../../../constants";
import {convertPhoneIfValid, validateEmail} from "../../../../../../../utils/utils";

function isInputQuestionValid(question, step, afterChange, service) {
  let isValid = true;
  if (isRequiredQuestion(question) && !isValidValue(question.answer)) {
    isValid = false;
    question.error = FIELD_REQUIRED_MSG
  }

  if (isValid && question.answer) {
    const input_type = getQuestionType(question, false);
    if (input_type === 'email' && !validateEmail(question.answer)) {
      isValid = false;
      question.error = 'Bitte tragen Sie eine gültige E-Mail Adresse ein.';
    } else {
      question.error = null
    }
  }

  return isValid
}

function initInputQuestionAnswer(question, answer, force) {
  if (answer && (answer.length > 0 || force)) {
    let value = answer[0];

    if(value && getQuestionType(question, false) === 'tel'){
      value = convertPhoneIfValid(value);
    }

    question.answer = value;
  }
}

export const InputQuestionMethods = {
  required: isRequiredQuestion,
  valid: isInputQuestionValid,
  multiline: isMiltilineQuestion,
  initAnswer: initInputQuestionAnswer,
};

const InputQuestion = props => {
  const {
    question,
    onAnswerChange
  } = props;

  const label = question.question_text;
  const required = InputQuestionMethods.required(question) || requiredCheck(question);
  const input_type = getQuestionType(question, false) || 'text';
  const multiline = InputQuestionMethods.multiline(question);
  const disabled = !!question.disabled;
  let placeholder = question.placeholder;
  if(!placeholder && input_type === 'tel'){
    placeholder = '+49 XXX XXXXXXX';
  }

  const handleChange = (value) => {
    question.error = null;  // clean error
    onAnswerChange(question.uid, value);
  };

  return (
    <InputFormElement
      label={label}
      value={question.answer || ''}
      multiline={multiline}
      required={required}
      onChange={handleChange}
      type={input_type}
      custom_classes={question.custom_classes}
      error={question.error}
      disabled={disabled}
      placeholder={placeholder}
    />
  )
};

export default InputQuestion;