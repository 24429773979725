import React from "react";
import Grid from "@material-ui/core/Grid";

import StepContent from "./StepContent";
import SideMenu from "../SideMenu";


const StepContentV2 = (props) => {
  const {
    classes,
    onNextClick,
    onPrevClick,
    onGoToStepClick,
    onResendResultClick,
    onConfirmResultClick,
    step,
    loading,
    isFirst,
    isLast,
    required,
    valid,
    onAnswerChange,
    successBody,
    nextStepBtnText,
    dataService,
    extraRightButtons,
    isNewDesign,
    answersData,
  } = props;

  const pageContent = (<StepContent
    step={step}
    loading={loading}
    isFirst={isFirst}
    isLast={isLast}
    onNextClick={onNextClick}
    onPrevClick={onPrevClick}
    onGoToStepClick={onGoToStepClick}
    onResendResultClick={onResendResultClick}
    onConfirmResultClick={onConfirmResultClick}
    required={required}
    valid={valid}
    onAnswerChange={onAnswerChange}
    successBody={successBody}
    nextStepBtnText={nextStepBtnText}
    dataService={dataService}
    extraRightButtons={extraRightButtons}
    isNewDesign={isNewDesign}
  />);

  if(isNewDesign){
    return (
      <Grid container style={{height: '100%'}}>
        <Grid item xs={12} sm={true} className={classes.sideBarGridItem}>
          <div className={classes.stepContainer}>
            <div className={classes.stepScrollContainer}>
              <SideMenu dataService={dataService} answersData={answersData} onGoToStepClick={onGoToStepClick} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={true} className={classes.withSideBar}>{pageContent}</Grid>
      </Grid>
    )
  }

  return pageContent;
};

export default StepContentV2;