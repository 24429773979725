/**
 * @typedef Asset
 * @type {Object}
 * @property {Number} id
 * @property {String} financial_information_isin
 * @property {String} financial_information_name
 * @property {Number} financial_information_class
 * @property {Number} financial_information_subclass
 * @property {String} asset_fund_category
 */

 /**
  * @typedef Customer
  * @type {Object}
  * @property {String} customer_full_name
  * @property {Array<Asset>} assets
  * @property {Array<{Object}>} group_identifiers
  */

import _ from 'lodash';

import {
  PORTFOLIO_PI_STRATEGY_IDENTIFIER,
  PORTFOLIO_NON_PI_STRATEGY_IDENTIFIER,
  PORTFOLIO_BANK_IDENTIFIER,
  PORTFOLIO_PRODUCT_CATEGORY_IDENTIFIER,
} from './constants';
import {
  CLIENT_FILTERS,
  CLIENT_TYPES
} from '../../components/FilteringPanel/components/ListSelector/constants'
import {
  PRODUCT_STRATEGIES,
  POSTBOX_STATUSES,
  PERMANENT_SUITABILITY_CHECK_STATUSES
} from '../../containers/Groups/components/FiltersPanel/FiltersPanel'
import {getRegExSafeString, getSortedObjects} from '../../utils/utils'

//#region Helpers


const getDepotStrategyName = (code) => {


  for (let i = 0; i < PRODUCT_STRATEGIES.length; i++) {
    if (PRODUCT_STRATEGIES[i].strategies.includes(code)) {
      return PRODUCT_STRATEGIES[i].description
    }
  }

  return code
}

export const getModelPortfolioIdentifier = (identifiers) => {
    return  getIdentifierByTypeId(identifiers, 31);
};

export const getClearingAccountNumber = (identifiers) => getIdentifierCodeByTypeId(identifiers, 3);

export const getIdentifierByTypeId = (identifiers, type_id) => {
  return _.find(identifiers || [], identifier => _.get(identifier, 'type.type_id') == type_id);
}

export const getIdentifierCodeByTypeId = (identifiers, type_id) => {
  const identifier = getIdentifierByTypeId(identifiers, type_id);
  return !!identifier ? identifier.code : undefined;
}


/**
 * Get portfolio category.
 *
 * @param {*} portfolio
 */
export const getPortfolioCategory = (portfolio) => {

  if (portfolio && portfolio.hasOwnProperty('identifiers')) {

    let { identifiers } = portfolio

    let modelportfolio = getModelPortfolioIdentifier(identifiers)
    let portfolioBank = getIdentifierByTypeId(identifiers, 22)
    let portfolioBankId = getIdentifierByTypeId(identifiers, 21)
    let depotStrategy = getIdentifierByTypeId(identifiers, 23)

    let name = ``
    if (!!modelportfolio) {
      name = `Modellportfolio ${modelportfolio.code}`
    }

    if (!!depotStrategy) {
      name = `${name}${!!modelportfolio ? ' - ': ''}${getDepotStrategyName(depotStrategy.code)}`
    }

    if (!!portfolioBank) {
      name = `${name}: ${portfolioBank.code}`
    }

    let modelPortfolioDataExists = true
    if (portfolio.is_private_investment && !portfolio.investment_strategy_data) {
      modelPortfolioDataExists = false
    } else if (portfolio.is_model_portfolio && !portfolio.model_portfolio_data) {
      modelPortfolioDataExists = false
    }

    const isETF = !!depotStrategy && depotStrategy.code == 'ETF';

    return {
      isModelportfolio: !!modelportfolio,
      isPrivateInvestment: portfolio.is_private_investment,
      mpData: modelportfolio,
      isETF: isETF,
      isDisabled: isETF || !modelPortfolioDataExists,
      categoryName: name,
      bankCode: portfolioBankId && portfolioBankId.code,
      disabledByMessage: !modelPortfolioDataExists && 'Für das gewählte Depot konnten leider die benötigten Daten nicht gefunden werden. Wenden Sie sich bitte an Ihren Kundenservice/Key Account.'
    }
  }

  return {
    isModelportfolio: false,
    isETF: false,
    isPrivateInvestment: false,
    isDisabled: true,
    categoryName: portfolio.not_connected ? '' : 'Unable to identifi category name'
  }

}


/**
 * Retrieve group identifier values from customer.
 *
 * @param {Customer} customer
 * @param {Number|String} groupIdentifier
 * @param {Function?} filter Function, that should be called in case some additional filtering required
 *
 * @returns {Array<Asset>} Values for requested group identifier
 */
const getGroupIdentifiers = (customer, groupIdentifier, filter) => {

  let groupIdentifiers = customer.group_identifiers;

  if(!_.isNil(groupIdentifiers) && groupIdentifiers.hasOwnProperty(groupIdentifier)) {


    if (filter && !filter(customer)) {
      return []
    }

    let result = groupIdentifiers[groupIdentifier];

    return Object.keys(result).map(key => ({
      value: key,
      description: result[key]
    }));
  }

  return [];
};

/**
 * Retrieve assets data from customer.
 *
 * @param {Customer} customer
 *
 * @return {Array<Asset>} Assets data
 */
const getAssets = (customer) => {

  let assets = customer.assets;

  if (_.isNil(assets)) {
    return [];
  }

  return assets;

};

/**
 * Check, if needed group identifier contains at least one value from required values.
 *
 * @param {Object} customer
 * @param {Number|String} groupIdentifier
 * @param {Array<{value:Number|String, description: Number|String}>} values
 * @param {String} checkField
 *
 * @returns {Boolean} Flag, that indicate, if customer's group identifiers contains needed values
 */
const isGroupIdentifierValid = (customer, groupIdentifier, values, checkField) => {

  let groupIdentifierValues = getGroupIdentifiers(customer, groupIdentifier);

  if (groupIdentifierValues.length == 0){
    return false;
  }

  for (let valueIndex = 0; valueIndex < values.length; valueIndex++) {
    let isValuePresent = !_.isNil(_.find(groupIdentifierValues,
      (identifierItem) => identifierItem[checkField || 'value'] == values[valueIndex].value));

    if (isValuePresent) {
      return true;
    }

  }

  return false;

};

/**
 * Check, if at least one asset has valid value in required field.
 *
 * @param {Array<Asset>} assets
 * @param {String} fieldName
 * @param {Array<Number|String>} values
 *
 * @returns {Boolean} Flag, that indicate, if assets contains valid asset item
 */
const isAssetsValid = (assets, fieldName, values) => {
  try {

    if (_.isNil(assets) || assets.length == 0) {
      return false;
    }

    for (let assetIndex = 0; assetIndex < assets.length; assetIndex++) {
      if (!_.isNil(_.find(values, valueItem => valueItem.value == assets[assetIndex][fieldName]))) {
        return true;
      }
    }

  } catch {
    return false;
  }
};

//#endregion

//#region Customers filtering functionality

/**
 * Check, if customer belongs to correct type.
 *
 * @param {Customer} customer
 * @param {{value: Number, description: String, type: Number}} customerType
 * @param {Object} brokerData
 *
 * @returns {Boolean} Flag, that indicate, if customer belongs to correct type.
 */
const isCustomerTypeValid = (customer, customerType, brokerData) => {

  try {

    if (_.isNil(customerType) || _.isNil(brokerData) || !brokerData.user.is_broker) {
      return false;
    }
    // filter customer by selected sub_agency
    switch (customerType.type) {
      case CLIENT_FILTERS.SUB_AGENCY: {
        return customer.agency == customerType.value;
      }
      case CLIENT_FILTERS.SUB_BROKER: {
        return customer.broker == customerType.value;
      }
    }


    switch (customerType.value) {
      case CLIENT_TYPES.SUB_AGENCY.value: {
        return customer.agency != brokerData.user.agency.agency_id;
      }
      case CLIENT_TYPES.AGENCY.value: {
        return customer.agency == brokerData.user.agency.agency_id;
      }
      default:
        return customer.broker == brokerData.user.broker_id;
    }

  } catch {
    return false;
  }

};

export const isNameValid = (name, value) => {

  let regExp = RegExp(`(${value})`, 'i');

  return name && (name.toLowerCase() == value.toLowerCase() || regExp.test(name));
}

/**
 * Check, if customer name is valid.
 *
 * @param {Customer} customer
 * @param {String} name
 *
 * @returns {Boolean} Flag, that indicate, if customer name is valid.
 */
export const isCustomerNameValid = (customer, value) => {

  try {

    let fullNameSplitted = customer.customer_full_name.split(',');
    let firstName = fullNameSplitted.length > 1 ? fullNameSplitted[0].trim() : customer.customer_full_name;
    let lastName = fullNameSplitted.length > 1 ? fullNameSplitted[1].trim() : customer.customer_full_name;

    let isFullNameValid = customer.customer_full_name && customer.customer_full_name.toLowerCase() == value.toLowerCase();
    let isFirstNameValid = isNameValid(firstName, value);
    let isLastNameValid = isNameValid(lastName, value);
    let isCustomerIdValid = isNameValid(customer.customer_id, value)

    return isFullNameValid || isFirstNameValid || isLastNameValid || isCustomerIdValid;

  } catch {
    return false;
  }

};

/**
 * Check, if at least one of the customer's assets contain needed isin.
 *
 * @param {Customer} customer
 * @param {String} isinValue
 *
 * @returns {Boolean} Flag, that indicate, if customer has needed isin inside assets
 */
const isIsinValid = (customer, isinValue) => {

  try {

    let assets = getAssets(customer);

    return isAssetsValid(assets, 'financial_information_isin', [{value: isinValue}]);

  } catch {
    return false;
  }

};

/**
 * Check, if at least one of the customer's assets contain needed asset.
 *
 * @param {Customer} customer
 * @param {String} assetName
 *
 * @returns {Boolean} Flag, that indicate, if customer has needed asset inside assets
 */
const isAssetNameValid = (customer, assetName) => {

  try {

    let assets = getAssets(customer);

    return isAssetsValid(assets, 'financial_information_name', [{value: assetName}]);

  } catch {
    return false;
  }

};

/**
 * Check if customer has proper customer_id
 * @param {Customer} customer
 * @param {String} customerId
 *
 * @returns {Boolean} Flag, that indicate, if customer has proper customer_id
 */
const isCustomerIdValid = (customer, customerId) => {
  try {
    // removes characters used for regex creation from customerId
    let safeCustomerId = getRegExSafeString(customerId)
    // creates regex with safe customerId
    let regExp = RegExp(`^(${safeCustomerId})`, 'i');
    // checks if customer_id of the customer contains safeCustomerId
    return regExp.test(_.get(customer, 'customer_id', ''));

  } catch {
    return false
  }
}

/**
 * Check, if customer belongs to needed portfolio banks.
 *
 * @param {Customer} customer
 * @param {Array<{value: Number|String, description: Number|String}>} portfolioBanks
 * @param {Array<{value: Number|String, description: Number|String}>} portfolioBanksAllowed
 *
 * @returns {Boolean} Flag, that indicate, if customer belongs to needed portfolio banks
 */
const isPortfolioBankValid = (customer, portfolioBanks, portfolioBanksAllowed) => {

  try {

    if (_.isNil(portfolioBanks) || portfolioBanks.length == 0) {
      return false;
    }

    if (!_.isNil(portfolioBanksAllowed) && portfolioBanks.length == portfolioBanksAllowed.length) {
      return true;
    }

    return isGroupIdentifierValid(customer, PORTFOLIO_BANK_IDENTIFIER, portfolioBanks);

  } catch {
    return false;
  }

};

/**
 * Check, if customer belongs to provided fund categories.
 *
 * @param {Customer} customer
 * @param {Array<{value: Number|String, description: Number|String}>} fundCategories
 * @param {Array<{value: Number|String, description: Number|String}>} fundCategoriesAllowed
 *
 * @returns {Boolean} Flag, that indicate, if customer belongs to needed fund categories
 */
const isFundCategoryValid = (customer, fundCategories, fundCategoriesAllowed) => {

  try {

    if (!_.isNil(fundCategories) && !_.isNil(fundCategoriesAllowed) && fundCategories.length == fundCategoriesAllowed.length) {
      return true;
    }

    let assets = customer.assets;

    return isAssetsValid(assets, 'asset_fund_category', fundCategories);

  } catch {
    return false;
  }

};

/**
 * Check, if customer belongs to provided investment compay.
 *
 * @param {Customer} customer
 * @param {Array<{value: Number|String, description: Number|String}>} investmentCompanies
 * @param {Array<{value: Number|String, description: Number|String}>} investmentCompaniesAllowed
 *
 * @returns {Boolean} Flag, that indicate, if customer belongs to needed investment company
 */
const isInvestmentCompanyValid = (customer, investmentCompanies, investmentCompaniesAllowed) => {

  try {

    if (!_.isNil(investmentCompanies) && !_.isNil(investmentCompaniesAllowed) && investmentCompanies.length == investmentCompaniesAllowed.length) {
      return true;
    }

    let assets = customer.assets;

    return isAssetsValid(assets, 'asset_info_investment_company', investmentCompanies);

  } catch {
    return false;
  }

};

/**
 * Check, if customer belongs to provided financial classes.
 *
 * @param {Customer} customer
 * @param {Array<{value: String|Number, description: Number|String, financialClasses: Array<Number>, financialSubclasses: Array<Number>}>} financialClasses
 *
 * @returns {Boolean} Flag, that indicate, if customer belongs to financial classes
 */
const isFinancialClassesValid = (customer, financialClasses, financialClassesAllowed) => {
  try {

    let assets = getAssets(customer);

    if (assets.length == 0 || _.isNil(financialClasses) || financialClasses.length == 0) {
      return false;
    }

    if (!_.isNil(financialClassesAllowed) && financialClasses.length == financialClassesAllowed.length) {
      return true;
    }

    for (let assetIndex = 0; assetIndex < assets.length; assetIndex++) {

      let isAssetValid = !_.isNil(_.find(financialClasses, finClass => {

        let classes = finClass.financialClasses;
        let subclasses = finClass.financialSubclasses;
        let isClassValid = true;
        let isSubclassValid = true;

        if (!_.isNil(classes)) {
          isClassValid = !_.isNil(_.find(classes, classData => classData == assets[assetIndex].financial_information_class));
        }

        if (!_.isNil(subclasses)) {
          isSubclassValid = !_.isNil(_.find(subclasses, classData => classData == assets[assetIndex].financial_information_subclass));
        }

        /*if (!_.isNil(classes) && _.isNil(subclasses) && _.includes(classes, 6) && assets[assetIndex].financial_information_subclass == 601) {
          isSubclassValid = false;
        }*/

        return isClassValid && isSubclassValid;

      }));

      if (isAssetValid) {
        return true;
      }

    }

    return false;

  } catch {
    return false;
  }
};

/**
 * Check, if customer belongs to provided product strategies.
 *
 * @param {Customer} customer
 * @param {Array<{value: Number|String, description: Number|String, strategies: Array<Number>}>} strategies
 * @param {Array<{value: Number|String, description: Number|String}>} strategies
 * @param {Array<{value: Number|String, description: Number|String}>} strategiesAllowed
 * @param {Integer} identifier
 *
 * @returns {Boolean} Flag, that indicate, if customer belongs to provided product strategies.
 */
const isStrategyValid = (customer, strategies, strategiesAllowed, identifier) => {
  let isValid = false;
  if (!_.isNil(strategies) && strategies.length == 0 && !_.isNil(strategiesAllowed) && strategiesAllowed.length == 0) {
    isValid = true;
  } else if (!_.isNil(strategies) && !_.isNil(strategiesAllowed) && strategies.length == strategiesAllowed.length) {
    isValid = true;
  } else {
    isValid = isGroupIdentifierValid(customer, identifier, strategies);
  }
  return isValid
}


/**
 * Check, if customer belongs to provided product strategies.
 *
 * @param {Customer} customer
 * @param {Array<{value: Number|String, description: Number|String, strategies: Array<Number>}>} productStrategies
 * @param {Array<{value: Number|String, description: Number|String}>} strategies
 * @param {Array<{value: Number|String, description: Number|String}>} strategiesAllowed
 * @param {Number} indentifier
 * @param {Boolean} isPI
 *
 * @returns {Boolean} Flag, that indicate, if customer belongs to provided product strategies.
 */
 const isProductStrategiesValid = (customer, productStrategies, strategies, strategiesAllowed, indentifier, isPI) => {
  try {
    let strategyValid = false;

    for (let strategyIndex = 0; strategyIndex < productStrategies.length; strategyIndex++) {
      if (isGroupIdentifierValid(customer, PORTFOLIO_PRODUCT_CATEGORY_IDENTIFIER, [productStrategies[strategyIndex]], 'description')) {

        const strategy = productStrategies[strategyIndex].value;
        const isAcceptedCategory = isPI ? strategy == 'PI' : strategy != 'PI';

        if (isAcceptedCategory) {
          strategyValid = isStrategyValid(customer, _.isEmpty(strategies) ? strategiesAllowed : strategies, strategiesAllowed, indentifier)
        }
      }
    }
    return strategyValid;
  } catch {
    return false;
  }
};


/** Get pre-defined filters options, that could be used for filtering
 * @param {Array<Customer>} customers
*/
const getAllowedPreDefinedOptions = (customers) => {

  let allowedOptions = {
    financialInformationClass: [],
    financialInformationSubclass: []
  };

  customers.forEach(customer => {

    let assets = getAssets(customer);

    if (assets) {

      assets.forEach(asset => {
        allowedOptions.financialInformationClass.push(asset.financial_information_class);
        allowedOptions.financialInformationSubclass.push(asset.financial_information_subclass);
      });

    }

  });

  allowedOptions.financialInformationClass =  _.uniqBy(_.compact(allowedOptions.financialInformationClass), option => option);
  allowedOptions.financialInformationSubclass =  _.uniqBy(_.compact(allowedOptions.financialInformationSubclass), option => option)

  return allowedOptions
}

/**
 * Check, if the customer's postbox status is needed value.
 *
 * @param {Customer} customer
 * @param {Array<{value: Boolean, description: String}>} postboxStatuses
 *
 * @returns {Boolean} Flag, that indicate, if customer has needed postbox value
 */
 const isCustomerPostboxValid = (customer, postboxStatuses) => {

    return _.findIndex(postboxStatuses, (status) => { return _.isEqual(status.value, customer.postbox) }) > -1;
};

 /**
 * Check, if the customer's postbox status is needed value.
 *
 * @param {Customer} customer
 * @param {Array<{value: Boolean, description: String}>} statuses
 *
 * @returns {Boolean} Flag, that indicate, if customer has permanent suitability check
 */
 const isCustomerPermanentSuitabilityCheck = (customer, statuses) => {
   const riskProfileExists = _.get(customer, 'last_srri.srri');

   // if no SRRI - permanent_suitability_check not possible at all for customer
   if (_.isNil(riskProfileExists)) return false;

   return _.findIndex(statuses, (status) => status.value === !!customer.permanent_suitability_check_enabled) > -1;
};

/**
 * Check, if the customer's depot value is withing the range
 *
 * @param {Customer} customer
 * @param {Array<Float>} range
 *
 * @returns {Boolean} Flag, that indicate, if customer's depot value is withing the range
 */
const isDepotRangeValid = (customer, range) => {
  let [lowerBoundary, upperBoundary] = range;
  let portfolioValue = Math.round(customer.aggregated_portfolio_value * 100) / 100;

  return (lowerBoundary <= portfolioValue) && (portfolioValue <= upperBoundary);
}
//#endregion


const convertListToFilterOptions = (list) => {
  return list.map(item => ({value: item, description: item}));
}

/**
 * Convert product strategies to format, that could be used for filtering.
 *
 * @returns {Array<{value: Number|String, description: Number|String}>} Product strategies in correct format
 */
const splitProductStrategiesOptions = (productStrategies, excludePI=false, excludeNonPI=false) => {
  let pi = [], nonePi = [];

  productStrategies && productStrategies.forEach(strategy => {
    strategy.strategies.forEach(strategyItem => {
      if(strategyItem == 'PI') {
        if (!excludePI) {
          pi.push({value: strategyItem, description: strategyItem})
        }
      }
      else if (!excludeNonPI) {
        nonePi.push({value: strategyItem, description: strategyItem})
      }
    });
  });

  return [pi, nonePi];
}

const isApplyFilter = (filters, filterKey) => {
  return filters.hasOwnProperty(filterKey) && !_.isNil(filters[filterKey]) && !_.isEmpty(filters[filterKey]);
}

export const filterCustomersData = (customers, filters, allowedOptions) => {

  let filteredCustomers = [];

  customers.forEach(customer => {

    if (filters.hasOwnProperty('clientType') && !_.isNil(filters.clientType)) {
      if (!isCustomerTypeValid(customer, filters.clientType, filters.broker || undefined)) {
        return;  // return is used as 'continue' because if customer failed at least one filter - it is invalid
      }
    }

    if (filters.hasOwnProperty('search') && !_.isNil(filters.search) && filters.search.length > 0) {

      let isNameValid = isCustomerNameValid(customer, filters.search);
      let isIsinNameValid = isIsinValid(customer, filters.search);
      let isInstrumentNameValid = isAssetNameValid(customer, filters.search);
      let isIdValid = isCustomerIdValid(customer, filters.search);

      if (!isNameValid && !isIsinNameValid && !isInstrumentNameValid && !isIdValid) {
        return;
      }
    }

    if (isApplyFilter(filters, 'financialInformationClass')) {
      if (!isFinancialClassesValid(customer, filters.financialInformationClass, allowedOptions.allowedOptions.financialInformationClass)) {
        return;
      }
    }

    if (isApplyFilter(filters, 'portfolioBanks')) {
      if (!isPortfolioBankValid(customer, filters.portfolioBanks, allowedOptions.portfolioBanks)) {
        return;
      }
    }

    if (isApplyFilter(filters, 'fundCategories')) {
      if (!isFundCategoryValid(customer, filters.fundCategories, allowedOptions.fundCategories)) {
        return;
      }
    }

    if (isApplyFilter(filters, 'investmentCompanies')) {
      if (!isInvestmentCompanyValid(customer, filters.investmentCompanies, allowedOptions.investmentCompanies)) {
        return;
      }
    }

    // if apply productStrategies filter use correct strategies
    let pi, nonePi;
    if (isApplyFilter(filters, 'productStrategies')) {
      [pi, nonePi] = splitProductStrategiesOptions(filters.productStrategies)
    } else { // use all categories for selected strategies
      [pi, nonePi] = splitProductStrategiesOptions(allowedOptions.productCategoriesOptions,
        _.isEmpty(filters.portfolioStrategiesPI), _.isEmpty(filters.portfolioStrategiesNonPI))
    }

    if (!_.isEmpty(pi) && !isProductStrategiesValid(customer, pi, filters.portfolioStrategiesPI, allowedOptions.portfolioStrategiesPI, PORTFOLIO_PI_STRATEGY_IDENTIFIER, true)) {
      return;
    }

    if (!_.isEmpty(nonePi) && !isProductStrategiesValid(customer, nonePi, filters.portfolioStrategiesNonPI, allowedOptions.portfolioStrategiesNonPI, PORTFOLIO_NON_PI_STRATEGY_IDENTIFIER, false)) {
      return;
    }

    if (isApplyFilter(filters, 'customerPostboxStatuses')) {
      if (!isCustomerPostboxValid(customer, filters.customerPostboxStatuses)) {
        return;
      }
    }

    if (isApplyFilter(filters, 'permanentSuitabilityCheckStatuses')) {
      if (!isCustomerPermanentSuitabilityCheck(customer, filters.permanentSuitabilityCheckStatuses)) {
        return;
      }
    }

    if (isApplyFilter(filters, 'depotRange')) {
      if (!isDepotRangeValid(customer, filters.depotRange)) {
        return;
      }
    }


    filteredCustomers.push(customer); // if customer passed all filters it is added to list
  });
  return filteredCustomers;
};

export const getCustomersFilters = (customers) => {
  let filtersOptions = {
    fundCategories: [],
    investmentCompanies: [],
    portfolioBanks: [],
    portfolioStrategiesPI: [],
    portfolioStrategiesNonPI: [],
    productCategories: [],
    productCategoriesOptions: [],
    depotRange: [0, 0]
  };

  let maxUpperBoundary = 0;
  customers.forEach(customer => {
    let assets = customer.assets || [];
    assets.forEach(asset => {

      filtersOptions.fundCategories.push(asset.asset_fund_category);
      filtersOptions.investmentCompanies.push(asset.asset_info_investment_company);

    });
    maxUpperBoundary = Math.max(maxUpperBoundary, customer.aggregated_portfolio_value);

    filtersOptions.portfolioBanks = [...filtersOptions.portfolioBanks, ...getGroupIdentifiers(customer, PORTFOLIO_BANK_IDENTIFIER)];
    filtersOptions.portfolioStrategiesPI = [
      ...filtersOptions.portfolioStrategiesPI,
      ...getGroupIdentifiers(
        customer,
        PORTFOLIO_PI_STRATEGY_IDENTIFIER,
        (customer) => isGroupIdentifierValid(
          customer, PORTFOLIO_PRODUCT_CATEGORY_IDENTIFIER, [{value: 'PI'}], 'description'))];
    filtersOptions.portfolioStrategiesNonPI = [...filtersOptions.portfolioStrategiesNonPI, ...getGroupIdentifiers(customer, PORTFOLIO_NON_PI_STRATEGY_IDENTIFIER)];
    filtersOptions.productCategories = [...filtersOptions.productCategories, ...getGroupIdentifiers(customer, PORTFOLIO_PRODUCT_CATEGORY_IDENTIFIER)];
  });

  let roundedUpperBoundary = Math.round(maxUpperBoundary * 100) / 100;
  filtersOptions.depotRange = [0, roundedUpperBoundary];
  filtersOptions.fundCategories = convertListToFilterOptions(_.compact(_.uniqBy(filtersOptions.fundCategories, option => option)));
  filtersOptions.investmentCompanies = convertListToFilterOptions(_.compact(_.uniqBy(filtersOptions.investmentCompanies, option => option)));
  filtersOptions.portfolioBanks = _.uniqBy(_.compact(filtersOptions.portfolioBanks), option => option.value);
  filtersOptions.portfolioStrategiesPI = _.uniqBy(_.compact(filtersOptions.portfolioStrategiesPI), option => option.value);
  filtersOptions.portfolioStrategiesNonPI = _.uniqBy(_.compact(filtersOptions.portfolioStrategiesNonPI), option => option.value);
  filtersOptions.productCategories = _.uniqBy(_.compact(filtersOptions.productCategories), option => option.value);

  const getProductCategoriesOptions = () => {

    if (!filtersOptions.productCategories) {
      return PRODUCT_STRATEGIES
    }

    let allowedOptions = [];

    PRODUCT_STRATEGIES.forEach(strategy => {
      let isStrategyPresent = false;

      strategy.strategies.forEach(item => {

        if (!_.isNil(_.find(filtersOptions.productCategories, category => category.description == item))) {
          isStrategyPresent  = true;
        }

      });

      if (isStrategyPresent) {
        allowedOptions.push(strategy);
      }

    });

    return allowedOptions

  }

  filtersOptions.productCategoriesOptions = getProductCategoriesOptions();

  filtersOptions.allowedOptions = getAllowedPreDefinedOptions(customers);

  filtersOptions.customerPostboxStatuses = POSTBOX_STATUSES;

  filtersOptions.permanentSuitabilityCheckStatuses = PERMANENT_SUITABILITY_CHECK_STATUSES;

  // sort options
  filtersOptions.investmentCompanies = getSortedObjects(filtersOptions.investmentCompanies, 'description')
  filtersOptions.portfolioBanks = getSortedObjects(filtersOptions.portfolioBanks, 'description')
  filtersOptions.portfolioStrategiesNonPI = getSortedObjects(filtersOptions.portfolioStrategiesNonPI, 'description')

  return filtersOptions;
}