import { makeStyles} from "@material-ui/core";
import {WARNING_BACKGROUND, WARNING_COLOR} from "../../../../../../../themes/themeConfigurator";

export default makeStyles(theme => ({
  primaryColorText: {
    color: theme.palette.primary.main
  },

  outdatedInfoText: {
    fontSize: '0.875rem',
    lineHeight: 1.66,
    fontFamily: 'Roboto-Regular',
    color: 'black'
  },

  mismatchWarningMessage: {
    color: theme.palette.primary.main
  },

  confirmButton: {
    marginLeft: 15
  },

  legitimationHelpText: {
    paddingBottom: 0,
    marginBottom: 0,
    lineHeight: '1.66em',
    fontSize: 16,
  },
  warningCard: {
    background: WARNING_BACKGROUND,
    borderLeft: `6px solid ${WARNING_COLOR}`,
    padding: theme.spacing(3),
  },
  warningIcon: {
    fontSize: '1.25em',
    position: 'absolute',
    left: -28,
    top: 3,
  }
}))
