import React from "react";
import clsx from "clsx";
import _ from 'lodash';

import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorIcon from "@material-ui/icons/Error";
import withStyles from "@material-ui/core/styles/withStyles";

import styles from "./styles";
import {getErrorMessage} from "../../../../../../utils/utils";
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../../../../../utils/constants";

const DashboardSection = props => {
  const {
    loading,
    error,
    title,
    titleControl,
    content,
    classes,
    minHeight,
    minWidth,
    empty,
    emptyMessage,
    displayError,
    style
  } = props;


  const errorContent = (
    <>
      {displayError && !_.isBoolean(error) && getErrorMessage(error) ? (
         <p className={classes.emptyMessage}>{getErrorMessage(error)}</p>
      ) : (
        <ErrorIcon/>
      )}
    </>
  );

  const emptyContent = (
    <p className={classes.emptyMessage}>{emptyMessage || DEFAULT_EMPTY_SECTION_MESSAGE}</p>
  );

  return (
    <div className={classes.container} style={{
      minHeight: minHeight || 'auto',
      ...(style || {})
    }}>
      <div className={classes.headerContainer}>
        <p className={classes.header}>
          {title}
        </p>
        {titleControl && (
          <div className={classes.titleControl}>
            {titleControl}
          </div>
        )}
      </div>

      <div style={{minWidth: minWidth || 0}} className={clsx(classes.contentContainer, (loading || error || empty) && classes.centerContent)}>
        {loading ? ( <CircularProgress/> ) : error ? errorContent : empty ? emptyContent : content}
      </div>
    </div>
  );
};

export default withStyles(styles)(DashboardSection);