import React from "react";
import _ from 'lodash'

import {TradeMenuItem} from "./TradeMenuItem";
import {
  SAVING_PLAN_TRANSACTION_TYPE,
  TRANSACTION_INSTR,
  TRANSACTION_INSTR_ORDERING_FIELD_NAMES,
  TRANSACTION_TYPE,
  TRANSACTION_TYPE_VALUES
} from "../constants";
import {BankSettingsHandlerResource} from "../../../utils/api";
import {_prepareBanksFromResponse, getDanteCustomerData, getTargetMarketAnswers} from "../../RiskProfiling/utils";
import {
  getAvailableOrderingFields,
  getBookingDate,
  getModelPortfolioInstrumentType,
  getModelPortfolioNumberFromPortfolio,
  updateInstrumentIdInstrumentType
} from "../utils";
import moment from "moment";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import DeleteIcon from "../../../images/DeleteIcon";
import {PROCESS_TYPE, VALID_UNTIL_TODAY_VALUE} from "../../RiskProfiling/constants";
import {getInstrumentName} from "../utils";
import {getSubSystemConfigItem} from "../../../utils/utils";
import {getIdentifierCodeByTypeId} from "../../../components/CustomersDataProvider/utils";

const PORTFOLIO_NR_ID = 37;

export class TradeDetailsMenuItem extends TradeMenuItem {

  constructor(uid, name, customer_id, questionnaire, getPrevStepAnswer) {
    super(uid, name, customer_id, questionnaire, getPrevStepAnswer);
    this.__banksDataDepotTypes = null;
    this._target_market = undefined;
    this._service_concept = undefined;
    this._srri = undefined;
  }

  _get_sub_depot = (item, convertToInt, isDst) => {
    // Function to get depot_sub_number from item

    if (!getSubSystemConfigItem('trading', 'sub_depots_trade_enabled')) return;

    let subDepotNumber = _.get(item, 'data.depot_sub_number');
    let subDepotType, subDepotNr = subDepotNumber;
    if (convertToInt && !_.isNil(subDepotNumber)) {
      // if sub depot starts with digits -> use them as "nr" and al the rest as "type"
      const m = subDepotNumber.match(/^\d+/);

      if (!_.isNil(m)){
        subDepotNr = parseInt(m[0]);
        subDepotType = subDepotNumber.substring(m[0].length) || undefined  // do not send empty string;
      }
    }

    const prefix = _.isUndefined(isDst) ? '' : isDst ? 'dst_' : 'src_';

    return {
      [`${prefix}sub_depot_nr`]: subDepotNr,
      [`${prefix}sub_depot_type`]: subDepotType
    }
  };

  _get_portfolio_nr = (item) => getIdentifierCodeByTypeId(_.get(item, 'data.identifiers'), PORTFOLIO_NR_ID);

  _prepareCreateOrdersTransaction = (portfoliosTransactions) => {

    let transactions = [];

    function roundValue(value) {
      return _.round(value, 2)
    }

    portfoliosTransactions.map(portfolio => {
      let order_details = [];
      const isUseProductId = _.get(portfolio, 'is_use_product_id');
      const depotNumber = _.get(portfolio, 'data.number');
      const depotTypeId = _.get(portfolio, 'depotType.value');
      const portfolioNr = this._get_portfolio_nr(portfolio);
      const companyId = _.get(portfolio, 'companyId');
      const convertSubDepotNrToInt = _.get(portfolio, 'convert_sub_depot_nr_to_int');

      Object.keys(TRANSACTION_TYPE).forEach(transaction_type => {
        const isAllAvailable = getAvailableOrderingFields(portfolio, transaction_type).includes(
          TRANSACTION_INSTR_ORDERING_FIELD_NAMES.is_all);

        (portfolio.transactions[transaction_type] || []).forEach(item => {
          if(transaction_type === TRANSACTION_TYPE_VALUES.SWITCH){
            let totalPercent = 0, usePercentage = item.sell_all && portfolio.usePercentageForSwitchAll;
            item.buy.forEach((switchInItem, idx) => {
              let order = {
                ...this._get_sub_depot(item, convertSubDepotNrToInt, false),
                ...this._get_sub_depot(switchInItem, convertSubDepotNrToInt, true),
                src_instrument: item.data.isin,
                dst_instrument: switchInItem.data.isin,
                src_instrument_name: getInstrumentName(item.data),
                dst_instrument_name: getInstrumentName(switchInItem.data),
                discount: switchInItem.discount || undefined,  // undefined will be not send
                transaction_type: transaction_type,
                portfolio_number: portfolioNr,
              };
              if (usePercentage) {
                const percent = roundValue(switchInItem.calculated.transaction_value_percentage);
                order.percent = percent;
                totalPercent += percent;

                // if total != 100 add difference to last element
                if (idx === item.buy.length - 1 && totalPercent !== 100){
                  order.percent = roundValue(order.percent + (100 - totalPercent));
                }
              } else {
                order.quantity = switchInItem.transaction_value; // We use only QTY for switch transactions
              }
              updateInstrumentIdInstrumentType(item, order, 'src_instrument', depotNumber, companyId, isUseProductId, 'src_instrument_type');
              updateInstrumentIdInstrumentType(switchInItem, order, 'dst_instrument', depotNumber, companyId, isUseProductId);
              order_details.push(order);
            });
          } else {
            let order = {
              discount: item.discount || undefined,  // undefined will be not send,,
              booking_date: getBookingDate(item),
              transaction_type: transaction_type,
              portfolio_number: portfolioNr,
              ...this._get_sub_depot(item, convertSubDepotNrToInt, transaction_type == TRANSACTION_TYPE_VALUES.BUY),
            };
            if (_.get(portfolio.available_asset_classes, item.data.internal_asset_class)) {
              order.instrument_type = `isin ${item.data.internal_asset_class}`
              if(item.sell_all && isAllAvailable){
                order.is_all = true;
              }
              order.amount_eur = roundValue(item.transaction_value);
              order.quantity = item.quantity;
              order.order_type = item.order_type !== 'no order' ? item.order_type : undefined
              order.limit_amount_eur = item.limit || 0;
              if (item.valid_until === VALID_UNTIL_TODAY_VALUE) {
                order.valid_until_today = true;
              } else {
                order.valid_until = item.valid_until;
              }
              order.stock_exchange = item.stock_exchange
            } else {
              if (_.get(portfolio, 'data.is_model_portfolio')) {
                order.instrument_type = getModelPortfolioInstrumentType(portfolio);
                item.data.isin = getModelPortfolioNumberFromPortfolio(portfolio)
              }

              if (item.sell_all && isAllAvailable) {
                order.is_all = true;
              } else if (item.transaction_type === TRANSACTION_INSTR.amount) {
                order.amount_eur = roundValue(item.transaction_value);
              } else {
                order.quantity = item.transaction_value;
              }
            }

            if (transaction_type === TRANSACTION_TYPE_VALUES.SELL) {
              order.src_instrument = item.data.isin;
              order.src_instrument_name = getInstrumentName(item.data);
              updateInstrumentIdInstrumentType(item, order, 'src_instrument', depotNumber, companyId, isUseProductId);
            } else {
              order.dst_instrument = item.data.isin
              order.dst_instrument_name = getInstrumentName(item.data);
              updateInstrumentIdInstrumentType(item, order, 'dst_instrument', depotNumber, companyId, isUseProductId);
            }

            order_details.push(order);
          }
        });
      });

      if(order_details.length > 0){
        transactions.push({
          bank: portfolio.bankCode,
          portfolioId: portfolio.portfolioId,
          custodian_id: companyId,
          depotNumber,
          depotTypeId,
          depotName: portfolio.data.name,
          order_details: order_details
        })
      }
    });

    return transactions
  }

  _prepareCreatePaymentPlansTransaction = (portfoliosTransactions) => {

    let transactions = []

    // Workaround to get start date for DELETE savings plans,
    // as CIOS.Connect require valid start date for all types of action
    const getDate = (date, transaction_type) => {

      if (transaction_type == 'delete') {
        return moment().add(1, 'day').format('YYYY-MM-DD')
      }

      return moment(date).format('YYYY-MM-DD')
    }

    portfoliosTransactions.forEach(portfolio => {
      let order_details = [];
      const isUseProductId = _.get(portfolio, 'is_use_product_id');
      const depotNumber = _.get(portfolio, 'data.number');
      const portfolioNr = this._get_portfolio_nr(portfolio);
      const depotTypeId = _.get(portfolio, 'depotType.value');
      const companyId = _.get(portfolio, 'companyId');
      const convertSubDepotNrToInt = _.get(portfolio, 'convert_sub_depot_nr_to_int');

      const isSavingsPlans = portfolio.transactions.savings_plan && !_.isEmpty(portfolio.transactions.savings_plan);
      const isPayoutPlans = portfolio.transactions.payout_plan && !_.isEmpty(portfolio.transactions.payout_plan);
      let paymentPlanKey = 'switch_plan';
      if (isSavingsPlans) {
        paymentPlanKey = 'savings_plan'
      } else if (isPayoutPlans) {
        paymentPlanKey = 'payout_plan'
      }

      for (let transaction_type in SAVING_PLAN_TRANSACTION_TYPE) {

        if (!portfolio.transactions || !portfolio.transactions[paymentPlanKey]) {
          break
        }

        portfolio.transactions[paymentPlanKey]
          .filter(i => i.is_changed && i.action == transaction_type).forEach(item => {

            if (paymentPlanKey === 'switch_plan') {
              item.buy.forEach(switchInItem => {
                let order = {
                  ...this._get_sub_depot(item, convertSubDepotNrToInt, false),
                  ...this._get_sub_depot(switchInItem, convertSubDepotNrToInt, true),
                  src_instrument: item.data.isin,
                  dst_instrument: switchInItem.data.isin,
                  src_instrument_name: getInstrumentName(item.data),
                  dst_instrument_name: getInstrumentName(switchInItem.data),
                  amount_eur: switchInItem.transaction_value,
                  transaction_type: transaction_type,
                  start_date: getDate(item.from_date, item.action),
                  periodic_plan: item.rotation,
                  contract_number: item.data.contract_number || undefined,  // undefined will be not send
                  portfolio_number: portfolioNr,
                };
                updateInstrumentIdInstrumentType(item, order, 'src_instrument', depotNumber, companyId, isUseProductId, 'src_instrument_type');
                updateInstrumentIdInstrumentType(switchInItem, order, 'dst_instrument', depotNumber, companyId, isUseProductId);

                if (item.till_date) {
                  order.end_date = getDate(item.till_date, item.action)
                }

                if (switchInItem.discount) {
                  order.discount = switchInItem.discount || undefined  // undefined will be not send,
                }


                order_details.push(order);
              });
            } else {
              const order = {
                ...this._get_sub_depot(item, convertSubDepotNrToInt),
                instrument: item.data.isin,
                instrument_name: getInstrumentName(item.data),
                // TODO dst_insrument is depracated and can be removed(sparplans)
                dst_instrument: item.data.isin,
                dst_instrument_name: getInstrumentName(item.data),
                //
                start_date: getDate(item.from_date, item.action),
                amount_eur: item.transaction_value,
                periodic_plan: item.rotation,
                contract_number: item.data.contract_number || undefined,  // undefined will be not send
                transaction_type: transaction_type,
                portfolio_number: portfolioNr,
              };

              if (isSavingsPlans) {
                order.discount = item.discount || undefined  // undefined will be not send,
              }

              if (item.till_date) {
                order.end_date = getDate(item.till_date, item.action)
              }

              if(_.get(portfolio, 'data.is_model_portfolio')){
                const mpNumber = getModelPortfolioNumberFromPortfolio(portfolio)
                order.instrument_type = getModelPortfolioInstrumentType(portfolio);
                order.instrument = mpNumber;
                order.dst_instrument = mpNumber
              }
              updateInstrumentIdInstrumentType(item, order, 'instrument', depotNumber, companyId, isUseProductId);
              updateInstrumentIdInstrumentType(item, order, 'dst_instrument', depotNumber, companyId, isUseProductId);

              order_details.push(order)
            }
        })
      }

      if(order_details.length > 0){
        transactions.push({
          bank: portfolio.bankCode,
          depotNumber: portfolio.data.number,
          depotTypeId,
          depotName: portfolio.data.name,
          custodian_id: companyId,
          portfolioId: portfolio.portfolioId,
          order_details: order_details
        })
      }
    })

    return transactions
  }

  getDataForAccount(validate) {

    const portfoliosTransactions = this.getStepAnswer('trade-step', 'transactions');

    if (_.isEmpty(portfoliosTransactions)) return {};

    return {
      transactions: {
        trading: this._prepareCreateOrdersTransaction(portfoliosTransactions.map((portfolio) => ({
          ...portfolio,
          transactions: {
            'buy': portfolio.transactions.buy,
            'sell': portfolio.transactions.sell,
            'switch': portfolio.transactions.switch
          }
        }))),
        savings_plan: this._prepareCreatePaymentPlansTransaction(portfoliosTransactions.map((portfolio) => ({
          ...portfolio,
          transactions: {
            'savings_plan': portfolio.transactions.savings_plan,
          }
        }))),
        payout_plan: this._prepareCreatePaymentPlansTransaction(portfoliosTransactions.map((portfolio) => ({
          ...portfolio,
          transactions: {
            'payout_plan': portfolio.transactions.payout_plan,
          }
        }))),
        switch_plan: this._prepareCreatePaymentPlansTransaction(portfoliosTransactions.map((portfolio) => ({
          ...portfolio,
          transactions: {
            'switch_plan': portfolio.transactions.switch_plan,
          }
        })))
      }
    }
  }

  async __setQuestionnaire(defaultAnswers){
    await super.__setQuestionnaire(defaultAnswers);
    this.current_customer = await getDanteCustomerData(this._customer_id);

    this._srri = this._getPrevStepAnswer('risk_profile', 'final-step', 'final_question_uid');
    this._target_market = getTargetMarketAnswers(this.serviceConcept, this._getPrevStepAnswer);

    // TODO: NEED TO REFACTOR
    const banksData = await this._getBanksData()
    this.__banksDataDepotTypes = banksData

  }

  async _getBanksData() {
    try {
      let response = await BankSettingsHandlerResource.getBanksData({
        customer_type: this.customer_type,
        usage_area: PROCESS_TYPE.TRADING,
        trading_type: this.objectType
      })
      return _prepareBanksFromResponse(response)

    } catch (err) {
      console.log(err)
      return undefined
    }
  }

  get hasRiskProfileConfigured() {
    return !!(this.serviceConcept)
  }

  get banksDataDepotTypes() {
    return this.__banksDataDepotTypes
  }

  get srri() {
    return this._srri;
  }

  get serviceConcept () {
    return this._service_concept
  }

  set serviceConcept (value) {
    this._service_concept = value
  }

  get targetMarket() {
    return this._target_market;
  }
}