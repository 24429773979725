import React from "react";
import {FETCH_DATA_INITIAL_STATE} from "../../../hooks/constants";
import _ from "lodash";
import {PRODUCT_TYPE} from "../constants";
import {splitProducts} from "./useYearlyPerformanceData";
import {FactSheetsHandlerResource, ModelPortfolioResource, PortfolioHandlerResource} from "../../../utils/api";
import {fetchMusterdepotPortfolioData, fetchMusterdepotPortfolioId} from "./useMetricsData";
import {
  buildProductConfigurationChangedDeps,
  getChartsSensitiveProductsData,
  getProductCustomerId, getProductIdsAsString,
  updateHookDataConfiguration
} from "../utils";
import {openMorningStartIsin} from "../../../utils/utils";
import {openPortfolioLink} from "../../Trades/components/TradeStep/components/PortfolioTrade/table-data";
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../../utils/constants";
import {ALL_VALUE} from "../../../components/TimeRangeButtonsPad/constants";


async function fetchInvestmentStrategyRiskReturnData(product, investmentStrategy, startDate, endDate) {
  return fetchMusterDepotRiskReturnData(product, investmentStrategy.related_model_portfolio, startDate, endDate, investmentStrategy.id)
}

function validateRiskReturnData(data, validator) {
  return validator(data, ALL_VALUE)
}

function isRiskReturnEmpty(data) {
  return validateRiskReturnData(data, (item, timeKey) => !item.hasOwnProperty(timeKey))
}

function isRiskReturnFull(data) {
  return validateRiskReturnData(data, (item, timeKey) => item.hasOwnProperty(timeKey))
}

function handleProductRiskReturnData(product, data, errors, parseDataCallback) {
  const result = {
    ...getChartsSensitiveProductsData(product),
    riskReturnData: {},
    onTooltipClick: {
      [PRODUCT_TYPE.ASSET]: () => {
        const isin = _.get(product, 'data.isin');
        isin && openMorningStartIsin(isin);
      },
      [PRODUCT_TYPE.PRIVATE_INVESTMENT]: () => openPortfolioLink(product)
    }[product.type]
  }

  result.riskReturnData = parseDataCallback(product, data);

  if (!_.isEmpty(errors) || !isRiskReturnFull(result.riskReturnData || {})) {
    result.missingData = true;
    result.missingDataText = 'Für dieses Instrument stehen für den gewählten Zeitraum nicht ausreichend Daten zur Verfügung. Bei Änderung des Betrachtungszeitraums liegen möglicherweise weitere Daten vor.'
  }

  return result;
}

function handleAssetProductRiskReturnResponse(product, response, errors) {

  const data = _.get(response, `${product.data.isin}.risk_return.data.0`);
  const _errors = errors || _.get(response, `${product.data.isin}.risk_return.error`);

  const parseData = (_product, _data) => {
    const result = {}, timeKey = ALL_VALUE;

    const data = _.get(_data, `${timeKey}.${product.data.isin}`);
    if (!data) {
      return result;
    }

    result[timeKey] = {
      volatility: data.volatility,
      return: data.return
    };
    return result
  };

  return handleProductRiskReturnData(product, data, _errors, parseData)
}

async function fetchAssetRiskReturnData(products, startDate, endDate) {
  const response = await FactSheetsHandlerResource.getPerformanceTimeseriesRiskReturnData(
    undefined, products.map((product) => product.data.isin), startDate, endDate);
  let data = _.get(response, 'risk_return.data') || {};
  const errors = _.get(response, 'risk_return.error');
  if (errors) {
    throw errors
  }

  return products.map((product) => handleAssetProductRiskReturnResponse(product, data, errors))
}

function parsePortfolioRiskReturnData(product, data) {
  return Object.keys(data).reduce((result, timeKey) => {
    result[timeKey] = data[timeKey].portfolio || {};
    return result
  }, {});
}

async function fetchMusterDepotRiskReturnData(product, musterdepot, startDate, endDate, investmentStrategyId) {
  const portfolioData = await fetchMusterdepotPortfolioData(musterdepot, investmentStrategyId);
  let [portfolioId, errors] = await fetchMusterdepotPortfolioId(portfolioData);
  let data = {};
  if (!errors) {
    const response = await ModelPortfolioResource.getPortfolioRiskReturn(
      musterdepot.id, portfolioId, investmentStrategyId, startDate, endDate)
    data = _.get(response, 'pf_risk_return.data.0');
    errors = _.get(response, 'pf_risk_return.errors');
  }

  return handleProductRiskReturnData(product, data, errors, parsePortfolioRiskReturnData)
}

async function fetchPortfolioRiskReturnData(product, portfolio, startDate, endDate) {

  const customerId = getProductCustomerId(product);
  const depotId = _.get(product, 'configuration.depot_id');

  const response = await PortfolioHandlerResource.getPortfolioRiskReturn(
    customerId, undefined, undefined, startDate, endDate, depotId)
  const data = _.get(response, 'pf_risk_return.data.0');
  const errors = _.get(response, 'pf_risk_return.errors');

  return handleProductRiskReturnData(product, data, errors, parsePortfolioRiskReturnData)
}

function useRiskReturnData(products, dateRange) {
  const [data, setData] = React.useState(FETCH_DATA_INITIAL_STATE);

  React.useEffect(() => {
    if (!_.isEmpty(products)) {
      fetchRiskReturnData();
    }
  }, [getProductIdsAsString(products), dateRange.start.format('YYYY-MM-DD'), dateRange.end.format('YYYY-MM-DD')]);

  React.useEffect(() => {
    setData((data) => updateHookDataConfiguration(data, products));
  }, [buildProductConfigurationChangedDeps(products)])

  const fetchRiskReturnData = async () => {
    setData({
      data: null,
      loading: true,
      errors: null,
      updatedAt: +new Date()
    });
    try {

      const [productsAssets, productsOther] = splitProducts(products);

      const handlers = productsOther.map((product) => {
        return {
          [PRODUCT_TYPE.MUSTERDEPOT]: fetchMusterDepotRiskReturnData,
          [PRODUCT_TYPE.MODEL_PORTFOLIO]: fetchMusterDepotRiskReturnData,
          [PRODUCT_TYPE.PRIVATE_INVESTMENT]: fetchInvestmentStrategyRiskReturnData,
          [PRODUCT_TYPE.CUSTOMER_PORTFOLIO]: fetchPortfolioRiskReturnData
        }[product.type](product, product.data, dateRange.start.format('YYYY-MM-DD'), dateRange.end.format('YYYY-MM-DD'));
      });
      productsAssets.length && handlers.push(fetchAssetRiskReturnData(productsAssets, dateRange.start.format('YYYY-MM-DD'), dateRange.end.format('YYYY-MM-DD')));

      let result = await Promise.all(handlers);
      result = _.flatten(result);
      const noData = _.every(result, (item) => isRiskReturnEmpty(item.riskReturnData || {}));

      setData({
        data: noData ? null : result,
        loading: false,
        errors: noData ? DEFAULT_EMPTY_SECTION_MESSAGE : null,
        updatedAt: +new Date()
      })
    } catch (errors) {
      setData({
        data: null,
        loading: false,
        errors,
        updatedAt: +new Date()
      })
    }
  }

  return data;
}

export default useRiskReturnData;