import CommonAnalysisCard from "../../../../components/AnalysisCard";
import React from "react";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import {toShortGermanFormat} from "../../../../../../utils/numberFormater";
import HighChartBase from "../../../../../../components/Charts/Base";
import {formatPortfolioName} from "../../../../../../utils/aggregated-portfolio";
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../../../../../utils/constants";

class ChartPerformanceBar extends React.Component {

  renderChart() {
    const defaultChartColor = '#0596CE';
    const { classes } = this.props;
    const years = [], values=[];

    this.props.data['year_performance'].forEach((item) => {
      years.push(item['year']);
      values.push({
        y: parseFloat((item.performance * 100).toFixed(2)),
        inEur: parseFloat((item['performance_eur']).toFixed(2))
      });
    })

    const series = [{
      name: formatPortfolioName(''),
      data: values,
      pointPadding: 0.15,
      visible: true,
      color: defaultChartColor
    }];

    return <HighChartBase className={classes.chart} options={{
      chart: {
        type: 'column',
        height: 350,
        style: {
          fontFamily: "\"Roboto-Regular\", \"Lucida Sans Unicode\", Verdana, Arial, Helvetica, sans-serif",
          fontSize: 14
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: years,
        tickLength: 0,
        labels: {
          style: {
            fontFamily: 'Roboto-Bold',
          }
        }
      },
      yAxis: {
        title: {
          enabled: false
        },
        labels: {
          formatter: function() {
            return toShortGermanFormat(this.value, '', ' %', 2, true);
          }
        },
        plotLines: [{
          color: '#747474',
          width: 2,
          value: 0,
          zIndex: 10
        }]
      },
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        column: {
          grouping: true,
          shadow: false,
          borderWidth: 0
        },
        tooltip: {
          enabled: false
        },
        series: {
          dataLabels: {
            enabled: true,
          },
          enableMouseTracking: true
        }
      },
      series: series
    }} language={'DE'}/>
  }

  render() {
    const { classes } = this.props;

    return <CommonAnalysisCard
        classes={{
          card: classes.root
        }}
        title={'Jährliche Wertentwicklung in %'}
        subtitle={'Modified Dietz Methode'}
        loading={this.props.loading}
        content={
          <div className={classes.container}>
            {this.props.error ? this.props.error : this.props.data ? this.renderChart() : DEFAULT_EMPTY_SECTION_MESSAGE}
          </div>
      }
    />
  }
}

export default withStyles(styles)(ChartPerformanceBar)