import _ from "lodash";
import React from 'react'
import {
  ASSET_DATA,
  ASSET_DATA_UID,
  CLEARING_ACCOUNT_BALANCING_QUESTION_UID,
  CLEARING_ACCOUNT_NOT_ENOUGH_MONEY_WARNING_MSG,
  CLEARING_ACCOUNT_OPTION_QUESTION_UID,
  CLEARING_ACCOUNT_QUESTION_UID,
  CountryISOMapping,
  COUPLE_PERMANENT_SUITABILITY_CHECK_DISABLED_TEXT,
  COUPLE_PERMANENT_SUITABILITY_CHECK_ENABLED_TEXT,
  CUSTOMER_TYPES,
  FIELD_REQUIRED_MSG,
  INVESTMENT_STRATEGY_SINGLE_INVESTMENT_HIGH_EXPERIENCE_WARNING,
  INVESTMENT_STRATEGY_SINGLE_INVESTMENT_HIGH_RISK_SCORE_AND_EXPERIENCE_WARNING,
  INVESTMENT_STRATEGY_SINGLE_INVESTMENT_HIGH_RISK_SCORE_WARNING,
  LEGAL_AGE,
  LEGITIMATION_MAPPING,
  LEGITIMATION_MINOR_MAPPING,
  LIABILITY_DATA,
  LIABILITY_DATA_UID,
  QUESTION_TYPE,
  radioNoUID,
  radioSelectNoExperienceValue,
  radioSelectNoKnowledgeValue,
  radioYesUID,
  radioYesValue,
  REF_ACCOUNT_STEP_ID,
  SAVINGS_PLAN_HIGH_RISK_SCORE_WARNING,
  SINGLE_CUSTOMER_PERMANENT_SUITABILITY_CHECK_DISABLED_TEXT,
  SINGLE_CUSTOMER_PERMANENT_SUITABILITY_CHECK_ENABLED_TEXT,
  SINGLE_INVESTMENT_HIGH_RISK_SCORE_WARNING,
  REQUIRED_CHECK_BEHAVIOR,
  SNACKBAR_DURATION_IN_SEC,
  STEP_TYPE,
  USER_CONTACT_STEP_IDENTIFIERS,
  WRONG_TYPE_MINOR_IN_CRM_WARNING, CHARGING_BANK_FEE_UID
} from "./constants";
import {checkCriterias, InstrumentsHandler, isAcceptanceCriteriasValid} from '../Modelportfolios/utils'

import GroupQuestion, {GroupQuestionMethods} from "./components/StepContent/components/question/GroupQuestion";
import RadioQuestion, {RadioQuestionMethods} from "./components/StepContent/components/question/RadioQuestion";
import DateQuestion, {DateQuestionMethods} from "./components/StepContent/components/question/DateQuestion";
import ReferenceAccountsQuestion, {ReferenceAccountsQuestionMethods} from "./components/StepContent/components/question/ReferenceAccountsQuestion";
import InputQuestion, {InputQuestionMethods} from "./components/StepContent/components/question/InputQuestion";
import SelectQuestion, {SelectQuestionMethods} from "./components/StepContent/components/question/SelectQuestion";
import CheckboxQuestion, {CheckboxQuestionMethods} from "./components/StepContent/components/question/CheckboxQuestion";
import RadioSelectQuestion, {RadioSelectQuestionMethods} from "./components/StepContent/components/question/RadioSelectQuestion";
import CalendarQuestion, {CalendarQuestionMethods} from "./components/StepContent/components/question/CalendarQuestion";
import ListSelectQuestion, {ListSelectQuestionMethods} from "./components/StepContent/components/question/ListSelectQuestion";
import SliderQuestion, {SliderQuestionMethods} from "./components/StepContent/components/question/SliderQuestion";
import AssetsQuestion, {AssetsQuestionMethods} from "./components/StepContent/components/question/AssetsQuestion";
import ChartSelectQuestion, {ChartSelectQuestionMethods} from "./components/StepContent/components/question/ChartSelectQuestion";
import {TimeQuestionMethods} from './components/StepContent/components/question/TimeQuestion';
import {AssetsStep, AssetsV2Step, CongratsStep, DynamicStep, FinalStep} from "./components/StepContent/components/step";
import CountrySelectQuestion from "./components/StepContent/components/question/CountrySelectQuestion";
import moment from "moment";
import {
  CustomerResource,
  ModelPortfolioResource,
  PortfolioHandlerResource,
  QuestionnairesHandlerResource
} from "../../utils/api";
import LegitimationDocumentsQuestion, {LegitimationDocumentsQuestionMethods} from "./components/StepContent/components/question/LegitimationDocumentsQuestion";
import Grid from "@material-ui/core/Grid";
import ProductSelectionStep from "./components/StepContent/components/step/ProductsSelectionSteps";
import InfoListQuestion, {InfoListQuestionMethods} from "./components/StepContent/components/question/InfoListQuestion/InfoListQuestion";
import CheckboxListQuestion, {CheckboxListQuestionMethods} from "./components/StepContent/components/question/CheckboxListQuestion/CheckboxListQuestion";
import {getMemberIndexFromStepUID} from "./services";
import {buildQuestionUIDForMember} from "./service";
import {getFileNameFromResponse} from "../../utils/utils";
import ClearingAccount
  from "./components/StepContent/components/step/CustomerDataSteps/ClearingAccount/ClearingAccount";
import {SERVICE_CONCEPTS, SERVICE_CONCEPTS_NAMES} from "../Trades/constants";
import {birthConfigs, personalInformationConfigs} from "./mock";
import {DATE_FORMAT, FODB_CUSTODIAN_ID} from "../../utils/constants";
import {displaySuccessSnackBar, displayWarningSnackBar} from "../../components/SnackbarProvider/actions";
import {CustodyCertificateService} from "./custody_certificate_service";
import {getFromStorage, setInStorage, SHARED_SETTINGS_KEY, STORAGE_TYPE} from "../../utils/storage";
import ToggleQuestion, {ToggleQuestionMethods} from "./components/StepContent/components/question/ToggleQuestion";
import {getInstrumentSri, instrCustodianData} from '../Trades/utils';
import {
  TRADING_ACTION_BUY,
  TRADING_ACTION_SAVINGS_PLAN,
  TRADING_ACTION_SELL,
  TRADING_ACTION_SWITCH
} from "../../components/Charts/InstrumentsAllocationTable/constants";
import {calculateSwitchPlanPossible} from "../FactSheetsPage/utils";
import TextQuestion from "./components/StepContent/components/question/TextQuestion";
import WarningIcon from "../../components/Icons/WarningIcon";


export const getStepType = (step) => {
  const _step_type = _.isArray(step.behaviour) && step.behaviour[0];

  if(_.includes(STEP_TYPE, _step_type)){
    return _step_type;
  }

  return STEP_TYPE.DYNAMIC;
};

export const getStepCategory = (step) => {
  return (step && _.isArray(step.behaviour) && step.behaviour.length > 1 && step.behaviour[1]) || '';
};

export const getStepComponent = (type) => {
  return {
    [STEP_TYPE.INSTRUMENTS]: ProductSelectionStep,
    [STEP_TYPE.ASSETS]: AssetsStep,
    [STEP_TYPE.ASSETS_V2]: AssetsV2Step,
    [STEP_TYPE.FINAL]: FinalStep,
    [STEP_TYPE.CONGRATS]: CongratsStep,
  }[type] || DynamicStep;
};

export const getUID = (uid) => {
  return uid && uid.split('_')[0]; // split UID. Ex: A10_DEV - ['A10', 'DEV']
};

export const getQuestionSize = (question_data) => {
  let uid = question_data;
  if (_.isObject(question_data)) {
    if (question_data.hasOwnProperty('size')) return question_data.size;
    uid = question_data.uid;
  }
  uid = getUID(uid);
  if (['A2','A4','A11', 'A201','A401','A1101', 'A202','A402','A1102', 'C1', 'C101', 'C102'].includes(uid)) {
    return 2;
  }
  if (['C2', 'C201', 'C202'].includes(uid)) {
    return 4;
  }
  if (['A10', 'A101', 'A102', 'A501','A601', 'A502','A602', 'A5','A6', 'A7','A8','A9','B1','B2', 'A701','A801','A901','B101','B201', 'A702','A802','A902','B102','B202','H1','H2','H3','H4', 'H5', 'H6', 'H7', 'H8', 'A51', 'A52', 'A53', 'A54', ''].includes(uid)) {
    return 6;
  }
  return 12;
};

export const getCheckboxListSize = (uid) => {
  uid = getUID(uid);
  if(['H8', 'I3'].includes(uid)){
    return 6;
  }
  return 12;
};

export const getQuestionType = (question, forStep) => {
  if (_.isArray(question.behaviour) && question.behaviour.filter(i => !i.startsWith("default-"))[0]) {
    let _type = question.behaviour.filter(i => !i.startsWith("default-"))[0];
    if (!['person1', 'person2', 'family'].includes(_type)) {
      forStep = _.isBoolean(forStep) ? forStep : true;

      return forStep && ['tel', 'email'].includes(_type) ? 'input': _type;
    }
  }

  if (question.type) {
    return question.type;
  }

  if (question.question) {
    return QUESTION_TYPE.GROUP;
  }

  return null;
};

export const setQuestionDefaults = (question) => {
  try {
    let defaults = {};
    (question.behaviour || []).map(behaviour => {
      if(behaviour.startsWith("default-")){
        let [pref, uid, ...values] = behaviour.split('-');
        let value = values.join('-').trim();
        if (uid && value){
          try {
            // try to convert to js object
            value = JSON.parse(value);
          } catch (e) {
            // pass
          }
          defaults[uid] = value;
        }
      }
    });

    if (!_.isEmpty(defaults)) {
      setQuestionAnswer(question, [defaults]); // answer should be array
    }
  } catch (e) {
    console.log(e);
  }
};

export const requiredCheck = (question) => (question.behaviour || []).includes(REQUIRED_CHECK_BEHAVIOR);

export const getQuestionComponent = (type) => {
  switch (type) {
    case QUESTION_TYPE.GROUP:
      return GroupQuestion;
    case QUESTION_TYPE.RADIO:
      return RadioQuestion;
    case QUESTION_TYPE.INPUT:
      return InputQuestion;
    case QUESTION_TYPE.COUNTRY_SELECT:
      return CountrySelectQuestion;
    case QUESTION_TYPE.SELECT:
      return SelectQuestion;
    case QUESTION_TYPE.DATE_SELECT:
      return DateQuestion;
    case QUESTION_TYPE.REFERENCE_ACCOUNTS:
      return ReferenceAccountsQuestion;
    case QUESTION_TYPE.CHECKBOX:
      return CheckboxQuestion;
    case QUESTION_TYPE.RADIO_SELECT:
      return RadioSelectQuestion;
    case QUESTION_TYPE.CALENDAR:
      return CalendarQuestion;
    case QUESTION_TYPE.LIST_SELECT:
      return ListSelectQuestion;
    case QUESTION_TYPE.SLIDER:
      return SliderQuestion;
    case QUESTION_TYPE.ASSETS:
      return AssetsQuestion;
    case QUESTION_TYPE.CHART_SELECT:
      return ChartSelectQuestion;
    case QUESTION_TYPE.LEGITIMATION_DOCUMENT:
      return LegitimationDocumentsQuestion;
    case QUESTION_TYPE.INFO_LIST:
      return InfoListQuestion;
    case QUESTION_TYPE.CHECKBOX_LIST:
      return CheckboxListQuestion;
    case QUESTION_TYPE.TOGGLE:
      return ToggleQuestion;
    case QUESTION_TYPE.TEXT:
      return TextQuestion;
    default:
      return null;
  }
};

export const getQuestionMethods = (type) => {
  switch (type) {
    case QUESTION_TYPE.GROUP:
      return GroupQuestionMethods;
    case QUESTION_TYPE.RADIO:
      return RadioQuestionMethods;
    case QUESTION_TYPE.DATE_SELECT:
      return DateQuestionMethods;
    case QUESTION_TYPE.REFERENCE_ACCOUNTS:
      return ReferenceAccountsQuestionMethods;
    case QUESTION_TYPE.INPUT:
    case QUESTION_TYPE.HIDDEN:
    case QUESTION_TYPE.TEXT:
      return InputQuestionMethods;
    case QUESTION_TYPE.SELECT:
    case QUESTION_TYPE.COUNTRY_SELECT:
      return SelectQuestionMethods;
    case QUESTION_TYPE.CHECKBOX:
      return CheckboxQuestionMethods;
    case QUESTION_TYPE.RADIO_SELECT:
      return RadioSelectQuestionMethods;
    case QUESTION_TYPE.CALENDAR:
      return CalendarQuestionMethods;
    case QUESTION_TYPE.LIST_SELECT:
      return ListSelectQuestionMethods;
    case QUESTION_TYPE.SLIDER:
      return SliderQuestionMethods;
    case QUESTION_TYPE.ASSETS:
      return AssetsQuestionMethods;
    case QUESTION_TYPE.CHART_SELECT:
      return ChartSelectQuestionMethods;
    case QUESTION_TYPE.TIME_SELECT:
      return TimeQuestionMethods;
    case QUESTION_TYPE.LEGITIMATION_DOCUMENT:
      return LegitimationDocumentsQuestionMethods;
    case QUESTION_TYPE.INFO_LIST:
      return InfoListQuestionMethods;
    case QUESTION_TYPE.CHECKBOX_LIST:
      return CheckboxListQuestionMethods;
    case QUESTION_TYPE.TOGGLE:
      return ToggleQuestionMethods;
    default:
      return null;
  }
};

export const setQuestionAnswerFromConfig = (q, customer, mapping_conf_func) => {
  if(q.hasOwnProperty('question')){
    (q.question || []).map((_q) => {
      setQuestionAnswerFromConfig(_q, customer, mapping_conf_func)
    });
  } else {
    const mapping_conf = (mapping_conf_func && mapping_conf_func(q)) || (q.config && q.config.mapping) || {};
    const dante_field = mapping_conf.dante_field || q.uid;
    let answer = typeof mapping_conf.dante_field_finder === 'function' ?
      mapping_conf.dante_field_finder(q, customer) : _.get(customer, dante_field);
    if(!_.isNil(answer)) {
      if (mapping_conf.config) {
        answer = mapping_conf.config[answer];
      }
      setQuestionAnswer(q, [answer]);
    }
  }
};

const questionHasError = (question) => !!question.error
  && ((!_.isObject(question.error) && !_.isArray(question.error)) || !_.isEmpty(question.error))

/**
 * @param keepExistingError Use this flag as true in case you need to call this function several times
 * and you want to keep errors from previous calls.
 */
export const setQuestionErrorFromConfig = (q, formErrors, mapping_conf_func, keepExistingError = false) => {
  let isValid = true;

  if (!questionHasError(q) || !keepExistingError) {
    q.error = getFieldMessage(q, formErrors, mapping_conf_func);
  }
  if(questionHasError(q)) isValid = false;

  return isValid;
};


export const getFieldMessage = (q, formErrors, mapping_conf_func, isWarning=false) => {
  /* Function to get error/warning message */
  const mapping_conf = (mapping_conf_func && mapping_conf_func(q)) || (q.config && q.config.mapping) || {};
  const conf_field = isWarning
    ? q.connect_uid
    : mapping_conf.dante_error_field || mapping_conf.dante_field || q.uid;

  let err = mapping_conf.dante_error_finder ? mapping_conf.dante_error_finder(formErrors, q) : _.get(formErrors, conf_field);

  if(err) {

    const _handleError = (error) => {
      if(_.isArray(error)){
        error = error.map(e => cleanDanteErrorMsg(e, conf_field));
      } else if (_.isString(error)) {
        error = cleanDanteErrorMsg(error, conf_field);
      } else {
        Object.keys(error).forEach((key) => {
          error[key] = _handleError(error[key])
        })
      }

      return error
    }

    err = _handleError(err);
  }

  return err;
};


const cleanDanteErrorMsg = (err, dante_field) => {
  // Dante provide field name in error message - so remove it
  const fieldName = dante_field.split('.').map(i => _.lowerCase(i)).join('.');
  return _.upperFirst(err.replace(fieldName, '').replace(dante_field, '').trim())
};

export const setQuestionAnswer = (question, answer, force=false) => {
  const type = getQuestionType(question);
  const questionMethods = getQuestionMethods(type);

  if (!questionMethods.initAnswer) {
    if (_.isArray(answer)){
      question.answer = answer[0];
    }
  } else {
    questionMethods.initAnswer(question, answer, force);
  }
};

export const getStepAnswer = (step, withOption) => {
  const answers = step.question.map(q => getQuestionAnswers(q, withOption)).reduce((acc, item) => {
    if (_.isArray(item)) {
      return [...acc, ...item];
    } else if (_.isNull(item) || _.isUndefined(item)) {
      return [...acc];
    } else {
      return [...acc, item];
    }
  }, []);

  _.forEach(answers, (it) => {
    _.remove(it.answer, (el) => {
      return _.isNull(el) || _.isUndefined(el)
    });
  });

  return {
    step_uid: step.uid,
    answers
  }
};

export const getQuestionAnswers = (question, withOption) => {
  withOption = !!withOption;

  const type = getQuestionType(question);
  const questionMethods = getQuestionMethods(type);
  let answerData;

  if (!questionMethods.answer) {
    answerData = {
      question_uid: question.uid,
      answer: [question.answer]
    }
  } else {
    answerData = questionMethods.answer(question, withOption);
  }

  // if question has choices - send selected choice instead of it's UID
  if(withOption && question.config && question.config.choices){
    answerData.answer = answerData.answer.map(answer => {
      return question.config.choices.find(o => o.uid == answer) || answer;
    });
  }

  return answerData;
};

export const UpdateCustomerData = async (customer_id, step, mapping_conf_func, defaultAnswers, skipEmptyFields=false) => {
  const stepAnswer = defaultAnswers || {};
  if(step && step.question){
    step.question.map(q => answerForDante(q, stepAnswer, mapping_conf_func, skipEmptyFields));
  }
  if(!_.isEmpty(stepAnswer)){
    return await QuestionnairesHandlerResource.at(`customer-info/${customer_id}/`).post(stepAnswer);
  }
};

export const UpdateCustomerAndSync = async (customer_id, step, mapping_conf_func, defaultAnswers, skipEmptyFields=false) => {
  const res = await UpdateCustomerData(customer_id, step, mapping_conf_func, defaultAnswers, skipEmptyFields);

  // update contacts
  if(res && res.data){
    const contacts = (step.question || []).find(q => getUID(q.uid) === 'A-contacts');
    contacts && contacts.question.map((q) => {
      // in case new one created - to update id
      if(!q.dante_obj) {
        setQuestionAnswerFromConfig(q, res.data, mapping_conf_func);
      }
    });

    return res
  }
};

export const updateCustomerWithCorrectLegitimation = (customer) => {
  const mapping = customer.customer_type == CUSTOMER_TYPES.MINOR ? LEGITIMATION_MINOR_MAPPING : LEGITIMATION_MAPPING;
  const all_legitimations = [...(customer.legitimation || [])];
  const currLegitimation = _.reverse(_.sortBy(all_legitimations, [
    (l) => l.main, (l) => moment(l.expirationDate)
  ])).find(l => mapping.hasOwnProperty(l.type)) || {};

  // store list of all legitimations as a separate field
  customer.all_legitimations = all_legitimations;

  // set customer legitimation data
  customer.legitimation = [currLegitimation];

  return customer;
};

export const getDanteCustomerData = async (customer_id) => {
  let customer = await CustomerResource.at(`${customer_id}/details/`).get();

  updateCustomerWithCorrectLegitimation(customer);

  if (Array.isArray(customer.relationships) && customer.relationships.length) {
    customer.relationships.forEach(updateCustomerWithCorrectLegitimation)
  }

  return customer
};

export const getCustomerData = async (customer_id) => {
  const res = await PortfolioHandlerResource.getCustomerAppData(customer_id, false, false, false, false, true, undefined, false)
  return _.get(res, 'customer.data.0');
}

export const noAnswer = (answer) => _.isNil(answer) || (_.isString(answer) && _.isEmpty(answer));

const answerForDante = (q, answers, mapping_conf_func, skipEmpty=false) => {
  const type = getQuestionType(q);
  if (type === QUESTION_TYPE.GROUP){
    q.question.map(sub_q => answerForDante(sub_q, answers, mapping_conf_func, skipEmpty));
  } else {
    const mapping_conf = (mapping_conf_func && mapping_conf_func(q)) || (q.config && q.config.mapping || {});
    const dante_field = mapping_conf.dante_field;
    const hasDanteFieldSetter = typeof mapping_conf.dante_field_setter === 'function';
    // ONLY fields we get from Dante
    if (dante_field || hasDanteFieldSetter){
      const questionMethods = getQuestionMethods(type);
      let answer = questionMethods.answer ? questionMethods.answer(q).answer[0] : q.answer;
      if(answer && type === QUESTION_TYPE.DATE_SELECT && !!q.out_format){
        // for date fields convert Connect format to Dante format
        answer = moment(answer, q.out_format).format('DD.MM.YYYY');
      }
      if (mapping_conf.config) {
        answer = _.findKey(mapping_conf.config, (v) => v == answer);
      }

      if ((skipEmpty || requiredCheck(q)) && noAnswer(answer)) {
        return
      }

      if(hasDanteFieldSetter){
        mapping_conf.dante_field_setter(answers, q, answer)
      } else {
        _.set(answers, dante_field, answer);
      }

    }
  }
};

export const scrollToFirstElementOfClass = (container_id='app-main', classes, delay=250) => {
  // Scrolls to first element on page with one of provided classes.
  // By default is used to scroll to required fields that were not filled
  setTimeout(() => {
    // set default classes
    if (!classes){
      classes = ['Mui-error', 'document-distribution-item-error']
    }

    let firstRequired = undefined;
    let container = document.getElementById(container_id);

    //loop over each class until first element is found
    for(let className of classes){
      if (container) {firstRequired = _.first(container.getElementsByClassName(className))}
      if (firstRequired){
        firstRequired.scrollIntoView({
          behavior: 'smooth',
          block: 'center'  // used so it scrolls to element and it is on the center of the screen, and not on top
        });
        break;
      }
    }

  }, delay);
};

export const isQuestionValid = (question, step=undefined, afterChange=undefined, service=undefined) => {
  if(question.hasOwnProperty('isValid')){
    return question.isValid(step, afterChange, service);
  } else {
    const type = getQuestionType(question);
    const questionMethods = getQuestionMethods(type);
    return questionMethods && questionMethods.valid && questionMethods.valid(question, step, afterChange, service) || false;
  }
};

export function showQuestionRequiredNote(question) {
  if(!question.question_text) return false; // do not show required not if question has no label

  const type = getQuestionType(question);

  if (['list_select', 'slider', 'chart_select', 'assets'].includes(type)) return false;

  const questionMethods = getQuestionMethods(type);

  return questionMethods && questionMethods.required && questionMethods.required(question) || false;
}

export const getAssetsListData = (uid) => {
  uid = getUID(uid);
  if (uid === ASSET_DATA_UID) {
    return ASSET_DATA;
  }

  if (uid === LIABILITY_DATA_UID) {
    return LIABILITY_DATA;
  }
};

export function isRequiredQuestion(question) {
  return question.optional === false;
}

export function isMiltilineQuestion(question) {
  return question.config && question.config.multiline
}

export function isValidString(value) {
  return typeof value === 'string' && value.trim().length > 0;
}
export function isValidBoolean(value) {
  return typeof value === 'boolean';
}
export function isValidNumber(value) {
  return typeof value === 'number';
}
export function isValidValue(value) {
  return isValidString(value) || isValidBoolean(value) || isValidNumber(value);
}

export function isValidBirthDate(question, customer_type) {
  if(DateQuestionMethods.valid(question)){
    const isLegal = moment().diff(moment(question.answer), 'years',false) >= LEGAL_AGE;
    if(customer_type == CUSTOMER_TYPES.MINOR){
      if (isLegal){
        question.error = `Hinweis: Ihr Kunde ist über ${LEGAL_AGE} Jahre alt! Sie können an dieser Stelle nicht fortfahren.`;
      }
    } else if (!isLegal) {
      question.error = `Hinweis: Ihr Kunde ist unter ${LEGAL_AGE} Jahre alt! Sie können an dieser Stelle nicht fortfahren.`;
    }
  }

  return !question.error;
}

export const splitEinzeltitelTradingInstruments = (instruments, availableAssetClasses, nonFunds, funds) => {
  (instruments || []).forEach((item) => {
    if (_.get(item, 'data.is_model_portfolio') || _.get(item, 'data.is_fund') || item.is_fund) {
      funds.push(item)
    } else {
      nonFunds.push(item)
    }
  })
  return [nonFunds, funds];
}

export const skipSrriValidation = (serviceConcept) => {
  // srri validation is skipped if execution only OR anlagevermittlung
  return [SERVICE_CONCEPTS.ExecutionOnly, SERVICE_CONCEPTS.Anlagevermittlung].includes(serviceConcept)
}

export const checkEinzeltitelTradingAcceptanceCriterias = (instrument, portfolio, broker_license, srri, acceptanceCriterias, serviceConcept, riskProfilingAnswers) => {
  if (portfolio) {
    let ordering_broker_license = [], min_ssri = 0;
    const instrumentInternalAssetClass = _.get(instrument, 'internal_asset_class') || _.get(instrument, 'data.internal_asset_class');
    const internalAssetClass = _.get(portfolio.available_asset_classes, instrumentInternalAssetClass)
    ordering_broker_license = !!internalAssetClass ? internalAssetClass.broker_license : []
    acceptanceCriterias.general.instrument_type.value = ordering_broker_license.includes(+broker_license) && !!internalAssetClass

    let internal_asset_class_valid = _.get(riskProfilingAnswers, instrumentInternalAssetClass)
    if (!_.isNil(internal_asset_class_valid) && acceptanceCriterias.general.instrument_type.value && [SERVICE_CONCEPTS.Anlageberatung, SERVICE_CONCEPTS.Anlagevermittlung].includes(+serviceConcept)) {
      acceptanceCriterias.general.instrument_type.value = internal_asset_class_valid
    }

    if (!!internalAssetClass && srri && !skipSrriValidation(serviceConcept)) {
      min_ssri = internalAssetClass.min_srri || 0
      acceptanceCriterias.general.srri = {
        title: `Kunden-Risikoklasse ≥ ${min_ssri}`,
        value: srri >= min_ssri
      }
    }
  }
}

export  function isKnowledgeAndExperienceValid(instrument, targetMarket) {

  if (!instrument.target_market) return false;

  let { investmentFunds } = targetMarket;

  let isValid = true;

  switch (investmentFunds) {

    case 'Keine Erfahrung':
    case '0 bis 2':
    case 'bis zu 1 Jahr':
    case undefined:
      isValid = instrument.target_market.expertise_basic == 'Y';
      break;

    case '3 bis 5':
    case '1 bis 3 Jahre':
      isValid = instrument.target_market.expertise_informed == 'Y';
      break;

    case '6 bis 10':
    case 'Mehr als 10':
    case 'Mehr als 3 Jahre':
      isValid = instrument.target_market.expertise_advanced == 'Y';
      break;

    default:
      isValid = true
  }

  return isValid
}

export class RiskProfileInstrumentsHandler extends InstrumentsHandler {
  constructor(srri=undefined, targetMarket=undefined, only_funds=undefined, disable_funds=undefined) {
    super();

    this.__srri = srri;
    this.__targetMarket = targetMarket;
    this.__only_funds = only_funds;
    this.__disable_funds = disable_funds;
  }

  /**
   * Check, if asset_type is valid.
   *
   * @param {Object} instrument Instrument data
   *
   * @returns {Boolean} Is instrument valid, or not
   */
  isFundAssetTypeValid(instrument) {

    if (this.__disable_funds && this.__only_funds) {
      console.error('Invalid parameters. Only funds and Disable funds can`t be used at the same time');
      return false
    }

    if (this.__only_funds) return instrument.is_fund;

    if (this.__disable_funds) return !instrument.is_fund;

    return true
  }

  /**
   * Check, if investment period is valid for selected instrument.
   *
   * @param {Object} instrument Instrument data
   *
   * @returns {Boolean} Is instrument valid, or not
   */
  isInvestmentPeriodValid(instrument) {

    if (!instrument.target_market) return false;

    let { investmentPeriod } = this.__targetMarket;

    let isValid = true;

    const SHORT_PERIOD = ['S', 'V', 'short'];
    const MEDIUM_PERIOD = [...SHORT_PERIOD, 'M', 'medium'];
    const LONG_PERIOD = [...MEDIUM_PERIOD, 'L', 'long'];
    const MAX_PERIOD = [...LONG_PERIOD, 'H'];

    const INVESTMENT_PERIOD_FILTERS = {
      'bis zu 3 Jahre': SHORT_PERIOD,
      'bis zu 5 Jahre': MEDIUM_PERIOD,
      'bis zu 10 Jahre': LONG_PERIOD,
      'mehr als 10 Jahre': MAX_PERIOD
    };

    switch (investmentPeriod) {

      case 'bis zu 3 Jahre':
        isValid = instrument.target_market.time_horizon <= 3
                  || INVESTMENT_PERIOD_FILTERS[investmentPeriod].includes(instrument.target_market.time_horizon);
        break;

      case 'bis zu 5 Jahre':
        isValid = instrument.target_market.time_horizon <= 5
                  || INVESTMENT_PERIOD_FILTERS[investmentPeriod].includes(instrument.target_market.time_horizon);
        break;

      case 'bis zu 10 Jahre':
        isValid = instrument.target_market.time_horizon <= 10
                  || INVESTMENT_PERIOD_FILTERS[investmentPeriod].includes(instrument.target_market.time_horizon);
        break;

      case 'mehr als 10 Jahre':
        isValid = instrument.target_market.time_horizon >=0
                  || INVESTMENT_PERIOD_FILTERS[investmentPeriod].includes(instrument.target_market.time_horizon);
        break;
      default:
        isValid = true
    }

    return isValid
  }

  /**
   * Check, if investor is valid for selected instrument.
   *
   * @param {Object} instrument Instrument data
   *
   * @returns {Boolean} Is instrument valid, or not
   */
  isInvestorTypeValid(instrument) {

    if (!instrument.target_market) return false;

    let { investorType } = this.__targetMarket;

    let isValid = true;

    switch (investorType) {

      case 'retail':
        isValid = instrument.target_market.investor_type_retail == 'Y';
        break;

      default:
        isValid = true
    }

    return isValid
  }

  /**
   * Check, if investment funds is valid for selected instrument.
   *
   * @param {Object} instrument Instrument data
   *
   * @returns {Boolean} Is instrument valid, or not
   */
  isInvestmentFundValid(instrument) {
    return isKnowledgeAndExperienceValid(instrument, this.__targetMarket);
  }

  /**
   * Check, if onboarding type is valid for selected instrument.
   *
   * @param {Object} instrument Instrument data
   *
   * @returns {Boolean} Is instrument valid, or not
   */
  isServiceConceptValid(instrument) {

    if (!instrument.target_market) return false;

    let { serviceConcept } = this.__targetMarket;

    let isValid = true;

    switch (serviceConcept) {

      case SERVICE_CONCEPTS.Anlageberatung:
        isValid = ['Both (Retail and Professional)', 'Retail'].includes(instrument.target_market.distribution_investment_advice);
        break;

      case SERVICE_CONCEPTS.Anlagevermittlung:
        isValid = ['Both (Retail and Professional)', 'Retail'].includes(instrument.target_market.dist_exec_app_test_non_adv);
        break;

      case SERVICE_CONCEPTS.ExecutionOnly:
        isValid = ['Both (Retail and Professional)', 'Retail'].includes(instrument.target_market.distribution_execution_only);
        break;

      default:
        isValid = true
    }

    return isValid
  }

  filterInstruments(instruments) {

    let instrumentsToAccept = [];
    let instrumentsToExclude = [];

    (instruments || []).forEach(instrument => {

      let acceptanceCriterias = {};

      if (this.__targetMarket) {
        let serviceConcept = this.__targetMarket.serviceConcept;

        acceptanceCriterias.target_market = {
          "distribution_investment_advice": {
            "title": SERVICE_CONCEPTS_NAMES[serviceConcept],
            "value": this.isServiceConceptValid(instrument)
          },
          "investor_type_retail": {
            "title": "Anlegertypen",
            "value": this.isInvestorTypeValid(instrument)
          },
        };

        if ([SERVICE_CONCEPTS.Anlagevermittlung, SERVICE_CONCEPTS.Anlageberatung].includes(serviceConcept)) {
          acceptanceCriterias.knowledge_and_experience = {
            "investment_fund": {
              "title": "Kenntnisse",
              "value": this.isInvestmentFundValid(instrument)
            }
          }
        }

        if (serviceConcept == SERVICE_CONCEPTS.Anlageberatung) {
          acceptanceCriterias.target_market.time_horizon = {
            "title": "Anlagehorizont",
            "value": this.isInvestmentPeriodValid(instrument)
          }
        }
      }

      if (this.__only_funds || this.__disable_funds) {
        if (!acceptanceCriterias.hasOwnProperty('general')) {
          acceptanceCriterias.general = {}
        }
        acceptanceCriterias.general.only_funds = {
          "title": this.__only_funds ? "Nur Fonds" : "Wertpapiere/Einzeltitel",
          "value": this.isFundAssetTypeValid(instrument)
        }
      }

      checkCriterias(instrument, acceptanceCriterias, instrumentsToAccept, instrumentsToExclude)

    });

    return [instrumentsToAccept, instrumentsToExclude]

  }
}

export class ModelPortfolioHandler extends RiskProfileInstrumentsHandler {

  constructor() {
    super(undefined, {}, undefined, undefined);
  }

  /**
   * Filter model portfolios list and exclude model portfolios with invalid SRRI.
   * @param {Object[]} modelPortfolios List of model portfolios
   * @return {Object[][]}
   */
  filterInstruments(modelPortfolios) {

    return [modelPortfolios, []]

  }

}

export class InvestmentStrategyHandler extends RiskProfileInstrumentsHandler {

  constructor(srri=undefined, targetMarket=undefined) {
    super(srri, targetMarket);
  }

  filterInstruments(investmentStrategies) {

    const strategiesToAccept = [];
    const strategiesToExclude = [];

    (investmentStrategies || []).forEach((strategy) => {

      const acceptanceCriterias = {
        "general": {
          "model_portfolio_valid": {
            "title": "Portfoliodaten vollständig", // TODO: Check, if German message is correct
            "value": !!strategy.related_model_portfolio
          }
        }
      };

      if (this.__targetMarket) {

        acceptanceCriterias.target_market = {
          "investor_type_retail": {
            "title": "Anlegertypen",
            "value": this.isInvestorTypeValid(strategy)
          }
        };

        if (this.__targetMarket.serviceConcept == SERVICE_CONCEPTS.Anlageberatung) {
          if (this.__srri) {
            acceptanceCriterias.general.srri = {
              "title": "SRI",
              "value": strategy.srri && strategy.srri <= this.__srri
            }
          }
          acceptanceCriterias.target_market.time_horizon = {
            "title": "Anlagehorizont",
            "value": this.isInvestmentPeriodValid(strategy)
          }
        }

      }

      checkCriterias(strategy, acceptanceCriterias, strategiesToAccept, strategiesToExclude)

    });

    return [strategiesToAccept, strategiesToExclude]

  }
}


export class CustodianAndRiskProfileInstrumentsHandler extends RiskProfileInstrumentsHandler {
  constructor(custodian, srri=undefined, targetMarket=undefined, only_funds=undefined, vl_plan_allowed=undefined, skipOrderCheck=false, disableFunds=undefined) {
    super(srri, targetMarket, only_funds, disableFunds);

    this.__custodian = custodian;
    this.__vl_plan_allowed = vl_plan_allowed;
    this.__custodianData = {};
    this.__skipOrderCheck = skipOrderCheck;
  }

  getPaymentPlanInstrumentTypeAcceptanceCriteria(instrument) {
    return {
      "title": this.__disable_funds ? "Wertpapiere/Einzeltitel" : "Nur Fonds",
      "value": this.__disable_funds ? false : instrument.is_fund
    }
  }

  filterInstruments(instruments, skipCustodianCheck=false) {
    let [acceptedByRisk, excludedByRisk] = super.filterInstruments(instruments);

    if(!!skipCustodianCheck){
      return [acceptedByRisk, excludedByRisk]
    }

    return this._filterByCustodian(acceptedByRisk, excludedByRisk)

  }

  async getCustodianAndFilterInstruments(instruments, skipCustodianDataRetrieving=false) {

    let [acceptedByRisk, excludedByRisk] = this.filterInstruments(instruments, true);

    // get custodian data
    if (!skipCustodianDataRetrieving) {
      const res = await getCustodianData(acceptedByRisk, [this.__custodian]);
      this.__custodianData = res && res[this.__custodian] || {};
    }

    return this._filterByCustodian(acceptedByRisk, excludedByRisk);
  }

  _filterByCustodian(acceptedByRiskItems, instrumentsToExclude){
    let instrumentsToAccept = [];

    (acceptedByRiskItems || []).forEach(instrument => {

      const custodianInstrument = this.__custodianData[instrument.isin];
      // in case custodianData is empty - get data from instrument itself
      const custodianInfo = custodianInstrument || instrCustodianData(instrument, this.__custodian);
      const hasCustodianInfo = !_.isEmpty(custodianInfo);
      // c_hard_close_subscription = true ( instrument is blocked for buying )
      // data exists and SingleBuy or SavingsPlan possible
      let acceptanceCriterias = {
        "custodian": {
          "available": {
            "title": "Depotbank",
            "value": hasCustodianInfo
          }
        }
      };
      if(hasCustodianInfo){
        this.__checkOrderPossibleCriteria(acceptanceCriterias.custodian, custodianInfo, instrument);
      }

      if (isAcceptanceCriteriasValid(acceptanceCriterias)) {
        if(custodianInstrument){
          // set default custodian_data as dict (remove old arrays if present from #62034)
          if(!instrument.hasOwnProperty('custodian_data') || _.isArray(instrument.custodian_data)) {
            instrument.custodian_data = {};
          }
          // update instrument with custodian data
          instrument.custodian_data[this.__custodian] = custodianInstrument;
        }
        instrumentsToAccept.push(instrument)
      } else {
        instrument.disabledByFilter = true;
        instrument.disabledByAcceptanceCriterias = acceptanceCriterias;
        instrumentsToExclude.push(instrument)
      }

    });

    return [instrumentsToAccept, instrumentsToExclude]
  }

   __checkBuyPossibleCriteria(custodianCriterias, custodianInfo){
      custodianCriterias.buy_possible = {
        "title": "Handelbarkeit",
        "value": !custodianInfo.c_hard_close_subscription
      };
   }

  __checkOrderPossibleCriteria(custodianCriterias, custodianInfo){
    this.__checkBuyPossibleCriteria(custodianCriterias, custodianInfo);

    if (!this.__skipOrderCheck) {
      if (this.__vl_plan_allowed) {

        custodianCriterias.vl_plan_possible = {
          "title": "Vermögenswirksame Leistungen",
          "value": !!custodianInfo.c_vl_possible
        };

      } else {

        custodianCriterias.single_payment_or_savings_plan_possible = {
          "title": "Einmalanlage oder Sparplan",
          "value": !!(custodianInfo.c_single_payment_possible || custodianInfo.c_savings_plan_possible)
        };

      }
    }
  }
}

export class TradingAddInstrumentsHandler extends CustodianAndRiskProfileInstrumentsHandler {
  __checkOrderPossibleCriteria(custodianCriterias, custodianInfo) {
    super.__checkBuyPossibleCriteria(custodianCriterias, custodianInfo);
    custodianCriterias.single_payment_possible = {
      "title": "Einmalanlage",
      "value": !!custodianInfo.c_single_payment_possible
    };
  }
}


export const getRiskProfilingAnswers = (dataService, availableAssetClasses) => {
  if ([SERVICE_CONCEPTS.Anlageberatung, SERVICE_CONCEPTS.Anlagevermittlung].includes(dataService.serviceConcept)) {
    let step_uid;
    if (dataService.serviceConcept === SERVICE_CONCEPTS.Anlageberatung) {
      step_uid = 'A2'
    } else if (dataService.serviceConcept === SERVICE_CONCEPTS.Anlagevermittlung) {
      step_uid = 'K1'
    }
    const riskProfilingAnswers = {}
    for (const [assetClass, config] of Object.entries(availableAssetClasses || {})) {
      const answer = dataService._getPrevStepAnswer('risk_profile', step_uid, config.quiestion_uid, true, true)
      riskProfilingAnswers[assetClass] = answer && _.get(answer, 'answer.radio') === radioYesValue;
    }
    return riskProfilingAnswers
  }
  return null
}

export class EinzeltitelTradingInstrumentsHandler extends CustodianAndRiskProfileInstrumentsHandler {
  constructor(custodian, srri=undefined, targetMarket=undefined, only_funds=undefined, vl_plan_allowed=undefined,  portfolio=undefined, brokerLicense=undefined, serviceConcept=undefined, riskProfilingAnswers=undefined, disableFunds=undefined) {
    super(custodian, srri, targetMarket, only_funds, vl_plan_allowed, false, disableFunds);
    this._portfolio = portfolio;
    this._brokerLicense = brokerLicense;
    this._serviceConcept = serviceConcept;
    this._riskProfilingAnswers = riskProfilingAnswers;
  }

  filterInstruments(instruments, skipCustodianCheck=false) {
    if (this._serviceConcept === SERVICE_CONCEPTS.Anlageberatung && _.isNil(this.__srri)) {
      instruments.forEach(instrument => {
        instrument.disabledByFilter = true;
        instrument.disabledByAcceptanceCriterias = {
          general: {
            sri: {
              "title": 'Das Risikoprofil wurde noch nicht ermittelt.',
              "value": true
            }
          }
        }
      });

      return [instruments, []]
    }

    let nonFunds = [], funds = [];
    [nonFunds, funds] = splitEinzeltitelTradingInstruments(instruments, this._portfolio.available_asset_classes, nonFunds, funds)

    let instrumentsToAccept = [], instrumentsToExclude = [], acceptedByRisk = [], excludedByRisk = [];

    if (nonFunds.length) {
      (nonFunds || []).forEach(instrument => {
        const instrumentInternalAssetClass = _.get(this._portfolio.available_asset_classes, instrument.internal_asset_class, {})

        let acceptanceCriterias = {
          general: {
            instrument_type: {
              "title": instrumentInternalAssetClass.title || 'Wertpapiere/Einzeltitel',
              "value": false
            }
          }
        }

        if (this.__only_funds) {
          acceptanceCriterias.general.only_funds = {
            "title": "Nur Fonds",
            "value": this.isFundAssetTypeValid(instrument)
          }
        }

        checkEinzeltitelTradingAcceptanceCriterias(
          instrument, this._portfolio, this._brokerLicense, this.__srri, acceptanceCriterias, this._serviceConcept, this._riskProfilingAnswers)

        checkCriterias(instrument, acceptanceCriterias, instrumentsToAccept, instrumentsToExclude)

      })
    }
    if (funds.length) {
      [acceptedByRisk, excludedByRisk] = super.filterInstruments(funds, skipCustodianCheck);
    }

    if(!!skipCustodianCheck){
      return [[...instrumentsToAccept, ...acceptedByRisk], [...instrumentsToExclude, ...excludedByRisk]]
    }

    return this._filterByCustodian([...instrumentsToAccept, ...acceptedByRisk], [...instrumentsToExclude, ...excludedByRisk])
  }
}

export class TradingSkipInstrumentsFilterHandler extends CustodianAndRiskProfileInstrumentsHandler {

  async getCustodianAndFilterInstruments(instruments, skipCustodianCheck=false) {
    return super.getCustodianAndFilterInstruments(instruments, skipCustodianCheck)
  }
}

export class TradingSellInstrumentsHandler extends TradingSkipInstrumentsFilterHandler {

  constructor(custodian, srri=undefined, targetMarket=undefined, only_funds=undefined, vl_plan_allowed=undefined, skipOrderCheck=false, disableFunds=undefined, portfolio=undefined, brokerLicense=undefined) {
    super(custodian, srri=undefined, targetMarket, only_funds, vl_plan_allowed, skipOrderCheck, disableFunds);

    this._portfolio = portfolio;
    this._brokerLicense = brokerLicense;

  }

  filterInstruments(instruments, skipCustodianCheck=false) {

    let nonFunds = [], funds = [];
    [nonFunds, funds] = splitEinzeltitelTradingInstruments(instruments, this._portfolio.available_asset_classes, nonFunds, funds)

    let instrumentsToAccept = [], instrumentsToExclude = [], acceptedFunds = [], excludedFunds = [];

    if (nonFunds.length) {
      (nonFunds || []).forEach(instrument => {
        const instrumentInternalAssetClass = _.get(this._portfolio.available_asset_classes, instrument.internal_asset_class, {})

        let acceptanceCriterias = {
          general: {
            instrument_type: {
              "title": instrumentInternalAssetClass.title || 'Wertpapiere/Einzeltitel',
              "value": true
            },
          }
        }

        if (this.__only_funds) {
          acceptanceCriterias.general.only_funds = {
            "title": "Nur Fonds",
            "value": this.isFundAssetTypeValid(instrument)
          }
        }

        checkEinzeltitelTradingAcceptanceCriterias(
          instrument, this._portfolio, this._brokerLicense, undefined, acceptanceCriterias, undefined, undefined)

        checkCriterias(instrument, acceptanceCriterias, instrumentsToAccept, instrumentsToExclude)

      })
    }
    if (funds.length) {
      (funds || []).forEach(instrument => {
        let acceptanceCriterias = {
          general: {}
        }
        if (this.__only_funds || this.__disable_funds) {
          acceptanceCriterias.general.only_funds = {
            "title": this.__only_funds ? "Nur Fonds" : "Wertpapiere/Einzeltitel",
            "value": this.isFundAssetTypeValid(instrument)
          }
        }
        checkCriterias(instrument, acceptanceCriterias, acceptedFunds, excludedFunds)
      })
    }

    return [[...instrumentsToAccept, ...acceptedFunds], [...instrumentsToExclude, ...excludedFunds]]
  }

  __checkOrderPossibleCriteria(custodianCriterias, custodianInfo, instrument){
    custodianCriterias.sell_possible = {
      "title": "Verkaufen",
      "value": !!instrument.quantity && !custodianInfo.c_hard_close_redemption
    };
  }
}

export class TradingSwitchOutInstrumentsHandler extends TradingSkipInstrumentsFilterHandler {

  filterInstruments(instruments, skipCustodianCheck=false) {
    const instrumentsToAccept = [], instrumentsToExclude = [];

    (instruments || []).forEach((instrument) => {
      let acceptanceCriterias = {
        general: {
          only_funds: {
            "title": this.__disable_funds && !instrument.is_fund ? "Wertpapiere/Einzeltitel" : "Nur Fonds",
            "value": this.__disable_funds ? false : instrument.is_fund
          },
        }
      };

      checkCriterias(instrument, acceptanceCriterias, instrumentsToAccept, instrumentsToExclude)
    });

    return [instrumentsToAccept, instrumentsToExclude]
  }
  __checkOrderPossibleCriteria(custodianCriterias, custodianInfo, instrument){
    custodianCriterias.switch_out_possible = {
      "title": "Tauschen",
      "value": !!instrument.quantity && custodianInfo.c_switch_out_possible
    };
  }
}


/**
 * Class to handle existing payment plans.
 * No additional filtering will be there.
 * Class used only to extend existing payment plans with custodian data.
 */
export class PaymentPlanEditInstrumentsHandler extends CustodianAndRiskProfileInstrumentsHandler {
  constructor(custodian, disableFunds=undefined, preventParentFiltering=false) {
    super(custodian, undefined, undefined, undefined, undefined, undefined, disableFunds);
    // flag. prevents calling filterInstruments of parent class. Used by virtual order to filter out instruments if they are in portfolio
    // TODO take to another place -> duplicated

    if (preventParentFiltering){
      this.getCustodianAndFilterInstruments = function(instruments){
        return this.filterInstruments(instruments, true);
      }
    }
  }

  filterInstruments(instruments, skipCustodianCheck = false) {
    let instrumentsToAccept = [];
    let instrumentsToExclude = [];

    (instruments || []).forEach(instrument => {
      const acceptanceCriterias = {
        general: {
          only_funds: this.getPaymentPlanInstrumentTypeAcceptanceCriteria(instrument),
        }
      };

      checkCriterias(instrument, acceptanceCriterias, instrumentsToAccept, instrumentsToExclude)
    });

    return [instrumentsToAccept, instrumentsToExclude];
  }

  /**
   * Method override to update instruments with custodian data.
   * @param acceptedByRiskItems
   * @param instrumentsToExclude
   * @returns {*[]}
   * @private
   */
  _filterByCustodian(acceptedByRiskItems, instrumentsToExclude){

    let instrumentsToAccept = [];

    (acceptedByRiskItems || []).forEach((instrument) => {

      const custodianInstrument = this.__custodianData[instrument.isin];

      if(custodianInstrument){
        // set default custodian_data as dict (remove old arrays if present from #62034)
        if(!instrument.hasOwnProperty('custodian_data') || _.isArray(instrument.custodian_data)) {
          instrument.custodian_data = {};
        }
        // update instrument with custodian data
        instrument.custodian_data[this.__custodian] = custodianInstrument;
      }

      instrumentsToAccept.push(instrument)

    });

    return [instrumentsToAccept, []]

  }
}


export class SavingPlanAddInstrumentsHandler extends CustodianAndRiskProfileInstrumentsHandler {
  constructor(custodian, srri=undefined, targetMarket=undefined, only_funds=undefined, disableFunds=undefined, preventParentFiltering=false) {
    super(custodian, srri, targetMarket, only_funds, undefined, undefined, disableFunds);
    // flag. prevents calling filterInstruments of parent class. Used by virtual order to filter out instruments if they are in portfolio
    this.__preventParentFiltering = preventParentFiltering;

    if (this.__preventParentFiltering){
      this.getCustodianAndFilterInstruments = function(instruments){
        return this.filterInstruments(instruments, true);
      }
    }
  }

  filterInstruments(instruments, skipCustodianCheck=false) {
    let instrumentsToAccept = [];
    let instrumentsToExclude = [];

    (instruments || []).forEach(instrument => {
      const acceptanceCriterias = {
        general: {
          only_funds: this.getPaymentPlanInstrumentTypeAcceptanceCriteria(instrument),
        }
      };

      checkCriterias(instrument, acceptanceCriterias, instrumentsToAccept, instrumentsToExclude)
    });

    if (this.__preventParentFiltering ){ return [instrumentsToAccept, instrumentsToExclude] }

    let [accepted, excludedByParent] = super.filterInstruments(instrumentsToAccept, skipCustodianCheck);

    return [accepted, [...instrumentsToExclude, ...excludedByParent]];
  }

  __checkOrderPossibleCriteria(custodianCriterias, custodianInfo){
    super.__checkBuyPossibleCriteria(custodianCriterias, custodianInfo);
    custodianCriterias.single_payment_possible = {
      "title": "Sparplan",
      "value": !!custodianInfo.c_savings_plan_possible
    };
  }
}


export class PayoutPlanAddInstrumentsHandler extends SavingPlanAddInstrumentsHandler {
  constructor(custodian, srri=undefined, targetMarket=undefined, only_funds=undefined, disableFunds=undefined) {
    super(custodian, srri, targetMarket, only_funds, disableFunds);
    this.__payoutPlanPossibleTitle = 'Entnahmeplan'
  }

  __checkOrderPossibleCriteria(custodianCriterias, custodianInfo){
    // We do not need buy for payout plans
    custodianCriterias.payout_plan_possible = {
      "title": this.__payoutPlanPossibleTitle,
      "value": !!custodianInfo.c_payout_plan_possible
    };
  }
}


export class SwitchInPlanInstrumentsHandler extends SavingPlanAddInstrumentsHandler {
  constructor(custodian, srri=undefined, targetMarket=undefined, only_funds=undefined, disableFunds=undefined) {
    super(custodian, srri, targetMarket, only_funds, disableFunds);
  }

  __checkOrderPossibleCriteria(custodianCriterias, custodianInfo, asset){

    const fundType = _.get(asset, 'asset_classification.sub_class_2') || '';
    const investmentCompany = _.get(asset, 'investment_company') || '';
    const isRealEstate = fundType.toLowerCase() == 'Real Estate'.toLowerCase();
    const switchPlanCompanyAllowed = investmentCompany.replaceAll('.', '').toLowerCase() != 'Dimensional Fund Advisors Ltd'.toLowerCase();

    const isinCond = !isRealEstate && (custodianInfo.c_company_id != FODB_CUSTODIAN_ID || switchPlanCompanyAllowed);

    // We do not need buy for payout plans
    custodianCriterias.payout_plan_possible = {
      "title": "Tauschplan",
      "value": !!calculateSwitchPlanPossible(custodianInfo) && isinCond
    };
  }
}


export class TradingSwitchInInstrumentHandler extends TradingAddInstrumentsHandler {
  constructor(custodian, srri=undefined, targetMarket=undefined) {
    super(custodian, srri, targetMarket, true);
  }

  _filterByCustodian(acceptedByRiskItems, excludedByRisk){
    const instrumentsToAccept = [];
    const [acceptedByCustodian, instrumentsToExclude] = super._filterByCustodian(acceptedByRiskItems, excludedByRisk);

    (acceptedByCustodian || []).forEach(instrument => {
      const custodianInstrument = this.__custodianData[instrument.isin];
      // in case custodianData is empty - get data from instrument itself
      const custodianInfo = custodianInstrument || instrCustodianData(instrument, this.__custodian);
      let acceptanceCriterias = {
        "custodian": {
          "switch_in_possible": {
            "title": "Tauschen",
            "value": custodianInfo && !!custodianInfo.c_switch_in_possible
          }
        }
      };

      checkCriterias(instrument, acceptanceCriterias, instrumentsToAccept, instrumentsToExclude)
    });

    return [instrumentsToAccept, instrumentsToExclude];
  }
}

export class EinzeltitelTradingSwitchInInstrumentsHandler extends TradingSwitchInInstrumentHandler {
  constructor(custodian, available_asset_classes, srri=undefined, targetMarket=undefined) {
    super(custodian, srri, targetMarket, true);
    this.__available_asset_classes = available_asset_classes;
  }

  _filterByCustodian(acceptedByRiskItems, excludedByRisk) {
    let instrumentsToAccept = [], instrumentsToExclude = [], acceptedByRiskItemsInternalNonFunds = [], acceptedByRiskItemsFunds = [];
    let acceptedByCustodian = [], excludedByCustodian = [];
    [acceptedByRiskItemsInternalNonFunds, acceptedByRiskItemsFunds] = splitEinzeltitelTradingInstruments(
      acceptedByRiskItems, this.__available_asset_classes, acceptedByRiskItemsInternalNonFunds, acceptedByRiskItemsFunds);

    if (acceptedByRiskItemsInternalNonFunds.length) {
      (acceptedByRiskItemsInternalNonFunds || []).forEach(instrument => {
        const acceptanceCriterias = {
          "custodian": {
            "switch_in_possible": {
              "title": "Tauschen",
              "value": false
            }
          }
        };
        instrument.disabledByFilter = true;
        instrument.disabledByAcceptanceCriterias = acceptanceCriterias;
        instrumentsToExclude.push(instrument)
      })
    }
    if (acceptedByRiskItemsFunds.length) {
      [acceptedByCustodian, excludedByCustodian] = super._filterByCustodian(acceptedByRiskItemsFunds, excludedByRisk);
    } else {
      instrumentsToExclude = [...instrumentsToExclude, ...excludedByRisk]
    }
    return [[...acceptedByCustodian, ...instrumentsToAccept] , [...excludedByCustodian, ...instrumentsToExclude]];
  }

}

/** Replace placeholders with components
 * NOTE: For the moment works only for specific case. componentsDict could contain only one key
 */
export const replacePlaceholdersWithComponents = (text, componentsDict) => {
  let textPartsUpdated = [];

  for (let placeholder in componentsDict) {

    let textParts = text.split(`<${placeholder}>`);

    textParts.forEach((part, index) => {
      textPartsUpdated.push(part);

      if(index != textParts.length - 1) {
        textPartsUpdated.push(<div key={index} style={{display: 'inline-block', padding: '0 16px', verticalAlign: 'middle'}}>{componentsDict[placeholder]}</div>)
      }
    })

  }

  return textPartsUpdated
};

export const renderQuestion = (question, handleAnswerChange, dataService) => {
  const type = getQuestionType(question);
  const QuestionComponent = getQuestionComponent(type);

  return (
    <Grid item key={question.uid} data-id={question.uid} sm={getQuestionSize(question)}>
      {QuestionComponent && (
        <QuestionComponent
          question={question}
          onAnswerChange={handleAnswerChange}
          dataService={dataService}
        />
      )}
    </Grid>
  )
};

function getAvailableOptions(banksData, custodian_id, option_name){
  const data = getBankData(banksData, custodian_id, option_name);
  if(data){
    return data.map(d => {
      return {id: d, value: d, label: `${d} %`}
    });
  }

  return [];
}

export const getDiscountsOptions = (banksData, custodian_id, data_field_key='discount') => {
  // get available discounts
  let availableOptions = getAvailableOptions(banksData, custodian_id, data_field_key).filter(bankData => bankData.value != 0);
  let noDiscountOption = {id: 'noneOptionId', value: undefined, label: 'Kein'};
  return _.isEmpty(availableOptions) ? [] : [noDiscountOption, ...availableOptions]
};

export const getKickbacksOptions = (banksData, custodian_id, data_field_key='kickback') => {
  // get available kickbacks
  if (getBankData(banksData, custodian_id, 'kickback_allowed')) {
    return getAvailableOptions(banksData, custodian_id, data_field_key)
  }
  return []
};

export const getServiceFeeMax = (banksData, custodian_id, data_field_key='service_fee_max') => {
  // get max service fee value

  if (getBankData(banksData, custodian_id, 'service_fee_allowed')){
    return getBankData(banksData, custodian_id, data_field_key);
  }
  return undefined
};

export const getListOptionsFromBankData = (banksData, custodian_id, data_field_key='savings_plan_periodic_plans') => {
  const bankData = getBankData(banksData, custodian_id, data_field_key);
  if(bankData){
    return bankData.map(d => {
      return {id: d.value, value: d.value, label: d.label}
    });
  }

  return [];
};

/**
 * Check, if order clearing account allowed for specific bank.
 *
 * @param {Array<*>} banksData Banks data
 * @param custodian_id Custodian identifier
 *
 * @return {Boolean} Flag, that indicate is order clearing account possible, or not.
 */
export const orderClearingAccountAllowed = (banksData, custodian_id) => {
  return !!getBankData(banksData, custodian_id, 'order_clearing_account_allowed')
};

/**
 * Check, if order clearing account convertation to bank account allowed for specific bank.
 *
 * @param {Array<*>} banksData Banks data
 * @param custodian_id Custodian identifier
 *
 * @return {Boolean} Flag, that indicate is order clearing account possible, or not.
 */
export const orderClearingAccountToBankAccountAllowed = (banksData, custodian_id) => {
  return !!getBankData(banksData, custodian_id, 'order_clearing_account_to_bank_account_allowed');
};

/**
 * Check, if clearing account balancing allowed for specific bank.
 *
 * @param {Array<*>} banksData Banks data
 * @param custodian_id Custodian identifier
 *
 * @return {Boolean} Flag, that indicate is clearing account balancing possible, or not.
 */
export const clearingAccountBalancingAllowed = (banksData, custodian_id) => {
  return !!getBankData(banksData, custodian_id, 'clearing_account_balancing_allowed')
};

export const getBankData = (banksData, custodian_id, data_field_key) => {
  if (data_field_key) {
    return _.get(banksData.find(b => b.custodian_id == custodian_id), data_field_key);
  }
  return banksData.find(b => b.custodian_id == custodian_id)
};

export const getCustodianData = async (instruments, custodians, withCompletelySoldAssets=false) => {
  if(instruments){
    let isins = new Set();
    instruments.forEach(i => {
      if(i.isin){
        isins.add(i.isin)
      } else if (withCompletelySoldAssets && i.is_profit_loss){
        (i.sub_components || []).forEach(sub_item => {
          if(!!sub_item.isin) {
            isins.add(sub_item.isin)
          }
        })
      }
    });
    isins = Array.from(isins);
    if(isins.length > 0){
      const res = await ModelPortfolioResource.assetsCustodiansDetails(isins, custodians).catch(error => {
        //for rejected promises eg 502
        throw Error("Fehler beim Abrufen der Gerätedaten");
      });

      if(res.assets && !_.isEmpty(res.assets.errors)){
        throw Error("Fehler beim Abrufen der Gerätedaten");
      }

      return res.assets && res.assets.data;
    }
  }
};

export const getDisabledTradingOptions = (item, custodiansInstrumentsData, availableAssetClasses, brokerLicense) => {
  const disabledTradingOptions = [];

  const isEinzeltitelInstrument = !(item.is_fund || _.get(item, 'data.is_fund'))
  const custodianInstrData = custodiansInstrumentsData && custodiansInstrumentsData[item.isin] || {};
  // switch out allowed ONLY for funds
  if(!item.quantity || !custodianInstrData.c_switch_out_possible || isEinzeltitelInstrument) {
    disabledTradingOptions.push(TRADING_ACTION_SWITCH);
  }

  // check Buy possible
  if (custodianInstrData.c_hard_close_subscription || !custodianInstrData.c_single_payment_possible) {
    disabledTradingOptions.push(TRADING_ACTION_BUY);
  }

  // Additional validation for Einzeltitel to check if broker has valid license and depot support Einzeltitel
  if (isEinzeltitelInstrument) {

    const einzeltitelTradingPossible = !_.isEmpty(availableAssetClasses)
    const licenseValid = einzeltitelTradingPossible
      && !!_.find(Object.values(availableAssetClasses), (config) => {
        return !!(config.broker_license || []).find((configLicense) => configLicense == brokerLicense)
      })
    // check Buy possible
    if (!licenseValid) {
      disabledTradingOptions.push(TRADING_ACTION_BUY);
    }

  }

  // check Sell possible
  if (!item.quantity || _.isEmpty(custodianInstrData) || custodianInstrData.c_hard_close_redemption) {
    disabledTradingOptions.push(TRADING_ACTION_SELL);
  }

  // check Saving Plan possible
  if (!custodianInstrData.c_savings_plan_possible) {
    disabledTradingOptions.push(TRADING_ACTION_SAVINGS_PLAN);
  }

  return disabledTradingOptions;
}

export const validateMultilineContent = (content, targetHeight, isHtml=true) => {
  let $contentContainer = document.getElementById('multiline-content-validator');
  // in case container does not exist - create it
  if (!$contentContainer) {
    $contentContainer = document.createElement('div');
    $contentContainer.setAttribute("id", "multiline-content-validator");
    document.body.appendChild($contentContainer);
  }

  $contentContainer.innerHTML = '';
  $contentContainer.style.height = `${targetHeight}px`;

  let $contentWrapper = document.createElement('div');
  if(isHtml){
    $contentWrapper.classList.add('ql-editor');
  }

  $contentWrapper.innerHTML = `<p>${content}</p>`;
  $contentContainer.appendChild($contentWrapper);

  return $contentWrapper.offsetHeight <= $contentContainer.offsetHeight - 8;
};


export const isStepQuestionsEmpty = (step, questionsToSkip=[], fullCheck=true) => {

  const questionsToValidate = _.filter(step.question,q => !questionsToSkip.includes(q.uid));

  const checkingFunction = fullCheck ? _.every : _.some;

  return checkingFunction(questionsToValidate, (q) => {

    const isDocumentEmpty = q.answer
      && (q.answer.hasOwnProperty('formData')
        && !q.answer.formData
        && q.answer.hasOwnProperty('guid')
        && !q.answer.guid);

    return !q.answer || (_.isString(q.answer) && _.isEmpty(q.answer)) || isDocumentEmpty
  })

};


/**
 * Check, if questions from legitimation step are empty.
 *
 * @param customerType
 * @param step Legitimation step
 * @param questionsToSkip Additionla questions identifiers, that should be ignored.
 *                        legitimation['type'], legitimation['country'] and
 *                        legitimation['GUID'] will be always ignored.
 * @param fullCheck
 * @return {boolean}
 */
export const isLegitimationQuestionsEmpty = (customerType, step, questionsToSkip=[], fullCheck=true) => {

  let memberIndex = getMemberIndexFromStepUID(step.uid);

  const skipQuestions = [
    buildQuestionUIDForMember("legitimation['type']", memberIndex, customerType),
    buildQuestionUIDForMember("legitimation['country']", memberIndex, customerType),
    buildQuestionUIDForMember("legitimation['GUID']", memberIndex, customerType),
    buildQuestionUIDForMember("legitimation_is_valid", memberIndex, customerType)];

  questionsToSkip.forEach(questionUID => skipQuestions.push(buildQuestionUIDForMember(questionUID, memberIndex, customerType)));

  return isStepQuestionsEmpty(step, skipQuestions, fullCheck)

};


/**
 * Check, if data from legitimation step is outdated.
 *
 * @param step - Step data
 *
 * @param customerType
 * @return { Boolean } Boolean value, that indicate, if legitimation data is outdated, or not.
 */
export const isLegitimationQuestionOutdated = (step, customerType) => {

  const memberIndex = getMemberIndexFromStepUID(getUID(step.uid));

  let expirationDateQuestion = _.find(
    step.question,
      q => q.uid == buildQuestionUIDForMember("legitimation['expiry_date']", memberIndex, customerType));

  if (!expirationDateQuestion || !expirationDateQuestion.answer) return false;

  return moment(expirationDateQuestion.answer).isBefore(moment(), 'day')
};

/**
 * Check, if list of legitimation documents contains
 * legitimation with specific type and number.
 *
 * @param legitimations List of legitimation documents
 * @param type Legitimation type
 * @param number Legitimation number
 */
export const getLegitimationData = (legitimations, type, number, mapping, skipOutdated=false) => {
  const data = legitimations.find((l) => mapping.hasOwnProperty(l.type) && mapping[l.type] == type && l.identificationNumber == number);

  if (skipOutdated && data) {
    if (moment(data.expirationDate, 'DD.MM.YYYY').isBefore(moment(), 'day')) {
      return undefined
    }
  }

  return data

};

/**
 * Get main legitimation data.
 *
 * @param {Array<Object>} legitimations List of legitimations
 * @return {unknown}
 */
export const getMainLegitimationData = (legitimations) => {
  return _.find(legitimations, (l) => l.main)
};

export const getLegitimationFileFormDataFromGuid = (guid) => {
  QuestionnairesHandlerResource.getDocument(guid).then(response => {

    let formData = new FormData();
    let fileBlob = new Blob([response.data], {type: response.headers['content-type']});
    formData.append('file', fileBlob, getFileNameFromResponse(response, true));

    return formData

  }).catch((error) => {
    return undefined
  })
};


export const throwErrorMsg = (errors, capTitle=true) => {
  if (!_.isEmpty(errors)) {
    let msg_list = [];
    Object.keys(errors).map(field => {
      errors[field].map(err => {
        let msg = err;
        if(!!field){ // in case field is not empty
          const fieldName = capTitle ? _.upperFirst(field.replace('_', ' ')) : field;
          msg = `${fieldName}: ${err}`
        }

        msg_list.push(msg);
      })
    });
    throw Error(msg_list.join("\n"));
  }
};


export const getPortfolioClearingAccountQuestionUID = (portfolio) =>
  `${CLEARING_ACCOUNT_QUESTION_UID}[\"portfolio_${portfolio.portfolioId}\"]`;

export const getPortfolioClearingAccountOptionQuestionUID = (portfolio) =>
  `${CLEARING_ACCOUNT_OPTION_QUESTION_UID}[\"portfolio_${portfolio.portfolioId}\"]`;

export const getPortfolioClearingAccountSaldoConfirmationQuestionUID = (portfolio) =>
  `${CLEARING_ACCOUNT_BALANCING_QUESTION_UID}[\"portfolio_${portfolio.portfolioId}\"]`;

export const clearingAccountUIDToConfirmation = (uid) =>
  uid.replace(CLEARING_ACCOUNT_QUESTION_UID, CLEARING_ACCOUNT_BALANCING_QUESTION_UID);

export const clearingAccountOptionUIDToConfirmation = (uid) =>
  uid.replace(CLEARING_ACCOUNT_OPTION_QUESTION_UID, CLEARING_ACCOUNT_BALANCING_QUESTION_UID);

export const clearingAccountOptionUIDToClearingAccount = (uid) =>
  uid.replace(CLEARING_ACCOUNT_OPTION_QUESTION_UID, CLEARING_ACCOUNT_QUESTION_UID);

export const clearingAccountUIDToOption = (uid) =>
  uid.replace(CLEARING_ACCOUNT_QUESTION_UID, CLEARING_ACCOUNT_OPTION_QUESTION_UID);

export const buildPortfolioClearingAccountOptionQuestion = (portfolio, asBankAccount=false, transactionsAmount) => {

  const choices = Object.values(portfolio.clearing_account || {}).map((cAccount) => ({
    id: cAccount.id,
    uid: cAccount.id,
    info: cAccount.value < transactionsAmount ? CLEARING_ACCOUNT_NOT_ENOUGH_MONEY_WARNING_MSG : undefined,
    text: (
      <ClearingAccount
        name={cAccount.account_number}
        amount={cAccount.value}
        date={moment(cAccount.value_date, 'YYYY-MM-DD')}
        iban={asBankAccount && cAccount.iban}
      />
    ),
    clearing_account: {
      account_number: cAccount.account_number,
      iban: cAccount.iban,
      value: cAccount.value,
      value_date: cAccount.value_date
    }
  }));

  return {
    uid: getPortfolioClearingAccountOptionQuestionUID(portfolio),
    type: "normal",
    answer: choices[0].id,
    behaviour: ["select"],
    config: {
      choices: choices
    },
    custom_classes: {"inputRoot": "noMarginBottom"},
    isValid: function (step, afterChange, service) {

      this.error = null;

      const clearingAccountCheckbox = service.getStepAnswer(
        REF_ACCOUNT_STEP_ID, clearingAccountOptionUIDToClearingAccount(this.uid));

      if (clearingAccountCheckbox && !this.answer) {
        this.error = FIELD_REQUIRED_MSG;
        return false;
      }

      if (!asBankAccount) {
        return true;
      }

      const mapping = {
        "clearing_account.{id}.account_holder": 'Kontoinhaber',
        "data.bank": 'Name des Kreditinstitutes',
        "clearing_account.{id}.bic": 'BIC',
        "clearing_account.{id}.iban": 'IBAN',
      };

      if (!!this.answer) {
        const cAccountId = this.answer;
        Object.keys(mapping).forEach((fieldName) => {
          if (!_.get(portfolio, fieldName.replace('{id}', cAccountId))) {
            this.error = `${mapping[fieldName]} ist ein Pflichtfeld`;
          }
        });
      }

      return _.isNil(this.error);
    }
  }
}

export const buildPortfolioClearingAccountQuestion = (portfolio, asBankAccount=false) => ({
  uid: getPortfolioClearingAccountQuestionUID(portfolio),
  question_text: (
    <ClearingAccount
      depot={portfolio.data.name}
    />
  ),
  type: 'checkbox',
});

export const buildPortfolioClearingAccountBalancingQuestion = (portfolio) => ({
  uid: getPortfolioClearingAccountSaldoConfirmationQuestionUID(portfolio),
  question_text: 'Um diese Transaktion durchführen zu können, stimmt der Kunde einer automatischen Ausgleichslastschrift auf dem Abwicklungskonto zu. Der Betrag wird automatisch von der, bei der Depotbank hinterlegten, Referenzbankverbindung abgebucht.',
  type: 'checkbox',
  optional: true
});

const USE_PREV_ANSWERS_FOR = [
  'notes_for_origin_of_assets', 'conflicts', 'conflicts_details', 'risk_additional', 'risk_additional_details',
  'interview_initiative', CHARGING_BANK_FEE_UID
];

export const filterQuestionWithUsePrevAnswers = (questions) => {
  return (questions || []).filter(q => USE_PREV_ANSWERS_FOR.includes(q.question_uid))
};

export const answerFromPrevStep = (question, dataService) => {

  const answersGetters = {
    "H1": "1",
    "H3": "2",
    "H2": "3",
    "H4": "4",
    "H5": "5",
    "H6": "6",
  };

  const checkboxUID = answersGetters[getUID(question.uid)];
  if(checkboxUID && dataService){
    const [investmentUID, advisoryUID] = dataService.isNewDesign ? ['K21', 'A21'] : ['K2', 'A3'];
    const stepUID = dataService.serviceConcept === SERVICE_CONCEPTS.Anlagevermittlung ? investmentUID : advisoryUID;
    const prevQuestion = dataService.getQuestion(stepUID, 'I3');

    if (prevQuestion && _.isArray(prevQuestion.answer) && prevQuestion.answer.includes(checkboxUID)) {
      return {
        radio: radioYesUID,
        period_select: radioSelectNoKnowledgeValue,
        order_select: radioSelectNoExperienceValue
      };
    }
  }
};

export function _prepareBanksFromResponse(response) {
  let result = [];

  response.forEach((bank, bankIndex) => {
    bank.depots.forEach((depot, depotIndex) => {

      result.push({
        id: +`${bankIndex}${depotIndex}`,
        bank: bank.code,
        bankCompanyId: bank.custodian_id,
        onboarding_savings_plan_min_amount_payment: bank.onboarding_savings_plan_min_amount_payment,
        onboarding_vl_plan_min_amount_payment: bank.onboarding_vl_plan_min_amount_payment,
        onboarding_min_amount_payment: bank.onboarding_min_amount_payment,
        ordering_min_amount_payment: bank.ordering_min_amount_payment,
        onboarding_discounts_allowed: bank.onboarding_discounts_allowed,
        ordering_savings_plan_min_amount_payment: bank.ordering_savings_plan_min_amount_payment,
        einzeltitel_discounts_allowed: bank.einzeltitel_discounts_allowed,
        onboarding_vl_plan_allowed: bank.onboarding_vl_plan_allowed,
        depot: depot.name,
        depot_id: depot.id,
        is_online: bank.is_online,
        is_private_investment: bank.is_private_investment,
        contract_types: (depot.depot_type && depot.depot_type.map(t => ({
          ...t,
          value: t.id,
          label: t.name,
        }))) || [],
        bankId: bank.id
      })
    })
  });

  return _.sortBy(result, (o) => _.lowerCase(o.depot));
}

export const setContactsOptionalFlag = (steps, optional) => {
  steps.forEach(step => {
    if (USER_CONTACT_STEP_IDENTIFIERS.includes(getUID(step.uid))) {
      step.question.forEach(stepQuestion => {
        if (getUID(stepQuestion.uid) == 'A-contacts') {
          stepQuestion.question.forEach(contactQuestion => {
            contactQuestion.optional = optional
          })
        }
      })
    }
  })
};

export const validateCustomer = async (customer_id, dispatch) => {
  // get customer details - to check if customer exists
  const customer = await CustomerResource.at(`${customer_id}/details/`).get();
  const isMinorInCrm = customer.typeId == '6';
  const isMinorInOurApp = customer.customer_type == CUSTOMER_TYPES.MINOR;

  if (customer.customer_type == CUSTOMER_TYPES.COMPANY){
    throw Error("Diese Option ist für juristische Personen nicht verfügbar");
  } else if(customer.salutation == '4' && customer.customer_type != CUSTOMER_TYPES.COUPLE){ // salutation: Herr "1", Frau"2", Firma "3", Gemeinschaft (Couple) "4", Minderjährig "6"
    // if it's couple by salutation but is_couple=false - something wrong with relationships
    throw Error("Die Gemeinschaft ist unvollständig. Bitte korrigieren Sie dies im CRM-Bereich.");
  } else if(isMinorInCrm && !isMinorInOurApp || (!isMinorInCrm && isMinorInOurApp) ){
    // if it's minor by type but is_minor=false - something wrong with relationships or age
    // of it's minor by age or guardians - but wrong type

    if(isMinorInCrm && minorIsLegalAgeOrOlder(customer)){
      // send request to update customer type in CRM (Kunde - single customer in CRM)
      dispatch(displayWarningSnackBar(WRONG_TYPE_MINOR_IN_CRM_WARNING))
    } else {
      throw Error("Die Angaben zu dem minderjährigen Kunden sind unvollständig. Bitte korrigieren Sie dieses im CRM-Bereich.");
    }
  }

  return customer;
};

const getCustomerAge = (customer, units='years') => {
  return moment().diff(moment(_.get(customer, birthConfigs.birth_date.dante_field), DATE_FORMAT), units, true);
};

export const minorIsLegalAgeOrOlder = (customer) => {
  const ageInYears = getCustomerAge(customer);
  return ageInYears >= LEGAL_AGE
};

export const getDanteCustomerFullName = (customer) => {
  const lastName = _.get(customer, personalInformationConfigs.lastName.dante_field);
  return customer.customer_type == CUSTOMER_TYPES.COUPLE ? lastName : `${_.get(customer, personalInformationConfigs.firstName.dante_field)} ${lastName}`;
};

export const checkMinor = (customer, dispatch, isTrading=false) => {
  if(customer.customer_type == CUSTOMER_TYPES.MINOR) {
    const ageInMonths = getCustomerAge(customer, 'months');
    const customerFullName = getDanteCustomerFullName(customer);
    // 1 month to Legal
    if (ageInMonths > LEGAL_AGE * 12 - 1) {
      dispatch(displayWarningSnackBar(
        `${customerFullName} erreicht in weniger als einem Monat die Volljährigkeit. Möchten Sie trotzdem ein Minderjährigendepot eröffnen?`))
    } else {
      const snackBarMessage = isTrading
        ? `Sie beginnen eine Beratung für den Minderjährigen ${customerFullName}. Bitte fahren Sie fort.`
        : `Sie möchten ein Minderjährigendepot für ${customerFullName} eröffnen. Bitte fahren Sie fort.`;
      dispatch(displaySuccessSnackBar(snackBarMessage, SNACKBAR_DURATION_IN_SEC));
    }

    // if only one guardian - we need get custody certificate
    if(customer.relationships.length === 1){
      const custodyService = new CustodyCertificateService('custody_certificate', 'Sorgerecht');
      custodyService.customer_type = customer.customer_type;
      custodyService.members = customer.relationships;
      return custodyService;
    }
  }
};

export const prepareDocumentsFilterData = (optionalDocuments) => {
  // convert banks data to DocumentsFilter representation
  const parseFolders = (documents, acc) => {
    documents.map(doc => {
      acc.name || (acc.name = doc.folder.name);
      acc.id || (acc.id = doc.folder.id);
      acc.documents.push({
        'id': doc.id,
        'name': doc.name,
        'document_exist': doc.document_exist,
        'updated_at': doc.updated_at,
        'to_optional_list': doc.to_optional_list
      })
    });
    return acc
  };

  let groupedRootDocs = _.groupBy(optionalDocuments, function(d) {
    return `${JSON.stringify(d.parent)}`
  });
  let filterData = [];

  try {
    for (const [index, [rootFolder, docs]] of Object.entries(Object.entries(groupedRootDocs))) {
      let rootFolderObj = JSON.parse(rootFolder);
      filterData.push({
        'documents': [],
        'folders': [],
        'id': rootFolderObj.id,
        'name': rootFolderObj.name,
        'to_optional_list': rootFolderObj.to_optional_list
      });
      parseFolders(docs, filterData[index]);
      let childDocs = _.groupBy(docs, function(d) {return d.folder.name});
      for (const [f, d] of Object.entries(childDocs)) {
        if (f !== rootFolderObj.name) {
          filterData[index].folders.push(parseFolders(d, {
            'documents': [],
            'folders': [],
            'id': undefined,
            'name': undefined,
            'to_optional_list': false
          }))
        }
      }
    }
  } catch (e) {
    console.log(e)
  }

  return {'data': filterData}
};

export const getContractTypeConfiguration = (contractType, configurationKey, defaultValue=undefined) => {

  if (!contractType.configuration) {
    return defaultValue
  }

  return _.get(contractType.configuration, configurationKey) || defaultValue
};

export const getMemberFieldName = (customer_type) => {
  return customer_type == CUSTOMER_TYPES.MINOR ? 'legal_guardian' : 'account_holder';
};

export const getPermanentSuitabilityCheckQuestionChoices = (isSingleCustomer) => ([
  {
    "id": 2,
    "uid": radioNoUID,
    "text": isSingleCustomer ? SINGLE_CUSTOMER_PERMANENT_SUITABILITY_CHECK_DISABLED_TEXT : COUPLE_PERMANENT_SUITABILITY_CHECK_DISABLED_TEXT
  }, {
    "id": 1,
    "uid": radioYesUID,
    "text": isSingleCustomer ? SINGLE_CUSTOMER_PERMANENT_SUITABILITY_CHECK_ENABLED_TEXT : COUPLE_PERMANENT_SUITABILITY_CHECK_ENABLED_TEXT
  }
]);

export const calculateInstrumentsAverageSRRI = (instruments, getInstrumentAmountFunc) => {

  if (_.isEmpty(instruments)) {
    return 0
  }

  const totalInvestedAmount = _.sumBy(instruments, getInstrumentAmountFunc);
  if (!totalInvestedAmount) {
    return 0
  }

  const averageSRRI = _.sumBy(instruments, (instrument) => {

    const sri = getInstrumentSri(instrument)

    if (!sri) {
      return 0
    }
    return (getInstrumentAmountFunc(instrument) * sri)
  }) / totalInvestedAmount;

  return averageSRRI.toFixed(2)

};

/**
 * Validate, if list of assets contains at least one asset with risk higher then client SRRI
 * @param {{}[]} assets List of assets
 */
export const assetsWithHighRiskExists = (assets, srriToCompare, getAssetSrriCallback, ...assetSrriCallbackArgs) => _.find(assets,
  (_asset) => {
    const assetSRRI = getAssetSrriCallback(_asset, ...assetSrriCallbackArgs);
    return assetSRRI && srriToCompare && assetSRRI > srriToCompare
  });

const getAssetSRRI = (asset) => asset.sri;


export const getRiskScoreConfirmation = (dataService, assets, isTradingSingleInvestment, getAssetSrriCallback=getAssetSRRI, ...assetSrriCallbackArgs) => {

  const serviceConcept = dataService.serviceConcept || dataService._service_concept;

  const riskScoreConfirmationRequired = !_.isEmpty(assets)
    && serviceConcept == SERVICE_CONCEPTS.Anlageberatung
    && !!assetsWithHighRiskExists(assets, dataService._srri, getAssetSrriCallback, ...assetSrriCallbackArgs);

  // In case of MP/PI trading we will have only one element inside.
  const isInvestmentStrategy = !!_.get(assets, '0.isInvestmentStrategy') || !!_.get(assets, '0.data.isPrivateInvestment');

  // For now experience confirmation required only for onboarding as we do not block portfolios with high experience for trading
  const experienceConfirmationRequired = !dataService.is_trading && isInvestmentStrategy
    && !isKnowledgeAndExperienceValid(assets[0], dataService._target_market);

  if (!riskScoreConfirmationRequired && !experienceConfirmationRequired) return;
  let singleInvestment;
  if (dataService.is_trading) {
    singleInvestment = isTradingSingleInvestment
  } else {
    const investedAmountData = dataService.getInvestedAmountData();
    singleInvestment = investedAmountData.single_investment_enabled
  }

  if (isInvestmentStrategy) {
    _.set(assets, '0.risk_score_confirmation_required', riskScoreConfirmationRequired);
    _.set(assets, '0.experience_confirmation_required', experienceConfirmationRequired);
    if (riskScoreConfirmationRequired && experienceConfirmationRequired) {
      return INVESTMENT_STRATEGY_SINGLE_INVESTMENT_HIGH_RISK_SCORE_AND_EXPERIENCE_WARNING
    }

    return riskScoreConfirmationRequired
      ? INVESTMENT_STRATEGY_SINGLE_INVESTMENT_HIGH_RISK_SCORE_WARNING
      : INVESTMENT_STRATEGY_SINGLE_INVESTMENT_HIGH_EXPERIENCE_WARNING
  }

  return singleInvestment
    ? SINGLE_INVESTMENT_HIGH_RISK_SCORE_WARNING
    : SAVINGS_PLAN_HIGH_RISK_SCORE_WARNING

};

export const isESGEnabled = () => {
  let sharedStorage = getFromStorage(SHARED_SETTINGS_KEY);

  return !!(_.get(sharedStorage, 'sub_systems.esg.enabled'));
};

export const getInvestorProfile = (serviceConcept, getPrevStepAnswer) => {
  let investorProfile = {
    knowledge: {},
    experience: {},
  };

  if (![SERVICE_CONCEPTS.Anlageberatung, SERVICE_CONCEPTS.Anlagevermittlung].includes(serviceConcept)) return investorProfile;

  let questionId = serviceConcept === SERVICE_CONCEPTS.Anlagevermittlung ? 'K1' : 'A2';

  const offeneInvestmentFunds = getPrevStepAnswer('risk_profile', questionId,  'H3', false, true);

  let knowledgeExperienceAnswers = {
    mixed_funds: offeneInvestmentFunds, // Offene Investmentfonds
    equity_funds: getPrevStepAnswer('risk_profile', questionId,  'H4', false, true), // Aktien
    real_estate_funds: offeneInvestmentFunds, // Offene Investmentfonds
    bond_funds: getPrevStepAnswer('risk_profile', questionId,  'H2', false, true), // Schuldverschreibungen
    money_market_funds: getPrevStepAnswer('risk_profile', questionId,  'H1', false, true), // Geldmarktinstrumente
  };

  let totalExperienceInYears = 0;
  let totalTransactionsAmount = 0;

  const KNOWLEDGE_MAPPING = {
    [radioYesUID]: 'AVAILABLE',
    [radioNoUID]: 'NO_KNOWLEDGE',
  };

  const getLastNumberFromString = (stringToCheck) => {
    let lastNumberInStringRegex = new RegExp('(\\d+)(?!.*\\d)');
    return stringToCheck && parseInt(stringToCheck.match(lastNumberInStringRegex)) || 0;
  };

  for (const[key, value] of Object.entries(knowledgeExperienceAnswers)){
    let knowledge = value.answer.radio;
    investorProfile.knowledge[key] = KNOWLEDGE_MAPPING[knowledge];
    if(knowledge === radioYesUID){
      // answer.period_select contains uid. use it to get text and extract years from it
      let experienceInYears = getLastNumberFromString(_.get(
        _.find(value.config.choices, o => o.uid == value.answer.period_select), 'text', '')
      );

      // experience in years is 0 if no experience is selected
      if (experienceInYears === 0) continue;

      investorProfile.experience[key] = 'LESS_THAN_5';

      // real_estate_funds duplicates mixed_funds -> so ignore it for totals
      if (key !== 'real_estate_funds') {
        // order_select contains string, extract amount from it
        let transactionsAmount = getLastNumberFromString(value.answer.order_select);

        totalExperienceInYears += experienceInYears;
        totalTransactionsAmount += transactionsAmount
      }
    }
  }

  if(totalExperienceInYears === 0){
    investorProfile.number_of_trades = '0'
  } else if (totalExperienceInYears <= 5) {
    investorProfile.number_of_trades = 'UP_TO_5'
  } else if (totalExperienceInYears <= 10) {
    investorProfile.number_of_trades = 'UP_TO_10'
  } else {
    investorProfile.number_of_trades = 'OVER_10'
  }

  if(totalTransactionsAmount === 0){
    investorProfile.total_volume_of_transactions = '0'
  } else if (totalTransactionsAmount <= 5000 ) {
    investorProfile.total_volume_of_transactions = 'UP_TO_5000'
  } else if(totalTransactionsAmount <= 25000) {
    investorProfile.total_volume_of_transactions = 'UP_TO_25000'
  } else {
    investorProfile.total_volume_of_transactions = 'OVER_25000'
  }

  return investorProfile;
};

/**
 * Build object with values, that should be used for target market filtering.
 *
 * @returns {Object} Target market filters data
 */
export const getTargetMarketAnswers = (serviceConcept, getPrevStepAnswer) => {

  const _getKnowledgeExperienceAnswer = (answer) => {

    // radio = No
    if (!answer || answer.uid === radioSelectNoKnowledgeValue) {
      return undefined
    }

    return answer.text

  };

  let targetMarket = {
    investorType: 'retail', // we are advising retail clients for the moment,
    serviceConcept: serviceConcept
  };

  if (serviceConcept == SERVICE_CONCEPTS.Anlagevermittlung) {
    targetMarket.investmentFunds = _getKnowledgeExperienceAnswer(getPrevStepAnswer('risk_profile', 'K1',  'H3', true))
  } else if (serviceConcept == SERVICE_CONCEPTS.Anlageberatung) {
    const investmentPeriod = getPrevStepAnswer('risk_profile', 'A9', 'P1', true);

    targetMarket.investmentFunds = _getKnowledgeExperienceAnswer(getPrevStepAnswer('risk_profile', 'A2',  'H3', true));
    targetMarket.investmentPeriod = _.get(investmentPeriod, 'text');
  }

  return targetMarket

};

/**
 * Extend assets with data attribute, as trading functionality used it
 */
export const extendAssetsWithDataAttribute = (assets) => {
  return (assets || []).map((asset) => {

    if (asset.hasOwnProperty('data')) {
      return asset
    }

    const _data = _.cloneDeep(asset);
    _.set(_data, 'data', _data);
    return _data
  })
};

export const instrumentsSourceSrriValidationRequired = (instrumentSource) => instrumentSource && !instrumentSource.isInvestmentStrategy;

export const getVatCoef = () => {
  return 1 + _.get(getFromStorage(SHARED_SETTINGS_KEY), 'reference_values.vat', 0) / 100;
};

export const countryCodeToISO = (code) => {
  return Object.keys(CountryISOMapping).find((iso) => CountryISOMapping[iso] == code)
};

export const PORTFOLIO_BUILDER_NO_ASSETS_MSG = 'Die vom Portfolio-Builder vorgeschlagenen Instrumente können ' +
  'nicht geordert werden, da sie nicht der Risikoklasse des Kunden entsprechen oder bei der gewählten ' +
  'Depotbank nicht verfügbar sind.';

export const isNewDesignProcess = (onboarding) => _.has(onboarding, 'onboarding_answers.new_design_dummy_step');

export const showSkipRequiredWarning = async (modalContext, sessionId) => {
  const skipStorageKey = `advisory_skip_required_configuration`;
  const skipSkipRequiredWarningConfiguration = getFromStorage(skipStorageKey, STORAGE_TYPE.LOCAL) || {};

  if(!skipSkipRequiredWarningConfiguration[sessionId]) {
    const msg = <>
      <p>Es fehlen noch Pflichtangaben, um den Prozess erfolgreich abzuschließen. Diese sind mit einem gelben
        Icon <WarningIcon /> markiert. Bitte vervollständigen Sie diese Angaben später.
      </p>
      <p>Wenn Sie die Meldung für diese Beratung nicht mehr sehen möchten, klicken Sie auf „Nicht mehr anzeigen“.</p>
    </>;

    // Reject = Do not show again
    skipSkipRequiredWarningConfiguration[sessionId] = !await modalContext.confirm(
      msg, 'Schließen & weiter', 'Nicht mehr anzeigen', 'Pflichtangaben fehlen');

    setInStorage(skipStorageKey, skipSkipRequiredWarningConfiguration)
  }

  return true; // always return true as warn was processed
};