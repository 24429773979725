import React from "react";
import _ from "lodash";
import clsx from "clsx";

import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

import PrimaryButton from "../../../../../../../components/Buttons/PrimaryButton";
import ConfirmModal from "../../../../../../../components/ConfirmModal";
import SuccessDialog from "../../../../../../../components/SuccessDialog";
import StepFooter from "../../StepFooter/StepFooter";
import {
  ASSET_DATA_UID,
  ASSETS_STEP_STATE,
  LIABILITY_DATA_UID,
  SAVE_AND_CONTINUE_BTN_LABEL
} from "../../../../../constants";
import MainStep from "./components/MainStep";
import ListStep from "./components/ListStep";
import FormStep from "./components/FormStep";
import {AssetsQuestionMethods} from "../../question/AssetsQuestion/AssetsQuestion";
import BaseDialog from "../../../../../../../components/BaseDialog/BaseDialog";
import {getUID} from "../../../../../utils";

import styles from "../styles";

const AssetsV2Step = props => {
  const {
    classes,
    uid,
    name,
    questions,
    help_text,
    onNextClick,
    onPrevClick,
    onAnswerChange,
    extraRightButtons,
    dataService,
  } = props;

  const [step, setStep] = React.useState(null);
  const [activeQuestion, setActiveQuestion] = React.useState(null);
  const [activeAsset, setActiveAsset] = React.useState(null);
  const [itemToDelete, setItemToDelete] = React.useState(null);

  const handleAddClick = (question) => {
    setStep(ASSETS_STEP_STATE.LIST);
    setActiveQuestion(question);
  };

  const handleDeleteClick = (question, item, index) => {
    setActiveQuestion(question);
    setItemToDelete({...item, index: index});
  };

  const onClickConfirmDeleteAsset = () => {
    const answer = activeQuestion.answer || [];
    _.pullAt(answer, itemToDelete.index);
    onAnswerChange(activeQuestion.uid, answer);

    setItemToDelete({...itemToDelete, deleted: true});
  };

  const onCloseDeleteAsset = () => {
    setItemToDelete(null);
    setActiveQuestion(null);
  };

  const handleEditClick = (question, asset, index) => {
    setStep(ASSETS_STEP_STATE.EDIT_FORM);
    setActiveQuestion(question);
    setActiveAsset({...asset, index: index});
  };

  const handleAssetClick = (asset) => {
    setStep(ASSETS_STEP_STATE.ADD_FORM);
    setActiveAsset(asset);
  };

  const handleSaveClick = () => {
    const answer = activeQuestion.answer || [];
    if(step === ASSETS_STEP_STATE.EDIT_FORM) {
      answer[activeAsset.index] = activeAsset;
      onAnswerChange(activeQuestion.uid, answer);
    } else {
      onAnswerChange(activeQuestion.uid, [...answer, activeAsset]);
    }

    handleDialogClose();
  };

  const isFormStep = () => {
    return [ASSETS_STEP_STATE.ADD_FORM, ASSETS_STEP_STATE.EDIT_FORM].includes(step);
  };

  const handleBackClick = () => {
    if (step === ASSETS_STEP_STATE.ADD_FORM) {
      return setStep(ASSETS_STEP_STATE.LIST);
    }

    handleDialogClose();
  };

  const handleActiveAssetInfoChange = (info) => {
    setActiveAsset({...activeAsset, info});
  };

  const handleActiveAssetValueChange = (value) => {
    setActiveAsset({...activeAsset, value});
  };

  const getDialogTitle = () => {
    const assetType = activeQuestion && {
      [ASSET_DATA_UID]: 'Vermögenswert',
      [LIABILITY_DATA_UID]: 'Verbindlichkeit'
    }[getUID(activeQuestion.uid)];

    return `${assetType} ${step === ASSETS_STEP_STATE.EDIT_FORM ? 'aktualisieren' : 'hinzufügen'}`
  };

  const handleDialogClose = () => {
    setStep(null);
    setActiveQuestion(null);
    setActiveAsset(null);
  };

  return (
    <div className={classes.stepContainer}>
      <div className={classes.stepScrollContainer}>
        <div className={clsx(classes.stepContent , 'app-page-container')}>
          <MainStep
            help_text={help_text}
            uid={uid}
            name={name}
            questions={questions}
            onAddClick={handleAddClick}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            onAnswerChange={onAnswerChange}
            dataService={dataService}
          />
          <BaseDialog
            open={!!step}
            title={getDialogTitle()}
            handleClose={handleDialogClose}
            actions={isFormStep() && (
              <Grid container justify={"space-between"}>
                <Grid item>
                  <PrimaryButton
                    text={"Zurück"}
                    icon={<i className={'far fa-long-arrow-left'}/>}
                    variant={"text"}
                    onButtonClick={handleBackClick}
                  />
                </Grid>
                <Grid item>
                  <PrimaryButton
                    text={step === ASSETS_STEP_STATE.EDIT_FORM ? 'Speichern' : 'Speichern & hinzufügen'}
                    onButtonClick={handleSaveClick}
                    disabled={!AssetsQuestionMethods.validAnswer(activeQuestion, activeAsset)}
                  />
                </Grid>
              </Grid>
            )}
          >
            {step === ASSETS_STEP_STATE.LIST && (
              <ListStep
                activeType={activeQuestion.uid}
                onAssetClick={handleAssetClick}
              />
            )}
            {isFormStep() && (
              <FormStep
                activeAsset={activeAsset}
                onInfoChange={handleActiveAssetInfoChange}
                onValueChange={handleActiveAssetValueChange}
                infoRequired={activeQuestion.config.answer_format_config[1].required}
                valueRequired={activeQuestion.config.answer_format_config[2].required}
              />
            )}
          </BaseDialog>
        </div>
        <div className={classes.scrollFix} />
      </div>

      <StepFooter
        rightItems={[
          ...extraRightButtons,
          <PrimaryButton
            text={SAVE_AND_CONTINUE_BTN_LABEL}
            onButtonClick={onNextClick}
          />
          ]}
      />
      {itemToDelete &&
        <>
          <ConfirmModal
            open={!itemToDelete.deleted}
            handleClose={onCloseDeleteAsset}
            handleConfirm={onClickConfirmDeleteAsset}
            title={
              <span style={{paddingRight: 150}}>
                <i className={'fa fa-exclamation-circle'}
                   style={{color: '#f50702', verticalAlign: 'middle', marginRight: 10, fontSize: 36}} aria-hidden="true" />
                Möchten Sie <b>“{itemToDelete.info}”</b> löschen?
              </span>
            }
            cancelBtnText={'Abbrechen'}
            confirmBtnText={'Löschen'}
          />
          <SuccessDialog
            open={!!itemToDelete.deleted}
            message={
              <p style={{margin: '1em 10em 1em 0', fontSize: 16}}>
                <b>“{itemToDelete.info}”</b> ist gelöscht.
              </p>
            }
            onClose={onCloseDeleteAsset}
          />
        </>
      }
    </div>
  );
};

AssetsV2Step.defaultProps = {
  extraRightButtons: []
};

export default withStyles(styles)(AssetsV2Step);
