import React from "react";

import RadioFormElement from "../../formElement/RadioFormElement";
import {
  isRequiredQuestion,
  isValidValue,
  replacePlaceholdersWithComponents, requiredCheck
} from "../../../../../utils";
import { FIELD_REQUIRED_MSG } from "../../../../../constants";
import useStyles from "./styles";

function isRadioQuestionValid(question, step, afterChange, service) {
  if (isRequiredQuestion(question)) {
    const isValid = isValidValue(question.answer);
    question.error = isValid ? null : FIELD_REQUIRED_MSG;

    return isValid;
  }
  return true;
}

export const RadioQuestionMethods = {
  required: isRequiredQuestion,
  valid: isRadioQuestionValid
}

const RadioQuestion = props => {
  const {
    question,
    onAnswerChange,
    replaceComponents
  } = props;
  const classes = useStyles();

  const label = question.question_text;
  const infoText = question.isDisplayInfo && question.isDisplayInfo() && (question.info_text || '');
  const required = RadioQuestionMethods.required(question) || requiredCheck(question);
  const options = (question.config.choices || []).map(item => ({
    id: item.id,
    value: item.uid,
    info_text: item.info_text,
    label: replaceComponents ? replacePlaceholdersWithComponents(item.text, replaceComponents) : item.text,
    disabled: item.disabled
  }));

  const handleChange = (value) => {
    question.error = null;  // clean error
    onAnswerChange(question.uid, value);
  };

  return (
    <>
      <RadioFormElement
        label={label}
        value={question.answer || ''}
        required={required}
        options={options}
        onChange={handleChange}
        custom_classes={question.custom_classes || {}}
        error={question.error}
        row={question.row}
      />
      {infoText && (
        <div className={classes.infoText}>
          <span className="fa fa-info-circle" />
          <p>{infoText}</p>
        </div>
      )}
    </>
  );
};

export default RadioQuestion;