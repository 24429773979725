import React from "react";
import HighChartBase from "../Base";
import _ from "lodash";
import {toGermanFormat} from "../../../utils/numberFormater";


export const DEFAULT_POINT_FORMAT = '<div style="font-size: 12px; z-index: 50; background-color: white">' +
  'Rendite: <b>{point.y:.2f}%</b><br />' +
  'Volatilität: <b>{point.x:.2f}%</b>';

export const RiskReturnChart = (props) => {

  const {
    series,
    pointFormat,
    pointFormatPF,
    mirrorYAxis,
    withGridLine
  } = props;

  const classes = props.classes || {};

  const legendOptions = props.legendOptions || {enabled: false};
  const tooltipOptions = props.tooltipOptions || {};
  const chartOptions = props.chartOptions || {};
  const axisOptions = {
    labels: {
      formatter: function() {
        return toGermanFormat(this.value, '', ' %', 2, true);
      }
    },
  };

  const yAxis = {
    ...axisOptions,
    minTickInterval: 0.5,
    title: {
      text: "Rendite (annualisiert)",
      style: {
        fontFamily: 'Roboto-Bold'
      },
      rotation: -90
    }
  }

  const yAxisConfiguration = !mirrorYAxis ? yAxis : [
    {...yAxis, opposite: false},
    {...yAxis, opposite: true, linkedTo: 0, title: {enabled: false}}
  ]

  return (
    <div className={classes.chartContainer}>
      <HighChartBase
        options={{
          boost: {
            enabled: false,
          },
          chart: {
            marginLeft: 70,
            type: 'scatter',
            zoomType: 'xy',
            height: 380,
            style: {
              fontFamily: "\"Roboto-Regular\", \"Lucida Sans Unicode\", Verdana, Arial, Helvetica, sans-serif",
              fontSize: 14
            },
            ...chartOptions,
          },
          legend: {
            ...legendOptions
          },
          credits: {
            enabled: false
          },
          title: {
            text: ''
          },
          plotOptions: {
            scatter: {
              marker: {
                radius: 8,
                lineWidth: 3,
                symbol: 'circle',
                width: 20,
                lineColor: 'rgba(0, 0, 0, 0.25)'
              }
            },
            series: {
              point: {
                events: {
                  click: function(e){
                    let onTooltipClick = _.get(e.point.series, 'data.0.onTooltipClick');
                    onTooltipClick && onTooltipClick();
                  }
                }
              },
              cursor: 'pointer',
            }
          },
          xAxis: {
            ...axisOptions,
            minTickInterval: 0.01,
            title: {
              text: "Volatilität (annualisiert)",
              style: {
                fontFamily: 'Roboto-Bold'
              },
            },
            gridLineWidth: withGridLine ? 1 : 0,
          },
          yAxis: yAxisConfiguration,
          tooltip: {
            pointFormat: pointFormat || DEFAULT_POINT_FORMAT,
            pointFormatPF: pointFormatPF || DEFAULT_POINT_FORMAT,

            pointFormatter: function() {
              let point = this;

              return point.tooltipFormatter(
                // portfolio has weight undefined
                _.get(point.series.tooltipOptions, point.weight === undefined ? 'pointFormatPF' : 'pointFormat')
              )
            },
            valueDecimals: 2,
            split: false,
            shared: false,
            backgroundColor: 'white',
            shadow: {
              color: '#00000026',
              offsetX: 0,
              offsetY: 0,
              width: 10,
              opacity: 0.15
            },
            padding: 20,
            useHTML: true,
            shape: 'callout',
            borderWidth: 0,
            borderRadius: 4,
            headerFormat: '<span style="font-size: 14px; font-family: Roboto-Bold">{series.name}</span><br/><br/>',
            dateTimeLabelFormats: {
              day: "%b %e, %Y"
            },
            style: {
              fontFamily: '"Roboto-Regular"',
            },
            ...tooltipOptions,
          },
          series: series
        }}
        language={'DE'}
        className={classes.chart}
      />
    </div>
  )
}