import React from 'react';
import _ from "lodash";

import Grid from "@material-ui/core/Grid";
import ChartSectionBordered, {ChartSectionPortfolioName} from "../ChartSectionBordered/ChartSectionBordered";
import {useCorrelationMatrixData} from "../../hooks/useCorrelationMatrixData";
import CorrelationMatrix from "../charts/CorrelationMatrix";
import {useBasicPortfolioData} from "../../hooks/useBasicPortfolioData";
import {useExpandedItems} from "../../hooks/useExpandedItems";

const PortfolioCorrelationMatrixChart = React.memo(({customerId, portfolio, dataProvider, expanded, onExpandedItemsChange, startDate, endDate}) => {
  const correlation = useCorrelationMatrixData(customerId, portfolio, dataProvider, startDate, endDate);
  const {
    basicPortfolioData,
  } = useBasicPortfolioData(customerId, portfolio, dataProvider);

  return (
    <Grid item xs={12}>
      <ChartSectionBordered
        dataId={`risk-analyse-correlation-matrix-${portfolio.depotNumber}`}
        title={(
          <ChartSectionPortfolioName portfolio={portfolio} />
        )}
        loading={basicPortfolioData.basicPortfolioLoading || correlation.correlationLoading}
        error={basicPortfolioData.basicPortfolioError || correlation.correlationError}
        displayError
        expanded={expanded}
        isPortfolioSection
        borderLeftColor={portfolio.color}
        onExpanded={(newState) => onExpandedItemsChange(portfolio, newState)}
        content={(
          <CorrelationMatrix
            portfolioData={basicPortfolioData.basicPortfolioData}
            portfolioLoading={basicPortfolioData.basicPortfolioLoading}
            portfolioError={basicPortfolioData.basicPortfolioError}
            data={correlation.correlationData}
            loading={correlation.correlationLoading}
            error={correlation.correlationError}
            title={'Korrelationsmatrix auf Basis der Performance der letzen drei Jahre '}
            expanded={true}
          />
        )}
      />
    </Grid>
  )
}, (prevProps, nextProps) => {
  return prevProps.customerId == nextProps.customerId
    && _.get(prevProps.portfolio, 'depotNumber') == _.get(nextProps.portfolio, 'depotNumber')
    && prevProps.expanded == nextProps.expanded
    && prevProps.startDate == nextProps.startDate
    && prevProps.endDate == nextProps.endDate
});

function CorrelationMatrixChart({portfolios, customerId, dataProvider, onChartSettingsChange, expandedItems, onExpandedItemsChange, startDate, endDate}) {

  const [isPtfExpanded, handleExpandedChanged] = useExpandedItems(expandedItems, onExpandedItemsChange, 'correlationMatrixItems');

  return (
    <Grid container spacing={2}>
      {portfolios.map((portfolio) => (
        <PortfolioCorrelationMatrixChart
          key={portfolio.depotNumber}
          portfolio={portfolio}
          customerId={customerId}
          dataProvider={dataProvider}
          onChartSettingsChange={onChartSettingsChange}
          expanded={isPtfExpanded(portfolio)}
          onExpandedItemsChange={handleExpandedChanged}
          startDate={startDate}
          endDate={endDate}
        />
      ))}
    </Grid>
  )
}

CorrelationMatrixChart.propTypes = {}

CorrelationMatrixChart.propDefs = {}

CorrelationMatrixChart.defaultProps = {}

export default CorrelationMatrixChart;