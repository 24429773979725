import React from "react";
import { withStyles } from "@material-ui/core";

import CommonAnalysisCard from "../../../../../VirtualPortfolioPage/components/AnalysisCard";
import EsgScore from "../EsgScore";
import { toShortGermanFormat } from "../../../../utils";
import styles from "./styles";
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../../../../../utils/constants";


class EsgScoreChart extends React.Component {

  constructor(props) {
    super(props);
  }

  renderValue(value) {
    return value === null || value === undefined ? 'k.A.' : toShortGermanFormat(value, '', '', 2, false)
  }

  renderData() {
    const { classes, assetInfo } = this.props;
    return (
      <div className={classes.bottomPart}>
          <div className={classes.part}>
              <EsgScore
                  score={assetInfo.esg_corporate_sustainability_score}
                  title={<>Historisches Morningstar Nachhaltigkeitsrating (Unternehmen)</>}
                  tooltip={<>Der historische Morningstar Nachhaltigkeits-Score ist ein gewichteter Durchschnitt der
                      letzten 12 Monate der Morningstar Portfolio Nachhaltigkeits-Scores. Es wird nach Risiken für
                      Unternehmen und Risiken für Staaten differenziert. Die Scores der beiden Unterrisiken werden
                      dann zu einem Gesamtscore zusammengeführt. Hierbei werden die Scores aus der jüngeren
                      Vergangenheit stärker gewichtet als ältere Scores des 12-Monats-Zeitraums. Basierend auf dem
                      Historischen Morningstar Nachhaltigkeits-Score werden den Fonds absolute Kategorien und
                      prozentuale Ränge innerhalb der Morningstar Global Categories zugewiesen. Das bedeutet, dass
                      durch den Ausweis absoluter Zahlen ein Fonds auch über Fondskategorien bzw. Vergleichsgruppen
                      hinweg vergleichbar wird.</>}
              />
          </div>
          <div className={classes.part}>
              <EsgScore
                  score={assetInfo.esg_sovereign_sustainability_score}
                  title={<>Historisches Morningstar Nachhaltigkeitsrating (Staaten)</>}
                  tooltip={<>Der historische Morningstar Nachhaltigkeits-Score ist ein gewichteter Durchschnitt der
                      letzten 12 Monate der Morningstar Portfolio Nachhaltigkeits-Scores. Es wird nach Risiken für
                      Unternehmen und Risiken für Staaten differenziert. Die Scores der beiden Unterrisiken werden
                      dann zu einem Gesamtscore zusammengeführt. Hierbei werden die Scores aus der jüngeren
                      Vergangenheit stärker gewichtet als ältere Scores des 12-Monats-Zeitraums. Basierend auf dem
                      Historischen Morningstar Nachhaltigkeits-Score werden den Fonds absolute Kategorien und
                      prozentuale Ränge innerhalb der Morningstar Global Categories zugewiesen. Das bedeutet, dass
                      durch den Ausweis absoluter Zahlen ein Fonds auch über Fondskategorien bzw. Vergleichsgruppen
                      hinweg vergleichbar wird.</>}
              />
          </div>
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return <CommonAnalysisCard
      classes={{
        card: classes.root
      }}
      title={<span>Nachhaltigkeitsanalyse</span>}
      content={
        <div className={classes.container}>
          {this.props.assetInfo ? this.renderData() : DEFAULT_EMPTY_SECTION_MESSAGE}
        </div>
      }
    />
  }
}

export default withStyles(styles)(EsgScoreChart)
