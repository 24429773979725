import React from "react";

import CombinedTradeStep from "../Trades/components/TradeStep/CombinedTradeStep";
import Create from "../Modelportfolios/Create/Create"
import connect from "react-redux/lib/connect/connect";
import _ from "lodash";
import {
  getDummyDataservice,
  validateTransactions
} from "../VirtualPortfoliosDashboard/utils";
import {prepareOrdersFromTransaction} from "../VirtualPortfolioManager/modals/VirtualOrderModal";
import {getDummyPortfolioTransactions} from "../Modelportfolios/utils";


export default connect()((props) => {
  const [portfoliosTransactions, setPortfoliosTransactions] = React.useState([]);
  React.useEffect(() => {
    setPortfoliosTransactions([getDummyPortfolioTransactions({isMP: true})])
  }, []);


  const updateTransactionsWithSelectedAssets = (selectedAssets) => {
    // when mp is filled with assets from other mp or factsheets convert them to transaction

    let updatedTransactions = _.cloneDeep(portfoliosTransactions);
    updatedTransactions[0].transactions.buy = selectedAssets.map(a => {
      return {
        data: {
          ...a,
          price_eur: a.calculated.last_price_value
        }
      }
    });

    setPortfoliosTransactions(updatedTransactions)
  };

  const getOrders = () => {
    return prepareOrdersFromTransaction(portfoliosTransactions)
  };

  return (
    <Create
      CombinedTradeStep={CombinedTradeStep}

      portfoliosTransactions={portfoliosTransactions}
      dataService={getDummyDataservice('test')}
      setPortfoliosTransactions={setPortfoliosTransactions}
      validateTransactions={validateTransactions}
      onAssetsUpdate={updateTransactionsWithSelectedAssets}
      getOrders={getOrders} // not implemented inside component to prevent cycle import

      {...props}
    />
  )
})