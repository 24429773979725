import {CustomerMenuItem} from "../CustomerMenuItem";
import {buildDataSteps} from "../../../RiskProfiling/components/servicesV2/mock_account_data";

export class CustomerAccountMenuItemV2 extends CustomerMenuItem {
  constructor(uid, name, customer_id, getPrevStepAnswer, validateAccountFunc) {
    super(uid, name, customer_id, getPrevStepAnswer, validateAccountFunc);
    this._validateAccountStep = undefined;
  }

  _buildSteps(customer){
    return buildDataSteps()
  }
}
