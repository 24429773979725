import ProtocolConversationDateAndPlace from "../components/ProtocolConversationDateAndPlace";

import React from "react";
import Grid from "@material-ui/core/Grid";

const DateAndPlaceStep = props => {
  const {
    onAnswerChange,
    questions: [
      interviewDate,
      interviewStartTime,
      interviewEndTime,
      interviewPlace,
      interviewPlaceBrokerInput,
      interviewPlaceCustomerInput,
      interviewPlaceAccountHolder1Input,
      interviewPlaceAccountHolder2Input,
    ],
    dataService
  } = props;

  return (
    <Grid container spacing={2}>
      <ProtocolConversationDateAndPlace
        interviewDate={interviewDate}
        interviewStartTime={interviewStartTime}
        interviewEndTime={interviewEndTime}
        onAnswerChange={onAnswerChange}
        dataService={dataService}
        interviewPlace={interviewPlace}
        interviewPlaceBrokerInput={interviewPlaceBrokerInput}
        interviewPlaceCustomerInput={interviewPlaceCustomerInput}
        interviewPlaceAccountHolder1Input={interviewPlaceAccountHolder1Input}
        interviewPlaceAccountHolder2Input={interviewPlaceAccountHolder2Input}
      />
    </Grid>
  )
};

export default DateAndPlaceStep;
