import React from 'react';

import { isExpertSectionVisible, isProSectionVisible, isSectionVisible } from '../../../utils/utils';
import {REPORT_TYPES} from "../../DashboardSettings/components/CustomersList/components/ReportType/constants";
import {Grid} from "@material-ui/core";
import CustomerThemeProvider from "../../../components/CustomerThemeProvider/CustomerThemeProvider";
import {HistoricalChart, PerformanceBarChart, PerformanceLineChart} from "../components/Widgets/components";
import {ChartSectionWrapper} from "../components_v2/ChartSectionBordered/ChartSectionBordered";
import WarningTooltip from "../../../components/WarningTooltip";
import clsx from "clsx";
import useStyles from './styles';
import {isCumulativeDisplayEnabled} from "../../CustomerDashboard/utils";
import PerformanceForecast from "../components_v2/PerformanceForecastChart/PerformanceForecast";
import {
  RANKING_PERFORMANCE_TABLE_INFO_TEXT
} from "../../VirtualPortfolioPage/Tabs/CommonAnalysis/items/RankingPerformanceTable";
import PeergroupRankingTable from "../components_v2/PeergroupRankingTable/PeergroupRankingTable";
import SinglePerformanceTable from "../components_v2/SinglePerformanceTable/SinglePerformanceTable";
import {getDataRetrievingParamsFromProps, SharedData} from "../utils";

function PerformanceTab(props) {

  const classes = useStyles();
  // FIXME: here customSettings are settings for PRO View
  const {customSettings, reportType, aggregatedPortfolioName} = props;

  const isCumulativeDisplaySwitchDisabled = !isCumulativeDisplayEnabled(props.selectedPortfolios);

  const handleOnExpandedChange = (expandedItem) => (value) => {
    props.onExpandedItemsChange(expandedItem, value)
  }

  const isExpertChartVisible = (chartName) => {
    return isExpertSectionVisible(chartName, reportType, customSettings);
  };

  const isProChartVisible = (chartName) => {
    return isProSectionVisible(chartName, reportType, customSettings);
  };

  const {
    customerId,
    portfolios,
    dates,
    dataProvider,
    withHistorical,
    benchmarks,
    investmentStrategyId,
  } = getDataRetrievingParamsFromProps(props);

  if(reportType == REPORT_TYPES.DEPOT_STATUS.value) return null;

  const historicalChartShown = isSectionVisible(customSettings, 'historicalChart', reportType);

  return (
    <Grid container spacing={2}>
      {historicalChartShown && (
        <Grid item xs={12} id="historical-chart">
          <Grid item xs={12}>
            <HistoricalChart
              customerId={customerId}
              startDate={dates.start}
              endDate={dates.end}
              dataProvider={dataProvider}
              withHistorical={withHistorical}
              portfolios={props.selectedPortfolios || []}
              expanded={props.expandedItems.historicalChart}
              onExpanded={handleOnExpandedChange("historicalChart")}
              onChartSettingsChange={props.onChartSettingsChange}
              showInvestmentCapital={props.chartsSettings.historical.withInvestiertesAmount}
              isCumulativeDisplaySwitchDisabled={isCumulativeDisplaySwitchDisabled}
              aggregateSelectedPortfolios={props.chartsSettings.historical.aggregateSelected}
              aggregatedPortfolioName={aggregatedPortfolioName}
              investmentStrategyId={investmentStrategyId}
            />
          </Grid>
        </Grid>
      )}
      {isExpertChartVisible('performanceLineChart') && (
        <Grid item xs={12} id="performance-chart">
          <CustomerThemeProvider>
            <PerformanceLineChart
              customerId={customerId}
              benchmarks={benchmarks}
              startDate={dates.start}
              endDate={dates.end}
              dataProvider={dataProvider}
              withHistorical={withHistorical}
              portfolios={props.selectedPortfolios || []}
              onChartSettingsChange={props.onChartSettingsChange}
              expanded={props.expandedItems.performanceLineChart}
              onExpanded={handleOnExpandedChange("performanceLineChart")}
              showBenchmark={props.chartsSettings.performance.withBenchmark}
              isCumulativeDisplaySwitchDisabled={isCumulativeDisplaySwitchDisabled}
              aggregateSelectedPortfolios={props.chartsSettings.performance.aggregateSelected}
              isVirtual={props.isVirtual}
              reportType={props.reportType}
              aggregatedPortfolioName={aggregatedPortfolioName}
              investmentStrategyId={investmentStrategyId}
              showPerformanceTable
            />
          </CustomerThemeProvider>
        </Grid>
      )}

      {(isSectionVisible(customSettings, 'performanceBarChart', reportType)) && (
        <Grid item xs={12} id="performance-bar-chart">
          <CustomerThemeProvider>
            <PerformanceBarChart
              customerId={customerId}
              startDate={dates.start}
              endDate={dates.end}
              dataProvider={dataProvider}
              withHistorical={props.chartsSettings.global.withHistoricalPortfolios}
              portfolios={props.selectedPortfolios || []}
              expanded={props.expandedItems.performanceBarChart}
              onExpanded={handleOnExpandedChange("performanceBarChart")}
              aggregatedPortfolioName={aggregatedPortfolioName}
              investmentStrategyId={investmentStrategyId}
              useSharedDataOnly={historicalChartShown}
            />
          </CustomerThemeProvider>
        </Grid>
      )}

      {isProChartVisible('forecastChart') && (
        <Grid item xs={12} id="performance-forecast-chart">
          <CustomerThemeProvider>
            <PerformanceForecast
              expanded={props.expandedItems.forecastChart}
              onExpanded={handleOnExpandedChange("forecastChart")}
              portfolios={props.selectedPortfolios}
              customerId={customerId}
              dataProvider={dataProvider}
              investmentStrategyId={investmentStrategyId}
            />
          </CustomerThemeProvider>
        </Grid>
      )}

      <SharedData>
        {isProChartVisible('singlePerformance') && (
          <Grid item xs={12}>
            <ChartSectionWrapper
              dataId={'performance-peergroup-ranking'}
              title={(
                <>
                  <b>Peergroup-Quintilsranking</b>
                  <WarningTooltip
                    title={RANKING_PERFORMANCE_TABLE_INFO_TEXT}
                    icon={<i className={clsx("far fa-info-circle", classes.chartSectionHeaderInfoIcon)}/>}
                    width={600}
                  />
                </>
              )}
              displayError
              expanded={props.expandedItems.singlePerformance}
              onExpanded={(newState) => props.onExpandedItemsChange('singlePerformance', newState)}
              content={(
                <PeergroupRankingTable
                  customerId={customerId}
                  portfolios={portfolios}
                  dataProvider={dataProvider}
                  onChartSettingsChange={props.onChartSettingsChange}
                  expandedItems={props.expandedItems}
                  onExpandedItemsChange={props.onExpandedItemsChange}
                />
              )}
            />
          </Grid>
        )}

        {isProChartVisible('performanceTable') && (
          <Grid item xs={12}>
            <ChartSectionWrapper
              dataId={'performance-performance-table'}
              title={(
                <>
                  <b>Einzelperformance der Depotpositionen</b>
                  <WarningTooltip
                    title={'Die folgende Tabelle zeigt die Performance der einzelnen im Depot enhaltenen Produkte, berechnet nach geldgewichteter Methode bzw. nach Modified Dietz, abhängig von der Haltedauer.'}
                    icon={<i className={clsx("far fa-info-circle", classes.chartSectionHeaderInfoIcon)}/>}
                    width={600}
                  />
                </>
              )}
              displayError
              expanded={props.expandedItems.performanceTable}
              onExpanded={(newState) => props.onExpandedItemsChange('performanceTable', newState)}
              content={(
                <SinglePerformanceTable
                  customerId={customerId}
                  portfolios={portfolios}
                  dataProvider={dataProvider}
                  onChartSettingsChange={props.onChartSettingsChange}
                  expandedItems={props.expandedItems}
                  onExpandedItemsChange={props.onExpandedItemsChange}
                />
              )}
            />
          </Grid>
        )}
      </SharedData>
    </Grid>
  )
}

PerformanceTab.propTypes = {};

PerformanceTab.propDefs = {};

PerformanceTab.defaultProps = {};

export default PerformanceTab;