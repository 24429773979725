import React from "react";
import clsx from "clsx";

import withStyles from "@material-ui/core/styles/withStyles";
import SvgIcon from "@material-ui/core/SvgIcon";

import styles from "./styles";
import {getAssetsListData} from "../../../../../../../utils";
import IconButton from "../../../../../../../../../components/Buttons/IconButton";
import {VISIBLE_ASSETS} from "../../../../../../../constants";
import PlusBackgroundIcon from "../../../../../../../../../images/PlusBackgroundIcon";
import MinusBackgroundIcon from "../../../../../../../../../images/MinusBackgroundIcon";

const ListStep = props => {
  const {
    classes,
    activeType,
    onAssetClick
  } = props;

  const [more, setMore] = React.useState(false);

  const {items} = getAssetsListData(activeType);

  const visibleItems = more ? items : items.slice(0, VISIBLE_ASSETS);

  const handleMoreClick = () => {
    setMore(true);
  };

  const handleLessClick = () => {
    setMore(false);
  };

  const moreButton = (
    <IconButton
      label={'Mehr sehen'}
      component={PlusBackgroundIcon}
      onClick={handleMoreClick}
      size={36}
    />
  );

  const lessButton = (
    <IconButton
      label={'Weniger sehen'}
      component={MinusBackgroundIcon}
      onClick={handleLessClick}
      size={36}
    />
  );

  return (
    <div className={classes.container}>
      <div className={clsx(classes.list)}>
        {visibleItems.map(item => (
          <div className={classes.item} key={item.id} onClick={() => onAssetClick(item)}>
            <div className={classes.itemIcon}>
              {item.icon && (
                <SvgIcon component={item.icon} />
              )}
            </div>
            <div className={classes.itemName}>{item.name}</div>
          </div>
        ))}
      </div>
      {items.length > VISIBLE_ASSETS && (
        <div className={classes.more}>
          {more ? lessButton : moreButton}
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(ListStep);