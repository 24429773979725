import React from "react";

import {isRequiredQuestion} from "../../../../../utils";
import RichTextEditor from "../../formElement/RichTextEditor";

const TextQuestion = props => {
  const {
    question,
    onAnswerChange
  } = props;

  return (
    <RichTextEditor
      uid={question.uid}
      label={question.question_text}
      value={question.answer || ''}
      onChange={(value) => {
        question.error = null; // clean error
        onAnswerChange(question.uid, value)
      }}
      rows={6}
      required={isRequiredQuestion(question)}
      customClasses={question.custom_classes}
      error={question.error}
    />
  )
};

export default TextQuestion;