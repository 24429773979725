import qs from 'qs';
import axios from 'axios';
import _ from 'lodash';

import { REQUEST_METHODS } from './constants';
import {getFromStorage, setInStorage, TOKEN_KEY} from './storage'
import {setAuthError} from "../containers/Authentication/Auth/actions";
import {store} from '../index';


axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';


export const axiosInstance = axios.create({
  baseURL: '/api/v1/',
  paramsSerializer: (params) => (
    qs.stringify(params, { arrayFormat: 'repeat' })
  ),
});

function parseArrayBufferError(error) {
  try {
    return JSON.parse(new TextDecoder().decode(error.response.data))
  } catch (e) {
    console.log('parseArrayBufferError', error);
    return {}
  }
}

axiosInstance.interceptors.response.use(response => response, error => {
  if (error.response && [401, 403].includes(error.response.status)) {
    store.dispatch(setAuthError(true));
  }

  if (error.request && error.request.responseType === 'arraybuffer' && error.response) {
    return Promise.reject({
      ...parseArrayBufferError(error),
      status: error.response.status,
      statusText: error.response.statusText
    });
  }

  return Promise.reject(error);
});

class Resource {
  constructor(resourceUrl, with_authorisation_token=true) {
    this.config = {};
    this.resourceUrl = resourceUrl;
    this.with_authorisation_token = with_authorisation_token
  }

  at(url, with_authorisation_token) {
    const newResourceUrl = `${this.resourceUrl}${url}`;
    return new Resource(newResourceUrl, _.isUndefined(with_authorisation_token) ? this.with_authorisation_token : with_authorisation_token);
  }

  get_request(method, params=undefined, data=undefined, cancelToken=undefined) {
    if (params)
      this.config.params = params;
    if (data)
      this.config.data = data;
    if (cancelToken)
      this.config.cancelToken = cancelToken;
    this.config.method = method;

    return this.request();
  }

  get(params) {
    return this.get_request(REQUEST_METHODS.GET, params);
  }

  post(data, cancelToken=undefined) {
    return this.get_request(REQUEST_METHODS.POST, undefined, data, cancelToken);
  }

  patch(data) {
    return this.get_request(REQUEST_METHODS.PATCH, undefined, data);
  }

  delete(params) {
    return this.get_request(REQUEST_METHODS.DELETE, params);
  }

  request() {
    this.config.url = this.resourceUrl;

    let token = getFromStorage(TOKEN_KEY);
    if (!_.isUndefined(token) && this.with_authorisation_token) {
      this.config.headers = {
        ...this.config.headers,
        'Authorization': `Bearer ${token}`
      }
    }

    return axiosInstance.request(this.config)
      .then((response) => {
        this.config = {};
        if (response.headers.refreshed_token) {
          setInStorage(TOKEN_KEY, response.headers.refreshed_token)
        }
        return response && response.data;
      })
      .catch((error) => {
        this.config = {};
        const e = error.response || error.message;
        console.error(e);
        throw e;
      });
  }
}


class PortfolioHandler extends Resource {
  constructor(url='') {
    if (url === '') {
      url = 'portfolio-handler/';
    }

    super(url)
  }

  deleteComparisonProducts(comparisonId, productIdentifiers) {
    return this.at(`customer/product-comparison/${comparisonId}/delete-products/`).delete({id: productIdentifiers});
  }

  getCustomerAppData(customerId, fetchSharedSettings=true, withInstruments=false, withCompanies=false, withTransactionTypes=false, withLastSRRI=false, withHistoricalPortfolios=undefined, withPortfoliosData=true) {
    const queryParams = {
      with_shared_settings: fetchSharedSettings,
      with_instruments: withInstruments,
      with_companies: withCompanies,
      with_transaction_types: withTransactionTypes,
      with_last_srri: withLastSRRI,
      with_portfolios_data: withPortfoliosData,
    }

    if (!_.isNil(withHistoricalPortfolios)){ queryParams.with_historical_portfolios = withHistoricalPortfolios }

    return this.at(`customer/${customerId}/customer-app-data/`).get(queryParams);
  };

  getPortfolioHandlerData(path, portfolios, startDate, endDate, withHistoricalPortfolios, benchmarks=undefined, assets=[], useAssetsApproach=false, investmentStrategyId=undefined, otherParams={}, cancelToken) {

    const excluded_depot_id = otherParams.excluded_depot_id;
    delete otherParams.excluded_depot_id;

    const queryParams = {
      depot_id: portfolios ?
        Array.isArray(portfolios) ? portfolios : [portfolios]
        : [],
      assets: assets,
      start_date: startDate,
      end_date: endDate,
      benchmarks: benchmarks && JSON.stringify(benchmarks),
      use_assets_approach: useAssetsApproach,
      with_historical_portfolios: withHistoricalPortfolios,
      ...otherParams
    };

    if (excluded_depot_id) {
      queryParams.excluded_depot_id = Array.isArray(excluded_depot_id) ? excluded_depot_id : [excluded_depot_id]
    }

    if (investmentStrategyId){ queryParams.investment_strategy_id = investmentStrategyId }

      return this.at(path).post(queryParams, cancelToken);
  }

  getPortfolioHandlerByDate(path, startDate, endDate, benchmarks, assets, investmentStrategyId, otherParams={}){
    const queryParams = {
      "start_date": startDate,
      "end_date": endDate,
      'benchmarks': benchmarks && JSON.stringify(benchmarks),
      "assets": assets,
      "investment_strategy_id": investmentStrategyId,
      ...otherParams
    };

    return this.at(path).post(queryParams);
  }

  getPortfolioData(customerId, portfolios, startDate, endDate, withHistoricalPortfolios, assets, investmentStrategyId, excluded_depot_id, with_other_assets, cancelToken) {

    return this.getPortfolioHandlerData(
      `customer/${customerId}/portfolio-data/`, portfolios, startDate, endDate,
      withHistoricalPortfolios, undefined, assets, undefined, investmentStrategyId, {
        excluded_depot_id,
        with_other_assets,
      }, cancelToken);

  };

  getCustomerESGProfileData(customerId, portfolios) {
    return this.at(`customer/${customerId}/esg-profile-data/`).get({depot_id: portfolios});
  }

  getCustomerDepots(customerId) {
    return this.at(`customer/${customerId}/depots/`).get();
  };

  getPortfolioBreakdownData(customerId, portfolios, startDate, endDate) {

    return this.getPortfolioHandlerData(`customer/${customerId}/portfolio-breakdown/`, portfolios, startDate, endDate);

  };

  getHistoricalData(
    customerId, portfolios, startDate, endDate, withHistoricalPortfolios, assets, useAssetsApproach,
    investmentStrategyId, with_time_series=false, with_year_performance=true,
    with_historical_time_series=true, with_invested_capital=true, inactive_assets, excluded_depot_id) {

    return this.getPortfolioHandlerData(
      `customer/${customerId}/historical-portfolio-data/`, portfolios, startDate, endDate,
      withHistoricalPortfolios, undefined, assets, useAssetsApproach, investmentStrategyId,
      {
        with_time_series, with_year_performance, with_historical_time_series,
        with_invested_capital, inactive_assets, excluded_depot_id});

  };

  getTimeWeightedReturnData(customerId, portfolios, startDate, endDate, withHistoricalPortfolios, benchmarks=undefined, investmentStrategyId=undefined, excluded_depot_id) {

    return this.getPortfolioHandlerData(`customer/${customerId}/time-weighted-return-data/`,
     portfolios, startDate, endDate, withHistoricalPortfolios, benchmarks, [], false, investmentStrategyId, {excluded_depot_id}
    );

  };

  getTransactionsData(customerId, portfolios, startDate, endDate, withHistoricalPortfolios, assets) {

    return this.getPortfolioHandlerData(`customer/${customerId}/transaction-data/`, portfolios, startDate, endDate, withHistoricalPortfolios, undefined, assets);

  };

  getRiskData(customerId, portfolios, startDate, endDate, withHistoricalPortfolios, benchmarks=undefined, assets, investmentStrategyId, excluded_depot_id) {

    return this.getPortfolioHandlerData(`customer/${customerId}/risk-data/`,
     portfolios, startDate, endDate, withHistoricalPortfolios, benchmarks, assets, undefined, investmentStrategyId, {excluded_depot_id});

  };

  getPortfolioRiskData(customerId, portfolioId, startDate, endDate) {
    return this.getPortfolioHandlerByDate(`customer/${customerId}/risk-data/portfolio/${portfolioId}/`, startDate, endDate);
  }

  getKeyIndicatorsData(customerId, portfolioId, start_date, end_date, benchmarks, assets, investmentStrategyId, portfolioIds) {

    return this.getPortfolioHandlerByDate(
      `customer/${customerId}/key-indicators-data/${portfolioId}/`,
      start_date, end_date, benchmarks, assets, investmentStrategyId, {portfolio_ids: portfolioIds});

  };

  getStressTestData(customerId, portfolios, startDate, endDate, benchmarks, assets, investmentStrategyId) {

    return this.getPortfolioHandlerData(
      `customer/${customerId}/stress-test/`, _.isArray(portfolios) ? portfolios : [portfolios],
      startDate, endDate, undefined,
      benchmarks, assets, undefined, investmentStrategyId);

  };

  getRollingVolatilityData(customerId, portfolioId, startDate, endDate, benchmarks, assets, investmentStrategyId) {

    let _portfolioId = portfolioId;
    let otherParams = {};
    if (_.isArray(portfolioId)) {
      _portfolioId = 0;
      otherParams.portfolio_ids = portfolioId;
    }

    return this.getPortfolioHandlerByDate(`customer/${customerId}/rolling-volatility-data/${_portfolioId}/`, startDate, endDate, benchmarks, assets, investmentStrategyId, otherParams);

  };

  getEsgScoreData(customerId, portfolioId, withHistoricalPortfolios, assets, investmentStrategyId) {

    let _portfolioId = portfolioId;
    let otherParams = {
      with_historical_portfolios: withHistoricalPortfolios,
      assets,
      investment_strategy_id: investmentStrategyId
    };
    if (_.isArray(portfolioId)) {
      _portfolioId = 0;
      otherParams.portfolio_ids = portfolioId;
    }

    return this.at(`customer/${customerId}/esg-score-data/${_portfolioId}/`).post(otherParams);

  };

  getRollingSharpeData(customerId, portfolioId, startDate, endDate, assets, investmentStrategyId) {

    let _portfolioId = portfolioId;
    let otherParams = {};
    if (_.isArray(portfolioId)) {
      _portfolioId = 0;
      otherParams.portfolio_ids = portfolioId;
    }

    return this.getPortfolioHandlerByDate(`customer/${customerId}/rolling-sharpe-data/${_portfolioId}/`, startDate, endDate, undefined, assets, investmentStrategyId, otherParams);

  };

  getRollingSharpeBMData(customerId, portfolioId, startDate, endDate, benchmarks, investmentStrategyId, otherParams) {

     return this.getPortfolioHandlerByDate(`customer/${customerId}/rolling-sharpe-bm-data/${portfolioId}/`, startDate, endDate, benchmarks, undefined, investmentStrategyId, otherParams);

  };

  getDepotFinancialData(customerId, portfolios, startDate, endDate, withHistoricalPortfolios, assets, investmentStrategyId, excluded_depot_id, withOtherAssets) {

    return this.getPortfolioHandlerData(
      `customer/${customerId}/portfolio-financial-data/`, portfolios, startDate, endDate,
      withHistoricalPortfolios, undefined, assets, undefined, investmentStrategyId, {
        excluded_depot_id,
        with_other_assets: withOtherAssets
      });

  };

  getBasicPortfolioData(customerId, portfolioId, skipEmpty=true, startDate=undefined, endDate=undefined, splitBySoldBeforePeriodComponents) {

    return this.at(`customer/${customerId}/basic-portfolio-data/${portfolioId}/`).get({
      skip_empty: skipEmpty,
      start_date: startDate,
      end_date: endDate,
      split_by_sold_before_period_components: splitBySoldBeforePeriodComponents
    }); //GET_BASIC_PORTFOLIO_DATA

  };

  getRiskReturn(customerId, portfolioId, startDate=undefined, endDate=undefined, assets) {
    const queryParams = {
      'start_date': startDate,
      'end_date': endDate,
      assets
    };

    return this.at(`customer/${customerId}/risk-return/${portfolioId}/`).post(queryParams); //GET_RISK_RETURN
  };

  getCorrelation(customerId, portfolioId, startDate=undefined, endDate=undefined, assets) {
    const queryParams = {
      'start_date': startDate,
      'end_date': endDate,
      assets
    };

    return this.at(`customer/${customerId}/correlation/${portfolioId}/`).post(queryParams);
  };

  getProductsComparisonCorrelationMatrix(products, startDate, endDate) {
    const body = {
      products,
      start_date: startDate,
      end_date: endDate
    }

    return this.at('customer/products-comparison/correlation-matrix/').post(body)
  }
  getPortfolioRiskReturn(customerId, portfolioId, investmentStrategyId, startDate, endDate, portfolio_ids) {

    return this.at(`customer/${customerId}/portfolio-risk-return/${portfolioId}/`).get({
      investment_strategy_id: investmentStrategyId,
      start_date: startDate,
      end_date: endDate,
      portfolio_ids
    });

  };

  getPortfolioPerformance(customerId, portfolioId, withHistoricalPortfolios, assets, investmentStrategyId) {

    let [portfolio_ids, urlPortfolioId] = [undefined, portfolioId];
    if (_.isArray(portfolioId)) {
      portfolio_ids = portfolioId;
      urlPortfolioId = undefined;
    }

    return this.at(`customer/${customerId}/performance-timeseries/${urlPortfolioId}/`).post({
      with_historical_portfolios: withHistoricalPortfolios,
      investment_strategy_id: investmentStrategyId,
      portfolio_ids,
      assets
    });
  };
  getPortfolioForecast(customerId, portfolioId, assets, investmentStrategyId) {

    let [queryParams, urlPortfolioId] = [{}, portfolioId];
    if (_.isArray(portfolioId)) {
      queryParams.portfolio_ids = portfolioId;
      urlPortfolioId = undefined;
    }

    return this.at(`customer/${customerId}/portfolio-forecast/${urlPortfolioId}/`)
      .post({assets, investment_strategy_id: investmentStrategyId, ...queryParams});
  };
  getPortfolioExtendedAssetData(customerId, portfolios, assets, investmentStrategyId) {
    return this.getPortfolioHandlerData(`customer/${customerId}/extended-asset-data/`, [portfolios], undefined, undefined, undefined, undefined, assets, false, investmentStrategyId);
  };

  postCreateProductsComparison(request) {
    return this.at('customer/products-comparison/new/').post(request);
  }
}

class FavoriteListHandler extends Resource {
  constructor() {
    super('favoritelists/');

    this.GET_FAVORITE_LISTS = 'list/'
    this.GET_FAVORITE_LIST_DETAILS = '<:id>/get/'
    this.GET_FAVORITE_LIST_PDF = ':id/pdf/'
    this.DELETE_FAVORITE_LIST = '<:id>/delete/'
    this.POST_FAVORITE_LIST_CEATE = 'create/'
    this.PATCH_FAVORITE_LIST_UPDATE = '<:id>/update/'
  }

  getFavoriteLists(params) {
    return this.at(this.GET_FAVORITE_LISTS).get(params);
  }

  getFavoriteListDetails(id) {
    return this.at(this.GET_FAVORITE_LIST_DETAILS.replace('<:id>', id)).get()
  }

  deleteFavoriteList(id) {
    return this.at(this.DELETE_FAVORITE_LIST.replace('<:id>', id)).delete()
  }

  createFavoriteList(data) {
    return this.at(this.POST_FAVORITE_LIST_CEATE).post(data)
  }

  updateFavoriteList(id, data) {
    return this.at(this.PATCH_FAVORITE_LIST_UPDATE.replace('<:id>', id)).post(data)
  }

}

class BaseVirtualPortfolioHandler extends PortfolioHandler {
  POST_ORDERS = 'management/:portfolio_owner_id/create-orders/';
  GET_ASSET_FOR_DATE = 'management/:customer_id/asset-for-date/:asset_id/';
  POST_DELETE_ORDER = 'customer/:portfolio_owner_id/asset/order/delete/';


  getInstrumentDataForDate(customerId, assetId, targetDate) {
    return this.at(this.GET_ASSET_FOR_DATE.replace(':customer_id', customerId).replace(':asset_id', assetId)).get({
      target_date: targetDate
    });
  }

  createOrders(customerId, requestBody) {
    return this.at(this.POST_ORDERS.replace(':portfolio_owner_id', customerId)).post(requestBody);
  }

  deleteOrder(customerId, transactions) {
    return this.at(this.POST_DELETE_ORDER
      .replace(':portfolio_owner_id', customerId))
      .post({transactions});
  }
}

class ModelPortfolioHandler extends BaseVirtualPortfolioHandler {
  constructor() {
    super('modelportfolios/');

    this.POST_ASSET_SEARCH_URL = 'asset/search/';
    this.POST_ASSET_INFO_URL = 'asset/info/';
    this.GET_MODEL_PORTFOLIOS_LIST = 'list/';
    this.POST_MODEL_PORTFOLIO_CEATE = 'create/';
    this.PATCH_MODEL_PORTFOLIO_UPDATE = '<:id>/update/';
    this.DELETE_MODEL_PORTFOLIO_UPDATE = '<:id>/delete/';
    this.GET_MODEL_PORTFOLIO_DETAILS = '<:id>/get/';
    this.GET_MODEL_PORTFOLIO_PDF = ':id/pdf/';
    this.POST_ASSETS_RISK_PARITY_WEIGHTS = 'asset/weights/risk-parity/';
    this.POST_ASSETS_CUSTODIANS_DETAILS_URL = 'asset/custodian-details/';
    this.POST_ORDERS = 'customer/:portfolio_owner_id/create-orders/';
    this.GET_ASSET_FOR_DATE = 'customer/:customer_id/asset-for-date/:asset_id/';
    this.POST_UPDATE_ORDER = 'customer/:portfolio_owner_id/orders/update/';
    this.POST_DELETE_ASSET = 'customer/:portfolio_owner_id/delete-asset/:asset_id/';
  }

  searchAssets(filters, cancelToken) {
    return this.at(this.POST_ASSET_SEARCH_URL).post(filters, cancelToken)
  }

  infoAssets(isins) {
    if(_.isEmpty(isins)){
      return [];
    }

    return this.at(this.POST_ASSET_INFO_URL).post({
      isins
    })
  }

  assetsCustodiansDetails(isins, custodians) {
    return this.at(this.POST_ASSETS_CUSTODIANS_DETAILS_URL).post({
      isins: isins, c_company_id: custodians
    })
  }

  getModelPortfoliosList(params) {
    return this.at(this.GET_MODEL_PORTFOLIOS_LIST).get(params)
  }

  createModelPortfolio(data) {
    return this.at(this.POST_MODEL_PORTFOLIO_CEATE).post(data)
  }

  updateModelPortfolio(id, data) {
    return this.at(this.PATCH_MODEL_PORTFOLIO_UPDATE.replace('<:id>', id)).post(data)
  }

  updateOrders(id, orders) {
    return this.updateModelPortfolio(id, {orders})
  }

  updateOrder(portfolioOwnerId, orderData) {
    return this.at(this.POST_UPDATE_ORDER
      .replace(':portfolio_owner_id', portfolioOwnerId))
      .post(orderData)
  }

  deleteModelPortfolio(id) {
    return this.at(this.DELETE_MODEL_PORTFOLIO_UPDATE.replace('<:id>', id)).delete()
  }

  deleteInstrument(mpId, assetId) {
    return this.at(this.POST_DELETE_ASSET.replace(':portfolio_owner_id', mpId).replace(':asset_id', assetId)).delete();
  }

  getModelPortfolioDetails(id) {
    return this.at(this.GET_MODEL_PORTFOLIO_DETAILS.replace('<:id>', id)).get()
  }

  getRiskParityWeights(instruments) {
    return this.at(this.POST_ASSETS_RISK_PARITY_WEIGHTS).post(instruments)
  }
}

class ESignHandler extends Resource {
  constructor() {
    super('esign/');

    this.GET_BUSINESS_CASES = 'businesscases/'
    this.GET_BUSINESS_CASE_DETAILS = 'businesscases/:businesscase_id/'
    this.GET_ONBOARDING_BUSINESS_CASE_DETAILS = 'businesscases/onboarding/:businesscase_id/'
    this.GET_ONBOARDING_BUSINESS_CASES = 'businesscases/onboarding/:onboarding_id/list/'
    this.POST_PREPARE_EDITOR_LAUNCH_URL = 'businesscases/:businesscase_id/editor-url/'
    this.POST_SHARE_BUSINESS_CASE = 'businesscases/:businesscase_id/customer/:customer_id/share/'
    this.POST_UPLOAD_BUSINESS_CASE_DOCUMENTS = 'businesscases/:businesscase_id/upload-documents/'
    this.GET_APPROVALS = 'approvals/'
    this.POST_UPDATE_APPROVAL_STATUS = 'approvals/:approval_id/documents/:document_id/'
  }

  getBusinessCases(getParams={}) {
    return this.at(this.GET_BUSINESS_CASES).get(getParams)
  }

  getBusinessCaseDetails(businesscaseId) {
    return this.at(this.GET_BUSINESS_CASE_DETAILS.replace(':businesscase_id', businesscaseId)).get()
  }

  getOnboardingBusinessCaseDetails(businesscaseId) {
    return this.at(this.GET_ONBOARDING_BUSINESS_CASE_DETAILS.replace(':businesscase_id', businesscaseId)).get()
  }

  postUploadBusinessCaseDocuments(businesscaseId, body) {
    return this.at(
      this.POST_UPLOAD_BUSINESS_CASE_DOCUMENTS
        .replace(':businesscase_id', businesscaseId))
      .post(body)
  }

  getOnboardingBusinessCases(onboardingId) {
    return this.at(this.GET_ONBOARDING_BUSINESS_CASES.replace(':onboarding_id', onboardingId)).get()
  }

  shareBusinessCase(businessCaseId, customerId, userRole, email, mailText) {
    return this.at(
      this.POST_SHARE_BUSINESS_CASE
        .replace(':businesscase_id', businessCaseId)
        .replace(':customer_id', customerId)
    ).post({
      role: userRole,
      email: email,
      mail_text: mailText
    })
  }

  postUpdateApprovalDocument(approvalId, documentId, status, feedback) {
    return this.at(this.POST_UPDATE_APPROVAL_STATUS
      .replace(':approval_id', approvalId)
      .replace(':document_id', documentId)).post({
      status,
      feedback
    })
  }

  getBusinessCaseEditorLaunchUrl(businesscaseId, documentId, allowedSignatureFields) {
    return this.at(this.POST_PREPARE_EDITOR_LAUNCH_URL.replace(':businesscase_id', businesscaseId))
      .post({
        document_id: documentId,
        allowed_signature_fields: allowedSignatureFields
      })
  }
}

class BankSettingsHandler extends Resource {
  constructor() {
    super('bank_settings/');

    this.GET_BANKS_URL = 'bank/';
    this.GET_BANK_DETAILS_URL = 'bank/:bank_id/';

    this.MANDANT_DETAILS = 'mandant/details/'

    this.DEPOT_TYPE_INFO = 'depot-type/:depot_type_id/'

    this.POST_EX_ANTE_CALCULATION = 'ex-ante/'
  }

  getBanksData(params) {
    return this.at(this.GET_BANKS_URL).get(params);
  }

  getMandantDetails() {
    return this.at(this.MANDANT_DETAILS).get();
  }

  getDepotTypeInfo(depotTypeId, tradingType) {
    return this.at(this.DEPOT_TYPE_INFO.replace(':depot_type_id', depotTypeId)).get({trading_type: tradingType})
  }

  getBankDetails(bankId, useCode=false, tradingType) {
    return this.at(this.GET_BANK_DETAILS_URL.replace(':bank_id', bankId)).get({use_code: useCode, trading_type: tradingType})
  }

  getExAnteCalculation(data, trading=false) {

    let requestUrl = `${this.POST_EX_ANTE_CALCULATION}${trading ? '?trading=true' : ''}`
    return this.at(requestUrl).post(data)
  }

}

class FormCenterHandler extends Resource {
  constructor() {
    super('form-center/');

    this.GET_BANK_DOCUMENT_CONTENT = 'documents/:onboarding_id/:document_id/filled/'
    this.GET_ONBOARDING_DOCUMENTS = 'documents/:onboarding_id/onboarding/'
    this.GET_TRADING_DOCUMENTS = 'documents/:onboarding_id/trading/';

    this.GET_FOLDERS_URL = 'documents/folders/';
    this.GET_DOCUMENTS_CONTENT_URL = 'documents/:customer_id/content/';
    this.GET_DOCUMENT_FORM_FIELDS = 'documents/:customer_id/form_fields/'

    this.GET_DOCUMENT_COLLECTIONS = 'collections/:customer_id/';
    this.GET_LATEST_COLLECTIONS = 'collections/documents/open/latest/';
    this.POST_DOCUMENT_COLLECTIONS = 'collections/:customer_id/save/';
    this.GET_COLLECTION_DATA = 'collections/:collection_id/:customer_id/';
    this.GET_DOCUMENT_COLLECTION_CONTENT = 'collections/:collection_id/:customer_id/document/:document_id/'
    this.DELETE_DOCUMENT_COLLECTION_CONTENT = 'collections/:collection_id/:customer_id/delete/'
    this.SIGN_DOCUMENT_COLLECTION = 'collections/:collection_id/:customer_id/sign/'
  }

  getOnboardingDocuments(onboarding_id) {
    return this.at(this.GET_ONBOARDING_DOCUMENTS.replace(':onboarding_id', onboarding_id)).get()
  }

  getTradingDocuments(onboarding_id, depotId, objectType=undefined, withGeneralDocuments=true, generalDocumentsOnly=false) {
    return this
      .at(this.GET_TRADING_DOCUMENTS.replace(':onboarding_id', onboarding_id))
      .get({
        depot_id: depotId,
        object_type: objectType,
        with_general_documents: withGeneralDocuments,
        general_documents_only: generalDocumentsOnly
      })
  }

  getBankDocumentContent(documentId, onboarding_id, depot_id, objectType) {
    const config = {
      responseType: 'arraybuffer'
    };

    let token = getFromStorage(TOKEN_KEY);
    if (!_.isUndefined(token) && this.with_authorisation_token) {
      config.headers = {
        ...this.config.headers,
        'Authorization': `Bearer ${token}`
      }
    }

    return axiosInstance.get(`${this.resourceUrl}${this.GET_BANK_DOCUMENT_CONTENT
        .replace(':onboarding_id', onboarding_id)
        .replace(':document_id', documentId)}${depot_id ? '?depot_id='+depot_id.toString() : ''}${objectType ? '&object_type='+objectType.toString() : ''}`, config);
  }

  getFolders() {
    return this.at(this.GET_FOLDERS_URL).get();
  }

  getLatestCollections(customers_ids=[]) {
    return this.at(this.GET_LATEST_COLLECTIONS).post({customers_ids: customers_ids})
  }

  getDocumentsContent(documents, customer_id, params) {
    let documentsIdentifiers = documents.map(document => document.id);

    let getParams = {
      documents: documentsIdentifiers,
      ...params
    };

    const config = {
      responseType: 'arraybuffer',
      params: getParams
    };

    let token = getFromStorage(TOKEN_KEY);
    if (!_.isUndefined(token) && this.with_authorisation_token) {
      config.headers = {
        ...this.config.headers,
        'Authorization': `Bearer ${token}`
      }
    }

    return axiosInstance.get(`${this.resourceUrl}${this.GET_DOCUMENTS_CONTENT_URL.replace(':customer_id',customer_id)}`, config);
  }

  getDocumentsFormFields(documents, customer_id) {

    let path = this.GET_DOCUMENT_FORM_FIELDS
      .replace(':customer_id', customer_id)

    return this.at(path).get({'document': documents})
  }

  getDocumentsCollections(customerId) {
    let path = this.GET_DOCUMENT_COLLECTIONS.replace(':customer_id', customerId)

    return this.at(path).get()
  }

  getSingleCollectionData(customerId, collectionId) {
    let path = this.GET_COLLECTION_DATA
      .replace(':customer_id', customerId)
      .replace(':collection_id', collectionId)

    return this.at(path).get()
  }

  getCollectionContent(customerId, collectionId, documentId) {
    let path = this.GET_DOCUMENT_COLLECTION_CONTENT
      .replace(':customer_id', customerId)
      .replace(':collection_id', collectionId)
      .replace(':document_id', documentId)

      const config = {
        responseType: 'arraybuffer',
      };

      let token = getFromStorage(TOKEN_KEY);
      if (!_.isUndefined(token) && this.with_authorisation_token) {
        config.headers = {
          ...this.config.headers,
          'Authorization': `Bearer ${token}`
        }
      }

      return axiosInstance.get(`${this.resourceUrl}${path}`, config);
  }

  saveCollection(customerId, data) {
    return this.at(this.POST_DOCUMENT_COLLECTIONS.replace(':customer_id', customerId)).post(data)
  }

  deleteCollectionContent(customerId, collectionId) {
    let path = this.DELETE_DOCUMENT_COLLECTION_CONTENT
      .replace(':customer_id', customerId)
      .replace(':collection_id', collectionId)

    return this.at(path).delete()
  }

  signCollectionsDocument(customerId, collectionId){
    let path = this.SIGN_DOCUMENT_COLLECTION
      .replace(':customer_id', customerId)
      .replace(':collection_id', collectionId)
    return this.at(path).get()
  }
}

class VirtualPortfolioHandler extends BaseVirtualPortfolioHandler {
  GET_INSTRUMENT_EARLIEST = 'management/asset-timeseries-earliest/';
  GET_INSTRUMENT_PRICES = 'management/instrument-price/';
  POST_CREATE_PORTFOLIO = 'management/:customer_id/create/';
  POST_CREATE_ASSET = 'management/:customer_id/create-asset/';
  POST_DELETE_ASSET = 'management/:customer_id/delete-asset/:asset_id/';
  POST_DELETE_PORTFOLIO = 'management/:customer_id/delete-portfolio/:portfolio_id/';
  POST_BENCHMARK_SETTINGS = 'management/:customer_id/benchmark-save/';
  GET_BENCHMARK_SETTINGS = 'management/:customer_id/benchmark/';
  GET_BENCHMARK = 'management/benchmark/';
  GET_ASSET = 'management/:customer_id/asset/:asset_id/';
  PUT_ASSET = 'management/:customer_id/asset/:asset_id/edit/';
  PATCH_PORTFOLIO = 'management/:customer_id/portfolio/:portfolio_id/edit/';
  GET_RISK_RETURN = 'management/:customer_id/risk-return/:portfolio_id/';
  GET_BASIC_PORTFOLIO_DATA = 'customer/:customer_id/basic-portfolio-data/:portfolio_id/';
  GET_ESG_SCORE_DATA = 'management/:customer_id/esg-score/';

  constructor() {
    super('virtual-portfolio-handler/');
  }

  createPortfolio(customerId, portfolioName) {
    const requestBody = {
      'portfolio_name': portfolioName,
    };

    return this.at(this.POST_CREATE_PORTFOLIO.replace(':customer_id', customerId)).post(requestBody);
  }

  createInstrument(customerId, requestBody) {
    return this.at(this.POST_CREATE_ASSET.replace(':customer_id', customerId)).post(requestBody);
  }

  getInstrument(customerId, assetId) {
    return this.at(this.GET_ASSET.replace(':customer_id', customerId).replace(':asset_id', assetId)).get();
  }

  deleteInstrument(customerId, assetId) {
    return this.at(this.POST_DELETE_ASSET.replace(':customer_id', customerId).replace(':asset_id', assetId)).delete();
  }

  updateInstrument(customerId, assetId, requestBody) {
    return this.at(this.PUT_ASSET.replace(':customer_id', customerId).replace(':asset_id', assetId)).patch(requestBody);
  }

  updatePortfolio(customerId, portfolioId, requestBody) {
    return this.at(this.PATCH_PORTFOLIO.replace(':customer_id', customerId).replace(':portfolio_id', portfolioId)).patch(requestBody);
  }

  deletePortfolio(customerId, portfolioId) {
    return this.at(this.POST_DELETE_PORTFOLIO.replace(':customer_id', customerId).replace(':portfolio_id', portfolioId)).delete();
  }

  createBenchmarkSettings(customerId, requestBody) {
    return this.at(this.POST_BENCHMARK_SETTINGS.replace(':customer_id', customerId)).post(requestBody);
  }

  getBenchmarkSettings(customerId, portfolioId) {
    return this.at(this.GET_BENCHMARK_SETTINGS.replace(':customer_id', customerId)).get({
      portfolio_id: portfolioId
    })
  }

  getPortfolio(customerId, portfolioId) {
    return this.at(`customer/${customerId}/portfolio/${portfolioId}/`).get();
  }

  getBenchmarks() {
    return this.at(this.GET_BENCHMARK).get()
  }

  getInstrumentPrice(isin, targetDate, withoutDelta=false) {
    const queryParams = {
      isin: isin,
      target_date: targetDate,
      without_delta: withoutDelta
    };

    return this.at(this.GET_INSTRUMENT_PRICES).get(queryParams);
  }

  getInstrumentsEarliestPriceDate(isins) {
    const queryParams = {
      'isins': isins
    };

    return this.at(this.GET_INSTRUMENT_EARLIEST).get(queryParams);
  }

  getRiskReturn(customerId, portfolioId, startDate=undefined, endDate=undefined, assets) {
    const queryParams = {
      'start_date': startDate,
      'end_date': endDate,
      assets
    };

    return this.at(this.GET_RISK_RETURN.replace(':portfolio_id', portfolioId).replace(':customer_id', customerId))
      .post(queryParams);
  }

  getPortfolioRiskReturn(customerId, portfolioId) {

    return this.at(`customer/${customerId}/portfolio-risk-return/${portfolioId}/`).get();

  };

  getBasicPortfolioData(customerId, portfolioId, skipEmpty=true) {
    return this.at(this.GET_BASIC_PORTFOLIO_DATA
      .replace(':customer_id', customerId)
      .replace(':portfolio_id', portfolioId))
      .get({skip_empty: skipEmpty});
  }

  getEsgScoreData(customerId, portfolioId) {
    const queryParams = {
      'depot_id': portfolioId,
    };

    return this.at(this.GET_ESG_SCORE_DATA.replace(':customer_id', customerId)).get(queryParams);
  }

}

class FactSheetsHandler extends Resource {
  GET_DATA_ASSET_INFO = ':isin/data-v1-asset-info/';
  GET_ANALYSE_ASSET_INFO_V2 = ':isin/analyse-v2-asset-info/';
  GET_DATA_ASSET_SEARCH_RISK_RETURN = ':isin/data-v1-asset-search-risk-return/';
  GET_ANALYSE_ASSET_PERFORMANCE_TIMESERIES_RISK_RETURN = ':isin/data-v2-asset-performance-timeseries-risk-return/';
  GET_DATA_TIMESERIES = ':isin/data-v1-timeseries/';
  GET_DATA_TIMESERIES_EARLIEST = ':isin/data-v1-timeseries-earliest/';
  GET_ANALYSE_PERFORMANCE_TIMESERIES = ':isin/analyse-v1-performance-timeseries/';
  GET_ANALYSE_PERFORMANCE_TIMESERIES_V2_RETURN = ':isin/analyse-v2-performance-timeseries-return/';
  GET_ANALYSE_HISTORIC_STRESS_TEST = ':isin/analyse-historic-stress-test/'
  GET_ANALYSE_ALLOCATION = ':isin/analyse-v1-allocation/';
  GET_ANALYSE_PERFORMANCE_INDICATORS_V2 = ':isin/analyse-v2-performance/';
  GET_ANALYSE_RISK_METRICS_HISTORICAL = ':isin/analyse-v1-risk-metrics/';
  GET_ANALYSE_RISK_METRICS_ROLLING_V2 = ':isin/analyse-v2-risk-metrics-rolling/';
  GET_ANALYSE_PERFORMANCE_ROLLING_V2 = ':isin/analyse-v2-performance-rolling/';
  GET_ANALYSE_PERFORMANCE_BENCHMARK = ':isin/analyse-v1-performance-benchmark/'
  GET_DATA_DOCUMENTS = ':isin/data-v1-documents/'
  GET_PDF = ':isin/get-pdf/'
  POST_ADD_FAVLIST = ':isin/add-to-favlist/'
  POST_ADD_MUSTERDEPOT = ':isin/add-to-mp/'

  getDataV1AssetInfo(isin, isins=undefined) {
    return this.at(this.GET_DATA_ASSET_INFO.replace(':isin', isin)).get({isins});
  }

  getPerformanceData(isin, startDate, endDate, response_type, isins) {
    return this.at(this.GET_ANALYSE_PERFORMANCE_TIMESERIES.replace(':isin', isin)).get(
        {'start_date': startDate, 'end_date': endDate, 'response_type': response_type, 'isins': isins}
    );
  }

  getPerformanceBenchmarkData(isin, startDate, endDate) {
    return this.at(this.GET_ANALYSE_PERFORMANCE_BENCHMARK.replace(':isin', isin)).get(
        {'start_date': startDate, 'end_date': endDate}
    );
  }

  getTimeseriesEarliest(isin) {
    return this.at(this.GET_DATA_TIMESERIES_EARLIEST.replace(':isin', isin)).get();
  }

  getHistoricalData(isin) {
    return this.at(this.GET_DATA_TIMESERIES.replace(':isin', isin)).get()
  }

  getAllocationData(isin, with_sri, with_category, sort_by_weight) {
    return this.at(this.GET_ANALYSE_ALLOCATION.replace(':isin', isin)).get({with_sri, with_category, sort_by_weight});
  }

  getRiskReturnData(isin, category, isins) {
    return this.at(this.GET_DATA_ASSET_SEARCH_RISK_RETURN.replace(':isin', isin)).get(
        {'category': category, isins}
    );
  }

  getHistoricStressTestData(isin, isins) {
    return this.at(this.GET_ANALYSE_HISTORIC_STRESS_TEST.replace(':isin', isin)).get(
      {isins}
    );
  }

  getPerformanceTimeseriesRiskReturnData(isin, isins, startDate, endDate) {
    return this.at(this.GET_ANALYSE_ASSET_PERFORMANCE_TIMESERIES_RISK_RETURN.replace(':isin', isin)).get(
      {isins, start_date: startDate, end_date: endDate}
    );
  }

  getAnalyseV2AssetInfo(isin) {
    return this.at(this.GET_ANALYSE_ASSET_INFO_V2.replace(':isin', isin)).get();
  }

  getAnalyseV2PerformanceTimeseriesReturn(isin, startDate, endDate, isins) {
    return this.at(this.GET_ANALYSE_PERFORMANCE_TIMESERIES_V2_RETURN.replace(':isin', isin)).get(
      {'start_date': startDate, 'end_date': endDate, isins}
    );
  }

  getAnalyseV2PerformanceIndicatorsData(isin, startDate, endDate, isins, benchmarks) {
    return this.at(this.GET_ANALYSE_PERFORMANCE_INDICATORS_V2.replace(':isin', isin)).get(
        {'start_date': startDate, 'end_date': endDate, isins, benchmarks}
    );
  }

  getAnalyseRiskMetricsHistoricalData(isin, startDate, endDate, isins) {
    return this.at(this.GET_ANALYSE_RISK_METRICS_HISTORICAL.replace(':isin', isin)).get(
        {'start_date': startDate, 'end_date': endDate, isins}
    );
  }

  getAnalyseRiskMetricsRollingData(isin, startDate, endDate) {
    return this.at(this.GET_ANALYSE_RISK_METRICS_ROLLING_V2.replace(':isin', isin)).get(
        {'start_date': startDate, 'end_date': endDate}
    );
  }

  getAnalysePerformanceRollingData(isin, startDate, endDate) {
    return this.at(this.GET_ANALYSE_PERFORMANCE_ROLLING_V2.replace(':isin', isin)).get(
        {'start_date': startDate, 'end_date': endDate}
    );
  }

  getDocumentsData(isin, doc_type, lang, market) {
    let queryParams = {type: doc_type}
    if(lang) queryParams.lang = lang
    if(market) queryParams.market = market
    return this.at(this.GET_DATA_DOCUMENTS.replace(':isin', isin)).get(queryParams);
  }

  getPdf(isin, date, title, pdf_type) {
    let queryParams = {}
    if(pdf_type) queryParams.type = pdf_type;
    if(date) queryParams.date = date;
    if(title) queryParams.title = title;
    return this.at(this.GET_PDF.replace(':isin', isin)).get(queryParams);
  }

  postToFavlistInstrument(isin, requestBody) {
    return this.at(this.POST_ADD_FAVLIST.replace(':isin', isin)).post(requestBody);
  }

  postToMpInstrument(isin, requestBody) {
    return this.at(this.POST_ADD_MUSTERDEPOT.replace(':isin', isin)).post(requestBody);
  }

}

class OnboardingProtocolResource extends Resource {
  constructor() {
    super('/reports');

    this.POST_PROTOCOL_CONTENT = '/onboarding/protocol/'
    this.POST_QUESTIONNAIRE_CONTENT = '/onboarding/questionnaire-pdf/'
    this.POST_PROTOCOL_SIGN = '/onboarding/protocol/sign/'
    this.POST_PRODUCT_INFORMATION_CONTENT = '/onboarding/product-information-pdf/'
    this.POST_LEGITIMATION_DOCUMENT_CONTENT = '/onboarding/legitimation-preview/'
    this.GET_BANK_ONLINE_DOCUMENT_CONTENT = '/onboarding/:onboarding_uid/online-document/'
    this.GET_ESG_DOCUMENT_CONTENT = '/onboarding/esg-profile-pdf/'
  }

  getQuestionnaireContent(requestBody) {
    const config = {
      responseType: 'arraybuffer'
    };

    let token = getFromStorage(TOKEN_KEY);
    if (!_.isUndefined(token) && this.with_authorisation_token) {
      config.headers = {
        ...this.config.headers,
        'Authorization': `Bearer ${token}`
      }
    }
    return axiosInstance.post(`${this.resourceUrl}${this.POST_QUESTIONNAIRE_CONTENT}`, requestBody, config);
  }

  getProtocolContent(protocolData) {
    const config = {
      responseType: 'arraybuffer'
    };

    let token = getFromStorage(TOKEN_KEY);
    if (!_.isUndefined(token) && this.with_authorisation_token) {
      config.headers = {
        ...this.config.headers,
        'Authorization': `Bearer ${token}`
      }
    }

    return axiosInstance.post(`${this.resourceUrl}${this.POST_PROTOCOL_CONTENT}`, protocolData, config);
  }

  getProductInformationContent(protocolData) {
    const config = {
      responseType: 'arraybuffer'
    };

    let token = getFromStorage(TOKEN_KEY);
    if (!_.isUndefined(token) && this.with_authorisation_token) {
      config.headers = {
        ...this.config.headers,
        'Authorization': `Bearer ${token}`
      }
    }

    return axiosInstance.post(`${this.resourceUrl}${this.POST_PRODUCT_INFORMATION_CONTENT}`, protocolData, config);
  }

  getBankOnlineDocumentContent(onboarding_uid, external_id, objectType) {
    let getParams = {
      external_id,
      object_type: objectType
    }

    const config = {
      responseType: 'arraybuffer',
      params: getParams
    };

    let token = getFromStorage(TOKEN_KEY);
    if (!_.isUndefined(token) && this.with_authorisation_token) {
      config.headers = {
        ...this.config.headers,
        'Authorization': `Bearer ${token}`
      }
    }

    return axiosInstance
      .get(`${this.resourceUrl}${this.GET_BANK_ONLINE_DOCUMENT_CONTENT
        .replace(':onboarding_uid', onboarding_uid)}`, config);
  }

  getLegitimationDocumentsContent(requestBody) {
    const config = {
      responseType: 'arraybuffer'
    };

    let token = getFromStorage(TOKEN_KEY);
    if (!_.isUndefined(token) && this.with_authorisation_token) {
      config.headers = {
        ...this.config.headers,
        'Authorization': `Bearer ${token}`
      }
    }

    return axiosInstance.post(`${this.resourceUrl}${this.POST_LEGITIMATION_DOCUMENT_CONTENT}`, requestBody, config);
  }

  signProtocolPdf(requestBody) {
    return this.at(this.POST_PROTOCOL_SIGN).post(requestBody)
  }

  getEsgDocumentContent(requestBody) {
    const config = {
      responseType: 'arraybuffer'
    };

    let token = getFromStorage(TOKEN_KEY);
    if (!_.isUndefined(token) && this.with_authorisation_token) {
      config.headers = {
        ...this.config.headers,
        'Authorization': `Bearer ${token}`
      }
    }

    return axiosInstance.post(`${this.resourceUrl}${this.GET_ESG_DOCUMENT_CONTENT}`, requestBody, config);
  }
}

class QuestionnaireResource extends Resource {
  getDocument(document_id) {

    const config = {
      responseType: 'arraybuffer',
    };

    let token = getFromStorage(TOKEN_KEY);
    if (!_.isUndefined(token) && this.with_authorisation_token) {
      config.headers = {
        ...this.config.headers,
        'Authorization': `Bearer ${token}`
      }
    }

    return axiosInstance.get(`${this.resourceUrl}customer-document/${document_id}/`, config);
  }

  getBanksData() {
    return this.at('banks_data/').get();
  }

  getLatestSession(customer_id, object_type, create) {
    return this.at(`${customer_id}/onboarding/`).get({object_type, create})
  }

  getLatestFinishedByServiceConcepts(customer_id, object_type) {
    return this.at(`${customer_id}/latest-finished-by-service-concepts/`).get({object_type})
  }

 /**
  *
  *   @param customer_id
  *   @param riskProfileOnly - filter by RISK_QUESTIONNAIRE
  *   @param anlageberatungOnly - filter by INVESTMENT_KNOWLEDGE_QUESTIONNAIRE
  *   @param skip_questionnaire - flag if questionnaire need to be present in response
  */
  getLastFinishedRiskProfile(customer_id, riskProfileOnly=undefined, anlageberatungOnly=undefined, skip_questionnaire=false) {
    let getParams = {skip_questionnaire: skip_questionnaire}
    if (riskProfileOnly) {
      getParams["risk_profile_only"] = riskProfileOnly
    }
    if(anlageberatungOnly) {
      getParams['anlageberatung_only'] = anlageberatungOnly
    }
    return this.at(`${customer_id}/get-last-risk-profile/`).get(getParams)
  }

  deleteQuestionnaire(onboarding_uid) {
    return this.at(`onboarding/${onboarding_uid}/`).delete()
  }

  getCombinedTradingDefaults(customer_id) {
    return this.at(`get-combined-trading-defaults/${customer_id}/`).get()
  }
}

class PortfolioBuilderHandler extends Resource {
  createCase(data) {
    return this.at('create/').post(data)
  }

  getCaseStatus(caseId) {
    return this.at(`${caseId}/state/`).get();
  }

  getCaseResult(caseId) {
    return this.at(`${caseId}/result/`).get();
  }
}

export const AuthResource = new Resource('auth/', false);
export const AccountsResource = new Resource('accounts/')
export const PortfolioResource = new Resource('portfolio/');
export const FinciteResource = new Resource('fincite/');
export const CustomerResource = new Resource('customer/');
export const BrokerResource = new Resource('broker/');
export const PortfolioManagerResource = new Resource('portfolio_manager/');
export const CustomerReportSettingResource = new Resource('reports/');
export const GroupResource = new Resource('groups/');
export const AgencyResource = new Resource('agencies/');
export const SharedSettingsResource = new Resource('shared_settings/');
export const PortfolioHandlerResource = new PortfolioHandler();
export const BankSettingsHandlerResource = new BankSettingsHandler();
export const FormCenterHandlerResource = new FormCenterHandler();
export const ModelPortfolioResource = new ModelPortfolioHandler();
export const VirtualPortfolioHandlerResource = new VirtualPortfolioHandler();
export const OnboardingProtocolHandlerResource = new OnboardingProtocolResource();
export const FactSheetsHandlerResource = new FactSheetsHandler('factsheets/');
export const QuestionnairesHandlerResource = new QuestionnaireResource('questionnaires/');
export const FavoriteListResouce = new FavoriteListHandler();
export const ESignResource = new ESignHandler();
export const AssetsResource = new Resource('assets/');
export const InvestmentStrategiesResource = new Resource('investment-strategies/');
export const PortfolioBuilderResource = new PortfolioBuilderHandler('questionnaires/portfolio-builder/');
export default Resource


export const parseResponse = (response, dataKey, resolve, reject, isDataInArray=true) => {
  try {
    let errors = _.get(response[dataKey], 'errors', [])
    let error = _.get(response[dataKey], 'error')
    if (error){
      errors.push(error)
    }
    let hasErrors = !_.isEmpty(errors)

    if (!hasErrors) {
      let data = response[dataKey].data;
      resolve(_.isArray(data)
        ? isDataInArray ? data[0] : data
        : _.isNil(data) ? {} : data);
    } else {
      if (hasErrors && reject) reject(errors[0]);
    }
  } catch (e) {
    reject('Empty response.')
  }
}
