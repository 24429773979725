import { REPORT_TYPES } from '../DashboardSettings/components/CustomersList/components/ReportType/constants';

export const ADD_PORTFOLIOS = 'ADD PORTFOLIOS';
export const REMOVE_PORTFOLIOS = 'REMOVE PORTFOLIOS';

export const TIME_SELECTOR_DATE_CHANGED = 'TIME_SELECTOR_DATE_CHANGED';
export const REPORT_TYPE_CHANGED = 'REPORT_TYPE_CHANGED';

export const BENCHMARK_CONFIGURATION_MODAL_OPENED = 'BENCHMARK_CONFIGURATION_MODAL_OPENED';
export const BENCHMARK_CONFIGURATION_REQUIRED = 'BENCHMARK_CONFIGURATION_REQUIRED';
export const BENCHMARK_CONFIGURATION_PORTFOLIO_ID = 'BENCHMARK_CONFIGURATION_PORTFOLIO_ID';
export const BENCHMARK_CONFIGURATION = 'BENCHMARK_CONFIGURATION';

export const AGGREGATED_COLOR = '#0092E5';
export const DEPOT_COLORS = ['#448744', '#983CA8', '#FCB900', '#FF55BE', '#3CD0DE', '#E56600', '#2330D0', '#9B9B9B', '#2F2F2F'];
export const CHART_COLORS = [AGGREGATED_COLOR, ...DEPOT_COLORS];

export const ADD_PORTFOLIO_DATA = 'ADD PORTFOLIO DATA';

export const SECTION_PADDING = 16;
export const TAB_HEADER_SIZE = 24;
export const CARD_HEADER_SIZE = 20;

export const NoPriceOnWeekendsWarning = 'Da für handelsfreie Tage keine Preisdaten zur Verfügung stehen, werden ' +
  'automatisch die letzten verfügbaren Preisdaten herangezogen, falls der gewählte Zeitraum an einem handelsfreien ' +
  'Tag beginnt oder endet.';

export const TAB = {
  OVERVIEW: {
    id: 'overview',
    label: 'Übersicht'
  },
  PERFORMANCE: {
    id:'performance_tab',
    label: 'Wertentwicklung'
  },
  INSTRUMENTS: {
    id: 'instruments',
    label: 'Instrumente'
  },
  TRANSACTIONS: {
    id: 'transactions',
    label: 'Transaktionen'
  },
  PAYMENT_PLANS: {
    id: 'payment_plans',
    label: 'Zahlpläne'
  },
  STRUCTURE: {
    id: 'portfolio_structure',
    label: 'Struktur'
  },
  KEY_FIGURES: {
    id: 'key_figures',
    label: 'Kennzahlen'
  },
  RISK: {
    id: 'risk_analysis',
    label: 'Risikoanalyse'
  },
  SUSTAINABILITY: {
    id: 'sustainability',
    label: 'Nachhaltigkeit'
  }
};

export const REPORT_TYPE_TABS = {
  [REPORT_TYPES.DEPOT_STATUS.value]: [
    TAB.OVERVIEW.id,
    TAB.INSTRUMENTS.id,
    TAB.TRANSACTIONS.id,
    TAB.PAYMENT_PLANS.id,
    TAB.STRUCTURE.id],
  [REPORT_TYPES.BASIC.value]: [
    TAB.OVERVIEW.id,
    TAB.PERFORMANCE.id,
    TAB.INSTRUMENTS.id,
    TAB.TRANSACTIONS.id,
    TAB.PAYMENT_PLANS.id,
    TAB.STRUCTURE.id],
  [REPORT_TYPES.EXPERT.value]: [
    TAB.OVERVIEW.id,
    TAB.PERFORMANCE.id,
    TAB.INSTRUMENTS.id,
    TAB.TRANSACTIONS.id,
    TAB.PAYMENT_PLANS.id,
    TAB.STRUCTURE.id,
    TAB.RISK.id,
    TAB.SUSTAINABILITY.id],
  [REPORT_TYPES.PRO.value]: [
    TAB.OVERVIEW.id,
    TAB.PERFORMANCE.id,
    TAB.INSTRUMENTS.id,
    TAB.TRANSACTIONS.id,
    TAB.PAYMENT_PLANS.id,
    TAB.STRUCTURE.id,
    TAB.KEY_FIGURES.id,
    TAB.RISK.id,
    TAB.SUSTAINABILITY.id],
  [REPORT_TYPES.CUSTOM.value]: [] // Is dynamic
};