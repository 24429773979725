import React, { useState } from 'react';
import _ from "lodash";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import {
  FormControlLabel,
  Checkbox, IconButton,
} from '@material-ui/core';

import { clone } from 'lodash';
import useStyles from './styles';
import CollapsableConfigWrapper from './CollapsableConfigWrapper';
import CloseIcon from "@material-ui/icons/Close";
import clsx from 'clsx';
import {DisabledReasonTooltip} from "../../components/Tooltip";


export default (props) => {

  const classes = useStyles();

  const {
    customReportTypeSettings,
    dashboardConfigurationOpened,
    onClose,
    handleCustomReportSettingsChange
  } = props;
  const [settings, setSettings] = useState(_.cloneDeep(customReportTypeSettings));

  const renderStructure = (data, collapsed = true, level = 1, idPath) => {
    // add keys as category id
    const categories = [];
    let haveParentSibling = false;
    Object.keys(data).map(key => {
      const category = {...data[key], id: key};
      categories.push(category);
      if(!haveParentSibling) {
        haveParentSibling = !_.isEmpty(category.subcategories);
      }
    });


    return _.sortBy(categories, ['order']).map(category => {
      // build id as full lodash path. Ex: "dashboard.riskChart"
      const idFullPath = !!idPath ? _.join([idPath, category.id], '.') : category.id;
      return (
        <Grid container>
          <Grid item xs={12} sm={12} lg={12}>
            {category.subcategories ? (
              <CollapsableConfigWrapper
                item={category}
                opened={collapsed}
                level={level}
                onChange={() => handleCheckParentItem(idFullPath)}
              >
                <Grid container>
                  {renderStructure(category.subcategories, false, level + 1, idFullPath)}
                </Grid>
              </CollapsableConfigWrapper>
            ) : (
              <div className={clsx(haveParentSibling ? classes.midLevelLabel : classes.labelWrap, classes.adaptableFontSize)}>
                <DisabledReasonTooltip title={category.disabled && category.reason} placement={"top"}>
                  <FormControlLabel
                    label={category.name}
                    classes={{label: classes.adaptableFontSize}}
                    disabled={category.disabled}
                    control={
                      <Checkbox
                        color="primary"
                        classes={{root: classes.checkbox}}
                        checked={category.checked}
                        onChange={handleCheckboxClick(idFullPath)}
                      />
                    }
                  />
                </DisabledReasonTooltip>
              </div>
            )}
          </Grid>
        </Grid>
      )
    })
  };

  const updateObject = (data, pathId) => {

    const ids = pathId.split('.');

    function checkItem (parent, lvl=1) {
      if (ids.length > lvl) {
        const subItem = _.get(parent.subcategories, ids[lvl]);
        checkItem(subItem, lvl + 1);

        // if child checked - check parent
        if (subItem.checked) {
          parent.checked = true;
        }
      } else {
        // it's current item which was clicked
        parent.checked = !parent.checked;
      }
    }

    // top lvl item
    const item = _.get(data, ids[0], {});
    checkItem(item);
  };

  const checkChildren = (parent) => {
    /** update checkboxes from top to bottom: check/uncheck all children subcategories */
    Object.values(parent.subcategories || {}).filter(item => !item.disabled).forEach((item) => {
      item.checked = parent.checked;
      checkChildren(item);
    });
  };

  const handleCheckboxClick = (categoryId) => () => {
    const data = clone(settings);
    updateObject(data, categoryId);
    setSettings(data);
  };

  const handleCheckParentItem = (idPath) => {
    const data = clone(settings);

    const ids = idPath.split('.');

    // ['dashboard', 'riskChart'] => 'dashboard.subcategories.riskChart';
    const lodashPath = _.join(ids, '.subcategories.');

    const item = _.get(data, lodashPath);

    // check it's own
    updateObject(data, idPath);
    // check all children
    checkChildren(item);

    setSettings(data);
  };

  const onSave = () => {
    handleCustomReportSettingsChange(settings);
    onClose();
  };


  return (
    <React.Fragment>
      <Dialog
        open={dashboardConfigurationOpened}
        fullWidth={true}
        maxWidth={'lg'}
        classes={{
          paper: classes.modalPaper
        }}
        scroll={'body'}
      >
        <IconButton aria-label="close" classes={{root: classes.closeButton}} onClick={onClose}>
          <CloseIcon/>
        </IconButton>
        <DialogTitle classes={{root: classes.dialogTitleRoot}}>Individuell</DialogTitle>
        <DialogContent classes={{root: classes.dialogContentRoot}}>
          <Grid container className={classes.container}>
            {renderStructure(settings)}
          </Grid>
        </DialogContent>
        <DialogActions classes={{root: classes.dialogActionsRoot}}>
          <Button className={classes.acceptButton}
           onClick={onClose}
            color="primary">
            Schließen
          </Button>
          <Button
            className={classes.acceptButton}
            onClick={onSave}
            color="primary"
            variant={"contained"}
            autoFocus>
              Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
};
