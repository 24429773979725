import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

import styles from "../styles";
import RadioQuestion from '../../../question/RadioQuestion';
import {SERVICE_CONCEPTS} from '../../../../../../../Trades/constants'
import ProtocolConversationDateAndPlace from "./components/ProtocolConversationDateAndPlace";
import ProtocolConversationParticipants from "./components/ProtocolConversationParticipants";
import TextQuestion from "../../../question/TextQuestion";

const ConversationDetailsStep = props => {
  const {
    classes,
    onAnswerChange,
    questions: [
      interviewDate,
      interviewStartTime,
      interviewEndTime,
      interviewPlace,
      interviewBrokerInput,
      interviewPlaceBrokerInput,
      interviewCustomerInput,
      interviewPlaceCustomerInput,
      interviewAccountHolder1Input,
      interviewPlaceAccountHolder1Input,
      interviewAccountHolder2Input,
      interviewPlaceAccountHolder2Input,
      interviewRepeatedDate,
      interviewInitiative,
      interviewReason,
      interviewDateType,
      permanentSuitabilityCheck,
      interviewOtherPeoplesInput
    ],
    dataService
  } = props;

  return (
    <>
      <Grid container spacing={2}>
        <ProtocolConversationDateAndPlace
          interviewDate={interviewDate}
          interviewStartTime={interviewStartTime}
          interviewEndTime={interviewEndTime}
          onAnswerChange={onAnswerChange}
          dataService={dataService}
          interviewPlace={interviewPlace}
          interviewPlaceBrokerInput={interviewPlaceBrokerInput}
          interviewPlaceCustomerInput={interviewPlaceCustomerInput}
          interviewPlaceAccountHolder1Input={interviewPlaceAccountHolder1Input}
          interviewPlaceAccountHolder2Input={interviewPlaceAccountHolder2Input}
        />
        <ProtocolConversationParticipants
          classes={classes}
          dataService={dataService}
          onAnswerChange={onAnswerChange}
          interviewBrokerInput={interviewBrokerInput}
          interviewCustomerInput={interviewCustomerInput}
          interviewAccountHolder1Input={interviewAccountHolder1Input}
          interviewAccountHolder2Input={interviewAccountHolder2Input}
          interviewDateType={interviewDateType}
          interviewRepeatedDate={interviewRepeatedDate}
          interviewOtherPeoplesInput={interviewOtherPeoplesInput}
        />
        {dataService.serviceConcept == SERVICE_CONCEPTS.Anlageberatung && (
          <Grid item xs={12} md={8}>
            <RadioQuestion
              question={permanentSuitabilityCheck}
              onAnswerChange={onAnswerChange}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextQuestion question={interviewInitiative} onAnswerChange={onAnswerChange} />
        </Grid>
        {dataService.serviceConcept != SERVICE_CONCEPTS.ExecutionOnly && (
          <Grid item xs={12}>
            <TextQuestion question={interviewReason} onAnswerChange={onAnswerChange} />
          </Grid>
        )}
       </Grid>
    </>
  );
};

export default withStyles(styles)(ConversationDetailsStep);
