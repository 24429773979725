import Grid from "@material-ui/core/Grid";
import React from "react";
import moment from "moment";
import _ from "lodash";

import SelectFormElement from "../../../../formElement/SelectFormElement";
import RadioFormElement from "../../../../formElement/RadioFormElement";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "./styles";
import {isRequiredQuestion} from "../../../../../../../utils";

const RiskProfileSelectionStep = props => {
  const {
    classes,
    onAnswerChange,
    dataService,
    questions: [
      riskProfileRadioQuestion,
      riskProfileSelectQuestion,
    ],
  } = props;

  const [riskProfiles, setRiskProfiles] = React.useState(
    _.take(
      _.reverse(
        _.sortBy(dataService.riskProfiles, [
          (a) => moment(a.time.split(' ')[0]),
          (a) => a['SRRI-final']])).map(r => {
          return {
            id: r.customerRiskProfileGUID,
            value: r.customerRiskProfileGUID,
            label: "Risikoprofilierung vom {date} - Risikoklasse {score}"
              .replace("{date}", moment(r.time).format("DD.MM.YYYY"))
              .replace("{score}", r['SRRI-final'])
          }
        }), 10));

  const hasExistingProfiles = !_.isEmpty(riskProfiles);

  React.useEffect(() => {
    if (!riskProfileRadioQuestion.answer) {
      if (hasExistingProfiles) {
        handleRadioValueChange('select');
        onAnswerChange(riskProfileSelectQuestion.uid, getLatestRiskProfile())
      } else {
        handleRadioValueChange('new')
      }
    }
    if(!hasExistingProfiles){
      const existingProfileOption = riskProfileRadioQuestion.options[1];
      existingProfileOption.disabled = true;
      existingProfileOption.info_text = 'Dieser Kunde/ diese Kundin hat noch keine Risikoprofilierung durchgeführt.'
    }
  }, []);

  const handleProfileChange = (value) => {
    onAnswerChange(riskProfileSelectQuestion.uid, value);
  };

  const handleRadioValueChange = (value) => {
    onAnswerChange(riskProfileRadioQuestion.uid, value);
    riskProfileSelectQuestion.optional = (value === "new");
  };

  const getLatestRiskProfile = () => {
    return _.get(riskProfiles, '0.value', '')
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <RadioFormElement
            label={riskProfileRadioQuestion.question_text}
            value={riskProfileRadioQuestion.answer || ''}
            options={riskProfileRadioQuestion.options}
            onChange={handleRadioValueChange}
            custom_classes={{radioGroupRoot: classes.radioGroupRoot, labelRoot: classes.labelRoot}}
            required={isRequiredQuestion(riskProfileRadioQuestion)}
          />
          {hasExistingProfiles && (
            <SelectFormElement
              value={riskProfileSelectQuestion.answer || getLatestRiskProfile()}
              options={riskProfiles}
              onChange={handleProfileChange}
              disabled={riskProfileRadioQuestion.answer === 'new'}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}


export default withStyles(styles)(RiskProfileSelectionStep);