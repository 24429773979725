import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

import styles from "../styles";
import CheckboxQuestion from "../../../question/CheckboxQuestion";
import RichTextEditor from "../../../formElement/RichTextEditor";
import {isRequiredQuestion} from "../../../../../../utils";
import FormLabelElement from '../../../formElement/LabelFormElement'

const RiskStep = props => {
  const {
    classes,
    onAnswerChange,
    questions: [
      risk,
      riskCredit,
      riskEmittent,
      riskTermination,
      riskEconomic,
      riskExchangeRate,
      riskLiquidity,
      riskSuspensionRedemption,
      riskCurrency,
      riskInterestRate,
      riskAdditional,
      riskAdditionalDetails
    ],
    dataService
  } = props;

  const handleAnswerChange = (question, answer) => {
    onAnswerChange(question, answer);
  };

  const handleRiskAdditionalCheckboxChange = (uid, value) => {
    riskAdditionalDetails.disabled = riskAdditionalDetails.optional = !value;
    if(riskAdditionalDetails.disabled){
      riskAdditionalDetails.answer = null;
      riskAdditionalDetails.error = null;
    }
    handleAnswerChange(uid, value);
  };

  React.useEffect(() => {
    handleRiskAdditionalCheckboxChange(riskAdditional.uid, riskAdditional.answer);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.checkboRegularContainer}>
        <CheckboxQuestion
          question={risk}
          onAnswerChange={handleAnswerChange}
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabelElement label={dataService.isNewDesign ? "Der Kunde/die Kundin wurde zusätzlich über die folgenden Risiken informiert und aufgeklärt:" : "Der Kunde wurde zusätzlich über die folgenden Risiken informiert und aufgeklärt:"} required={false} customClasses={{labelRoot: 'bold'}} />
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} lg={3} className={classes.checkboRegularContainer}>
            <CheckboxQuestion
              question={riskCredit}
              onAnswerChange={handleAnswerChange}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3} className={classes.checkboRegularContainer}>
            <CheckboxQuestion
              question={riskEmittent}
              onAnswerChange={handleAnswerChange}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3} className={classes.checkboRegularContainer}>
            <CheckboxQuestion
              question={riskTermination}
              onAnswerChange={handleAnswerChange}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3} className={classes.checkboRegularContainer}>
            <CheckboxQuestion
              question={riskEconomic}
              onAnswerChange={handleAnswerChange}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3} className={classes.checkboRegularContainer}>
            <CheckboxQuestion
              question={riskExchangeRate}
              onAnswerChange={handleAnswerChange}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3} className={classes.checkboRegularContainer}>
            <CheckboxQuestion
              question={riskLiquidity}
              onAnswerChange={handleAnswerChange}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3} className={classes.checkboRegularContainer}>
            <CheckboxQuestion
              question={riskSuspensionRedemption}
              onAnswerChange={handleAnswerChange}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3} className={classes.checkboRegularContainer}>
            <CheckboxQuestion
              question={riskCurrency}
              onAnswerChange={handleAnswerChange}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3} className={classes.checkboRegularContainer}>
            <CheckboxQuestion
              question={riskInterestRate}
              onAnswerChange={handleAnswerChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.checkboRegularContainer}>
        <CheckboxQuestion
          question={riskAdditional}
          onAnswerChange={handleRiskAdditionalCheckboxChange}
        />
        <RichTextEditor
          uid={riskAdditionalDetails.uid}
          value={riskAdditionalDetails.answer || ''}
          onChange={(value) => handleAnswerChange(riskAdditionalDetails.uid, value)}
          rows={13}
          disabled={riskAdditionalDetails.disabled || false}
          required={isRequiredQuestion(riskAdditionalDetails)}
          placeholder={riskAdditionalDetails.config && riskAdditionalDetails.config.placeholder}
          error={riskAdditionalDetails.error}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(RiskStep);
