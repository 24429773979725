import React from 'react';
import _ from 'lodash';
import Skeleton from '@material-ui/lab/Skeleton';
import { isSubDepotItem, openMorningStartIsin } from './utils';
import { getInstrName } from '../containers/Modelportfolios/utils';
import {makeStyles} from "@material-ui/core";
import clsx from "clsx";
import WarningTooltip from "../components/WarningTooltip";
import {
  getEcologicalChartDataFromAssetInfo,
  getESGChartPropsFromAssetInfo,
  getPAIChartDataFromAssetInfo
} from "../containers/FactSheetsPage/Tabs/SustainabilityTab";
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "./constants";

export const IsinCell = {
    header: 'ISIN/WKN',
    body: {
    content: (item) => {
        if((!item.isin && !item.wkn) || isSubDepotItem(item)) {
            return ''
        }
        return (
            <p style={{margin: 0}}>
              {item.isModelportfolio ? item.mp_number : (
                <>
                  { item.isin || '-' } / <span>{ item.wkn || '-' }</span>
                </>
              )}
            </p>
        )

    }},
    key: 'IsinCell'
}


export const DepotSubNumberCell = {
  header: {
    content: () => null
  },
  body: {
    content: (item) => {
      if (_.isNil(item.depot_sub_number) || item.isSubItem || item.isMpOrPi) {
        return null
      }

      return (
        <small>Unterdepotnummer: {item.depot_sub_number}</small>
      )

    }
  },
  key: 'DepotSubNumberCell'
}


export const InstrumentNameCell = {
    header: "Instrumentenname",
    body: {
      onClick: (item) => openMorningStartIsin(item.isin),
      className: 'name bold link',
      ellipsis: true,
      content: (item, options) => {
        if (options.loading) {
          return <Skeleton />
        }

        return getInstrName(item)
      },
    },
    key: 'InstrumentNameCell'
}

const useSustainabilityMetricStyles = makeStyles(() => ({
  line: {
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    fontFamily: 'Roboto-Regular !important'
  },
  title: {
    display: 'inline-block',
    width: 34
  },
  metric: {
    display: 'inline-flex',
    height: 16,
    width: 16,
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent'
  },
  green: {
    background: 'rgb(35, 211, 116)',
  },
  red: {
    background: 'rgb(229, 0, 0)',
  }
}))

const SustainabilityMetric = ({title, value, description}) => {
  const classes = useSustainabilityMetricStyles();

  const hasValue = !_.isNil(value);

  return (
    <p className={classes.line}>
      {title && (
        <span className={classes.title}>{title} </span>
      )}
      <span style={{display: 'flex', alignItems: 'center'}}>
        <span className={clsx(classes.metric)}>
          {_.isNil(value) ? <i className="fal fa-minus" style={{color: '#707070', fontSize: 16}}/> : (
            <>
              {
                value ? (
                  <i className="fal fa-check-circle" style={{color: '#23D374', fontSize: 16}} />
                ) : (
                  <i className="fal fa-times-circle" style={{color: '#E50000', fontSize: 16}} />
                )
              }
            </>

          )}
        </span>
        {description && (
          <span style={{marginLeft: 3}}>{description}</span>
        )}
      </span>
    </p>
  )
}

const getSustainabilityValue = (data) => {

  const values = Object.values(data);

  if (_.some(values, (value) => _.isBoolean(value) && value)) return true;
  if (_.some(values, (value) => _.isBoolean(value) && !value)) return false;
  return undefined;
}

export const SustainabilityCell = {
  header: {
    content: (item, options) => (
     <span>{options.sustainabilityTitle || "Nachhaltig-keit "}<WarningTooltip
       title={
        <>
          <SustainabilityMetric value={true} description=" = Erfüllt"/>
          <SustainabilityMetric value={false} description=" = Nicht erfüllt" />
          <SustainabilityMetric value={undefined} description={` = ${DEFAULT_EMPTY_SECTION_MESSAGE}`}/>
        </>
      }/>
     </span>
    )
  },
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }

      return (
        <>
          <SustainabilityMetric title="Öko" value={getSustainabilityValue(getEcologicalChartDataFromAssetInfo(item))}/>
          <SustainabilityMetric title="ESG" value={getSustainabilityValue(getESGChartPropsFromAssetInfo(item))}/>
          <SustainabilityMetric title="PAI" value={getSustainabilityValue(getPAIChartDataFromAssetInfo(item))}/>
        </>
      )
    }
  }
}