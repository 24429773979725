import React, {Component} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash';
import moment from "moment";

import {Container, Grid, withStyles, IconButton} from '@material-ui/core'
import {Skeleton} from '@material-ui/lab';

import Link from '../../../components/Link';
import {DeleteModelPortfolioModal, ModelPortfolioModal} from '../components'

import {executeIfPathExist, getInvestmentDynamicPath} from '../../InvestmentPlatform/utils'
import {ModelPortfolioResource, parseResponse, VirtualPortfolioHandlerResource} from '../../../utils/api'
import {displayErrorSnackBar, displaySuccessSnackBar} from '../../../components/SnackbarProvider/actions';
import {
  MODEL_PORTFOLIO_DELETE_ERROR_MESSAGE,
  MODEL_PORTFOLIO_DELETE_SUCCESS_MESSAGE,
  PB_NO_ASSETS_MSG
} from '../constants';
import {
  buildDeleteOrderConfirmationMessage,
  buildModelPortfoliosTransactionsFromAssets,
  getDummyPortfolioTransactions,
  modelPortfolioAccessible
} from '../utils'
import {
  musterPortfolioInstrumentsTableStructure,
  StartPortfolioBuilderButton
} from '../components/InstrumentsList/table-data';
import {
  getBaseReportGenerationSettings,
  getErrorMessage,
  VIRTUAL_PORTFOLIO_QTY_DECIMALS
} from "../../../utils/utils";
import withModelPortfolioDashboardData from "../../../components/ModelPortfolioDashboardDataProvider/ModelPortfolioDashboardDataProviderV2";
import withNotification from "../../../components/NotificationProvider";
import InstrumentsTab from "./components/ExtendedTabs/InstrumentsV2";
import {handleModelPortfolioCreateClick} from "../Create/Create";
import FixModelPortfolioModal from "../components/FixModelPortfolioModal/FixModelPortfolioModal";
import VirtualOrderModal from "../../VirtualPortfolioManager/modals/VirtualOrderModal";
import {
  TRADING_ACTION_BUY,
  TRADING_ACTION_SELL,
  TRADING_ACTION_SWITCH
} from "../../../components/Charts/InstrumentsAllocationTable/constants";
import {
  SelectableInstrumentsTable
} from "../../../components/SelectForProductsComparisonModal/SelectForProductsComparisonModal";
import {TRANSACTION_INSTR, TRANSACTION_TYPE_VALUES} from "../../Trades/constants";
import {
  buildTransactionDateSensitiveData
} from "../../Trades/components/TradeStep/components/PortfolioTrade/PortfolioTrade";
import {ORDER_TYPES} from "../../VirtualPortfolioManager/constants";
import {
  calculateSwitchTransactionSaldo,
  mergeSwitchItems,
  setEarliestPriceDates,
  transactionValueToPercentage,
  transactionValueToQuantity
} from "../../Trades/utils";
import {
  ConfirmationModalContext
} from "../../../components/ConfirmationModalContextProvider/ConfirmationModalContextProvider";
import PageTitle from '../../../components/PageLayout/components/PageTitle';
import PDFButton from '../../CustomerDashboardV2/components_v2/ActionButtons/PDFButton';
import InvestmentDetailsNavigation, { StickyNavigation } from '../../CustomerDashboardV2/components/InvestmentDetailsNavigation';
import TabsContainer from '../../../components/TabsContainer';
import { TabsSharedData } from '../../CustomerDashboardV2/components_v2/TabsSharedData/TabsSharedData';
import { REPORT_TYPES } from '../../DashboardSettings/components/CustomersList/components/ReportType/constants';
import OverviewTab from './components/ExtendedTabs/Overview';
import PerformanceTab from '../../CustomerDashboardV2/tabs/Performance';
import StructureTab from '../../CustomerDashboardV2/tabs/Structure';
import KeyFiguresTab from '../../CustomerDashboardV2/tabs/KeyFigures';
import RiskTab from '../../CustomerDashboardV2/tabs/Risk';
import SustainabilityTab from '../../CustomerDashboardV2/tabs/Sustainability';
import setSticky from '../../../utils/sticky';
import DeleteIcon from '../../../images/DeleteIcon';
import WarningTooltip from '../../../components/WarningTooltip';
import PaymentPlansTab from '../../CustomerDashboardV2/tabs/PaymentPlans';
import TransactionsTab from './components/ExtendedTabs/Transactions';
import OrderButton from '../../CustomerDashboardV2/components_v2/ActionButtons/OrderButton';
import { isTradingEnabled } from '../../../components/TradingStore/utils';

import {styles} from './styles'

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS(),
  auth: state.get('auth').toJS(),
  benchmarkConfiguration: state.get('benchmarkConfiguration').toJS(),
});

export const defaultExpandedItems = {
  historicalChart: true,
  performanceLineChart: true,
  performanceBarChart: true,
  riskChart: true,
  structureTab: {
    structureSection: true,
    chartItems: {}
  },
  paymentPlansItems: {},
  paymentPlans: true,
  payoutPlansItems: {},
  payoutPlans: true,
  switchPlansItems: {},
  switchPlans: true,
  instruments: true,
  // by default all shown
  instrumentsTab: {
    depots: {}, // list of depots
    securities: {}, // depots with Wertpapiere expanded
    clearingAccounts: {}, // depots with Verrechnungskonto expanded
    transactionsSaldo: {}, // depots with Transaktionsaldo expanded
    instrumentsSubItems: {},
  },
  cumulativeReturnChart: true,
  transactions: true,

  //to include charts from pro view
  forecastChart: true,
  performanceTable: true,
  performanceTableItems: {},
  singlePerformance: true,
  singlePerformanceItems: {},

  // charts KeyFiguresTab
  keyIndicatorsChart: true,
  rollingVolatilityChart: true,
  rollingSharpeRatioChart: true,
  // charts from RiskAnalysisTab
  riskAnalyse: true,
  riskReturnChart: true,
  riskReturnChartItems: {},
  stressTestChart: true,
  stressTestMaximumLossChart: true,
  stressTestRecoveryPeriodChart: true,
  correlationMatrix: true,
  correlationMatrixItems: {},
  // ESG (Sustainability)Tab
  sustainabilityMetrics: true,
  esgScoreCorporate: true,
  esgScoreSovereign: true,
};

class Overview extends Component {

  constructor(props) {
    super(props);

    this.tabs = [
      { name: 'Basisinformationen', component: OverviewTab },
      { name: 'Wertentwicklung', component: PerformanceTab},
      { name: 'Instrumente', component: InstrumentsTab },
      { name: 'Zahlpläne', component: PaymentPlansTab },
      { name: 'Transaktionen', component: TransactionsTab },
      { name: 'Portfoliostruktur', component: StructureTab, settings: {totalAssetVolumeChart: {checked: false}}},
      { name: 'Kennzahlen', component: KeyFiguresTab },
      { name: 'Risikoanalyse', component: RiskTab },
      { name: 'Nachhaltigkeit', component: SustainabilityTab }
    ];

    this.state = {
      selectedTabId: this.tabs[0].name,
      modelPortfolio: {
        data: null,
        loading: true,
        errors: null,
        date: Date.now()
      },
      deleteModelPortfolioModal: {
        open: false
      },
      investmentDynamicPath: getInvestmentDynamicPath(),
      pdfDownloading: {
        loading: false,
        errors: null
      },
      expandedItems: {...defaultExpandedItems, transactionsOverview: true},

      // data for mp save modal
      modelPortfolioModalVisible: false,
      fixModelPortfolioModalVisible: false,
      modelPortfolioCreationStatus: {
        loading: false,
        errors: null
      },
      orderModalOpened: false,
      // Used to notify child components when data should be reloaded
      refreshDataTrigger: undefined,
      fillEmptyMPWithMissingData: false
    };

    this.renderTabsCommonSection = this.renderTabsCommonSection.bind(this);
    this.postVirtualOrdersCreated = this.postVirtualOrdersCreated.bind(this)
  }

  static contextType = ConfirmationModalContext;

  componentDidMount() {
    setSticky('app-main', 'sticky-stoper', 'investment-navigation-component-sticky', 'sticky-trigger');
    this.fetchModelPortfolio()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.modelPortfolio.loading && !!this.state.modelPortfolio.data &&  !this.state.fillEmptyMPWithMissingData &&
      !this.props.instrumentList.loading && _.isArray(this.props.instrumentList.data) && _.isEmpty(this.props.instrumentList.data)
    ) {
      this.setState({fillEmptyMPWithMissingData: true});
      this.fillEmptyMpWithData();
    }

    // region BCA-7408 Remove later
    if (prevState.modelPortfolio.data != this.state.modelPortfolio.data && !!this.state.modelPortfolio.data) {
      const connectPortfolioId = _.get(this.state.modelPortfolio.data, 'connect_portfolio_id');
      if (!connectPortfolioId || _.isEmpty(connectPortfolioId)) {
        this.setState({fixModelPortfolioModalVisible: true})
      }
    }
    // endregion
  }

  handleExpandedItemsChange = (path, expanded) => {
    let oldExpandedItems = _.cloneDeep(this.state.expandedItems);
      _.set(oldExpandedItems, path, expanded);
    this.setState({expandedItems: oldExpandedItems})
  };

  handleChange = (event, newValue) => {
    if (newValue !== undefined) {
      this.setState({
        selectedTabId: newValue
      })
    }
  };

  fetchModelPortfolio = async () => {
    try {
      let response;
      if (this.props.portfolio){
        response = this.props.portfolio;
      } else {
        let id = this.props.computedMatch.params.id;
        response = await ModelPortfolioResource.getModelPortfolioDetails(id)
      }

      const benchmarksExists = _.isArray(response.benchmarks) && !_.isEmpty(response.benchmarks);
      let preSelectedBenchmarks = [];
      this.setState({
        modelPortfolio: {
          data: response,
          loading: false,
          errors: null,
          date: Date.now()
        }
      })

      if(benchmarksExists) {
          preSelectedBenchmarks = response.benchmarks.map(benchmark_data => {
          let percentage = parseInt(benchmark_data.percentage);
          return {
            ...benchmark_data,
            percentage: percentage,
            weight: percentage / 100,
          }
        });

      }

      this.props.onChartSettingsChange('performance', 'currently_selected_benchmarks', preSelectedBenchmarks || []);
      this.props.onChartSettingsChange('performance', 'withBenchmark', benchmarksExists);

    } catch (errors) {
      this.setState({
        modelPortfolio: {
          data: null,
          loading: false,
          errors,
          date: Date.now()
        }
      })
    }
  };

  renderBackLink = () => {

    return executeIfPathExist(this.props.investmentPlatform.routes, 'MODELPORTFOLIO_LIST', (path) => (
      <Grid item xs={12}>
        <Link
          title="Zurück zu Musterdepots"
          icon={<i className="fa fa-chevron-left" aria-hidden="true" />}
          underline
          onClick={() => this.props.history.push(`/${this.state.investmentDynamicPath}${path}`)}
        />
      </Grid>
    ))

  };

  getAssetsDataSource = () => {
    if (this.state.modelPortfolio.loading) {
      return [{}, {}, {}, {}, {}, {}]
    }

    if (this.state.modelPortfolio.data && this.state.modelPortfolio.data.assets) {
      return this.state.modelPortfolio.data.assets;
    }

    return []
  };

  handleDeleteButtonClick = () => {
    this.setState({
      deleteModelPortfolioModal: {
        open: true
      }
    })
  };

  handleDeleteModalClose = () => {
    this.setState({
      deleteModelPortfolioModal: {
        open: false
      }
    })
  };

  handleEditButtonClick = () => {
    this.setState({modelPortfolioModalVisible: true})
  };

  handleDeleteModelPortfolio = async () => {
    try {
      let id = this.props.computedMatch.params.id;

      await ModelPortfolioResource.deleteModelPortfolio(id)

      executeIfPathExist(this.props.investmentPlatform.routes, 'MODELPORTFOLIO_LIST', (path) => (
        this.props.history.push(`/${this.state.investmentDynamicPath}${path}`)
      ));

      this.props.dispatch(displaySuccessSnackBar(MODEL_PORTFOLIO_DELETE_SUCCESS_MESSAGE))

    } catch (errors) {
      this.props.dispatch(displayErrorSnackBar(MODEL_PORTFOLIO_DELETE_ERROR_MESSAGE))
    }
  };

  renderNotFound = () => {
    const { classes } = this.props;

    return (
      <div className={classes.errorContainer}>
        <h1>404</h1>
        <p>Not found</p>
      </div>
    )
  };

  getAvailableAssets = () => {
    let assets = [].concat(...this.getAssetsDataSource());
    return assets.filter(asset => !asset.disabledByFilter);
  };

  handleAllSelected = () => {
    if (this.props.onSelectAll){
      const selected = this.props.selectedInstruments || [];
      let assets = this.getAvailableAssets();
      if (selected.length === assets.length) {
        assets = []; // unselect all
      }

      this.props.onSelectAll(assets)
    }
  };

  handleDownloadClick = async () => {

    try {
      this.setState({
        pdfDownloading: {
          loading: true
        }
      });

      const formData = getBaseReportGenerationSettings(
        [], this.props.calculationDates, undefined, this.state.expandedItems);
      formData.append('charts_settings', JSON.stringify(this.props.chartsSettings));
      formData.append('new_design', true);

      await ModelPortfolioResource.at(ModelPortfolioResource.GET_MODEL_PORTFOLIO_PDF.replace(':id', this.state.modelPortfolio.data.id)).post(formData)
    } catch (errors) {
      this.props.dispatch(displayErrorSnackBar('Fehler beim Herunterladen des Dokuments.'))
    } finally {
      this.setState({
        pdfDownloading: false
      })
    }
  };

  postVirtualOrdersCreated(){

    this.fetchModelPortfolio();

    this.props.fetchData();

    this.setState({refreshDataTrigger: +new Date()})
  }

  getTabContent() {
    const tab = _.find(this.tabs, (tab) => tab.name === this.state.selectedTabId);

    return (
      <TabsSharedData>
        <tab.component
          reportType={REPORT_TYPES.CUSTOM.value}
          customSettings={tab.settings}
          modelPortfolio={this.state.modelPortfolio}
          aggregatedPortfolioName={_.get(this.state.modelPortfolio, 'data.name')}
          expandedItems={this.state.expandedItems}
          onExpandedItemsChange={this.handleExpandedItemsChange}
          postVirtualOrdersCreated={this.postVirtualOrdersCreated}
          handleEditOrderClick={this.handleEditOrderClick}
          handleDeleteOrderClick={this.handleDeleteOrderClick}
          useUpdate={this.state.fillEmptyMPWithMissingData}
          handleEmptyMpUpdated={() => this.setState({fillEmptyMPWithMissingData: false})}
          dashboardSettings={{with_payment_plans: true, with_profit_loss: true,}}
          {...this.props}
          viewOnly={this.props.viewOnly || !this.state.modelPortfolio.data || !modelPortfolioAccessible(this.state.modelPortfolio.data, this.props.auth)}
          withTransactionsOverview
          refreshDataTrigger={this.state.refreshDataTrigger}
          onEditButtonClick={this.handleEditButtonClick}
          paymentPlansData={this.props.paymentPlans.data}
          paymentPlansLoading={this.props.paymentPlans.loading}
          paymentPlansLoadingError={this.props.paymentPlans.errors}
          isVirtual
        />
      </TabsSharedData>
    )
  }

  renderTabsCommonSection = () => {
    // Function to render 'header of the page' that is present for evry tab

    const { classes, viewOnly, auth, instrumentList } = this.props;

    const mpData = this.state.modelPortfolio.data;
    const hasAccess = !viewOnly && mpData && modelPortfolioAccessible(mpData, auth);
    const tradingEnabled = hasAccess && isTradingEnabled();

    const onOrderClick = tradingEnabled ? () => this.setState({orderModalOpened: true}) : undefined;
    const ordersCount = _.get(this.props.combinedTradings, '0.instruments', []).length;

    const isEmpty = _.get(instrumentList, 'data.0.is_empty', true);

    const navigationProps = {
      chartsSettings: this.props.chartsSettings,
      onChartSettingsChange: this.props.onChartSettingsChange,
      calculationDates: this.props.calculationDates,
      calculationDatesType: this.props.selectedDatesType,
      handleCalculationDatesChanged: this.props.onSelectedDatesChanged,
      investmentData: this.props.investmentData.data,
      dataLoading: this.props.dataLoading,
      benchmarkConfigurationEnabled: true,
    };

    const btnClasses = { root: classes.panelButton };

    return (
      <>
        { !viewOnly && this.renderBackLink() }
        <Grid item xs={12}>
          {this.state.modelPortfolio.loading ? (
            <Skeleton style={{width: "100%", height: 40}} />
          ) : (
            <>
              {mpData && (
                <PageTitle
                  title={
                    <Grid container spacing={1} wrap={'nowrap'}>
                      <Grid item>{mpData.name}</Grid>
                      {hasAccess && (
                        <Grid item>
                          <WarningTooltip
                            title={"Musterdepot löschen"}
                            icon={
                              <IconButton color={'primary'} onClick={this.handleDeleteButtonClick}>
                                <DeleteIcon />
                              </IconButton>
                            }
                          />
                        </Grid>
                      )}
                    </Grid>
                  }
                  rightPanelItems={
                    <>
                      <Grid item xs={12} sm={'auto'}>
                        <StartPortfolioBuilderButton
                          customClasses={btnClasses}
                          item={mpData}
                          onlyIcon={false}
                          disabled={instrumentList.loading || isEmpty}
                          tooltip={isEmpty && PB_NO_ASSETS_MSG}
                        />
                      </Grid>
                      <Grid item xs={12} sm={'auto'}>
                        <PDFButton
                          customClasses={btnClasses}
                          onButtonClick={this.handleDownloadClick}
                          disabled={this.state.pdfDownloading.loading}
                        />
                      </Grid>
                      {tradingEnabled && (
                        <Grid item xs={12} sm={'auto'}>
                          <OrderButton
                            customClasses={btnClasses}
                            tradingSession={this.props.combinedTradings}
                            onButtonClick={onOrderClick}
                            ordersCount={ordersCount}
                            disabled={instrumentList.loading}
                          />
                        </Grid>
                      )}
                    </>
                  }
                />
              )}
            </>
          )}
        </Grid>
        <StickyNavigation
          handlePdfExportClick={this.handleDownloadClick}
          // onAddToComparisonClick={() => {}}
          // flags for components rendering
          onGoToTradeDetails={onOrderClick}
          tradingSession={this.props.combinedTradings}
          ordersCount={ordersCount}
          customerHasTradings={true}
          extraActions={
            <Grid item>
              <StartPortfolioBuilderButton item={mpData} customClasses={{root: classes.iconBtnRoot}} onlyIcon />
            </Grid>
          }
          {...navigationProps}
        />
        <Grid item xs={12} id="sticky-trigger">
          <InvestmentDetailsNavigation {...navigationProps} />
        </Grid>
      </>
    )
  };

  updateState = (newState) => {
    this.setState(newState)
  };
  onEditSuccess = (data) => {
    this.setState({
      modelPortfolioCreationStatus: data.modelPortfolioCreationStatus,
      modelPortfolioModalVisible: false,
      modelPortfolio: {
        data: {
          ...this.state.modelPortfolio.data,
          ...data.modelPortfolioData
        },
        date: Date.now()
      }
    });

    this.props.dispatch(displaySuccessSnackBar(data.successModelPortfolioCreationModal.message))
  };

  fillEmptyMpWithData = (fixMP=false) => {
    const selectedAssets = _.get(this.state.modelPortfolio, 'data.assets') || [];
    const depositFromDate = moment(_.get(this.state.modelPortfolio, 'data.tracking_start_date'), 'YYYY-MM-DD');

    let updatedTransactions = getDummyPortfolioTransactions(this.state.modelPortfolio.data)
    updatedTransactions.transactions.buy = buildModelPortfoliosTransactionsFromAssets(selectedAssets, depositFromDate);

    if (fixMP) {
      VirtualPortfolioHandlerResource.getInstrumentPrice(selectedAssets.map((asset) => asset.isin), depositFromDate.format('YYYY-MM-DD'), true)
        .then((response) => {
          parseResponse(response, 'instrument_price',
            (data) => {
              updatedTransactions.transactions.buy.forEach((transaction) => {
                transaction.data.price_eur = _.get(data, `${transaction.data.isin}.price_eur`)
              })
            },
            (error) => {
              updatedTransactions.transactions.buy.forEach((transaction) => {
                transaction.data.price_eur = undefined
              })
            }
          )
        })
        .catch((error) => {
          this.props.dispatch(displayErrorSnackBar(getErrorMessage(error)))
        });
    }

    this.props.setPortfoliosTransactions([updatedTransactions]);
  };

  // region BCA-7408 Remove later
  handleFixModelPortfolioClick = async () => {
    this.setState({
      orderModalOpened: true,
      fixModelPortfolioModalVisible: false
    });

    this.fillEmptyMpWithData(true);
  };
  // endregion

  getInstrumentForOrder = (isin) => {

    const getInstrument = (components) => {
      return _.find(components, (component) => component.isin == isin);
    }

    let instrument = getInstrument(_.get(this.props, 'instrumentList.data.0.components') || []);
    if (!instrument) {
      instrument = getInstrument(_.get(this.props, 'profitAndLoss.data.0.components') || []);
    }

    return instrument;

  };

  initBuyTransactionWithPrice = async (transactionBody) => {

    const transactions = _.isArray(transactionBody) ? transactionBody : [transactionBody];

    const prices = await VirtualPortfolioHandlerResource.getInstrumentPrice(
      transactions.map((t) => t.data.isin), transactions[0].deposit_from_date.format('YYYY-MM-DD'), true);

    parseResponse(prices, 'instrument_price',
      (data) => {
        transactions.forEach((t) => {
          t.data.price_eur = _.get(data, `${t.data.isin}.price_eur`);
        })
      },
      (error) => {
        transactions.forEach((t) => {
          t.data.price_eur = undefined;
        })
      }
    );
  };

  initSellTransactionWithPrice = async (transactionBody) => {

    const instrumentDataForDate = await ModelPortfolioResource.getInstrumentDataForDate(
      _.get(this.state.modelPortfolio, 'data.id'),
      transactionBody.data.id, transactionBody.deposit_from_date.format('YYYY-MM-DD'));

    parseResponse(instrumentDataForDate, 'asset', (asset_data) => {
      const _asset = asset_data[transactionBody.data.id].history[0];
      transactionBody.data = {
        ...transactionBody.data,
        ...buildTransactionDateSensitiveData(transactionBody, _asset)
      };

    })
  };

  _getTransactionTypeValueFromOrder = (order) => {
    if ([ORDER_TYPES.BUY_VALUE, ORDER_TYPES.BUY_QUANTITY].includes(order.type)) return TRANSACTION_TYPE_VALUES.BUY;
    if ([ORDER_TYPES.SELL_VALUE, ORDER_TYPES.SELL_QUANTITY].includes(order.type)) return TRANSACTION_TYPE_VALUES.SELL;
    if ([ORDER_TYPES.SWITCH_OUT, ORDER_TYPES.SWITCH_IN].includes(order.type)) return TRANSACTION_TYPE_VALUES.SWITCH;
    throw Error('Nicht unterstützter Transaktionstyp.')
  };

  _buildTransactionBody = (order) => {

    const isin = _.get(order, 'asset_details.financial_information_isin');
    const asset = this.getInstrumentForOrder(isin)
    if (!asset) {
      throw Error(`Das Produkt ${isin} kann nicht gefunden werden.`);
    }

    // Prepare original data to use it later in price data retrieving in trading components
    const transactionOriginalData = {
      executionUnitPrice: parseFloat(order.execution_unit_price || 0),
      executionQuantity: parseFloat(order.execution_quantity || 0),
      executionValue: Math.abs(parseFloat(order.execution_value || 0)),
      executionDate: moment(order.execution_datetime, 'YYYY-MM-DD'),
      requestedValue: Math.abs(parseFloat(order.requested_value || 0)),
    };

    const result = {
      data: {
        ...asset,
      },
      transaction_id: order.id,
      deposit_from_date: transactionOriginalData.executionDate,
      deposit_from_type: '2', // TRANSACTION_START_DATE.date. Hardcoded value used due to importing issue.
      transaction_value: _.round(transactionOriginalData.requestedValue, 2),
      transaction_type: TRANSACTION_INSTR.amount,
      discount: _.round(parseFloat(_.get(order, 'transaction_order.fee_breakdown.discount') || 0), 2),
      transactionOriginalData
    }

    // Switch orders will have switch_in orders under "components" field
    // so we need to build transactions for them as well.
    if (!_.isEmpty(order.components)) {
      result.buy = [];
      result.sub_transactions = {};
      this._setOrderData(result.sub_transactions, order);
      order.components.forEach((tBuy) => {
        result.buy.push(this._buildTransactionBody(tBuy));
        this._setOrderData(result.sub_transactions, tBuy);
      });
    }

    return result;
  };

  _updateSwitchTransactionsWithCalculatedValues = (transactionBody, parentTransactionBody=undefined) => {

    const itemForCalculation = (parentTransactionBody && mergeSwitchItems(transactionBody, parentTransactionBody)) || transactionBody;

    const transactionValueEuro = _.round(transactionBody.transactionOriginalData[!parentTransactionBody ? 'executionValue' : 'requestedValue'], 2);
    let transactionValue = !parentTransactionBody
      ? transactionBody.transactionOriginalData.executionQuantity // For switch out transactions we could use quantity from transaction details
      : transactionValueToQuantity(transactionValueEuro, itemForCalculation, false);
    transactionValue = transactionValue && _.floor(transactionValue, VIRTUAL_PORTFOLIO_QTY_DECIMALS);
    const transactionValuePercentage = transactionValueToPercentage(
      transactionValueEuro, itemForCalculation);

    transactionBody.transaction_value = transactionValue;
    transactionBody.transaction_type = TRANSACTION_INSTR.qty;
    transactionBody.calculated = {
      transaction_value_euro: transactionValueEuro,
      transaction_value_percentage: transactionValuePercentage,
    };

    if (!_.isEmpty(transactionBody.buy)) {
      transactionBody.buy.forEach((tBuy) => this._updateSwitchTransactionsWithCalculatedValues(tBuy, transactionBody))
    }

  };

  handleEditOrderClick = async (orders) => {

    try {

      const updatedTransactions = getDummyPortfolioTransactions(_.get(this.props, 'instrumentList.data.0'));

      for (let order of orders) {

        const transactionBody = this._buildTransactionBody(order);

        const transactionKey = this._getTransactionTypeValueFromOrder(order);
        transactionBody.tradingType = {
          [TRANSACTION_TYPE_VALUES.SELL]: TRADING_ACTION_SELL,
          [TRANSACTION_TYPE_VALUES.BUY]: TRADING_ACTION_BUY,
          [TRANSACTION_TYPE_VALUES.SWITCH]: TRADING_ACTION_SWITCH
        }[transactionKey];

        if (transactionKey == TRANSACTION_TYPE_VALUES.SELL) {
          await this.initSellTransactionWithPrice(transactionBody)
        } else if (transactionKey == TRANSACTION_TYPE_VALUES.BUY) {
          await this.initBuyTransactionWithPrice(transactionBody);
        } else {
          await Promise.all([
            this.initSellTransactionWithPrice(transactionBody),
            this.initBuyTransactionWithPrice(transactionBody.buy),
            setEarliestPriceDates(transactionBody.buy, () => {})]);
          this._updateSwitchTransactionsWithCalculatedValues(transactionBody);
          const saldo = _.round(calculateSwitchTransactionSaldo(
            transactionBody, VIRTUAL_PORTFOLIO_QTY_DECIMALS), VIRTUAL_PORTFOLIO_QTY_DECIMALS);
          const saldoEur = _.round(transactionValueToQuantity(saldo, transactionBody), 2);
          // In case saldo has euro value (> 0.01 EUR) - do nothing. Saldo should be fixed manually.
          // Otherwise - put saldo to first transaction.
          if (!saldoEur) {
            transactionBody.buy[0].transaction_value = _.round(
              transactionBody.buy[0].transaction_value + saldo, VIRTUAL_PORTFOLIO_QTY_DECIMALS);
          }
        }

        updatedTransactions.transactions[transactionKey].push(transactionBody);
      }

      this.props.setPortfoliosTransactions([updatedTransactions]);
      this.setState({
        orderModalOpened: true,
        orderModalTransactionEditing: true
      })

    } catch (error) {
      this.props.dispatch(displayErrorSnackBar(getErrorMessage(error)))
    }

  };

  _setOrderData = (transactions, _order) => {

    const assetId = _.get(_order, 'asset_details.id');
    if (!transactions.hasOwnProperty(assetId)) {
      transactions[assetId] = [];
    }

    transactions[assetId].push(_order.id);
  };

  handleDeleteOrderClick = async (orders, throwError=false, useConfirmation=false, isAll=false) => {
    try {

      if (useConfirmation && !await this.context.confirm(buildDeleteOrderConfirmationMessage(orders, isAll))) {
        return;
      }

      const transactions = {};

      orders.forEach((order) => {
        this._setOrderData(transactions, order);
        if (!_.isEmpty(order.components)) {
          order.components.forEach((c) => this._setOrderData(transactions, c));
        }
      });

      await ModelPortfolioResource.deleteOrder(this.props.customerId, transactions);
      this.postVirtualOrdersCreated();
      return true;
    } catch (errors) {
      this.props.dispatch(displayErrorSnackBar("Fehler beim Löschen der Transaktion"));
      if (throwError) {
        throw errors
      }
    }
  };

  handleVirtualModalClose = () => {
    this.state.orderModalTransactionEditing && this.props.initPortfoliosTransactions();
    this.setState({
      orderModalOpened: false,
      orderModalTransactionEditing: false
    });
  };


  render() {

    const { classes, viewOnly, isInstrumentsSelection, onSourceSelect, sourceSelected } = this.props;
    // TODO: It is placed there due to some disabledByFilter handling. Need to place it out of component
    if (isInstrumentsSelection) {
      return (
        <Container style={{paddingBottom: 16}}>
          <SelectableInstrumentsTable
            container={this.state.modelPortfolio.data || {}}
            ContainerProps={{
              enabled: true,
              path: 'MODELPORTFOLIO_OVERVIEW',
              selectable: !!onSourceSelect,
              checked: sourceSelected,
              onChange: onSourceSelect,
              classes: {
                containerName: classes.containerName,
                containerCheckbox: classes.containerCheckbox
              }
            }}
            instruments={this.getAssetsDataSource()}
            InstrumentsProps={{
              enabled: true,
              selected: this.props.selectedInstruments || [],
              handleSelectAll: !!this.props.onSelectAll ? this.handleAllSelected : null,
              handleSelect: this.props.onInstrumentSelect,
              tableStructure: musterPortfolioInstrumentsTableStructure,
              tableClasses: classes
            }}
          />
        </Container>
      )
    }

    const fixMPPossible = this.state.modelPortfolio.data && !viewOnly &&
      modelPortfolioAccessible(this.state.modelPortfolio.data, this.props.auth);

    return (
      <>
        <Container className={`app-page-container`}>
          <Grid container spacing={3}>
            {this.renderTabsCommonSection()}
            <>
              {this.state.modelPortfolio.errors ? (
                <>
                  {this.state.modelPortfolio.errors.status && this.state.modelPortfolio.errors.status == 404 && (
                    <>
                      { this.renderNotFound() }
                    </>
                  )}
                </>
              ) : (
                <Grid item xs={12}>
                  <TabsContainer
                    value={this.state.selectedTabId}
                    onChange={this.handleChange}
                    loading={this.state.modelPortfolio.loading}
                    disabled={_.isEmpty(this.props.selectedPortfolios)}
                    tabs={this.tabs.map((tab) => ({id: tab.name, label: tab.name}))}
                    tabContent={this.getTabContent()}
                  />
                </Grid>
              )}
            </>
            {!viewOnly && (
              <DeleteModelPortfolioModal
                open={this.state.deleteModelPortfolioModal.open}
                modelPortfolioName={this.state.modelPortfolio.data && this.state.modelPortfolio.data.name}
                onClose={this.handleDeleteModalClose}
                onAccept={this.handleDeleteModelPortfolio}
              />
            )}

            <ModelPortfolioModal
              title={"Basisinformationen bearbeiten"}
              onModelPortfolioCreateClick={(data, update) => handleModelPortfolioCreateClick(
                _.get(this.state.modelPortfolio, 'data.id'), data, update, undefined,
                this.props.chartsSettings.performance.currently_selected_benchmarks, undefined,
                this.updateState, this.props.dispatch, this.state.modelPortfolioCreationStatus, this.onEditSuccess
              )}
              open={this.state.modelPortfolioModalVisible}
              onClose={() => {
                this.setState({modelPortfolioModalVisible: false})
              }}

              defaultStartDate={_.get(this.state.modelPortfolio, 'data.tracking_start_date')}
              loading={this.state.modelPortfolioCreationStatus.loading}
              errors={this.state.modelPortfolioCreationStatus.errors}
              selectedModelPortfolio={this.state.modelPortfolio.data}
              hideAsNew
            />
            {/*region BCA-7408 Remove later*/}
            {fixMPPossible && (
              <>
                <FixModelPortfolioModal
                  open={this.state.fixModelPortfolioModalVisible}
                  onClose={() => this.setState({fixModelPortfolioModalVisible: false})}
                  onEdit={this.handleFixModelPortfolioClick}
                  onDelete={() => this.setState({fixModelPortfolioModalVisible: false, deleteModelPortfolioModal: {open: true}})}
                />
              </>
            )}
            {/* endregion */}
            <VirtualOrderModal
              open={this.state.orderModalOpened}
              onClose={this.handleVirtualModalClose}
              onCancelClick={this.state.orderModalTransactionEditing && this.handleDeleteOrderClick}

              portfoliosTransactions={this.props.portfoliosTransactions}
              setPortfoliosTransactions={this.props.setPortfoliosTransactions}
              initPortfoliosTransactions={this.props.initPortfoliosTransactions}

              portfolioOwnerId={_.get(this.state.modelPortfolio, 'data.id')}
              useUpdate={!_.get(this.state.modelPortfolio, 'data.connect_portfolio_id')}
              refresh={this.postVirtualOrdersCreated}
              dispatch={this.props.dispatch}
              resource={ModelPortfolioResource}
              transactionEditing={this.state.orderModalTransactionEditing}
            />
            <Grid item xs={12} id={'sticky-stoper'} />
          </Grid>
        </Container>
      </>
    )
  }
}

export default connect(mapStateToProps)(withModelPortfolioDashboardData(withNotification(withStyles(styles)(Overview))))
