import React from 'react';
import { useLocation, Switch } from 'react-router-dom';
import connect from 'react-redux/lib/connect/connect';
import { PERMISSION_TYPE } from '../../utils/constants';

import CustomerDashboard from '../CustomerDashboard';
import ProtectedRoute from '../../components/PrivateRoute';

import PortfolioDashboard from '../PortfolioDashboard';
import PortfolioDashboardV2 from "../PortfolioDashboardV2";
import DocumentCenter from '../DocumentCenter';
import VirtualPortfoliosDashboard from '../VirtualPortfoliosDashboard';
import VirtualPortfolioPage from '../VirtualPortfolioPage';
import FactSheetsPage from '../FactSheetsPage';
import {
  AddGroupClients,
  RemoveGroupClients,
} from '../ReportGroupCreation';
import { GroupCreatePage, GroupEditPage } from '../Groups';
import {
  OtherAssetsBrokerView,
  OtherAssetsCustomerView,
} from '../OtherAssets';
import ProfessionalView from '../ProfessionalView/ProfessionalView';
import CustomerThemeProvider from '../../components/CustomerThemeProvider';
import { CustomersDataProvider } from '../../components/CustomersDataProvider';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Documents from '../Documents';
import { getMenuItems, ROUTES } from '../../routes';
import SharedSettingsProvider from '../../components/SharedSettingsProvider';

import VirtualPortfolioManager from '../VirtualPortfolioManager';
import { addRoutes } from '../InvestmentPlatform/actions';
import {getSubSystemConfigItem, UserUtils} from '../../utils/utils';

const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
  investmentPlatform: state.get('investmentPlatform').toJS(),
});

function inIframe () {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const InvestingPlatform = (props) => {
  const [showMenuItems, setShowMenuItems] = React.useState(true);
  const location = useLocation();

  React.useEffect(() => {
    fetchMenuConfiguration();
  }, []);

  const fetchMenuConfiguration = async () => {
    const userIsBroker = UserUtils.isBroker(props.auth);
    const menuItems = ['PRO_VIEW'];

    if (userIsBroker) {
      menuItems.push('MODELPORTFOLIO_OVERVIEW', 'PRODUCTS_COMPARISON_NEW', 'PRODUCTS_COMPARISON');
    }
    const response = await getMenuItems(props.auth, menuItems);
    props.dispatch(addRoutes(response));
  };

  React.useEffect(() => {
    setShowMenuItems(() => {return !(location.pathname.indexOf('/factsheets/') >= 0)})
  }, [location]);

  return (
    <>
      <SharedSettingsProvider>
        <CustomersDataProvider>
          <CustomerThemeProvider loadTheme={true}>
            {!inIframe() && (
              <Header showMenuItems={showMenuItems} />
            )}
            <div className={'app-main'} id={'app-main'}>
              <div className={'app-content'}>
                <Switch>
                  <ProtectedRoute authenticationType={PERMISSION_TYPE.BROKER_ONLY} path={ROUTES.BROKER.GROUP_CLIENTS_REMOVE.path} component={RemoveGroupClients} />
                  <ProtectedRoute authenticationType={PERMISSION_TYPE.BROKER_ONLY} path={ROUTES.BROKER.GROUP_CREATE.path} component={GroupCreatePage} />
                  <ProtectedRoute authenticationType={PERMISSION_TYPE.BROKER_ONLY} path={ROUTES.BROKER.GROUP_UPDATE.path} component={GroupEditPage} />
                  <ProtectedRoute authenticationType={PERMISSION_TYPE.BROKER_ONLY} path={ROUTES.BROKER.GROUP_CLIENTS_ADD.path} component={AddGroupClients} />
                  <ProtectedRoute authenticationType={PERMISSION_TYPE.BROKER_ONLY} path={ROUTES.BROKER.CUSTOMER_DASHBOARD.path} component={CustomerDashboard} />
                  <ProtectedRoute authenticationType={PERMISSION_TYPE.BROKER_ONLY} path={ROUTES.BROKER.OTHER_ASSETS.path} component={OtherAssetsBrokerView} />
                  <ProtectedRoute authenticationType={PERMISSION_TYPE.BROKER_ONLY} path={ROUTES.BROKER.FORM_CENTER.path} component={DocumentCenter} beta/>
                  <ProtectedRoute authenticationType={PERMISSION_TYPE.CUSTOMER_ONLY} path={ROUTES.CUSTOMER.OTHER_ASSETS.path} component={OtherAssetsCustomerView} />
                  <ProtectedRoute authenticationType={PERMISSION_TYPE.AUTHORIZED_ONLY} path={ROUTES.CUSTOMER.PRO_VIEW.path} component={ProfessionalView} isCustomerApp />
                  <ProtectedRoute authenticationType={PERMISSION_TYPE.CUSTOMER_ONLY} path={ROUTES.CUSTOMER.DASHBOARD.path} isCurrentCustomerData={true} isVirtual={false} component={getSubSystemConfigItem('reporting', 'use_v2') ? PortfolioDashboardV2 : PortfolioDashboard} />
                  <ProtectedRoute authenticationType={PERMISSION_TYPE.CUSTOMER_ONLY} path={ROUTES.CUSTOMER.DASHBOARD_OLD.path} isCurrentCustomerData={true} isVirtual={false} component={PortfolioDashboard} />
                  <ProtectedRoute authenticationType={PERMISSION_TYPE.CUSTOMER_ONLY} path={ROUTES.CUSTOMER.DASHBOARD_VIRTUAL.path} isCurrentCustomerData={true} isVirtual={true} component={VirtualPortfoliosDashboard} />
                  <ProtectedRoute authenticationType={PERMISSION_TYPE.CUSTOMER_ONLY} path={ROUTES.CUSTOMER.VIRTUAL_PRO_VIEW.path} isVirtual={true} isCustomerApp component={ProfessionalView} />
                  <ProtectedRoute authenticationType={PERMISSION_TYPE.CUSTOMER_ONLY} path={ROUTES.CUSTOMER.VIRTUAL_PORTFOLIO_PAGE.path} component={VirtualPortfolioPage} />
                  <ProtectedRoute authenticationType={PERMISSION_TYPE.AUTHORIZED_ONLY} path={ROUTES.CUSTOMER.FACTSHEETS_DASHBOARD.path} component={FactSheetsPage} />
                  <ProtectedRoute authenticationType={PERMISSION_TYPE.CUSTOMER_ONLY} path={ROUTES.CUSTOMER.VIRTUAL_PORTFOLIO_MANAGER.path} component={VirtualPortfolioManager} />
                  <ProtectedRoute authenticationType={PERMISSION_TYPE.CUSTOMER_ONLY} path={ROUTES.CUSTOMER.DOCUMENTS.path} component={Documents} />
                  <ProtectedRoute authenticationType={PERMISSION_TYPE.AUTHORIZED_ONLY} path="" component={() => null} />
                </Switch>
              </div>
              <Footer />
            </div>
          </CustomerThemeProvider>
        </CustomersDataProvider>
      </SharedSettingsProvider>
    </>
  )
};

export default connect(mapStateToProps)(InvestingPlatform);