import React from "react";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";

import ProtocolConversationParticipants from "../components/ProtocolConversationParticipants";
import styles from "../../styles";

const ParticipantsStep = props => {
  const {
    classes,
    onAnswerChange,
    dataService,
    questions: [
      interviewBrokerInput,
      interviewCustomerInput,
      interviewAccountHolder1Input,
      interviewAccountHolder2Input,
      interviewOtherPeoplesInput,
      interviewDateType,
      interviewRepeatedDate
    ],
  } = props;

  return (
    <Grid container spacing={2}>
      <ProtocolConversationParticipants
        classes={classes}
        dataService={dataService}
        onAnswerChange={onAnswerChange}
        interviewBrokerInput={interviewBrokerInput}
        interviewCustomerInput={interviewCustomerInput}
        interviewAccountHolder1Input={interviewAccountHolder1Input}
        interviewAccountHolder2Input={interviewAccountHolder2Input}
        interviewDateType={interviewDateType}
        interviewRepeatedDate={interviewRepeatedDate}
        interviewOtherPeoplesInput={interviewOtherPeoplesInput}
      />
    </Grid>
  )
};

export default withStyles(styles)(ParticipantsStep);
