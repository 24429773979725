import RiskProfileSelectionStep
  from "./components/StepContent/components/step/RiskProfile/components/RiskProfileSelectionStep";
import _ from "lodash";
import {CountryISOMapping} from "./constants";

const step1 = {
  "uid": "A1",
  "name": "Bitte überprüfen Sie die Stammdaten des Kunden",
  "question": [
    {
      "uid": "A-general",
      "question_text": "",
      "info_text": "Bitte geben Sie den Namen ihres Kunden wie im Personalausweis an.",
      "question": [
        {
          "uid": "A2",
          "question_text": "Anrede",
          "type": "normal",
          "config": {
            "sub_type": "radio",
            "choices": [
              {
                "id": 1,
                "uid": "1",
                "text": "Herr",
                "score": 0
              },
              {
                "id": 2,
                "uid": "2",
                "text": "Frau",
                "score": 0
              }
            ]
          },
          "optional": false
        },
        {
          "uid": "A4",
          "question_text": "Titel",
          "type": "normal",
          "config": {
            "sub_type": "select",
            "choices": [
              {
                "id": 1,
                "uid": "1",
                "text": "Dr.",
                "score": 0
              },
              {
                "id": 2,
                "uid": "2",
                "text": "Prof.",
                "score": 0
              }
            ]
          },
          "optional": true
        },
        {
          "uid": "A5",
          "question_text": "Vorname",
          "type": "input",
          "config": {
            "input_type": ""
          },
          "optional": false
        },
        {
          "uid": "A6",
          "question_text": "Nachname",
          "type": "input",
          "config": {
            "input_type": ""
          },
          "optional": false
        },
      ]
    },
    {
      "uid": "A-address",
      "question_text": "Adresse",
      "question": [
        {
          "uid": "A7",
          "question_text": "Straße",
          "type": "input",
          "config": {
            "input_type": ""
          },
          "optional": false
        },
        {
          "uid": "A11",
          "question_text": "Hausnr.",
          "type": "input",
          "config": {
            "input_type": ""
          },
          "optional": false
        },
        {
          "uid": "A8",
          "question_text": "PLZ",
          "type": "input",
          "config": {
            "input_type": ""
          },
          "optional": false
        },
        {
          "uid": "A9",
          "question_text": "Stadt",
          "type": "input",
          "config": {
            "input_type": ""
          },
          "optional": false
        },
        {
          "uid": "A10",
          "question_text": "Land",
          "type": "input",
          "config": {
            "input_type": ""
          },
          "optional": false
        },
      ]
    },
    {
      "uid": "A-contacts",
      "question_text": "Kontaktdaten",
      "question": [
        {
          "uid": "B1",
          "question_text": "Telefon",
          "type": "input",
          "config": {
            "input_type": "tel"
          },
          "optional": false
        },
        {
          "uid": "B2",
          "question_text": "E-Mail",
          "type": "input",
          "config": {
            "input_type": "email"
          },
          "optional": false
        }
      ]
    },
    {
      "uid": "A12",
      "question_text": "Mein Kunde ist eine politisch exponierte Person (PeP)",
      "type": "checkbox",
      "help_text": "Dies sind gemäß Geldwäschegesetz (GwG) natürliche Personen, die aktuell oder vor weniger als einem Jahr, ein wichtiges öffentliches Amt bekleiden oder ausgeübt haben, sowie deren unmittelbare Familienmitglieder.",
      "optional": true
    }

  ]
}

const step2 = {
    "uid": "A2",
    "name": "Kenntnisse & Erfahrungen",
    "help_text": "Kenntnisse bedeutet, dass Ihr Kunde ein grundlegendes Verständnis für die Risiken der jeweiligen Anlageklasse hat. Eigene Anlageerfahrungen sind dabei hilfreich, aber nicht zwingend notwendig.",
    "question": [
      {
        "uid": "H1",
        "question_text": "Geldmarktinstrumente",
        "type": "normal",
        "config": {
          "sub_type": "",
          "choices": [
            {
              "id": 1,
              "uid": "1",
              "text": "Keine Erfahrung",
              "score": 0
            },
            {
              "id": 2,
              "uid": "2",
              "text": "bis zu 1 Jahr",
              "score": 0
            },
            {
              "id": 3,
              "uid": "3",
              "text": "1 bis 3 Jahre",
              "score": 0
            },
            {
              "id": 4,
              "uid": "4",
              "text": "Mehr als 3 Jahre",
              "score": 0
            }
          ]
        },
        "optional": false
      },
      {
        "uid": "H2",
        "question_text": "Schuldverschreibungen",
        "type": "normal",
        "config": {
          "choices": [
            {
              "id": 1,
              "uid": "1",
              "text": "Keine Erfahrung",
              "score": 0
            },
            {
              "id": 2,
              "uid": "2",
              "text": "bis zu 1 Jahr",
              "score": 0
            },
            {
              "id": 3,
              "uid": "3",
              "text": "1 bis 3 Jahre",
              "score": 0
            },
            {
              "id": 4,
              "uid": "4",
              "text": "Mehr als 3 Jahre",
              "score": 0
            }
          ]
        },
        "optional": false
      },
      {
        "uid": "H3",
        "question_text": "Offene Investmentfonds",
        "type": "normal",
        "config": {
          "choices": [
            {
              "id": 1,
              "uid": "1",
              "text": "Keine Erfahrung",
              "score": 0
            },
            {
              "id": 2,
              "uid": "2",
              "text": "bis zu 1 Jahr",
              "score": 0
            },
            {
              "id": 3,
              "uid": "3",
              "text": "1 bis 3 Jahre",
              "score": 0
            },
            {
              "id": 4,
              "uid": "4",
              "text": "Mehr als 3 Jahre",
              "score": 0
            }
          ]
        },
        "optional": false
      },
      {
        "uid": "H4",
        "question_text": "Aktien",
        "type": "normal",
        "config": {
          "choices": [
            {
              "id": 1,
              "uid": "1",
              "text": "Keine Erfahrung",
              "score": 0
            },
            {
              "id": 2,
              "uid": "2",
              "text": "bis zu 1 Jahr",
              "score": 0
            },
            {
              "id": 3,
              "uid": "3",
              "text": "1 bis 3 Jahre",
              "score": 0
            },
            {
              "id": 4,
              "uid": "4",
              "text": "Mehr als 3 Jahre",
              "score": 0
            }
          ]
        },
        "optional": false
      },
      {
        "uid": "H5",
        "question_text": "Hat Ihr Kunde beruflich mit dem Kapitalmarkt zu tun oder weist die Berufsausbildung Bezug zum Kapitalmarkt auf (z.B. Bank- oder Versicherungskauffrau/-mann)?",
        "type": "normal",
        "config": {
          "choices": []
        },
        "optional": true
      }
    ]
  }

const step3 = {
    "uid": "A3",
    "name": "Informationen durch den Anlagevermittler",
    "help_text": "Die Basisinformationen über Vermögensanlagen in Investmentfonds sowie über Wertpapiere und weitere Kapitalanlagen können bei der Erstellung der Dokumente ausgewählt und angehängt werden. Der Abruf der Basisinformationen erfolgt über den Bank-Verlag und ist kostenpflichtig. Die anfallenden Kosten werden automatisch in Ihrer Abrechnung verrechnet.",
    "question": [
      {
        "uid": "I1",
        "config": {
          "sub_type": "calendar",
        },
        "question": [
          {
            "uid": "I1-checkbox",
            "question_text": "Basisinformationen über Vermögensanlagen in Investmentfonds.",
            "config": {
              "sub_type": "checkbox",
            },
            "optional": false,
          },
          {
            "uid": "I1-date",
            "question_text": "Information erfolgte am:",
            "config": {
              "sub_type": "calendar_input"
            },
            "optional": false,
          },
          {
            "uid": "I2",
            "question_text": "Versionsnr.",
            "type": "input",
            "config": {
              "input_type": ""
            },
            "optional": false
          },
        ]
      },
      {
        "uid": "I3",
        "config": {
          "sub_type": "calendar",
        },
        "question": [
          {
            "uid": "I3-checkbox",
            "question_text": "Basisinformationen über Wertpapiere und weitere Kapitalanlagen.",
            "config": {
              "sub_type": "checkbox",
            },
            "optional": true,
          },
          {
            "uid": "I3-calendar",
            "question_text": "Information erfolgte am:",
            "type": "input",
            "config": {
              "sub_type": "calendar_input",
              "input_type": ""
            },
            "optional": true
          },
          {
            "uid": "I4",
            "question_text": "Versionsnr.",
            "type": "input",
            "config": {
              "input_type": ""
            },
            "optional": true
          }
        ]
      },
    ]
  };

const step4 = {
    "uid": "A4",
    "name": "Was ist der Kunde von Beruf?",
    "question": [
      {
        "uid": "J1",
        "question_text": "Was ist der Kunde von Beruf?",
        "type": "normal",
        "config": {
          "sub_type": "list_select",
          "choices": [
            {
              "id": 1,
              "uid": "1",
              "text": "Arbeiter / Angestellter / angestellter Handwerker",
              "score": 0
            },
            {
              "id": 2,
              "uid": "2",
              "text": "leitender Angestellter",
              "score": 0
            },
            {
              "id": 3,
              "uid": "3",
              "text": "Beamter / Behördenangestellter",
              "score": 0
            },
            {
              "id": 4,
              "uid": "4",
              "text": "leitender Beamter / leitender Behördenangestellter",
              "score": 0
            },
            {
              "id": 5,
              "uid": "5",
              "text": "Unternehmer / Freiberufler / selbständiger Handwerker / sonstiger Selbständiger",
              "score": 0
            },
            {
              "id": 6,
              "uid": "6",
              "text": "Hausfrau / Hausmann",
              "score": 0
            },
            {
              "id": 7,
              "uid": "7",
              "text": "vorübergehend ohne Beschäftigung",
              "score": 0
            },
            {
              "id": 8,
              "uid": "8",
              "text": "Schüler / Praktikant / Auszubildender / Student",
              "score": 0
            }
          ]
        },
        "optional": false
      }
    ]
  };

const step5 = {
    "uid": "A5",
    "name": "Wie hoch ist das derzeitige monatliche Netto-Einkommen des Kunden?",
    "help_text": "Hinweis: Dazu gehören dauerhafte Einkünfte wie Gehälter, Renten, stabile Erträge aus Kapitalanlagen und Mieterträge.",
    "question": [
      {
        "uid": "K1",
        "question_text": "Wie hoch ist das derzeitige monatliche Netto-Einkommen des Kunden?",
        "type": "normal",
        "config": {
          "sub_type": "slider",
          "choices": [
            {
              "id": 1,
              "uid": "1",
              "text": "bis zu 1.000 EUR",
              "score": 0
            },
            {
              "id": 2,
              "uid": "2",
              "text": "bis zu 2.000 EUR",
              "score": 0
            },
            {
              "id": 3,
              "uid": "3",
              "text": "bis zu 5.000 EUR",
              "score": 0
            },
            {
              "id": 4,
              "uid": "4",
              "text": "bis zu 10.000 EUR",
              "score": 0
            },
            {
              "id": 5,
              "uid": "5",
              "text": "über 10.000 EUR",
              "score": 0
            }
          ]
        },
        "optional": false
      }
    ]
  };

const step6 = {
    "uid": "A6",
    "name": "Wieviel Geld hat der Kunde nach Abzug aller Kosten monatlich frei zur Verfügung?",
    "question": [
      {
        "uid": "L1",
        "question_text": "Wieviel Geld hat der Kunde nach Abzug aller Kosten monatlich frei zur Verfügung?",
        "type": "normal",
        "config": {
          "sub_type": "slider",
          "choices": [
            {
              "id": 1,
              "uid": "1",
              "text": "0 EUR",
              "score": 0
            },
            {
              "id": 2,
              "uid": "2",
              "text": "bis zu 200 EUR",
              "score": 0
            },
            {
              "id": 3,
              "uid": "3",
              "text": "bis zu 500 EUR",
              "score": 0
            },
            {
              "id": 4,
              "uid": "4",
              "text": "bis zu 1.000 EUR",
              "score": 0
            },
            {
              "id": 5,
              "uid": "5",
              "text": "über zu 1.500 EUR",
              "score": 0
            }
          ]
        },
        "optional": false
      }
    ]
  };

const step7_assets = {
    "uid": "A7",
    "name": "Gesamtvermögen",
    "help_text": "Hier haben Sie die Möglichkeit eine Gesamtvermögensübersicht des Kunden zu erstellen. Fügen Sie hierzu Vermögen und Verbindlichkeiten in den jeweiligen Kategorien hinzu.",
    "config": {
      "sub_type": "assets",
    },
    "question": [
      {
        "uid": "M1",
        "question_text": "Vermögenswerte",
        "config": {
          "sub_type": "assets",
        },
        "optional": false
      },
      {
        "uid": "N1",
        "question_text": "Verbindlichkeiten",
        "config": {
          "sub_type": "assets",
        },
        "optional": false
      }
    ]
  };

const step8 = {
    "uid": "A8",
    "name": "Welches generelle Anlageziel verfolgen der Kunde mit der Vermögensanlage?",
    "question": [
      {
        "uid": "O1",
        "question_text": "Welches generelle Anlageziel verfolgen der Kunde mit der Vermögensanlage?",
        "type": "normal",
        "config": {
          "sub_type": "list_select",
          "choices": [
            {
              "id": 1,
              "uid": "1",
              "text": "Vermögenserhalt",
              "score": 0
            },
            {
              "id": 2,
              "uid": "2",
              "text": "Vermögensaufbau / Vermögenswachstum",
              "score": 0
            },
            {
              "id": 3,
              "uid": "3",
              "text": "Erzielung von Zusatzeinkommen",
              "score": 0
            }
          ]
        },
        "optional": false
      }
    ]
  };

const step9 = {
  "uid": "A9",
  "name": "Wie lange möchte der Kunde anlegen?",
  "question": [
    {
      "uid": "P1",
      "question_text": "Wie lange möchte der Kunde anlegen?",
      "type": "normal",
      "config": {
        "sub_type": "slider",
        "choices": [
          {
            "id": 1,
            "uid": "1",
            "text": "bis zu 3 Jahre",
            "score": 0
          },
          {
            "id": 2,
            "uid": "2",
            "text": "bis zu 5 Jahre",
            "score": 0
          },
          {
            "id": 3,
            "uid": "3",
            "text": "bis zu 10 Jahre",
            "score": 0
          },
          {
            "id": 4,
            "uid": "4",
            "text": "mehr als 10 Jahre",
            "score": 0
          }
        ]
      },
      "optional": false
    }
  ]
};

const step10 = {
    "uid": "A10",
    "name": "Wie beschreibt der Kunde seine Risikobereitschaft?",
    "info_text": "Warum benötigen wir die Selbsteinschätzung?",
    "help_text": "Da es um die Geldanlage des Kunden geht, wollen wir auch wissen, wie der Kunde sich selbst einschätzt. Der Kunde kann dabei aus einer Auswahl zwischen “sicher” bis “spekulativ” wählen, wobei “sicher” minimal und “spekulativ” maximal risikobereit bedeutet. Die Selbsteinstufung beeinflusst das Risikoprofil dabei in einem relativ geringen Maß.",
    "question": [
      {
        "uid": "Q1",
        "question_text": "Wie beschreibt der Kunde seine Risikobereitschaft?",
        "type": "normal",
        "help_text": "Ich bevorzuge Anlagen mit wenig Schwankungen und bin mir bewusst, dass diese ggf. keine hohen Renditen erwirtschaften.",
        "config": {
          "sub_type": "slider",
          "choices": [
            {
              "id": 1,
              "uid": "1",
              "text": "Sicher",
              "score": 0
            },
            {
              "id": 2,
              "uid": "2",
              "text": "Konservativ",
              "score": 0
            },
            {
              "id": 3,
              "uid": "3",
              "text": "Ausgewogen",
              "score": 0
            },
            {
              "id": 4,
              "uid": "4",
              "text": "Chancenorientiert",
              "score": 0
            },
            {
              "id": 5,
              "uid": "5",
              "text": "Spekulativ",
              "score": 0
            }
          ]
        },
        "optional": false
      }
    ]
  };

const step11 = {
    "uid": "A11",
    "name": "Wie hoch ist die Risikobereitschaft des Kunden?",
    "info_text": "Warum benötigen wir die Selbsteinschätzung?",
    "help_text": "Da es um die Geldanlage des Kunden geht, wollen wir auch wissen, wie der Kunde sich selbst einschätzt. Der Kunde kann dabei aus einer Auswahl zwischen “gering” bis “hoch” wählen. Die Selbsteinstufung beeinflusst das Risikoprofil dabei in einem relativ geringen Maß.",
    "question": [
      {
        "uid": "R1",
        "question_text": "Wie hoch ist die Risikobereitschaft des Kunden?",
        "type": "normal",
        "config": {
          "sub_type": "slider",
          "choices": [
            {
              "id": 1,
              "uid": "1",
              "text": "Gering",
              "score": 0
            },
            {
              "id": 2,
              "uid": "2",
              "text": "Mittel",
              "score": 0
            },
            {
              "id": 3,
              "uid": "3",
              "text": "Hoch",
              "score": 0
            }
          ]
        },
        "optional": false
      }
    ]
  };

const step12 = {
  "uid": "A12",
  "name": "Wie würde der Kunde reagieren, wenn es in seinem Portfolio plötzlich zu massiven Kursverlusten käme?",
  "question": [
    {
      "uid": "S1",
      "question_text": "Wie würde der Kunde reagieren, wenn es in seinem Portfolio plötzlich zu massiven Kursverlusten käme?",
      "type": "normal",
      "config": {
        "sub_type": "list_select",
        "choices": [
          {
            "id": 1,
            "uid": "1",
            "text": "“Ich hätte schlaflose Nächte.”",
            "score": 0
          },
          {
            "id": 2,
            "uid": "2",
            "text": "“Ich würde es als sehr enttäuschend empfinden.”",
            "score": 0
          },
          {
            "id": 3,
            "uid": "3",
            "text": "“Es wäre schade, aber mir ist bewusst, dass so etwas passieren kann.”",
            "score": 0
          },
          {
            "id": 4,
            "uid": "4",
            "text": "“Es würde mir keine Sorgen bereiten.”",
            "score": 0
          }
        ]
      },
      "optional": false
    }
  ]
};

const step13_charts = {
  "uid": "A13",
  "name": "Welches Rendite- und Risikoverhältnis spricht den Kunden am stärksten an?",
  "info_text": "Die dargestellten Renditen sind lediglich beispielhaft, um den Zusammenhang zwischen Rendite und Risiko zu erklären.",
  "question": [
    {
      "uid": "T1",
      "question_text": "Welches Rendite- und Risikoverhältnis spricht den Kunden am stärksten an?",
      "type": "normal",
      "config": {
        "sub_type": "chart_select",
        "choices": [
          {
            "id": 1,
            "uid": "1",
            "text": "Defensiv",
            "score": 0
          },
          {
            "id": 2,
            "uid": "2",
            "text": "Ausgeglichen",
            "score": 0
          },
          {
            "id": 3,
            "uid": "3",
            "text": "Offensiv",
            "score": 0
          }
        ]
      },
      "optional": false
    }
  ]
};

export const finalStep = {
  "uid": "final-step",
  "name": "Risikoprofil von {customer_name}: {risk_category}",
  "info": "Das heißt, für diesen Kunden wird eine {risk_category} Anlagestrategie empfohlen werden.",
  "behaviour": ["final", "Risikoprofil"],
  "question": [
    {
      "uid": "final_question_uid",
      "info": "Das Risikoprofil der Kunden wird anhand verschiedener Faktoren berechnet und spiegelt das Risiko wider, welches der/die Kunde/in nach dieser Berechnung grundsätzlich tragen kann. Sollte sich der/die Kunde/in mit dem errechneten Risikoprofil nicht identifizieren können, haben Sie die Möglichkeit, das Risikoprofil mittels Schieberegler anzupassen. Auf Grund regulatorischer Vorgaben ist jedoch nur eine Anpassung nach unten möglich.",
      "type": "normal",
      "behaviour": ["slider"]
    }
  ]
}

export const congratsStep = {
  "uid": "congrats-step",
  "name": "Risikoprofilierung",
  "help": "Das heißt, für diesen Kunden wird eine moderate Anlagestrategie empfohlen werden.",
  "behaviour": ["congrats"],
}

export const riskProfileSelectionStep = {
  "uid": "risk-profile-selection-step",
  "name": "Risikoprofilierung",
  "custom_classes": {"stepName": "text-left"},
  "behaviour": ["dynamic", "Auswahl"],
  "step_content": RiskProfileSelectionStep,
  "question": [
    {
      "uid": "risk-profile-radio-uid",
      "type": "normal",
      "behaviour": ["radio"],
      "optional": false,
      "options": [
        {id: 1, value: 'new', label: 'Neues Risikoprofil anlegen'},
        {id: 2, value: 'select', label: 'Bereits durchgeführte Risikoprofilierung auswählen'},
      ]
    },
    {
      "uid": "risk-profile-select-uid",
      "type": "normal",
      "behaviour": ["select"],
      "optional": true,
    }
  ]
}

const getContact = (q, customer, findFunc, usePreferred=false) => {
  try {
    const contact = customer && (customer.contacts || []).find(contact => {
      let isValid = findFunc(contact)
      if (isValid && usePreferred) {
        isValid = contact.preferred
      }

      return isValid
    });
    q.dante_obj = contact;
    return contact && contact.value;
  } catch (e) {
    console.error(e);
  }
}

const getCustomerPhone = (q, customer) => {
  // First check contacts by preferred flag
  return getContact(q, customer,contact => 'Mobiltelefon' === contact.type, true) || // mobile is preferable
    getContact(q, customer,contact => 'Telefon' === contact.type, true) ||
    getContact(q, customer,contact => 'Mobiltelefon' === contact.type) ||
    getContact(q, customer,contact => 'Telefon' === contact.type)
}

const getCustomerEmail = (q, customer) => {
  return getContact(q, customer, contact => 'E-Mail' === contact.type, true) ||
    getContact(q, customer, contact => 'E-Mail' === contact.type) ||
    customer.emailAppCustomer
}

const findRelationshipField = (relationshipIndex, fieldName) => {
  return (question, customer) => {
    question.customer_id = _.get(customer, `relationships.${relationshipIndex}.id`)
    return _.get(customer, `relationships.${relationshipIndex}.${fieldName}`)
  }
}

const setRelationshipField = (relationshipIndex, fieldName) => {
  return (answers, question, value) => {
    _.set(answers, `relationships.${relationshipIndex}.id`, question.customer_id)
    _.set(answers, `relationships.${relationshipIndex}.${fieldName}`, value)
    return answers
  }
}

const getRelationshipFieldConfiguration = (relationshipIndex, fieldName) => ({
  "dante_field_finder": findRelationshipField(relationshipIndex, fieldName),
  "dante_field_setter": () => undefined,
  "dante_error_field": fieldName,
  "dante_error_finder": (errors, question) => {
    if (!question.customer_id) {
      return undefined
    }

    const dante_error_field = `relationships.${question.customer_id}.errors`
    let err = _.get(errors, dante_error_field)

    if (err) {
      return _.get(err, fieldName)
    }
  }
})

const salutationConfigs = {"dante_field": "salutation"}
export const personalInformationConfigs = {
  firstName: {"dante_field": "personalInformation.firstName"},
  lastName: {"dante_field": "personalInformation.lastName"},
  birthName: {"dante_field": "personalInformation.birthName"},
  title: {
    "dante_field": "personalInformation.title",
    "config": {"Dr.": "1", "Dr. Dr.": "2", "Prof. Dr.": "3", "Prof. Dr. Dr.": "4", "Prof.": "5", "Dipl. Ing.": "6"}
  }
};
const addressConfigs = {
  street: {"dante_field": "addresses.0.street"},
  zip: {"dante_field": "addresses.0.zip"},
  city: {"dante_field": "addresses.0.city"},
  country: {
    "dante_field": "addresses.0.country",
    "config": CountryISOMapping
  }
}
const legitimationPePConfigs = {
  "dante_field_finder": (q, customer) => {
    return _.get(customer, 'legitimation.0.peP')
  }
}
const contactsConfigs = {
  mobile:  {
    "dante_field_finder": getCustomerPhone,
    "dante_error_field": "contacts.0.value",
    "dante_field_setter": (answers, q, value) => {
      // default obj in case we create new
      const dante_obj = q.dante_obj || {type: 'Telefon'};
      if(!dante_obj.syncType) dante_obj.syncType = 'privat';
      return _.set(answers, 'contacts.0', {...dante_obj, value: value});
    }
  },
  email: {
    "dante_field_finder": getCustomerEmail,
    "dante_error_field": "contacts.1.value",
    "dante_field_setter": (answers, q, value) => {
      const dante_obj = q.dante_obj || {type: 'E-Mail'};
      if(!dante_obj.syncType) dante_obj.syncType = 'privat';
      return _.set(answers, 'contacts.1', {...dante_obj, value: value});
    },
  }
}

export const birthConfigs = {
  birth_date: {
    "dante_field": "personalInformation.dateOfBirth"
  },
  birth_place: {
    "dante_field": "personalInformation.placeOfBirth"
  },
};

export const CustomerDataMapping = {
  "A2": salutationConfigs,
  "A201": salutationConfigs,
  "A202": salutationConfigs,
  "A4": personalInformationConfigs.title,
  "A401": personalInformationConfigs.title,
  "A402": personalInformationConfigs.title,
  "A5": personalInformationConfigs.firstName,
  "A501": personalInformationConfigs.firstName,
  "A502": personalInformationConfigs.firstName,
  "A6": personalInformationConfigs.lastName,
  "A601": personalInformationConfigs.lastName,
  "A602": personalInformationConfigs.lastName,
  "A7": addressConfigs.street,
  "A701": addressConfigs.street,
  "A702": addressConfigs.street,
  "A8": addressConfigs.zip,
  "A801": addressConfigs.zip,
  "A802": addressConfigs.zip,
  "A9": addressConfigs.city,
  "A901": addressConfigs.city,
  "A902": addressConfigs.city,
  "A10": addressConfigs.country,
  "A101": addressConfigs.country,
  "A102": addressConfigs.country,
  "A12": legitimationPePConfigs,
  "A1201": legitimationPePConfigs,
  "A1202": legitimationPePConfigs,
  "A14": personalInformationConfigs.birthName,
  "A1401": personalInformationConfigs.birthName,
  "A1402": personalInformationConfigs.birthName,
  "B1": contactsConfigs.mobile,
  "B101": contactsConfigs.mobile,
  "B102": contactsConfigs.mobile,
  "B2": contactsConfigs.email,
  "B201": contactsConfigs.email,
  "B202": contactsConfigs.email,
  "C1": birthConfigs.birth_date,
  "C101": birthConfigs.birth_date,
  "C102": birthConfigs.birth_date,
  "C2": birthConfigs.birth_place,
  "C201": birthConfigs.birth_place,
  "C202": birthConfigs.birth_place,
  "A50": {"dante_field": "personalInformation.lastName"},
  "A51": {
    ...getRelationshipFieldConfiguration(0, 'personalInformation.firstName')
  },
  "A52": {
    ...getRelationshipFieldConfiguration(0, 'personalInformation.lastName')
  },
  "A53": {
    ...getRelationshipFieldConfiguration(1, 'personalInformation.firstName')
  },
  "A54": {
    ...getRelationshipFieldConfiguration(1, 'personalInformation.lastName')
  },
  "J1": {
    "dante_field": "personalInformation.occupationalGroup",
    "config": {
      "1": "12", "2": "19", "3": "17", "4": "7", "5": "8", "6": "6",
      "7": "11", "8": "18", "9": "15", "10": "5", "11": "1", "12": "16", "13": "2",
      "14": "3", "15": "10", "16": "4", "17": "13", "18": "14", "19": "21", "20": "22",
      "21": "9", "22": "20"
    }
  },
  "J2": {
    "dante_field_finder": (q, customer) => {
      const branch = _.get(customer, 'business');
      const option = branch && (q.config.choices || []).find(i => i.text === branch);
      return _.get(option, 'uid');
    },
    "dante_field_setter": (answers, q, value) => {
      const branch = (q.config.choices || []).find(i => i.uid === value);
      return _.set(answers, 'business', _.get(branch, 'text', ''));
    }
  },
  "J3": {
    "dante_field": "personalInformation.highestLevelOfEducation",
    "config": {
      "1": "1", "2": "2", "3": "4", "4": "7", "5": "3", "8": "5", "9": "6"
    }
  }
};