import {INFO_BACKGROUND} from "../../../../themes/themeConfigurator";

const styles = theme => ({
  menuContainer: {
    padding: theme.spacing(2),
    borderRight: '1px solid #D9DCDF',
    height: '100%',
  },
  listItemRoot: {
    color: 'initial',
    fontFamily: 'Roboto-Regular !important',
    '&:hover': {
      cursor: 'initial',
      backgroundColor: 'transparent', // INFO_BACKGROUND,
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: 10,
      paddingBottom: 10
    }
  },
  listItemRootNested: {
    paddingLeft: theme.spacing(4),
  },
  listItemRootClickable: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: INFO_BACKGROUND,
    }
  },
  listItemSelected: {
    fontFamily: 'Roboto-Bold !important',
    '&:not(.nested)': {
      backgroundColor: `${INFO_BACKGROUND} !important`,
    },
    '&.nested': {
      backgroundColor: 'transparent !important',
    }
  }
});

export default styles;