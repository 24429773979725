import CommonAnalysisCard from "../../../../components/AnalysisCard";
import React from "react";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import {formatPercentage} from "../../index";

import PortfolioBreakdownChart from "../../components/PortfolioBreakdownChart";
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../../../../../utils/constants";


class ChartSectorBreakdown extends React.Component {

  renderChart() {
    const { classes } = this.props;

    let data = this.props.data.portfolio_structure.by_currency
    let series = []
    if(data) {
        data.forEach((ac) => {
            series.push({
                 name: '<div class="legend-custom"><span>'+ac.name+'</span><span>'+formatPercentage(ac.weight, 100)+'</span></div>',
                 data: [ac.weight]
            })
        })
        return <PortfolioBreakdownChart series={series}></PortfolioBreakdownChart>
    } else return <div>{DEFAULT_EMPTY_SECTION_MESSAGE}</div>
  }

  render() {
    const { classes } = this.props;

    return <CommonAnalysisCard
        classes={{
          card: classes.root
        }}
        title={'Währungen'}
        loading={this.props.loading}
        allowFloatingMixMax
        content={
          <div className={classes.container}>
            {this.props.error ? this.props.error : this.props.data ? this.renderChart() : DEFAULT_EMPTY_SECTION_MESSAGE}
          </div>
      }
    />
  }
}

export default withStyles(styles)(ChartSectorBreakdown)