import React from 'react';
import _ from 'lodash';

import HighChartBase from '../Base';
import {formatPortfolioName} from "../../../utils/aggregated-portfolio";
import withStyles from "@material-ui/core/styles/withStyles";
import {toShortGermanFormat} from "../../../utils/numberFormater";
import {CHART_MOBILE_VIEW_WIDTH} from "../../../utils/constants";

const getYAxisSettings = (x, opposite=false, linkedTo=undefined) => ({
  title: {
    enabled: false
  },
  opposite,
  linkedTo,
  labels: {
    x,
    formatter: function() {
      return toShortGermanFormat(this.value, '', ' %', 2, true);
    }
  },
  plotLines: [{
    color: '#747474',
    width: 2,
    value: 0,
    zIndex: 10
  }]
})

const PerformanceFixedColumnBarChart = (props) => {
  const {
    data,
    categories
  } = props;

  const [series, setSeries] = React.useState([]);

  React.useEffect(() => {
    setSeries([...generateSeries()]);
  }, [data]);

  const generateSeries = () => {
    if (!_.isUndefined(data)) {
      let series = [];

      for (let i = 0; i < data.length; i++) {

        // Prepare portfolio data
        if (!_.isUndefined(data[i].portfolio)) {
          series.push({
            name: formatPortfolioName(data[i].portfolio.name),
            data: data[i].portfolio.data,
            pointPadding: 0.15,
            visible: true,
            color: data[i].portfolio.color,
            tooltip: {
              valueSuffix: ' %',
              valueDecimals: 2,
              pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y} ({point.inEur:,.2f} €)</b><br/>'
            }
          });
        }
      }

      return series;
    }
  };

  return (
    <HighChartBase options={{
      chart: {
        type: 'column',
        height: 370,
        marginTop: 30,
        // by some reason responsive rule is not applied on first render. As a workaround disable it manually
        events: {
          render: function () {
            if(this.chartWidth < CHART_MOBILE_VIEW_WIDTH){
              this.navigator.update({enabled: false})
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: categories,
        labels: {
          style: {
            fontFamily: 'Roboto-Bold'
          },
        },
        gridLineWidth: 3,
        min: 0,
        max: null,
      },
      yAxis: [getYAxisSettings(-2), getYAxisSettings(4, true, 0)],
      legend: {
        shadow: false,
        enabled: false
      },
      tooltip: {
        shared: false,
      },
      plotOptions: {
        column: {
          grouping: true,
          shadow: false,
          borderWidth: 0
        },
        series: {
          minPointLength: 3,
          dataLabels: {
            enabled: false,
          },
          enableMouseTracking: true
        }
      },
      navigator: {
        enabled: true,
        xAxis: {
          labels: {
            enabled: true,
            formatter: function () {
              const categories = _.get(this, 'chart.xAxis.0.categories');
              return categories[this.value]
            }
          }
        }
      },
      scrollbar: {
        enabled: true
      },
      series: series
    }} language={'DE'}/>
  )
};

export default withStyles(null, {withTheme: true})(PerformanceFixedColumnBarChart);