import React from "react";
import _ from 'lodash';

import {parseResponse} from "../../../utils/api";
import {generateQueryParamsHash, useSharedDataContext} from "../utils";
import {useTabsSharedDataContext} from "../components_v2/TabsSharedData/TabsSharedData";

const SHARED_DATA_KEY = 'extended-assets-data';

export function useExtendedAssetsData(customerId, portfolio, dataProvider) {

  const dataContext = useSharedDataContext();
  const sharedData = useTabsSharedDataContext();

  const fetch = async () => {
    const _setState = (depotNr, depotData) => {
      // It is not possible to sync data with shared data in
      // hook because hook is not executed if component destroyed
      dataContext.setPortfolioData(depotNr, depotData);
      sharedData.setDataValue(`${SHARED_DATA_KEY}-${portfolio.depotNumber}`, hashKey, depotData);
    };

    const hashKey = generateQueryParamsHash(customerId, [portfolio]);

    if(dataContext.isPortfolioDataLoading(portfolio.depotNumber)){
      return;
    }
    dataContext.setPortfolioDataLoading(portfolio.depotNumber, true);
    _setState(portfolio.depotNumber, {
      data: null,
      loading: true,
      errors: null,
      updatedAt: +new Date()
    });

    try {
      const response = await dataProvider.getPortfolioExtendedAssetData(customerId, portfolio.depotNumberForAssets || portfolio.depotNumber);
      parseResponse(response, 'extended_asset_data', (data) => {
        _setState(portfolio.depotNumber, {
          data: data,
          loading: false,
          errors: null,
          updatedAt: +new Date()
        })
      }, (errors) => {
        _setState(portfolio.depotNumber, {
          data: null,
          loading: false,
          errors: errors,
          updatedAt: +new Date()
        })
      }, false)
    } catch (errors) {
      _setState(portfolio.depotNumber, {
        data: null,
        loading: false,
        errors: errors,
        updatedAt: +new Date()
      })
    }

    dataContext.setPortfolioDataLoading(portfolio.depotNumber, false);
  };

  React.useEffect(() => {
    if (!!customerId && !!portfolio) {
      const sharedPortfolioData = !!sharedData
        ? sharedData.getDataValue(`${SHARED_DATA_KEY}-${portfolio.depotNumber}`, generateQueryParamsHash(customerId, [portfolio]))
        : undefined;

      if (!!sharedPortfolioData && !sharedPortfolioData.errors) {
        // sync internal hook data with shared data.
        dataContext.setPortfolioData(portfolio.depotNumber, sharedPortfolioData);
        return;
      }

      // fetch data if there is no shared data or shared data was loaded with error
      // check if data fetched for the first time (updatedAt is undefined) to prevent infinite data reloading
      // in case of data loading error, so data will be requested again only if tab changed
      if (!dataContext.getPortfolioData(portfolio.depotNumber).updatedAt || !sharedPortfolioData) {
        fetch();
      }
    }
  }, [customerId, _.get(portfolio, 'depotNumberForAssets'), _.get(portfolio, 'depotNumber')]);

  return dataContext.getPortfolioData(portfolio.depotNumber)
}