import CommonAnalysisCard from "../../../../components/AnalysisCard";
import React from "react";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import {getErrorMessage, withPercentOrDash} from "../../../../../../utils/utils";
import moment from "moment";
import clsx from "clsx";
import ExplanationTooltip from "../../../../../../components/ExplanationTooltip";
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../../../../../utils/constants";

class CumulativeReturnWidget extends React.Component {

    componentDidUpdate(prevProps) {
        if (this.props.data && this.props.data !== prevProps.data) {
            this.renderData()
        }
    }

    renderErrorMessage(error) {
      const { classes } = this.props;
      return <p className={classes.emptyMessage}>{getErrorMessage(error)}</p>
    }

    renderData() {
        const { classes } = this.props;

        return (
            <ul className={classes.list}>
                <li className={[classes.item, classes.headline].join(' ')}>
                  <span className={classes.title}></span>
                  <span className={classes.values}>Annualisiert</span>
                  <span className={classes.values}>Kumuliert</span>
                </li>
                <li className={classes.item}>
                    <span className={classes.title}>1 Monat</span>
                    <span className={classes.values}>-</span>
                    <span className={classes.values}>{withPercentOrDash(this.props.data['cum_ret_g_1m'])}</span>
                </li>
                <li className={classes.item}>
                    <span className={classes.title}>3 Monate</span>
                    <span className={classes.values}>-</span>
                    <span className={classes.values}>{withPercentOrDash(this.props.data['cum_ret_g_3m'])}</span>
                </li>
                <li className={classes.item}>
                    <span className={classes.title}>6 Monate</span>
                    <span className={classes.values}>-</span>
                    <span className={classes.values}>{withPercentOrDash(this.props.data['cum_ret_g_6m'])}</span>
                </li>
                <li className={classes.item}>
                    <span className={classes.title}>lfd. Jahr</span>
                    <span className={classes.values}>-</span>
                    <span className={classes.values}>{withPercentOrDash(this.props.data['cum_ret_g_ytd'])}</span>
                </li>
                <li className={classes.item}>
                    <span className={classes.title}>1 Jahr</span>
                    <span className={classes.values}>-</span>
                    <span className={classes.values}>{withPercentOrDash(this.props.data['cum_ret_g_1y'])}</span>
                </li>
                <li className={classes.item}>
                    <span className={classes.title}>3 Jahre</span>
                    <span className={classes.values}>{withPercentOrDash(this.props.data['ann_ret_3y'])}</span>
                    <span className={classes.values}>{withPercentOrDash(this.props.data['cum_ret_g_3y'])}</span>
                </li>
                <li className={classes.item}>
                    <span className={classes.title}>5 Jahre</span>
                    <span className={classes.values}>{withPercentOrDash(this.props.data['ann_ret_5y'])}</span>
                    <span className={classes.values}>{withPercentOrDash(this.props.data['cum_ret_g_5y'])}</span>
                </li>
                <li className={classes.item}>
                    <span className={classes.title}>10 Jahre</span>
                    <span className={classes.values}>{withPercentOrDash(this.props.data['ann_ret_10y'])}</span>
                    <span className={classes.values}>{withPercentOrDash(this.props.data['cum_ret_g_10y'])}</span>
                </li>
                <li className={classes.item}>
                    <span className={classes.title}>seit Auflage <br /> am {moment(this.props.data['tracking_start_date']).format('DD.MM.YYYY')}</span>
                    <span className={classes.values}>{withPercentOrDash(this.props.data['cum_ret_g_inception_pa'])}</span>
                    <span className={classes.values}>{withPercentOrDash(this.props.data['cum_ret_g_inception'])}</span>
                </li>
            </ul>
        )
    }

    render() {
        const { classes } = this.props;

        return <CommonAnalysisCard
            classes={{
              card: classes.root
            }}
            title={(
              <>
                <span>Wertentwicklung in %</span>
                <ExplanationTooltip
                  tooltipKey={'performance_table'}
                  customClasses={{helpIcon: this.props.classes.helpIconMiddle}}
                />
              </>
            )}
            loading={this.props.loading}
            content={
              <div className={clsx(classes.container, this.props.error ? classes.containerError : this.props.data ? '' : classes.containerError)}>
                {this.props.error ? this.renderErrorMessage(this.props.error) : this.props.data ? this.renderData() : this.renderErrorMessage(DEFAULT_EMPTY_SECTION_MESSAGE)}
              </div>
            }
            expanded={this.props.expanded}
            onExpandedClick={this.props.onExpanded}
        />
      }
}

export default withStyles(styles)(CumulativeReturnWidget)