import React from "react";
import _ from "lodash";
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";

import {
  getCustodianData,
  getStepAnswer,
  getUID,
  isQuestionValid,
  validateCustomer,
  scrollToFirstElementOfClass,
  checkMinor,
  isESGEnabled, isNewDesignProcess, showSkipRequiredWarning,
} from "../../../RiskProfiling/utils";
import {brokerLogout} from "../../../Authentication/Auth/utils";
import Snackbar from "../../../../components/Snackbar";
import Navigation from "../../../RiskProfiling/components/Navigation";
import {TradeMenuItem} from "../TradeMenuItem";
import { ExAnteTradeMenuItem } from '../ExAnteTradeMenuItem'
import {SERVICE_CONCEPTS} from "../../constants";
import { combinedTradeStepsData } from "../../trade_constants";
import { exAnteStepsData } from '../../exante_constants'
import {getCustomerTradings, getCustomerTradingType} from "../../../../components/TradingStore/utils";
import {executeIfPathExist, getInvestmentDynamicPath} from "../../../InvestmentPlatform/utils";
import {QuestionnairesHandlerResource} from "../../../../utils/api";
import {CustomerMenuItem} from "../CustomerMenuItem";
import {ProtocolMenuItem} from "../ProtocolMenuItem";
import {TradeDetailsMenuItem} from "../TradeDetailsMenuItem"
import {RiskProfileMenuItem} from "../RiskProfileMenuItem";
import {ESGMenuItem} from "../ESGMenuItem";
import {
  CLEARING_ACCOUNT_BALANCING_QUESTION_UID, CLEARING_ACCOUNT_OPTION_QUESTION_UID,
  CLEARING_ACCOUNT_QUESTION_UID, CONFIRM_REMOVE_MSG,
  OBJECT_TYPES
} from "../../../RiskProfiling/constants";

import {SERVER_ERROR} from '../../../../utils/constants'
import {TRADING_TYPE} from "../../../../components/TradingStore/constants";
import { removeAllCombinedTradings } from "../../../../components/TradingStore/actions";
import ConfirmationDialog from "../../../VirtualPortfolioManager/modals/ConfirmationDialog";
import {displayWarningSnackBar} from "../../../../components/SnackbarProvider/actions";
import {setRefreshHeaderTrigger} from "../../../InvestmentPlatform/actions";
import {PrimaryButton} from "../../../../components/Buttons";
import ServiceConceptSelection from "../../../RiskProfiling/components/ServiceConceptSelection";
import {ArrowBack} from "@material-ui/icons";
import {clearingAccountToBankAccount, DUMMY_ACCOUNT_HOLDER, DUMMY_ORDER} from "../../utils";
import { getCombinedTradingSelector } from "../../../../components/TradingsDataProvider/TradingsDataProvider";
import {PROOF_ID} from "../../../RiskProfiling/mock_custody_certificate";
import {getAuthSelector, getInvestmentPlatformSelector} from "../../../../utils/redaxSelectors";
import {CustomerAccountMenuItemV2} from "../servicesV2/CustomerAccountMenuItemV2";
import {CustomerMenuItemV2} from "../servicesV2/CustomerMenuItemV2";
import {TradingProtocolDataServiceV2} from "../servicesV2/TradingProtocolServiceV2";
import {useConfirmationModalContext} from "../../../../components/ConfirmationModalContextProvider";
import MissingFieldsSummary from "../../../RiskProfiling/components/MissingFieldsSummary";


const mapStateToProps = (state) => ({
  auth: getAuthSelector(state),
  investmentPlatform: getInvestmentPlatformSelector(state),
  combinedTrading: getCombinedTradingSelector(state),
});

let currService;

const TradesDataProvider = WrappedComponent => {
  return withRouter(connect(mapStateToProps)(function dataProvider(props) {
    const {
      match: {
        params: {
          customer_id
        }
      },
      combinedTrading,
      auth: {
        user
      },
      newDesign: useNewDesignForNewSessions,
    } = props;

    const FIRST_MENU_IDX = 1;
    const objectType = OBJECT_TYPES.COMBINED;
    let portfolios = getCustomerTradings(combinedTrading.customers, customer_id);
    let headerTitle = "Portfolio handeln";
    let customerTradingType =  getCustomerTradingType(combinedTrading.customers, customer_id);
    let esgEnabled = isESGEnabled();
    const modalContext = useConfirmationModalContext();

    const _getStepAnswer = (onboarind_step_uid, step_uid, question_uid) => {
      const menu = menuItems.find(menu => menu.uid === onboarind_step_uid);
      if(menu){
        return question_uid ? menu.getStepAnswer(step_uid, question_uid) : menu;
      }
    };

    const handleCreateOrderClick = (validate, raiseExceptions=true) => {

      const buildClearingAccount = (cAccount) => {
        if (!cAccount) return undefined;

        return {
          number: cAccount.account_number,
          saldo: cAccount.value,
          iban: cAccount.iban,
          date: cAccount.value_date
        }
      }

      /**
       * Send request to create order.
       *
       * @param data
       * @param transaction Transaction data
       * @param _ordersDataKey
       * @param _objectType
       * @return {Promise<unknown>}
       * @private
       */
      async function __sendRequest(data, transaction, _ordersDataKey, _objectType) {
        let clearingAccountAsBankAccount = false;
        const portfolio = _.get(data, `portfolios.${transaction.portfolioId}`) || {};

        try {

          const clientDetails = data.client_details;

          let usePortfolioClearingAccount = _.get(
            clientDetails, `${CLEARING_ACCOUNT_QUESTION_UID}.portfolio_${transaction.portfolioId}`);
          let usePortfolioClearingAccountBalancing = _.get(
            clientDetails, `${CLEARING_ACCOUNT_BALANCING_QUESTION_UID}.portfolio_${transaction.portfolioId}`);
          let clearingAccountId = _.get(
            clientDetails,`${CLEARING_ACCOUNT_OPTION_QUESTION_UID}.portfolio_${transaction.portfolioId}`);

          clearingAccountAsBankAccount = usePortfolioClearingAccount
            && !portfolio.orderClearingAccountAllowed
            && portfolio.orderClearingAccountToBankAccountAllowed;
          if (clearingAccountAsBankAccount) {
            usePortfolioClearingAccount = usePortfolioClearingAccountBalancing = false;
            clientDetails.bank_account = clearingAccountToBankAccount(
              portfolio.clearing_account[clearingAccountId], portfolio.data || {});
          }

          const body = {
            customer_id,
            onboarding_uid: currService.onboarding_uid,
            validate,
            bank: transaction.bank,
            depot_number: transaction.depotNumber,
            portfolio_id: transaction.portfolioId,
            depot_type_id: transaction.depotTypeId,
            custodian_id: transaction.custodian_id,
            data: {
              [_ordersDataKey]: transaction.order_details,
              client_details: {
                ...(_.isEmpty(clientDetails) ? DUMMY_ACCOUNT_HOLDER : clientDetails),
                depot: transaction.depotNumber,
                use_clearing_account: usePortfolioClearingAccount,
                clearing_account: usePortfolioClearingAccount
                  ? buildClearingAccount(portfolio.clearing_account[clearingAccountId])
                  : undefined,
                account_details: data.account_details,
                [PROOF_ID]: data[PROOF_ID],
              },
              signature_places: data.signature_places
            },
          };

          if (_objectType === OBJECT_TYPES.TRADING && usePortfolioClearingAccountBalancing) {
            body.data.client_details['allow_clearing_account_balancing'] = true
          }

          const path = {
            [OBJECT_TYPES.TRADING]: 'create-order/',
            [OBJECT_TYPES.SAVINGS_PLAN]: 'create-savings-plans/',
            [OBJECT_TYPES.PAYOUT_PLAN]: 'create-payout-plans/',
            [OBJECT_TYPES.SWITCH_PLAN]: 'create-switch-plans/'
          }[_objectType]

          return await QuestionnairesHandlerResource.at(path).post(body);

        } catch (error) {

          if (raiseExceptions) {
            throw error
          }

          return {
            error,
            clearingAccountAsBankAccount,
            portfolio: {
              depotNumber: transaction.depotNumber,
              depotName: transaction.depotName,
              portfolioId: portfolio.portfolioId,
            },
          };

        }

      }

      let data = {};
      menuItems.map(menuItem => {
        if(!validate || (menuItem.isDone || currService === menuItem)) {
          const userData = menuItem.getDataForAccount(validate) || {};
          _.merge(data, userData)
        }
      });

      let requests = [];

      // For combined trading we group transactions by transaction types,
      // so instead of list we have a dict
      if (validate && _.isEmpty(data.transactions)){
        // DUMMY TRANSACTION
        data.transactions = {trading: [DUMMY_ORDER]};
      }

      Object.keys(data.transactions).forEach((transactionType) => {
        if (_.isEmpty(data.transactions[transactionType])) {
          return
        }

        const [ordersDataKey, _objectType] = {
          trading: ['order_details', OBJECT_TYPES.TRADING],
          savings_plan: ['saving_plan_details', OBJECT_TYPES.SAVINGS_PLAN],
          payout_plan: ['payout_plan_details', OBJECT_TYPES.PAYOUT_PLAN],
          switch_plan: ['switch_plan_details', OBJECT_TYPES.SWITCH_PLAN]
        }[transactionType]

        requests = requests.concat(data.transactions[transactionType]
          .map(transaction => __sendRequest(data, transaction, ordersDataKey, _objectType)))
      })

      return Promise.all(requests)
    };

    const handleValidateOrderClick = (raiseExceptions=false) => {
      return handleCreateOrderClick(true, raiseExceptions);
    };

    const _handleDeleteSession = async () => {
      try {
        await QuestionnairesHandlerResource.deleteQuestionnaire(currService.onboarding_uid);
        // Cleaning all configured transaction for customer
        props.dispatch(removeAllCombinedTradings(customer_id));
        goBackToPreviousRout();
      } catch (error) {
        handleError(error);
      } finally {
        setDeleteTradeModalOpen(false);
      }
    };

    const [tradingType, setTradingType] = React.useState(customerTradingType || TRADING_TYPE.NORMAL);
    const [initialPortfolios, setInitialPortfolios] = React.useState(portfolios);
    const [deleteTradeModalOpen, setDeleteTradeModalOpen] = React.useState(false);

    const extraMenuItems = React.useRef([
      new CustomerMenuItem('user_data', 'Kundendaten', customer_id, _getStepAnswer, handleValidateOrderClick),
      new ProtocolMenuItem('protocol', 'Aufklärung & Protokoll', customer_id, _getStepAnswer, handleCreateOrderClick)
    ]);
    const extraStepsUIDs = ['user_data', 'user_account', 'protocol', 'risk_profile', 'custody_certificate'];

    if (esgEnabled) {
      extraStepsUIDs.push('esg_profile');
    }

    const [serviceConcept, setServiceConcept] = React.useState(null);
    const [serviceConcepts, setServiceConcepts] = React.useState([
      {id: SERVICE_CONCEPTS.Anlageberatung, name: 'Anlageberatung', menuItems: [
        new RiskProfileMenuItem('risk_profile', 'Risikoprofilierung', customer_id,
          esgEnabled ? "Weiter zum Nachhaltigkeitsprofil" : "Weiter zur Kundendaten",
          "Das Risikoprofil des Kunden wurde erfolgreich gespeichert."),
            ...(
          esgEnabled ? [
              new ESGMenuItem('esg_profile', 'NACHHALTIGKEITSPROFIL', customer_id,
          'Weiter zu Handeln Details',
          "Das Risikoprofil des Kunden wurde erfolgreich gespeichert."),
          ] : []
            ),
      ]},
      {id: SERVICE_CONCEPTS.Anlagevermittlung, name: 'Anlagevermittlung', menuItems: [
        new RiskProfileMenuItem('risk_profile', 'Kenntnisse & Erfahrungen', customer_id,
          "Zum Kundendaten",
          "Sie haben den prozess erfolgreich abgeschlossen.")
      ]},
      {id: SERVICE_CONCEPTS.ExecutionOnly, name: 'Execution-Only', menuItems: []},
    ]);

    const [menuItems, setMenuItems] = React.useState([
      new TradeMenuItem('products_selection', 'Produktauswahl', customer_id),
      new TradeDetailsMenuItem('trade', 'Handeln details', customer_id, combinedTradeStepsData,
        () => setDeleteTradeModalOpen(true)),
      new ExAnteTradeMenuItem('ex_ante_cost', 'Überprüfung', customer_id, exAnteStepsData, _getStepAnswer)
    ]);
    const [riskProfile, setRiskProfile] = React.useState(null);
    const [tradingData, setTradingData] = React.useState({});
    const [latestFinished, setLatestFinished] = React.useState(null);
    const [step, setStep] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(undefined);
    const [currMenu, setMenu] = React.useState(FIRST_MENU_IDX);
    const [missingFieldsData, setMissingFieldsData] = React.useState(undefined);

    const [investmentDynamicPath, setInvestmentDynamicPath] = React.useState(getInvestmentDynamicPath())

    const validateMissingData = () => {
      const missingData = [];
      menuItems.forEach(menuItem => {
        const missingMenuData = menuItem.getQuestionsWithoutAnswers();

        if(!_.isEmpty(missingMenuData)){
          missingData.push({
            uid: menuItem.uid,
            name: menuItem.name,
            steps: missingMenuData
          })
        }
      });

      if(!_.isEmpty(missingData)) {
        setMissingFieldsData(missingData);
        return false;
      } else {
        setMissingFieldsData(undefined);
        return true;
      }
    };

    const navigateToPrevMenu = async (menuUID, stepUID) => {
      const _currMenu = menuItems.findIndex(menu => menu.uid === menuUID);
      await _setCurrService(_currMenu, true, stepUID);
      setMenu(_currMenu);
      setMissingFieldsData(undefined);
    };

    const goBackToPreviousRout = () => {
      // if you start the process, clear browser history, props.history.length would be set, but you won't be able to redirect => use window.history
      let hasHistory = window.history.length > 1

      if(!hasHistory) {
        // redirects to customer search of dashboard
        executeIfPathExist(props.investmentPlatform.routes, 'DASHBOARD', path => {
          props.history.push(`/${investmentDynamicPath}${path}`)
        })
      }
      else{
        props.history.goBack()
      }
    };

    const addServiceConceptMenuItems = (sc_id) => {
      const currServiceConcept = serviceConcepts.find(sc => sc.id == sc_id);
      if(currServiceConcept){
        setServiceConcept(sc_id);
        setMenuItems(items => {
          // if some current menuItems are in extraSteps -> filter them out to prevent duplication
          const defaultMenuItems = items.filter(i => !extraStepsUIDs.includes(i.uid));
          // pop product selection menu item from array
          const productSelectionMenuItem = defaultMenuItems.shift()
          // defines proper order of menu items
          return [productSelectionMenuItem, ...currServiceConcept.menuItems, ...defaultMenuItems, ...extraMenuItems.current];
        });
      }
    };

    React.useEffect(() => {
      currService = undefined;
      initData().catch(handleError);
    }, []);

    React.useEffect(() => {

      const _getStepAnswer = (onboarind_step_uid, step_uid, question_uid, withOption, asQuestion=false) => {

        const menu = menuItems.find(menu => menu.uid === onboarind_step_uid);
        if(menu){
          return menu.getStepAnswer(step_uid, question_uid, withOption, asQuestion);
        }
      };

      menuItems.forEach(item => {
        item._getPrevStepAnswer = _getStepAnswer;
        if(_.isFunction(item.handleCreateOrderClick)){
          item.handleCreateOrderClick = handleCreateOrderClick;
        }
        if(_.isFunction(item._validateAccountFunc)){
          item._validateAccountFunc = handleValidateOrderClick;
        }
        if(_.isFunction(item._validateMissingData)){
          item._validateMissingData = validateMissingData;
        }
      })
    }, [menuItems]);  // ToDo check why there are two useEffects for menuItems

    React.useEffect(() => {
      if(!_.isEmpty(tradingData)) {
        // update state will go to next menu if curr menu is finished
        initWithTradingData().catch(handleError);
      }
    }, [menuItems]);

    React.useEffect(() => {
      props.dispatch(setRefreshHeaderTrigger());
    }, [currMenu]);

    // callback on tradeData loaded and set to state
    React.useEffect(() => {
      if(!_.isEmpty(tradingData)){

        if (tradingData.skip_init) {
          return
        }

        if(!tradingData.custom_change){
          const currentTradeAnswers = _.get(tradingData.onboarding_answers, 'trade.trade-step');

          if(!_.isEmpty(portfolios)){
            // store portfolios from Overview
            QuestionnairesHandlerResource.post({
              customer_id: customer_id,
              onboarding_step_uid: 'products_selection',
              onboarding_uid: tradingData.session_id,
              step_uid: 'portfolios',
              answers: portfolios
            }).then(() => {
              // store trading type
              QuestionnairesHandlerResource.post({
                customer_id: customer_id,
                onboarding_step_uid: 'products_selection',
                onboarding_uid: tradingData.session_id,
                step_uid: 'trading_type',
                answers: [tradingType || TRADING_TYPE.NORMAL]
              });
            });
          } else if (_.isEmpty(currentTradeAnswers)) {
            // get portfolios and trading type from backend in case we don't have trade details answer
            portfolios = _.get(tradingData.onboarding_answers, 'products_selection.portfolios', []);
          }


          if(_.isEmpty(portfolios) && _.isEmpty(currentTradeAnswers)) {
            // redirect to customer dashboard
            goBackToPreviousRout();
          }

          setInitialPortfolios(portfolios);

          // Get trading type from onboarding, if it already exists and not comming from dashboard
          if (!customerTradingType) {
            let cTradingType = _.get(tradingData.onboarding_answers, 'products_selection.trading_type', [TRADING_TYPE.NORMAL]);
            cTradingType = (Array.isArray(cTradingType) && cTradingType.length) ? cTradingType[0] : cTradingType
            if (currService.hasOwnProperty('_trading_type')) {
              currService.tradingType = cTradingType
            }
            setTradingType(cTradingType)
          }
        }
        if(tradingData.service_concept){
          addServiceConceptMenuItems(tradingData.service_concept);
        } else {
          setLoading(false);
        }
      }
    }, [tradingData]);

    React.useEffect(() => {
      validateQuestions(true)
    }, [JSON.stringify(step)]);

    React.useEffect(() => {
      menuItems.forEach(item => {
        if (['risk_profile', 'user_confirmation'].includes(item.uid)) {
          item.onLegalDocumentsButtonClick = onDownloadLegalDocumentClick
          item._syncQuestions()
        }
      })
    }, [step])

    const initWithTradingData = async () => {
      let currentStep = currMenu;
      if (tradingData.current_step){
        let menuIdx = menuItems.findIndex(i => i.uid === tradingData.current_step);
        if(menuIdx !== -1){
          // skip hidden steps and just init them
          if(menuIdx >= FIRST_MENU_IDX) {
            setMenu(menuIdx);
            currentStep = menuIdx;
          }
          for(let i = 0; i < currentStep; i++) {
            // we need to init all prev steps with data from Onboarding
            await initService(i, true);
          }
        }
      }
      // init with Last Finished ONLY on restore progress
      await _setCurrService(currentStep, false, tradingData.last_finished_sub_step);
    };

    const _setCurrService = async (idx, isBack, lastFinishedStep) => {
      if(_.isNumber(idx)) {
        setLoading(true);
        try {
          await initService(idx, false, isBack, lastFinishedStep);
          await updateState(isBack);
          setLoading(false);
        } catch (error) {
          handleError(error);
        }
      }
    };

    async function onDownloadLegalDocumentClick() {
      if (validateQuestions()) {
        try {
          setLoading(true);
          await currService.nextStep(true);
          setLoading(false);
          return true
        } catch (error) {
          handleError(error);
          return false
        }
      } else {
        return false
      }
    }

    const initService = async (idx, initOnly, isBack, lastFinishedStep) => {
      let prevService = currService

      currService = menuItems[idx];
      currService.user = user;
      currService.serviceConcept = serviceConcept
      currService.customer_type = prevService && prevService.customer_type
      currService.bank = prevService && prevService.bank
      currService.members = prevService && prevService.members
      currService.current_customer = prevService && prevService.current_customer
      currService.objectType = objectType
      if(serviceConcept){
        const lastProcess = (latestFinished || []).find(l => l.service_concept == serviceConcept);
        currService.prevProcessAnswers = lastProcess && lastProcess.onboarding_answers;
      }
      currService.is_trading = true
      if (currService.hasOwnProperty('_trading_type')) {
        currService.tradingType = tradingType
      }
      if(isBack) {
        currService.finished = false;
        if(currService.step && currService.step.uid === 'congrats-step'){
          // as there is no back button in congrats step
          await currService.prevStep();
        }
        // store current step
        await currService.sendCurrentStep();

        return;
      }

      currService.onboarding_uid = tradingData.session_id;

      switch (currService.uid) {
        case 'trade':
          // getting bank data from CIOS for discounts
          const banksCIOSData = await QuestionnairesHandlerResource.getBanksData();

          // getting custodian instruments data from CIOS
          const instruments = _.flatten(initialPortfolios.map(ptf => {

            // In case trading for Model portfolio and savings plans process is
            // running (portfolio has base_components) - use base_components
            // to get list of portfolio's instruments
            let instruments = (ptf.isModelportfolio && (_.get(ptf, 'data.base_components') || []).length)
              ? _.get(ptf, 'data.base_components').map((instrument) => ({data: {...instrument}}))
              : ptf.instruments

            return _.flatten(_.flatten(instruments.map(i => {
              // for switch plans each plan has list of switch in instruments inside components
              if (!_.isEmpty(i.data.components)) {
                return [i.data,  i.data.components.map(c => c.hasOwnProperty('data') ? c.data : c)]
              }
              return i.data
            })))
          }));
          const bankInstrumentsData = await getCustodianData(instruments, initialPortfolios.map(ptf => ptf.companyId));

          currService.setQuestionData('trade-step', 'transactions',
            {
              portfolios: initialPortfolios,
              banksData: banksCIOSData.response,
              bankInstrumentsData: bankInstrumentsData
            });

          break;

        case 'risk_profile':
          currService.is_investment_questionnaire = serviceConcept === SERVICE_CONCEPTS.Anlagevermittlung;
          if(serviceConcept === SERVICE_CONCEPTS.Anlageberatung) {
            // init service session
            if(tradingData.is_risk_session) {
              currService.session_id = tradingData.risk_session;
            }
            if (!currService.session_id && riskProfile){
              currService.session_id = riskProfile.session_id;
              currService.srri = riskProfile.srri;
            }
            // set final SRRI to answers
            if (tradingData.srri) {
              tradingData.onboarding_answers.srri = tradingData.srri;
            }
          }

          break;
      }
      // TODO: Remove session id usage from onboarding/trading
      if(!currService.session_id) {
        currService.session_id = prevService && prevService.session_id;
      }
      await currService.initQuestionnaire(tradingData.onboarding_answers, initOnly, lastFinishedStep);
      if(currService.warning){
        props.dispatch(displayWarningSnackBar(currService.warning));
        delete currService.warning;
      }
    };

    const updateState = async () => {
      if(currService.finished) {
        setLoading(true);
        await _nextMenu();
        setLoading(false);
      }
      setStep(currService.step);
    };

    const initData = async () => {
      setLoading(true);
      if(!customer_id) brokerLogout();
      await initService(0, true);

      const newStepsForServiceConcepts = [];
      // get customer details - to check if customer exists
      const customer = await validateCustomer(customer_id, props.dispatch);
      const custodyService = checkMinor(customer, props.dispatch, true);
      if(custodyService){
        custodyService.customer_id = customer_id;
        newStepsForServiceConcepts.push(custodyService)
      }

      const res = await QuestionnairesHandlerResource.getLatestSession(customer_id, objectType, !_.isEmpty(portfolios));

      // handle no last session data. eg reload after finished session
      if (!_.get(res, 'current_step') && !_.get(res, 'onboarding_answers')) {
        goBackToPreviousRout();
        return;
      }

      const isNewSession = _.isEmpty(res.onboarding_answers);
      if((useNewDesignForNewSessions && isNewSession) || isNewDesignProcess(res)) {
        console.log('Welcome to Trading v2.0!');
        if (isNewSession) {
          // init new session with dummy step to mark new processes
          await QuestionnairesHandlerResource.post({
            customer_id: customer_id,
            onboarding_uid: res.session_id,
            onboarding_step_uid: 'new_design_dummy_step',
            step_uid: 'new_design',
            answers: true
          });
        }

        newStepsForServiceConcepts.push(new CustomerMenuItemV2('user_data', 'Stammdaten', customer_id, _getStepAnswer, handleValidateOrderClick))
        // replace user_data with account_data step
        extraMenuItems.current = extraMenuItems.current.map(menu => {
          if(menu.uid === 'user_data'){
            menu = new CustomerAccountMenuItemV2('user_account', 'Konto', customer_id, _getStepAnswer, handleValidateOrderClick);
          } else if (menu.uid === 'protocol'){
            menu = new TradingProtocolDataServiceV2(menu.uid, menu.name, customer_id, _getStepAnswer, handleCreateOrderClick, handleValidateOrderClick, validateMissingData)
          }

          return menu
        })
      }

      if(!_.isEmpty(newStepsForServiceConcepts)){
        setServiceConcepts(scConcepts => {
          return scConcepts.map(sc => {
            sc.menuItems.unshift(...newStepsForServiceConcepts);

            return sc;
          });
        })
      }

      // set service will be called in useEffect below when tradingData changed
      setTradingData(res);

      try {
        let res = await QuestionnairesHandlerResource.at(`${customer_id}/get-last-srri/`).get();
        setRiskProfile(res);
      } catch (e) {
        // risk profile doesn't exist
      }
      try {
        let lastFinishedProcesses = await QuestionnairesHandlerResource.getLatestFinishedByServiceConcepts(customer_id, objectType);
        if(lastFinishedProcesses){
          setLatestFinished(lastFinishedProcesses);
        }
      } catch (error) {
        // not critical as we use it just to pre-fill
      }
    };

    const _nextMenu = async () => {
      if (currMenu < menuItems.length - 1){
        const _currMenu = currMenu + 1;
        await _setCurrService(_currMenu);
        setMenu(_currMenu);
      } else {
        // this is last step
        console.log('You have finished Trading.');

        // Cleaning all configured transaction for customer after process was finished
        props.dispatch(removeAllCombinedTradings(customer_id));

        executeIfPathExist(props.investmentPlatform.routes, 'ESIGN_STATUS', path => {
          props.history.push(`/${investmentDynamicPath}${path}`)
        })
      }
    };

    const _prevMenu = async () => {
      if(currMenu > FIRST_MENU_IDX){
        const _currMenu = currMenu - 1;
        await _setCurrService(_currMenu, true);
        setMenu(_currMenu);
      } else {
        setServiceConcept(null)
      }
    };

    const handleNextClick = async () => {
      if (validateQuestions() && (!currService.checkHasMissingAnswers(step) || await showSkipRequiredWarning(modalContext, tradingData.session_id))) {
        try {
          setLoading(true);
          const latestOnboardingData = await currService.nextStep();
          if (latestOnboardingData && latestOnboardingData.is_onboarding) {
            setTradingData({
              ...latestOnboardingData,
              skip_init: true
            })
          }
          // for service concept step - updateState will be fired after menu items updated (in useEffect)
          if(step.uid === 'service-concept-step'){
            currService.checkServiceConcept();
          } else {
            await updateState();
            setLoading(false);
          }
        } catch (error) {
          handleError(error);
        }
      } else{
        scrollToFirstElementOfClass()
      }
    };

    const handleConfirmResultClick = async (data) => {
      try {
        // this comparios needed for cases, when menu has more then one confirmation steps
        let isDone = currService.isDone;
        if (!isDone) {
          setLoading(true);
        }
        await currService.confirmResult(data);
        await updateState();
        // this comparios needed for cases, when menu has more then one confirmation steps
        if (!isDone) {
          setLoading(false);
        }
      } catch (error) {
        handleError(error);
      }
    };

    const handleGoToStepClick = async (step_uid) => {
      try {
        setLoading(true);
        await currService.goToStep(step_uid);
        await updateState();
        setLoading(false);
      } catch (error) {
        handleError(error);
      }
    };

    const handlePrevClick = async () => {
      try {
        setLoading(true);
        if(currService.isFistStep){
          await _prevMenu();
        } else {
          await currService.prevStep();
          await updateState();
        }
        setLoading(false);
      } catch (error) {
        handleError(error);
      }
    };

    const handleResendResultClick = async () => {
      try {
        setLoading(true);
        await currService.resendResult();
        await updateState();
        setLoading(false);
      } catch (error) {
        handleError(error);
      }
    };

    const validateQuestions = (afterChange=false) => {

      let isValid = true;

      if (step && step.question) {
        let questions = [...step.question]

        questions.forEach(q =>  {

          if (afterChange && !q.validateImmediately) {
            return
          }

          step.tradingType = tradingType

          let isQValid = isQuestionValid(q, step, afterChange, currService)

          isValid = !isValid ? false : isQValid
        })

        setStep({
          ...step,
          question: questions
        })
      }

      return isValid
    }

    const handleStepAnswerChange = (uid, answer) => {
      setStep((prevStep) => ({
        ...prevStep,
        question: prevStep.question.map(question => {
          if (question.uid === uid) {
            question.answer = answer;
          }

          if (question.question) {
            question.question = question.question.map(question => {
              if (question.uid === uid) {
                question.answer = answer;
              }
              return question;
            })
          }

          return question;
        })
      }));

      // trigger goNext after SC set
      if(uid === 'service_concept' && !!answer) {
        handleNextClick();
      }
    };

    const handleError = (error) => {
      setLoading(false);

      // For testing purposes. Remove later.
      console.log(error)

      function parseError(errorData) {
        if(errorData.errors){
          currService.setFormErrors(errorData.errors);
        }

        if(errorData.detail){
          setError(errorData.detail)
        } else if (errorData.message) {
          setError(errorData.message);
        } else {
          setError(typeof error === 'string' ? error : SERVER_ERROR)
        }
      }

      switch (error.status) {
        case 401:
        case 403:
          brokerLogout();
          return;
        default:
          if (error.data) {
            parseError(error.data);
          } else {
            parseError(error);
          }
      }
    };

    const blockGoBack = currService && currService.goBackDisabled || false;

    // Jump from Anlageberatung to Anlagevermittlung functionality
    const handleJumpToAnlagevermittlung = async () => {
      // add answers for Anlagevermittlung and clean "current_step"
      const _getStepData = (stepUID) => {
        const step = currService.getStep(stepUID);

        return step && _.get(getStepAnswer(step), 'answers');
      };

      // start from beginning
      setMenu(FIRST_MENU_IDX);
      // add answers for Anlagevermittlung and clean "current_step"
      const data = {...tradingData, current_step: undefined, custom_change: true};
      _.set(data, "onboarding_answers.risk_profile.K1", _getStepData('A2'));
      _.set(data, "onboarding_answers.risk_profile.K2", _getStepData('A3'));

      setTradingData(data);

      await handleServiceConceptChanged(SERVICE_CONCEPTS.Anlagevermittlung)
    };

    let rightButtons = [];
    if (serviceConcept === SERVICE_CONCEPTS.Anlageberatung && ['A5', 'A7'].includes(getUID(_.get(currService, 'step.uid', '')))) {
      rightButtons = [
        <PrimaryButton text={"Abbrechen und zu Anlagevermittlung wechseln"}
            onButtonClick={handleJumpToAnlagevermittlung}
        />
      ]
    }

    const handleServiceConceptChanged = async (sc_id) => {
      const currServiceConcept = serviceConcepts.find(sc => sc.id == sc_id);

      await QuestionnairesHandlerResource.post({
        customer_id: customer_id,
        onboarding_uid: tradingData.session_id,
        onboarding_step_uid: 'ex_ante_cost',
        step_uid: 'service-concept-step',
        answers: [{
          'answer': [{'id': currServiceConcept.id, 'uid': currServiceConcept.id, 'text': currServiceConcept.name}],
          'question_uid': 'service_concept'
        }]
      });
      if (sc_id != SERVICE_CONCEPTS.ExecutionOnly) {
        setTradingData((tradingData) => ({
          ...tradingData,
          current_step: currServiceConcept.menuItems[0].uid,
          skip_init: true
        }))
      }
      addServiceConceptMenuItems(sc_id)
    };

    // show Service concept screen ONLY after trading data loaded
    if (!loading && serviceConcept === null && !_.isEmpty(tradingData)) {
      return <ServiceConceptSelection
        onServiceConceptChange={handleServiceConceptChanged}
        footerContent={(
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', paddingTop: 45}}>
            <PrimaryButton
              text={"Zurück"}
              icon={<ArrowBack color={'primary'}/>}
              variant={"outlined"}
              onButtonClick={() => goBackToPreviousRout()}
            />
          </div>
        )}
      />
    }

    const answersData = _.get(tradingData, 'onboarding_answers');
    const isNewDesign = useNewDesignForNewSessions || isNewDesignProcess(tradingData);

    const onDeleteClick = () => setDeleteTradeModalOpen(true);

    if(isNewDesign){
      const deleteBtn = (
        <PrimaryButton
          text={"Abbrechen"}
          color={'secondary'}
          variant={'outlined'}
          onButtonClick={onDeleteClick}
        />
      );
      const backButton = (
        <PrimaryButton
          text={"Zurück"}
          variant={"outlined"}
          onButtonClick={handlePrevClick}
        />
      );
      rightButtons = [deleteBtn, backButton, ...rightButtons];
    }

    return (
      <>
        {!!currService && serviceConcept && (
          <Navigation
            title={headerTitle}
            menuItems={menuItems.filter(item => item.uid != 'products_selection')}
            currMenu={currMenu - 1}
            onDeleteProcessClick={!isNewDesign ? onDeleteClick : undefined}
            isFinished={!!_.get(tradingData, 'finished')}
            answersData={answersData}
            navigateToPrevMenu={isNewDesign ? navigateToPrevMenu : undefined}
          />
        )}
        <WrappedComponent
          onNextClick={handleNextClick}
          onPrevClick={handlePrevClick}
          onGoToStepClick={handleGoToStepClick}
          onConfirmResultClick={handleConfirmResultClick}
          onResendResultClick={handleResendResultClick}
          onAnswerChange={handleStepAnswerChange}
          step={step}
          isFirst={blockGoBack} // on first step we have custom logic
          isLast={currService && currService.isLastStep}
          loading={loading}
          dataService={currService}
          extraRightButtons={rightButtons}
          isNewDesign={isNewDesign}
          answersData={answersData}
        />
        <Snackbar
          open={Boolean(error)}
          variant={'error'}
          message={error}
          handleClose={() => setError(undefined)}/>
        <ConfirmationDialog
          title={'Beratung beenden'}
          message={CONFIRM_REMOVE_MSG}
          open={deleteTradeModalOpen}
          onClose={() => setDeleteTradeModalOpen(false)}
          confirm={_handleDeleteSession}
          confirmButtonText={'Ja, beenden & verwerfen'}
        />
        <MissingFieldsSummary
          missingFieldsData={missingFieldsData}
          navigateToPrevMenu={navigateToPrevMenu}
          handleClose={() => setMissingFieldsData(undefined)}
        />
      </>
    );
  }))
};

export default TradesDataProvider;
