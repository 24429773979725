import {useState} from "react";
import _ from 'lodash';

import {useTabsSharedDataContext} from "../components_v2/TabsSharedData/TabsSharedData";
import {generateQueryParamsHash} from "../utils";

const SHARED_DATA_KEY = 'esg-score-data';

export function useEsgScoreData(customerId, portfolios, withHistoriacalPortfolios, dataProvider) {
  const [data, setData] = useState({
    data: null,
    errors: null,
    loading: false,
    updatedAt: +new Date()
  });

  const paramsForHash = [withHistoriacalPortfolios];
  const sharedData = useTabsSharedDataContext();

  async function fetch() {
    const hashKey = generateQueryParamsHash(customerId, portfolios, ...paramsForHash);
    const sharedRiskReturnData = !!sharedData
        ? sharedData.getDataValue(SHARED_DATA_KEY, hashKey)
        : undefined;

      if (!!sharedRiskReturnData && !sharedRiskReturnData.errors) {
        // sync internal hook data with shared data.
        setData(sharedRiskReturnData);
        return;
      }

      const _setData = (state) => {
        // It is not possible to sync data with shared data in
        // hook because hook is not executed if component destroyed
        setData(state);
        sharedData.setDataValue(SHARED_DATA_KEY, hashKey, state);
      };


      _setData({
        data: null,
        errors: null,
        loading: true,
        updatedAt: +new Date()
      });

      try {

        const data = await dataProvider.getEsgScoreData(
            customerId, portfolios.map(ptf => ptf.depotNumberForAssets || ptf.depotNumber),
            withHistoriacalPortfolios);

        _setData({
          data: data.data,
          errors: _.isEmpty(data.error) ? null : data.error,
          loading: false,
          updatedAt: +new Date()
        })

      } catch (errors) {
        _setData({
          data: null,
          errors: errors,
          loading: false,
          updatedAt: +new Date()
        });
      }
  }

  return [data, fetch];
}