import React from "react";
import useStyles from "./styles";
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import PrimaryButton from "../../../../../../../../components/Buttons/PrimaryButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import {downloadPdf} from "../../../../../../../../utils/utils";
import {displayErrorSnackBar} from "../../../../../../../../components/SnackbarProvider/actions";
import {useParams} from "react-router-dom";
import AspectCard from "../AspectCard";
import Chart from "./components/Chart";
import DisclaimerText from "../DisclaimerText";
import DisclaimerTextV2 from "../DisclaimerText/DisclaimerTextV2";

const Summary = (props) => {
  const {dataService} = props;

  const [fileDownloadingProgress, setFileDownloadingProgress] = React.useState(false);

  let {customer_id} = useParams();

  const sustainableStep = dataService.getStep("ecologically-sustainable");

  const esgStep = dataService.getStep("esg");

  const paiStep = dataService.getStep("pai");

  const steps = [sustainableStep, esgStep, paiStep];

  const getQuestion = (step, uid) => {
    return step.question.find(item => item.uid === uid);
  };

  const getPercent = (step) => {
    const question = getQuestion(step, 'percent');

    return question.answer
  };

  const getAspects = (step) => {
    const question = getQuestion(step, 'aspects');

    if (question.answer) {
      return question.config.choices.filter(element => question.answer.includes(element.uid))
    } else {
      return []
    }
  };

  const sustainablePercent = getPercent(sustainableStep);
  const esgPercent = getPercent(esgStep);
  const paiPercent = getPercent(paiStep);


  const data = [];

  steps.forEach(step => {
    const aspects = getAspects(step);
    const percent = getPercent(step);

    data.push({
      "uid": step.uid,
      "aspects": aspects,
      "percent": percent
    })
  });


  const classes = useStyles();

  const handlePdfDownload = () => {

    let requestBody = {
      'customer_id': customer_id,
      'questionnaire_session_id': dataService.session_id
    };

    setFileDownloadingProgress(true);

    downloadPdf(`/reports/onboarding/esg-profile-pdf/`, undefined, 'POST', undefined, requestBody)
      .then(() => setFileDownloadingProgress(false))
      .catch(error => {
        setFileDownloadingProgress(false);
        props.dispatch(displayErrorSnackBar("Fehler bei der PDF-Erstellung"))
      });
  };

  return (
    <div className={classes.wrapper}>
      <Grid container spacing={3} style={{marginBottom: "30px"}}>
        <Grid item xs={5}>
          <Typography className={classes.title}>
            Nachhaltigkeitsprofil des Kunden
          </Typography>
          <Typography className={classes.description}>
            Ihr Kunde möchte in nachhaltige Produkte investieren.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{marginBottom: "30px"}}>
        <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
          <div className={classes.chart}>
            <Chart esgPercent={esgPercent} paiPercent={paiPercent} sustainablePercent={sustainablePercent}
                   notitle={true}/>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.title}>
            Ihr Kundenportfolio soll idealerweise diese Vorgabe erfüllen
          </Typography>
          <Typography className={classes.description}>
            Diese Filter sind in der Übersicht der Produktauswahl berücksichtigt und entsprechende Produkte
            werden gekennzeichnet.
          </Typography>
          <Grid container spacing={3} style={{marginTop: 20}}>
            {data.map((category) => {
              if (category.aspects.length > 0 && category.percent !== 0) {
                return (
                  <Grid item xs={4} className={classes.card}>
                    <Typography
                      className={classes.optionName}>{category.uid === "esg" ? "ESG" : category.uid === "pai" ? "PAI" : "Ökologisch Nachhaltig"}</Typography>
                    <Typography className={classes.optionPercent}>{category.percent}%</Typography>
                    <div className={classes.aspects}>
                      {category.aspects.map((option) => {
                        return (
                          <AspectCard text={option.text} icon={option.icon}
                                      classNames={classes.item}/>
                        );
                      })}
                    </div>
                  </Grid>
                )
              }
            })}
          </Grid>
        </Grid>

      </Grid>
      <PrimaryButton
        style={{marginBottom: "20px"}}
        text={"Als PDF herunterladen"}
        icon={fileDownloadingProgress ?
          <CircularProgress style={{color: 'rgba(0, 0, 0, 0.26)'}} size={15}/> : <SaveAltIcon/>}
        onButtonClick={handlePdfDownload}
      />
      {dataService.isNewDesign ? <div><DisclaimerTextV2 /></div> : <DisclaimerText />}
    </div>
  );
};


export default Summary;
