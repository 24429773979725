import React from 'react';
import moment from 'moment';
import _ from 'lodash';

import {Grid} from "@material-ui/core";
import {
  buildBenchmarkName, getSubSystemConfigItem,
  isExpertSectionVisible, isProSectionVisible
} from '../../../utils/utils';
import ChartSectionBordered, {ChartSectionWrapper} from "../components_v2/ChartSectionBordered/ChartSectionBordered";
import clsx from "clsx";
import WarningTooltip from "../../../components/WarningTooltip";
import useStyles from "./styles";
import RiskReturnChart from "../components_v2/RiskReturnChart/RiskReturnChart";
import CorrelationMatrixChart from "../components_v2/CorrelationMatrixChart/CorrelationMatrixChart";
import Paper from '@material-ui/core/Paper';
import ExplanationTooltip from '../../../components/ExplanationTooltip';
import {noBenchmarkValue, NOT_APPLICABLE_DE} from '../../../utils/constants';
import { toGermanFormat } from '../../../utils/numberFormater';
import { formatPercentage } from '../../FactSheetsPage/utils';
import Legend from '../components_v2/Legend/Legend';
import StressTestChartComponent from "../components_v2/StressTestChart/StressTestChartComponent";
import {useRiskAnalyseData} from "../hooks/useRiskAnalyseData";
import {getDataRetrievingParamsFromProps, SharedData} from "../utils";

function RiskTab(props) {

  const classes = useStyles();

  const {customSettings, reportType, aggregatedPortfolioName} = props;

  const {
    customerId,
    portfolios,
    dates,
    dataProvider,
    withHistorical,
    benchmarks,
    assets,
    investmentStrategyId,
  } = getDataRetrievingParamsFromProps(props);

  const riskAnalyseData = useRiskAnalyseData(
    customerId, portfolios, benchmarks, withHistorical,
    dates.start, dates.end, dataProvider, assets, investmentStrategyId);

  if (!customerId) return null;

  const IndicatorCard = ({title, value, benchmark, tooltipKey}) => {
    return (
      <Grid container data-id={`risk-indicators-${tooltipKey}`}>
        <Grid item xs={12}>
          <h5 className={classes.title}>
            {title}<ExplanationTooltip tooltipKey={tooltipKey} customClasses={{helpIcon: classes.helpIcon}}/>
          </h5>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs>
              <Paper className={clsx(classes.indicatorCard)} elevation={0}>
                <div className={classes.hugeNumber}>
                  {formatPercentage(value)}
                </div>
                <h5 className={classes.smallTitle}>
                  {aggregatedPortfolioName}
                </h5>
              </Paper>
            </Grid>
            <Grid item xs>
              <Paper className={clsx(classes.indicatorCard, 'grey')} elevation={0}>
                <div className={classes.hugeNumber}>
                  {!!benchmark ? toGermanFormat(benchmark) : <span className={classes.empty}>{noBenchmarkValue}</span>}
                </div>
                <h5 className={classes.smallTitle}>Benchmark</h5>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  };

  const legend = [{name: aggregatedPortfolioName, color: 'rgba(32, 146, 222, 0.3)'}];
  if (!!benchmarks) {
    legend.push({name: buildBenchmarkName(benchmarks), color: 'rgba(131, 138, 147, 0.3)'});
  }

  const riskAnalyseSectionsConfig = _.get(customSettings, 'riskAnalyse.subcategories');
  const mddPickDate = _.get(riskAnalyseData, 'data.mddPickDate');

  return (
    <Grid container spacing={2}>
      {isExpertSectionVisible('riskAnalyse', reportType, customSettings) && (
        <Grid item xs={12}>
          <ChartSectionBordered
            dataId={'risk-analyse-esg'}
            title={(
              <b>Risikokennzahlen</b>
            )}
            loading={!props.isMounted || riskAnalyseData.loading}
            error={riskAnalyseData.errors}
            displayError
            expanded={props.expandedItems.riskAnalyse}
            onExpanded={(newState) => props.onExpandedItemsChange('riskAnalyse', newState)}
            content={(
              <Grid container spacing={2}>
                {isExpertSectionVisible('maximumDrawdown', reportType, riskAnalyseSectionsConfig) && (
                  <Grid item xs={12} md={6} container spacing={2}>
                    <Grid item xs={12}>
                      <IndicatorCard
                        title={"Maximaler Wertverlust"}
                        tooltipKey={'maximum_drawdown'}
                        value={riskAnalyseData.data.mdd}
                        benchmark={riskAnalyseData.data.mdd_benchmark}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {riskAnalyseData.data.mddEnd ? (
                        <>
                          <div><b>Zeitraum der Wertaufholung:</b> {riskAnalyseData.data.recoveryDays} Tage</div>
                          <div><b>Wertverlust:</b> {moment(riskAnalyseData.data.mddStart).format('DD.MM.YYYY')}</div>
                          <div><b>Erholungsdatum:</b> {moment(riskAnalyseData.data.mddEnd).format('DD.MM.YYYY')}</div>
                        </>
                      ) : (
                        <><b>Wertaufholung:</b> Keine Wertaufholung seit{mddPickDate ? ` ${moment(mddPickDate).format('DD.MM.YYYY')}` : `: ${NOT_APPLICABLE_DE}`}</>
                      )}
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} md={6} container spacing={2}>
                  {isExpertSectionVisible('valueAtRisk', reportType, riskAnalyseSectionsConfig) && (
                    <Grid item xs={12}>
                      <IndicatorCard
                        title={"Historischer Value at Risk (VaR)"}
                        tooltipKey={'value_at_risk'}
                        value={riskAnalyseData.data.value_at_risk}
                        benchmark={riskAnalyseData.data.value_at_risk_benchmark}
                      />
                    </Grid>
                  )}
                  {isExpertSectionVisible('volatility', reportType, riskAnalyseSectionsConfig) && (
                    <Grid item xs={12}>
                      <IndicatorCard
                        title={"Schwankungsbreite / Volatilät"}
                        tooltipKey={'volatility'}
                        value={riskAnalyseData.data.volatility}
                        benchmark={riskAnalyseData.data.volatility_benchmark}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Legend data={legend} flexDirection={"row"}/>
                </Grid>
              </Grid>
            )}
          />
        </Grid>
      )}
      <SharedData>
        {isProSectionVisible('riskReturnChart', reportType, customSettings) && (
          <Grid item xs={12}>
            <ChartSectionWrapper
              dataId={'risk-analyse-risk-return'}
              title={(
                <>
                  <b>Rendite-Risiko-Diagramm</b>
                  <WarningTooltip
                    title={getSubSystemConfigItem('reporting', 'footnotes.riskReturnChart.title')}
                    icon={<i className={clsx("far fa-info-circle", classes.chartSectionHeaderInfoIcon)}/>}
                    width={600}
                  />
                </>
              )}
              displayError
              expanded={props.expandedItems.riskReturnChart}
              onExpanded={(newState) => props.onExpandedItemsChange('riskReturnChart', newState)}
              content={(
                <RiskReturnChart
                  customerId={customerId}
                  portfolios={portfolios}
                  dataProvider={dataProvider}
                  onChartSettingsChange={props.onChartSettingsChange}
                  expandedItems={props.expandedItems}
                  onExpandedItemsChange={props.onExpandedItemsChange}
                  investmentStrategyId={investmentStrategyId}
                  startDate={dates.start}
                  endDate={dates.end}
                />
              )}
            />
          </Grid>
        )}
        {isProSectionVisible('stressTestChart', reportType, customSettings) && (
          <Grid item xs={12}>
            <ChartSectionWrapper
              dataId={'risk-analyse-stress-test'}
              title={(
                <>
                  <b>Historische Stresstests</b>
                  <WarningTooltip
                    title={'Für einige Zeiträume werden keine Daten angezeigt, da nicht genügend historische Daten vorhanden sind oder die Erholung noch nicht erreicht wurde.'}
                    icon={<i className={clsx("far fa-info-circle", classes.chartSectionHeaderInfoIcon)}/>}
                    width={600}
                  />
                </>
              )}
              displayError
              expanded={props.expandedItems.stressTestChart}
              onExpanded={(newState) => props.onExpandedItemsChange('stressTestChart', newState)}
              content={(
                <StressTestChartComponent
                  customerId={customerId}
                  portfolios={portfolios}
                  dataProvider={dataProvider}
                  benchmarks={benchmarks}
                  expanded={props.expandedItems}
                  onExpanded={props.onExpandedItemsChange}
                  selectedPortfoliosName={aggregatedPortfolioName}
                  assets={assets}
                  investmentStrategyId={investmentStrategyId}
                  customSettings={_.get(customSettings, 'stressTestChart.subcategories')}
                  reportType={reportType}
                />
              )}
            />
          </Grid>
        )}
        {isProSectionVisible('correlationMatrix', reportType, customSettings) && (
          <Grid item xs={12}>
            <ChartSectionWrapper
              dataId={'risk-analyse-correlation-matrix'}
              title={(
                <>
                  <b>Korrelationsmatrix auf Basis der Performance im gewählten Zeitraum</b>
                  <WarningTooltip
                    title={'Die Korrelationsmatrix zeigt, wie eng die Wertentwicklungen der im Depot enthaltenen Fonds während des gewählten Zeitraums miteinander verbunden sind. Anhand des Korrelationskoeffizienten, welcher Werte zwischen -1 und +1 annehmen kann, lässt sich die Art der Beziehung zwischen den Fonds erkennen: Ein Wert von +1 weist auf eine hohe positive Korrelation hin, bei der sich die Fonds nahezu identisch entwickeln. Ein Wert von 0 zeigt an, dass keine Abhängigkeit zwischen ihnen besteht, während ein Wert von -1 auf eine entgegengesetzte Entwicklung hindeutet. Je niedriger die Korrelation, desto höher der Diversifikationsvorteil.'}
                    icon={<i className={clsx("far fa-info-circle", classes.chartSectionHeaderInfoIcon)}/>}
                    width={600}
                  />
                </>
              )}
              displayError
              expanded={props.expandedItems.correlationMatrix}
              onExpanded={(newState) => props.onExpandedItemsChange('correlationMatrix', newState)}
              content={(
                <CorrelationMatrixChart
                  customerId={customerId}
                  portfolios={portfolios}
                  dataProvider={dataProvider}
                  onChartSettingsChange={props.onChartSettingsChange}
                  expandedItems={props.expandedItems}
                  onExpandedItemsChange={props.onExpandedItemsChange}
                  startDate={dates.start}
                  endDate={dates.end}
                />
              )}
            />
          </Grid>
        )}
      </SharedData>
    </Grid>
  )
}

RiskTab.propTypes = {}

RiskTab.propDefs = {}

RiskTab.defaultProps = {}

export default RiskTab;