import React from 'react';
import _ from "lodash";

import useStyles from './styles'
import Grid from "@material-ui/core/Grid";

import {getCheckboxListSize, isRequiredQuestion} from "../../../../../utils";
import {FIELD_REQUIRED_MSG} from "../../../../../constants";
import CheckboxFormElement from "../../formElement/CheckboxFormElement";

function isQuestionValid(question, step, afterChange, service) {
  question.error = null;
  if (isRequiredQuestion(question) && _.isEmpty(question.answer)) {
    question.error = FIELD_REQUIRED_MSG;
  }

  return !question.error;
}

function initQuestionAnswer(question, answer, force) {
  if (answer && (answer.length > 0 || force)) {
    const options = (question.config.choices || []).map(i => i.uid);
    question.answer = answer.filter(a => options.includes(a));
  }
}

function getQuestionAnswer(question) {
  return {
    question_uid: question.uid,
    answer: question.answer
  }
}

export const CheckboxListQuestionMethods = {
  required: isRequiredQuestion,
  valid: isQuestionValid,
  initAnswer: initQuestionAnswer,
  answer: getQuestionAnswer,
}

function CheckboxListQuestion(props) {

  const {
    question,
    onAnswerChange
  } = props;

  const classes = useStyles();

  let options = question.config.choices || [];

  React.useEffect(() => {
    if(!question.answer){
      question.answer = [];
    }
  }, []);

  const handleCheckboxChanged = (option, checked) => {
    const answer = checked ? [...question.answer, option.uid] : _.filter(question.answer, i => i !== option.uid);
    onAnswerChange(question.uid, answer)
  };

  const renderOption = (option) => (
    <Grid item key={option.uid} sm={getCheckboxListSize(question.uid)}>
      <CheckboxFormElement
        name={option.uid}
        label={option.text}
        value={question.answer && question.answer.includes(option.uid) || false}
        onChange={(checked) => handleCheckboxChanged(option, checked)}
        custom_classes={{root: classes.option, label: classes.optionLabel}}
      />
    </Grid>
  );

  return (
    <div className={classes.inputContainer}>
      <p className={classes.label}>{ question.question_text }{isRequiredQuestion(question) ? <span className={classes.asterisk}>&nbsp;*</span> : ''}</p>
      <Grid container>
        { options.map(renderOption) }
      </Grid>
    </div>
  );
}

export default CheckboxListQuestion;