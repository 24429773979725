import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  dialogTitle: {
    background: 'rgba(255, 157, 0, 0.12)',
  },
  infoMsg: {
    fontSize: 18,
  },
  menuName: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    textTransform: 'uppercase'
  },
  stepName: {
    fontFamily: 'Roboto-Bold',
    fontSize: 18,
  },
  missingField: {
    color: '#E25443',
    fontSize: 14,
  },
  menuContainer: {
  },
  stepContainer: {
    marginLeft: theme.spacing(2),
  },
  arrowBreadcrumb: {
    color: '#838A93',
    margin: '0 0.25em',
    fontSize: '1.15em',
    verticalAlign: 'middle'
  }
}))