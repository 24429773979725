import React from 'react'
import PropTypes from "prop-types";

import useStyles from './styles'
import {Card, CardContent, Grid} from "@material-ui/core";
import Contacts from "./components/Contacts/Contacts";
import DepotsSelection from "./components/DepotsSelection/DepotsSelection";
import RiskIndicators from "./components/RiskIndicators/RiskIndicator";
import {getEsgColor, getRiskColor} from "../../utils";
import clsx from "clsx";
import _ from "lodash";
import ErrorIcon from "@material-ui/icons/Error";
import AggregatedValue from "./components/AggregatedValue/AggregatedValue";
import EsgProfileMatchingDetails from "./components/EsgProfileMatchingDetails/EsgProfileMatchingDetails";
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../../../utils/constants";


const CustomerSummaryContext = React.createContext({})

export function useCustomerSummaryContext() {

  const context = React.useContext(CustomerSummaryContext)
  if (!context) {
    throw new Error('Extracting context without wrapping your component with context Provider!')
  }

  return context

}


/**
 * Card with customer summary information
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function CustomerSummaryCard(props) {

  const {
    customer,
    loading,
    errors,
    isOverview,
    children,
    productRiskValue,
    productRiskLoading,
    productRiskErrors
  } = props

  const customerRisk = React.useMemo(() => {

    if (!customer) {
      return undefined
    }

    return _.get(customer, 'last_srri.srri')

  }, [customer])

  const riskIndicatorColor = React.useMemo(() => {
    return getRiskColor(customerRisk, productRiskValue)
  }, [customerRisk, productRiskValue])

  const esgIndicatorColor = React.useMemo(() => {
    return getEsgColor(_.get(customer, 'esg_profile_matching'))
  }, [_.get(customer, 'esg_profile_matching')])

  const classes = useStyles({
    cursor: !props.isOverview && !_.isNil(customerRisk) ? 'pointer' : 'normal',
    riskColor: riskIndicatorColor,
    esgColor: esgIndicatorColor
  });
  const propsClasses = props.classes || {};

  if (errors) {
    return (
      <Card
        className={classes.listItemCard}
      >
        <CardContent className={classes.cardContent}>
          <Grid container spacing={2} className={classes.content}>
            <Grid item xs={12} className={classes.contentError}>
              <ErrorIcon/>
              <p>{DEFAULT_EMPTY_SECTION_MESSAGE}</p>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  const handleCardClick = () => {
    props.onClick && props.onClick()
  }

  return (
    <CustomerSummaryContext.Provider value={{customer, loading, productRiskValue, productRiskLoading, productRiskErrors, isOverview, customerRisk}}>
      <Card
        className={classes.listItemCard}
        onClick={!_.isNil(customerRisk) && handleCardClick}
      >
        <CardContent className={classes.cardContent}>
          <Grid container className={classes.content}>
            {React.Children.map(children, (child) => (
              <Grid item className={clsx(classes.item, propsClasses.item, classes[child.props.indicatorClassName])} {...(child.props.GridProps || {})}>{ child }</Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </CustomerSummaryContext.Provider>
  )
}

CustomerSummaryCard.Contacts = Contacts;
CustomerSummaryCard.DepotsSelection = DepotsSelection;
CustomerSummaryCard.RiskIndicators = RiskIndicators;
CustomerSummaryCard.AggregatedValue = AggregatedValue;
CustomerSummaryCard.EsgProfileMatchingDetails = EsgProfileMatchingDetails;

CustomerSummaryCard.propTypes = {
  /**
   * Customer data.
   */
  customer: PropTypes.object.isRequired,
  /**
   * Flag that indicate is data loading running, or not.
   */
  loading: PropTypes.bool,
  /**
   * Flag, that indicate, is card used as a Customer overview, or inside customers list.
   * Used for specifying different styles for that cases.
   */
  isOverview: PropTypes.bool,
  /**
   * Components, that should be included to the customer summary card
   */
  children: PropTypes.element.isRequired
};

export default CustomerSummaryCard;