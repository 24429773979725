import ExAnteStep from "./components/ExAnteStep";
import {isEmpty} from "lodash";

const exAnteStep = {
  "uid": "ex-ante-step",
  "name": "Überprüfung",
  "behaviour": ["dynamic", 'Ex-Ante Kosten'],
  "custom_classes": {"stepName": "text-left"},
  "step_content": ExAnteStep,
  "question": [
    {
      "uid": "ex_ante",
      "type": "input",
      "optional": false,
      "isValid": function() {
        return !isEmpty(this.answer);
      }
    }
  ]
};

export const exAnteStepsData = {
  steps: [
    exAnteStep
  ]
};
