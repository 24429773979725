import _ from "lodash";

import {CustomerService} from "../../customer_step_service";
import {buildDataSteps} from "./mock_account_data";

export class CustomerAccountServiceV2 extends CustomerService {
  _buildSteps(customer) {
    const isVlPlan = this._getPrevStepAnswer('products_selection', 'product-selection-deposits', "vl_plan['checkbox']");

    let chargingBankFeeOptions = [];

    const productsService = this._getStepService('products_selection');
    const depot = productsService.getDepot(true);

    if(depot) {
      const depotType = depot.selected_contract_type;

      chargingBankFeeOptions = _.get(
        productsService.banksDataGrouped,
        [
          depot.bankCompanyId,
          'onboarding_allowed_charging_bank_fees_sources',
          depot.is_online ? depotType.code : 'GENETIC_DEPOT'
        ]);
    }

    return buildDataSteps(isVlPlan, chargingBankFeeOptions);
  }
}