import _ from "lodash";

import {ProtocolMenuItem} from "../ProtocolMenuItem";
import {protocolStepsData} from "../../../RiskProfiling/components/servicesV2/mock_protocol";

export class TradingProtocolDataServiceV2 extends ProtocolMenuItem {
  constructor(uid, name, customer_id, getPrevStepAnswer, handleCreateOrderClick, handleValidateOrderClick, validateMissingData) {
    super(uid, name, customer_id, getPrevStepAnswer, handleCreateOrderClick);
    this._createAccountStep = 'finalize-documents-delivery';
    this._validateAccountFunc = handleValidateOrderClick;
    this._validateAccountStep = this._createAccountStep;
    this.__stepsData = protocolStepsData;
    this.__is_new_design = true;
    this._validateMissingData = validateMissingData;
  }

  async nextStep(){
    if (this._step && this._step.uid === this._validateAccountStep) {
      if (_.isFunction(this._validateMissingData) && !await this._validateMissingData()) {
        return;
      }
    }

    return await super.nextStep()
  }

  async __sendStepData() {
    if(this._step && this._step.uid === this._validateAccountStep) {
      await this._validateAccount();
    }

    return await super.__sendStepData(); // store answers in our db
  }
}