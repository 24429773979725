import _ from "lodash";
import React from "react";
import {useTabsSharedDataContext} from "../components_v2/TabsSharedData/TabsSharedData";
import {getErrorMessage} from "../../../utils/utils";
import {parseResponse} from "../../../utils/api";
import {getHashValue, getPortfolioColor, getPtfTrackingStartDate} from "../utils";

const generateQueryParamsHash = (customerId, portfolios, startDate, endDate, benchmarks, withHistoricalPortfolios) => {
  const portfoliosDepotNumbers = JSON.stringify(_.sortBy(
    portfolios.map((portfolio) => portfolio.depotNumber)));
  return getHashValue(customerId, portfoliosDepotNumbers, startDate, endDate, withHistoricalPortfolios, JSON.stringify(benchmarks))
}

export function useTimeWeightedReturnData(customerId, portfolios, startDate, endDate, withHistoricalPortfolios, benchmarks, dataProvider, investmentStrategyId) {
  const [data, setData] = React.useState({
    data: null,
    loading: true,
    errors: null,
    updatedAt: undefined,
  });

  const sharedData = useTabsSharedDataContext();

  React.useEffect(() => {
    if (!!customerId && !_.isEmpty(portfolios)) {

      const sharedPortfolioData = !!sharedData
        ? sharedData.getDataValue('time-weighted-return', generateQueryParamsHash(customerId, portfolios, startDate, endDate, benchmarks, withHistoricalPortfolios))
        : undefined;

      if (!!sharedPortfolioData && !sharedPortfolioData.errors) {
        // sync internal hook data with shared data.
        setData(sharedPortfolioData);
        return;
      }

      // fetch data if there is no shared data or shared data was loaded with error
      // check if data fetched for the first time (updatedAt is undefined) to prevent infinite data reloading
      // in case of data loading error, so data will be requested again only if tab changed
      if (!data.updatedAt || !sharedPortfolioData) {
        fetch();
      }
    }
  }, [
    customerId, JSON.stringify(portfolios), JSON.stringify(benchmarks),
    startDate, endDate, withHistoricalPortfolios,
    _.get(sharedData, 'updatedAt')]);

  const fetch = async () => {

    const hashKey = generateQueryParamsHash(customerId, portfolios, startDate, endDate, benchmarks, withHistoricalPortfolios);

    const state = {
      data: null,
      loading: true,
      errors: null,
      updatedAt: +new Date()
    }
    setData(state);
    // It is not possible to sync data with shared data in
    // hook because hook is not executed if component destroyed
    sharedData.setDataValue('time-weighted-return', hashKey, state);

    try {

      let _startDate = startDate;

      // if one portfolio is selected, and selected time period is beginning
      if (!startDate && portfolios.length == 1) {
        _startDate = getPtfTrackingStartDate(portfolios[0]);
      }

      const depotNumbers = portfolios.map((portfolio) => portfolio.depotNumber).filter((value) => !!value);

      let response = await dataProvider.getTimeWeightedReturnData(customerId, depotNumbers, _startDate, endDate,
        withHistoricalPortfolios,
        benchmarks, investmentStrategyId
      );

      parseResponse(response, 'time_weighted_return', (data) => {
        const state = {
          data: {
            portfolios: (data || []).map(p => ({...p, color: getPortfolioColor(portfolios, p)}))
          },
          loading: false,
          errors: undefined,
          timestamp: new Date().getTime()
        };
        setData(state);
        sharedData.setDataValue('time-weighted-return', hashKey, state);
      }, (errors) => {
        const state = {
          data: null,
          loading: false,
          errors: getErrorMessage(errors),
          updatedAt: +new Date()
        }
        setData(state)
        sharedData.setDataValue('time-weighted-return', hashKey, state);
      });
    }
    catch(errors) {
      const state = {
        data: null,
        loading: false,
        errors: getErrorMessage(errors),
        updatedAt: +new Date()
      }
      setData(state)
      sharedData.setDataValue('time-weighted-return', hashKey, state);
    }
  }

  return data
}