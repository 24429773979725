import React from "react";
import moment from "moment";

import {withStyles} from "@material-ui/core/styles";

import styles from "./styles";
import CalendarFormElement from "../../formElement/CalendarFormElement";
import {isRequiredQuestion, requiredCheck} from "../../../../../utils";
import {FIELD_REQUIRED_MSG} from "../../../../../constants";

function isDateQuestionRequired(question) {
  return isRequiredQuestion(question);
}

function isDateQuestionValid(question, step, afterChange, service) {
  if(isDateQuestionRequired(question) && !question.answer){
    question.error = FIELD_REQUIRED_MSG;
    return false;
  }

  question.error = null;

  // validate answer if it's present
  if(question.answer){
    const date = moment(question.answer);
    const defaultMsg = 'Das Datum ist nicht korrekt';
    if(!date.isValid()){
      question.error = defaultMsg;
    } else if (question.disable_future && date.diff(moment(), 'days', true) > 0) {
      question.error = question.disable_future_msg || defaultMsg;
    } else if (question.disable_past && moment().diff(date, 'days') > 0) {
      question.error = question.disable_past_msg || defaultMsg;
    } else if (moment.isMoment(question.min_date) && question.min_date.diff(date, 'days') > 0) {
      question.error = question.min_date_msg || defaultMsg;
    } else if (typeof question.handleDateValidity === 'function' && question.handleDateValidity(date)){
      question.error = defaultMsg;
    }
  }

  return !question.error;
}

function getDateQuestionAnswer(question) {
  let answer = [];
  if(!!question.answer) {
    answer.push(moment(question.answer).format(question.out_format || 'DD.MM.YYYY'));
  }
  return {
    question_uid: question.uid,
    answer: answer
  }
}

function initDateQuestionAnswer(question, answer, force){
  if (answer && (answer.length > 0 || force)) {
    let value = answer[0];

    if(value){
      let date = moment(value, 'DD.MM.YYYY');

      // in case answer was stored in out_format
      if(!date.isValid() && question.out_format){
        date = moment(value, question.out_format);
      }

      question.answer = date;
    } else {
      question.answer = undefined;
    }
  }
}

export const DateQuestionMethods = {
  required: isDateQuestionRequired,
  valid: isDateQuestionValid,
  answer: getDateQuestionAnswer,
  initAnswer: initDateQuestionAnswer,
}

const DateQuestion = props => {
  const {
    classes,
    question,
    onAnswerChange
  } = props;

  const handleValueChange = (newValue) => {
    question.error = null; // clean error
    onAnswerChange(question.uid, newValue)
  };

  const disabled = !!question.disabled;

  return (
    <div className={classes.calendarQuestionContainer}>
      <CalendarFormElement
        label={question.question_text}
        value={question.answer || null}
        onChange={handleValueChange}
        required={isDateQuestionRequired(question) || requiredCheck(question)}
        custom_classes={question.custom_classes}
        disablePast={question.disable_past}
        disableFuture={question.disable_future}
        minDate={question.min_date}
        error={question.error}
        disableDatesCallback={question.disableDatesCallback}
        disabled={disabled}
      />
    </div>
  )
};

export default withStyles(styles)(DateQuestion);