import React from 'react';

import {useExpandedItems} from "../../hooks/useExpandedItems";
import Grid from "@material-ui/core/Grid";
import ChartSectionBordered, {ChartSectionPortfolioName} from "../ChartSectionBordered/ChartSectionBordered";
import {useExtendedAssetsData} from "../../hooks/useExtendedAssetsData";
import RankingPerformanceTable from "../charts/RankingPerformanceTable";


function PortfolioPeergroupRankingTable({customerId, portfolio, dataProvider, onChartSettingsChange, expanded, onExpandedItemsChange}) {

  const extendedAssetsData = useExtendedAssetsData(customerId, portfolio, dataProvider);

  return (
    <Grid item xs={12}>
      <ChartSectionBordered
        dataId={`performance-peergroup-ranking-${portfolio.depotNumber}`}
        title={(
          <ChartSectionPortfolioName portfolio={portfolio} />
        )}
        loading={extendedAssetsData.loading}
        error={extendedAssetsData.errors}
        displayError
        expanded={expanded}
        isPortfolioSection
        skipContentPadding
        borderLeftColor={portfolio.color}
        onExpanded={(newState) => onExpandedItemsChange(portfolio, newState)}
        content={(
          <RankingPerformanceTable
            data={extendedAssetsData.data}
            loading={extendedAssetsData.loading}
            error={extendedAssetsData.errors}
            order='asc'
            orderBy='name'
          />
        )}
      />
    </Grid>
  )
}


function PeergroupRankingTable({customerId, portfolios, expandedItems, onExpandedItemsChange, dataProvider, onChartSettingsChange}) {

  const [isPtfExpanded, handleExpandedChanged] = useExpandedItems(expandedItems, onExpandedItemsChange, 'singlePerformanceItems');

  return (
    <Grid container spacing={2}>
      {portfolios.map((portfolio) => (
        <PortfolioPeergroupRankingTable
          key={portfolio.depotNumber}
          portfolio={portfolio}
          customerId={customerId}
          dataProvider={dataProvider}
          onChartSettingsChange={onChartSettingsChange}
          expanded={isPtfExpanded(portfolio)}
          onExpandedItemsChange={handleExpandedChanged}
        />
      ))}
    </Grid>
  )
}

PeergroupRankingTable.propTypes = {}

PeergroupRankingTable.propDefs = {}

PeergroupRankingTable.defaultProps = {}

export default PeergroupRankingTable;