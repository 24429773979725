import React from "react";
import { withStyles } from "@material-ui/core";

import CommonAnalysisCard from "../../../../../VirtualPortfolioPage/components/AnalysisCard";
import { toShortGermanFormat } from "../../../../utils";
import styles from "./styles";
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../../../../../utils/constants";

class PerformanceTable extends React.Component {

  constructor(props) {
    super(props);

    const { data } = this.props;

    const perf = data.performance || {};

    this.performanceLines = [
      { title: '1 Monat', value_c: perf.return_1m, value_a: '-'},
      { title: '3 Monate', value_c: perf.return_3m , value_a: '-'},
      { title: '6 Monate', value_c: perf.return_6m, value_a: '-'},
      { title: 'Lfd. Jahr', value_c: perf.return_ytd, value_a: '-'},
      { title: '1 Jahr', value_c: perf.return_1y, value_a: '-' },
      { title: '3 Jahre', value_c: perf.cum_ret_3y, value_a: perf.return_3y },
      { title: '5 Jahre', value_c: perf.cum_ret_5y, value_a: perf.return_5y },
      { title: '10 Jahre', value_c: perf.cum_ret_10y, value_a: perf.return_10y },
      { title: 'Seit Auflage', value_c: perf.cum_ret_inception, value_a: perf.return_inception },
    ];
  }

  renderValue(value) {
    if(value === '-') return value;
    return value ? toShortGermanFormat(value, '', ' %', 2, false) : 'k.A.';
  }

  renderLines() {
    const { classes } = this.props;

    return this.performanceLines.map((item, index) => (
      <li className={classes.item} key={index}>
        <span className={classes.title}>{item.title}</span>
        <span className={classes.values}>{this.renderValue(item.value_a)}</span>
        <span className={classes.values}>{this.renderValue(item.value_c)}</span>
      </li>
    ));
  }

  renderData() {
    const { classes, data } = this.props;

    return (
      <ul className={classes.list}>
        <li className={[classes.item, classes.headline].join(' ')}>
          <span className={classes.title}></span>
          <span className={classes.values}>Annualisiert</span>
          <span className={classes.values}>Kumuliert</span>
        </li>
        {this.renderLines()}
      </ul>
    )
  }

  renderError() {
    return <div>{'Fehler. Die "Performance" Tabelle konnte nicht geladen werden.'}</div>;
  }

  render() {
    const { classes } = this.props;

    return <CommonAnalysisCard
      classes={{
        card: classes.root
      }}
      title={this.props.title}
      subtitle={this.props.subtitle}
      loading={this.props.loading}
      content={
        <div className={classes.container}>
          {this.props.error ? this.renderError() : this.props.data ? this.renderData() : DEFAULT_EMPTY_SECTION_MESSAGE}
        </div>
      }
    />
  }
}

export default withStyles(styles)(PerformanceTable)