import React from "react";
import {connect} from 'react-redux'

import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import {toGermanFormat} from "../../../../../../../../../utils/numberFormater";

import styles from "../styles";
import useStyles from "./styles";
import DashboardTable from "../../../../../../../../../components/DashboardTable/DashboardTable";
import {tableInvestmentStrategyStructure, tableStructure} from "./table-data";
import {withEuroOrDash} from "../../../../../../../../../utils/utils";
import clsx from "clsx";
import PrimaryButton from "../../../../../../../../../components/Buttons/PrimaryButton";
import SelectFormElement from "../../../../formElement/SelectFormElement";
import _ from "lodash";
import {
  instrumentDataPath,
  instrumentDiscountPath,
  INSTRUMENTS_KEY,
  SAVINGS_PLANS_KEY,
  SINGLE_INVESTMENTS_KEY
} from "../../../../../../../mock_produktauswahl";
import {
  getBankData,
  getContractTypeConfiguration,
  getDiscountsOptions,
  getKickbacksOptions,
  getListOptionsFromBankData,
  getServiceFeeMax,
  getVatCoef, isRequiredQuestion
} from "../../../../../../../utils";
import {CircularProgress} from "@material-ui/core";
import InputFormElement, {
  DECIMAL_SCALE,
  PercentageNumberFormat
} from '../../../../formElement/InputFormElement/InputFormElement'
import {OPTION_IS_NOT_AVAILABLE_FOR_DEPOT_MSG} from "../../../../../../../constants";
import ErrorIcon from "@material-ui/icons/Error";
import CustomTooltip from "../../../../../../../../../components/Tooltip";
import InputArrows from "../../../../formElement/InputArrows";
import {InfoIcon} from "../../../../../../../../../images";
import WarningTooltip from "../../../../../../../../../components/WarningTooltip";


const InfoTooltip = withStyles(styles)(({classes, title}) => (
  <CustomTooltip title={title} placement={"top"}>
    <ErrorIcon className={classes.helpIcon}/>
  </CustomTooltip>
));

const COMMON_MAX_SERVICE_FEE = 2; // in case service fee is higher we will show info message

const SummaryStep = props => {
  const {
    classes,
    dataService,
    onGoToStepClick,
    questions: [
      discountsQuestion,
      kickbacksQuestion,
      serviceFeeQuestion,
      serviceFeeVatQuestion
    ],
    onAnswerChange
  } = props;

  const tableClasses = useStyles();

  const [stepsData, setStepsData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const vatCoef = React.useMemo(() => {
    return getVatCoef();
  }, []);

  const isModelPortfolio = _.get(stepsData, 'modelPortfolio.is_modelportfolio');

  React.useEffect(() => {
    if(!discountsQuestion.answer){
      discountsQuestion.answer = {
        discountsEnabled: false,
        overall: '',
        [SAVINGS_PLANS_KEY]: '',
        [SINGLE_INVESTMENTS_KEY]: ''
      };
    }

    setLoading(true);
    const data = dataService.buildData();
    data.fundsBuyTotal = dataService.getFundsBuyTotal();
    const modelPortfolio = data.instruments_source[0];

    data.custodian = data.custodians[0];
    data.modelPortfolio = modelPortfolio;
    data.custodian.contract = data.custodian.depot.contract_types.find(
      contract => contract.value == data.custodian.contract_type);

    let savings_plan_key = data["savings_plan['checkbox']"][0] ? 'savings_plan' : data["vl_plan['checkbox']"][0] ? 'vl_plan' : undefined;

    if(savings_plan_key){
      const fieldKey = `${savings_plan_key}_periodic_plans`
      const rotationOptions = getListOptionsFromBankData(dataService.banksData, data.custodian.depot.bankCompanyId, fieldKey)
      const rotation = rotationOptions.find(o => o.value == data[`${savings_plan_key}['rotation']`][0]);
      data['savings_plan_input'] = data[`${savings_plan_key}['input']`][0];
      data['savings_plan_from_date'] = data[`${savings_plan_key}['from_date']`][0];
      data['savings_plan_rotation'] = rotation && rotation.label.toLowerCase();
    }

    serviceFeeQuestion.optional = serviceFeeVatQuestion.optional = !getContractTypeConfiguration(
      data.custodian.contract, 'onboarding_service_fee_required', false);

    if (!modelPortfolio) {
      const bankInstrumentsInfo = dataService.getBankInstrumentsInfo(data.custodian.depot.bankCompanyId);

      // update instruments with custodian data
      const discountsData = {...discountsQuestion.answer};

      let maxFundsInstrumentsOverallDiscount = 100;
      let maxEinzeltitelsInstrumentsOverallDiscount = 100;

      data.instruments[0].map(i => {
        const info = bankInstrumentsInfo[i.isin];
        if(info){
          _.set(discountsData, [...instrumentDataPath(i), 'max_discount'], info.c_front_end_load_max_discount);
          _.set(discountsData, [...instrumentDataPath(i), 'broker_fee'], info.c_front_end_load_percent);

          const max_discount = info.c_front_end_load_max_discount || 0;
          if (i.is_fund) {
            maxFundsInstrumentsOverallDiscount = _.min([max_discount, maxFundsInstrumentsOverallDiscount])
          } else {
            maxEinzeltitelsInstrumentsOverallDiscount = _.min([max_discount, maxEinzeltitelsInstrumentsOverallDiscount])
          }
        }
      });

      onAnswerChange(discountsQuestion.uid, discountsData);
      data.maxFundsInstrumentsOverallDiscount = maxFundsInstrumentsOverallDiscount;
      data.maxEinzeltitelsInstrumentsOverallDiscount = maxEinzeltitelsInstrumentsOverallDiscount;

      if (savings_plan_key === 'vl_plan') {
        serviceFeeQuestion.maxValue = getServiceFeeMax(dataService.banksData, data.custodian.depot.bankCompanyId, 'vl_service_fee_max');
      } else {
        serviceFeeQuestion.maxValue = getServiceFeeMax(dataService.banksData, data.custodian.depot.bankCompanyId);
      }
    } else if (modelPortfolio.isInvestmentStrategy) {
      serviceFeeQuestion.question_text = 'Anteil Vertrieb p.a. in % (zzgl. MwSt.)';
      serviceFeeVatQuestion.question_text = 'Anteil Vertrieb p.a. in % (inkl. MwSt.)';
      serviceFeeQuestion.maxValue = modelPortfolio.broker_commission;

      // Extend discount data with rows for each instrument
      // to re-use existing Ex-Ante functionality for model portfolios
      const discountsData = {...discountsQuestion.answer};
      data.instruments[0].map(i => {
        _.set(discountsData, instrumentDataPath(i), {});
      });
      onAnswerChange(discountsQuestion.uid, discountsData);
    } else if (modelPortfolio.is_modelportfolio) {
      if (savings_plan_key === 'vl_plan') {
        serviceFeeQuestion.maxValue = getServiceFeeMax(dataService.banksData, data.custodian.depot.bankCompanyId, 'vl_service_fee_max');
      } else {
        serviceFeeQuestion.maxValue = getServiceFeeMax(dataService.banksData, data.custodian.depot.bankCompanyId);
      }

      // Extend discount data with rows for each instrument
      // to re-use existing Ex-Ante functionality for model portfolios
      const discountsData = {...discountsQuestion.answer};
      data.instruments[0].map(i => {
        _.set(discountsData, instrumentDataPath(i), {});
      });
      onAnswerChange(discountsQuestion.uid, discountsData)
    }

    if(!modelPortfolio || modelPortfolio.isInvestmentStrategy || modelPortfolio.is_modelportfolio){

      let discounts;

      if (savings_plan_key === 'vl_plan') {
        discounts = getDiscountsOptions(dataService.banksData, data.custodian.depot.bankCompanyId, 'vl_discount')
      } else {
        discounts = getDiscountsOptions(dataService.banksData, data.custodian.depot.bankCompanyId)
      }

      discountsQuestion.config.choices = discounts;

      let kickbacks;
      if(modelPortfolio && modelPortfolio.is_modelportfolio) {
        const kickbacks_configuration = getContractTypeConfiguration(
          data.custodian.contract,
          'kickback',
          []);
        kickbacks = kickbacks_configuration.map(option => ({id: option, value: option, label: `${option} %`}))
      } else {
        if (savings_plan_key === 'vl_plan') {
          kickbacks = getKickbacksOptions(dataService.banksData, data.custodian.depot.bankCompanyId, 'vl_kickback');
        } else {
          kickbacks = getKickbacksOptions(dataService.banksData, data.custodian.depot.bankCompanyId);
        }

      }
      kickbacksQuestion.config.choices = kickbacks;
    }

    let instrumentsGrouped = {};
    data.instruments[0].forEach(i => {
      const instr = i.savingsPlan ? {savingsPlanItem: i} : {singleInvestment: i};
      instrumentsGrouped[i.isin] = {...instrumentsGrouped[i.isin], ...instr}
    });

    data.instrumentsGrouped = Object.values(instrumentsGrouped);
    data.einzeltitelsOnly = _.every(data.instrumentsGrouped
      .map((item) => item.singleInvestment || item.savingsPlanItem || {}), (instrument) => !instrument.is_fund);
    data.infoText = getBankData(dataService.banksData, data.custodian.depot.bankCompanyId, 'onboarding_summary_info_text') || []

    // set data after available discounts is loaded
    // to prevent auto update instrument discounts when no options available
    setStepsData(data);

    if(_.isNil(serviceFeeQuestion.answer)){
      let defaultFee;
      if(!!modelPortfolio) {
        defaultFee = modelPortfolio.service_fee_per_year || serviceFeeQuestion.maxValue
      }
      onAnswerChange(serviceFeeQuestion.uid, defaultFee || 0);
    }

    kickbacksQuestion.tooltip = getBankData(dataService.banksData, data.custodian.depot.bankCompanyId, 'kickback_info_text');
    const defaultKickback = getBankData(dataService.banksData, data.custodian.depot.bankCompanyId, 'default_kickback') || 0;
    // set default in case no answer or prev answer is not available
    if(_.isNil(kickbacksQuestion.answer) || !_.find(kickbacksQuestion.config.choices, (o) => o.value == kickbacksQuestion.answer)){
      onAnswerChange(kickbacksQuestion.uid, defaultKickback);
    }

    // disable dropdown in case defaultKickback is the only option
    kickbacksQuestion.disabled = kickbacksQuestion.config.choices.length === 1 && defaultKickback == kickbacksQuestion.config.choices[0].value;

    setLoading(false)
  }, []);

  React.useEffect(() => {
    // serviceFeeVat should be recalculated as it depends on serviceFee
    onAnswerChange(serviceFeeVatQuestion.uid, _.round(serviceFeeQuestion.answer * vatCoef, 5))
  }, [serviceFeeQuestion.answer]);

  React.useEffect(() => {
    serviceFeeQuestion.minValue = isModelPortfolio && kickbacksQuestion.answer == 100 && 0.2;
  }, [kickbacksQuestion.answer, isModelPortfolio]);

  if (loading){
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress className={classes.loadingIndicator}/>
      </div>
    );
  }

  // in case step data not set -> nothing to show
  if(!stepsData) return null;


  const isInvestmentStrategy = _.get(stepsData, 'modelPortfolio.isInvestmentStrategy');

  const allowedDiscountTypes = _.get(stepsData, 'custodian.depot.onboarding_discounts_allowed') || [];
  const allowedEinzeltitelDiscountTypes = _.get(stepsData, 'custodian.depot.einzeltitel_discounts_allowed') || [];

  const isInstrumentDiscountPossible = (isin, key) => {
    const instruments = stepsData.instruments[0] || []
    const instrumentData = _.find(instruments, (instrument) => instrument.isin == isin);
    if (!instrumentData) {
      return false
    }

    if (instrumentData.is_fund) {
      return true
    }

    return allowedEinzeltitelDiscountTypes.includes({SAVINGS_PLANS_KEY: 'payment_plan', SINGLE_INVESTMENTS_KEY: 'single_buy'}[key])
  }

  const changeDiscount = (field, value) => {

    const discountsData = {...discountsQuestion.answer};

    function setForAllInstruments(key){
      Object.keys(discountsData[INSTRUMENTS_KEY] && discountsData[INSTRUMENTS_KEY][key] || {}).forEach(isin => {
        if (isInstrumentDiscountPossible(isin, key)) {
          _.set(discountsData, [INSTRUMENTS_KEY, key, isin, 'discount'], value);
        }
      });
    }

    if(field === 'overall'){
      discountsData[field] = value;

      if (!stepsData.modelPortfolio) {
        [SINGLE_INVESTMENTS_KEY, SAVINGS_PLANS_KEY].forEach(key => {
          discountsData[key] = value;
          setForAllInstruments(key);
        });
      }

    } else {
      discountsData[field] = value;
      setForAllInstruments(field);
    }

    onAnswerChange(discountsQuestion.uid, discountsData)
  };

  const changeItemDiscount = (instrument, value) => {

    if (!isInstrumentDiscountPossible(instrument.isin, instrument.savingsPlan ? SAVINGS_PLANS_KEY : SINGLE_INVESTMENTS_KEY)) {
      return false
    }

    const discountsData = {...discountsQuestion.answer};
    _.set(discountsData, instrumentDiscountPath(instrument), value);

    onAnswerChange(discountsQuestion.uid, discountsData)
  };

  const onEditDiscountsClick = () => {
    if(discountsQuestion.answer.discountsEnabled) {
      discountsQuestion.answer.discountsEnabled = false;
      changeDiscount('overall', ''); // clear all values
    } else {
      onAnswerChange(discountsQuestion.uid, {...discountsQuestion.answer, discountsEnabled: true});
    }
  };

  const handleKickbackChange = (value) => {
    onAnswerChange(kickbacksQuestion.uid, value);
  };

  const updateServiceFeeQuestionAnswer = (value) => {
    onAnswerChange(serviceFeeQuestion.uid, value >= 0 ? value : 0 || 0);
  };

  const topArrowClicked = () => {
    updateServiceFeeQuestionAnswer(_.round(parseFloat(serviceFeeQuestion.answer) + 0.1, DECIMAL_SCALE))
  };

  const bottomArrowClicked = () => {
    updateServiceFeeQuestionAnswer(_.round(parseFloat(serviceFeeQuestion.answer) - 0.1, DECIMAL_SCALE))
  };

  const showKickback = !_.isEmpty(kickbacksQuestion.config.choices);
  const showServiceFee = !_.isNil(serviceFeeQuestion.maxValue) || !_.isNil(serviceFeeQuestion.minValue);
  const savingsPlanSelected = stepsData["savings_plan['checkbox']"][0];
  const vlPlanSelected = stepsData["vl_plan['checkbox']"][0];
  const entryFeeInfoText = getContractTypeConfiguration(
    stepsData.custodian.contract,
    'entry_fee_info_text')
  const investmentStrategyTotalCommission = isInvestmentStrategy && _.sum([
    _.get(stepsData, 'modelPortfolio.bfv_commission'),
    _.get(stepsData, 'modelPortfolio.asset_manager_commission'),
    serviceFeeQuestion.answer
  ]);
  const singleAmount = stepsData.fundsBuyTotal;
  const savingsPlanAmount = (savingsPlanSelected || vlPlanSelected) && stepsData["savings_plan_input"];

  const einzeltitelDepotDiscountDisabled = !allowedEinzeltitelDiscountTypes.includes('depot');
  const depotDiscountDisabled = !allowedDiscountTypes.includes('depot') || (stepsData.einzeltitelsOnly && einzeltitelDepotDiscountDisabled)

  const availableDiscounts = discountsQuestion.config.choices || [];
  let availableOverAllDiscounts = availableDiscounts;
  // for assets - overall discount can't be higher then MIN of instruments max_discount
  if(!stepsData.modelPortfolio) {
    const einzeltitelDiscountsAllowed = !einzeltitelDepotDiscountDisabled
      || allowedEinzeltitelDiscountTypes.includes('single_buy')
      || allowedEinzeltitelDiscountTypes.includes('payment_plan');
    let _minDiscount = _.min([stepsData.maxEinzeltitelsInstrumentsOverallDiscount, stepsData.maxFundsInstrumentsOverallDiscount]);
    if (stepsData.einzeltitelsOnly) {
      _minDiscount = stepsData.maxEinzeltitelsInstrumentsOverallDiscount;
    } else if (!einzeltitelDiscountsAllowed) {
      _minDiscount = stepsData.maxFundsInstrumentsOverallDiscount
    }
    availableOverAllDiscounts = availableOverAllDiscounts.filter(o => parseInt(o.value || '0') <= _minDiscount)
  }
  const showDiscounts = !_.isEmpty(availableOverAllDiscounts);

  let serviceFeeInfoText, serviceFeeQuestionRequired = isRequiredQuestion(serviceFeeQuestion);
  if (isInvestmentStrategy){
    serviceFeeInfoText = "Sie können nur den “Anteil Vertrieb p.a. (zzgl. MwSt.)” rabattieren. Weitere Details zur Gesamtvergütung finden Sie unten."
  } else if(isModelPortfolio){
    serviceFeeInfoText = serviceFeeQuestion.minValue && `Wenn Sie eine Rückerstattung der Bestandsprovision angeben haben, müssen Sie mindestens ${toGermanFormat(serviceFeeQuestion.minValue)}% Serviceentgelt angeben.`;
  } else {
    serviceFeeInfoText = serviceFeeQuestion.answer > COMMON_MAX_SERVICE_FEE && `Ihr Eingabe der Servicefee überschreitet die übliche maximale Höhe von ${COMMON_MAX_SERVICE_FEE}%. Bitte überprüfen Sie Ihre Eingabe.`
  }

  return (
    <Grid container direction={"column"} spacing={2}>
      <Grid item xs={12}>
        <div className={clsx(classes.headerTitle, classes.firstHeaderTitle)}>
          Anlageplan
          <span className={classes.linkBottom}>
            <a href={null} onClick={() => onGoToStepClick('product-selection-deposits')}>
              Bearbeiten
            </a>
          </span>
        </div>
        <Grid container spacing={2} style={{padding: '8px 0'}}>
          <Grid item xs={savingsPlanSelected || vlPlanSelected ? 3 : 4}  className={classes.stepInfoContainer}>
            <div className={classes.stepInfoTitle}>Depot</div>
            <div>{stepsData.custodian.depot.depot} {stepsData.custodian.contract.label}</div>
          </Grid>

          <Grid item xs={savingsPlanSelected || vlPlanSelected ? 4 : 3} className={classes.stepInfoContainer} container direction={"column"}>
            <div className={classes.stepInfoTitle}>Einmalanlage</div>
            <div>
              {withEuroOrDash(singleAmount)}
            </div>
            <div className={classes.stepInfoTitle}>Sparplan</div>
            <div>
              {withEuroOrDash(savingsPlanAmount)}
              {!!savingsPlanAmount && (
                <>
                  &nbsp;{stepsData["savings_plan_rotation"]}
                  {stepsData["savings_plan_from_date"] && <>,&nbsp;ab {stepsData["savings_plan_from_date"]}</>}
                </>
              )}
            </div>
          </Grid>

          {(showDiscounts || showServiceFee || showKickback) && (
            <Grid item xs={5} className={classes.stepInfoContainer}>
              <div className={classes.stepInfoTitle} style={{marginBottom: 0}}>Konditionen</div>
              {showServiceFee && (
                <>
                <div className={classes.serviceInputContainer}>
                  <div className={classes.blueLabel}>
                    {serviceFeeQuestion.question_text} {serviceFeeQuestionRequired ? <span className={classes.asterisk}>&nbsp;*</span> : ''}
                    {!!serviceFeeInfoText && <InfoTooltip title={serviceFeeInfoText}/>}
                  </div>
                  <div className={classes.blueInputs}>
                    <Tooltip open={Boolean(serviceFeeQuestion.error)} title={serviceFeeQuestion.error} arrow={true} placement="top">
                      <div style={{position: 'relative', top: '10px'}}/>
                    </Tooltip>
                    <div className={classes.serviceFeeQuestionContainer}>
                      <InputFormElement
                        value={serviceFeeQuestion.answer}
                        onChange={(value) => onAnswerChange(serviceFeeQuestion.uid, value)}
                        inputComponent={PercentageNumberFormat}
                        inputProps={{inputProps: {allowNegative: false, fixedDecimalScale: true}}}
                        type={'text'}
                        custom_classes={{inputRoot: classes.smallInput,}}
                        required={serviceFeeQuestionRequired}
                      />
                      <span className={classes.inputArrows}>
                        <InputArrows topArrowClick={topArrowClicked} bottomArrowClick={bottomArrowClicked} />
                      </span>
                    </div>
                  </div>
                </div>
                <div className={classes.serviceInputContainer}>
                  <div className={classes.blueLabel}>
                    {serviceFeeVatQuestion.question_text} {serviceFeeQuestionRequired ? <span className={classes.asterisk}>&nbsp;*</span> : ''}
                  </div>
                  <div className={classes.blueInputs}>
                    <InputFormElement
                      value={serviceFeeVatQuestion.answer}
                      inputComponent={PercentageNumberFormat}
                      inputProps={{inputProps: {allowNegative: false, fixedDecimalScale: true}}}
                      type={'text'}
                      custom_classes={{inputRoot: classes.smallInput,}}
                      disabled={true}
                      required={serviceFeeQuestionRequired}
                    />
                  </div>
                </div>
                </>
              )}

              {showKickback &&
                <div className={classes.serviceInputContainer}>
                  <div className={classes.blueLabel}>
                    Rückvergütung der Bestandsprovision in %
                    {!!kickbacksQuestion.tooltip && <InfoTooltip title={kickbacksQuestion.tooltip}/>}
                  </div>
                  <div className={classes.blueInputs}>
                    <SelectFormElement
                      options={kickbacksQuestion.config.choices}
                      value={kickbacksQuestion.answer}
                      onChange={handleKickbackChange}
                      disabled={kickbacksQuestion.disabled}
                      custom_classes={{
                        inputRoot: classes.smallSelect,
                      }}
                    />
                  </div>
                </div>
              }

              {showDiscounts && (
                <Grid container className={classes.overallDiscountContainer} alignItems={'flex-end'}>
                  <Grid item>
                    <PrimaryButton
                      text={"Anmerkung"}
                      variant={discountsQuestion.answer.discountsEnabled ? "contained" : "outlined"}
                      disabled={isModelPortfolio && parseFloat(_.get(stepsData, 'modelPortfolio.fee_netto')) == 0}
                      onButtonClick={onEditDiscountsClick} />
                  </Grid>
                  {discountsQuestion.answer.discountsEnabled && (
                    <Grid item className={classes.bgBlue}>
                      <SelectFormElement
                        label={(
                          <span>
                            Depotrabatt&nbsp;
                            {depotDiscountDisabled && (
                              <WarningTooltip color={"rgb(177, 177, 177)"} title={OPTION_IS_NOT_AVAILABLE_FOR_DEPOT_MSG}/>
                            )}
                          </span>
                        )}
                        options={availableOverAllDiscounts}
                        disabled={depotDiscountDisabled}
                        value={discountsQuestion.answer.overall}
                        onChange={(val) => changeDiscount('overall', val)}
                        custom_classes={{
                          inputRoot: classes.smallSelect,
                          labelRoot: classes.smallLabel
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} className={clsx(classes.bgGrey, discountsQuestion.answer.discountsEnabled ? 'editMode' : '')}>
        <div className={classes.headerTitle} >
          Produkte
          <span className={classes.linkBottom}>
            <a href={null} onClick={() => onGoToStepClick('product-selection-investment-recommendation')}>
              Bearbeiten
            </a>
          </span>
        </div>
        {stepsData.infoText.length > 0 && (
          <div className={classes.infoText} style={{marginBottom: 25}}>
            {stepsData.infoText.map((infoText) => (
              <div>
                <InfoIcon />
                <p>{infoText}</p>
              </div>
            ))}
          </div>
        )}
        {!!stepsData.modelPortfolio && (
          <DashboardTable
            structure={tableInvestmentStrategyStructure}
            dataSource={[stepsData.modelPortfolio]}
            expanded={true}
            tableClasses={{...tableClasses, table: classes.tableBlue}}
            withFooter={false}
            withInputs={true}
            options={{
              singleInvestmentAmount: singleAmount,
              savingsPlanAmount: savingsPlanAmount,
              onDiscountChange: changeDiscount,
              discounts: discountsQuestion.answer,
              availableDiscounts,
              availableOverAllDiscounts,
              allowedDiscountTypes,
              itemsLength: 1,
              entryFeeInfoText,
            }}
          />
        )}
        <DashboardTable
          structure={tableStructure}
          dataSource={stepsData.instrumentsGrouped}
          expanded={true}
          tableClasses={tableClasses}
          withFooter={false}
          withInputs={true}
          options={{
            onDiscountChange: changeDiscount,
            onItemDiscountChange: changeItemDiscount,
            discounts: discountsQuestion.answer,
            availableDiscounts,
            itemsLength: stepsData.instrumentsGrouped.length,
            isInvestmentStrategy,
            allowedDiscountTypes,
            allowedEinzeltitelDiscountTypes,
            einzeltitelsOnly: stepsData.einzeltitelsOnly,
            availableOverAllDiscounts,
            isModelportfolio: isModelPortfolio
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.headerTitle}>
          Kosten Depotführung
          <span className={classes.linkBottom}>
            <a href={null} onClick={() => onGoToStepClick('product-selection-depot')}>
              Bearbeiten
            </a>
          </span>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={3} className={classes.stepInfoContainer}>
            <div className={classes.stepInfoTitle}>Ausgewählte Depot:</div>
            <div>{stepsData.custodian.depot.depot} ({stepsData.custodian.contract.label})</div>
          </Grid>
          {!!isInvestmentStrategy && (
            <>
              <Grid item xs={3} className={classes.stepInfoContainer}>
                <div className={classes.stepInfoTitle}>Vermögensverwaltungsentgelt</div>
                <div>{toGermanFormat(investmentStrategyTotalCommission)} p.a. in % (zzgl. MwSt.)
                </div>
                <div>{toGermanFormat(investmentStrategyTotalCommission * vatCoef)} p.a. in % (inkl. MwSt.)</div>
              </Grid>
              <Grid item xs={3} className={classes.stepInfoContainer}>
                <div className={classes.stepInfoTitle}>Anteil Vermögensverwalter</div>
                <div>{toGermanFormat(stepsData.modelPortfolio.bfv_commission)} p.a. in % (zzgl. MwSt.)</div>
                <div>{toGermanFormat(stepsData.modelPortfolio.bfv_commission * vatCoef)} p.a. in % (inkl. MwSt.)</div>
              </Grid>
              <Grid item xs={3} className={classes.stepInfoContainer}>
                <div className={classes.stepInfoTitle}>Anteil Anlageberater</div>
                <div>{toGermanFormat(stepsData.modelPortfolio.asset_manager_commission)} p.a. in % (zzgl. MwSt.)</div>
                <div>{toGermanFormat(stepsData.modelPortfolio.asset_manager_commission * vatCoef)} p.a. in % (inkl. MwSt.)</div>
              </Grid>
            </>
          )}
          <Grid item xs={3} className={classes.stepInfoContainer}>
            <div className={classes.stepInfoTitle}>Depotkosten</div>
            <div>{stepsData.custodian.contract.depot_cost_display || '-'}</div>
          </Grid>
          <Grid item xs={3} className={classes.stepInfoContainer}>
            <div className={classes.stepInfoTitle}>Transaktionskosten</div>
            <div>{stepsData.custodian.contract.transaction_cost_display || '-'}</div>
          </Grid>
          <Grid item xs={3} className={classes.stepInfoContainer}>
            <div className={classes.stepInfoTitle}>Sonstige Kosten</div>
            <div>{stepsData.custodian.contract.others_cost_display || '-'}</div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect()(withStyles(styles)(SummaryStep));
