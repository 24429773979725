import React from "react";
import {FETCH_DATA_INITIAL_STATE} from "../../../hooks/constants";
import _ from "lodash";
import {splitProducts} from "./useYearlyPerformanceData";
import {PRODUCT_TYPE} from "../constants";
import {FactSheetsHandlerResource, ModelPortfolioResource, PortfolioHandlerResource} from "../../../utils/api";
import {fetchMusterdepotPortfolioData, fetchMusterdepotPortfolioId} from "./useMetricsData";
import {
  buildProductConfigurationChangedDeps,
  getChartsSensitiveProductsData,
  getProductCustomerId,
  getProductIdsAsString,
  updateHookDataConfiguration
} from "../utils";
import {DEFAULT_EMPTY_SECTION_MESSAGE, NOT_FULL_INSTR_DATA_MSG} from '../../../utils/constants';


function stressTestItemHasData(item) {
  if (_.isEmpty(item.portfolio)) {
    return false
  }

  return !_.isNil(item.portfolio.maximum_drawdown) && !_.isNil(item.portfolio.recovery_days)
}


export function isStressTestDataFull(data) {
  return _.every(data.map(stressTestItemHasData))
}

export function isStressTestDataEmpty(data) {
  if (!_.isArray(data) || _.isEmpty(data)) {
    return true;
  }

  return _.every(data.map((item) => !stressTestItemHasData(item)))
}

function processProductStressTestResponse(product, response, errors) {
  const result = {
    ...getChartsSensitiveProductsData(product),
    stressTestData: {},
  };

  if (!_.isEmpty(errors) || _.isEmpty(response) || !isStressTestDataFull(response)) {
    result.missingData = true;
    result.missingDataText = NOT_FULL_INSTR_DATA_MSG
  }

  result.stressTestData = response;

  return result;
}


async function fetchAssetsStressTestData(products) {

  const response = await FactSheetsHandlerResource.getHistoricStressTestData(
    undefined, products.map((product) => product.data.isin));

  const data = _.get(response, 'stress_test.data.0');
  const errors = _.get(response, 'stress_test.errors');

  return products.map((product) => {

    let _data = [];
    if (_.isEmpty(errors) && !_.isEmpty(data)) {
      _data = data.map((item) => {
        const productData = _.get(item, `components.${product.data.isin}`);
        return {
          ...item,
          portfolio: productData || {}
        }
      })
    }

    return processProductStressTestResponse(product, _data, errors)
  })
}

async function fetchInvestmentStartegyStressTestData(product, investmentStrategy, startDate, endDate) {
  return fetchMusterdepotStressTestData(
    product, investmentStrategy.related_model_portfolio,
    startDate, endDate, investmentStrategy.id)
}

async function fetchMusterdepotStressTestData(product, musterdepot, startDate, endDate, investmentStrategyId) {

  const portfolioData = await fetchMusterdepotPortfolioData(musterdepot, investmentStrategyId, startDate, endDate);
  let [portfolioId, errors] = await fetchMusterdepotPortfolioId(portfolioData);
  let data = {};
  if (!errors) {
    const response = await ModelPortfolioResource.getStressTestData(
      musterdepot.id, portfolioId, startDate, endDate, undefined, undefined, investmentStrategyId)

    data = _.get(response, 'stress_test.data.0');
    errors = _.get(response, 'stress_test.errors');
  }

  return processProductStressTestResponse(product, data, errors)
}

async function fetchPortfolioStressTestData(product, portfolio, startDate, endDate) {

  const customerId = getProductCustomerId(product);
  const depotId = _.get(product, 'configuration.depot_id');

  const response = await PortfolioHandlerResource.getStressTestData(
    customerId, depotId, startDate, endDate)

  const data = _.get(response, 'stress_test.data.0');
  const errors = _.get(response, 'stress_test.errors');

  return processProductStressTestResponse(product, data, errors)
}

function useStressTestData(products) {
  const [data, setData] = React.useState(FETCH_DATA_INITIAL_STATE);

  React.useEffect(() => {
    if (!_.isEmpty(products) ) {
      fetchStressTestData();
    }

    // triggered when selected products or date range are changed
  }, [getProductIdsAsString(products)]);

  React.useEffect(() => {
    setData((data) => updateHookDataConfiguration(data, products));
  }, [buildProductConfigurationChangedDeps(products)])

  const fetchStressTestData = async () => {
    setData({
      data: null,
      loading: true,
      errors: null,
      updatedAt: +new Date()
    })

    try {

      const [productsAssets, productsOther] = splitProducts(products);

      const handlers = productsOther.map((product) => {
        return {
          [PRODUCT_TYPE.MUSTERDEPOT]: fetchMusterdepotStressTestData,
          [PRODUCT_TYPE.MODEL_PORTFOLIO]: fetchMusterdepotStressTestData,
          [PRODUCT_TYPE.PRIVATE_INVESTMENT]: fetchInvestmentStartegyStressTestData,
          [PRODUCT_TYPE.CUSTOMER_PORTFOLIO]: fetchPortfolioStressTestData
        }[product.type](product, product.data);
      })
      if (productsAssets.length) {
        handlers.push(fetchAssetsStressTestData(productsAssets))
      }

      let result = await Promise.all(handlers);
      result = _.flatten(result);
      const noData = _.every(result, (item) => isStressTestDataEmpty(item.stressTestData))

      setData({
        data: noData ? null : result,
        loading: false,
        errors: noData ? DEFAULT_EMPTY_SECTION_MESSAGE : null,
        updatedAt: +new Date()
      })
    } catch (errors) {
      setData({
        data: null,
        loading: false,
        errors,
        updatedAt: +new Date()
      })
    }
  }

  return data;
}

export default useStressTestData;