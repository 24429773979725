import React from "react";
import moment from "moment";

import {withStyles} from "@material-ui/core/styles";

import styles from "./styles";
import TimePickerFormElement from "../../formElement/TimePickerFormElement";
import {isRequiredQuestion} from "../../../../../utils";
import {FIELD_REQUIRED_MSG} from "../../../../../constants";

function isTimeQuestionRequired(question) {
  return isRequiredQuestion(question);
}

function isTimeQuestionValid(question, step, afterChange, service) {
   if(isTimeQuestionRequired(question)){
     if(!question.answer){
       question.error = FIELD_REQUIRED_MSG;
       return false;
     }
     const isValid = moment(question.answer).isValid();
     question.error = isValid ? null : 'Die Uhrzeit ist nicht korrekt';
     return isValid;
   }

   return true;
}

function getTimeQuestionAnswer(question) {
  let answer = [];
  if(!!question.answer) {
    answer.push(moment(question.answer).format('HH:mm'));
  }
  return {
    question_uid: question.uid,
    answer: answer
  }
}

function initTimeQuestionAnswer(question, answer, force){
  if (answer && (answer.length > 0 || force)) {
    const value = answer[0];
    question.answer = !!value ? moment(value, 'HH:mm') : undefined;
  }
}

export const TimeQuestionMethods = {
  required: isTimeQuestionRequired,
  valid: isTimeQuestionValid,
  answer: getTimeQuestionAnswer,
  initAnswer: initTimeQuestionAnswer,
}

const TimeQuestion = props => {
  const {
    classes,
    question,
    onAnswerChange
  } = props;

  const handleValueChange = (newValue) => {
    question.error = null; // clean error
    onAnswerChange(question.uid, newValue)
  };


  return (
    <div className={classes.calendarQuestionContainer}>
      <TimePickerFormElement
        label={question.question_text}
        value={question.answer || null}
        onChange={handleValueChange}
        required={isTimeQuestionRequired(question)}
        custom_classes={{...question.custom_classes, ...classes}}
        disablePast={question.disable_past}
        disableFuture={question.disable_future}
        error={question.error}
      />
    </div>
  )
};

export default withStyles(styles)(TimeQuestion);