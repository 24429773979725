import React from 'react';
import _ from "lodash";

import {Grid} from "@material-ui/core";
import {ChartSectionBordered} from "../ChartSection/ChartSection";
import WarningTooltip from "../../../../components/WarningTooltip";
import {CORRELATION_MATRIX_CHART_INFO_TEXT, RISK_RETURN_CHART_INFO_ICON_TEXT} from "../../constants";
import clsx from "clsx";
import useStyles from './styles'
import useRiskReturnData from "../../hooks/useRiskReturnData";
import RiskReturnChart from "../charts/RiskReturnChart/RiskReturnChart";
import {PerformanceChartLegend} from "../charts";
import useStressTestData from "../../hooks/useStressTestData";
import StressTestChart from "../charts/StressTestChart/StressTestChart";
import useCorrelationMatrixData from "../../hooks/useCorrelationMatrixData";
import CorrelationMatrix from "../charts/CorrelationMatrix/CorrelationMatrix";
import {ALL_VALUE} from "../../../../components/TimeRangeButtonsPad/constants";


function RiskAnalyseTab(props) {

  const classes = useStyles();

  const [expandedSections, setExpandedSections] = React.useState({...props.expandedSectionsRef.current.riskAnalysTab});
  React.useEffect(() => {
    setExpandedSections({...props.expandedSectionsRef.current.riskAnalysTab})
  },[props.expandedSectionsRef.current])

  const riskReturnData = useRiskReturnData(props.selectedProducts, props.selectedDates);
  const products = (riskReturnData.data || []).filter((product) => !_.isEmpty(product.riskReturnData))
  const stressTestData = useStressTestData(props.selectedProducts, props.selectedDates)
  const correlationMatrixData = useCorrelationMatrixData(props.selectedProducts, props.selectedDates);

  const onExpanded = (sectionKey) => () => {
    setExpandedSections((current) => {
      let newState = !current[sectionKey]
      props.expandedSectionsRef.current.riskAnalysTab[sectionKey] = newState
      return {
        ...current,
        [sectionKey]: newState
      }
    })
  }

  return (
    <>
      <Grid item xs={12}>
        <ChartSectionBordered
          title={(
            <>
              <b>Rendite-Risiko Diagramm</b>
              <WarningTooltip
                title={RISK_RETURN_CHART_INFO_ICON_TEXT}
                icon={<i className={clsx("far fa-info-circle", classes.chartSectionHeaderInfoIcon)} />}
                width={600}
              />
            </>
          )}
          loading={riskReturnData.loading}
          error={riskReturnData.errors}
          displayError
          expanded={expandedSections.risk_return}
          onExpanded={onExpanded("risk_return")}
          content={(
            <Grid container>
              <Grid item xs={12}>
                <RiskReturnChart
                  products={products}
                  getSeriesXY={
                    (product) => [
                      _.get(product, `riskReturnData.${ALL_VALUE}.volatility`) * 100,
                      _.get(product, `riskReturnData.${ALL_VALUE}.return`) * 100
                    ]
                  }
                />
              </Grid>
              <Grid item xs={12} style={{zIndex: 0}}>
                {riskReturnData.data && (
                  <PerformanceChartLegend data={riskReturnData.data.map(i => ({...i, symbol: 'circle'}))} flexDirection={"row"}/>
                )}
              </Grid>
            </Grid>

          )}
        />
      </Grid>
      <Grid item xs={12}>
        <ChartSectionBordered
          title={(<b>Historische Stresstests</b>)}
          loading={stressTestData.loading}
          error={stressTestData.errors}
          displayError
          expanded={expandedSections.stress_test}
          onExpanded={onExpanded("stress_test")}
          content={(
            <Grid container>
              <Grid item xs={12}>
                <StressTestChart
                  products={stressTestData.data || []}
                  updatedAt={stressTestData.updatedAt}
                />
              </Grid>
              <Grid item xs={12}>
                {stressTestData.data && (
                  <PerformanceChartLegend data={stressTestData.data} flexDirection={"row"}/>
                )}
              </Grid>
            </Grid>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <ChartSectionBordered
          title={(
            <>
              <b>Korrelationsmatrix auf Basis der Performance im gewählten Zeitraum</b>
              <WarningTooltip
                title={CORRELATION_MATRIX_CHART_INFO_TEXT}
                icon={<i className={clsx("far fa-info-circle", classes.chartSectionHeaderInfoIcon)} />}
                width={600}
              />
            </>
          )}
          loading={correlationMatrixData.loading}
          error={_.get(correlationMatrixData, 'errors.0')}
          displayError
          expanded={expandedSections.correlation}
          onExpanded={onExpanded("correlation")}
          content={(
            <Grid container>
              <Grid item xs={12}>
                <CorrelationMatrix
                  products={props.selectedProducts}
                  data={correlationMatrixData.data || {}}
                />
              </Grid>
            </Grid>
          )}
        />
      </Grid>
    </>
  )
}

export default RiskAnalyseTab