import React from 'react';
import _ from "lodash";
import PropTypes from "prop-types";
import Grid from '@material-ui/core/Grid';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import useStyles from './styles';
import {PrimaryButton} from "../Buttons";
import { Expanded, Documents, CoverPage, Customers } from './components';


export const DownloadPdfDialogContext = React.createContext();

export function useDownloadPdfDialogContext() {

  const context = React.useContext(DownloadPdfDialogContext)
  if (!context) {
    throw new Error('Extracting context without wrapping your component with context Provider!')
  }

  return context

}

function DownloadPdfDialog({open, onClose, onExport, title, children, exportBtnDisabled, customer, defaultEditorEnabled}) {

  const classes = useStyles();

  const [state, setState] = React.useState({
    editorValue: _.get(customer, 'report_settings.cover_message_content', null),
    editorEnabled: defaultEditorEnabled
  });

  React.useEffect(() => {
    if (!open) {
      setState({});
    }
  }, [open])

  React.useEffect(() => {
    if (!_.isNil(state.editorEnabled)) {
      setState((current) => ({
        ...current,
        showTextEditor: true,
        customers: !!customer ? [customer] : []
      }))
    }
  }, [state.editorEnabled])

  const handleClose = () => onClose && onClose();

  const handleExport = () => onExport && onExport(
    !!state.skipExpanded, !!state.skipSubDepotExpanded, !!state.editorEnabled,
    state.editorValue, state.extraFiles || [], state.customers || []);

  const handlePreview = () => setState((current) => ({
    ...current,
    showTextEditor: current.hasOwnProperty('showTextEditor') ? !current.showTextEditor : true
  }))

  const handleStateValueChange = (fieldName, value) => {
    setState((current) => ({
      ...current,
        [fieldName]: value
    }))
  }

  return (
    <DownloadPdfDialogContext.Provider value={{state, handleStateValueChange}}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.container}
        maxWidth='lg'
        classes={
          {
            root: classes.root
          }
        }
        PaperProps={{
          style: {
            width: 700
          }
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          classes={{
            root: classes.modalTitle
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container spacing={1}>
            {React.Children.map(children, (child) => (
              <Grid item xs={12}>
                {child}
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <PrimaryButton
            text='Abbrechen'
            variant='text'
            onButtonClick={handleClose}
          />
          {state.editorEnabled && (
            <PrimaryButton
              text={state.showTextEditor ? 'Vorschau' : 'Vorschau beenden'}
              onButtonClick={handlePreview}
            />
          )}
          <PrimaryButton
            text='PDF exportieren'
            onButtonClick={handleExport}
            disabled={exportBtnDisabled}
          />
        </DialogActions>
      </Dialog>
    </DownloadPdfDialogContext.Provider>
  )
}


DownloadPdfDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  exportBtnDisabled: PropTypes.bool,
  customer: PropTypes.object,
  defaultEditorEnabled: PropTypes.bool
};

DownloadPdfDialog.propDefs = {
  open: {
    description: 'Flag to indicate if Dialog should be visible or not.'
  },
  title: {
    description: 'Dialog title.'
  },
  onClose: {
    description: 'Dialog onClose handler.'
  },
  onExport: {
    description: 'PDF exporting handler.'
  },
  exportBtnDisabled: {
    description: 'Flag that indicate if export button is disabled.'
  },
  customer: {
    description: 'Customer`s data. Used in case modal should be prefiled with some customer related data.'
  },
  defaultEditorEnabled: {
    description: 'Flag that indicate if editor should be enabled by default or not.'
  }
};

DownloadPdfDialog.defaultProps = {
  title: 'Möchten Sie ein Anschreiben zum Report hinzufügen?',
  defaultEditorEnabled: true
};

DownloadPdfDialog.Expanded = Expanded;
DownloadPdfDialog.Documents = Documents;
DownloadPdfDialog.CoverPage = CoverPage;
DownloadPdfDialog.Customers = Customers;


export default DownloadPdfDialog;