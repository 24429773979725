import CommonAnalysisCard from "../../../../components/AnalysisCard";
import React from "react";
import {withStyles} from "@material-ui/core";
import styles from "./styles";

import {AllocationMap} from "fincite-react-charts";
import {PortfolioUtils} from "../../../../../../utils/utils";
import {CHART_COLORS, DEFAULT_EMPTY_SECTION_MESSAGE} from '../../../../../../utils/constants';



class ChartRegionBreakdown extends React.Component {

  renderChart() {
    const { classes } = this.props;

    let regionData = {'by_region': PortfolioUtils.getPortfolioRegions(this.props.data.portfolio_structure)}

    return <div className={classes.chart}><AllocationMap
            data={regionData}
            title=''
            colors={CHART_COLORS}
            orientation='vertical'
            showLegend={true}
            language='DE'
          /></div>
  }

  render() {
    const { classes } = this.props;

    return <CommonAnalysisCard
        classes={{
          card: classes.root
        }}
        title={'Regionen'}
        loading={this.props.loading}
        content={
          <div className={classes.container}>
            {this.props.error ? this.props.error : this.props.data ? this.renderChart() : DEFAULT_EMPTY_SECTION_MESSAGE}
          </div>
      }
    />
  }
}

export default withStyles(styles)(ChartRegionBreakdown)