import React from "react";
import {PortfolioManagerResource} from "../../../utils/api";
import {useTabsSharedDataContext} from "../components_v2/TabsSharedData/TabsSharedData";
import {generateQueryParamsHash} from "../utils";

const SHARED_DATA_KEY = 'other-assets-data';

export function useOtherAssetsData(customerId, portfolios, startDate, endDate) {

  const [data, setData] = React.useState({
    data: null,
    loading: true,
    error: null,
    updatedAt: +new Date(),
  });

  const paramsForHash = [startDate, endDate];
  const sharedData = useTabsSharedDataContext();

  const fetch = async () => {

    const _setState = (state) => {
      // It is not possible to sync data with shared data in
      // hook because hook is not executed if component destroyed
      setData(state);
      sharedData.setDataValue(SHARED_DATA_KEY, hashKey, state);
    };

    const hashKey = generateQueryParamsHash(customerId, portfolios, ...paramsForHash);

    _setState({
      data: null,
      loading: true,
      error: null,
      updatedAt: +new Date()
    });

    try {
      const response = await PortfolioManagerResource.at(`customer/${customerId}/other-assets/`).get({
        clearing_account: true,
        start_date: startDate,
        end_date: endDate,
        depot_id: portfolios.map(portfolio => portfolio.depotNumber)
      });

      _setState({
        data: response,
        loading: false,
        error: null,
        updatedAt: +new Date()
      })

    } catch (error) {
      _setState({
        data: null,
        loading: false,
        error: error,
        updatedAt: +new Date()
      })
    }
  };

  React.useEffect(() => {
    if (!!customerId) {
      const sharedPortfolioData = !!sharedData
        ? sharedData.getDataValue(SHARED_DATA_KEY, generateQueryParamsHash(customerId, portfolios, ...paramsForHash))
        : undefined;

      if (!!sharedPortfolioData && !sharedPortfolioData.errors) {
        // sync internal hook data with shared data.
        setData(sharedPortfolioData);
        return;
      }

      // fetch data if there is no shared data or shared data was loaded with error
      // check if data fetched for the first time (updatedAt is undefined) to prevent infinite data reloading
      // in case of data loading error, so data will be requested again only if tab changed
      if (!data.updatedAt || !sharedPortfolioData) {
        fetch();
      }
    }
  }, [customerId, JSON.stringify(portfolios), startDate, endDate]);

  return [data, fetch]

}