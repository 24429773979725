import React from "react";
import Rating from '@material-ui/lab/Rating';
import { withStyles } from "@material-ui/core";
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';

import {noMSRating} from "../../../../utils/constants";
import styles from "./styles";


function MsRating({ value, classes }) {
  const isNull = value === null;

  return (
    <>
      {isNull ? <span className={classes.label}>{noMSRating}&nbsp;</span> : (
        <Rating
          readOnly
          value={value}
          icon={<StarRoundedIcon style={{ fill: '#202A38' }} />}
          emptyIcon={<StarBorderRoundedIcon style={{ fill: '#202A38' }} />}
          classes={{ root: classes.ratingWrapper }}
        />
      )}
      <span className={classes.label}>
        &nbsp;-&nbsp;&nbsp;Morningstar Rating
      </span>
    </>
  );
}

export default withStyles(styles)(MsRating);