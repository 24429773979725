import React, {useState} from "react";

import _ from "lodash";
import {AGGREGATED_PORTFOLIO_ID} from "../../../utils/utils";
import {parseResponse} from "../../../utils/api";
import { CHART_COLORS, NOT_FULL_INSTR_DATA_MSG } from '../../../utils/constants';
import {getBenchmarkPortfolio} from "./useRollingVolatilityData";
import { isStressTestDataEmpty, isStressTestDataFull } from '../../ProductComparison/hooks/useStressTestData';
import {useTabsSharedDataContext} from "../components_v2/TabsSharedData/TabsSharedData";
import {generateQueryParamsHash} from "../utils";

async function fetchPortfolioStressTestData(customerId, portfolio, dataProvider, benchmarks, assets, investmentStrategyId) {
  const response = await dataProvider.getStressTestData(
    customerId, portfolio.depotNumber, undefined, undefined, benchmarks, assets, investmentStrategyId);

  return [portfolio, response];
}

export const getDataWarningIsins = (data, warningsKey='portfolio.warnings') => {
  const isinsSet = new Set(_.flatten((data || []).map((dItem) => _.get(dItem, warningsKey) || [])));
  return [...isinsSet.values()];
};

const checkForMissingData = (portfolio) => {
  if (_.isEmpty(portfolio.data) || !isStressTestDataFull(portfolio.data)) {
    portfolio.missingData = true;
    portfolio.missingDataText = NOT_FULL_INSTR_DATA_MSG
  }
};

const SHARED_DATA_KEY = 'stress-test-data';

export function useStressTestData(customerId, portfolios, dataProvider, benchmarks, selectedPortfoliosName, assets, investmentStrategyId) {
  const [data, setData] = useState({
    data: null,
    errors: null,
    loading: true,
    updatedAt: +new Date()
  });


  const paramsForHash = [investmentStrategyId, benchmarks];
  const sharedData = useTabsSharedDataContext();

  React.useEffect(() => {
    if (!!customerId && !_.isEmpty(portfolios)) {
      const sharedPortfolioData = !!sharedData
        ? sharedData.getDataValue(SHARED_DATA_KEY, generateQueryParamsHash(customerId, portfolios, ...paramsForHash))
        : undefined;

      if (!!sharedPortfolioData && !sharedPortfolioData.errors) {
        // sync internal hook data with shared data.
        setData(sharedPortfolioData);
        return;
      }

      // fetch data if there is no shared data or shared data was loaded with error
      // check if data fetched for the first time (updatedAt is undefined) to prevent infinite data reloading
      // in case of data loading error, so data will be requested again only if tab changed
      if (!data.updatedAt || !sharedPortfolioData) {
        fetch();
      }
    }
  }, [customerId, JSON.stringify(portfolios), JSON.stringify(benchmarks)]);

  async function fetch() {

    const _setState = (state) => {
      // It is not possible to sync data with shared data in
      // hook because hook is not executed if component destroyed
      setData(state);
      sharedData.setDataValue(SHARED_DATA_KEY, hashKey, state);
    };

    const hashKey = generateQueryParamsHash(customerId, portfolios, ...paramsForHash);

    _setState({
      data: null,
      errors: null,
      loading: true,
      updatedAt: +new Date()
    });

    try {

      let _portfolios = portfolios;
      if (_portfolios.length > 1) {
        const aggregatedPortfolio = {
          id: AGGREGATED_PORTFOLIO_ID,
          name: selectedPortfoliosName,
          color: CHART_COLORS[0],
          depotNumber: portfolios.map((portfolio) => portfolio.depotNumber)
        };
        _portfolios = [aggregatedPortfolio, ...portfolios];
      }

      const response = await Promise.all(_portfolios.map((portfolio) => {
        return fetchPortfolioStressTestData(customerId, portfolio, dataProvider, benchmarks, assets, investmentStrategyId);
      }));

      const result = response.reduce((result, [portfolio, res]) => {

        const portfolioData = {
          name: portfolio.name,
          color: portfolio.color,
          id: portfolio.id,
        };

        parseResponse(res, 'stress_test',
          data => {
            const portfolioResult = {
              ...portfolioData,
              data,
              warnings: getDataWarningIsins(data)
            };

            checkForMissingData(portfolioResult);
            result[portfolio.id] = portfolioResult;
          },
          error => {
            result[portfolio.id] = {
              ...portfolioData,
              data: undefined,
              error,
              missingData: true,
              missingDataText: error
            }
          }
        )
        return result;
      }, {});

      if (!!benchmarks) {
        const benchmarkPortfolio = {
          ...getBenchmarkPortfolio(benchmarks),
          data: undefined,
        };
        const portfolioWithData = _.find(Object.values(result), (portfolio) => !!portfolio.data);
        if (!!portfolioWithData) {
          benchmarkPortfolio.data = portfolioWithData.data.map((item) => ({
            ...item,
            portfolio: item.benchmark,
          }));
          benchmarkPortfolio.warnings = getDataWarningIsins(benchmarkPortfolio.data);
        }
        checkForMissingData(benchmarkPortfolio);
        result[benchmarkPortfolio.id] = benchmarkPortfolio;
      }
      const noData = _.every(Object.values(result), (item) => isStressTestDataEmpty(item.data));
      _setState({
        data: noData ? null : result,
        errors: null,
        loading: false,
        updatedAt: +new Date()
      })

    } catch (errors) {
      _setState({
        data: null,
        errors: errors,
        loading: false,
        updatedAt: +new Date()
      });
    }
  }

  return data
}