import React from 'react';
import {FETCH_DATA_INITIAL_STATE} from "../../../hooks/constants";
import _ from "lodash";
import {PRODUCT_TYPE} from "../constants";
import {FactSheetsHandlerResource, ModelPortfolioResource, PortfolioHandlerResource} from "../../../utils/api";
import {
  buildProductConfigurationChangedDeps,
  getChartsSensitiveProductsData,
  getProductCustomerId,
  getProductIdsAsString,
  hookDataSorted, updateHookDataConfiguration
} from "../utils";
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../../utils/constants";

const performanceHasFullData = (performance) => {
  if (_.isEmpty(performance)) {
    return false;
  }

  return _.every(performance, (item) => !!item.performance)
}

const performanceHasNoData = (performance) => {
  if (_.isEmpty(performance)) {
    return true;
  }

  return _.every(performance, (item) => !item.performance)
}

const handleProductYearlyPerformanceData = (product, performance, error) => {
  const result = {
    ...getChartsSensitiveProductsData(product),
    portfolio: [],
  }

  if (!_.isEmpty(error) || !performanceHasFullData(performance)) {
    result.missingData = true;
    result.missingDataText = 'Für dieses Instrument bzw. Depot sind Preisdaten nicht für den gesamten gewählten Zeitraum verfügbar. Aus diesem Grunde existieren für die entsprechenden Jahre keine Berechnungen oder nur für einen Teil der Periode.'
  }

  result.year_performance = performance;
  return result
}

function _processAssetYearlyPerformanceData(product, response, error) {
  const _error = error || _.get(response, 'asset_info.error');
  let performance = _.get(response, 'asset_info.data.performance_history.years') || [];

  const currentYearReturn = _.get(response, 'asset_info.data.return_data.return_ytd') || 0;
  const currentYear = new Date().getFullYear();

  performance = Array.from({length: 4}, (v, i) => currentYear - (i + 1)).sort().map((year) => {
    const result = {
      year: year,
      performance: 0,
      performance_eur: 0
    }
    const yearPerformanceData = performance.find((item) => item.year == year);
    if (yearPerformanceData) {
      result.performance =  (_.get(yearPerformanceData, 'data.calc') || 0) / 100
    }

    return result
  });
  performance.push({
    year: currentYear,
    performance: currentYearReturn / 100,
    performance_eur: 0
  })

  return handleProductYearlyPerformanceData(product, performance, _error);
}

export async function fetchAssetsInfoData(products){
  return await FactSheetsHandlerResource.getDataV1AssetInfo(undefined, products.map((product) => product.data.isin));
}

async function fetchAssetsYearlyPerformanceData(products) {
  const response = await fetchAssetsInfoData(products);
  const error = _.get(response, 'asset_info.error');
  const data = _.get(response, 'asset_info.data') || {};

  return products.map((product) => _processAssetYearlyPerformanceData(product, data[product.data.isin], error))
}

async function fetchPortfolioLikeProductYearlyPerformanceData(HandlerResource, product, customerId, portfolios, investmentStrategyId) {
  const response = await HandlerResource.getHistoricalData(
    customerId, portfolios, undefined, undefined,
    false, undefined, false, investmentStrategyId, false,
    true, false, false)
  const error = _.get(response, 'historical.errors');
  const performance = _.get(response, 'historical.data.0.0.year_performance') || [];

  return handleProductYearlyPerformanceData(product, performance, error)
}

async function fetchMusterDepotYearlyPerformanceData(product, musterdepot, investmentStrategyId=undefined) {
  try {
    return fetchPortfolioLikeProductYearlyPerformanceData(
      ModelPortfolioResource, product, musterdepot.id, undefined, investmentStrategyId)
  } catch (errors) {
    throw errors;
  }
}

async function fetchInvestmentStrategyYearlyPerformanceData(product, investmentStrategy) {
  return fetchMusterDepotYearlyPerformanceData(product, investmentStrategy.related_model_portfolio, investmentStrategy.id)
}

async function fetchPortfolioYearlyPerformanceData(product, portfolio) {

  const customerId = getProductCustomerId(product);

  const depotId = _.get(product, 'configuration.depot_id');

  return fetchPortfolioLikeProductYearlyPerformanceData(PortfolioHandlerResource, product, customerId, depotId);
}

export const splitProducts = (products) => products.reduce(([assets, other], product, index) => {
  if (product.type === PRODUCT_TYPE.ASSET) {
    assets.push(product)
  } else {
    other.push(product)
  }
  return [assets, other]
}, [[], []]);

function useYearlyPerformanceData(products) {
  const [data, setData] = React.useState(FETCH_DATA_INITIAL_STATE);

  React.useEffect(() => {
    if (!_.isEmpty(products)) {
      fetchProductsYearlyPerformanceData();
    }
  }, [getProductIdsAsString(products)]);

  React.useEffect(() => {
    setData((data) => updateHookDataConfiguration(data, products));
  }, [buildProductConfigurationChangedDeps(products)])

  const fetchProductsYearlyPerformanceData = async () => {
    setData({
      data: null,
      loading: true,
      errors: null,
      updatedAt: +new Date()
    })
    try {

      const [productsAssets, productsOther] = splitProducts(products);

      const handlers = productsOther.map((product) => {
        return {
          [PRODUCT_TYPE.MUSTERDEPOT]: fetchMusterDepotYearlyPerformanceData,
          [PRODUCT_TYPE.MODEL_PORTFOLIO]: fetchMusterDepotYearlyPerformanceData,
          [PRODUCT_TYPE.PRIVATE_INVESTMENT]: fetchInvestmentStrategyYearlyPerformanceData,
          [PRODUCT_TYPE.CUSTOMER_PORTFOLIO]: fetchPortfolioYearlyPerformanceData
        }[product.type](product, product.data);
      })
      productsAssets.length && handlers.push(fetchAssetsYearlyPerformanceData(productsAssets))

      let result = await Promise.all(handlers);
      result = _.flatten(result);

      const noData = _.every(result, (item) => performanceHasNoData(item.year_performance))

      setData({
        data: noData ? null : result,
        loading: false,
        errors: noData ? DEFAULT_EMPTY_SECTION_MESSAGE : null,
        updatedAt: +new Date()
      })
    } catch (errors) {
      setData({
        data: null,
        loading: false,
        errors,
        updatedAt: +new Date()
      })
    }
  }

  return {
    ...data,
    'data': hookDataSorted(data.data)
  }
}

export default useYearlyPerformanceData;