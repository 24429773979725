import React from 'react';
import {Prompt} from 'react-router';
import connect from "react-redux/es/connect/connect";
import { withRouter } from 'react-router-dom';
import _ from "lodash";

import Footer from './components/Footer';
import {CLIENT_FILTERS, getSearchCustomerType} from "../../components/FilteringPanel/components/ListSelector/constants";
import CustomersList from './components/CustomersList';
import PromptDialog from '../../components/PromptDialog';
import {CustomerReportSettingResource} from '../../utils/api';
import {
  paginateArray,
  UserUtils
} from '../../utils/utils';
import {ERROR_MODAL_MESSAGE} from '../../utils/constants';
import {PRODUCT_TYPES_WITH_ALL_OPTION} from "../ReportGroupCreation/constants";

import theme from '../../themes/mainTheme';
import {SORTING_TYPES} from "../../components/FilteringPanel/components/SortingFilter/constants";
import {filterCustomers, sortCustomers} from "../RiskDashboard/utils";
import {DASHBOARD_TYPE, DASHBOARD_SETTINGS_TYPE} from './constants'
import CustomerFilteringPanel from "../../components/CustomersSelectorProviderNew/components/CustomerFilteringPanel";
import { onCustomerSettingsChanged, resetDashboardSettings } from './utils';
import withNotification from "../../components/NotificationProvider";


const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
});

const CustomerSettings = (props) => {
  
    const {
      history,
      auth,
      dashboardType,
      dispatch,
      preselectedCustomerId,
      newDesign,
    } = props;

    const [isFirstRender, setIsFirstRender] = React.useState(true);
    const [customers, setCustomers] = React.useState(undefined);
    const [loading, setLoading] = React.useState(true);
    const [page, setPage] = React.useState(1);
    const [settingsChanges, setSettingChanges] = React.useState([]);
    const [isSettingsUpload, setSettingsUpload] = React.useState(false);
    const [isSettingsSuccessUpload, setSettingsSuccessUpload] = React.useState(undefined);
    const [showSettingsSaveDialog, setShowSettingsSaveDialog] = React.useState(false);
    const settingsSaveDialogTitle = '';
    const settingsSaveDialogMessage = <><p>Sie haben Änderungen vorgenommen, diese aber nicht gespeichert. Wenn Sie die Seite vor Speicherung verlassen, gehen Ihre Änderungen verloren.</p><p>Sind Sie sicher, dass Sie die Seite verlassen möchten?</p></>
    const okBtnLabel = 'Auf Seite bleiben';
    const cancelBtnLabel = 'Seite verlassen';
    const [redirectToPathName, setRedirectToPathName] = React.useState('');

    const [filteredCustomers, setFilteredCustomers] = React.useState(undefined);
  
    const [filters, setFilters] = React.useState({
      search: '',
      first_letter: '',
      sortingType: SORTING_TYPES.NAME_ASC,
      triggerFilter: true
    });

    const [defaultSearchValue, setDefaultSearchValue] = React.useState('')

    React.useEffect(() => {
      // on first render when customers are loaded if preselected customer is passed - search him
      if(isFirstRender && filteredCustomers && filteredCustomers.length > 0 && preselectedCustomerId){
        setIsFirstRender(false)
        setDefaultSearchValue(preselectedCustomerId)
        handleSearchClicked(preselectedCustomerId)
      }
    }, [filteredCustomers])

    const [filterResult, setFilterResult] = React.useState({
      count: undefined,
      word: undefined
    });

    const [parameters, setParameters] = React.useState({
      customerType: getSearchCustomerType(auth).value
    });
    const [companyStatus, setCompanyStatus] = React.useState(PRODUCT_TYPES_WITH_ALL_OPTION.ALL);

    React.useEffect(() => {
        fetchCustomers();
    }, [parameters, companyStatus]);
  
    React.useEffect(() => {
      handleFilterCustomers();
    }, [customers]);

  React.useEffect(() => {
    if (filters.triggerFilter) {
      handleFilterCustomers();
    }
  }, [filters.triggerFilter]);

    const getFilters = (external_setting) => {
      let filters = {};

      if (!_.isNil(parameters)) {
        filters['customer_type'] = parameters.customerType
        if (parameters.requestType == CLIENT_FILTERS.SUB_AGENCY) {
          filters.sub_agency = parameters.customerType
          filters.customer_type = parameters.requestType
        } else if (parameters.requestType == CLIENT_FILTERS.SUB_BROKER) {
          filters.sub_broker = parameters.customerType
          filters.customer_type = parameters.requestType
        }
      }

      if (!_.isNil(companyStatus.value)) {
        filters['group_identifier_code'] = companyStatus.value
      }

      if(!_.isNil(external_setting)){
        _.merge(filters, external_setting)
      }

      return filters;
    };
  
    const fetchCustomers = async () => {
      try {
        setLoading(true);
        let response = await CustomerReportSettingResource.at('customer/dashboard-customer-settings/').get(getFilters(
          {'settings_type': dashboardType}
        ));

        setLoading(false);
        setCustomers(response);
  
      } catch (err) {
        setLoading(false);
        props.displayNotification('error', ERROR_MODAL_MESSAGE);
      }
    };

    const syncSettingsWithUpdated = (changes) => {
      let customersCopy = [...customers];

      for (let i = 0; i < changes.length; i++) {
        for (let j = 0; j < customersCopy.length; j++) {
          for(let k = 0; k < customersCopy[j].length; k++) {
            if (customersCopy[j][k].customer_id === changes[i].customer_id) {
              customersCopy[j][k].settings = changes[i];
              break;
            }
          }
        }
      }
  
      setCustomers(customersCopy);
    }
  
    const uploadSettings = async () => {
      setSettingsUpload(true);
      try {

        let settingsChangesBody = settingsChanges.map(change => ({
          ...change,
          date_range: change.date_range.toString(),
          broker_id: auth.user.broker_id,
        }))

        let response = await CustomerReportSettingResource.at('customer/dashboard-settings/create/').post({
          data: settingsChangesBody,
          settings_type: dashboardType === DASHBOARD_TYPE.CUSTOMER ? DASHBOARD_SETTINGS_TYPE.SINGLE_CUSTOMER : DASHBOARD_SETTINGS_TYPE.SINGLE_BROKER,
        });
        setSettingsSuccessUpload(true);
  
        const uploadErrors = response.filter(responseRow => responseRow.errors);
        if (uploadErrors.length) {
          //  TODO handle upload errors
        }

        setTimeout(function(){
          syncSettingsWithUpdated(response);
          setSettingsUpload(false);
          setSettingsSuccessUpload(false);
          setSettingChanges([]);
        }, 2000);
      } catch (err) {
        props.displayNotification('error', err);
        setSettingsUpload(false);
        setSettingsSuccessUpload(false);
      }
    };

    const resetSettings = async (type, settings_id, customer_id) => {
      let response = await resetDashboardSettings(type, settings_id, customer_id, dispatch);
      syncSettingsWithUpdated([{...response, customer_id: customer_id}]);
    };
  
    const handleSearchClicked = (value) => {
      let searchValue = value ? value.trim() : ''

      setFilters({
        ...filters,
        first_letter: '',
        search: searchValue,
        triggerFilter: true
      });
    };
  
    const handleClientTypeChanged = (event) => {
      setParameters({
        customerType: event.target.value,
        requestType: event.nativeEvent.target.getAttribute('data-type')
      })
    };

    const handleSearchByLetter = (letter) => {
      let searchValue = letter ? letter.trim() : ''

      setFilters({
        ...filters,
        first_letter: searchValue,
        search: '',
        triggerFilter: true
      });
    };
  
    const handleFilterCustomers = () => {
      if (Object.entries(filters).length !== 0) {
        let customersJoined = [].concat.apply([], customers || []);
  
        let filtered = [...filterCustomers(customersJoined, filters)];
  
        filtered = sortCustomers(filtered, filters.sortingType);

        setFilters(({
          ...filters,
          triggerFilter: false
        }))
        let searchText = filters.first_letter ? `Anfangszeichen ${filters.first_letter}` : filters.search;

        setFilterResult({
          word: searchText,
          count: filtered.length
        })

        setFilteredCustomers([...paginateArray(filtered, 10)]);
      }
    };

    const handleSaveClicked = () => {
      return uploadSettings();
    };
  
    const handleCustomerSettingsChanged = (customer, field, value) => {

      // checking if we already updated fields of the customer
      let updatedCustomerData = _.find(settingsChanges, change => change.customer_id == customer.customer_id) || {...customer.settings, customer_id: customer.customer_id};

      const [newCustomerData, isOldEqualNew] = onCustomerSettingsChanged(updatedCustomerData, customer.settings, field, value);

      // getting all customers that we updated except for the one we are currently changing
      let updatedCustomers = _.filter(settingsChanges, change => change.customer_id != customer.customer_id);

      // if new settings of the customer are not the same as old we add him to list of updated customers
      if (!isOldEqualNew) {
        updatedCustomers.push(newCustomerData);
      }

      setSettingChanges(updatedCustomers)
    };
  
  
    let leavePageConfirmation = false;
    let handleWarningModalState = (state) => {
      setRedirectToPathName(state.pathname);
      setShowSettingsSaveDialog(true);
      return leavePageConfirmation;
    };
  
    const handleClickNoSaveDialog = () => {
      setShowSettingsSaveDialog(false);
      leavePageConfirmation = true;
  
      if (redirectToPathName) {
        history.push(redirectToPathName);
      }
    };
  
    const handleClickYesSaveDialog = () => {
      setShowSettingsSaveDialog(false);
    };
  
    const handleClickCancelSaveDialog = () => {
      setShowSettingsSaveDialog(false);
    };

    const renderLoading = () => {
      return (
        <CustomersList loading={loading} customers={[[{}, {}, {}]]}/>
      )
    };

    const renderList = () => {
      return (
        <CustomersList
          customers={filteredCustomers}
          updatedCustomers={settingsChanges} // todo passing two lists with customers and customers' settings should be refactored
          currentPage={page}
          accentColor={theme.palette.primary.main}
          handleCustomerSettingsChanged={handleCustomerSettingsChanged}
          dashboardType={dashboardType}
          filterResult={filterResult}
          resetSettings={resetSettings}
          newDesign={newDesign}
        />
      )
    };

    return (
      <React.Fragment>
        <Prompt
          when={settingsChanges.length > 0}
          message={handleWarningModalState}
        />
        <CustomerFilteringPanel
          customerType={parameters.customerType}
          defaultSearchValue={defaultSearchValue}
          handleSearchByLetter={handleSearchByLetter}
          handleSearchClicked={handleSearchClicked}
          handleClientTypeChanged={handleClientTypeChanged}
          isChief={UserUtils.isChief(auth)}
          user={auth.user}
          useNameAndKundenNrTooltip
          loading={loading}
        />
        {loading ? renderLoading() : renderList()}
        <Footer 
          isVisible={settingsChanges.length > 0 || !!isSettingsSuccessUpload}
          isLoading={isSettingsUpload}
          isSettingsSuccessUpload={isSettingsSuccessUpload}
          handleSaveClicked={handleSaveClicked}/>
        <PromptDialog 
          isOpen={showSettingsSaveDialog}
          title={settingsSaveDialogTitle}
          message={settingsSaveDialogMessage}
          okBtnLabel={okBtnLabel}
          cancelBtnLabel={cancelBtnLabel}
          handleYesBtn={handleClickYesSaveDialog}
          handleNoBtn={handleClickNoSaveDialog}
          handleCancel={handleClickCancelSaveDialog}
        />
      </React.Fragment>
    )
  }

export default connect(mapStateToProps)(withNotification(withRouter(CustomerSettings)));