import React from "react";
import _ from "lodash"

import withStyles from "@material-ui/core/styles/withStyles";
import {ArrowBack} from "@material-ui/icons";

import styles from "../styles";
import PrimaryButton from "../../../../../../../components/Buttons/PrimaryButton";
import ConfirmModal from "../../../../../../../components/ConfirmModal";
import SuccessDialog from "../../../../../../../components/SuccessDialog";
import StepFooter from "../../StepFooter/StepFooter";
import {ASSETS_STEP_STATE, SAVE_AND_CONTINUE_BTN_LABEL} from "../../../../../constants";
import MainStep from "./components/MainStep";
import ListStep from "./components/ListStep";
import FormStep from "./components/FormStep";
import {AssetsQuestionMethods} from "../../question/AssetsQuestion/AssetsQuestion";
import clsx from "clsx";

const AssetsStep = props => {
  const {
    classes,
    uid,
    name,
    questions,
    help_text,
    isFirst,
    onNextClick,
    onPrevClick,
    onAnswerChange,
    extraRightButtons
  } = props;

  const [step, setStep] = React.useState(ASSETS_STEP_STATE.MAIN);
  const [activeQuestion, setActiveQuestion] = React.useState(null);
  const [activeAsset, setActiveAsset] = React.useState(null);
  const [itemToDelete, setItemToDelete] = React.useState(null);

  const handleAddClick = (question) => {
    setStep(ASSETS_STEP_STATE.LIST);
    setActiveQuestion(question);
  }

  const handleDeleteClick = (question, item, index) => {
    setActiveQuestion(question);
    setItemToDelete({...item, index: index});
  }

  const onClickConfirmDeleteAsset = () => {
    const answer = activeQuestion.answer || [];
    _.pullAt(answer, itemToDelete.index);
    onAnswerChange(activeQuestion.uid, answer);

    setItemToDelete({...itemToDelete, deleted: true});
  }

  const onCloseDeleteAsset = () => {
    setItemToDelete(null);
    setActiveQuestion(null);
  }

  const getDeleteModalTitle = () => {
    return (
      <span style={{paddingRight: 150}}>
        <i className={'fa fa-exclamation-circle'}
           style={{color: '#f50702', verticalAlign: 'middle', marginRight: 10, fontSize: 36}} aria-hidden="true" />
        Möchten Sie <b>“{itemToDelete.info}”</b> löschen?
      </span>
    )
  }

  const getDeleteSuccessModalTitle = () => {
    return (
      <p style={{margin: '1em 10em 1em 0', fontSize: 16}}>
        <b>“{itemToDelete.info}”</b> ist gelöscht.
      </p>
    )
  }

  const handleEditClick = (question, asset, index) => {
    setStep(ASSETS_STEP_STATE.EDIT_FORM);
    setActiveQuestion(question);
    setActiveAsset({...asset, index: index});
  }

  const handleAssetClick = (asset) => {
    setStep(ASSETS_STEP_STATE.ADD_FORM);
    setActiveAsset(asset);
  }

  const handleSaveClick = () => {
    const answer = activeQuestion.answer || [];
    if(step === ASSETS_STEP_STATE.EDIT_FORM) {
      answer[activeAsset.index] = activeAsset;
      onAnswerChange(activeQuestion.uid, answer);
    } else {
      onAnswerChange(activeQuestion.uid, [...answer, activeAsset]);
    }

    setStep(ASSETS_STEP_STATE.MAIN);
    setActiveQuestion(null);
    setActiveAsset(null);
  }

  const isFormStep = () => {
    return [ASSETS_STEP_STATE.ADD_FORM, ASSETS_STEP_STATE.EDIT_FORM].includes(step);
  }

  const handleBackClick = () => {
    if (step === ASSETS_STEP_STATE.ADD_FORM) {
      return setStep(ASSETS_STEP_STATE.LIST);
    }

    if ([ASSETS_STEP_STATE.LIST, ASSETS_STEP_STATE.EDIT_FORM].includes(step)) {
      return setStep(ASSETS_STEP_STATE.MAIN);
    }

    return onPrevClick();
  }

  const handleNextClick = () => {
    if (isFormStep()) {
      return handleSaveClick();
    }

    return onNextClick();
  }

  const handleActiveAssetInfoChange = (info) => {
    setActiveAsset({...activeAsset, info});
  }

  const handleActiveAssetValueChange = (value) => {
    setActiveAsset({...activeAsset, value});
  }

  const getNextButtonMessage = () => {
    if (step === ASSETS_STEP_STATE.MAIN) {
      return SAVE_AND_CONTINUE_BTN_LABEL;
    }

    return 'Speichern';
  }

  const getNextButtonDisabled = () => {
    if (step === ASSETS_STEP_STATE.MAIN) {
      return false;
    }

    return !AssetsQuestionMethods.validAnswer(activeQuestion, activeAsset);
  }

  const getLeftButtonItems = () => {
    if (isFirst && step === ASSETS_STEP_STATE.MAIN) {
      return [];
    }

    return [BackButton];
  }

  const getRightButtonItems = () => {
    if (step === ASSETS_STEP_STATE.LIST) {
      return [];
    } else if (step === ASSETS_STEP_STATE.MAIN){
      return [...extraRightButtons, NextButton]
    }

    return [NextButton]
  }


  const BackButton = (
    <PrimaryButton
      text={"Zurück"}
      icon={<ArrowBack color={'primary'}/>}
      variant={"outlined"}
      onButtonClick={handleBackClick}
    />
  )

  const NextButton = (
    <PrimaryButton
      text={getNextButtonMessage()}
      onButtonClick={handleNextClick}
      disabled={getNextButtonDisabled()}
    />
  )

  return (
    <div className={classes.stepContainer}>
      <div className={classes.stepScrollContainer}>
        <div className={clsx(classes.stepContent , 'app-page-container')}>
          {step === ASSETS_STEP_STATE.MAIN && (
            <MainStep
              help_text={help_text}
              uid={uid}
              name={name}
              questions={questions}
              onAddClick={handleAddClick}
              onEditClick={handleEditClick}
              onDeleteClick={handleDeleteClick}
              onAnswerChange={onAnswerChange}
            />
          )}
          {step === ASSETS_STEP_STATE.LIST && (
            <ListStep
              activeType={activeQuestion.uid}
              onAssetClick={handleAssetClick}
            />
          )}
          {isFormStep() && (
            <FormStep
              activeAsset={activeAsset}
              onInfoChange={handleActiveAssetInfoChange}
              onValueChange={handleActiveAssetValueChange}
              infoRequired={activeQuestion.config.answer_format_config[1].required}
              valueRequired={activeQuestion.config.answer_format_config[2].required}
            />
          )}
        </div>
        <div className={classes.scrollFix} />
      </div>

      <StepFooter
        leftItems={getLeftButtonItems()}
        rightItems={getRightButtonItems()}
      />
      {step === ASSETS_STEP_STATE.MAIN && itemToDelete &&
        <>
          <ConfirmModal
            open={!itemToDelete.deleted}
            handleClose={onCloseDeleteAsset}
            handleConfirm={onClickConfirmDeleteAsset}
            title={getDeleteModalTitle()}
            cancelBtnText={'Abbrechen'}
            confirmBtnText={'Löschen'}
          />
          <SuccessDialog
            open={!!itemToDelete.deleted}
            message={getDeleteSuccessModalTitle()}
            onClose={onCloseDeleteAsset}
          />
        </>
      }
    </div>
  );
};

AssetsStep.defaultProps = {
  extraRightButtons: []
};

export default withStyles(styles)(AssetsStep);
