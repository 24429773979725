export const ADD_GROUP_FOR_GUIDE_KEY = 'ADD_GROUP_FOR_GUIDE';
export const REMOVE_GROUP_FOR_GUIDE_KEY = 'REMOVE_GROUP_FOR_GUIDE';
export const SET_GROUPS_KEY = 'SET_GROUPS';
export const SET_LOADING_KEY = 'SET_LOADING';
export const SET_ERRORS_KEY = 'SET_ERRORS';

export const customerForGuide = {
  id: 'id',
  customer_id: '1234567890',
  customer_full_name: 'Maximillian Mustermann',
  aggregated_portfolio_value: 10000
};

export const groupForGuide = {
  id: 'id',
  name: 'Neukunden seit 2018',
  report_type: 1,
  distribution_type: 2,
  date_range: 2,
  expanded: true,
  customers: [customerForGuide]
};

export const DASHBOARD_SETTINGS_TYPE = {
    BROKER_GROUP: 0,
    SINGLE_BROKER: 1,
    CUSTOMER_GROUP: 2,
    SINGLE_CUSTOMER: 3,
    GENERAL: 4
}

export const DASHBOARD_TYPE = {
  BROKER: 0,
  CUSTOMER: 1,
}

export const DASHBOARD_GROUP_TYPE_MENU_ITEM_MAPPING = {
  BROKER_GROUP: 'BROKER_DASHBOARD_SETTINGS',
  CUSTOMER_GROUP: 'CUSTOMER_DASHBOARD_SETTINGS'
}

// message that explains that aggregate selected switch is disabled as only one portfolio is selected
export const aggregateSelectedDisabledExplanation = 'Bei nur einem gewählten Depot, ist diese Funktion nicht verfügbar.';
export const DISABLED_FOR_CUSTOMER_SETTING_MSG = 'Nicht für Kundenansicht verfügbar';