import React from 'react';

export const PRODUCT_COMPARISON_STEP = {
  PRODUCT_SELECTION: '0',
  COMPARISON: '1'
}

export const PRODUCT_TYPE = {
  ASSET: 0,
  MUSTERDEPOT: 1,
  MODEL_PORTFOLIO: 2,
  PRIVATE_INVESTMENT: 3,
  CUSTOMER_PORTFOLIO: 4
}

export const PRODUCTS_TO_COMPARE_MAX_COUNT = 10;

export const EXTEND_COMPARISON_WITH_PRODUCTS_STORAGE_KEY = 'EXTEND_COMPARISON_WITH_PRODUCTS';

export const PERFORMANCE_CHART_INFO_ICON_TEXT = (
  <>
    <p>Für Instrumente mit einer unterlegten Ein- und Auszahlungsstruktur (z.B. reale Portfolios) wird zur Vergleichbarkeit von Anlagen innerhalb des Performance Charts die “Zeitgewichtete Rendite” verwendet. Dies ist die Standardmethode, um unabhängig von Zeitpunkt und Höhe von Einzahlungen und Auszahlungen die reine Wertentwicklung eines Portfolios für einen beliebigen Zeitraum mit Fonds, alternativen Anlagen oder Indizes zu vergleichen. Fonds hingegen werden mit der Geldgewichteten Renditeberechnung dargestellt.</p>
  </>
)

export const RISK_RETURN_CHART_INFO_ICON_TEXT = (
  <p>Gegenüberstellung von Rendite (annualisiert, berechnet nach zeitgewichteter Methode) und Volatilität (Schwankungsbreite) der ausgewählten Instrumente im gewählten Betrachtungszeitraum. Instrumente, deren letzter Preis länger als 6 Monate zurückliegt, oder für welche nicht genug Preisdaten aus der gewählten Periode zur Verfügung stehen sind in dem Diagramm nicht berücksichtigt.</p>
)

export const CORRELATION_MATRIX_CHART_INFO_TEXT = (
  <p>Die Korrelationsmatrix zeigt, wie eng die Wertentwicklungen der ausgewählten Produkte während des gewählten Zeitraums miteinander verbunden sind. Anhand des Korrelationskoeffizienten, welcher Werte zwischen -1 und +1 annehmen kann, lässt sich die Art der Beziehung zwischen den Produkten erkennen: Ein Wert von +1 weist auf eine hohe positive Korrelation hin, bei der sich die Produkte nahezu identisch entwickeln. Ein Wert von 0 zeigt an, dass keine Abhängigkeit zwischen ihnen besteht, während ein Wert von -1 auf eine entgegengesetzte Entwicklung hindeutet. Je niedriger die Korrelation, desto höher der Diversifikationsvorteil.</p>
)

export const PERFORMANCE_CHART_MISSING_DATA_INFO_TEXT = 'Für dieses Instrument bzw. Depot sind Preisdaten nicht für den gesamten gewählten Zeitraum verfügbar.';

export const PRODUCT_SELECTION_TIME_SELECTOR_DATE_CHANGED = 'PRODUCT_SELECTION_TIME_SELECTOR_DATE_CHANGED';
export const PRODUCT_COMPARISON_SET_COMPARISON = 'PRODUCT_COMPARISON_SET_COMPARISON';

export const NEW_COMPARISON_STORAGE_KEY = 'newComparison';
export const EXISTING_COMPARISON_STORAGE_KEY = 'existingComparison';
export const COULD_NOT_GET_PRODUCT_DETAILS = 'Fehler beim Laden der Produktdetails'
export const PROCESS_TYPES = {
  LIST: 0,
  EDIT: 1,
  COPY: 2,
  OPEN: 3,
  EXTEND: 4,
  DELETE: 5
};
export const LIST_PROCESS = {
  type: PROCESS_TYPES.LIST,
  comparison: undefined
};