import React from "react";
import moment from "moment";
import _ from "lodash";

import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import styles from "./styles";
import CheckboxFormElement from "../../formElement/CheckboxFormElement";
import InputFormElement from "../../formElement/InputFormElement";
import CalendarFormElement from "../../formElement/CalendarFormElement";
import {isRequiredQuestion, isValidValue, requiredCheck} from "../../../../../utils";
import FormLabel from "@material-ui/core/FormLabel";
import {FIELD_REQUIRED_MSG} from "../../../../../constants";

function isCalendarQuestionRequired(question) {
  return isRequiredQuestion(question);
}

function isCalendarQuestionValid(question, step, afterChange, service) {
  const answerConfig = question.config.answer_format_config;
  const calendarSubQuestion = answerConfig[0];
  const inputSubQuestion = answerConfig[1];
  const isCheckboxRequired = isRequiredQuestion(question);
  question.error = {};

  if (question.answer) {
    const isChecked = question.answer.checkbox === true;
    if(isCheckboxRequired && !isChecked){
      question.error.checkbox = FIELD_REQUIRED_MSG;
    }

    if(isChecked) {
      if(inputSubQuestion.required && !isValidValue(question.answer.input)){
        question.error.input = FIELD_REQUIRED_MSG;
      }

      if(calendarSubQuestion.required && !question.answer.calendar){
        question.error.calendar = FIELD_REQUIRED_MSG;
      } else if(question.answer.calendar) {
        const date = moment(question.answer.calendar);
        if (!date.isValid() || date.diff(moment(), 'days', true) > 0) {
          question.error.calendar = 'Das Datum ist nicht korrekt';
        }
      }
    }

    return _.isEmpty(question.error);
  }

  return !isCheckboxRequired;
}

function getCalendarQuestionAnswer(question) {
  let answer = [];
  if (question.answer && question.answer.checkbox === true) {
    const answerConfig = question.config.answer_format_config;
    answer.push({
      [answerConfig[0].key]: moment(question.answer.calendar).format('DD.MM.YYYY'),
      [answerConfig[1].key]: question.answer.input
    })
  }

  return {
    question_uid: question.uid,
    answer: answer
  };
}

function initCalendarQuestionAnswer(question, answer, force){
  if (answer && (answer.length > 0 || force)) {
    const value = answer[0];
    const answerConfig = question.config.answer_format_config;
    const date = _.get(value, answerConfig[0].key);
    question.answer = {
      checkbox: !!_.get(answer, '0.checkbox', true),
      calendar: !!date ? moment(date, 'DD.MM.YYYY') : undefined,
      input: _.get(value, answerConfig[1].key)
    };
  }
}

export const CalendarQuestionMethods = {
  required: isCalendarQuestionRequired,
  valid: isCalendarQuestionValid,
  answer: getCalendarQuestionAnswer,
  initAnswer: initCalendarQuestionAnswer,
}

const CalendarQuestion = props => {
  const {
    classes,
    question,
    onAnswerChange
  } = props;

  const answerConfig = question.config.answer_format_config;
  const calendarSubQuestion = answerConfig[0];
  const inputSubQuestion = answerConfig[1];
  if(!question.hasOwnProperty('error')){
    question.error = {};
  }

  const isCheckboxRequired = isRequiredQuestion(question) || requiredCheck(question);
  const isCalendarRequired = calendarSubQuestion.required;
  const isInputRequired = inputSubQuestion.required;


  const defaultCheckboxValue = false;
  const defaultCalendarValue = null;
  const defaultInputValue = '';

  const checkboxValue = question.answer && question.answer.checkbox || defaultCheckboxValue;
  const calendarValue = question.answer && question.answer.calendar  || defaultCalendarValue;
  const inputValue = question.answer && question.answer.input || defaultInputValue;

  const defaultAnswer = {checkbox: defaultCheckboxValue, calendar: defaultCalendarValue, input: defaultInputValue};

  const helpText = question.info;

  React.useEffect(() => {
    if (!question.answer) {
      onAnswerChange(question.uid, defaultAnswer);
    }
  })

  const handleCheckboxValueChange = (newValue) => {
    if (newValue === false) {
      question.error = {};  // clean all errors
      onAnswerChange(question.uid, defaultAnswer)
    } else {
      question.error.checkbox = null;  // clean checkbox error
      onAnswerChange(question.uid, {...question.answer, checkbox: newValue})
    }
  };

  const handleCalendarValueChange = (newValue) => {
    question.error.calendar = null;  // clean calendar error
    onAnswerChange(question.uid, {...question.answer, calendar: newValue})
  };

  const handleInputValueChange = (newValue) => {
    question.error.input = null;  // clean input error
    onAnswerChange(question.uid, {...question.answer, input: newValue})
  };

  const formLabelClasses = {root: classes.labelRoot, asterisk: classes.asterisk};

  return (
    <div className={classes.calendarQuestionContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CheckboxFormElement
            value={checkboxValue}
            label={question.question_text}
            onChange={handleCheckboxValueChange}
            name={question.uid}
            required={isCheckboxRequired}
            error={question.error.checkbox}
            helpTextUsed={!!helpText}
          />
          {helpText && <div className={classes.helpText}>{helpText}</div>}
        </Grid>
        <Grid item xs={6}>
          <FormLabel error={question.error.calendar} classes={formLabelClasses} required={isCalendarRequired}>
            {calendarSubQuestion.name}
          </FormLabel>
        </Grid>
        <Grid item xs={6}>
          <CalendarFormElement
            value={calendarValue}
            onChange={handleCalendarValueChange}
            required={isCalendarRequired}
            disabled={!checkboxValue}
            disableFuture
            error={question.error.calendar}
          />
        </Grid>
        <Grid item xs={6}>
          <FormLabel error={question.error.input} classes={formLabelClasses} required={isInputRequired}>
            {inputSubQuestion.name}
          </FormLabel>
        </Grid>
        <Grid item xs={6}>
          <InputFormElement
            value={inputValue}
            onChange={handleInputValueChange}
            required={isInputRequired}
            disabled={!checkboxValue}
            error={question.error.input}
          />
        </Grid>
      </Grid>
    </div>
  )
};

export default withStyles(styles)(CalendarQuestion);