import React from "react";

import Grid from "@material-ui/core/Grid";

import InputQuestion from "../../../../question/InputQuestion";
import FormLabelElement from "../../../../formElement/LabelFormElement/LabelFormElement";
import InputFormElement from "../../../../formElement/InputFormElement";
import RadioQuestion from "../../../../question/RadioQuestion";
import DateQuestion from "../../../../question/DateQuestion/DateQuestion";

const ProtocolConversationParticipants = (props) => {
  const {
    classes,
    onAnswerChange,
    dataService,
    interviewOtherPeoplesInput,
    interviewDateType,
    interviewRepeatedDate,
    interviewBrokerInput,
    interviewCustomerInput,
    interviewAccountHolder1Input,
    interviewAccountHolder2Input,
  } = props;

  const holders = [interviewAccountHolder1Input, interviewAccountHolder2Input].filter(q => !q.hidden);

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormLabelElement label={dataService.isNewDesign ? "Wer hat an dem Gespräch teilgenommen?" : "Gesprächsteilnehmer"} required={true} customClasses={{labelRoot: 'bold'}} style={{margin: 0, padding: 0, marginTop: 16}}/>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <InputQuestion
              question={interviewBrokerInput}
              onAnswerChange={onAnswerChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container direction={'column'}>
              {dataService.useMembers ?
                holders.map(q => (
                  <Grid item xs={12} md={4} lg={4} key={q.uid}>
                    <InputQuestion
                      question={q}
                      onAnswerChange={onAnswerChange}
                    />
                  </Grid>
                )) :
                <Grid item xs={12} md={4} lg={4}>
                  <InputQuestion
                    question={interviewCustomerInput}
                    onAnswerChange={onAnswerChange}
                  />
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <FormLabelElement label={interviewOtherPeoplesInput.question_text} required={false} style={{marginTop: 12, padding: 0, marginBottom: 12}}/>
            <InputFormElement
              name={interviewOtherPeoplesInput.uid}
              value={interviewOtherPeoplesInput.answer || ''}
              onChange={(value) => onAnswerChange(interviewOtherPeoplesInput.uid, value)}
              error={interviewOtherPeoplesInput.error}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6} md={4} lg={4} className={classes.checkboRegularContainer}>
            <RadioQuestion
              question={interviewDateType}
              onAnswerChange={onAnswerChange}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={3} style={{display: 'flex', alignItems: 'flex-end'}}>
            <DateQuestion
              question={interviewRepeatedDate}
              onAnswerChange={onAnswerChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
};

export default ProtocolConversationParticipants