import React from "react";
import _ from "lodash";
import {generateQueryParamsHash, getPortfolioChartName} from "../utils";
import {AGGREGATED_PORTFOLIO_ID, getErrorMessage} from "../../../utils/utils";
import {useTabsSharedDataContext} from "../components_v2/TabsSharedData/TabsSharedData";
import {CHART_COLORS} from "../../../utils/constants";

const SHARED_DATA_KEY = 'time-weighted-return-table';

export const useTimeWeightedCumulativeReturnsData = (customerId, portfolios, withHistorical, dataProvider, aggregatedPortfolioName, assets, investmentStrategyId) => {
  const [data, setData] = React.useState({
    data: null,
    loading: true,
    errors: null,
    updatedAt: undefined,
  });

  const sharedData = useTabsSharedDataContext();

  React.useEffect(() => {
    if (!!customerId && !_.isEmpty(portfolios)) {
      const sharedPortfolioData = !!sharedData
        ? sharedData.getDataValue(SHARED_DATA_KEY, generateQueryParamsHash(customerId, portfolios, withHistorical))
        : undefined;

      if (!!sharedPortfolioData && !sharedPortfolioData.errors) {
        // sync internal hook data with shared data.
        setData(sharedPortfolioData);
        return;
      }

      // fetch data if there is no shared data or shared data was loaded with error
      // check if data fetched for the first time (updatedAt is undefined) to prevent infinite data reloading
      // in case of data loading error, so data will be requested again only if tab changed
      if (!data.updatedAt || !sharedPortfolioData) {
        fetch();
      }
    }
  }, [customerId, JSON.stringify(portfolios), withHistorical, _.get(sharedData, 'updatedAt')]);

  const fetch = async () => {

    const hashKey = generateQueryParamsHash(customerId, portfolios, withHistorical);

    const state = {
      data: null,
      loading: true,
      errors: null,
      updatedAt: +new Date()
    }
    setData(state);
    // It is not possible to sync data with shared data in
    // hook because hook is not executed if component destroyed
    sharedData.setDataValue(SHARED_DATA_KEY, hashKey, state);

    try {
      const depots = portfolios.reduce((acc, cur) => {
        acc[cur.depotNumber] = cur;
        return acc;
      }, {});

      if (!depots.hasOwnProperty(AGGREGATED_PORTFOLIO_ID)) {
        depots[AGGREGATED_PORTFOLIO_ID] = {
          id: AGGREGATED_PORTFOLIO_ID,
          name: aggregatedPortfolioName,
          color: CHART_COLORS[0],
          number: AGGREGATED_PORTFOLIO_ID
        }
      }

      const depotNumbers = portfolios.map((portfolio) => portfolio.depotNumber).filter((value) => !!value);

      let response = await dataProvider.getPortfolioPerformance(
        customerId, depotNumbers, withHistorical, assets, investmentStrategyId);
      const depotNumbersAsStr = depotNumbers.map(_.toString);
      const result = _.orderBy(Object.keys(response), (depotId) => _.indexOf(depotNumbersAsStr, depotId)).map((depotId) => ({
        id: depotId,
        name: getPortfolioChartName(depots[depotId], aggregatedPortfolioName),
        depotNumber: depots[depotId].number,
        color: [depots[depotId].color],
        data: _.get(response, `${depotId}.cum_returns.data`) || {},
        error: _.get(response, `${depotId}.cum_returns.error`),
      }));

      const state = {
        data: result,
        loading: false,
        errors: undefined,
        timestamp: new Date().getTime()
      };
      setData(state);
      sharedData.setDataValue(SHARED_DATA_KEY, hashKey, state);
    } catch(errors) {
      const state = {
        data: null,
        loading: false,
        errors: getErrorMessage(errors),
        updatedAt: +new Date()
      }
      setData(state);
      sharedData.setDataValue(SHARED_DATA_KEY, hashKey, state);
    }
  }

  return data
}