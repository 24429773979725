import React from "react";
import _ from "lodash";
import useStyles from './styles';
import {RiskReturnChart as BaseRiskReturnChart} from "../../../../../components/Charts/RiskReturnChart";

export function RiskReturnChart({products, getSeriesXY, getSeriesColor, onSeriesTooltipClick}) {

  const classes = useStyles();

  if (!products.length) {
    return null
  }

  const series = products.reduce((result, product) => {
    let [x, y] = getSeriesXY(product)

    result.push({
      name: product.name,
      color: _.isFunction(getSeriesColor) ? getSeriesColor(product) : product.color,
      data: [{
        x: x, y: y, onTooltipClick: _.isFunction(onSeriesTooltipClick) ? onSeriesTooltipClick : product.onTooltipClick
      }],
      shadow: true,
      marker: {
        symbol: 'circle'
      }
    });

    return result
  }, []);

  return (
    <BaseRiskReturnChart
      classes={classes}
      series={series}
    />
  )
}

export default RiskReturnChart