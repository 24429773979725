import React from 'react';
import connect from "react-redux/es/connect/connect";
import { Document, Page, pdfjs } from 'react-pdf';
import _ from 'lodash';

import {Grid} from "@material-ui/core";
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

import styles from './styles';
import {getFromStorage, TOKEN_KEY} from "../../../../../../utils/storage";
import {axiosInstance} from "../../../../../../utils/api";
import {PERIOD_OPTIONS} from "../../../Tabs/ReportSettingsV2";

pdfjs.GlobalWorkerOptions.workerSrc = "/static/pdf.worker.js";
const guidePdfSrc = '/static/pdf/cover-showcase.pdf';

const mapStateToProps = (state, ownProps) => ({
  customer: ownProps.customer || state.get('reporting').toJS().customer,
  guide: state.get('guide').toJS(),
});

const CoverPageDocument = (props) => {
  const {
    classes,
    reportSettings,
    customer,
    guide,
    pageWidth,
    useDummyCustomer
  } = props;

  const [loading, setLoading] = React.useState(true);
  const [content, setContent] = React.useState(null);
  const [contentPageCount, setCoverPageCount] = React.useState(null);
  const [entity, setEntity] = React.useState(null);

  React.useEffect(() => {
    if (!_.isEmpty(customer)) {
      setEntity(customer);
    }

    if (!_.isEmpty(reportSettings)) {
      setEntity(reportSettings);
    }
  }, [customer, reportSettings]);

  React.useEffect(() => {
    if (entity && (!!entity.force_fetch_cover || !contentPageCount)){
      if (guide && guide.active) {
        return setGuidePdf();
      }
      return fetchCoverShowcasePDF();
    }
  }, [entity]);

  const pdfLoadingIndicator = (<CircularProgress/>);

  const pdfErrorComponent = (<ErrorIcon/>);

  const getCustomerId = () => {
    const customerIdFromGroup = entity && entity.customers && entity.customers[0] && entity.customers[0].customer_id;
    return entity.customer_id ? entity.customer_id : customerIdFromGroup;
  };

  const fetchCoverShowcasePDF = () => {
    const config = {
      responseType: 'arraybuffer'
    };
    const token = getFromStorage(TOKEN_KEY);
    if (token) {
      config.headers = {
        'Authorization': `Bearer ${token}`
      }
    }
    try {
      let startDate = entity.start_date, endDate = entity.end_date;
      if(!(startDate || endDate) && entity.date_range){
        const dateRange = Object.values(PERIOD_OPTIONS).find(s => s.value == entity.date_range);
        if (dateRange.value === PERIOD_OPTIONS.CUSTOM.value) {
          startDate = entity.date_range_start_date;
          endDate = entity.date_range_end_date;
        } else {
          const {start, end} = dateRange.getDateRange();
          startDate = start ? start.format('YYYY-MM-DD') : undefined;
          endDate = end ? end.format('YYYY-MM-DD') : undefined;
        }
      }
      const cover_page_content = (entity.cover_message_enabled && entity.cover_message_content) ||
        (entity.report_settings && entity.report_settings.cover_message_content) || '';
      setLoading(true);
      const is_dynamic_group = useDummyCustomer || !_.isNil(_.get(entity, 'dynamic_filters'));
      axiosInstance.post(`/reports/report/cover/`, {
        'cover_page_content': cover_page_content,
        'customer_id': getCustomerId(),
        'depots': !is_dynamic_group ? entity.portfolio_ids : undefined,
        'date_range': entity.date_range,
        'periodicity': entity.periodicity,
        'start_date': startDate,
        'end_date': endDate,
        'with_other_assets': entity.with_other_assets,
        'with_historical_portfolios': entity.include_historical_portfolios,
        'is_dynamic_group': is_dynamic_group
      }, config).then((response) => {
        setLoading(false);
        setContent(response.data);
      }).catch(error => {
        setLoading(false);
        setContent(null);
      });
    } catch (error) {
      setLoading(false);
      setContent(null);
    }
  };

  const setGuidePdf = () => {
    setLoading(false);
    setContent(guidePdfSrc);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setCoverPageCount(numPages);
  };

  const renderDocumentPages = () => {
    if (!_.isNil(contentPageCount)) {
      let pages = [];
      for (let pageNumber = 1; pageNumber <= contentPageCount; pageNumber++) {
        pages.push(
          <Page
            key={pageNumber}
            pageNumber={pageNumber}
            className={classes.page}
            renderTextLayer={false}
            width={pageWidth}
          />
        )
      }

      return pages;
    }

    return [];
  };

  const documentContent = (
    <div className={classes.scrollWrap}>
      <Document
        file={content}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={pdfLoadingIndicator}
        error={pdfErrorComponent}
        options={{
          cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
          cMapPacked: true,
        }}
      >
        { renderDocumentPages() }
      </Document>
    </div>
  );

  return (
    <Grid container className={classes.container} id="cover-page-document-preview-container">
      <Grid item xs={12} className={classes.headerWrap}>
        <Grid container className={classes.header}>
          <Grid item xs={12} className={classes.headerTitle}>
            Vorschau Anschreiben
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.contentWrap}>
        {loading ? pdfLoadingIndicator : content ? documentContent : pdfErrorComponent}
      </Grid>
    </Grid>
  )
};

export default withStyles(styles)(
  connect(mapStateToProps)(CoverPageDocument)
);