import _ from "lodash";

import {
  setQuestionDefaults,
  getQuestionType,
  getStepAnswer,
  setQuestionAnswer,
  setQuestionErrorFromConfig,
  filterQuestionWithUsePrevAnswers,
  getMemberFieldName,
  getFieldMessage,
  getPortfolioClearingAccountQuestionUID,
  scrollToFirstElementOfClass,
} from "./utils";
import {BankSettingsHandlerResource, QuestionnairesHandlerResource} from "../../utils/api";
import {
  CUSTOMER_TYPES,
  MAX_RISK_THRESHOLD,
  QUESTION_TYPE,
  kundendatenStep, radioYesUID, PB_KNOWLEDGE_MAPPING, buildStepUIDForMember, REF_ACCOUNT_STEP_ID, LEGITIMATION_STEP_ID
} from './constants';
import {MenuItem} from "./components/Navigation/MenuItem";
import {savingsPlansDeleteOnly} from "../Trades/utils";
import {SERVER_ERROR} from "../../utils/constants";
import {productsStepsData} from "./mock_produktauswahl";
import {custodyCertificateSteps, PROOF_ID} from "./mock_custody_certificate";
import { SERVICE_CONCEPTS } from '../Trades/constants';

  /**
 * Build question identifier for member.
 * @param {String} questionUID - Question UID
 * @param {Number} memberIndex - Member index (starting from 0)
 * @param {String} customer_type - Customer type (CUSTOMER_TYPES)
 * @return {String} - Member question identifier
 * */
  export const buildQuestionUIDForMember = (questionUID, memberIndex, customer_type) => {
    if (_.isUndefined(memberIndex)) return questionUID;
    return `${getMemberFieldName(customer_type)}.${memberIndex}.${questionUID}`
  };

  const DEFAULT_ERROR_GROUPING_KEY = 'DEFAULT';

  const ERROR_KEYS_TRANSLATIONS = {
    validation_error: 'Fehler bei der Überprüfung'
  };

  const stepsToValidate = {
    'user_data': kundendatenStep.map(step => step.uid),
    'products_selection': productsStepsData.steps.map(step => step.uid),
    'custody_certificate': custodyCertificateSteps
  };

export class Service extends MenuItem {
  constructor(uid, name, next_btn, _success_body, getPrevStepAnswer, validateAccountFunc, handleWarnings, validateAccountStep=REF_ACCOUNT_STEP_ID) {
    super(uid, name);
    this._next_btn = next_btn;
    this._success_body = _success_body;
    this.__onboarding_step_uid = uid;
    this.__onboarding_uid = null;
    this._customer_id = null;
    this._user = null;
    this.custodian = null;
    this._bank = null;
    this._getPrevStepAnswer = getPrevStepAnswer;
    this.__initVars();
    this._validateAccountFunc = validateAccountFunc;
    this.__customer_type = undefined;
    this._current_customer = null;
    this.__members = [];
    this.prevProcessAnswers = undefined; // data to pre fill from prev Onboarding / Trading
    this._handleWarnings = handleWarnings;
    this._validateAccountStep = validateAccountStep;
  }

  __initVars() {
    this._step = null;
    this._session_id = null;
    this._questionnaire = null;
    this._isFirstStep = false;
    this._isLastStep = false;
    this._progress = 0;
    this._is_finished = false;
  }

  get next_btn() {
    return this._next_btn;
  }

  set next_btn(value) {
    this._next_btn = value;
  }

  get success_body() {
    return this._success_body;
  }

  set success_body(value) {
    this._success_body = value;
  }

  set customer_id(customer_id){
    this._customer_id = customer_id;
  }

  set customer_type(customer_type){
    this.__customer_type = customer_type;
  }

  get customer_type(){
    return this.__customer_type;
  }

  set session_id(session_id){
    this._session_id = session_id;
  }

  get session_id(){
    return this._session_id;
  }

  set bank(bank){
    this._bank = bank;
  }

  get bank(){
    return this._bank;
  }

  set onboarding_uid(onboarding_uid){
    this.__onboarding_uid = onboarding_uid;
  }

  get onboarding_uid(){
    return this.__onboarding_uid;
  }

  get customer_id() {
    return this._customer_id;
  }

  set user(user) {
    this._user = user;
  }

  get user() {
    return this._user;
  }

  get is_couple() {
    return this.customer_type == CUSTOMER_TYPES.COUPLE;
  }

  get useMembers(){
    return [CUSTOMER_TYPES.COUPLE, CUSTOMER_TYPES.MINOR].includes(this.customer_type);
  }

  get members() {
    return this.__members
  }

  set members(value) {
    this.__members = value
  }

  get current_customer() {
    return this._current_customer
  }

  set current_customer(value) {
    this._current_customer = value
  }

  buildData() {
    let answers = {};
    this._questionnaire.steps.map(step => {
      if(step.question){
        (getStepAnswer(step).answers || []).map(answ => answers[answ.question_uid] = answ.answer)
      }
    });

    return answers;
  }

  get isSwitchSellFlow() {
    if(this.is_trading){
      let tradeAnswer = this._getPrevStepAnswer('trade', 'trade-step', "transactions", true);

      if (!!tradeAnswer) {
        for (let portfolio of tradeAnswer) {
          let { transactions } = portfolio;

          if ((transactions.savings_plan && transactions.savings_plan.find(t => t.is_changed && ['create', 'edit'].includes(t.action)))
            || (transactions.buy && transactions.buy.length)) {
            return false
          }
        }

        return true // ONLY if tradeAnswer exists and there is no "buy"
      }
    }

    return false;
  }

  _portfolioHasBuyTransactions = (portfolio) => {
    const transactions = portfolio.transactions
    return !_.isEmpty(transactions.buy || [])
      || !_.isEmpty(transactions.switch || [])
      || !_.isEmpty(transactions.savings_plan || [])
      || !_.isEmpty(transactions.switch_plan || [])
  }

  /**
   * Validate, if trading process contains only instruments for selling.
   *
   * @return {boolean}
   */
  get isSellOnlyFlow() {

    if (!this.is_trading) {
      return false
    }

    let tradeAnswer = this._getPrevStepAnswer('trade', 'trade-step', "transactions", true);

    if (!!tradeAnswer) {
      for (let portfolio of tradeAnswer) {

        if (this._portfolioHasBuyTransactions(portfolio)) {
          return false
        }

      }

      return true
    }

  }

  get maxRiskThreshold() {
    return this._srri && this._srri + MAX_RISK_THRESHOLD
  }

  get portfoliosWithProductInformationRequired() {

    if (this.is_trading) {

      let tradeAnswer = this._getPrevStepAnswer('trade', 'trade-step', "transactions", true);

      if (!tradeAnswer) {
        return []
      }

      return _.filter(tradeAnswer, (portfolio) => {

        const isValidPortfolio = !_.get(portfolio, 'data.is_model_portfolio')
          || !_.get(portfolio, 'data.is_private_investment')

        return this._portfolioHasBuyTransactions(portfolio) && isValidPortfolio

      })

    }

    return this._bank && !this._bank.is_private_investment ? [this._bank] : []

  }

  get isOpenOnly(){
    const menu = 'products_selection';
    const step = 'product-selection-deposits';
    const q = "open_only['checkbox']";

    return (this.uid === menu ? this.getStepAnswer(step, q) : this._getPrevStepAnswer(menu, step, q)) || false;
  }

  /**
   * Validate, if trading process contains only savings plans for deleting.
   *
   * @return {boolean}
   */
  get isDeleteSavingPlanFlow() {

    if (!this.is_trading) {
      return false
    }

    let tradeAnswer = this._getPrevStepAnswer('trade', 'trade-step', "transactions", true);
    if (!!tradeAnswer) {
      return savingsPlansDeleteOnly(tradeAnswer)
    }

  }

  getProofOfGurdiansDocument(){
    return  this._getPrevStepAnswer('custody_certificate', 'proof_of_guardianship', 'proof_of_guardianship')
  }

  getLigitimationDocuments(){

    const getLegitimationSideGUID = (idx, side) => {
      return this._getPrevStepAnswer(
        'user_data',
        buildStepUIDForMember(LEGITIMATION_STEP_ID, idx),
        buildQuestionUIDForMember(`legitimation['${side}']`, idx, this.customer_type));
    }

    const getLegitimetionDocuments = (idx) => {
      let front_side = getLegitimationSideGUID(idx, 'front_side')
      let rear_side = getLegitimationSideGUID(idx, 'rear_side')

      if (front_side || rear_side) {
        return [{
          'name': `${this.getMemberFullName(idx, ', ', true)} Legitimationsdokument`,
          'legitimationID': this._getPrevStepAnswer(
            'user_data',
            buildStepUIDForMember(LEGITIMATION_STEP_ID, idx),
            buildQuestionUIDForMember("legitimation['GUID']", idx, this.customer_type)
          ),
          'legitimations': [
            front_side,
            rear_side
          ]
        }]
      }

      return []
    }

    let documents = [...getLegitimetionDocuments(undefined)]

    this.members.map((m, idx) => {
      documents.push(...getLegitimetionDocuments(idx))
    })

    return documents
  }

  getMemberFullName(idx, separator=' ', last_name_first=false){
    let q = this._getPrevStepAnswer("user_data", buildStepUIDForMember('A1', idx), "A-name");
    if(q){
      let firstName = _.get(q.find(a => a.question_uid === buildQuestionUIDForMember('first_name', idx, this.customer_type)), 'answer.0', '');
      let lastName =  _.get(q.find(a => a.question_uid === buildQuestionUIDForMember('last_name', idx, this.customer_type)), 'answer.0', '');

      return _.join(!!last_name_first ? [lastName, firstName] : [firstName, lastName], separator);
    }
  };

  getMemberRegistrationAddress(idx){
    let q = this._getPrevStepAnswer("user_data", buildStepUIDForMember('A1', idx), "A-address");
    if(q){
      return _.get(q.find(a => a.question_uid === buildQuestionUIDForMember("registration_address['city']", idx, this.customer_type)), 'answer.0', '');
    }
  };

  async goToStep(step_uid) {
    if(this._questionnaire && this._questionnaire.steps) {
      const index = this._questionnaire.steps.findIndex(step => step.uid === step_uid || this._getStepUID(step.uid) === step_uid);
      if(index !== -1) {
        await this.__setStep(null, index);
      }
    }
  }

  async resendResult(){

  }

  async confirmResult(){

  }

  _checkWarnings(warnings){
    if(typeof this._handleWarnings === 'function' && !_.isEmpty(warnings)){
      this._handleWarnings(warnings)
    }
  }

  async _validateStepFunc() {
    if (typeof this._validateAccountFunc === 'function') {
      try {
        let response =  await this._validateAccountFunc(true);
        this._checkWarnings(response.warnings)
      } catch (e) {

        if(e.data.hasOwnProperty('warnings')){
          this._checkWarnings(e.data.warnings)
        }
        // in case no errors provided - it's server error
        if (!e.data.hasOwnProperty('errors')) {
          throw Error(e.data.detail);
        }

        if (e.data.errors.hasOwnProperty('instruments')) {
          // step has questions with errors - so throw error
          throw Error(_.uniq(e.data.errors.instruments).join('\n'));
        }
      }
    }
  }

  async __sendStepData(isFinished, step=undefined) {
    let stepToSend = step
    if (!stepToSend) {
      stepToSend = this._step
    }
    if(this.__onboarding_uid){
      const stepAnswer = getStepAnswer(stepToSend, this.__isCustomAnswers);
      if(!!isFinished){
        stepAnswer.finished = true;
      }

      stepAnswer.customer_id = this._customer_id;
      stepAnswer.onboarding_step_uid = this.__onboarding_step_uid;
      stepAnswer.onboarding_uid = this.__onboarding_uid;
      stepAnswer.is_trading = this.is_trading;
      return await QuestionnairesHandlerResource.post(stepAnswer);
    }
  }

  getDataForAccount(validate){
    return {}
  }

  async sendCurrentStep() {
    if(this.__onboarding_uid && this._customer_id && this.__onboarding_step_uid){
      await QuestionnairesHandlerResource.post({
        customer_id: this._customer_id,
        onboarding_step_uid: this.__onboarding_step_uid,
        onboarding_uid: this.__onboarding_uid,
        is_trading: this.is_trading
      });
    }
  }

  setFormErrors(formErrors, mappingConfig, keepExistingError = false){
    let isValid = true;

    const _validateQuestion = (q) => {
      if(q.hasOwnProperty('question')) {
        (q.question || []).forEach(_validateQuestion);
      } else {
        const skipEmpty = this._checkRequiredQuestionMissing(q);

        if (!(skipEmpty || setQuestionErrorFromConfig(q, formErrors, mappingConfig || this.__mappingConfFunction, keepExistingError))) {
          isValid = false;
        }
      }
    };

    (this._step && this._step.question || []).forEach(_validateQuestion);

    return isValid;
  }

  parsePageWarnings(warnings) {
    let warnings_list = [];

    (this._step && this._step.question || []).map((q) => {
      const warning = getFieldMessage(q, warnings, null, true);
      if(warning) warnings_list.push(warning);
    });
    
    if(!_.isEmpty(warnings_list)) return warnings_list.join('\n');

    if (this._validateAccountStep && this._step.uid === this._validateAccountStep && _.has(warnings, 'bank_validation_warning')){
      return warnings.bank_validation_warning;
    }
  }

  _setDefaultAnswers(onboardingAnswers){
    let defaultAnswers = _.get(onboardingAnswers, this.__onboarding_step_uid) || {};
    let prevProcessAnswers = _.get(this.prevProcessAnswers, this.__onboarding_step_uid) || {};
    if(this._questionnaire && this._questionnaire.steps) {
      this._questionnaire.steps.map((step) => {
        const stepUID = this._getStepUID(step.uid);
        const stepAnswers = defaultAnswers[stepUID] || filterQuestionWithUsePrevAnswers(prevProcessAnswers[stepUID]) || [];
        if(stepAnswers){
          this._setDefaultQuestionsAnswers(step, stepAnswers);
        } else {
          this._setQuestionsDefaults(step);
        }
      });
    }
  }

  _setQuestionsDefaults(q){
    (q.question || []).map((q) => {
      if(getQuestionType(q) === QUESTION_TYPE.GROUP){
        this._setQuestionsDefaults(q);
      } else {
        setQuestionDefaults(q);
      }
    });
  }

  _setDefaultQuestionsAnswers(q, defaultAnswers){
    (q.question || []).map((q) => {
      if(getQuestionType(q) === QUESTION_TYPE.GROUP){
        this._setDefaultQuestionsAnswers(q, defaultAnswers);
      } else {
        const answer = defaultAnswers.find(a => this._getQuestionUID(q.uid) === this._getQuestionUID(a.question_uid));
        if (answer) {
          this._setQuestionAnswer(q, answer);
        } else {
          setQuestionDefaults(q);
        }
      }
    });
  }

  _setQuestionAnswer(q, answer){
    if(_.isArray(answer.answer)) {
      setQuestionAnswer(q, answer.answer.map(answer => _.isObject(answer) && answer.uid ? answer.uid : answer), true);
    }
  }

  _getPortfoliosBanksCodes = (portfolios) => {
  //return ['10000', '100976']
    let banksCodes = portfolios.map(portfolio => portfolio.companyId)
    banksCodes = new Set(banksCodes)
    return _.filter(Array.from(banksCodes), code => !!code)
  }

  _getBankCodeDetails = async (bankCode, useCode) => {
    return await BankSettingsHandlerResource.getBankDetails(bankCode, useCode, this.objectType)
  }

  async _getBankDetails() {
    if (this.is_trading) {
      let portfolios = this._getPrevStepAnswer('trade', 'trade-step', 'transactions');
      let banksCodes = this._getPortfoliosBanksCodes(portfolios)
      if (!this._bank) {
        this._bank = await Promise.all(banksCodes.map((bankCode) => {
          return this._getBankCodeDetails(bankCode, true)
        }));
      }
    } else {
      const custodianAnswer = this._getPrevStepAnswer('products_selection', 'product-selection-depot', 'custodians');
      this.custodian = custodianAnswer;
      const bankId = custodianAnswer.depot.bankId;
      if (_.get(this._bank, 'id') !== bankId) {
        this._bank = await this._getBankCodeDetails(bankId, false);
      }
    }
  }

  __mappingConfFunction = undefined;

  hasInvestmentFundsKnowledge(serviceConcept){
    const questionId = serviceConcept === SERVICE_CONCEPTS.Anlagevermittlung ? 'K1' : 'A2';
    const answer = this._getPrevStepAnswer('risk_profile', questionId,  'H3', false, true);
    return _.get(answer, 'answer.radio') === radioYesUID;
  }

  async getPortfolioBuilderInfo(){
    return {
      lossCapacity: this._getPrevStepAnswer('risk_profile', 'A11', 'R1'),
      knowledgeInAssetGroups: Object.keys(PB_KNOWLEDGE_MAPPING).map((key) => ({
        knowledgeInAsset: key,
        knowledge: this._getPrevStepAnswer('risk_profile', 'A2',  PB_KNOWLEDGE_MAPPING[key], false, true).answer.radio === radioYesUID
      })),
      esg: {
        esg: this._getPrevStepAnswer('esg_profile', 'esg', 'percent'),
        oko: this._getPrevStepAnswer('esg_profile', 'ecologically-sustainable', 'percent'),
        pai: this._getPrevStepAnswer('esg_profile', 'pai', 'percent')
      },
      investmentMotive: this._getPrevStepAnswer('risk_profile', 'A8', 'O1'),
      investmentDuration: this._getPrevStepAnswer('risk_profile', 'A9', 'P1'),
      displayFirstName: _.get(this.current_customer, 'customer_first_name') || _.get(this.current_customer, 'personalInformation.firstName'),
      displayLastName: _.get(this.current_customer, 'customer_last_name') || _.get(this.current_customer, 'personalInformation.lastName'),
      sri: _.get(this.current_customer, 'last_srri.srri'),
      portfolioValue: 0,
    }
  }

  // region Validate Create Account/Order responses
  _getGermanErrorFieldName(question_uid) {
    // Searches for question by uid and returns its question_text

    for(const[step_uid, nested_steps] of Object.entries(stepsToValidate)){
      for (let i = 0; i < nested_steps.length; i++) {
        let nested_step_uid = nested_steps[i];
        // last param is passed as true to get hole question obj, not just answer
        let question = this._getPrevStepAnswer(step_uid, nested_step_uid, question_uid, false, true);
        // replace field name from back_end with german label of the field that we use in forms
        let germanFieldName = _.get(question, 'question_text');
        // if question with question_text was found - return it
        if(!_.isNil(germanFieldName)) return germanFieldName
      }
    }
  }

  _joinErrors(errors) {
    let msg = "";
    Object.keys(errors).map(field => {
      errors[field].map(err => {
        if(field !== 'bank_validation_error'){

          let fieldName = ERROR_KEYS_TRANSLATIONS[field] || this._getGermanErrorFieldName(field) || _.upperFirst(field.replace('_', ' '));

          err = `${fieldName}: ${err}`;
        }

        msg += `${err}\n`;
      })
    });

    return msg
  }

  _handleTaxIdentifiersErrors(errors, question) {

    const _errors = {};

    const _setError = (key, uid, errorKey) => {
      const index = +_.last(uid.split('_'));
      const answerId = _.get(question.answer, `${index - 1}.id`);
      _.set(_errors, `${answerId}.${key}`, errors[errorKey]);
    }

    const getMemberFromUID = (uid) => {
      return _.take(uid, uid.length - 1).join('.')
    }

    const questionUIDSplited = question.uid.split('.');

    Object.keys(errors).forEach((errorKey) => {

      // If splited uid has more than one element - couple/legal guardian used.
      const errorKeySplited = errorKey.split('.');
      if (errorKeySplited.length > 1) {
        if (questionUIDSplited.length !== errorKeySplited.length || getMemberFromUID(questionUIDSplited) != getMemberFromUID(errorKeySplited)) {
          return
        }
      }

      const errorQuestionUID = _.last(errorKey.split('.'));
      if (errorQuestionUID.startsWith('tax_id')) {
        _setError('tax_id', errorQuestionUID, errorKey);
      } else if (errorQuestionUID.startsWith('tax_country')) {
        _setError('country', errorQuestionUID, errorKey)
      }

    });

    return _.isEmpty(_errors) ? undefined : _errors;
  }

  _handleError(error, keepExistingError = false) {
    // in case no errors provided - it's server error
    if(!error.data.hasOwnProperty('errors')){

      return error.data.hasOwnProperty('detail') ? error.data.detail : SERVER_ERROR

    }

    if(!this.setFormErrors(error.data.errors, (i) => { return {
      dante_error_field: i.uid,
      dante_error_finder: (errors, question) => {

        const questionUID = _.last(question.uid.split('.'));
        if (questionUID == 'tax_identifiers') {
          return this._handleTaxIdentifiersErrors(errors, question);
        } else if (questionUID === PROOF_ID) {
          return errors[`legal_guardian.0.${PROOF_ID}`]
        } else {
          return errors[question.uid];
        }

      }
    }}, keepExistingError)){
      // step has questions with errors - so throw error
      return error.data.details;
    } else if (this._validateAccountStep && this._step.uid === this._validateAccountStep){
      // it's last step - so throw all errors

      return this._joinErrors(error.data.errors)
    }

  }

  /**
   * Validate list of failed responses.
   *
   * @param {Array<CreateOrderResponseError>} responses List of failed responses
   * @return {CreateOrderResponseErrorHandled}
   * @private
   */
  _handleFailedResponses(responses) {

    let depots = _.groupBy(responses, (response) => _.get(response, 'portfolio.depotNumber', DEFAULT_ERROR_GROUPING_KEY))
    let errors = {};
    Object.keys(depots).forEach((depotNumber) => {
      errors[depotNumber] = {
        portfolioName: `Depot ${_.get(depots[depotNumber], '0.portfolio.depotName')}`,
        errors: []
      }
    });

    for (let depotNumber in depots) {
      for (let error of depots[depotNumber]) {

        // BCA-8071 Additional error parsing required to replace bank_account error with clearing account error
        // in case clearing account used as bank account
        this._handleFailedResponseBancAccount(error);

        let errorHandled = this._handleError(error.error, true)
        if (!!errorHandled && !errors[depotNumber].errors.includes(errorHandled)) {
          errors[depotNumber].errors.push(errorHandled);
        }
      }
    }

    return errors;
  }

  _handleFailedResponseBancAccount(response) {

    const errors = _.get(response, 'error.data.errors');

    if (errors && response.clearingAccountAsBankAccount && errors.hasOwnProperty('bank_account')) {
      response.error.data.errors[getPortfolioClearingAccountQuestionUID(response.portfolio)] = response.error.data.errors.bank_account;
      delete response.error.data.errors.bank_account;
    }

  }

  /**
   * Check, if at least one portfolio has error.
   *
   * @param {CreateOrderResponseErrorHandled} errors Object with depots errors
   * @return {boolean} Flag, that indicate, if at leas one portfolio has errors
   * @private
   */
  _errorsEmpty(errors) {
    return !_.find(Object.keys(errors), (depotNumber) => errors[depotNumber].errors.length)
  }

  _buildFullErrorMessage(errors) {
    let resultError = '';

    for (let depotNumber in errors) {

      if (!errors[depotNumber].errors.length) {
        continue
      }

      if (resultError.length && !resultError.endsWith('\n')) {
        resultError += '\n'
      }

      // Add portfolio name to the error message only in case portfolio was specified for error
      if (depotNumber != DEFAULT_ERROR_GROUPING_KEY) {
        resultError += `${errors[depotNumber].portfolioName}: \n`
      }

      resultError += errors[depotNumber].errors.reduce((result, error) => {
        return result + `${typeof error === 'string' ? error : SERVER_ERROR}`;
      }, '')
    }

    return resultError
  }

  _validateAccount = async () => {
    if (typeof this._validateAccountFunc === 'function'){
      // try {
      let response = await this._validateAccountFunc();
      if (!Array.isArray(response)) {
        // one response means it's onboarding - so need to check for warnings
        this._checkWarnings(response.warnings);

        response = [response]
      }

      let responseWithErrors = _.filter(response, (res) => !!res.error);
      if (responseWithErrors.length) {

        let errors = this._handleFailedResponses(responseWithErrors);

        if (!this._errorsEmpty(errors)) {
          // scroll to errors from response
          scrollToFirstElementOfClass();
          throw this._buildFullErrorMessage(errors);
        }

      }
    }
  }
// endregion
}