import React from 'react';

/* Material-UI Components */
import Tooltip from '@material-ui/core/Tooltip';

/* BCA modules */
import useStyles from './styles';
import {mergeClasses} from "@material-ui/styles";


const CustomTooltip = (props) => {
  const { arrow, ...classes } = useStyles(props);
  const [arrowRef, setArrowRef] = React.useState(null);

  const title = props.title ? (
    <React.Fragment>
      {props.title}
      <span className={arrow} ref={setArrowRef} />
    </React.Fragment>
  ) : '';

  return (
    <Tooltip
      {...props}
      classes={mergeClasses({baseClasses: classes, newClasses: props.classes, Component: Tooltip})}
      enterTouchDelay={1}
      leaveTouchDelay={5000}
      PopperProps={{
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: Boolean(arrowRef),
              element: arrowRef,
            },
          },
        },
      }}
      title={title}
    />
  )
};

export const DisabledReasonTooltip = (props) => {
  const {title, children} = props;

  return !!title ? <CustomTooltip {...props} /> : children;
};

export default CustomTooltip;