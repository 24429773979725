import React from "react";
import clsx from "clsx";
import _ from "lodash";

import {Grid} from "@material-ui/core";
import useStyles from './styles';
import {toGermanFormat} from "../../../../../../../../utils/numberFormater";

const InvertedInsideStyles = {
  position: 'absolute',
  width: '100%',
  paddingLeft: 4,
  justifyContent: 'flex-start',
}

const OutsideStyles = {
  position: 'absolute',
  marginLeft: 3,
  justifyContent: 'left',
}

const DetailsLine = (props) => {

  const {
    topLeftLabel,
    topRightLabel,

    bottomLeftLabel,
    bottomRightLabel,

    currentValue,  // number that is displayed inside the progress if it is not 100 % filled

    isOverweighted,
    isUnderweighted,

    unitOfMeasure,
    borderRadius,  //  used in styles. is passed to override border radius of progress and fill lines
    withoutOverweightIcon, // if passed, there is no space left in container for overweight icon

    useInvertedLine,
  } = props;

  const threshold = 0.01;
  let filledPercentage = props.filledPercentage;  // used in styles. defines how much of progress is filled (100 is max)
  if (filledPercentage < 0) {
    filledPercentage = Math.abs(filledPercentage)
  }

  if(filledPercentage > 100){
    filledPercentage = 100
  } else if(filledPercentage > 0 && filledPercentage < threshold){
    filledPercentage = threshold;
  }

  const classes = useStyles({...props, filledPercentage: _.round(filledPercentage, 2)});
  const customClasses = props.customClasses || {};

  let prefix = '';
  let value = currentValue;
  if(value > 0 && value < threshold){
    value = threshold;
    prefix = '<';
  } else if(value < 0 && value > -threshold){
    value = -threshold;
    prefix = '>';
  }
  const filledPercentageLabel = isUnderweighted && toGermanFormat(value, prefix, unitOfMeasure, 2, true);

  const renderLabel = () => {
    return (
      <span
        className={clsx(customClasses.filledPercentage, classes.filledPercentage)}
        style={
          (!props.displayProgressLabelOutside && useInvertedLine) 
            ? InvertedInsideStyles 
            : props.displayProgressLabelOutside ? {
              ...OutsideStyles,
              left: !useInvertedLine && `${filledPercentage}%`,
              right: useInvertedLine && `${filledPercentage}%`,
            } : {}
        }
      >
        {filledPercentageLabel}
      </span>
    )
  }

  return (
    <Grid container style={{alignItems: 'center'}}>
      <Grid item xs={withoutOverweightIcon ? 12 : 11} className={clsx('background', customClasses.background, classes.background)}>
        {/* upper labels */}
        {topLeftLabel && <div className={classes.topLeftLabel}>{topLeftLabel} </div> }
        {topRightLabel && <div className={classes.topRightLabel}>{topRightLabel} </div> }

        {/* fill line */}
        <div className={classes.blueFill}>
          {!props.displayProgressLabelOutside && renderLabel()}
        </div>
        {props.displayProgressLabelOutside && renderLabel()}

        {/* lower labels */}
        {bottomLeftLabel && <div className={classes.topLeftLabel}>{bottomLeftLabel} </div> }
        {bottomRightLabel && <div className={classes.topRightLabel}>{bottomRightLabel} </div> }
      </Grid>

      {!withoutOverweightIcon &&
        <Grid item xs={1}>
          {isOverweighted &&
            <Grid className={classes.overweightIcon}>
              <i className="fa fa-caret-right" aria-hidden="true"></i>
            </Grid>
          }
        </Grid>
      }

    </Grid>
  )
}

export default DetailsLine;
