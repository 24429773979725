import React from 'react';
import moment from "moment";

import { Grid } from '@material-ui/core';

import useStyles from '../styles';
import images from '../../../../images';
import TextField from '../TextField';
import DateField from '../DateField';
import CurrencyField from '../CurrencyField';
import SelectField from '../SelectField';
import NonFieldErrors from '../NonFieldErrors';


import {
  REAL_ESTATE_TYPES,
  REAL_ESTATE_USAGE_TYPES
} from '../../constants';
import {decimalStrToNumber} from "../../../../utils/utils";


export default (props) => {
  const classes = useStyles();

  const {
    handleFormChange,
    asset,
    errors,
    viewOnly
  } = props;

  const [model, setModel] = React.useState({
    name: undefined,
    type: undefined,
    address: undefined,
    date_of_construction: undefined,
    usage: undefined,
    rental_income: undefined,
    purchase_price: undefined,
    estimated_value: undefined,
    updated: undefined,
    valuation_date: undefined,
    acquisition_date: undefined,
  });

  React.useEffect(() => {
    if (asset) {
      const type = REAL_ESTATE_TYPES.find(type => asset.estate_type === type.id);
      const usage = REAL_ESTATE_USAGE_TYPES.find(type => asset.usage === type.id);
      const _model = {
        name: asset.name,
        type: type,
        address: asset.address,
        date_of_construction: asset.date_of_construction && moment(asset.date_of_construction),
        usage: usage,
        rental_income: decimalStrToNumber(asset.rental_income),
        purchase_price: decimalStrToNumber(asset.purchase_price),
        estimated_value: decimalStrToNumber(asset.value),
        updated: asset.updated && moment(asset.updated + 'Z'),
        valuation_date: asset.valuation_date && moment(asset.valuation_date),
        acquisition_date: asset.acquisition_date && moment(asset.acquisition_date),
      };
      setModel(_model);
    }
  }, [asset]);


  const prepareFormData = () => {
    return {
      name: model.name,
      value: model.estimated_value,
      address: model.address,
      date_of_construction: model.date_of_construction && model.date_of_construction.format('YYYY-MM-DD'),
      usage: model.usage && model.usage.id,
      rental_income: model.rental_income,
      purchase_price: model.purchase_price,
      estate_type: model.type && model.type.id,
      valuation_date: model.valuation_date && model.valuation_date.format('YYYY-MM-DD'),
      acquisition_date: model.acquisition_date && model.acquisition_date.format('YYYY-MM-DD'),
    }
  };

  React.useEffect(() => {
    handleFormChange && handleFormChange(prepareFormData());
  }, [model]);

  const handleModelChange = (field) => (event) => {
    setModel({
      ...model,
      [field]: event.target.value
    })
  };

  const handleDateChanged = (field) => (value) => {
    setModel({
      ...model,
      [field]: value
    })
  };

  const handleSelectChanged = field => value => {
    setModel({
      ...model,
      [field]: value
    })
  };

  const isOwnUse = (value) => {
    return value && value.id === REAL_ESTATE_USAGE_TYPES[0].id || false
  };

  const isRentalIncomeDisabled = () => {
    return isOwnUse(model && model.usage);
  };

  return (
    <Grid container className={classes.formContainer} id='asset-form-modal-tour-element'>
      <Grid item className={classes.formBody}>
        <div className={classes.formHeader}>
          <div>
            <img src={images.asset_icons.immobilien_icon_white} alt="Immobilien"/>
          </div>
          <p>Immobilien</p>
        </div>
        <div>
          <TextField
            label="Name"
            error={errors && errors.name}
            value={model.name}
            onChange={handleModelChange('name')}
            disabled={viewOnly}
          />
          <SelectField
            label="Typ"
            value={model.type}
            error={errors && errors.estate_type}
            onChange={handleSelectChanged('type')}
            items={REAL_ESTATE_TYPES}
            disabled={viewOnly}
          />
          <DateField
            label="Erwerbsdatum"
            maxDate={moment()}
            value={model.acquisition_date}
            error={errors && errors.acquisition_date}
            onChange={handleDateChanged('acquisition_date')}
            disabled={viewOnly}
          />
          <TextField
            label="Adresse"
            value={model.address}
            error={errors && errors.address}
            onChange={handleModelChange('address')}
            disabled={viewOnly}
          />
          <DateField
            label="Baujahr"
            maxDate={moment()}
            value={model.date_of_construction}
            error={errors && errors.date_of_construction}
            onChange={handleDateChanged('date_of_construction')}
            disabled={viewOnly}
          />
          <SelectField
            label="Nutzung"
            value={model.usage}
            error={errors && errors.usage}
            onChange={handleSelectChanged('usage')}
            items={REAL_ESTATE_USAGE_TYPES}
            disabled={viewOnly}
          />
          <CurrencyField
            label="Jahresnettomieteinkünfte"
            value={model.rental_income}
            error={errors && errors.rental_income}
            disabled={isRentalIncomeDisabled() || viewOnly}
            onChange={handleModelChange('rental_income')}
          />
          <CurrencyField
            label="Kaufpreis"
            value={model.purchase_price}
            onChange={handleModelChange('purchase_price')}
            error={errors && errors.purchase_price}
            disabled={viewOnly}
          />
           <CurrencyField
            label="Schätzwert"
            value={model.estimated_value}
            error={(errors && errors.value ) || (errors && errors.estimated_value)}
            onChange={handleModelChange('estimated_value')}
            disabled={viewOnly}
          />
          <DateField
            label="Bewertungsdatum"
            maxDate={moment()}
            value={model.valuation_date}
            error={errors && errors.valuation_date}
            onChange={handleDateChanged('valuation_date')}
            disabled={viewOnly}
          />
          <DateField
            label="Stand vom"
            value={model.updated || moment()}
            disabled={true}
          />
          {errors && errors['non_field_errors'] && <NonFieldErrors errors={errors['non_field_errors']}/>}
        </div>
      </Grid>
    </Grid>
  )
}