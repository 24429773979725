import _ from "lodash";

import {FinalizeService} from "../../finalize_step_service";
import {protocolStepsData} from "./mock_protocol";

export class ProtocolDataServiceV2 extends FinalizeService {
  constructor(uid, name, next_btn, success_text, getPrevStepAnswer, createAccountFunc, validateAccountFunc, validateMissingData) {
    super(uid, name, next_btn, success_text, getPrevStepAnswer, createAccountFunc);
    this._createAccountStep = 'finalize-documents-delivery';
    this._validateAccountFunc = validateAccountFunc;
    this._validateAccountStep = this._createAccountStep;
    this.__stepsData = protocolStepsData;
    this.__is_new_design = true;
    this._validateMissingData = validateMissingData;
  }

  async nextStep(){
    if (this._step && this._step.uid === this._validateAccountStep) {
      if (_.isFunction(this._validateMissingData) && !await this._validateMissingData()) {
        return;
      }
    }

    return await super.nextStep()
  }

  async __sendStepData() {
    if (this._step && this._step.uid === this._validateAccountStep) {
      await this._validateAccount();
    }

    return await super.__sendStepData(); // store answers in our db
  }
}