import {createMuiTheme} from "@material-ui/core";

export const ERROR_COLOR = '#E50000';
export const ERROR_BACKGROUND = 'rgba(229, 0, 0, 0.12)';
export const SUCCESS_COLOR = '#23D374';
export const SUCCESS_BACKGROUND = 'rgba(35, 211, 116, 0.12)';
export const INFO_COLOR = '#0092E5';
export const INFO_BACKGROUND = 'rgba(32, 146, 229, 0.12)';
export const WARNING_COLOR = '#FF9D00';
export const WARNING_BACKGROUND = 'rgba(255, 157, 0, 0.12)';

export const DEFAULT_THEME_COLOR = INFO_COLOR;


export function createDefaultTheme(extensions={}, color=DEFAULT_THEME_COLOR) {
  const theme = getThemeConfig(color);
  return createMuiTheme({...theme, ...extensions});
}

function getThemeConfig(color) {
  return {
    typography: {
      useNextVariants: true,
    },
    palette: {
      primary: {
        main: color,
        contrastText: '#fff'
      }
    },
    overrides: {
      MuiCheckbox: {
        root: {
          '& svg': {
            //fill: '#B1B1B1 !important',
          }
        },
      },
      MuiCard: {
        root: {
          overflow: 'visible'
        }
      },
      MuiExpansionPanel: {
        root: {
          zIndex: 100
        }
      },
      MuiMenu: {
        paper: {
          boxShadow: 'none',
          border: `2px solid ${color}`,
        },
        list: {
          padding: 0
        }
      },
      MuiListItem: {
        root: {
          transition: 'all',
          transitionDuration: '0.2s',
          padding: '10',
          fontFamily: 'Roboto-Bold !important',
          color: color,
          fontSize: 14,

          '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#EFEFF2'
          }
        }
      },
      MuiDialog: {
        paper: {
          boxShadow: 'none',
          borderRadius: 2
        }
      }
    }
    // Here you can set or override all your variables and settings related to styling.
    // https://material-ui.com/customization/themes/
  };
}