import CommonAnalysisCard from "../../../../components/AnalysisCard";
import React from "react";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import HighChartBase from "../../../../../../components/Charts/Base";
import {toShortGermanFormat} from "../../../../../../utils/numberFormater";
import moment from "moment";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import clsx from "clsx";
import WarningTooltip from "../../../../../../components/WarningTooltip";
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../../../../../utils/constants";

class StressTestChart extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: 'drawdown',
      loading: true,

      categories: [], series: [], warningIsins: [],
      yAxisTitle: null, yAxisMax: null, yAxisUnits: null
    }

    this.prepareData = this.prepareData.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.renderBenchmarkToolTip = this.renderBenchmarkToolTip.bind(this);
    this.renderWarningIsins = this.renderWarningIsins.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.data && this.props.data !== prevProps.data) {
      this.prepareData()
    }

    if (prevProps.showBenchmark !== this.props.showBenchmark) {
      this.prepareData();
    }
  }

  onTypeChange(event) {
    this.setState({
      show: event.target.value,
    }, this.prepareData)
  }

  processWarnings (warnings) {
    return warnings.flat().filter((v, i, a) => a.indexOf(v) === i).join(', ')
  }

  prepareData() {
    const data = this.props.data;
    if(data) {
      let categories = [];
      let series = [];
      let warnings = [];

      let yAxisTitle = 'Maximaler Wertverlust';
      let valueName = 'maximum_drawdown';
      let yAxisUnits = ' %'
      let multiplier = 100;
      if(this.state.show === 'days') {
        yAxisTitle = 'Zeitraum der Wertaufholung (Tage)';
        valueName = 'recovery_days';
        yAxisUnits = '';
        multiplier = 1;
      }
      let yAxisMax = 0;
      let result = {ptf: [], bench: []};
      data.map((item) => {
        categories.push({
          name: item.name,
          dates: moment(item.start_date).format('DD.MM.YYYY') + ' - ' + moment(item.end_date).format('DD.MM.YYYY')
        });
        if(item.portfolio.warnings) warnings.push(item.portfolio.warnings)
        if(item.benchmark.warnings) warnings.push(item.benchmark.warnings)
        let ptfValue = item.portfolio[valueName] ? (item.portfolio[valueName] * multiplier) : item.portfolio[valueName];
        let benchValue = item.benchmark[valueName] ? (item.benchmark[valueName] * multiplier) : item.benchmark[valueName];
        let max = Math.max(Math.abs(ptfValue), Math.abs(benchValue));
        if(max > yAxisMax) {
          yAxisMax = max;
        }
        result.ptf.push(ptfValue)
        result.bench.push(benchValue)
      });
      series.push({
        name: 'portfolio', data: result.ptf, stacked: 's'
      })
      if(this.props.showBenchmark){
        series.push({
          name: 'benchmark', data: result.bench, stacked: 's'
        })
      }
      this.setState({
        categories: categories, series: series, warningIsins: this.processWarnings(warnings),
        yAxisTitle: yAxisTitle, yAxisMax: yAxisMax, yAxisUnits: yAxisUnits
      });
    }

  }

  renderBenchmarkToolTip(){
    let listItems = this.props.benchmarkDetails.map((item, index) => (
      <li key={index}>
        <span><b>{item.percentage}%</b>&nbsp;{item.benchmark_name}</span>
      </li>
    ))

    if(!listItems.length) {
      return 'Keine Benchmark erstellt';
    }

    return <ul>
      {listItems}
    </ul>
  }

  renderWarningIsins() {
    const { classes } = this.props;
    if(this.state.warningIsins.length) {
      return <p className={classes.warning}>
        Instrumente, die nicht über genügend historische Daten verfügen: {this.state.warningIsins}
      </p>
    }
  }

  renderChart() {

    const { classes } = this.props;

    const defaultChartColor = '#0098C6';
    const defaultBenchmarkColor = '#7ED2CF';
    const chartLegendStyle = {
      background: defaultChartColor
    };
    const benchmarkLegendStyle = {
      background: defaultBenchmarkColor
    };

    const yAxisUnits = this.state.yAxisUnits;

    return <div className={classes.chartContainer}>
       <RadioGroup aria-label="type" name="data-type" value={this.state.show} onChange={this.onTypeChange} row>
        <FormControlLabel value="drawdown" control={<Radio color="default" classes={{root: classes.radioSelectRoot, checked: classes.radioSelectChecked}} />} label="Maximaler Wertverlust" />
        <FormControlLabel value="days" control={<Radio color="default" classes={{root: classes.radioSelectRoot, checked: classes.radioSelectChecked}} />} label="Erholungs-Zeitraum" />
      </RadioGroup>

      <ul className={classes.legend}>
        <li className={classes.legendItem}>
          <div className={classes.bullet} style={chartLegendStyle} />
          <label>{this.props.portfolioName}</label>
        </li>

        <li className={clsx(classes.legendItem, !this.props.benchmarkDetails.length || !this.props.showBenchmark ? classes.disabled : undefined)}>
          <div className={classes.bullet} style={benchmarkLegendStyle} />
          <label>Benchmark</label>
          <WarningTooltip
            placement={'left'}
            disableHoverListener={!this.props.benchmarkDetails.length}
            disableTouchListener={!this.props.benchmarkDetails.length}
            disableFocusListener={!this.props.benchmarkDetails.length}
            title={
              <div>
                {this.props.showBenchmark && this.props.benchmarkDetails && this.renderBenchmarkToolTip()}
              </div>
            }
            iconClassName={clsx(classes.helpIcon, !this.props.benchmarkDetails.length ? classes.disabledIcon : undefined)}
          >
          </WarningTooltip>
        </li>
      </ul>

    <HighChartBase className={classes.chart} options={{
      colors: [defaultChartColor, defaultBenchmarkColor],
      chart: {
        type: 'column',
        height: 300,
        style: {
          fontFamily: "\"Roboto-Regular\", \"Lucida Sans Unicode\", Verdana, Arial, Helvetica, sans-serif",
          fontSize: 14
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: this.state.categories,
        tickLength: 0,
        labels: {
          style: {
            fontSize: 14,
          },
          formatter: function() {
            return "<span>" + this.value.dates + "<br/>" + "(" +this.value.name +")</span>";
          }
        }
      },
      yAxis: {
        max: this.state.yAxisMax,
        title: {
            text: "<b>" + this.state.yAxisTitle + "</b>",
            useHTML: true,
            rotation: -90
        },
        labels: {
          x: -2,
          formatter: function() {
            return toShortGermanFormat(this.value, '', yAxisUnits, 2, true);
          }
        },
        plotLines: [{
          color: '#747474',
          width: 2,
          value: 0,
          zIndex: 10
        }]
      },
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        column: {
          grouping: true,
          shadow: false,
          borderWidth: 0,
          stacked: 'normal',
        },
        tooltip: {
          enabled: false
        },
        series: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return (this.y === undefined || this.y === null) ? '' : toShortGermanFormat(this.y, '', yAxisUnits, 3, true);
            },
          },
          enableMouseTracking: true
        }
      },
      series: this.state.series
    }} language={'DE'}/>
        <p className={classes.description}>
          <sup>2</sup> Für einige Zeiträume werden keine Daten angezeigt, da nicht genügend historische Daten vorhanden sind oder die Erholung noch nicht erreicht wurde.
        </p>
          {this.renderWarningIsins()}
    </div>
  }

  render() {
    const { classes } = this.props;

    return <CommonAnalysisCard
        classes={{
          card: classes.root
        }}
        title={<span>Historische Stresstests<sup>2</sup></span>}
        loading={this.props.loading}
        content={
          <div className={classes.container}>
            {this.props.error ? this.props.error : this.props.data ? this.renderChart() : DEFAULT_EMPTY_SECTION_MESSAGE}
          </div>
        }
        expanded={this.props.expanded}
        onExpandedClick={this.props.onExpandedClick}
    />
  }
}

StressTestChart.defaultProps = {
  showBenchmark: true,
};

export default withStyles(styles)(StressTestChart)