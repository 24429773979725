import React from 'react';

import useStyles from './styles';
import ChartLegend from "../../../../components/Charts/Legend";
import WarningTooltip from "../../../../components/WarningTooltip";
import _ from "lodash";
import clsx from "clsx";
import {openMorningStartIsin} from "../../../../utils/utils";
import {PERFORMANCE_CHART_MISSING_DATA_INFO_TEXT} from "../../../ProductComparison/constants";


function Legend({data, flexDirection, asLink=false}) {
  const classes = useStyles({flexDirection});

  const dataSorted = _.sortBy(data, (item) => item.order);

  const handleItemClick = (item) => {
    return openMorningStartIsin(item.isin);
  }

  const legendItems = (() => {
    return dataSorted.map((timeSeries) => {

      const hasLink = asLink && !!timeSeries.isin;

      const Name  = <span className={!!hasLink && classes.link} onClick={() => hasLink && handleItemClick(timeSeries)}>{timeSeries.name}</span>

      const name = timeSeries.highlighted
        ? <b className={classes.highlightedLegendItem}>{Name}</b>
        : Name

      const otherControls = [];

      if (timeSeries.highlighted) {
        otherControls.push(<i className={clsx("fa fa-star fa-lg", classes.highlightIcon)}/>)
      }
      if (timeSeries.missingData) {
        otherControls.push((
          <span style={{display: 'inline-block', width: 40}}>
            <WarningTooltip
              title={timeSeries.missingDataText || PERFORMANCE_CHART_MISSING_DATA_INFO_TEXT}
            />
          </span>
        ))
      }
      return {name, otherControls, tooltip: timeSeries.name, color: timeSeries.color, symbol: _.get(timeSeries, 'marker.symbol')};
    })
  })();

  return (
    <ChartLegend
      legendItems={legendItems}
      classes={{
        container: classes.chartLegendContainer
      }}
    />
  )
}

export default Legend;
