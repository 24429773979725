import React from "react";
import _ from "lodash";
import clsx from "clsx";
import moment from "moment";

import Grid from '@material-ui/core/Grid';
import FormLabel from "@material-ui/core/FormLabel";
import {FormControl} from "@material-ui/core";

import ReportsOptions from '../../AdditionalReportOptions';
import { REPORTS_OPTIONS } from '../../AdditionalReportOptions/constants';
import { REPORT_TYPES } from '../../../../DashboardSettings/components/CustomersList/components/ReportType/constants';
import {parseResponse, PortfolioHandlerResource} from "../../../../../utils/api";
import {getErrorMessage} from "../../../../../utils/utils";
import PortfolioList from "../../../../CustomerDashboardV2/components_v2/PortfolioList";
import {AUTO_REPORT_PERIODICITY} from '../../../constants';
import WarningTooltip from "../../../../../components/WarningTooltip";
import {aggregateSelectedDisabledExplanation} from "../../../../DashboardSettings/constants";
import ReportsAreasVisibility
  from '../../../../DashboardSettings/components/CustomersList/components/ReportsAreasVisibility';
import {
  CUSTOMER_RELATED_REPORTS_AREAS_LIST_V2,
  REPORTS_AREAS
} from '../../../../DashboardSettings/components/CustomersList/components/ReportsAreasVisibility/constants';
import TimeFramePicker
  from '../../../../CustomerDashboardV2/components/InvestmentDetailsNavigation/PortfolioTimeSelector';
import { PERFORMANCE_TIME_TYPE } from '../../../../../utils/constants';
import AggregatedDepotsSwitch
  from '../../../../CustomerDashboardV2/components_v2/AggregatedDepotsSwitch/AggregatedDepotsSwitch';
import ReportTypeV2 from '../../../../DashboardSettings/components/CustomersList/components/ReportTypeV2';

import useStyles from '../../../../DashboardSettings/components/DasboardSettingWindowV2/styles';
import BenchmarkModal
  from '../../../../CustomerDashboardV2/components/InvestmentDetailsNavigation/BenchmarkSettingsModal';
import { isBenchmarksSettingsEqual } from '../../../../DashboardSettings/utils';
import { SENDING_DATE } from '../../ReportSendingDate/constants';
import { filterOutPortfoliosToExclude } from '../../../../DashboardSettings/components/DasboardSettingWindowV2';

const AVAILABLE_AREAS_OPTIONS = CUSTOMER_RELATED_REPORTS_AREAS_LIST_V2.filter(
  o => o.value !== REPORTS_AREAS.TRANSACTIONS_MONITOR.value);
export const PERIOD_OPTIONS = {
  CUSTOM: {...PERFORMANCE_TIME_TYPE.CUSTOM, value: SENDING_DATE.CUSTOM.value},
  LAST_1_YEARS: {...PERFORMANCE_TIME_TYPE.LAST_1_YEARS, value: SENDING_DATE.ONE_Y.value},
  LAST_3_YEARS: {...PERFORMANCE_TIME_TYPE.LAST_3_YEARS, value: SENDING_DATE.THREE_Y.value},
  LAST_5_YEARS: {...PERFORMANCE_TIME_TYPE.LAST_5_YEARS, value: SENDING_DATE.FIVE_Y.value},
  BEGINNING: {...PERFORMANCE_TIME_TYPE.BEGINNING, value: SENDING_DATE.BEGIN.value},
};

const LAST_PERIOD_OPTION = {
  LAST_PERIOD: {
    ...SENDING_DATE.LAST_PERIOD,
    placeholder: SENDING_DATE.LAST_PERIOD.description,
    getDateRange: () => ({
      start: undefined,
      end: undefined
    })}
};

const ReportSettingsV2 = props => {

  const {
    reportSettings,
    handleSettingUpdate,
    loading,
    isGroupReporting,
    benchmarks,
    handleBenchmarkUpdate,
  } = props;
  const classes = useStyles();

  const [benchmarkModalOpen, setBenchmarkModalOpen] = React.useState(false);

  // depots can be selected only for single customer report generation
  const [selectedDepots, setSelectedDepots] = React.useState([]);
  const [customerDepots, setCustomerDepots] = React.useState({
    data: undefined,
    loading: false,
    errors: undefined
  });

   React.useEffect(() => {
     if(!isGroupReporting){
       fetchCustomerDepots()
     }
   }, []);

   const fetchCustomerDepots = async () => {
    if(_.isNil(reportSettings)) { return; }

    try {
      setCustomerDepots({
        data: undefined,
        loading: true,
        errors: undefined
      });

      let response = await PortfolioHandlerResource.getCustomerDepots(reportSettings.customer_id);

      parseResponse(response, 'portfolios', (data) => {
        setCustomerDepots({
          data: data,
          loading: false,
          errors: undefined
        });

        // select portfolios from settings or all
        let selectedPortfolios = [];
        if(_.get(reportSettings, 'portfolio_ids')){
          selectedPortfolios = _.filter(data, (portfolio) =>
            _.some(reportSettings.portfolio_ids, p => p == portfolio.depotNumber) )
        }

         // if customer did not pass series reporting process before - selectedPortfolios is empty
        if(_.isEmpty(selectedPortfolios)){
          selectedPortfolios = data; // set available portfolios as selected
          handleSelectedPortfolioChanged(data) // update settings with automatically selected
        }

        setSelectedDepots(selectedPortfolios);

      }, (errors) => {
        setCustomerDepots({
          data: [],
          loading: false,
          errors: getErrorMessage(errors)
        });
      });
    }
    catch (errors) {
      setCustomerDepots({
        data: [],
        loading: false,
        errors: getErrorMessage(errors)
      });
    }
  };

  const handleSelectedPortfolioChanged = (justSelectedPortfolios) => {
    setSelectedDepots(justSelectedPortfolios);

    if(!isGroupReporting) {
      let selectedDepotNumbers = justSelectedPortfolios.map(p => p.depotNumber);
      handleSettingUpdate('portfolio_ids')(selectedDepotNumbers)
    }
  };

  const handleValueChanged = (fieldname) => (value) => {
    if (!_.isNil(handleSettingUpdate)) {
      handleSettingUpdate(fieldname)(value);
    }
  };

  const handleDateRangeChanged = (dates, datesType) => {
    handleValueChanged('date_range')(datesType.value.toString());
    handleValueChanged('date_range_start_date')(dates.start && dates.start.format('YYYY-MM-DD') || undefined);
    handleValueChanged('date_range_end_date')(dates.end && dates.end.format('YYYY-MM-DD') || undefined);
  };

  const getSettingsAdditionalReports = () => {
    let additionalReports = [];

    if (reportSettings.with_cost) {
      additionalReports.push(REPORTS_OPTIONS.COST.value);
    }
    if (reportSettings.with_transactions) {
      additionalReports.push(REPORTS_OPTIONS.TRANSACTIONS.value);
    }

    return additionalReports;
  };

  const getSettingsAreasVisibility = () => {
    let areasVisibility = [];

    if (reportSettings.with_payment_plans) {
      areasVisibility.push(REPORTS_AREAS.PAYMENT_PLANS.value);
    }
    if (reportSettings.with_profit_loss) {
      areasVisibility.push(REPORTS_AREAS.PROFIT_LOSS.value);
    }
    if (reportSettings.include_historical_portfolios) {
      areasVisibility.push((REPORTS_AREAS.INCLUDE_HISTORICAL_PORTFOLIOS.value))
    }
    if (reportSettings.with_transaction_saldo) {
      areasVisibility.push((REPORTS_AREAS.WITH_TRANSACTION_SALDO.value))
    }
    if (reportSettings.with_other_assets) {
      areasVisibility.push((REPORTS_AREAS.OTHER_ASSETS.value))
    }

    return areasVisibility;
  };

  const handleBenchmarkConfigured = (newBenchmarks) => {
    setBenchmarkModalOpen(false);
    if(_.isNil(newBenchmarks)) return;  // cancel was clicked

    let hasBenchmarks = newBenchmarks && newBenchmarks.length > 0;
    if (!hasBenchmarks) {
      updateBenchmarkSwitchState(false);
    } else {
      // FIXME: weight should be 0.1
      newBenchmarks = newBenchmarks.map(b => ({...b, weight: _.round(b.weight * 100, 5)}))
    }
    if (!isBenchmarksSettingsEqual(newBenchmarks, benchmarks)) {
      handleSettingUpdate('benchmark_updates')({
        benchmarks: newBenchmarks,
        benchmark_enabled: hasBenchmarks,
      });
      handleBenchmarkUpdate(newBenchmarks);
    }
  };

  const updateBenchmarkSwitchState = (forceShow) => {
    handleSettingUpdate('benchmark_updates')({
      benchmarks: reportSettings.benchmarks,
      benchmark_enabled: !!forceShow,
    })
  };

  let isDepotStatus = reportSettings.report_type === REPORT_TYPES.DEPOT_STATUS.value;
  let isBasis = reportSettings.report_type === REPORT_TYPES.BASIC.value;
  let benchmarkSwitchDisabled = isDepotStatus || isBasis;

  const [historicalAggregateSelectedSwitchDisabled, setHistoricalAggregateSelectedSwitchDisabled] =  React.useState(undefined);
  const [performanceAggregateSelectedSwitchDisabled, setPerformanceAggregateSelectedSwitchDisabled] =  React.useState(undefined);
  let [multiplePortfoliosAreSelected, seMultiplePortfoliosAreSelected] = React.useState(undefined);

  React.useEffect(() => {
    // conditions to disable switches if group reporting
    let histSwitchDisabled = isDepotStatus;
    let perfSwitchDisabled = isDepotStatus || isBasis;

    // extra conditions to disable switches for single reporting
    if(!isGroupReporting && !_.isNil(reportSettings.portfolio_ids)){
      let multPortfoliosSelected = reportSettings.portfolio_ids.length > 1;
      seMultiplePortfoliosAreSelected(multPortfoliosSelected);
      setHistoricalAggregateSelectedSwitchDisabled(histSwitchDisabled || !multPortfoliosSelected);
      setPerformanceAggregateSelectedSwitchDisabled(perfSwitchDisabled || !multPortfoliosSelected)

    } else if (isGroupReporting){
      seMultiplePortfoliosAreSelected(true);
      setHistoricalAggregateSelectedSwitchDisabled(histSwitchDisabled);
      setPerformanceAggregateSelectedSwitchDisabled(perfSwitchDisabled)
    }

  }, [reportSettings.report_type, reportSettings.portfolio_ids]);

  // in following useEffect-s check for reportSettings to fix cases when in containers/GroupReporting/Reporting.js fetchAutoReportData finishes later and overrides settings
  React.useEffect(() => {
    // if switch is disabled, but in settings aggregate selected is set to true - update settings
    if(historicalAggregateSelectedSwitchDisabled && reportSettings.aggregate_historical_chart_portfolios){
      handleValueChanged('aggregate_historical_chart_portfolios')(false)
    }
  }, [reportSettings.aggregate_historical_chart_portfolios]);

  React.useEffect(() => {
    if(performanceAggregateSelectedSwitchDisabled && reportSettings.aggregate_performance_chart_portfolios){
      handleValueChanged('aggregate_performance_chart_portfolios')(false)
    }
  }, [reportSettings.aggregate_performance_chart_portfolios]);

  const handleHistoricalDepotsChange = (value) => {
    handleSettingUpdate('include_historical_portfolios')(value);
    if(!value) {
      handleSelectedPortfolioChanged(filterOutPortfoliosToExclude(selectedDepots, undefined, value))
    }
  };

  const availableDepots = React.useMemo(() => {
    if (!_.isEmpty(customerDepots.data)) {
      return filterOutPortfoliosToExclude(customerDepots.data, undefined, reportSettings.include_historical_portfolios);
    }

    return customerDepots.data;
  }, [reportSettings.include_historical_portfolios, customerDepots.data]);

  const getValueFromSettings = (settingName) => {
    return _.get(reportSettings, settingName);
  };

  let periodOptions = {};
  const withPrevPeriod = reportSettings.is_auto_report && reportSettings.periodicity !== AUTO_REPORT_PERIODICITY.NONE.id;
  if(withPrevPeriod){
    Object.assign(periodOptions, PERIOD_OPTIONS, LAST_PERIOD_OPTION);
  } else {
    periodOptions = PERIOD_OPTIONS
  }

  const calculationDatesType = _.find(periodOptions, o => o.value == reportSettings.date_range);

  let calculationDates = {
    start: reportSettings.date_range_start_date && moment(reportSettings.date_range_start_date) || null,
    end: reportSettings.date_range_end_date && moment(reportSettings.date_range_end_date) || null
  };
  if (calculationDatesType.value !== PERIOD_OPTIONS.CUSTOM.value){
    calculationDates = calculationDatesType.getDateRange();
  }

  return (
    <Grid container spacing={2}>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className={clsx(classes.periodSelector, loading && 'loading-line')}>
              <TimeFramePicker
                options={periodOptions}
                calculationDates={calculationDates}
                calculationDatesType={calculationDatesType}
                handleCalculationDatesChanged={handleDateRangeChanged}
                loading={loading}
                showNoPriceOnWeekendsWarning
                useFilterStyles
                optionsInfo={{
                  BEGINNING: 'Bis zum heutigen Datum',
                  LAST_PERIOD: 'Mit dieser Option wird ein Report immer für die letzte abgeschlossene Periode des gewählten Zeitraums erstellt, also z.B. mit der Einstellung Quartal, für das letzte abgeschlossene Quartal. Diese Option ist relevant für automatische Serienreports, welche für Reports in regelmässigen Zeitabständen über zurückliegende Zeitperioden konfiguriert worden sind.'
                }}
                BenchmarkProps={{
                  enabled: !benchmarkSwitchDisabled,
                  showBenchmark: reportSettings.benchmark_enabled,
                  configuration: benchmarks,
                  onValueChanged: () => updateBenchmarkSwitchState(!reportSettings.benchmark_enabled),
                  onConfigurationClick: () => setBenchmarkModalOpen(true)
                }}
              />
              {benchmarkModalOpen && (
                <BenchmarkModal
                  onBenchmarkConfigured={handleBenchmarkConfigured}
                  benchmarks={benchmarks.map(b => ({...b, benchmark: _.get(b.benchmark, 'id') || b.benchmark}))}
                  withoutSnackbar
                />
              )}
            </div>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className={clsx(loading && 'loading-line')} >
              <AggregatedDepotsSwitch
                title={'Historische Depots'}
                value={getValueFromSettings('include_historical_portfolios')}
                onChange={handleHistoricalDepotsChange}
              />
            </div>
          </Grid>
          {!isGroupReporting &&
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className={clsx(classes.periodSelector, loading && 'loading-line')}>
                <PortfolioList
                  selectedPortfolios={selectedDepots}
                  handleSelectedPortfolioChanged={handleSelectedPortfolioChanged}
                  portfolios={availableDepots}
                  portfoliosLoadingError={customerDepots.errors}
                  portfoliosDataLoading={customerDepots.loading}
                  alwaysScrollableList={true}
                  loading={loading}
                  isListAutoUpdate
                  customClasses={{ container: classes.portfoliosSelectorContainer }}
                />
              </div>
            </Grid>
          }

          {/* historical chart settings */}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <FormControl disabled={props.disabled} component="fieldset" style={{ width: '100%' }}>
              <FormLabel component="legend" classes={{ root: classes.formLabel }}>
                Geldgewichtete Rendite-Chart
              </FormLabel>

              <Grid container>
                {/* invested capital switch */}
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className={clsx(loading && 'loading-line')}>
                    <AggregatedDepotsSwitch
                      title={'Kapitaleinsatz'}
                      value={!isDepotStatus && reportSettings.with_invested_capital || false}
                      onChange={handleValueChanged('with_invested_capital')}
                      disabled={isDepotStatus} // disabled if report type is depot status
                    />
                  </div>
                </Grid>
                {/* aggregate selected portfolios switch */}
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <AggregatedDepotsSwitch
                    title={<>Kumuliert {!_.isNil(multiplePortfoliosAreSelected) && !multiplePortfoliosAreSelected &&
                      <WarningTooltip width={"100%"} title={aggregateSelectedDisabledExplanation} />
                    }</>}
                    value={!historicalAggregateSelectedSwitchDisabled && reportSettings.aggregate_historical_chart_portfolios}
                    onChange={handleValueChanged('aggregate_historical_chart_portfolios')}
                    disabled={historicalAggregateSelectedSwitchDisabled}
                    loading={_.isNil(historicalAggregateSelectedSwitchDisabled)}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Grid>

           {/*Performance chart settings */}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <FormControl disabled={props.disabled} component="fieldset" style={{ width: '100%' }}>
              <FormLabel component="legend" classes={{ root: classes.formLabel }}>
                Zeitgewichtete Rendite-Chart
              </FormLabel>

              <Grid container>
                {/* aggregate selected portfolios switch */}
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <AggregatedDepotsSwitch
                    title={<>Kumuliert {!_.isNil(multiplePortfoliosAreSelected) && !multiplePortfoliosAreSelected &&
                      <WarningTooltip width={"100%"} title={aggregateSelectedDisabledExplanation} />
                    }</>}
                    value={!performanceAggregateSelectedSwitchDisabled && reportSettings.aggregate_performance_chart_portfolios}
                    onChange={handleValueChanged('aggregate_performance_chart_portfolios')}
                    disabled={performanceAggregateSelectedSwitchDisabled}
                    loading={_.isNil(performanceAggregateSelectedSwitchDisabled)}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Grid>

        </Grid>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className={clsx(loading && 'loading-line')}>
              <ReportTypeV2
                selected={reportSettings.report_type}
                handleChanged={handleValueChanged('report_type')}
                customReportTypeSettings={reportSettings && reportSettings.individual_settings}
                handleCustomReportSettingUpdate={handleSettingUpdate('individual_settings')}
              />
            </div>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className={clsx(loading && 'loading-line')}>
              <ReportsAreasVisibility
                label={'Zusätzliche Inhalte'}
                options={AVAILABLE_AREAS_OPTIONS}
                isDepotStatus={isDepotStatus}
                selected={getSettingsAreasVisibility()}
                handleChanged={handleValueChanged('areas_visibility')}
              />
            </div>
          </Grid>
          <Grid item lg={12} md={12} sm={6} xs={12}>
            <div className={clsx(loading && 'loading-line')} >
              <ReportsOptions
                selected={getSettingsAdditionalReports()}
                handleChanged={handleValueChanged('additional_reports')}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportSettingsV2;