/**
 * @typedef QuestionnaireStep
 * @type {Object}
 * @property {String} uid
 * @property {String} name
 * @property {Array<*>} question
 * @property {Array<String>} behaviour
 */

/**
 * @typedef Questionnaire
 * @type {Object}
 * @property {String} name
 * @property {String} uid
 * @property {Array<QuestionnaireStep>} steps
 */


import React from "react";
import _ from 'lodash';
import moment from 'moment'

import {
  getDanteCustomerData,
  getQuestionType,
  getStepAnswer,
  getUID, isNewDesignProcess, isValidBirthDate, setContactsOptionalFlag,
  setQuestionAnswer,
  setQuestionAnswerFromConfig, UpdateCustomerAndSync
} from "./utils";
import {QuestionnairesHandlerResource} from "../../utils/api";
import {congratsStep, CustomerDataMapping, finalStep, riskProfileSelectionStep} from "./mock";
import {
  COUPLE_MEMBERS_CONFIRMATION_STEP_IDENTIFIERS, COUPLE_MEMBERS_DISABLED_FIELDS_IDENTIFIERS,
  QUESTION_TYPE, SECURITY_DOCUMENT_HANDED_STEP_UIDS,
  stepGroups,
  USER_CONFIRMATION_STEP_IDENTIFIER
} from "./constants";
import {Service} from "./service";
import InvestmentIntermediaryInformation
  from "./components/StepContent/components/step/InvestmentIntermediaryInformationStep";
import {getOriginLink} from '../../utils/constants'

/**
 * Validate, if step is Customer validation step.
 * @param {QuestionnaireStep} step - Step data
 * @return {Boolean} - Result of validation
 * */
const isUserConfirmationStep = (step) => getUID(step.uid) === USER_CONFIRMATION_STEP_IDENTIFIER

/**
 * Validate, if step is Customer profession validation step.
 * @param {QuestionnaireStep} step - Step data
 * @return {Boolean} - Result of validation
 * */
const isUserProfessionStep = (step) => getUID(step.uid) === 'A4'


/**
 * Validate, if step is Couple member validation step.
 * @param {QuestionnaireStep} step - Step data
 * @return {Boolean} - Result of validation
 * */
const isCoupleMemberConfirmationStep = (step) => COUPLE_MEMBERS_CONFIRMATION_STEP_IDENTIFIERS.includes(getUID(step.uid))

/**
 * Validate, if step is risk profile data validation step.
 * @param {QuestionnaireStep} step - Step data
 * @return {Boolean} - Result of validation
 * */
const isRiskProfileStep = (step) => !isUserConfirmationStep(step) && !isCoupleMemberConfirmationStep(step)

/**
 * Get step number from step UID.
 * @param {QuestionnaireStep} step - Step data
 * @return {Number} - Step UID number
 * */
const getStepNumberFromUID = (step) => {
  const customAnlageberatungIndexes = {
    "21": 3.4, // in the new process A2 is splitted into 4 steps: A21, A2, A22, A23
    "2": 3.5, // put A2 after A3
    "22": 3.6,
    "23": 3.7,
    "13": 9.5, // put A13 before A10
    "14": 4.5, // put A14 after A4,
    "71": 7.1 // put A71 after A7
  };

  const customAnlagevermittlungIndexes = {
    "21": 2.4, // in the new process K2 is splitted into 4 steps: K21, K2, K22, K23
    "1": 2.5, // put K1 after K2
    "22": 2.6,
    "23": 2.7,
  };

  let customIndexes = {};

  // we have "A" step naming ONLY for Anlageberatung
  // for Anlagevermittlung steps starts with "K"
  switch (step.uid.charAt(0)) {
    case "A":
      customIndexes = customAnlageberatungIndexes;
      break;
    case "K":
      customIndexes = customAnlagevermittlungIndexes;
      break;
  }

  let stepIndex = parseInt(step.uid.substring(1));  // remove first char

  return _.get(customIndexes, `${stepIndex}`, stepIndex || 0); // 0 = all equal = keep order
};

/**
 * Check if UID is valid couple member step identifier and return member index.
 * @param {String} uid - Step UID
 * @return {Number} - Member index if UID is valid
 * */
export const getMemberIndexFromStepUID = (uid) => {
  if (!!uid && uid.match(/.*0[1,2]$/)) {
    return parseInt(uid.substr(-1)) - 1
  }
}


export class InteractService extends Service {
  constructor(uid, name, next_btn, success_text, getPrevStepAnswer, onLegalDocumentsButtonClick) {
    super(uid, name, next_btn, success_text, getPrevStepAnswer);
    this.__is_customer_confirmation = this.uid === 'user_confirmation';
    this._use_session = !this.__is_customer_confirmation;
    this._session_id = null;  // Interact session UID
    this._finalStep = this.__deepCopy(finalStep);
    this._defaultRiskProfileToken = null; // Dante RiskProfile UID to pre-fill answers from
    this._riskProfilesSelectionStep = this.__deepCopy(riskProfileSelectionStep);
    this._riskProfiles = [];
    this.__isCustomAnswers = false; // flag to get step / question UID
    this.is_investment_questionnaire = false;
    this.onLegalDocumentsButtonClick = onLegalDocumentsButtonClick;
    this.srri = null; // show SRRI step to confirm
  }

  /**
   * Make questions from step as disabled using provided identifiers.
   * @param {QuestionnaireStep} step - Step
   * @param {Array<String>} identifiers - Identifiers, that should be disabled
   * */
  __makeQuestionsDisabled(step, identifiers) {
    step.question.forEach(question => {
      if (identifiers.includes(getUID(question.uid))) {
        question.disabled = true;
        question.optional = true
      }
    })
  }

  /**
   * Prepare Interact questionnaire.
   * @param {Questionnaire} questionnaire - Questionnaire data.
   * @return {Questionnaire} - Questionnaire with valid steps configuration
   * */
  __buildQuestionnaire(questionnaire) {

    const { steps } = questionnaire;
    const stepsSorted = _.sortBy(steps, getStepNumberFromUID);

    const userConfirmationStep = _.filter(stepsSorted, isUserConfirmationStep)
    if (userConfirmationStep.length) {
      this.__makeQuestionsDisabled(userConfirmationStep[0], COUPLE_MEMBERS_DISABLED_FIELDS_IDENTIFIERS)
    }
    const coupleMembersConfirmationSteps = _.filter(stepsSorted, isCoupleMemberConfirmationStep)
    const otherRiskProfileDataSteps = _.filter(stepsSorted, isRiskProfileStep)

    const stepsConfigured = _.flatten(
      [userConfirmationStep, coupleMembersConfirmationSteps, this.__is_customer_confirmation ? [] : otherRiskProfileDataSteps]);

    if(this.isNewDesign){
      const KNOWLEDGE_GROUP = 'Kenntnisse & Erfahrungen';
      const OCCUPATION_GROUP = 'Beruf & Bildungsabschluss';
      const INCOME_GROUP = 'Einkommen & Überschuss';
      const ASSETS_GROUP = 'Vermögen & Verbindlichkeiten';
      const INVESTMENT_GROUP = 'Anlageziel & -zeitraum';
      const stepsParents = {
        'A21': KNOWLEDGE_GROUP, 'A22': KNOWLEDGE_GROUP, 'A2': KNOWLEDGE_GROUP, 'A23': KNOWLEDGE_GROUP,
        'A4': OCCUPATION_GROUP, 'A14': OCCUPATION_GROUP,
        'A5': INCOME_GROUP, 'A6': INCOME_GROUP,
        'A7': ASSETS_GROUP, 'A71': ASSETS_GROUP,
        'A8': INVESTMENT_GROUP, 'A9': INVESTMENT_GROUP,
      };

      stepsConfigured.forEach(step => {
        const stepUID = this._getStepUID(step.uid);
        step.parent = stepsParents[stepUID];
      })
    }

    return {
      ...questionnaire,
      steps: stepsConfigured
    }
  }

  /**
   * Fill questions with customer data.
   * @param {function} getUserDataFunction - Async function to get user data
   * @param {function} getUserStepFunction - Function, that should be passed to iterator, to find needed step
   * @param {function} setQuestionAnswerFunction - Function, that handle question answer handling
   * @param {function} [handleUserDataCallback] - Callback, that should be called with user data
   * */
  async __prefillUserData(getUserDataFunction, getUserStepFunction, setQuestionAnswerFunction, handleUserDataCallback) {

    if (!this._questionnaire) return

    const userConfirmationSteps = _.filter(this._questionnaire.steps, getUserStepFunction);

    if (userConfirmationSteps.length) {

      const userData = await getUserDataFunction(this._customer_id);
      handleUserDataCallback && handleUserDataCallback(userData);

      userConfirmationSteps.forEach(step => {
        (step.question || []).map((question) => {
          setQuestionAnswerFunction(question, userData)
        });
      })
    }

  }

  /**
   * Fill questions with couple members data.
   * @param {Array<Object>} membersData - Couple members data
   * @param {String} stepUID - UID of step, that should be prefilled with data
   * */
  async __prefillMembersData(membersData, stepUID) {

    const isValidQuestionnaire = !!this._questionnaire
    const isValidMembers = this.useMembers && Array.isArray(this.members) && this.members.length

    if (!isValidQuestionnaire || !isValidMembers) return

    membersData.forEach((member, index) => {
      const memberStepUID = `${stepUID}0${index + 1}`
      this.__prefillUserData(
        async () => member,
        (step) => getUID(step.uid) === memberStepUID,
        (question, userData) => {
          setQuestionAnswerFromConfig(question, userData, this.__mappingConfFunction);
        })
    })

  }

  _syncQuestions() {
    if (!this._questionnaire) return
    this._questionnaire.steps.forEach(step => {
      if (getUID(step.uid) === USER_CONFIRMATION_STEP_IDENTIFIER) {
        step.question.forEach(question => {
          if (getUID(question.uid) === 'A13') {
            question.onButtonClick = this.onLegalDocumentsButtonClick
          }
        })
      }
    })
  }

  async updateCustomerMemberData() {
    const memberIndex = getMemberIndexFromStepUID(getUID(this._step.uid))
    if (!_.isUndefined(memberIndex) && this.members[memberIndex]) {

      let customerData = await UpdateCustomerAndSync(this.members[memberIndex].id, this._step, this.__mappingConfFunction, undefined, this.isSwitchSellFlow);
      // TODO: As an improvement for future we should update first step in case related member data was updated
      if (customerData) {
        let { data } = customerData
        this.members[memberIndex] = data

        let updatedData = {
          "relationships": this.members
        }

        await this.__prefillUserData(
          async () => updatedData,
          isUserConfirmationStep,
          (question, userData) => {
            setQuestionAnswerFromConfig(question, userData, this.__mappingConfFunction);
          })
        let userConfirmationStep = _.find(this._questionnaire.steps, isUserConfirmationStep)
        if (!!userConfirmationStep) {
          await this.__sendStepAnswerData(userConfirmationStep)
        }
      }
    }
  }

  // flag to identify if we need to use existing Risk Profile
  get useSRRI() {
    return !!(this.srri && this._session_id);
  }

  get isFistStep() {
    return this.useSRRI || super.isFistStep;
  }

  get hideProgress(){
    return this._step && this._step.uid === this._riskProfilesSelectionStep.uid;
  }

  /**
   * Make Security Documents question required.
   *
   * @example
   * For sell only trading questions should be optional.
   * For other than that - function should be executed.
   *
   * @private
   */
  __makeSecurityDocumentHandedQuestionRequired() {

    this._questionnaire.steps.forEach((step) => {

      if (!SECURITY_DOCUMENT_HANDED_STEP_UIDS.includes(getUID(step.uid))) return;

      step.question.forEach((question) => {

        if (getUID(question.uid) !== 'I1') return;

        question.optional = false
      })

    })

  }

    /**
   * Clean question answers for specific step.
   * Tested with SECURITY_DOCUMENT_HANDED_STEP_UIDS steps.
   * Pay attention when use with other steps.
   *
   * @param step - Step
   * @private
   */
  cleanStepAnswers(step) {
    step.question.forEach((question) => {
      question.answer = [];
    })
  }

  async __setQuestionnaire(onboardingAnswers, initOnly) {
    await super.__setQuestionnaire(onboardingAnswers, initOnly);
    let response = await QuestionnairesHandlerResource.get({
      customer_id: this._customer_id, is_investment_questionnaire: this.is_investment_questionnaire, new_design: this.__is_new_design});
    const fullAdvisory = !this.is_investment_questionnaire && !this.__is_customer_confirmation;
    let answers = [];

    this._questionnaire = this.__buildQuestionnaire(response.response);
    if (this.useSRRI) {
      try {
        response = await QuestionnairesHandlerResource.at('get_score/').get({session: this._session_id});
        answers = response && response.response && response.response.answers || [];
        this.__setFinalQuestionData(this.srri, this.srri, true);
        this._step = this._finalStep;
      } catch (e) {
        this.warning = "Bei der Datenabfrage für das aktuelle Risikoprofil ist ein Fehler aufgetreten.";
        console.error("ERROR DURING GET EXISTING RISK PROFILE");

        this.__initVars();
        this.srri = null;
        this._use_session = false;
        await this.__setQuestionnaire(onboardingAnswers, initOnly);
        return;
      }
    } else {
      if(this._use_session && !(initOnly && this._session_id)) {  // skip step set for session init only
        response = await QuestionnairesHandlerResource.at('get_step/').get({
          customer_id: this._customer_id,
          onboarding_uid: this.onboarding_uid,
          is_investment_questionnaire: this.is_investment_questionnaire,
          new_design: this.__is_new_design
        });
        const res = response && response.response;
        this._defaultRiskProfileToken = response && response.origin_session_id;
        this._session_id = res.session;
        if(!_.isEmpty(res.current_step)) this._step = res.current_step;
      }

      if(this._session_id){
        response = await QuestionnairesHandlerResource.at('get_score/').get({session: this._session_id});
        answers = response && response.response && response.response.answers || [];
        if(fullAdvisory && response && response.response && response.response.completed) {
          // init score from Onboarding or Interact
          this.__initScore(response, onboardingAnswers);
          this._step = this._finalStep;
        }
      } else if(fullAdvisory) {
        // Add Risk Questionnaire Selection for New process
        try {
          this._riskProfiles = await QuestionnairesHandlerResource.at('get-risk-profiles/').get({customer_id: this._customer_id}) || [];
        } catch (e) {
          console.error(e);
        }
        this._questionnaire.steps.unshift(this._riskProfilesSelectionStep);
        this._step = this._riskProfilesSelectionStep;
      }
    }

    // region Fill user confirmation steps with data (and couple members data in case couple onboarding)
    await this.__prefillUserData(
      getDanteCustomerData,
      (step) => isUserConfirmationStep(step) || isUserProfessionStep(step),
      (question, userData) => {
        setQuestionAnswerFromConfig(question, userData, this.__mappingConfFunction);
      },
      (customerData) => {
        this.customer_type = customerData.customer_type;
        this.current_customer = customerData;
        this.members = customerData.relationships;
      });
    await this.__prefillMembersData(this.members, USER_CONFIRMATION_STEP_IDENTIFIER)
    // endregion

    // set answers from our db or questions config
    this._setDefaultAnswers(onboardingAnswers);

    // get Default answers to pre-fill Questionnaire
    let defaultAnswers = await this._getDefaultAnswers();

    this._questionnaire.steps.map((step) => {
      (step.question || []).map((q) => {
        const answer = answers.find(a => getUID(q.uid) === getUID(a.question_uid)) || defaultAnswers.find(a => getUID(q.uid) === getUID(a.question_uid));
        if(answer){
          setQuestionAnswer(q, _.flatten((answer.answer_data && answer.answer_data.answer || []).map(
              answer => _.isObject(answer) && answer.uid ? answer.uid : answer)));
        }

        // Slider/ChartSelect: set step info_text from question info
        if([QUESTION_TYPE.SLIDER, QUESTION_TYPE.CHART_SELECT].includes(getQuestionType(q))){
          step.info_text = q.info;
        }
        // added custom validation for Birth Date
        const customer_type = this.customer_type;
        if(['C1', 'C101', 'C102'].includes(getUID(q.uid))){
          q.isValid = function(){
            return isValidBirthDate(this, customer_type);
          }
        }
      });

      let groups = [];
      const stepUID = getUID(step.uid);

      if(SECURITY_DOCUMENT_HANDED_STEP_UIDS.includes(stepUID)){
        step.step_content = InvestmentIntermediaryInformation;
      }

      // iterate over groups
      Object.entries(stepGroups).map(([key, value]) => {
        if (stepUID === key) {
          value.map((group) => {
            if(this.is_couple && stepUID === 'A1' && group.uid === 'A-address'){
              group.question_text = 'Versandanschrift';
            }
            // remove all questions related to group from step
            const groupItems = _.remove(step.question, (q) => group.questionUIDs.includes(getUID(q.uid)));
            if(!_.isEmpty(groupItems)) {
              // use info text from first question with info
              const infoTextItem = groupItems.find(i => i.info);
              // add all questions to groups
              groups.push({...group, question: groupItems, info_text: infoTextItem && infoTextItem.info});
            }
          })
        }
      });
      // add groups to step questions
      step.question = [...groups, ...step.question];
    });

    // set contacts Phone / E-Mail required if it's not SwitchSellFlow
    // NOTE: do it after grouping questions - so "A-contacts" group created
    if (!this.isSwitchSellFlow) {
      setContactsOptionalFlag(this._questionnaire.steps, false);
    }

    let questionnaire;
    try {
      let lastRiskProfile = await QuestionnairesHandlerResource.getLastFinishedRiskProfile(this.customer_id, true, true)
      questionnaire = lastRiskProfile && lastRiskProfile.questionnaire;
    } catch (e) {
      console.error(e);
    }

    if (questionnaire) {
      let { response: { answers } } = questionnaire

      let basicInformationAnswer = _.find(answers, (answer => getUID(answer.question_uid) === 'I1'))
      if (basicInformationAnswer) {
        let { answer_data: { answer }} = basicInformationAnswer

        if (answer && answer.length) {
          let { information_erfolgte_am, versionsnr } = answer[0];

          let basicInformationStepIndex = this._questionnaire.steps.findIndex((step => ['K2', 'A3'].includes(getUID(step.uid))))
          if (basicInformationStepIndex >= 0) {
            let basicInformationQuestionIndex = this._questionnaire.steps[basicInformationStepIndex].question.findIndex(question => getUID(question.uid) === 'I1')
            if (basicInformationQuestionIndex >= 0) {
              this._questionnaire.steps[basicInformationStepIndex].question[basicInformationQuestionIndex].answer = {
                calendar: moment(information_erfolgte_am),
                input: versionsnr,
                checkbox: true
              }
            }
          }
        }
      }
    }

    if(fullAdvisory){
      // add custom steps
      if(this.is_couple){
        this._finalStep.name = "Risikoprofil der Gemeinschaft: {risk_category}";
        this._finalStep.info = "Das heißt, für diese Gemeinschaft wird eine {risk_category} Anlagestrategie empfohlen werden.";
      }
      this._questionnaire.steps.push(this._finalStep);

      if(this.isNewDesign && this.getHasMissingAnswers()){
        // in case Risk profile has missing answers clear final and suggested scores/sri
        this.__setSRRIData(undefined, undefined, true);
      }
    }
    if(!!this.next_btn && !!this.success_body) {
      this._questionnaire.steps.push({...this.__deepCopy(congratsStep), name: this.name});
    }

    if (!this.isSellOnlyFlow) {
      this.__makeSecurityDocumentHandedQuestionRequired()
    } else if (this.step && SECURITY_DOCUMENT_HANDED_STEP_UIDS.includes(getUID(this.step.uid))) {

      this.cleanStepAnswers(this.step)

      await this.nextStep()
    }

  }

  get riskProfiles() {
     return this._riskProfiles;
  }

  async resendResult() {
    this.__initVars();
    this.srri = null;
    this._use_session = false;
    await this.initQuestionnaire();
  }

  async confirmResult(data) {
    if(this.step.uid === 'congrats-step'){
      this._is_finished = true;
      console.log('Thank you');
    } else {
      if (this.useSRRI) {
        // set existing questionnaire to current process
        await QuestionnairesHandlerResource.at('set_onboarding_questionnaire/').post({
          customer_id: this.customer_id,
          session_id: this._session_id,
          onboarding_uid: this.__onboarding_uid,
        })
      // no profile handling for new design
      } else if(!this.isNewDesign || _.get(data, 'srri')) {
        const stepAnswer = data || {};
        stepAnswer.session_id = this._session_id;
        stepAnswer.customer_id = this._customer_id;
        await QuestionnairesHandlerResource.at('set_risk_profile/').post(stepAnswer);
      }

      await this.__setStep(1);
    }
  }

  async nextStep(onlySendData=false) {
    if(this._step.uid === this._riskProfilesSelectionStep.uid){
      // get origin session
      const riskProfileToken = this._step.question[1].answer;
      if(riskProfileToken) {
        this._defaultRiskProfileToken = riskProfileToken;
        // get origin session answers
        const answers = await this._getDefaultAnswers();
        // set origin session answers
        this._questionnaire.steps.map((step) => {
          this._setAnswersFromPrevSession(step, answers);
        })
      }

      this._questionnaire.steps.splice(0, 1); // remove Risk selection step from steps
      await this.__setStep(null, 0);
      return;
    }
    const latestOnboardingData = await this.__sendStepData();
    if(this.isLastStep) {
      this._is_finished = true;
    } else {
      if (!onlySendData) {
        await this.__setStep(1);
      }
    }

    return latestOnboardingData
  }

  get success_body() {
    // Stammdaten
    if(this.uid === 'user_confirmation') {
      return null;
    }

    // Kenntnisse & Erfahrungen
    if(this.is_investment_questionnaire) {
      return "Die Kenntnisse & Erfahrungen des Kundens wurden erfolgreich gespeichert."
    }

    // RiskProfiling
    return (
      <>
        Das Risikoprofil {this.is_couple ? 'der Familie' : 'des Kunden'}  wurde erfolgreich gespeichert. Sie finden das
        zugehörige Dokument in der <a href={getOriginLink(`/#/Kunden/${this._customer_id}/Dokumente`)} target="_blank">Dokumentenablage</a> Ihres Kunden.
      </>
    )
  }

  async _getDefaultAnswers() {
    if(this._defaultRiskProfileToken) {
      let res = await QuestionnairesHandlerResource.at(
        'get-risk-profile-details/').get({token: this._defaultRiskProfileToken});
      return res && res.response && res.response.answers || [];
    }

    return [];
  }

  _setAnswersFromPrevSession(step, answers){
    (step.question || []).map((q) => {
      if(q.question) {
        this._setAnswersFromPrevSession(q, answers)
      } else {
        // if CustomerData already pre-filled - DO NOT set it from "old" session
        if(_.isNil(q.answer) || !Object.keys(CustomerDataMapping).includes(this._getQuestionUID(q.uid))) {
          this.__setAnswer(q, answers)
        }
      }
    })
  }

  __setAnswer(q, answers){
    const answer = answers.find(a => getUID(q.uid) === getUID(a.question_uid));
    if (answer) {
      setQuestionAnswer(q, _.flatten((answer.answer_data && answer.answer_data.answer || []).map(
        answer => _.isObject(answer) && answer.uid ? answer.uid : answer)));
    }
  }

  async __sendStepAnswerData(step) {
    const stepAnswer = getStepAnswer(step);
    if (this._session_id) stepAnswer.session = this._session_id;
    if(this._defaultRiskProfileToken) stepAnswer.origin_session_id = this._defaultRiskProfileToken;
    stepAnswer.customer_id = this._customer_id;
    stepAnswer.onboarding_step_uid = this.__onboarding_step_uid;
    stepAnswer.onboarding_uid = this.__onboarding_uid;
    stepAnswer.is_investment_questionnaire = this.is_investment_questionnaire;
    stepAnswer.new_design = this.__is_new_design;
    return await QuestionnairesHandlerResource.post(stepAnswer);
  }

  async __sendStepData() {
    if(this._step && [USER_CONFIRMATION_STEP_IDENTIFIER, 'A4'].includes(getUID(this._step.uid))){
      let customerData = await UpdateCustomerAndSync(this._customer_id, this._step, this.__mappingConfFunction, undefined, this.isSwitchSellFlow);

      if (getUID(this._step.uid) === USER_CONFIRMATION_STEP_IDENTIFIER) {
        if (customerData && !customerData.errors && Array.isArray(customerData.relationships) && customerData.relationships.length) {
          let membersDataUpdated = customerData.relationships.map(member => member.data)
          await this.__prefillMembersData(membersDataUpdated, USER_CONFIRMATION_STEP_IDENTIFIER)
        }
      }
    }

    const isValidMembers = this.useMembers && Array.isArray(this.members) && this.members.length

    if (isValidMembers && COUPLE_MEMBERS_CONFIRMATION_STEP_IDENTIFIERS.includes(getUID(this._step.uid))) {
      await this.updateCustomerMemberData()
    }

    let response = await this.__sendStepAnswerData(this._step)
    if(this.__is_customer_confirmation) return response;

    this._session_id = response.response.session;

    const _res = response.response;
    if (_res.current_step && _res.current_step.uid === this._step.uid && !_res.finished){
      throw Error('Invalid step');
    }
    if(_res.finished && !this.is_investment_questionnaire && (!this.isNewDesign || !this.getHasMissingAnswers())){
      response = await QuestionnairesHandlerResource.at('get_score/').get({session: this._session_id});
      this.__initScore(response);
    }
  }

  __initScore(response, defaultAnswers) {
    const final_srri = defaultAnswers && defaultAnswers.srri;
    const suggested_srri = response.response && response.response.band_score_id;
    this.__setFinalQuestionData(suggested_srri, final_srri)
  }

  __setFinalQuestionData(suggested_srri, final_srri, disabled=false) {
    if (suggested_srri || final_srri) {
      this.__setSRRIData(suggested_srri, final_srri, disabled)
    } else {
      throw Error('Can\'t get score');
    }
  }

  __setSRRIData(suggested_srri, final_srri, disabled){
    const finalQuestion = this._finalStep && _.isArray(this._finalStep.question) && this._finalStep.question[0];
    if(finalQuestion) {
      finalQuestion.answer = final_srri || suggested_srri;
      finalQuestion.value = suggested_srri || final_srri;
      finalQuestion.disabled = !!disabled;
    }
  }

  __mappingConfFunction = (i) => CustomerDataMapping[getUID(i.uid)];

}