import React from "react";
import _ from "lodash";
import {generateQueryParamsHash, useSharedDataContext} from "../utils";
import {useTabsSharedDataContext} from "../components_v2/TabsSharedData/TabsSharedData";

const SHARED_DATA_KEY = 'basic-assets-data';

export function useBasicPortfolioData(customerId, portfolio, dataProvider) {

  const dataContext = useSharedDataContext();
  const sharedData = useTabsSharedDataContext();

  const fetchBasicPortfolioData = () => {
    if(dataContext.isPortfolioDataLoading(portfolio.depotNumber)){
      return;
    }

    const _setState = (depotNr, depotData) => {
      // It is not possible to sync data with shared data in
      // hook because hook is not executed if component destroyed
      dataContext.setPortfolioData(depotNr, depotData);
      sharedData.setDataValue(`${SHARED_DATA_KEY}-${portfolio.depotNumber}`, hashKey, depotData);
    };

    const hashKey = generateQueryParamsHash(customerId, [portfolio]);

    dataContext.setPortfolioDataLoading(portfolio.depotNumber, true);
    _setState(portfolio.depotNumber,{
      basicPortfolioData: null,
      basicPortfolioLoading: true,
      basicPortfolioError: null,
      updatedAt: +new Date(),
    });

    dataProvider.getBasicPortfolioData(customerId, portfolio.depotNumberForAssets || portfolio.depotNumber)
      .then((response) => {
        response.portfolio_data.data[0].components = _.orderBy(response.portfolio_data.data[0].components, ['weight'], 'desc')
        if(!!portfolio){
          // portfolio name is changed to display name instead of id
          response.portfolio_data.data[0].name = portfolio.name;
        }
        _setState(portfolio.depotNumber, {
          basicPortfolioData: response,
          basicPortfolioLoading: false,
          basicPortfolioError: null,
          updatedAt: +new Date()
        })
      })
      .catch((error) => {
        _setState(portfolio.depotNumber, {
          basicPortfolioData: null,
          basicPortfolioLoading: false,
          basicPortfolioError: error,
          updatedAt: +new Date()
        })
      })
      .finally(() => {
        dataContext.setPortfolioDataLoading(portfolio.depotNumber, false);
      })
  };

  React.useEffect(() => {
    if (!!customerId && !!portfolio) {
      const sharedPortfolioData = !!sharedData
        ? sharedData.getDataValue(`${SHARED_DATA_KEY}-${portfolio.depotNumber}`, generateQueryParamsHash(customerId, [portfolio]))
        : undefined;

      if (!!sharedPortfolioData && !sharedPortfolioData.errors) {
        // sync internal hook data with shared data.
        dataContext.setPortfolioData(portfolio.depotNumber, sharedPortfolioData);
        return;
      }

      // fetch data if there is no shared data or shared data was loaded with error
      // check if data fetched for the first time (updatedAt is undefined) to prevent infinite data reloading
      // in case of data loading error, so data will be requested again only if tab changed
      if (!dataContext.getPortfolioData(portfolio.depotNumber).updatedAt || !sharedPortfolioData) {
        fetchBasicPortfolioData();
      }
    }
  }, [customerId, _.get(portfolio, 'depotNumberForAssets'), _.get(portfolio, 'depotNumber')]);

  return {
    basicPortfolioData: dataContext.getPortfolioData(portfolio.depotNumber)
  }

}