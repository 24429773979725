import moment from 'moment';
import _ from 'lodash';
import { validatePortfolioTransactions } from './constants';
import CombinedTradeStep from './components/TradeStep/CombinedTradeStep';

function getValidPortfolios(portfolios, skipEmpty = false, dataService) {
  if (_.isEmpty(portfolios) || _.isNil(portfolios)) {
    return {
      isValid: false,
      validPortfolios: [],
    };
  }

  let isValid = true, validPortfolios = [];
  portfolios.forEach(portfolio => {
    let validatedPortfolioResult = validatePortfolioTransactions.bind(this)(portfolio, dataService)

    // For testing purposes. Need to remove later.
    if (!validatedPortfolioResult.isValid) {
      console.error('Error during portfolio validation.')
      console.log(portfolio)
    }

    if (isValid) {
      isValid = validatedPortfolioResult.isValid
      if (!isValid && skipEmpty) {
        isValid = !validatedPortfolioResult.hasTransactions
      }
    }
    if (validatedPortfolioResult.isValid) {
      validPortfolios.push(portfolio)
    }
  })

  return {
    isValid,
    validPortfolios
  }
}

const step1 = {
  "uid": "trade-step",
  "name": null,
  "behaviour": ["dynamic", "Handeln Details"],
  "custom_classes": {"stepName": "text-left"},
  "step_content": CombinedTradeStep,
  "question": [
    {
      "uid": "transactions",
      "type": "input",
      "question_text": "Gespeichertes Referenzkonto auswählen",
      "custom_classes": {"labelRoot": "bold"},
      "minDepositDate": moment().add(1, 'days'),
      "isValid": function(step, afterChange, dataService) {
        const { isValid, validPortfolios } = getValidPortfolios.bind(this)(this.answer, true, dataService);
        const isValidStep = isValid && validPortfolios.length;

        if (isValidStep) {
          this.answer = validPortfolios;
        }

        return isValidStep;
      },
    },
  ],
};

export const combinedTradeStepsData = {
  steps: [
    step1,
  ],
};
