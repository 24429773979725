import React from "react";
import {withStyles} from "@material-ui/core";
import styles from "./styles";

import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from '@material-ui/core/TableCell';

import {openMorningStartIsin} from "../../../../../utils/utils";
import clsx from "clsx";
import _ from "lodash";
import {DEFAULT_EMPTY_SECTION_MESSAGE, NO_SECTION_MARK} from "../../../../../utils/constants";
import {getComparator, stableSort} from "../../../../VirtualPortfolioPage/Tabs/CommonAnalysis";
import { SECTION_PADDING } from '../../../constants';
import ErrorMessage from '../../ChartSectionBordered/components/ErrorMessage';


class RankingPerformanceItem extends React.Component {

    render () {
        let data = this.props.data;
        let colors = [1, 2, 3, 4, 5];
        if(data) {
            return <div>
                <div className='indicator' title={data.percentile_rank}>
                    {
                        colors.map((i) => (
                            <div key={i} className={i === data.percentile_rank_key ? 'active' : 'non-active'}></div>
                        ))
                    }
                </div>
                <div className='rank'>
                    <span>{data.absolute_rank || '-'}</span>/<span>{data.group_size || '-'}</span>
                </div>
            </div>
        } else return <div style={{textAlign: 'center'}}>{NO_SECTION_MARK}</div>;
    }
}

export const RANKING_PERFORMANCE_TABLE_INFO_TEXT = "Diese Tabelle zeigt, wie sich der Fonds über verschiedene Zeiträume im Vergleich zu seinen Peers in derselben Morningstar-Kategorie entwickelt. Die Rankings basieren auf der Gesamtrendite der Fonds und werden in absolutem und Quintil-Ranking angezeigt. Ein absolutes Ranking von 174/210 bedeutet, dass der Fonds in der jeweiligen Vergleichsgruppe unter 210 Fonds auf Platz 174 steht. Ein Quintil-Rang von 5 bedeutet, dass der Fonds zu den 20% der Fonds mit der schlechtesten Performance gehört, während ein Quintil-Rang von 1 bedeutet, dass der Fonds zu den 20% der Fonds mit der besten Performance unter den Vergleichsfonds gehört."

export const rankingDataMissing = (data) => {
  return data && _.some(data, (asset) => {
    return _.some(['1m', 'ytd', '1y', '3y', '5y'], (dataKey) => !asset[dataKey]);
  })
}

class RankingPerformanceTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            order: this.props.order,
            orderBy: this.props.orderBy
        }
    }

    componentDidUpdate(prevProps) {
      if (this.props.data && this.props.data !== prevProps.data) {
        this.props.data.forEach((asset) => {
          asset['1m_sort_key'] = asset['1m'] ? asset['1m'].percentile_rank_key : null;
          asset['ytd_sort_key'] = asset['ytd'] ? asset['ytd'].percentile_rank_key : null;
          asset['1y_sort_key'] = asset['1y'] ? asset['1y'].percentile_rank_key : null;
          asset['3y_sort_key'] = asset['3y'] ? asset['3y'].percentile_rank_key : null;
          asset['5y_sort_key'] = asset['5y'] ? asset['5y'].percentile_rank_key : null;
        })
        this.renderTable()
      }
    }

    renderTableRows() {
        const { classes } = this.props;

        const rows = [];

        let data = this.props.data || [];

        if (data && data.length > 0) {

            stableSort(data, getComparator(this.state.order, this.state.orderBy)).forEach((asset, index) => {

            let link = undefined;

            if (asset.hasOwnProperty('link')) {
              link = asset['link']
            }

                rows.push(
                    <tr key={asset.isin + '_' + index}>
                        <td className={classes.leftAligned}>

                          {link ?
                            <a href={link} target={'_blank'} className={classes.assetName} title={asset.name}>
                              {asset.name}
                            </a>
                            :
                            <a onClick={() => {openMorningStartIsin(asset.isin, null)}} className={classes.assetName} title={asset.name}>
                              {asset.name}
                            </a>
                          }
                          <br />
                          {asset.isin || '-'} / {asset.wkn || '-'}
                        </td>

                        <td>
                            {asset.ms_category}
                        </td>
                        <td className={!asset['1m'] && classes.tableCellVerticalAlignMiddle}>
                            <RankingPerformanceItem data={asset['1m']}></RankingPerformanceItem>
                        </td>
                        <td className={!asset['ytd'] && classes.tableCellVerticalAlignMiddle}>
                            <RankingPerformanceItem data={asset['ytd']}></RankingPerformanceItem>
                        </td>
                        <td className={!asset['1y'] && classes.tableCellVerticalAlignMiddle}>
                            <RankingPerformanceItem data={asset['1y']}></RankingPerformanceItem>
                        </td>
                        <td className={!asset['3y'] && classes.tableCellVerticalAlignMiddle}>
                            <RankingPerformanceItem data={asset['3y']}></RankingPerformanceItem>
                        </td>
                        <td className={!asset['5y'] && classes.tableCellVerticalAlignMiddle}>
                            <RankingPerformanceItem data={asset['5y']}></RankingPerformanceItem>
                        </td>
                    </tr>
                )
            })
        }
        return rows;
    }

    onRequestSort(event, property) {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({
            orderBy: property,
            order: isAsc ? 'desc' : 'asc'
        })
    }

    renderTableHeader() {
        const { classes } = this.props;

        const createSortHandler = (property) => (event) => {
            this.onRequestSort(event, property);
        };

        const headlines = [
            { id: 'name', label: 'Produktname', sublabel: 'ISIN/WKN' },
            { id: 'ms_category', label: 'Morningstar-Kategorie'},
            { id: '1m_sort_key', label: 'Vormonat'},
            { id: 'ytd_sort_key', label: 'Laufendes Jahr'},
            { id: '1y_sort_key', label: '1 Jahr'},
            { id: '3y_sort_key', label: '3 Jahre'},
            { id: '5y_sort_key', label: '5 Jahre'},
        ];

        return <tr>
            {headlines.map((headline) => (
                <TableCell
                    key={headline.id}
                    align={headline.id === 'name' || headline.id === 'ms_category' ? 'left' : 'center'}
                    sortDirection={this.state.orderBy === headline.id ? this.state.order : false}
                >
                    <TableSortLabel
                      active={this.state.orderBy === headline.id}
                      direction={this.state.orderBy === headline.id ? this.state.order : 'asc'}
                      onClick={createSortHandler(headline.id)}
                    >
                        <div>
                            <div>{headline.label}</div>
                            {headline.sublabel && <div className="sub">{headline.sublabel}</div>}
                        </div>
                    </TableSortLabel>
                </TableCell>
            ))}
        </tr>
    }

    renderTable() {
        const { classes, order, orderBy } = this.props;

        return (
          <div style={{overflowX: 'auto'}}>
            <table className={classes.tableAll} aria-label="simple table">
              <thead className={classes.tableHeader}>
                {this.renderTableHeader()}
              </thead>
              <tbody>
                {this.renderTableRows()}
              </tbody>
            </table>
          </div>
        )
      }

    renderErrorMessage(error) {
      return <ErrorMessage error={error} withPadding />
    }

    render() {

      const { classes } = this.props;

      return (
        <div
          className={clsx(classes.container, this.props.error ? classes.containerError : this.props.data ? '' : classes.containerError)}>
          {this.props.error ? this.renderErrorMessage(this.props.error) : !_.isEmpty(this.props.data) ? this.renderTable() : this.renderErrorMessage(DEFAULT_EMPTY_SECTION_MESSAGE)}
          {rankingDataMissing(this.props.data) && (
            <div style={{marginTop: '1.5em', paddingLeft: SECTION_PADDING, paddingBottom: 10}}>
              *Für dieses Instrument steht diese Statistik nicht zu Verfügung.
            </div>
          )}
        </div>
      )
    }
}

RankingPerformanceTable.defaultProps = {
  withHeader: true,
}

export default withStyles(styles, { withTheme: true })(RankingPerformanceTable)