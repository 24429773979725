import React from "react";

import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";

import {withEuroOrDash} from "../../../../../../../../../utils/utils";
import AssetsQuestion, {AssetsQuestionMethods} from "../../../../question/AssetsQuestion/AssetsQuestion";
import {getQuestionType, getUID} from "../../../../../../../utils";
import {
  ASSET_DATA_UID,
  LIABILITY_CHECKBOX_UID,
  LIABILITY_DATA_UID,
  QUESTION_TYPE
} from "../../../../../../../constants";
import InputQuestion from "../../../../question/InputQuestion";
import CheckboxQuestion from "../../../../question/CheckboxQuestion";
import StepName from "../../../DynamicStep/components/StepName";

import styles from "./styles";
import FormHelperText from "@material-ui/core/FormHelperText";

const MainStep = props => {

  const {
    classes,
    help_text,
    uid,
    name,
    questions,
    onAddClick,
    onEditClick,
    onDeleteClick,
    onAnswerChange,
    dataService,
  } = props;

  const calcTotal = (question) => {
    return question && !question.disabled && question.answer && question.answer.reduce((sum, item) => {
      return sum + Number(item.value);
    }, 0) || 0;
  };

  const assetsQuestion = questions.find(q => getUID(q.uid) === ASSET_DATA_UID);
  const liabilityQuestion = questions.find(q => getUID(q.uid) === LIABILITY_DATA_UID);
  const liabilityCheckbox = questions.find(q => getUID(q.uid) === LIABILITY_CHECKBOX_UID);

  // set initial flags for liability if it's optional
  if(liabilityQuestion && liabilityCheckbox
    && !liabilityQuestion.hasOwnProperty('disabled') && liabilityQuestion.optional){
    liabilityQuestion.disabled = liabilityQuestion.optional = liabilityCheckbox.answer || false;
    liabilityCheckbox.customLogic = true;
  }

  const totalQuestion = questions.find((question) => {
    return getQuestionType(question, false) === QUESTION_TYPE.HIDDEN;
  });

  const actives = calcTotal(assetsQuestion || dataService.getQuestion('A7', 'M1'));
  const passives = calcTotal(liabilityQuestion || dataService.getQuestion('A71', 'N1'));
  const total = actives - passives;

  const handleCheckboxChange = (uid, newVal) => {
    liabilityQuestion.disabled = liabilityQuestion.optional = newVal;
    onAnswerChange(uid, newVal);
  };

  if(totalQuestion) totalQuestion.answer = total;

  const error = assetsQuestion && assetsQuestion.error || (liabilityQuestion && liabilityQuestion.error);

  return (
    <>
      <Grid container className={classes.total} spacing={2}>
        <Grid item xs={12}>
          <div className={classes.stepName} data-id={`${uid}-name`}>
            <StepName name={name}/>
          </div>
          {withEuroOrDash(total)}
        </Grid>

        <Grid item xs={12}>
          {help_text && (
            <div className={classes.stepHelpText} data-id={`${uid}-help`}>
              {help_text}
            </div>
          )}
          { error && (
            <FormHelperText error>{ error }</FormHelperText>
          )}
        </Grid>
      </Grid>

      <Grid container className={classes.questionsContent} spacing={2}>
        {assetsQuestion &&
          <Grid item xs={6} key={assetsQuestion.uid}>
            <AssetsQuestion
              question={assetsQuestion}
              onAddClick={onAddClick}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
          </Grid>
        }

        {liabilityCheckbox &&
          <Grid item xs={12} key={liabilityCheckbox.uid}>
            <CheckboxQuestion
              disabled={liabilityQuestion.answer && liabilityQuestion.answer.length > 0}
              question={liabilityCheckbox}
              onAnswerChange={liabilityCheckbox.customLogic ? handleCheckboxChange : onAnswerChange}
            />
          </Grid>
        }

        {liabilityQuestion &&
          <Grid item xs={6} key={liabilityQuestion.uid}>
            <AssetsQuestion
              question={liabilityQuestion}
              onAddClick={onAddClick}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
          </Grid>
        }
        {totalQuestion && <InputQuestion question={totalQuestion}/>}
      </Grid>
    </>
  )
}

export default withStyles(styles)(MainStep);