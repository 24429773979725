import React from "react";
import moment from "moment";
import _ from "lodash";

import Grid from "@material-ui/core/Grid";

import DateQuestion from "../../../../question/DateQuestion/DateQuestion";
import TimeQuestion from "../../../../question/TimeQuestion/TimeQuestion";
import RadioQuestion from "../../../../question/RadioQuestion";
import InputQuestion from "../../../../question/InputQuestion";
import {INTERVIEW_PLACE} from "../../../../../../../mock_finalize";

const ProtocolConversationDateAndPlace = (props) => {
  const {
    onAnswerChange,
    dataService,
    interviewDate,
    interviewStartTime,
    interviewEndTime,
    interviewPlace,
    interviewPlaceBrokerInput,
    interviewPlaceAccountHolder1Input,
    interviewPlaceAccountHolder2Input,
    interviewPlaceCustomerInput
  } = props;

  const handleStartTimeChange = (uid, answer) => {
    if(!interviewEndTime.answer && moment.isMoment(answer) && answer.isValid()){
      interviewEndTime.answer = moment(answer).add(1, 'hour');
      interviewEndTime.error = null; // clean error
    }
    onAnswerChange(uid, answer);
  };

  const holdersInterviewPlaces = [interviewPlaceAccountHolder1Input, interviewPlaceAccountHolder2Input].filter(q => !q.hidden);

  const handleInterviewPlaceAnswerChange = (question, answer) => {
    const updateInterviewPlaceQuestions = (interviewPlace) => {
      [
        interviewPlaceBrokerInput,
        interviewPlaceCustomerInput,
        interviewPlaceAccountHolder1Input,
        interviewPlaceAccountHolder2Input
      ].forEach((q) => {
        q.error = null;
        q.answer = _.isNil(interviewPlace) ? q.defaultAnswer : interviewPlace;
      })
    };

    let interviewPlace = {
      [INTERVIEW_PLACE.BROKER]: interviewPlaceBrokerInput.defaultAnswer || '',
      [INTERVIEW_PLACE.CUSTOMER]: (dataService.useMembers && !_.isEmpty(dataService.members)
        ? interviewPlaceAccountHolder1Input.defaultAnswer // Use address of first account holder
        : interviewPlaceCustomerInput.defaultAnswer) || '',
      [INTERVIEW_PLACE.OTHER]: '', // Empty in case other option selected
      [INTERVIEW_PLACE.ONLINE]: undefined // Each participant use own address
    }[+answer];

    updateInterviewPlaceQuestions(interviewPlace);
    onAnswerChange(question, answer);
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <DateQuestion
              question={interviewDate}
              onAnswerChange={onAnswerChange}
            />
          </Grid>
          <Grid item lg={2}>
            <TimeQuestion
              question={interviewStartTime}
              onAnswerChange={handleStartTimeChange}
            />
          </Grid>
          <Grid item lg={2}>
            <TimeQuestion
              question={interviewEndTime}
              onAnswerChange={onAnswerChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <RadioQuestion
              question={interviewPlace}
              onAnswerChange={handleInterviewPlaceAnswerChange}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <InputQuestion
              question={interviewPlaceBrokerInput}
              onAnswerChange={onAnswerChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container direction={'column'}>
              {dataService.useMembers ?
                holdersInterviewPlaces.map(q => (
                  <Grid item xs={12} md={4} lg={4} key={q.uid}>
                    <InputQuestion
                      question={q}
                      onAnswerChange={onAnswerChange}
                    />
                  </Grid>
                )) :
                <Grid item xs={12} md={4} lg={4}>
                  <InputQuestion
                    question={interviewPlaceCustomerInput}
                    onAnswerChange={onAnswerChange}
                  />
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
};

export default ProtocolConversationDateAndPlace;