import CommonAnalysisCard from "../../../../components/AnalysisCard";
import React from "react";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import {formatPercentage} from "../../index";

import PortfolioBreakdownChart from "../../components/PortfolioBreakdownChart";
import {PortfolioUtils} from "../../../../../../utils/utils";
import EmptyCard from "../../../../components/EmtyCard";
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../../../../../utils/constants";


class ChartFixedIncomeSubTypeBreakdown extends React.Component {

  renderChart() {
    const { classes } = this.props;

    let data = PortfolioUtils.getPortfolioFixedIncomeSubType(this.props.data.portfolio_structure)
    let series = []

    if (data) {
      data.forEach((ac) => {
        series.push({
          name: `<div class="legend-custom"><span>${ac.name}</span><span>${formatPercentage(ac.weight, 100)}</span></div>`,
          data: [ac.weight]
        })
      })

      return <PortfolioBreakdownChart series={series}></PortfolioBreakdownChart>

    } else {

      return <div>{DEFAULT_EMPTY_SECTION_MESSAGE}</div>
    }
  }

  render() {
    const { classes } = this.props;

    // chart render triggered only when response come back
    if (!this.props.error && this.props.data.portfolio_structure.by_fixed_income_sub_type.length > 0) {
      return <CommonAnalysisCard
        classes={{
          card: classes.root
        }}
        title={'Emittentenstruktur'}
        loading={this.props.loading}
        content={
          <div className={classes.container}>
            {this.props.error ? this.props.error : this.props.data ? this.renderChart() : DEFAULT_EMPTY_SECTION_MESSAGE}
          </div>
        }
      />
    } else {

      return <EmptyCard
        classes={{
          card: classes.root
        }}
        title={'Restlaufzeiten'}
      />
    }
  }
}

export default withStyles(styles)(ChartFixedIncomeSubTypeBreakdown)
