import React from "react";

const DisclaimerTextV2 =  () => {
  return <>
    <b>Risikohinweis zur Berücksichtigung Ihrer Nachhaltigkeitspräferenzen</b><br/>
    Sie sind nicht verpflichtet, sich für eine der dargestellten Nachhaltigkeitspräferenzen zu entscheiden.
    Wir als Anbieter sind bemüht, Ihnen ein Angebot unter Berücksichtigung von Nachhaltigkeitspräferenzen unterbreiten
    zu können. Bitte berücksichtigen Sie dabei, dass wir zum gegenwärtigen Zeitpunkt noch nicht auf eine ausreichende
    Datengrundlage von Unternehmen zurückgreifen können. Erst in Zukunft werden Unternehmen verpflichtet werden,
    detaillierte Angaben zum Anteil ihrer Investitionen und ihrer Umsätze in wirtschaftlichen Tätigkeiten, die z.B.
    der Taxonomieverordnung entsprechen, in ihrer nicht-finanziellen Berichterstattung zu machen. Diese Verpflichtungen
    werden nur größere Unternehmen betreffen, nicht aber kleinere und mittlere Unternehmen. Letztere werden
    voraussichtlich lediglich zu einer eingeschränkten Berichterstattung verpflichtet. Das Gleiche gilt für staatliche
    oder supranationale Emittenten. <b>Wir können daher keine Gewährleistung für die Erreichung Ihrer Nachhaltigkeitsziele übernehmen.</b>
  </>
};

export default DisclaimerTextV2;