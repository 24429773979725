import React, {Component} from 'react'
import {connect} from 'react-redux'

import {CircularProgress, Container, Grid, withStyles} from '@material-ui/core'
import {Skeleton} from '@material-ui/lab';

import Link from '../../../components/Link';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import {DownloadIcon} from '../../../images';
import {DeleteModelPortfolioModal, ModelPortfolioModal} from '../components'

import {styles} from './styles'
import {executeIfPathExist, getInvestmentDynamicPath} from '../../InvestmentPlatform/utils'
import {ModelPortfolioResource, parseResponse, VirtualPortfolioHandlerResource} from '../../../utils/api'
import {displayErrorSnackBar, displaySuccessSnackBar} from '../../../components/SnackbarProvider/actions';
import {MODEL_PORTFOLIO_DELETE_ERROR_MESSAGE, MODEL_PORTFOLIO_DELETE_SUCCESS_MESSAGE} from '../constants'
import {
  buildDeleteOrderConfirmationMessage,
  buildModelPortfoliosTransactionsFromAssets,
  getDummyPortfolioTransactions,
  modelPortfolioAccessible
} from '../utils'
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {ModelPortfolioDetails} from "./components";
import {
  musterPortfolioInstrumentsTableStructure,
  StartPortfolioBuilderButton
} from '../components/InstrumentsList/table-data';
import {
  getBaseReportGenerationSettings,
  getErrorMessage,
  VIRTUAL_PORTFOLIO_QTY_DECIMALS
} from "../../../utils/utils";
import _ from 'lodash';
import withModelPortfolioDashboardData from "../../../components/ModelPortfolioDashboardDataProvider";
import InvestmentDetailsNavigation from "../../CustomerDashboard/components/InvestmentDetailsNavigation";
import BenchmarkWithConfiguration
  from "../../CustomerDashboard/components/Widgets/components/PerformanceLineChart/benchmark";
import withNotification from "../../../components/NotificationProvider";
import BenchmarkModal from "../../CustomerDashboard/components/InvestmentDetailsNavigation/BenchmarkSettingsModal";
import InstrumentsTab from "./components/ExtendedTabs/Instruments";
import RiskAnalysisTab from "../../ProfessionalView/Tabs/RiskAnalysisTab";
import {default as ProPerformanceTab} from "../../ProfessionalView/Tabs/PerformanceTab";
import {default as ProPortfolioStructureTab} from "../../ProfessionalView/Tabs/PortfolioStructureTab";
import {default as ProKeyFiguresTab} from "../../ProfessionalView/Tabs/KeyFiguresTab";
import {handleModelPortfolioCreateClick} from "../Create/Create";
import FixModelPortfolioModal from "../components/FixModelPortfolioModal/FixModelPortfolioModal";
import VirtualOrderModal from "../../VirtualPortfolioManager/modals/VirtualOrderModal";
import moment from "moment";
import {
  TRADING_ACTION_BUY,
  TRADING_ACTION_SELL,
  TRADING_ACTION_SWITCH
} from "../../../components/Charts/InstrumentsAllocationTable/constants";
import {
  SelectableInstrumentsTable
} from "../../../components/SelectForProductsComparisonModal/SelectForProductsComparisonModal";
import {TRANSACTION_INSTR, TRANSACTION_TYPE_VALUES} from "../../Trades/constants";
import {
  buildTransactionDateSensitiveData
} from "../../Trades/components/TradeStep/components/PortfolioTrade/PortfolioTrade";
import {ORDER_TYPES} from "../../VirtualPortfolioManager/constants";
import {
  calculateSwitchTransactionSaldo,
  mergeSwitchItems,
  setEarliestPriceDates,
  transactionValueToPercentage,
  transactionValueToQuantity
} from "../../Trades/utils";
import {
  ConfirmationModalContext
} from "../../../components/ConfirmationModalContextProvider/ConfirmationModalContextProvider";

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS(),
  auth: state.get('auth').toJS(),
  benchmarkConfiguration: state.get('benchmarkConfiguration').toJS(),
});

export const defaultExpandedItems = {
  historicalChart: true,
  performanceLineChart: true,
  performanceBarChart: true,
  unrealizedProfitAndLossItems: [],
  profitAndLossItems: [],
  profitAndLossSubItems: {},
  paymentPlansItems: [],
  payoutPlansItems: [],
  switchPlansItems: [],
  instrumentsSubItems: {},
  instrumentsItems: [],
  cumulativeReturnChart: true,

  //to include charts from pro view
  forecastChart: true,
  performanceTable: true,
  singlePerformance: true,

  // charts KeyFiguresTab
  keyIndicatorsChart: true,
  rollingVolatilityChart: true,
  rollingSharpeRatioChart: true,
  esgScoreChart: true,
  // charts from RiskAnalysisTab
  riskReturnChart: true,
  stressTestChart: true,
  correlationMatrix: true
};

class Overview extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedTabId: 0,
      modelPortfolio: {
        data: null,
        loading: true,
        errors: null,
        date: Date.now()
      },
      deleteModelPortfolioModal: {
        open: false
      },
      investmentDynamicPath: getInvestmentDynamicPath(),
      pdfDownloading: {
        loading: false,
        errors: null
      },
      expandedItems: {...defaultExpandedItems, transactionsOverview: true},

      // data for mp save modal
      modelPortfolioModalVisible: false,
      fixModelPortfolioModalVisible: false,
      modelPortfolioCreationStatus: {
        loading: false,
        errors: null
      },
      orderModalOpened: false,
      // Used to notify child components when data should be reloaded
      refreshDataTrigger: undefined,
      fillEmptyMPWithMissingData: false
    };

    this.tabs = [
      { name: 'Instrumente', component: InstrumentsTab },
      { id: 'performance_tab', name: 'Wertentwicklung', component: ProPerformanceTab },
      { id: 'portfolio_structure', name: 'Portfoliostruktur', component: ProPortfolioStructureTab },
      { id: 'key_figures', name: 'Kennzahlen', component: ProKeyFiguresTab },
      { id: 'risk_analysis', name: 'Risikoanalyse', component: RiskAnalysisTab },
    ];

    this.renderBenchmarkSwitch = this.renderBenchmarkSwitch.bind(this);
    this.renderTabsCommonSection = this.renderTabsCommonSection.bind(this);
    this.onChartSettingsChange = this.onChartSettingsChange.bind(this);
    this.postVirtualOrdersCreated = this.postVirtualOrdersCreated.bind(this)
  }

  static contextType = ConfirmationModalContext;

  componentDidMount() {
    this.fetchModelPortfolio()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.portfolioId != this.props.portfolioId && !this.props.portfolioId) {
      this.setState({
        selectedTabId: 0 // Workaround to reset selected tab in case portfolio does not have active virtual portfolio
      })
    }

    if (!this.state.modelPortfolio.loading && !!this.state.modelPortfolio.data &&  !this.state.fillEmptyMPWithMissingData &&
      !this.props.instrumentList.loading && _.isArray(this.props.instrumentList.data) && _.isEmpty(this.props.instrumentList.data)
    ) {
      this.setState({fillEmptyMPWithMissingData: true});
      this.fillEmptyMpWithData();
    }

    // region BCA-7408 Remove later
    if (prevState.modelPortfolio.data != this.state.modelPortfolio.data && !!this.state.modelPortfolio.data) {
      const connectPortfolioId = _.get(this.state.modelPortfolio.data, 'connect_portfolio_id');
      if (!connectPortfolioId || _.isEmpty(connectPortfolioId)) {
        this.setState({fixModelPortfolioModalVisible: true})
      }
    }
    // endregion
  }

  handleExpandedItemsChange = (field, data) => {
    let oldExpandedItems = _.cloneDeep(this.state.expandedItems);
    oldExpandedItems[field] = data;
    this.setState({expandedItems: oldExpandedItems})
  };

  handleChange = (event, newValue) => {
    if (newValue !== undefined) {
      this.setState({
        selectedTabId: newValue
      })
    }
  };

  fetchModelPortfolio = async () => {
    try {
      let response;
      if (this.props.portfolio){
        response = this.props.portfolio;
      } else {
        let id = this.props.computedMatch.params.id;
        response = await ModelPortfolioResource.getModelPortfolioDetails(id)
      }

      const benchmarksExists = _.isArray(response.benchmarks) && !_.isEmpty(response.benchmarks);
      let preSelectedBenchmarks = [];
      this.setState({
        modelPortfolio: {
          data: response,
          loading: false,
          errors: null,
          date: Date.now()
        }
      })

      if(this.props.handleChartSettingsChange && benchmarksExists) {
          preSelectedBenchmarks = response.benchmarks.map(benchmark_data => {
          let percentage = parseInt(benchmark_data.percentage);
          return {
            ...benchmark_data,
            percentage: percentage,
            weight: percentage / 100,
          }
        });

      }

      this.onChartSettingsChange('performance', 'currently_selected_benchmarks', preSelectedBenchmarks || []);
      this.onChartSettingsChange('performance', 'withBenchmark', benchmarksExists);

    } catch (errors) {
      this.setState({
        modelPortfolio: {
          data: null,
          loading: false,
          errors,
          date: Date.now()
        }
      })
    }
  };

  renderBackLink = () => {

    return executeIfPathExist(this.props.investmentPlatform.routes, 'MODELPORTFOLIO_LIST', (path) => (
      <Link 
        title="Zurück zu Musterdepots"
        icon={<i className="fa fa-chevron-left" aria-hidden="true" />}
        underline
        onClick={() => this.props.history.push(`/${this.state.investmentDynamicPath}${path}`)}
      />
    ))

  };

  getAssetsDataSource = () => {
    if (this.state.modelPortfolio.loading) {
      return [{}, {}, {}, {}, {}, {}]
    }

    if (this.state.modelPortfolio.data && this.state.modelPortfolio.data.assets) {
      return this.state.modelPortfolio.data.assets;
    }

    return []
  };

  handleDeleteButtonClick = () => {
    this.setState({
      deleteModelPortfolioModal: {
        open: true
      }
    })
  };

  handleDeleteModalClose = () => {
    this.setState({
      deleteModelPortfolioModal: {
        open: false
      }
    })
  };

  handleEditButtonClick = () => {
    this.setState({modelPortfolioModalVisible: true})
  };

  handleDeleteModelPortfolio = async () => {
    try {
      let id = this.props.computedMatch.params.id;

      await ModelPortfolioResource.deleteModelPortfolio(id)

      executeIfPathExist(this.props.investmentPlatform.routes, 'MODELPORTFOLIO_LIST', (path) => (
        this.props.history.push(`/${this.state.investmentDynamicPath}${path}`)
      ));

      this.props.dispatch(displaySuccessSnackBar(MODEL_PORTFOLIO_DELETE_SUCCESS_MESSAGE))

    } catch (errors) {
      this.props.dispatch(displayErrorSnackBar(MODEL_PORTFOLIO_DELETE_ERROR_MESSAGE))
    }
  };

  renderNotFound = () => {
    const { classes } = this.props;

    return (
      <div className={classes.errorContainer}>
        <h1>404</h1>
        <p>Not found</p>
      </div>
    )
  };

  getAvailableAssets = () => {
    let assets = [].concat(...this.getAssetsDataSource());
    return assets.filter(asset => !asset.disabledByFilter);
  };

  handleAllSelected = () => {
    if (this.props.onSelectAll){
      const selected = this.props.selectedInstruments || [];
      let assets = this.getAvailableAssets();
      if (selected.length === assets.length) {
        assets = []; // unselect all
      }

      this.props.onSelectAll(assets)
    }
  };

  handleDownloadClick = async () => {

    try {
      this.setState({
        pdfDownloading: {
          loading: true
        }
      });

      const formData = getBaseReportGenerationSettings(
        [], this.props.selectedDates, undefined, this.state.expandedItems);
      formData.append('charts_settings', JSON.stringify(this.props.chartsSettings));

      await ModelPortfolioResource.at(ModelPortfolioResource.GET_MODEL_PORTFOLIO_PDF.replace(':id', this.state.modelPortfolio.data.id)).post(formData)
    } catch (errors) {
      this.props.dispatch(displayErrorSnackBar('Fehler beim Herunterladen des Dokuments.'))
    } finally {
      this.setState({
        pdfDownloading: false
      })
    }
  };

  a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  renderTabsCaptions() {
    const { classes } = this.props;

    return this.tabs.map((tab, index) => (
      <Tab label={tab.name} {...this.a11yProps(index)}
        className={classes.tabTitle}
        classes={{ selected: classes.selected }}
        disableRipple={true} key={'tab' + index}
        disabled={!this.props.portfolioId}
      />
    ))
  }

  postVirtualOrdersCreated(){

    this.fetchModelPortfolio();

    this.props.fetchData();

    this.setState({refreshDataTrigger: +new Date()})
  }

  renderTabsPanels() {
    const { classes } = this.props;

    return this.tabs.map((tab, index) => (
      <TabPanel value={this.state.selectedTabId} index={index} className={classes.tabContent} key={'tab' + index}>
        <tab.component
          modelPortfolio={this.state.modelPortfolio}
          portfolioName={_.get(this.state.modelPortfolio, 'data.name')}
          passedClasses={classes}
          regionHeight={300}

          expandedItems={this.state.expandedItems}
          onExpandedItemsChange={this.handleExpandedItemsChange}

          postVirtualOrdersCreated={this.postVirtualOrdersCreated}
          handleEditOrderClick={this.handleEditOrderClick}
          handleDeleteOrderClick={this.handleDeleteOrderClick}
          useUpdate={this.state.fillEmptyMPWithMissingData}
          handleEmptyMpUpdated={() => this.setState({fillEmptyMPWithMissingData: false})}
          {...this.props}
          viewOnly={this.props.viewOnly || !modelPortfolioAccessible(this.state.modelPortfolio.data, this.props.auth)}
          withTransactionsOverview
          refreshDataTrigger={this.state.refreshDataTrigger}
        />
      </TabPanel>
    ));
  }

  onChartSettingsChange(chart, field, value){
    if(this.props.handleChartSettingsChange) {
      this.props.handleChartSettingsChange(chart, field, value)
    }
  }

  renderBenchmarkSwitch = () => {
    // Function to render Benchmark switch with configuration

    return (
      <Grid item style={{marginLeft: 10}}>
        <BenchmarkWithConfiguration
          portfolio={this.state.modelPortfolio.data}
          selectedBenchmarks={this.props.chartsSettings.performance.currently_selected_benchmarks}

          benchmarkConfigurationEnabled
          // todo when benchmarks are implemented for charts add requiredDataExist() like for pro view
          isBenchmarkConfigurationEnabled={true}
          requiredDataExist={true}
          showInvestment={this.props.chartsSettings.performance.withBenchmark}

          dispatch={this.props.dispatch}
          onChartSettingsChange={this.onChartSettingsChange}
          // updateBenchmarkSwitchState={updateBenchmarkSwitchState}
        />
      </Grid>
    )
  };


  renderTabsCommonSection = () => {
    // Function to render 'header of the page' that is present for evry tab

    const { classes, viewOnly } = this.props;

    return (
      <>
        { !viewOnly && this.renderBackLink() }
        <div className={classes.navigationContainer} style={{marginBottom: 10}}>
          {this.state.modelPortfolio.loading ? (
            <>
              <Skeleton style={{width: 300, height: 40}}/>
              <Skeleton style={{width: 250, height: 40}}/>
            </>
          ) : (
            <>
              {this.state.modelPortfolio.data && (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={true}>
                    <h1 className={classes.header}>{ this.state.modelPortfolio.data.name }</h1>
                  </Grid>
                  <Grid item xs={12} sm={'auto'}>
                    <StartPortfolioBuilderButton item={this.state.modelPortfolio.data} onlyIcon={false} />
                  </Grid>
                  <Grid item xs={12} sm={'auto'}>
                    <PrimaryButton
                      text="Portfolio als PDF herunterladen"
                      icon={<DownloadIcon color="white"/>}
                      onButtonClick={this.handleDownloadClick}
                      disabled={this.state.pdfDownloading.loading}
                      style={{width: '100%'}}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </div>
        <Grid item xs={12}>
          <ModelPortfolioDetails
            loading={this.state.modelPortfolio.loading}
            modelPortfolio={this.state.modelPortfolio.data}

            investmentData={this.props.investmentData.data}
            investmentDataLoading={this.props.investmentData.loading}
            investmentDataLoadingError={this.props.investmentData.errors}
            sectionHeader={'Depotwert'}
            sectionSubheader={''}

            viewOnly={this.props.viewOnly || !this.state.modelPortfolio.data || !modelPortfolioAccessible(this.state.modelPortfolio.data, this.props.auth)}
            onEditButtonClick={this.handleEditButtonClick}
            onDeleteButtonClick={this.handleDeleteButtonClick}
          />
        </Grid>
        <Grid item xs={12} style={{marginTop: 15}}>
          <Grid container style={{marginBottom: 20}} id="sticky-trigger">
            <Grid item className={classes.investmentNavigationContainer} xs={12}>
              <InvestmentDetailsNavigation
                // options for time selection
                calculationDates={this.props.selectedDates}
                calculationDatesType={this.props.selectedDatesType}
                handleCalculationDatesChanged={this.props.onSelectedDatesChanged}
                investmentData={this.props.investmentData.data}

                // flags for components rendering
                dataLoading={this.props.dataLoading}
                // func to render benchmark component
                renderCustomSwitch={() => !_.isNil(this.state.modelPortfolio.data)
                  ? this.renderBenchmarkSwitch()
                  : false
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  };

  updateState = (newState) => {
    this.setState(newState)
  };
  onEditSuccess = (data) => {
    this.setState({
      modelPortfolioCreationStatus: data.modelPortfolioCreationStatus,
      modelPortfolioModalVisible: false
    });

    this.props.dispatch(displaySuccessSnackBar(data.successModelPortfolioCreationModal.message))
  };

  fillEmptyMpWithData = (fixMP=false) => {
    const selectedAssets = _.get(this.state.modelPortfolio, 'data.assets') || [];
    const depositFromDate = moment(_.get(this.state.modelPortfolio, 'data.tracking_start_date'), 'YYYY-MM-DD');

    let updatedTransactions = getDummyPortfolioTransactions(this.state.modelPortfolio.data)
    updatedTransactions.transactions.buy = buildModelPortfoliosTransactionsFromAssets(selectedAssets, depositFromDate);

    if (fixMP) {
      VirtualPortfolioHandlerResource.getInstrumentPrice(selectedAssets.map((asset) => asset.isin), depositFromDate.format('YYYY-MM-DD'), true)
        .then((response) => {
          parseResponse(response, 'instrument_price',
            (data) => {
              updatedTransactions.transactions.buy.forEach((transaction) => {
                transaction.data.price_eur = _.get(data, `${transaction.data.isin}.price_eur`)
              })
            },
            (error) => {
              updatedTransactions.transactions.buy.forEach((transaction) => {
                transaction.data.price_eur = undefined
              })
            }
          )
        })
        .catch((error) => {
          this.props.dispatch(displayErrorSnackBar(getErrorMessage(error)))
        });
    }

    this.props.setPortfoliosTransactions([updatedTransactions]);
  };

  // region BCA-7408 Remove later
  handleFixModelPortfolioClick = async () => {
    this.setState({
      orderModalOpened: true,
      fixModelPortfolioModalVisible: false
    });

    this.fillEmptyMpWithData(true);
  };
  // endregion

  getInstrumentForOrder = (isin) => {

    const getInstrument = (components) => {
      return _.find(components, (component) => component.isin == isin);
    }

    let instrument = getInstrument(_.get(this.props, 'instrumentList.data.0.components') || []);
    if (!instrument) {
      instrument = getInstrument(_.get(this.props, 'profitAndLoss.data.0.components') || []);
    }

    return instrument;

  };

  initBuyTransactionWithPrice = async (transactionBody) => {

    const transactions = _.isArray(transactionBody) ? transactionBody : [transactionBody];

    const prices = await VirtualPortfolioHandlerResource.getInstrumentPrice(
      transactions.map((t) => t.data.isin), transactions[0].deposit_from_date.format('YYYY-MM-DD'), true);

    parseResponse(prices, 'instrument_price',
      (data) => {
        transactions.forEach((t) => {
          t.data.price_eur = _.get(data, `${t.data.isin}.price_eur`);
        })
      },
      (error) => {
        transactions.forEach((t) => {
          t.data.price_eur = undefined;
        })
      }
    );
  };

  initSellTransactionWithPrice = async (transactionBody) => {

    const instrumentDataForDate = await ModelPortfolioResource.getInstrumentDataForDate(
      _.get(this.state.modelPortfolio, 'data.id'),
      transactionBody.data.id, transactionBody.deposit_from_date.format('YYYY-MM-DD'));

    parseResponse(instrumentDataForDate, 'asset', (asset_data) => {
      const _asset = asset_data[transactionBody.data.id].history[0];
      transactionBody.data = {
        ...transactionBody.data,
        ...buildTransactionDateSensitiveData(transactionBody, _asset)
      };

    })
  };

  _getTransactionTypeValueFromOrder = (order) => {
    if ([ORDER_TYPES.BUY_VALUE, ORDER_TYPES.BUY_QUANTITY].includes(order.type)) return TRANSACTION_TYPE_VALUES.BUY;
    if ([ORDER_TYPES.SELL_VALUE, ORDER_TYPES.SELL_QUANTITY].includes(order.type)) return TRANSACTION_TYPE_VALUES.SELL;
    if ([ORDER_TYPES.SWITCH_OUT, ORDER_TYPES.SWITCH_IN].includes(order.type)) return TRANSACTION_TYPE_VALUES.SWITCH;
    throw Error('Nicht unterstützter Transaktionstyp.')
  };

  _buildTransactionBody = (order) => {

    const isin = _.get(order, 'asset_details.financial_information_isin');
    const asset = this.getInstrumentForOrder(isin)
    if (!asset) {
      throw Error(`Das Produkt ${isin} kann nicht gefunden werden.`);
    }

    // Prepare original data to use it later in price data retrieving in trading components
    const transactionOriginalData = {
      executionUnitPrice: parseFloat(order.execution_unit_price || 0),
      executionQuantity: parseFloat(order.execution_quantity || 0),
      executionValue: Math.abs(parseFloat(order.execution_value || 0)),
      executionDate: moment(order.execution_datetime, 'YYYY-MM-DD'),
      requestedValue: Math.abs(parseFloat(order.requested_value || 0)),
    };

    const result = {
      data: {
        ...asset,
      },
      transaction_id: order.id,
      deposit_from_date: transactionOriginalData.executionDate,
      deposit_from_type: '2', // TRANSACTION_START_DATE.date. Hardcoded value used due to importing issue.
      transaction_value: _.round(transactionOriginalData.requestedValue, 2),
      transaction_type: TRANSACTION_INSTR.amount,
      discount: _.round(parseFloat(_.get(order, 'transaction_order.fee_breakdown.discount') || 0), 2),
      transactionOriginalData
    }

    // Switch orders will have switch_in orders under "components" field
    // so we need to build transactions for them as well.
    if (!_.isEmpty(order.components)) {
      result.buy = [];
      result.sub_transactions = {};
      this._setOrderData(result.sub_transactions, order);
      order.components.forEach((tBuy) => {
        result.buy.push(this._buildTransactionBody(tBuy));
        this._setOrderData(result.sub_transactions, tBuy);
      });
    }

    return result;
  };

  _updateSwitchTransactionsWithCalculatedValues = (transactionBody, parentTransactionBody=undefined) => {

    const itemForCalculation = (parentTransactionBody && mergeSwitchItems(transactionBody, parentTransactionBody)) || transactionBody;

    const transactionValueEuro = _.round(transactionBody.transactionOriginalData[!parentTransactionBody ? 'executionValue' : 'requestedValue'], 2);
    let transactionValue = !parentTransactionBody
      ? transactionBody.transactionOriginalData.executionQuantity // For switch out transactions we could use quantity from transaction details
      : transactionValueToQuantity(transactionValueEuro, itemForCalculation, false);
    transactionValue = transactionValue && _.floor(transactionValue, VIRTUAL_PORTFOLIO_QTY_DECIMALS);
    const transactionValuePercentage = transactionValueToPercentage(
      transactionValueEuro, itemForCalculation);

    transactionBody.transaction_value = transactionValue;
    transactionBody.transaction_type = TRANSACTION_INSTR.qty;
    transactionBody.calculated = {
      transaction_value_euro: transactionValueEuro,
      transaction_value_percentage: transactionValuePercentage,
    };

    if (!_.isEmpty(transactionBody.buy)) {
      transactionBody.buy.forEach((tBuy) => this._updateSwitchTransactionsWithCalculatedValues(tBuy, transactionBody))
    }

  };

  handleEditOrderClick = async (orders) => {

    try {

      const updatedTransactions = getDummyPortfolioTransactions(_.get(this.props, 'instrumentList.data.0'));

      for (let order of orders) {

        const transactionBody = this._buildTransactionBody(order);

        const transactionKey = this._getTransactionTypeValueFromOrder(order);
        transactionBody.tradingType = {
          [TRANSACTION_TYPE_VALUES.SELL]: TRADING_ACTION_SELL,
          [TRANSACTION_TYPE_VALUES.BUY]: TRADING_ACTION_BUY,
          [TRANSACTION_TYPE_VALUES.SWITCH]: TRADING_ACTION_SWITCH
        }[transactionKey];

        if (transactionKey == TRANSACTION_TYPE_VALUES.SELL) {
          await this.initSellTransactionWithPrice(transactionBody)
        } else if (transactionKey == TRANSACTION_TYPE_VALUES.BUY) {
          await this.initBuyTransactionWithPrice(transactionBody);
        } else {
          await Promise.all([
            this.initSellTransactionWithPrice(transactionBody),
            this.initBuyTransactionWithPrice(transactionBody.buy),
            setEarliestPriceDates(transactionBody.buy, () => {})]);
          this._updateSwitchTransactionsWithCalculatedValues(transactionBody);
          const saldo = _.round(calculateSwitchTransactionSaldo(
            transactionBody, VIRTUAL_PORTFOLIO_QTY_DECIMALS), VIRTUAL_PORTFOLIO_QTY_DECIMALS);
          const saldoEur = _.round(transactionValueToQuantity(saldo, transactionBody), 2);
          // In case saldo has euro value (> 0.01 EUR) - do nothing. Saldo should be fixed manually.
          // Otherwise - put saldo to first transaction.
          if (!saldoEur) {
            transactionBody.buy[0].transaction_value = _.round(
              transactionBody.buy[0].transaction_value + saldo, VIRTUAL_PORTFOLIO_QTY_DECIMALS);
          }
        }

        updatedTransactions.transactions[transactionKey].push(transactionBody);
      }

      this.props.setPortfoliosTransactions([updatedTransactions]);
      this.setState({
        orderModalOpened: true,
        orderModalTransactionEditing: true
      })

    } catch (error) {
      this.props.dispatch(displayErrorSnackBar(getErrorMessage(error)))
    }

  };

  _setOrderData = (transactions, _order) => {

    const assetId = _.get(_order, 'asset_details.id');
    if (!transactions.hasOwnProperty(assetId)) {
      transactions[assetId] = [];
    }

    transactions[assetId].push(_order.id);
  };

  handleDeleteOrderClick = async (orders, throwError=false, useConfirmation=false, isAll=false) => {
    try {

      if (useConfirmation && !await this.context.confirm(buildDeleteOrderConfirmationMessage(orders, isAll))) {
        return;
      }

      const transactions = {};

      orders.forEach((order) => {
        this._setOrderData(transactions, order);
        if (!_.isEmpty(order.components)) {
          order.components.forEach((c) => this._setOrderData(transactions, c));
        }
      });

      await ModelPortfolioResource.deleteOrder(this.props.customerId, transactions);
      this.postVirtualOrdersCreated();
      return true;
    } catch (errors) {
      this.props.dispatch(displayErrorSnackBar("Fehler beim Löschen der Transaktion"));
      if (throwError) {
        throw errors
      }
    }
  };

  handleVirtualModalClose = () => {
    this.state.orderModalTransactionEditing && this.props.initPortfoliosTransactions();
    this.setState({
      orderModalOpened: false,
      orderModalTransactionEditing: false
    });
  };


  render() {

    const { classes, viewOnly, isInstrumentsSelection, onSourceSelect, sourceSelected } = this.props;
    // TODO: It is placed there due to some disabledByFilter handling. Need to place it out of component
    if (isInstrumentsSelection) {
      return (
        <Container style={{paddingBottom: 16}}>
          <SelectableInstrumentsTable
            container={this.state.modelPortfolio.data || {}}
            ContainerProps={{
              enabled: true,
              path: 'MODELPORTFOLIO_OVERVIEW',
              selectable: !!onSourceSelect,
              checked: sourceSelected,
              onChange: onSourceSelect,
              classes: {
                containerName: classes.containerName,
                containerCheckbox: classes.containerCheckbox
              }
            }}
            instruments={this.getAssetsDataSource()}
            InstrumentsProps={{
              enabled: true,
              selected: this.props.selectedInstruments || [],
              handleSelectAll: !!this.props.onSelectAll ? this.handleAllSelected : null,
              handleSelect: this.props.onInstrumentSelect,
              tableStructure: musterPortfolioInstrumentsTableStructure,
              tableClasses: classes
            }}
          />
        </Container>
      )
    }

    const fixMPPossible = this.state.modelPortfolio.data && !viewOnly &&
      modelPortfolioAccessible(this.state.modelPortfolio.data, this.props.auth);

    return (
      <>
        <Container className={`app-page-container`} style={{paddingBottom: 16}}>
          {this.renderTabsCommonSection()}
        </Container>
        <div className={classes.whiteBox}>
        <Container className={`app-page-container`}>
          {!this.state.modelPortfolio.loading && !this.state.modelPortfolio.errors && this.state.modelPortfolio.data ? (
            <>
            <AppBar position="static" className={classes.tabsHeader}>
              <Grid container>
                <Tabs value={this.state.selectedTabId} onChange={this.handleChange} aria-label="simple tabs example"
                      classes={{indicator: classes.indicator}} variant="scrollable"
                >
                  {this.renderTabsCaptions()}
                </Tabs>
              </Grid>
            </AppBar>
            {this.renderTabsPanels()}
            </>
          ) : (
            <>
            {this.state.modelPortfolio.errors ? (
              <>
                {this.state.modelPortfolio.errors.status && this.state.modelPortfolio.errors.status == 404 && (
                  <>
                    { this.renderNotFound() }
                  </>
                )}
              </>
            ) : 
             <div style={{
              display: 'flex',
              minHeight: '100vh',
              alignItems: 'center',
              justifyContent: 'center'
             }}>
              <CircularProgress />
             </div>
            }
            </>
            )
          }
          </Container>    
        </div>
        {!viewOnly && (
          <DeleteModelPortfolioModal
            open={this.state.deleteModelPortfolioModal.open}
            modelPortfolioName={this.state.modelPortfolio.data && this.state.modelPortfolio.data.name}
            onClose={this.handleDeleteModalClose}
            onAccept={this.handleDeleteModelPortfolio}
          />
        )}

        {this.props.benchmarkConfiguration && this.props.benchmarkConfiguration.modal_opened && (
          <BenchmarkModal
            benchmarks={this.props.chartsSettings.performance.currently_selected_benchmarks}
            selectedPortfolio={this.state.modelPortfolio.data}
            managerResource={ModelPortfolioResource}
            customerId={this.state.modelPortfolio.data.id}

            // todo when more charts for the tabs are implemented use it
            // updateRiskMetricsBenchmark={updateRiskMetricsBenchmark}
            updateHistoricalData={this.props.updateHistoricalData}
            onChartSettingsChange={this.onChartSettingsChange}
          />
        )}

        <ModelPortfolioModal
          title={"Basisinformationen bearbeiten"}
          onModelPortfolioCreateClick={(data, update) => handleModelPortfolioCreateClick(
            _.get(this.state.modelPortfolio, 'data.id'), data, update, undefined,
            this.props.chartsSettings.performance.currently_selected_benchmarks, undefined,
            this.updateState, this.props.dispatch, this.state.modelPortfolioCreationStatus, this.onEditSuccess
          )}
          open={this.state.modelPortfolioModalVisible}
          onClose={() => {
            this.setState({modelPortfolioModalVisible: false})
          }}

          defaultStartDate={_.get(this.state.modelPortfolio, 'data.tracking_start_date')}
          loading={this.state.modelPortfolioCreationStatus.loading}
          errors={this.state.modelPortfolioCreationStatus.errors}
          selectedModelPortfolio={this.state.modelPortfolio.data}
          hideAsNew
        />
        {/*region BCA-7408 Remove later*/}
        {fixMPPossible && (
          <>
            <FixModelPortfolioModal
              open={this.state.fixModelPortfolioModalVisible}
              onClose={() => this.setState({fixModelPortfolioModalVisible: false})}
              onEdit={this.handleFixModelPortfolioClick}
              onDelete={() => this.setState({fixModelPortfolioModalVisible: false, deleteModelPortfolioModal: {open: true}})}
            />
          </>
        )}
      {/* endregion */}
        <VirtualOrderModal
          open={this.state.orderModalOpened}
          onClose={this.handleVirtualModalClose}
          onCancelClick={this.state.orderModalTransactionEditing && this.handleDeleteOrderClick}

          portfoliosTransactions={this.props.portfoliosTransactions}
          setPortfoliosTransactions={this.props.setPortfoliosTransactions}
          initPortfoliosTransactions={this.props.initPortfoliosTransactions}

          portfolioOwnerId={_.get(this.state.modelPortfolio, 'data.id')}
          useUpdate={!_.get(this.state.modelPortfolio, 'data.connect_portfolio_id')}
          refresh={this.postVirtualOrdersCreated}
          dispatch={this.props.dispatch}
          resource={ModelPortfolioResource}
          transactionEditing={this.state.orderModalTransactionEditing}
        />
      </>
    )
  }
}

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default connect(mapStateToProps)(withModelPortfolioDashboardData(withNotification(withStyles(styles)(Overview))))
