import moment from 'moment'
import _, { get } from 'lodash'

import { TradeMenuItem } from './TradeMenuItem'
import {aggregatePortfoliosExAnte, getAmount, getBookingDate} from "../utils";
import {BankSettingsHandlerResource} from "../../../utils/api";
import {
  TRADING_ACTION_BUY,
  TRADING_ACTION_PAYOUT_PLAN,
  TRADING_ACTION_SAVINGS_PLAN, TRADING_ACTION_SELL,
} from "../../../components/Charts/InstrumentsAllocationTable/constants";
import {OBJECT_TYPES} from "../../RiskProfiling/constants";

export class ExAnteTradeMenuItem extends TradeMenuItem {
  constructor(uid, name, customer_id, questionnaire, getPrevStepAnswer, onServiceConceptDone) {
    super(uid, name, customer_id, questionnaire, getPrevStepAnswer);
    this._questionnaire = this.__deepCopy(questionnaire);
    this._customer_id = customer_id;
    this._tradings = [];
    this._exAnteCosts = null;
    this.onServiceConceptDone = onServiceConceptDone;
  }

  get isInvestmentStrategyTrading() {
    if (!this._tradings.length) {
      return false
    }

    return _.some(this._tradings, (portfolio) => {
      return _.get(portfolio, 'data.is_private_investment')
    })
  }

  _getModelPortfolioInstruments = (instruments, transactions, portfolio, tradingType) => {

    const instrumentsKey = {
      // TODO: keep old keys just in case could be removed later
      "buy": 'instruments',
      "sell": 'instruments_sell',
      "saving_plans": 'savings_plans',
      "payout_plan": 'payout_plans',
      //
      [TRADING_ACTION_BUY]: 'instruments',
      [TRADING_ACTION_SELL]: 'instruments_sell',
      [TRADING_ACTION_SAVINGS_PLAN]: 'savings_plans',
      [TRADING_ACTION_PAYOUT_PLAN]: 'payout_plans'
    }[tradingType];

    const orders = _.flatten(transactions.map((transaction) => {
      return instruments.filter(i => !!i.isin).map((instrument) => {

        let body = {
          dst_instrument: instrument.isin,
          discount: transaction.discount || undefined,  // undefined will be not send,
          amount_eur: (transaction.transaction_value * instrument.weight).toFixed(2),
          bank: portfolio.bankCode,
          booking_date: getBookingDate(transaction),
        };
        if (['savings_plans', 'payout_plans'].includes(instrumentsKey)) {
          body = {
            ...body,
            start_date: moment(transaction.from_date).format('YYYY-MM-DD'),
            periodic_plan: transaction.rotation,
          }
        }

        for (let key in body) {
          if (!body.hasOwnProperty(key) || !body[key]) {
            delete body[key]
          }
        }

        return body
      })
    }));

    return {
      [instrumentsKey]: orders
    }
  };

  _getExAnteInstrumentBody = (portfolio, item, switchOutItem, withData=true) => {
     let body = {
       dst_instrument: item.data.isin,
       discount: item.discount || undefined,  // undefined will be not send,
       amount_eur: getAmount(item, null, switchOutItem).toFixed(2),
       bank: portfolio.bankCode,
       booking_date: getBookingDate(item),
     };
     if (withData) {
       body['data'] = item
     }
    for (let key in body) {
      if (!body.hasOwnProperty(key) || !body[key]) {
        delete body[key]
      }
    }

    return body

  };

  /**
   * Convert list of instruments to the format, that is valid for ex ante request.
   *
   * @param {Array<Object>} instruments List of instruments data
   * @param {Object} portfolio Portfolio data
   *
   * @return {Array<Object>} List of instruments in required format.
   * */
  _convertInstrumentsToRequestFormat = (instruments, portfolio) => {
    if (!instruments || (Array.isArray(instruments) && !instruments.length)) {
      return []
    }

    return instruments.map(instrument => this._getExAnteInstrumentBody(portfolio, instrument, undefined, true));
  };

  /**
   * Convert savings plans to the format, that could be used for ex ante request.
   *
   * @param {Array<Object>} instruments Savings plans data. Instruments should be result of _convertInstrumentsToRequestFormat
   *
   * @return {Array<Object>} Savings plans in required format.
   * */
  _convertSavingsPlansToRequestFormat = (instruments, isPayout=false) => {

    return instruments.map(instrument => {

      const result = {
        ...instrument,
        start_date: moment(instrument.data.from_date).format('YYYY-MM-DD'),
        periodic_plan: instrument.data.rotation
      };

      if (!isPayout) {
        result.discount = instrument.data.discount || undefined  // undefined will be not send,
      }

      delete result['data'];

      return result
    })

  };

  _getActivePaymentPlans = (paymentPlans) => paymentPlans && _.filter(paymentPlans, sp => sp.action != 'delete' && sp.is_changed);

  _getExAnteRequestBody = (portfolio, objectType) => {

    if (portfolio.isModelportfolio) {

      const portfolioInstruments = _.has(portfolio, 'data.base_components')
        ? get(portfolio, 'data.base_components', [])
        : get(portfolio, 'data.components', []);

      return {
        [OBJECT_TYPES.TRADING]: () => {
          let { transactions: { buy, sell } } = portfolio;
          const transactions = buy.length ? buy : sell;
          return this._getModelPortfolioInstruments(
            portfolioInstruments, transactions, portfolio, portfolio.tradingType)
        },
        [OBJECT_TYPES.SAVINGS_PLAN]: () => {
          let { transactions: { savings_plan } } = portfolio;
          const transactions = _.filter(savings_plan || [], sp => sp.action != 'delete' && sp.is_changed)
          return this._getModelPortfolioInstruments(
            portfolioInstruments, transactions, portfolio, TRADING_ACTION_SAVINGS_PLAN)
        },
        [OBJECT_TYPES.PAYOUT_PLAN]: () => {
          let { transactions: { payout_plan } } = portfolio;
          const transactions = _.filter(payout_plan || [], sp => sp.action != 'delete' && sp.is_changed)
          return this._getModelPortfolioInstruments(
            portfolioInstruments, transactions, portfolio, TRADING_ACTION_PAYOUT_PLAN)
        },
        [OBJECT_TYPES.SWITCH_PLAN]: () => null,
      }[objectType]()
    }

    return {
      [OBJECT_TYPES.TRADING]: () => {
        let { transactions: { buy, sell } } = portfolio;

        let instruments = this._convertInstrumentsToRequestFormat(buy, portfolio);
        let instrumentsSell = this._convertInstrumentsToRequestFormat(sell, portfolio);
        let switchOutInstruments = [];
        let switchInstruments = _.flatten((portfolio.transactions.switch || []).map(switchOutItem => {
          switchOutInstruments.push(this._getExAnteInstrumentBody(portfolio, switchOutItem, undefined, false));
          return switchOutItem.buy.map(
            switchInItem => this._getExAnteInstrumentBody(portfolio, switchInItem, switchOutItem, false)
          );
        }));
        instruments = instruments.concat(switchInstruments);
        instrumentsSell = instrumentsSell.concat(switchOutInstruments);

        if (!instruments.length && !instrumentsSell.length) {
          return
        }

        return {
          instruments,
          instruments_sell: instrumentsSell,
        }
      },
      [OBJECT_TYPES.SAVINGS_PLAN]: () => {
        let { transactions: { savings_plan } } = portfolio;
        let instrumentsSavingsPlans = this._convertInstrumentsToRequestFormat(this._getActivePaymentPlans(savings_plan), portfolio);

        if (!instrumentsSavingsPlans.length) {
          return
        }

        return {
          savings_plans: this._convertSavingsPlansToRequestFormat(instrumentsSavingsPlans),
        }
      },
      [OBJECT_TYPES.PAYOUT_PLAN]: () => {
        let { transactions: { payout_plan } } = portfolio;
        let instrumentsPayoutPlans = this._convertInstrumentsToRequestFormat(this._getActivePaymentPlans(payout_plan), portfolio);

        if (!instrumentsPayoutPlans.length) {
          return
        }

        return {
          payout_plans: this._convertSavingsPlansToRequestFormat(instrumentsPayoutPlans, true)
        }
      },
      [OBJECT_TYPES.SWITCH_PLAN]: () => {
        let { transactions: { switch_plan } } = portfolio;

        let switchOutPlans = [];
        let switchInPlans = _.flatten((this._getActivePaymentPlans(switch_plan)|| []).map((switchOutPlan) => {
          switchOutPlans.push(switchOutPlan);
          return switchOutPlan.buy.map((switchInPlan) => ({
            ...switchInPlan,
            rotation: switchOutPlan.rotation,
            from_date: switchOutPlan.from_date
          }));
        }));
        switchOutPlans = this._convertInstrumentsToRequestFormat(switchOutPlans, portfolio);
        switchInPlans = this._convertInstrumentsToRequestFormat(switchInPlans, portfolio);

        if (!switchOutPlans.length) {
          return
        }

        return {
          savings_plans: this._convertSavingsPlansToRequestFormat(switchInPlans ),
          payout_plans: this._convertSavingsPlansToRequestFormat(switchOutPlans, true)
        }
      }
    }[objectType]()

  };

  _getPortfolioExAnte = async (portfolio, simulationYears=5, objectType) => {

    try {

      let requestBody = this._getExAnteRequestBody(portfolio, objectType);

      if (!requestBody) {
        return {
          portfolioName: portfolio.data.name,
          portfolioId: portfolio.portfolioId,
          bankCode: portfolio.bankCode,
          objectType,
          simulationYears,
          response: {
            [portfolio.bankCode]: {}
          }
        }
      }

      requestBody.simulationYears = simulationYears;
      requestBody.custodian_id = portfolio.companyId;
      requestBody.depot_type_id = _.get(portfolio, 'depotType.value');
      requestBody['portfolio_id'] = portfolio.portfolioId;
      requestBody['session_id'] = this.__onboarding_uid;
      requestBody['object_type'] = objectType

      let response = await BankSettingsHandlerResource.getExAnteCalculation(requestBody, true);

      return {
        portfolioName: portfolio.data.name,
        portfolioId: portfolio.portfolioId,
        bankCode: portfolio.bankCode,
        simulationYears,
        objectType,
        response
      }

    } catch (error) {

      let errorMessage = `Error during retrieving ex ante calculation for portfolio ${portfolio.portfolioId} - ${portfolio.data.name}`;
      console.error(errorMessage);

      throw error

    }

  };

  getExAnteData = async () => {

    if (!this._tradings.length) {
      return
    }

    let promises = [];

    [OBJECT_TYPES.TRADING, OBJECT_TYPES.SAVINGS_PLAN, OBJECT_TYPES.PAYOUT_PLAN, OBJECT_TYPES.SWITCH_PLAN]
      .forEach((objectType) => {

        this._tradings.forEach((portfolio) => {
          promises.push(this._getPortfolioExAnte(portfolio, objectType !== OBJECT_TYPES.PAYOUT_PLAN ? 5 : 1, objectType));
          if (objectType === OBJECT_TYPES.SWITCH_PLAN) {
            promises.push(this._getPortfolioExAnte(portfolio, 1, objectType))
          }
        });

      })


    const exAnteQuestion = this._questionnaire.steps[0].question[0];
    exAnteQuestion.error = null; // clean error
    try {
      this._exAnteCosts = await Promise.all(promises);
      exAnteQuestion.answer = aggregatePortfoliosExAnte(this._exAnteCosts)
    } catch (e) {
      exAnteQuestion.answer = null;
      await this.__setStep(null, 0);

      exAnteQuestion.error = e;
    }

  };

  /**
   * Retrieve service concept from verification step.
   * Used for processes with old steps structure
   * */
  setServiceConceptFromVerification(onboardingAnswers) {

    let serviceConcept = _.get(onboardingAnswers, 'verification.service-concept-step.0.answer.0.id');
    if (!!serviceConcept) {

      let serviceConceptStepIndex = this._questionnaire.steps.findIndex((step => step.uid == 'service-concept-step'));
      if (serviceConceptStepIndex == -1) return;
      let serviceConceptQuestionIndex = this._questionnaire.steps[serviceConceptStepIndex].question
          .findIndex(question => question.uid == "service_concept");
      if (serviceConceptQuestionIndex == -1) return;

      this._questionnaire.steps[serviceConceptStepIndex].question[serviceConceptQuestionIndex].answer = serviceConcept

    }
  }

  async __setQuestionnaire(defaultAnswers, initOnly){
    await super.__setQuestionnaire(defaultAnswers, initOnly);
    this._setDefaultAnswers(defaultAnswers);
    let prevAnswers = this._getPrevStepAnswer('trade', 'trade-step', 'transactions');

    this._tradings = prevAnswers || [];

    // get ExAnte data if not restore progress or answer is missing
    if(!initOnly || _.isEmpty(this._questionnaire.steps[0].question[0])) {
      await this.getExAnteData()
    }

    if (defaultAnswers.hasOwnProperty('verification') && !this.getStepAnswer('service-concept-step', 'service_concept')) {
      this.setServiceConceptFromVerification(defaultAnswers)
    }

    this.checkServiceConcept();
  }

  checkServiceConcept() {
    const sc = this.getStepAnswer('service-concept-step', 'service_concept');
    if(sc){
      // if SC answer present
      this.onServiceConceptDone && this.onServiceConceptDone(sc);
    }
  }
}