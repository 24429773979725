import React from "react";
import _ from "lodash";
import clsx from "clsx";

import {makeStyles} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";

import {getStepCategory} from "../../utils";
import WarningIcon from "../../../../components/Icons/WarningIcon";

import styles from "./styles";


const NO_PARENT = "no parent";

const warningUseStyles = makeStyles(() => ({
  warnIcon: {
    fontSize: '1.15em'
  }
}));

const SideMenuWarningIcon = () => {
  const classes = warningUseStyles();

  return <>&nbsp;<WarningIcon className={classes.warnIcon}/></>
};

const SideMenuItem = ({group, classes, isStepSelectedCheck, getGoToStepFunction}) => {
  const [isExpanded, setIsExpanded] = React.useState(group.parent === NO_PARENT || group.selected);

  const onGroupClick = () => {
    setIsExpanded(!isExpanded)
  };

  const renderListItem = (groupItem) => {
    const step = groupItem.step;
    const isSelected = isStepSelectedCheck(step);
    const goToStep = getGoToStepFunction(groupItem.stepNr);
    const nested = group.parent !== NO_PARENT;

    return (
      <ListItem
        key={step.uid}
        classes={{
          root: clsx(classes.listItemRoot, nested && classes.listItemRootNested, !!goToStep && classes.listItemRootClickable),
          selected: clsx(classes.listItemSelected, nested && 'nested')
        }}
        selected={isSelected}
        onClick={goToStep && (() => goToStep(step.uid))}
        button={!!goToStep}
      >
        {getStepCategory(step)}
        {groupItem.hasMissingInfo && (
          <SideMenuWarningIcon />
        )}
      </ListItem>
    )
  };

  return (
    <>
      {group.parent !== NO_PARENT && (
        <ListItem
          classes={{root: clsx(classes.listItemRoot, classes.listItemRootClickable), selected: classes.listItemSelected}}
          selected={group.selected}
          onClick={onGroupClick}
          button
        >
          {group.parent}
          {group.hasMissingInfo && (
            <SideMenuWarningIcon />
          )}
        </ListItem>
      )}
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <List disablePadding>
          {group.items.map(renderListItem)}
        </List>
      </Collapse>
    </>
  )
};

const SideMenu = (props) => {
  const {
    classes,
    dataService,
    answersData, // answers stored in db
    onGoToStepClick,
  } = props;

  if (!dataService) return null;

  const isStepSelectedCheck = (step) => step.uid === _.get(dataService.step, 'uid');
  const getGoToStepFunction = (stepNr) => dataService.currentStepNumber > stepNr ? onGoToStepClick : undefined;

  const groupedSteps = React.useMemo(() => {
    const groupedData = [];
    let lastParent;

    dataService.steps.forEach((item, idx) => {
      const parentKey = item.parent || NO_PARENT;
      if (parentKey !== lastParent) {
        groupedData.push({ parent: parentKey, selected: false, hasMissingInfo: false, items: [] });
        lastParent = parentKey;
      }
      const group = _.last(groupedData);
      // select group in case current step is inside it
      if(!group.selected) {
        group.selected = isStepSelectedCheck(item)
      }
      // in case we have stored step answers in db it was visited
      const hasMissingInfo = _.has(answersData, [dataService.uid, item.uid]) && dataService.checkHasMissingAnswers(item);
      group.items.push({step: item, hasMissingInfo, stepNr: idx + 1});
      if(!group.hasMissingInfo) {
        group.hasMissingInfo = hasMissingInfo;
      }
    });

    return groupedData;
  }, [dataService.steps]);

  return (
    <div className={classes.menuContainer}>
      {groupedSteps.map((group, idx) => (
        <SideMenuItem
          key={idx}
          group={group}
          classes={classes}
          isStepSelectedCheck={isStepSelectedCheck}
          getGoToStepFunction={getGoToStepFunction}
        />
      ))}
    </div>
  )
};

export default withStyles(styles)(SideMenu);