import React from 'react';

/* Material-UI Components */
import Grid from '@material-ui/core/Grid';

/* BCA Components */
import useStyles from './styles'
import Pagination from '../../../../components/CustomerList/components/Pagination';
import CustomerListItem from './components/CustomerListItem';
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../../../utils/constants";

const CustomerList = (props) => {
  const classes = useStyles();

  const {
    customers,
  } = props;

  const [page, setPage] = React.useState(0);

  const customersPageData = customers[page <= customers.length - 1 ? page : customers.length - 1]

  return (
    <Grid container className={`${classes.listContainer}`}>
      <React.Fragment>
        {customers && (
          <React.Fragment>
            {customers && customers.length > 1 && (
              <Pagination handlePageChanged={setPage} totalPageCount={customers.length} currentPage={page}/>
            )}
            {
              (customers && customers.length) > 0 ? customersPageData.map(customer => (
                <CustomerListItem key={customer.customer_id} customer={customer} customerDataLoading={false} customerLoadingError={false}/>
              )) : (
                <div className={classes.emptyMessageContainer}>
                  <p>{DEFAULT_EMPTY_SECTION_MESSAGE}</p>
                </div>
              )
            }
            {customers && customers.length > 1 && (
              <Pagination handlePageChanged={setPage} totalPageCount={customers.length} currentPage={page}/>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    </Grid>
  );
};

export default CustomerList;