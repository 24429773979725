import React, {useState} from "react";
import _ from "lodash";

import {parseResponse, PortfolioHandlerResource} from "../../../utils/api";
import {AGGREGATED_PORTFOLIO_NAME} from "../../../utils/aggregated-portfolio";
import {AGGREGATED_PORTFOLIO_ID, buildBenchmarkName} from "../../../utils/utils";
import {BENCHMARK_CHART_COLOR, CHART_COLORS, DEFAULT_EMPTY_SECTION_MESSAGE} from "../../../utils/constants";
import {useTabsSharedDataContext} from "../components_v2/TabsSharedData/TabsSharedData";
import {generateQueryParamsHash} from "../utils";


async function fetchPortfolioRollingVolatilityData(customerId, portfolio, assets, startDate, endDate, benchmarks, dataProvider, investmentStrategyId) {
  const response = await dataProvider.getRollingVolatilityData(
    customerId, portfolio.portfolioIdsToRequest || portfolio.depotNumber, startDate, endDate,
    benchmarks, assets, investmentStrategyId);

  return [portfolio, response];
}

export function getPortfoliosToUse(aggregated, portfolios, aggregatedPortfolioName) {
  return aggregated && portfolios.length > 1 ?
    [{
      id: AGGREGATED_PORTFOLIO_ID,
      name: aggregatedPortfolioName || AGGREGATED_PORTFOLIO_NAME,
      color: CHART_COLORS[0],
      portfolioIdsToRequest: portfolios.map((p) => p.depotNumber)
    }] : portfolios;
}

export function getBenchmarkPortfolio(benchmarks) {
  return {
    id: 'benchmark',
    name: buildBenchmarkName(benchmarks),
    color: BENCHMARK_CHART_COLOR
  }
}

export function _parseResponse(response, key, handleDataCallback) {
  return response.reduce((result, [portfolio, res]) => {

    const portfolioData = {
      name: portfolio.name,
      color: portfolio.color,
      id: portfolio.id,
    }

    parseResponse(res, key,
      data => {
        result[portfolio.id] = {
          ...portfolioData,
          ...handleDataCallback(data),
        }
      },
      error => {
        result[portfolio.id] = {
          ...portfolioData,
          portfolio: [],
          error,
          missingData: true,
          missingDataText: error
        }
      }
    )
    return result;
  }, {})
}

function handleRollingVollatility(data) {
  let _data = data['rolling_risk_metrics'].filter(value => (value.volatility_annual !== undefined))
      .map(value => ({date: value.date, value: value.volatility_annual * 100}));
  let error = null;
  if (_.isEmpty(_data)) {
    error = DEFAULT_EMPTY_SECTION_MESSAGE;
  }

  return {
    portfolio: _data,
    error
  }
}

const SHARED_DATA_KEY = 'rolling-volatility-data';

/**
 * Provide rolling volatility data.
 * @param customerId {Number|String} Customer identifier
 * @param portfolios {Object} Object with portfolios/assets identifiers.
 * portfolios example:
 * @example
 * useRollingVolatilityData(9999999999, {
 *   'portfolioId_1': {
 *     'assets': [], // If array is empty then data for whole portfolio should be requested.
 *     'name': 'portfolio name',
 *     'color': 'black'
 *   },
 *   'portfolioId_2': {
 *      'assets': [ //Otherwise data for specific assets should be requested.
 *        'assetId_1',
 *        'assetId_2'
 *      ],
 *      'name': 'portfolio name',
 *      'color': 'black'
 *   }
 * })
 */
export function useRollingVolatilityData(customerId, portfolios, benchmarks, aggregated, startDate, endDate, dataProvider=PortfolioHandlerResource, aggregatedPortfolioName, investmentStrategyId) {
  const [data, setData] = useState({
    data: null,
    errors: null,
    loading: false,
    updatedAt: +new Date()
  });

  const paramsForHash = [startDate, endDate, investmentStrategyId, benchmarks, aggregated];
  const sharedData = useTabsSharedDataContext();

  async function fetch() {

    const _setState = (state) => {
      // It is not possible to sync data with shared data in
      // hook because hook is not executed if component destroyed
      setData(state);
      sharedData.setDataValue(SHARED_DATA_KEY, hashKey, state);
    };

    const hashKey = generateQueryParamsHash(customerId, portfolios, ...paramsForHash);

    _setState({
      data: null,
      errors: null,
      loading: true,
      updatedAt: +new Date()
    });

    try {
      const response = await Promise.all(
        getPortfoliosToUse(aggregated, portfolios, aggregatedPortfolioName)
          .map((portfolioData) => fetchPortfolioRollingVolatilityData(
              customerId, portfolioData, [],
              startDate, endDate, benchmarks, dataProvider, investmentStrategyId)
          ));

      const portfoliosData = _parseResponse(response, 'rolling_vola_portfolio', handleRollingVollatility);
      const benchmarksData = !_.isEmpty(benchmarks)
        ? _parseResponse([[getBenchmarkPortfolio(benchmarks), response[0][1]]], 'rolling_vola_benchmark', handleRollingVollatility)
        : {}

      _setState({
        data: {...portfoliosData, ...benchmarksData},
        errors: null,
        loading: false,
        updatedAt: +new Date()
      })

    } catch (errors) {
      console.log(errors)
      _setState({
        data: null,
        errors: errors,
        loading: false,
        updatedAt: +new Date()
      });
    }
  }

  React.useEffect(() => {
    if (!_.isEmpty(portfolios) && !!customerId) {
      const sharedPortfolioData = !!sharedData
        ? sharedData.getDataValue(SHARED_DATA_KEY, generateQueryParamsHash(customerId, portfolios, ...paramsForHash))
        : undefined;

      if (!!sharedPortfolioData && !sharedPortfolioData.errors) {
        // sync internal hook data with shared data.
        setData(sharedPortfolioData);
        return;
      }

      // fetch data if there is no shared data or shared data was loaded with error
      // check if data fetched for the first time (updatedAt is undefined) to prevent infinite data reloading
      // in case of data loading error, so data will be requested again only if tab changed
      if (!data.updatedAt || !sharedPortfolioData) {
        fetch();
      }
    }
  }, [customerId, JSON.stringify(portfolios), startDate,
    endDate, JSON.stringify(benchmarks), aggregated]);

  return [data, fetch];
}