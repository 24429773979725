import React from "react";
import _ from 'lodash';

import {SORTING_TYPES} from "../../components/FilteringPanel/components/SortingFilter/constants";
import {DASHBOARD_SETTINGS_TYPE, DASHBOARD_TYPE} from "./constants";
import { CustomerReportSettingResource } from '../../utils/api';
import { displayErrorSnackBar, displaySuccessSnackBar } from '../../components/SnackbarProvider/actions';
import { REPORTS_AREAS } from './components/CustomersList/components/ReportsAreasVisibility/constants';
import {SENDING_DATE} from "../GroupReporting/components/ReportSendingDate/constants";
import {CUSTOM_PERFORMANCE_TIME_TYPE_VALUE} from "../../utils/constants";

export const getDashboardSettingsType = (dashboardType, isGroupDashboard) => {
  /** Returns dashboard settings type (broker, customer, broker group, customer group)
   *  depending on dashboard type (broker or customer dashboard)
   *
   *  @param dashboardType - defines is it brokers or customers dashboard
   *  @param isGroupDashboard - flag to get settings of the group or single broker/customer
   *  */
  if(isGroupDashboard){
    return dashboardType === DASHBOARD_TYPE.BROKER ? DASHBOARD_SETTINGS_TYPE.BROKER_GROUP : DASHBOARD_SETTINGS_TYPE.CUSTOMER_GROUP
  } else {
    return dashboardType === DASHBOARD_TYPE.BROKER ? DASHBOARD_SETTINGS_TYPE.SINGLE_BROKER : DASHBOARD_SETTINGS_TYPE.SINGLE_CUSTOMER
  }
}

export const getDashboardType = (dashboardSettingsType) => {
  /** Returns dashboard type (broker, customer or general) depending on dashboard settings type
   * (single customer, customer group, single broker, broker group, general) */

  let dashboardType = undefined
  if (dashboardSettingsType == DASHBOARD_SETTINGS_TYPE.BROKER_GROUP || dashboardSettingsType == DASHBOARD_SETTINGS_TYPE.SINGLE_BROKER){
    dashboardType = DASHBOARD_TYPE.BROKER
  } else if (dashboardSettingsType == DASHBOARD_SETTINGS_TYPE.CUSTOMER_GROUP || dashboardSettingsType == DASHBOARD_SETTINGS_TYPE.SINGLE_CUSTOMER){
    dashboardType = DASHBOARD_TYPE.CUSTOMER
  }
  return dashboardType
}


export const isBenchmarksSettingsEqual = (benchmarksList1, benchmarksList2) => {
  /* compare benchmarks settings by id, weight */

  let reduced_arr1 = benchmarksList1.map(el => {
    return [el.id, el.weight].join('_')
  })
  let reduced_arr2 = benchmarksList2.map(el => {
    return [el.id, el.weight].join('_')
  });

  return _.isEqual(reduced_arr1, reduced_arr2)
}


/**
 * Filter groups according to the provided filters criteria.
 *
 * @param {{}[]} groups List of groups to filter
 * @param {{}} filters Filters
 */
 export const filterGroups = (groups, filters) => {
  
  return groups.filter((group) => isGroupAcceptedByFilters(group, filters));
}

/**
 * Validate, if group is valid for provided filters.
 * @param {{}} group Group data
 * @param {{}} filters Filters
 */
 const isGroupAcceptedByFilters = (group, filters) => {

  return _isLetterFirstInGroupName(group, filters.first_letter) && _isGroupNameValid(group, filters.search)

}

/**
 * Check, if group name match with search value.
 * @param {{name: string}} group Group data
 * @param {string} search Value to compare with
 */
 const _isGroupNameValid = (group, search) => {
  if (_.isEmpty(search)) {
    return true
  }
  let groupName = (group.name || '').toLowerCase();

  return groupName.includes(search.toLowerCase());
}


/**
 * Check, if group name match with search value.
 * @param {{name: string}} group Group data
 * @param {string} search Value to compare with
 */
 const _isLetterFirstInGroupName = (group, search) => {
  if (_.isEmpty(search)) {
    return true
  }
  return (group.name.charAt(0) || '').toLowerCase() ===  search.toLowerCase();
}


/**
 * Sort groups by selected sorting type.
 * @param {{}[]} groups Groups data
 * @param {{value: number}} sortType Sorting type
 */
 export const sortGroups = (groups, sortType) => {
  const [iteratees, orders] = {
    [SORTING_TYPES.NAME_ASC.value]: _orderByGroupName('asc'),
  }[sortType.value]

  return _.orderBy(groups, iteratees, orders)
}

const _orderByGroupName = (type) => {
  return [[(group) => (group.name).toLowerCase()], [type]]
}

export const resetDashboardSettings = async (type, settings_id, customer_id, dispatch) => {
  try {
    let response = await CustomerReportSettingResource.at(`customer/dashboard-settings/${settings_id}/delete/?type=${type}`).delete();

    dispatch(displaySuccessSnackBar('Die Einstellungen für die Vermögensübersicht sind wieder auf die Grundeinstellungen zurückgesetzt worden.'))

    return response;
  } catch (err) {
    dispatch(displayErrorSnackBar('Während des Zurücksetzens auf die Grundeinstellungen ist ein Problem aufgetreten.'))
  }
};

export const portfoliosIdentifiersAreEqual = (portfoliosLeft, portfoliosRight) => {
  const prepareList = (list) => !!list && JSON.stringify(list.sort())
  return prepareList(portfoliosLeft) === prepareList(portfoliosRight)
};

export const onCustomerSettingsChanged = (updatedCustomerData, initialSettings, field, value) => {
  if (field == 'portfolios') {
    updatedCustomerData = {
      ...updatedCustomerData,
      portfolios: value.portfolios,
      excluded_portfolios: value.excluded_portfolios
    }
  } else if (field === 'areas_visibility') {
    updatedCustomerData = {
      ...updatedCustomerData,
      with_payment_plans: value.includes(REPORTS_AREAS.PAYMENT_PLANS.value),
      with_profit_loss: value.includes(REPORTS_AREAS.PROFIT_LOSS.value),
      include_historical_portfolios: value.includes(REPORTS_AREAS.INCLUDE_HISTORICAL_PORTFOLIOS.value),
      with_transaction_saldo: value.includes(REPORTS_AREAS.WITH_TRANSACTION_SALDO.value),
      with_transactions_monitor: value.includes(REPORTS_AREAS.TRANSACTIONS_MONITOR.value),
      with_other_assets: value.includes(REPORTS_AREAS.OTHER_ASSETS.value)
    }
  } else if(field === 'date_updates'){
    updatedCustomerData = {
      ...updatedCustomerData,
      date_range: value.date_range,
      date_range_start_date: value.date_range_start_date,
      date_range_end_date: value.date_range_end_date,
    }
  } else if(field === 'benchmark_updates') {
    updatedCustomerData = {
      ...updatedCustomerData,
      benchmark_enabled: value.benchmark_enabled,
      benchmarks: value.benchmarks,
    }
  } else {
    if (_.isArray(field) && _.isArray(value)) {
      field.forEach((name, index) => {
        updatedCustomerData = {
          ...updatedCustomerData,
          [name]: value[index]
        }
      })
    } else {
      updatedCustomerData = {
        ...updatedCustomerData,
        [field]: value
      };
    }
  }

  const isCustomDateRange = updatedCustomerData.date_range == SENDING_DATE.CUSTOM.value || updatedCustomerData.date_range == CUSTOM_PERFORMANCE_TIME_TYPE_VALUE;

  // checking if any fields were changed from initial state
  let isOldEqualNew = updatedCustomerData.report_type === initialSettings.report_type &&
    updatedCustomerData.distribution_type === initialSettings.distribution_type &&
    updatedCustomerData.date_range == initialSettings.date_range &&  // might be int (old settings) or str (new settings)
    updatedCustomerData.with_cost === initialSettings.with_cost &&
    updatedCustomerData.with_transactions === initialSettings.with_transactions &&
    updatedCustomerData.with_payment_plans === initialSettings.with_payment_plans &&
    updatedCustomerData.with_profit_loss === initialSettings.with_profit_loss &&
    updatedCustomerData.include_historical_portfolios === initialSettings.include_historical_portfolios &&
    !!updatedCustomerData.save_settings_to_related === !!initialSettings.save_settings_to_related && // save_settings_to_related is undefined as it is flag, we need to convert it
    updatedCustomerData.with_invested_capital === initialSettings.with_invested_capital &&
    isBenchmarksSettingsEqual(updatedCustomerData.benchmarks, initialSettings.benchmarks) &&
    (!isCustomDateRange || updatedCustomerData.date_range_start_date === initialSettings.date_range_start_date) &&
    (!isCustomDateRange || updatedCustomerData.date_range_end_date === initialSettings.date_range_end_date) &&
    updatedCustomerData.benchmark_enabled === initialSettings.benchmark_enabled &&
    updatedCustomerData.aggregate_historical_chart_portfolios === initialSettings.aggregate_historical_chart_portfolios &&
    updatedCustomerData.aggregate_performance_chart_portfolios === initialSettings.aggregate_performance_chart_portfolios &&
    updatedCustomerData.with_transaction_saldo === initialSettings.with_transaction_saldo &&
    updatedCustomerData.with_transactions_monitor === initialSettings.with_transactions_monitor &&
    updatedCustomerData.with_other_assets === initialSettings.with_other_assets &&
    JSON.stringify(updatedCustomerData.custom_report_type_settings) === JSON.stringify(initialSettings.custom_report_type_settings) &&
    JSON.stringify(updatedCustomerData.individual_settings) === JSON.stringify(initialSettings.individual_settings) &&
    portfoliosIdentifiersAreEqual(updatedCustomerData.portfolios.value,  initialSettings.portfolios.value) &&
    portfoliosIdentifiersAreEqual(updatedCustomerData.excluded_portfolios.value,  initialSettings.excluded_portfolios.value);

  return [updatedCustomerData, isOldEqualNew];
};