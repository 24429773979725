import React from "react";
import {Typography, withStyles} from "@material-ui/core";
import styles from "./styles";
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../../../utils/constants";


class EmptyCard extends React.Component {

  render() {
    const { classes, title, sup} = this.props;
    return <div className={classes.card}>
      {title &&
        <Typography variant={"h2"} className={classes.title}>
          {title}{sup && <sup>{sup}</sup>}
        </Typography>
      }
      <div className={classes.contentWrapper}>{DEFAULT_EMPTY_SECTION_MESSAGE}</div>
    </div>
  }
}

export default withStyles(styles)(EmptyCard);
