export const footerHeight = 92;

const styles = () => ({
  footerContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: '1px solid #D9DCDF',
    background: '#fff',
    padding: '20px 32px',
    height: footerHeight,
    zIndex: 1,
  },
  footerContent: {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto'
  }
});

export default styles;