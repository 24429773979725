import React from "react";

import SettingsProfile from "../../components/StepContent/components/step/EsgProfile/SettingsProfile";
import SelectProfile from "../../components/StepContent/components/step/EsgProfile/SelectProfile";
import Summary from "../../components/StepContent/components/step/EsgProfile/Summary";

import {
  OneAspect,
  TwoAspect,
  ThreeAspect,
  FourAspect,
  FiveAspect,
  SixAspect,
  SevenAspect,
  EightAspect,
  NineAspect,
  TenAspect,
  ElevenAspect,
  TwelveAspect,
  ThirteenAspect,
  FourteenAspect,
  FifteenAspect,
  OneAspectDark,
  TwoAspectDark,
  ThreeAspectDark,
  FourAspectDark,
  FiveAspectDark,
  SixAspectDark,
  SevenAspectDark,
  EightAspectDark,
  NineAspectDark,
  TenAspectDark,
  ElevenAspectDark,
  TwelveAspectDark,
  ThirteenAspectDark,
  FourteenAspectDark,
  FifteenAspectDark

} from "../../../../images"
import DisclaimerTextV2 from "../StepContent/components/step/EsgProfile/DisclaimerText/DisclaimerTextV2";

const ESGChoices = [
  {
    "uid": "ecologically-sustainable",
    "title": "1. Ökologisch Nachhaltig",
    "sub_title":
      "„Ökologisch nachhaltig“ bezeichnet eine nachhaltige Investition gemäss der Taxonomieverordnung. Solch eine Investition leistet u.a. einen wesentlichen Beitrag zur Verwirklichung eines oder mehrerer Umweltziele.",
  },
  {
    "uid": "esg",
    "title": "2. ESG",
    "sub_title":
      "„ESG“ (Environmental Social Governance) bezeichnet eine nachhaltige Investition gemäss der Offenlegungsverordnung. Sie trägt zur Erreichung eines Umweltziels oder eines sozialen Ziels bei und investiert in Unternehmen, die Verfahrensweisen einer guten Unternehmensführung anwenden.",
  },
  {
    "uid": "pai",
    "title": "3. PAI",
    "sub_title":
      "„PAI“ (Principal Adverse Impacts) bezeichnet Investitionen, welche die wichtigsten nachteiligen Auswirkungen auf Nachhaltigkeitsfaktoren beachten wie z.B.: Treibhausgasemissionen, Biodiversitätsverlust, Wasserverbrauch, Abfall sowie soziale und arbeitsrechtliche Belange.",
  },
];

const step1 = {
  "uid": "esg-profile-selection-step",
  "name": "Bitte wählen Sie das Nachhaltigkeitsprofil",
  "info_text": <DisclaimerTextV2 />,
  "behaviour": ["dynamic", "Auswahl"],
  "step_content": SelectProfile,
  "question": [
    {
      "uid": "esg-advisor-type",
      "type": "normal",
      "behaviour": ["radio"],
      "optional": true,
      "hidden": true,
      "options": [
        {
          "id": 1,
          "value": "yes",
          "label": "Ja, ich möchte ein ESG Profil anlegen"
        },
        {
          "id": 2,
          "value": "no",
          "label": "Nein, ich möchte kein ESG Profil anlegen",
        }
      ],
    },
    {
      "uid": "esg-profile-radio-uid",
      "type": "normal",
      "behaviour": ["radio"],
      "custom_classes": {'labelRoot': 'bold'},
      "optional": true,
      "options": [
        {
          "id": 1,
          "value": "new",
          "label": "Neues Nachhaltigkeitsprofil anlegen"
        },
        {
          "id": 2,
          "value": "not-interested",
          "label": "Der Kunde/die Kundin wünscht keine Nachhaltigkeitsprofilierung",
        },
        {
          "id": 3,
          "value": "prefill",
          "label": "Bereits durchgeführte Nachhaltigkeitsprofilierung anpassen",
        },
        {
          "id": 4,
          "value": "select",
          "label": "Bereits durchgeführte Nachhaltigkeitsprofilierung auswählen",
        },
      ],
    },
    {
      "uid": "esg-profile-select-uid",
      "question_text": "Nachhaltigkeitsprofilierung",
      "type": "normal",
      "behaviour": ["select"],
      "optional": true,
    },
  ],
};

const ESG_PERCENTAGE_QUESTION = 'Wieviel Prozent des Kundenportfolios sollten mindestens diese Vorgabe erfüllen?';
const ESG_GROUP = "Gewichtung der Nachhaltigkeit";
const step3 = {
  "uid": "ecologically-sustainable",
  "name": "Wie wichtig ist Ihrem Kunden/Ihrer Kundin Nachhaltigkeit?",
  "step_content": SettingsProfile,
  "parent": ESG_GROUP,
  "behaviour": ["dynamic", "Ökologisch nachhaltig"],
  "question": [
    {
      "uid": "cards",
      "type": "permission",
      "behaviour": ["checkbox_list"],
      "question_text": "1. Ökologisch Nachhaltig",
      "question_info":
        "Nachhaltige Investition gemäss Taxonomieverordnung. Leistet u.a. einen wesentlichen Beitrag zur Verwirklichung eines oder mehrerer Umweltziele.",
      "optional": true,
      "config": {
        "choices": ESGChoices,
      },
    },
    {
      "uid": "percent",
      "type": "normal",
      "behaviour": ["slider"],
      "question_text": ESG_PERCENTAGE_QUESTION,
      "optional": true,
    },
    {
      "uid": "aspects",
      "type": "permission",
      "behaviour": ["checkbox_list"],
      "question_text": "Welche Aspekte möchte ihr Kunde gezielt unterstützen?",
      "question_info":
        "Filter die hier ausgewählt sind, werden später in der Übersicht der Produktauswahl berücksichtigt und entsprechende Produkte werden gekennzeichnet.",
      "optional": true,

      "config": {
        "choices": [
          {
            "uid": 1,
            "icon": <OneAspect/>,
            "icon_dark": <OneAspectDark/>,
            "text": "Klimaschutz",
          },
          {
            "uid": 2,
            "icon": <TwoAspect/>,
            "icon_dark": <TwoAspectDark/>,
            "text": "Anpassung an den Klimawandel",
          },
          {
            "uid": 3,
            "icon": <ThreeAspect/>,
            "icon_dark": <ThreeAspectDark/>,
            "text": "Nachhaltige Nutzung und Schutz von Wasser- und Meeresressourcen",
          },
          {
            "uid": 4,
            "icon": <FourAspect/>,
            "icon_dark": <FourAspectDark/>,
            "text": "Übergang zu einer Kreislaufwirtschaft",
          },
          {
            "uid": 5,
            "icon": <FiveAspect/>,
            "icon_dark": <FiveAspectDark/>,
            "text": "Vermeidung und Verminderung der Umweltverschmutzung",
          },
          {
            "id": 6,
            "uid": 6,
            "icon": <SixAspect/>,
            "icon_dark": <SixAspectDark/>,
            "text": "Schutz und Wiederherstellung der Biodiversität und der Ökosysteme",
          },
        ],
      },
    },
  ],
};

const step4 = {
  "uid": "esg",
  "name": "Wie wichtig ist Ihrem Kunden/Ihrer Kundin Nachhaltigkeit?",
  "parent": ESG_GROUP,
  "behaviour": ["dynamic", "ESG"],
  step_content: SettingsProfile,
  "question": [
    {
      "uid": "cards",
      "type": "permission",
      "behaviour": ["checkbox_list"],
      "question_text": "1. Ökologisch Nachhaltig",
      "question_info":
        "Fokus ist die Umwelt und natürlichen Ressourcen zu schonen.",
      "optional": true,
      "config": {
        "choices": ESGChoices,
      },
    },
    {
      "uid": "percent",
      "type": "normal",
      "behaviour": ["slider"],
      "question_text": ESG_PERCENTAGE_QUESTION,
      "question_info":
        "(Umwelt-, Sozial- und Unternehmensführung ) Fokus ist Gewissenhaftigkeit eines Unternehmens für soziale und ökologische Faktoren.",
      "optional": true,
    },
    {
      "uid": "aspects",
      "type": "permission",
      "behaviour": ["checkbox_list"],
      "question_text": "Welche Aspekte möchte ihr Kunde gezielt unterstützen?",
      "question_info":
        "Filter die hier ausgewählt sind, werden später in der Übersicht der Produktauswahl berücksichtigt und entsprechende Produkte werden gekennzeichnet.",
      "optional": true,
      "config": {
        "choices": [
          {
            "uid": 1,
            "icon": <TwoAspect/>,
            "icon_dark": <TwoAspectDark/>,
            "text": "Ökologische Kriterien",
          },
          {
            "uid": 2,
            "icon": <SevenAspect/>,
            "icon_dark": <SevenAspectDark/>,
            "text": "Soziale Kriterien",
          },
          {
            "uid": 3,
            "icon": <EightAspect/>,
            "icon_dark": <EightAspectDark/>,
            "text": "Verfahrensweisen einer guten Unternehmensführung",
          },
        ],
      },
    },
  ],
};

const step5 = {
  "uid": "pai",
  "name": "Wie wichtig ist Ihrem Kunden/Ihrer Kundin Nachhaltigkeit?",
  "parent": ESG_GROUP,
  "behaviour": ["dynamic", "PAI"],
  "step_content": SettingsProfile,
  "question": [
    {
      "uid": "cards",
      "type": "permission",
      "behaviour": ["checkbox_list"],
      "question_text": "1. Ökologisch Nachhaltig",
      "question_info":
        "Fokus ist die Umwelt und natürlichen Ressourcen zu schonen.",
      "optional": true,
      "config": {
        "choices": ESGChoices,
      },
    },
    {
      "uid": "percent",
      "type": "normal",
      "behaviour": ["slider"],
      "question_text": ESG_PERCENTAGE_QUESTION,
      "question_info":
        "(Umwelt-, Sozial- und Unternehmensführung ) Fokus ist Gewissenhaftigkeit eines Unternehmens für soziale und ökologische Faktoren.",
      "optional": true,
    },
    {
      "uid": "aspects",
      "type": "permission",
      "behaviour": ["checkbox_list"],
      "question_text": "Das Finanzinstrument soll folgende PAI-Themen berücksichtigen.",
      "question_info":
        "Filter die hier ausgewählt sind, werden später in der Übersicht der Produktauswahl berücksichtigt und entsprechende Produkte werden gekennzeichnet.",
      "optional": true,
      "config": {
        "choices": [
          {
            "uid": 1,
            "icon": <NineAspect/>,
            "icon_dark": <NineAspectDark/>,
            "text": "Treibhausgas-Emissionen",
          },
          {
            "uid": 2,
            "icon": <TenAspect/>,
            "icon_dark": <TenAspectDark/>,
            "text": "Biodiversität",
          },
          {
            "uid": 3,
            "icon": <ElevenAspect/>,
            "icon_dark": <ElevenAspectDark/>,
            "text": "Wasser",
          },
          {
            "uid": 4,
            "icon": <TwelveAspect/>,
            "icon_dark": <TwelveAspectDark/>,
            "text": "Abfall",
          },
          {
            "uid": 5,
            "icon": <ThirteenAspect/>,
            "icon_dark": <ThirteenAspectDark/>,
            "text": "Soziale Themen und Arbeitnehmerbelange, Korruption und Menschenrechte",
          },
          {
            "uid": 6,
            "icon": <FourteenAspect/>,
            "icon_dark": <FourteenAspectDark/>,
            "text": "Indikatoren bei Investments in staatliche und supranationale Emittenten",
          },
          {
            "uid": 7,
            "icon": <FifteenAspect/>,
            "icon_dark": <FifteenAspectDark/>,
            "text": "Indikatoren bei Investments in Immobilienvermögen",
          },
        ],
      },
    },
  ],
};

const step6 = {
  "uid": "summary",
  "behaviour": ["dynamic", "Nachhaltigkeitsprofil"],
  "name": "Nachhaltigkeitsprofil von {customer_name}",
  "withUserName": true,
  step_content: Summary,
  "question": [
    {
      "uid": "esg",
      "question_text":
        "Ihr Kundenportfolio soll idealerweise diese Vorgabe erfüllen",
      "question_info":
        "Diese Filter sind in der Übersicht der Produktauswahl berücksichtigt und entsprechende Produkte werden gekennzeichnet.",
      "type": "normal",
      "behaviour": ["radio"],
      "optional": true,
    },
    {
      "uid": "pai",
      "question_text":
        "Nachhaltigkeitsprofil des Kunden",
      "question_info":
        "Ihr Kunde möchte in nachhaltige Produkte investieren.",
      "type": "normal",
      "behaviour": ["radio"],
      "optional": true,
    },
    {
      "uid": "ecologically-sustainable",
      "question_text":
        "Nachhaltigkeitsprofil des Kunden",
      "question_info":
        "Ihr Kunde möchte in nachhaltige Produkte investieren.",
      "type": "normal",
      "behaviour": ["radio"],
      "optional": true,
    },
  ],
};

const congrats = {
  "uid": "congrats-step",
  "name": "Nachhaltigkeitsprofilierung",
  "help": "Das Nachhaltigkeitsprofil des Kunden wurde erfolgreich gespeichert. Sie können diese Informationen auch unter Meine Kunden ansehen und bearbeiten.",
  "behaviour": ["congrats"],
}

export const esgProfileStepsDataV2 = {
  steps: [step1, step3, step4, step5, step6, congrats],
};