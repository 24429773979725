import React from "react";
import _ from "lodash";
import {parseResponse} from "../../../utils/api";
import {generateQueryParamsHash} from "../utils";
import {useTabsSharedDataContext} from "../components_v2/TabsSharedData/TabsSharedData";

const SHARED_DATA_KEY = 'portfolio-risk-return-data';

/**
 * Fetch portfolio risk return and correlation matrix data.
 * @param customerId Customer identifier
 * @param portfolio Portfolio data
 * @param dataProvider Data provider
 * @param useContext Flag, that indicate if data from context should be used or not.
 * Set to true in case hook wrapped in context provider that already fetch data and it could be reused.
 */
export function usePortfolioRiskReturnData(customerId, portfolio, dataProvider, startDate, endDate, investmentStrategyId) {

  const [data, setData] = React.useState({
    portfolioRiskReturnData: undefined,
    portfolioRiskReturnLoading: true,
    portfolioRiskReturnError: null,
    updatedAt: +new Date(),
  });

  const paramsForHash = [startDate, endDate, investmentStrategyId];
  const sharedData = useTabsSharedDataContext();
  const dataKey = `${SHARED_DATA_KEY}-${portfolio.depotNumber}`;

  const fetch = () => {

    const hashKey = generateQueryParamsHash(customerId, [portfolio], ...paramsForHash);

    const _setData = (state) => {
      // It is not possible to sync data with shared data in
      // hook because hook is not executed if component destroyed
      setData(state);
      sharedData.setDataValue(dataKey, hashKey, state);
    };

    dataProvider.getPortfolioRiskReturn(customerId, portfolio.depotNumber, investmentStrategyId, startDate, endDate)
      .then((response) => {
        parseResponse(response, 'pf_risk_return',
          (data) => {
            _setData({
              portfolioRiskReturnData: data,
              portfolioRiskReturnLoading: false,
              portfolioRiskReturnError: undefined,
              updatedAt: +new Date()
            })
          },
          (error) => {
            _setData({
              portfolioRiskReturnData: undefined,
              portfolioRiskReturnLoading: false,
              portfolioRiskReturnError: error,
              updatedAt: +new Date()
            })
          }
        )
      })
      .catch((error) => {
        _setData({
          portfolioRiskReturnData: undefined,
          portfolioRiskReturnLoading: false,
          portfolioRiskReturnError: error.toString(),
          updatedAt: +new Date()
        })
      })
  };

  React.useEffect(() => {
    if (!!customerId && !!portfolio) {
      const sharedRiskReturnData = !!sharedData
        ? sharedData.getDataValue(dataKey, generateQueryParamsHash(customerId, [portfolio], ...paramsForHash))
        : undefined;

      if (!!sharedRiskReturnData && !sharedRiskReturnData.errors) {
        // sync internal hook data with shared data.
        setData(sharedRiskReturnData);
        return;
      }

      // fetch data if there is no shared data or shared data was loaded with error
      // check if data fetched for the first time (updatedAt is undefined) to prevent infinite data reloading
      // in case of data loading error, so data will be requested again only if tab changed
      if (!data.updatedAt || !sharedRiskReturnData) {
        fetch();
      }
    }
  }, [customerId, _.get(portfolio, 'depotNumberForAssets'), _.get(portfolio, 'depotNumber'), startDate, endDate]);

  return data

}