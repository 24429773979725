import React from "react";
import {Grid, Typography, withStyles} from "@material-ui/core";
import CommonAnalysisCard from "../../../../../VirtualPortfolioPage/components/AnalysisCard";
import MsRating from "../MsRating";
import { toShortGermanFormat } from "../../../../utils";
import styles from "./styles";
import Article from "../Article";
import _ from "lodash";
import WarningTooltip from "../../../../../../components/WarningTooltip";
import LoadingIndicator from "../../../../../../components/LoadingIndicator/LoadingIndicator";
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../../../../../utils/constants";

class EsgScoreChart extends React.Component {
  constructor(props) {
    super(props);
  }

  renderValue(value) {
    return value === null || value === undefined
      ? "k.A."
      : toShortGermanFormat(value, "", "", 2, false);
  }

  renderData() {
    const { classes, assetInfo, loading } = this.props;

    const sfdr = _.get(assetInfo, 'sfdr_detail.value');

    const tooltip = (
      <>
        Klassifizierung von Finanzprodukten nach der sogenannten
        Offenlegungsverordnung (Sustainable Finance Disclosures Regulation).
        Diese enthält Regelungen zur Klassifikation von Finanzprodukten nach dem
        Grad der Transparenz ihrer Nachhaltigkeit. Die Klassifikation der
        Produkte reicht von Artikel 6 - Finanzprodukte, welche nur eine
        Risikobewertung hinsichtlich nachhaltiger Kriterien vornehmen - über
        Artikel 8 - Finanzprodukte, welche ökologische oder soziale Merkmale
        bewerben bzw. bei der Auswahl ihrer Anlageinstrumente berücksichtigen -
        bis zu Artikel 9 - Finanzprodukte, nicht nur bestimmte
        Nachhaltigkeitskriterien definiert, sondern sich zu einem konkreten,
        nachhaltigkeitsbezogenen Anlageziel verpflichtet haben (Impactprodukte).
      </>
    );
    return (
      <>
        <Grid container className={classes.topPart}>
          <Grid item xs={12} md={6} lg={4} className={classes.table}>
            <MsRating
              value={!_.isNil(assetInfo.esg_sustainability_rating) ? assetInfo.esg_sustainability_rating + 1 : undefined}
              tooltip={
                <>
                  Das Morningstar Nachhaltigkeits-Rating ist ein Maß dafür wie
                  gut ein Fonds ESG-Risiken im Vergleich zu anderen Fonds seiner
                  Vergleichsgruppe managt bzw. reduziert. Das Rating setzt sich
                  aus den Scores für Unternehmen und für Staaten zusammen.
                  Entsprechend der Gewichtung der Portfoliopositionen im Fonds
                  werden auch diese beiden Unterrisiken zum Gesamtscore
                  zusammengeführt. Die Betonung bei diesem Rating liegt auf dem
                  relativen Ergebnis des Fonds im Vergleich zu Fonds in der
                  Vergleichsgruppe, also mit ähnlichem Chance-Risiko-Profil. Je
                  vergleichsweise besser ein Fonds ESG-Risiken managt, desto
                  höher ist das Rating, also die Zahl der Morningstar Globen.
                </>
              }
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={8} className={classes.articleWrapper}>
            <div className={classes.article}>
              <div className={classes.titleWrapper}>
                <Typography variant="h2" className={classes.mainTitle}>
                  SFDR Kategorie
                </Typography>
                {tooltip && (
                  <>
                    &nbsp;
                    <WarningTooltip title={tooltip} iconClassName={classes.helpIcon}/>
                  </>
                )}
              </div>
              {loading ? (
                <LoadingIndicator />
              ) : (
                <Article articleNO={sfdr}/>
              )}
            </div>
          </Grid>
        </Grid>
      </>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <CommonAnalysisCard
        classes={{
          card: classes.root,
        }}
        content={
          <div className={classes.container}>
            {this.props.assetInfo ? this.renderData() : DEFAULT_EMPTY_SECTION_MESSAGE}
          </div>
        }
      />
    );
  }
}

export default withStyles(styles)(EsgScoreChart);
